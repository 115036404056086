import * as React from "react";
import { useEffect, useReducer } from "react";
import * as R from "ramda";
import { compose, contains, flatten, prop, sortBy, toLower } from "ramda";
import {
  ApplyButton, CompCodeFilterRow,
  FilterRow,
  FiltersBlock,
  FiltersContainer,
  FiltersContainerWrapper,
  ResetButton,
  SelectContainer,
  StyledButtons
} from "../../../shared/components/filters/filters.styles";
import { Toolbar } from "../../../shared/components/layout/styles";
import {
  FilterDateRangeForDelDate,
  FilterHeader,
  FilterSelector,
  FilterSingleValueType,
  FilterValuesWithAdd
} from "../../../shared/components/filters/filters";
import {
  FilterCategoryView,
  TextFilter
} from "../../../shared/domains/query/filter";
import { initialPslFiltersvalue, newPslQuery, PslFilterKeys, PslFilters } from "../../domains/pslQuery";
import { useDispatch, useSelector } from "react-redux";
import { QueryPSLActions } from "../../reducers/pslList.reducer";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { MasterService } from "../../../shared/domains/master/master.service";
import {
  CustomFastAsyncSelect,
  CustomFastInput,
  CustomFastSelect,
  CustomFastStyledAsyncSelect,
  CustomTextareaField
} from "../../../shared/components/form";
import { IUserRole, User } from "../../../shared/domains/user/user";
import { PslComplianceStatus, SupplierTabs } from "../../domains/psl";
import {
  IUserScopeCompany,
  UserService
} from "../../../shared/domains/user/user.service";
import { useFetchCurrencies } from "../../domains/psl.hooks";
import { useFetchBusinessUnits, useFetchPslScopeTitles } from "../../../shared/domains/master/master.hooks";
import { SupplierDiversityFilterComponent, supplierDiversityLabels } from "../pslForm/component/supplierDiversityFilter.component";
import { Query } from "../../../shared/queryable/query";
import { NewPslScopeFilterComponent, PslScopeFilterComponent } from "../pslForm/component/pslScopeFilter.component";
import { Features } from "src/app/shared/domains/core/pataflag";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { RootStateStore } from "src/app/application.reducers";
import { ITreeCategory } from "../pslForm/pslForm.utils";
import { TreeSelect } from "antd";
import _ from "lodash";
import moment from 'moment';
import { createStyles, makeStyles } from "@material-ui/styles";
import { WaysOfBuyingComponent, WOBLabel } from "../pslForm/component/waysOfBuying.component";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import { PSLService } from "../../domains/psl.service";
import { getTitleWithId } from "../../helpers/functions";

export interface PslFilterProps {
  data: any;
  user: User;
  approverQuery: Query;
}

export interface PslFilterDispatcher {
  fetchFilters: () => void;
  updateFilters: (updatedFilters: any, tab: SupplierTabs, isApproval?: boolean) => void;
  resetFilters: (tab: SupplierTabs) => void;
  saveApproversFilter: (filters: any) => void;
}

interface Props extends PslFilterProps, PslFilterDispatcher {
  onClose: Function;
  userRole: IUserRole;
  mainTab: SupplierTabs;
  isApproverInApproval: boolean;
  taskTab: PslComplianceStatus
}

const useStyles = makeStyles(() =>
  createStyles({
    treeSelectClass: {
      minHeight: "38.19px",
      width: '100%',
      marginTop: 10,
      "&& .ant-select-selector": {
        color: "rgba(0, 0, 0, 0.65)",
        padding: '5px 10px'

      }
    }
  })
);


export const PslActiveFiltersList = ({ onAddFilter, filters, mainTab, isApproverInApproval = false }) => {
  const dispatch = useDispatch();
  const { hasFeature } = React.useContext(FeaturesContext);
  const deleteFilter = (filter: TextFilter) =>
    dispatch(new QueryPSLActions.onDeleteFilter(filter, mainTab));

  const deleteTypeFilter = (filterKey: string) => {
    dispatch(new QueryPSLActions.resetFiltersForKey(filterKey));
  }

  const deleteParentPslFilter = () => {
    filters["childrenOf"].selected = "";
    dispatch(new QueryPSLActions.newFilters(filters, mainTab));
  }

  const deleteSupplierFilter = (filter: TextFilter) => {
    if (filters["SupplierId"].selected?.length === 1) {
      filters["supplierDescription"].selected = [];
      filters["SupplierId"].selected = [];
      dispatch(new QueryPSLActions.newFilters(filters, mainTab));
    } else {
      dispatch(new QueryPSLActions.onDeleteFilter(filter, mainTab));
    }
  }


  return (
    <FiltersBlock>
      {PslFilters.filter(el => el.key !== "StatusId").map((f, idx) => {


        if (isApproverInApproval && (f.key === PslFilterKeys.ZONE)) {
          return ''
        }

        if ((isApproverInApproval && !hasFeature(Features.MY_TASK_STATIC_FILTER)) &&
          (f.key === PslFilterKeys.SPEND_CAT_1 || f.key === PslFilterKeys.MARKETS ||
            f.key === PslFilterKeys.COMP_CODE
            || f.key === PslFilterKeys.SPEND_CAT_2
            || f.key === PslFilterKeys.SPEND_CAT_3)) {
          return ''
        }

        switch (f.key) {
          case PslFilterKeys.TO_SPEND:
            return "";

          case PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE:
            return (
              <div style={{ display: "flex" }} key={idx + "pslCreationAndValidityDate"}>
                {(filters[PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE]?.selected.type
                  && filters[PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE]?.selected.startDate &&
                  filters[PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE]?.selected.endDate
                ) ? (
                  <FilterSingleValueType
                    key={idx}
                    chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                    name={
                      filters[PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE]?.selected.type === 'pslCreationDate' ? "PSL Creation Date" : "PSL Validity Date"
                    }
                    label={
                      `${moment(filters[PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE]?.selected?.startDate, 'YYYYMMDD').format("DD/MM/YYYY")} - ${moment(filters[PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE]?.selected?.endDate, 'YYYYMMDD').format("DD/MM/YYYY")}`
                    }
                    onDelete={() => {
                      deleteFilter(filters[PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE]?.selected)
                    }
                    }
                  />
                ) : null}
              </div>
            )

          case PslFilterKeys.FROM_SPEND:
            return (
              <div style={{ display: "flex" }} key={idx + "fromSpend"}>
                {filters[PslFilterKeys.FROM_SPEND]?.selected.length ? (
                  <FilterSingleValueType
                    key={idx}
                    chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                    name={"From"}
                    label={filters[PslFilterKeys.FROM_SPEND]?.selected}
                    onDelete={() => deleteTypeFilter(PslFilterKeys.FROM_SPEND)}
                  />
                ) : null}
                {filters[PslFilterKeys.TO_SPEND]?.selected.length ? (
                  <FilterSingleValueType
                    key={idx + 1}
                    chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                    name={"To"}
                    label={filters[PslFilterKeys.TO_SPEND]?.selected}
                    onDelete={() => deleteTypeFilter(PslFilterKeys.TO_SPEND)}
                  />
                ) : null}
              </div>
            );
          case PslFilterKeys.CURRENCY_SPEND:
            return (filters[f.key]?.selected.length ?
              <FilterSingleValueType
                key={idx}
                chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                name={f.label}
                label={filters[f.key]?.selected}
                onDelete={() => deleteTypeFilter(f.key)}
              />
              : null);
          case PslFilterKeys.PARENT_PSL:
            return (filters[f.key]?.selected.toString().length > 0 ?
              <FilterSingleValueType
                key={idx}
                chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                name={f.label}
                label={filters[f.key]?.selected.title}
                onDelete={() => deleteParentPslFilter()}
              />
              : null);
          case PslFilterKeys.SCOPE:
            // below is needed to ensure scope filter is not shown when view children button is clicked
            return ((hasFeature(Features.PSL_163770_VIEW_CHILD_PSL_FROM_PARENT) && (filters["childrenOf"]?.selected.toString().length > 0)) ? null :
              <FilterValuesWithAdd
                key={idx + f.label}
                chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                kind={f.key}
                name={f.label}
                source={filters[f.key]?.selected}
                onAddFilter={onAddFilter}
                onDelete={
                  deleteFilter}
                showAdd={
                  filters[f.key].hasOwnProperty("showAdd")
                    ? filters[f.key].showAdd
                    : f?.showAdd
                }
              />);
          case PslFilterKeys.SUPPLIER:
            return (<FilterValuesWithAdd
              key={idx + f.label}
              chipColor={idx % 2 ? "#F7A122" : "#666E72"}
              kind={f.key}
              name={f.label}
              source={filters[f.key]?.selected}
              onAddFilter={onAddFilter}
              onDelete={
                deleteSupplierFilter}
              showAdd={
                filters[f.key].hasOwnProperty("showAdd")
                  ? filters[f.key].showAdd
                  : f?.showAdd
              }
            />);
          case PslFilterKeys.MATERIALIDS:
            if (hasFeature(Features.PSL_168068_MATERIAL_ID_FILTER)) {
              return (
                mainTab === SupplierTabs.MY_TASKS ? null : <FilterValuesWithAdd
                  key={idx + f.label}
                  chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                  kind={f.key}
                  name={f.label}
                  source={filters[f.key]?.selected}
                  onAddFilter={onAddFilter}
                  onDelete={
                    deleteFilter}
                  showAdd={
                    filters[f.key].hasOwnProperty("showAdd")
                      ? filters[f.key].showAdd
                      : f?.showAdd
                  }
                />
              );
            } else {
              return null;
            }

          default:
            return (
              <FilterValuesWithAdd
                key={idx + f.label}
                chipColor={idx % 2 ? "#F7A122" : "#666E72"}
                kind={f.key}
                name={f.label}
                source={filters[f.key]?.selected}
                onAddFilter={onAddFilter}
                onDelete={
                  deleteFilter}
                showAdd={
                  filters[f.key].hasOwnProperty("showAdd")
                    ? filters[f.key].showAdd
                    : f?.showAdd
                }
              />
            );
        }
      }
      )
      }
    </FiltersBlock>
  );
};


function selectedUserScopeFun(spendCatL1, spendCatL2, spendCatL3, filters) {
  let L1 = filters['SpendCat1Code']['selected'];
  let L2 = filters['SpendCat2Code']['selected'];
  let L3 = filters['SpendCat3Code']['selected'];

  return [].concat(
    L1.map(el => ({
      value: "l1-" + el?.id,
      label: el?.title
    }))
  )
    .concat(
      L2.map(el => ({
        value: "l2-" + el?.id,
        label: el?.title
      }))
    )
    .concat(
      L3.map(el => ({
        value: "l3-" + el?.id,
        label: el?.title
      }))
    )

}

export function CreationAndValidityComponent({ filters, isViewOnly, setFilters }) {

  let data = filters['creationAndValidityDate']

  let { type } = data.selected;
  let arr = []

  if (type === 'pslCreationDate')
    arr = [{ value: type, label: 'PSL Creation Date' }]

  else if (type === 'pslValidityDate')
    arr = [{ value: type, label: 'PSL Validity Date' }]


  return <FilterRow>
    <Grid container spacing={2} wrap={"wrap"}>
      <Grid item sm={6}>
        <InputLabel>PSL Dates</InputLabel>
      </Grid>
      <Grid item sm={6}>
        <CustomFastSelect
          disabled={isViewOnly}
          options={[{ value: 'pslCreationDate', label: 'PSL Creation Date' }, { value: 'pslValidityDate', label: 'PSL Validity Date' }]}
          initialValue={arr}
          onChange={(v: { value: any; }) => {
            setFilters(R.set(R.lensPath([data.key, 'selected']), { startDate: '', endDate: '', type: v?.value ? v.value : null }, filters))
          }}
        />
      </Grid>
      <Grid item>

        <FilterDateRangeForDelDate
          format={"dd/MM/yyyy"}
          label=""
          disabled={isViewOnly || !type}
          yearDuration={1}
          source={data.selected}
          onChange={range => {
            let startDate = moment.utc(range.startDate, "YYYYMMDD").toISOString();
            let endDate = moment.utc(range.endDate, "YYYYMMDD").toISOString();

            if (startDate > endDate) return;

            setFilters(R.set(R.lensPath([data.key, 'selected']), { ...range, type: type }, filters))
          }

          } />
      </Grid>
    </Grid>
  </FilterRow>
}

export function CategoryTreeComponent(
  {
    spendCatL1, spendCatL2, spendCatL3, filters, spendCatTree, data, setFilters, changeTheFilters,
    allDisabled
  }

) {
  const [dataCat, setDataCat] = React.useState([]);
  const [selectedUserScope, setSelectedUserScope] = React.useState(selectedUserScopeFun(spendCatL1, spendCatL2, spendCatL3, filters));

  useEffect(() => {
    if (spendCatTree.length) {
      setDataCat(
        sortByCode(spendCatTree as ITreeCategory[]).map((el: ITreeCategory) => ({
          title: el.code + " - " + el.name,
          code: el.code,
          id: el.id,
          isLeaf: false,
          key: `l1-${el.id}`,
          children: sortByCode(el.spendCategories).map(
            (el2: ITreeCategory) => ({
              title: el2.code + " - " + el2.name,
              code: el2.code,
              id: el2.id,
              isLeaf: false,
              key: `l2-${el2.id}`,
              // disabled: l2Disabled,
              children: sortByCode(el2.spendCategories).map(
                (el3: ITreeCategory) => ({
                  title: el3.code + " - " + el3.name,
                  code: el3.code,
                  id: el3.id,
                  isLeaf: true,
                  key: `l3-${el3.id}`,
                  mCode: el3.materialCode,
                  mDesc: el3.materialDescription,
                  mLongDesc: el3.materialLongDescription,
                  // disabled: l3Disabled,
                })
              )
            })
          )
        }))
      );
    }

  }, [spendCatTree])
  const sortByCode = sortBy(compose(toLower, prop("code")));
  const allData = dataCat.map(el => ({ value: el.key, label: el.title }));
  const classes = useStyles({});

  const onChangeUserScope = (
    originalValues: { value: string; label: string; extra?: any }[]
  ) => {
    setSelectedUserScope(originalValues);

    //  for L1, use 
    let dL1 = data.SpendCat1Code
    let dL2 = data.SpendCat2Code
    let dL3 = data.SpendCat3Code

    let spendCatL1Args = originalValues?.filter(el => el && el.value?.search(/l1-\d*$/gi) !== -1).map(el => ({ azureId: el.value.split("l1-")[1], title: el.label, id: `${el.label.split(' - ')[0]}` }))
    let spendCatL2Args = originalValues?.filter(el => el && el.value?.search(/l2-\d*$/gi) !== -1).map(el => ({ azureId: el.value.split("l2-")[1], title: el.label, id: `${el.label.split(' - ')[0]}` }))
    let spendCatL3Args = originalValues?.filter(el => el && el.value?.search(/l3-\d*$/gi) !== -1).map(el => ({ azureId: el.value.split("l3-")[1], title: el.label, id: `${el.label.split(' - ')[0]}` }))
    let filtersAfterL1Change = changeTheFilters(spendCatL1Args, dL1)
    let filtersAfterL2Change = changeTheFilters(spendCatL2Args, dL2, filtersAfterL1Change);
    let filtersAfterL3Change = changeTheFilters(spendCatL3Args, dL3, filtersAfterL2Change)

    setFilters(filtersAfterL3Change)

  };

  const userScopeProps = {
    treeData: [
      {
        title:
          selectedUserScope.length > 0 ? (
            <span
              onClick={() => onChangeUserScope([])}
              style={{
                display: "inline-block",
                color: "#286FBE",
                cursor: "pointer"
              }}
            >
              Unselect all
            </span>
          ) : (
            <span
              onClick={() => onChangeUserScope(allData)}
              style={{
                display: "inline-block",
                color: "#286FBE",
                cursor: "pointer"
              }}
            >
              Select all
            </span>
          ),
        value: "xxx",
        disableCheckbox: true,
        checkable: false,
        disabled: true
      }
    ].concat(dataCat),
    value: selectedUserScope,
    onChange: onChangeUserScope,
    treeCheckable: true,
    disabled: false,
    treeCheckStrictly: false,
    dropdownMatchSelectWidth: false,
    treeNodeFilterProp: "title",
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    labelInValue: true,
    placeholder: "Click here to select categories",
    filterTreeNode: (inputValue: string, treeNode: any) => {
      if (inputValue.length < 2 || typeof treeNode.title !== 'string') return false;
      return contains(inputValue.toUpperCase(), treeNode.title.toUpperCase());
    }
  };

  return <>
    <InputLabel
      style={{
        marginTop: 20
      }}
    >Spend Category</InputLabel>
    <TreeSelect
      {...userScopeProps}
      disabled={allDisabled}
      dropdownStyle={{ zIndex: 99999 }}
      // style={getStyle()}
      className={classes.treeSelectClass}
    />
  </>
}

export function LocationComponent({
  isViewOnly, filters, companyCodes, setCompCodes, handleChange,
  marketState, setMarketState, setFilters, zoneState, setLocationsIds, setLocation, setPlants
}) {

  let [arr, setArr] = React.useState([]);

  const zoneData = filters.zones;
  const marketData = filters.Markets;
  const companyCodeData = filters.CompanyCode

  let options = [
    { value: filters['zones'].key, label: filters['zones'].label },
    { value: filters['Markets'].key, label: filters['Markets'].label },
    { value: filters['CompanyCode'].key, label: filters['CompanyCode'].label }
  ]


  React.useEffect(() => {

    if (isViewOnly) {
      setArr([options[2]]);
      return;
    }

    if (zoneData.selected.length) setArr([options[0]])
    if (marketData.selected.length) setArr([options[1]])
    if (companyCodeData.selected.length) setArr([options[2]])
  }, [zoneData, marketData, companyCodeData])

  const { hasFeature } = React.useContext(FeaturesContext);

  function changeHandler(v) {
    const filtersCopy = _.cloneDeep(filters)

    if (hasFeature(Features.PSL_162445_ADD_PLANT_FILTER_AND_SEARCH)) {
      filtersCopy.plants.selected = [];
    }

    let selected = v ? v.map(el => ({
      id: el.value,
      title: el.label
    })) : []

    if (hasFeature(Features.PSL_162445_ADD_PLANT_FILTER_AND_SEARCH)) {

      if (arr[0]?.value === filters['CompanyCode'].key) {
        filtersCopy.zones.selected = [];
        filtersCopy.Markets.selected = [];
        setLocationsIds({ zoneIds: [], marketIds: [], companyIds: (v?.map((comp: { label: string; }) => comp.label.split(" - ")[0])) });
        filtersCopy.CompanyCode.selected = selected
        setFilters(filtersCopy)
      }

      if (arr[0]?.value === filters['Markets'].key) {
        filtersCopy.zones.selected = [];
        filtersCopy.CompanyCode.selected = [];
        setLocationsIds({ zoneIds: [], marketIds: (v?.map((market: { value: string; }) => market.value)), companyIds: [] });
        filtersCopy.Markets.selected = selected
        setFilters(filtersCopy)
      }

      if (arr[0]?.value === filters['zones'].key) {
        filtersCopy.Markets.selected = [];
        filtersCopy.CompanyCode.selected = [];
        setLocationsIds({ zoneIds: (v?.map((zone: { value: string; }) => zone.value)), marketIds: [], companyIds: [] });
        filtersCopy.zones.selected = selected
        setFilters(filtersCopy)
      }

    } else {
      if (arr[0]?.value === filters['CompanyCode'].key) {
        // make default values for markets and zone
        filtersCopy.zones.selected = [];
        filtersCopy.Markets.selected = [];
        setCompCodes(v?.map((comp: { label: string; }) => comp.label.split(" - ")[0]));
        filtersCopy.CompanyCode.selected = selected
        setFilters(filtersCopy)
      }

      if (arr[0]?.value === filters['Markets'].key) {
        filtersCopy.zones.selected = [];
        filtersCopy.CompanyCode.selected = [];
        filtersCopy.Markets.selected = selected
        setFilters(filtersCopy)
      }

      if (arr[0]?.value === filters['zones'].key) {
        filtersCopy.Markets.selected = [];
        filtersCopy.CompanyCode.selected = [];
        filtersCopy.zones.selected = selected
        setFilters(filtersCopy)
      }
    }
  }

  function removeAllFilters(v) {
    if (!v) {
      let filtersCopy = _.cloneDeep(filters);
      filtersCopy.Markets.selected = [];
      filtersCopy.CompanyCode.selected = [];
      filtersCopy.zones.selected = [];
      setFilters(filtersCopy)
    }
  }

  function getInitialValues() {

    if (arr[0]?.value === filters['zones'].key) {
      return filters[zoneData.key]?.selected?.map((el: { id: any; title: any; }) => ({
        value: el.id.toString(),
        label: el.title
      }))
    } else if (arr[0]?.value === filters['Markets'].key) {
      return filters[marketData.key]?.selected?.map((el: { id: any; title: any; }) => ({
        value: el.id.toString(),
        label: el.title
      }))
    } else if (arr[0]?.value === filters['CompanyCode'].key) {
      return filters[companyCodeData.key]?.selected?.map((el: { id: any; title: any; }) => ({
        value: el.id,
        label: el.title
      }))
    } else return []
  }

  function getOptions() {

    if (arr[0]?.value === filters['zones'].key) {

      return zoneState.map(el => ({
        value: el.id.toString(), label: el.name
      }))
    } else if (arr[0]?.value === filters['Markets'].key) {
      return marketState.map(v => ({
        value: v.id.toString(),
        label: v.title
      }))
    } else if (arr[0]?.value === filters['CompanyCode'].key) {
      return companyCodes.map(v => ({
        value: v.companyCode.id,
        label: `${v.companyCode.code} - ${v.companyCode.name}`
      }))
    } else return []
  }

  function getPlaceholder() {
    if (arr[0]?.value === filters['CompanyCode'].key)
      return 'Please select Company Codes'
    else if (arr[0]?.value === filters['Markets'].key)
      return 'Please select Markets'
    else if (arr[0]?.value === filters['zones'].key)
      return 'Please select Zones'
    else return ''
  }

  return (<FilterRow>
    <Grid container spacing={2} wrap={"wrap"}>
      <Grid item sm={6}>
        <InputLabel>Location</InputLabel>
      </Grid>
      <Grid item sm={6}>
        <CustomFastSelect
          disabled={isViewOnly}
          options={options}
          initialValue={arr}
          onChange={(v: { value: any; label: any }) => {
            if (hasFeature(Features.PSL_162445_ADD_PLANT_FILTER_AND_SEARCH)) {
              if ((v?.value && v?.label)) {
                setLocation(true)
                setPlants([])
              } else {
                setLocation(false)
                setPlants([])
                let filtersCopy = _.cloneDeep(filters);
                filtersCopy.plants.selected = [];
                setFilters(filtersCopy)
              }
            }
            setArr((v?.value && v?.label) ? [{ value: v.value, label: v.label }] : [])
            removeAllFilters(v);
          }}
        />
      </Grid>
    </Grid>
    <Grid item >
      <SelectContainer
        style={{ marginTop: 10 }}
      >
        <CustomFastSelect
          isMulti={true}
          disabled={!arr.length}
          options={getOptions()}
          placeholder={getPlaceholder()}
          initialValue={getInitialValues()}
          onChange={(v: any[]) => {
            changeHandler(v);
          }}
        />
      </SelectContainer>

    </Grid>
  </FilterRow>)
}


export default function PslFiltersPage(props: Props) {
  const { data, user } = props;
  const [filters, setFilters]: [any, any] = useReducer(R.mergeDeepRight, data)
  const spendCatL1: Array<any> = user.filters.userScope.spendCatL1s;
  const spendCatL2: Array<any> = user.filters.userScope.spendCatL2s;
  const spendCatL3: Array<any> = user.filters.userScope.spendCatL3s;
  const companies: Array<any> = user.filters.psl.companies;
  const [companyCodes, setCompanyCodes] = React.useState<IUserScopeCompany[]>(
    []
  );
  const [plants, setPlants] = React.useState([]);
  const [compCodes, setCompCodes] = React.useState([]);
  const isViewOnly = props.user.isViewOnly();
  const isScopeFilterVisible = props.user.isAtMarket() || props.user.isRequestor();
  const [marketState, setMarketState] = React.useState([])
  const [zoneState, setZoneState] = React.useState([])

  const spendCatL1Selected = spendCatL1.length > 0;
  const spendCatL2Selected = spendCatL2.length > 0;
  const spendCatL3Selected = spendCatL3.length > 0;
  const { data: currencies } = useFetchCurrencies();
  const { data: businessUnits } = useFetchBusinessUnits();
  const { data: pslScopeTitles } = useFetchPslScopeTitles();
  const { hasFeature } = React.useContext(FeaturesContext);

  const [locationsIds, setLocationsIds] = React.useState({ zoneIds: [], marketIds: [], companyIds: [] });
  const [location, setLocation] = React.useState(false)

  const spendCatTree = useSelector((state: RootStateStore) => state.psl.spendCategoryTreeReducer);

  function changeTheFilters(value: any[], d: FilterCategoryView, filtersCopyFromFn?: any) {
    let filtersCopy = filtersCopyFromFn ? filtersCopyFromFn : _.cloneDeep(filters);
    const newValues = value
      ? value.map((el: { title: any; azureId: number; id: any; }) => ({
        title: el.title,
        id: el.azureId && el.azureId !== 0 ? el.azureId : el.id
      }))
      : [];

    filtersCopy[d.key]["selected"] = newValues
    return filtersCopy
  }

  useEffect(() => {
    if (companies.length) {
      const codes = companies.map(code => code.title.split(" - ")[0]);
      if (codes.filter(item => compCodes.indexOf(item) < 0).length) {
        setCompCodes(codes);
      }
    } else if (companyCodes.length) {
      setCompCodes(companyCodes.map(code => code.companyCode.code));
    }
  }, [companies, companyCodes]);

  useEffect(() => {
    if (compCodes && compCodes.length) {
      MasterService.fetchPlants("", compCodes.join(","), (data: React.SetStateAction<any[]>) =>
        setPlants(data)
      );
    }
  }, [compCodes])

  const clearPlantFilter = () => {
    let filtersCopy = _.cloneDeep(filters);
    filtersCopy.plants.selected = [];
    setFilters(filtersCopy)
    setPlants([])
  }

  useEffect(() => {
    if (location) {
      clearPlantFilter()
    }
    if (locationsIds.zoneIds && locationsIds.zoneIds.length || locationsIds.marketIds && locationsIds.marketIds.length || locationsIds.companyIds && locationsIds.companyIds.length) {
      clearPlantFilter();
      MasterService.fetchPlantsBySearchOrZoneOrMarketOrCompCode("", locationsIds.zoneIds.length ? locationsIds.zoneIds.join(",") : null, locationsIds.marketIds.length ? locationsIds.marketIds.join(",") : null, locationsIds.companyIds.length ? locationsIds.companyIds.join(",") : null, null, (data: React.SetStateAction<any[]>) =>
        setPlants(data)
      );
    }
  }, [location, locationsIds]);


  useEffect(() => {
    props.fetchFilters();

    UserService.fetchUserScope(false).then((res: any) => {
      const markets = user.filters.userScope.markets.map(it => it.id);
      const marketsInScope = user.filters.userScope.markets
      if (markets.length > 0) {
        const marketItems = res.filter((item: { market: { id: { toString: () => string | number; }; }; }) =>
          markets.includes(item.market.id.toString())
        );
        setCompanyCodes(flatten(marketItems.map((item: { companyCodes: any; }) => item.companyCodes)));
        setMarketState(marketsInScope)
      } else {
        setCompanyCodes(flatten(res.map((item: { companyCodes: any; }) => item.companyCodes)));
        setMarketState(res.map(item => {
          return {
            id: item.market.id.toString(),
            title: item.market.name
          }
        }))
      }
      if (isViewOnly) {
        const compCode: Array<any> = flatten(
          res.map((item: { companyCodes: any; }) => item.companyCodes)
        );
        setCompanyCodes(
          compCode.filter(item => item.code === props.user.companyCode)
        );
      }
    });

    UserService.fetchZones().then(res => {
      setZoneState(res)
    })
  }, []);


  const handleChange = (value: any[], d: FilterCategoryView) => {
    const newValues = value
      ? value.map((el: { title: any; azureId: number; id: any; }) => ({
        title: el.title,
        id: el.azureId && el.azureId !== 0 ? el.azureId : el.id
      }))
      : [];
    setFilters(R.set(R.lensPath([d.key, "selected"]), newValues, filters));
  };
  const handleSimpleChange = (value: any, d: FilterCategoryView) => {
    setFilters(R.set(R.lensPath([d.key, "selected"]), value, filters));
  };
  const resetFilters = () => {
    props.updateFilters(
      R.mergeDeepWithKey(
        (k, l, r) => (k === "data" ? r : l),
        newPslQuery(initialPslFiltersvalue).filters,
        data
      ),
      props.mainTab
    );
  };

  const filterCmps = R.mapObjIndexed(
    (d: FilterCategoryView<TextFilter[]>, key: string) => {
      if (d === null) return [];
      switch (d.key) {
        case PslFilterKeys.MATERIAL_NUMBER:
          return (
            <FilterRow key={key}>
              <InputLabel>{d.label}</InputLabel>
              <SelectContainer>
                <CustomFastAsyncSelect
                  disabled={isViewOnly}
                  isMulti={true}
                  onLoadLimit={3}
                  onLoad={(x: string) => MasterService.fetchMaterialFromAzure(null, x)}
                  customDisplay={(option: { value: any; label: any; }) => `${option.value} - ${option.label}`}
                  initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                    value: el.id,
                    label: el.title
                  }))}
                  onChange={v =>
                    handleChange(
                      v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                      d
                    )
                  }
                />
              </SelectContainer>
            </FilterRow>
          );
        case PslFilterKeys.SUPPLIER:
          let options = [
            { value: 'parentVendor', label: 'Parent Vendor' },
            { value: 'localChidVendor', label: 'Local/Child Vendor' },
          ]
          return (
            <FilterRow key={key}>
              {
                hasFeature(Features.PSL_166590_PSL_SCREEN_POPUP_ICON_SHOWINGS) ?
                  <>
                    {
                      hasFeature(Features.PSL_163229_SUPPLIER_FILTER_ENHANCEMENT) &&
                      <>
                        <Grid container spacing={2} wrap={"wrap"}>
                          <Grid item sm={6}>
                            <InputLabel>{d.label}</InputLabel>
                          </Grid>
                          <Grid item sm={6}>
                            <CustomFastSelect
                              disabled={isViewOnly}
                              options={options}
                              // initialValue={options.find((v) => v.value === filters['supplierDescription']?.selected)}
                              initialValue={filters['supplierDescription']?.selected?.map((el: { id: any; title: any; }) => ({
                                value: el.id,
                                label: el.title
                              }))}
                              onChange={(v) => {
                                let modifiedFilter = R.set(R.lensPath(['supplierDescription', "selected"]), v?.value ? [{ id: v.value, title: v.label }] : [], filters);
                                let updatedFilter = R.set(R.lensPath([d.key, "selected"]), [], modifiedFilter);
                                setFilters(updatedFilter);
                                // handleChange([], d)
                              }}
                            />
                          </Grid>
                        </Grid><Grid item>
                          <div style={{ paddingTop: '10px' }}>
                            <SelectContainer>
                              <CustomFastStyledAsyncSelect
                                disabled={filters['supplierDescription']?.selected.length === 0}
                                isMulti={true}
                                onLoadLimit={3}
                                onLoad={(x: string) => {
                                  if (filters['supplierDescription']?.selected[0].id === "localChidVendor") {
                                    return MasterService.fetchSuppliersFromAzure(x, "", true).then(data => data.map(el => ({ value: el.id, label: el.name })))
                                  } else if (filters['supplierDescription']?.selected[0].id === "parentVendor") {
                                    return MasterService.fetchParentSuppliers(x).then(data => data.map(el => ({ value: el.id, label: el.name })))
                                  } else {
                                    return []
                                  }
                                }}
                                customDisplay={(option: { value: any; label: any; }) => `${option.value} - ${option.label}`}
                                initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                                  value: el.id,
                                  label: el.title
                                }))}
                                onChange={(v) => {
                                  const tempData = v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label }));
                                  let updatedFilter = R.set(R.lensPath([d.key, "selected"]), tempData, filters);
                                  let modifiedFilter = R.set(R.lensPath(['supplierDescription', "selected"]), tempData?.length > 0 ? filters["supplierDescription"].selected : [], updatedFilter);
                                  setFilters(modifiedFilter);
                                  // handleChange([], d)
                                }}
                              // onChange={v => handleChange(
                              //   v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                              //   d
                              // )} 
                              />
                            </SelectContainer>
                          </div>
                        </Grid>
                      </>
                    }
                    {
                      !hasFeature(Features.PSL_163229_SUPPLIER_FILTER_ENHANCEMENT) &&
                      <>
                        <InputLabel>{d.label}</InputLabel>
                        <SelectContainer>
                          <CustomFastStyledAsyncSelect
                            disabled={isViewOnly}
                            isMulti={true}
                            onLoadLimit={3}
                            onLoad={(x: string) => MasterService.fetchSuppliersFromAzure(x, "", true).then(data => data.map(el => ({ value: el.id, label: el.name }))
                            )}
                            customDisplay={(option: { value: any; label: any; }) => `${option.value} - ${option.label}`}
                            initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                              value: el.id,
                              label: el.title
                            }))}
                            onChange={v => handleChange(
                              v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                              d
                            )} />
                        </SelectContainer>
                      </>
                    }
                  </>
                  :
                  <>
                    {
                      hasFeature(Features.PSL_163229_SUPPLIER_FILTER_ENHANCEMENT) &&
                      <>
                        <Grid container spacing={2} wrap={"wrap"}>
                          <Grid item sm={6}>
                            <InputLabel>{d.label}</InputLabel>
                          </Grid>
                          <Grid item sm={6}>
                            <CustomFastSelect
                              disabled={isViewOnly}
                              options={options}
                              // initialValue={options.find((v) => v.value === filters['supplierDescription']?.selected)}
                              initialValue={filters['supplierDescription']?.selected?.map((el: { id: any; title: any; }) => ({
                                value: el.id,
                                label: el.title
                              }))}
                              onChange={(v) => {
                                let modifiedFilter = R.set(R.lensPath(['supplierDescription', "selected"]), v?.value ? [{ id: v.value, title: v.label }] : [], filters);
                                let updatedFilter = R.set(R.lensPath([d.key, "selected"]), [], modifiedFilter);
                                setFilters(updatedFilter);
                                // handleChange([], d)
                              }}
                            />
                          </Grid>
                        </Grid><Grid item>
                          <div style={{ paddingTop: '10px' }}>
                            <SelectContainer>
                              <CustomFastAsyncSelect
                                disabled={filters['supplierDescription']?.selected.length === 0}
                                isMulti={true}
                                onLoadLimit={3}
                                onLoad={(x: string) => {
                                  if (filters['supplierDescription']?.selected[0].id === "localChidVendor") {
                                    return MasterService.fetchSuppliersFromAzure(x, "", true).then(data => data.map(el => ({ value: el.id, label: el.name })))
                                  } else if (filters['supplierDescription']?.selected[0].id === "parentVendor") {
                                    return MasterService.fetchParentSuppliers(x).then(data => data.map(el => ({ value: el.id, label: el.name })))
                                  } else {
                                    return []
                                  }
                                }}
                                customDisplay={(option: { value: any; label: any; }) => `${option.value} - ${option.label}`}
                                initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                                  value: el.id,
                                  label: el.title
                                }))}
                                onChange={v => handleChange(
                                  v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                                  d
                                )} />
                            </SelectContainer>
                          </div>
                        </Grid>
                      </>
                    }
                    {
                      !hasFeature(Features.PSL_163229_SUPPLIER_FILTER_ENHANCEMENT) &&
                      <>
                        <InputLabel>{d.label}</InputLabel>
                        <SelectContainer>
                          <CustomFastAsyncSelect
                            disabled={isViewOnly}
                            isMulti={true}
                            onLoadLimit={3}
                            onLoad={(x: string) => MasterService.fetchSuppliersFromAzure(x, "", true).then(data => data.map(el => ({ value: el.id, label: el.name }))
                            )}
                            customDisplay={(option: { value: any; label: any; }) => `${option.value} - ${option.label}`}
                            initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                              value: el.id,
                              label: el.title
                            }))}
                            onChange={v => handleChange(
                              v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                              d
                            )} />
                        </SelectContainer>
                      </>
                    }
                  </>
              }
            </FilterRow>
          );

        case PslFilterKeys.ZONE:
          return null;

        case PslFilterKeys.LOCATION:
          return ((
            (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval) &&
            hasFeature(Features.SHOW_LOCATION_FIELD_IN_PSL_FILTERS)) ?
            <LocationComponent
              isViewOnly={isViewOnly}
              filters={filters}
              setFilters={setFilters}
              companyCodes={companyCodes}
              setCompCodes={setCompCodes}
              handleChange={handleChange}
              marketState={marketState}
              setMarketState={setMarketState}
              zoneState={zoneState}
              setLocationsIds={setLocationsIds}
              setLocation={setLocation}
              setPlants={setPlants}
            />
            : null
          )

        case PslFilterKeys.COMP_CODE:
          return ((
            (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval) &&
            !hasFeature(Features.SHOW_LOCATION_FIELD_IN_PSL_FILTERS)) ?
            <FilterRow key={key}>
              <InputLabel>{d.label}</InputLabel>
              <SelectContainer>
                <CustomFastSelect
                  isMulti={true}
                  options={companyCodes.map(v => ({
                    value: v.companyCode.id,
                    label: `${v.companyCode.code} - ${v.companyCode.name}`
                  }))}
                  initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                    value: el.id,
                    label: el.title
                  }))}
                  onChange={(v: any[]) => {
                    setCompCodes(v?.map((comp: { label: string; }) => comp.label.split(" - ")[0]));
                    handleChange(
                      v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                      d
                    );
                  }}
                />
              </SelectContainer>
            </FilterRow>
            : null
          );
        case PslFilterKeys.PLANT:
          if (hasFeature(Features.PSL_162445_ADD_PLANT_FILTER_AND_SEARCH)) {
            if (location) {
              return (
                !hasFeature(Features.HIDE_PLANT_IN_PSL_FILTERS)
                  ?
                  <FilterRow key={key}>
                    <InputLabel>{d.label}</InputLabel>
                    <SelectContainer>
                      <CustomFastSelect
                        disabled={isViewOnly}
                        isMulti={true}
                        options={plants.map(v => ({
                          value: v.value,
                          label: `${v.value} - ${v.label}`
                        }))}
                        initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                          value: el.id,
                          label: el.title
                        }))}
                        onChange={(v: any[]) =>
                          handleChange(
                            v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                            d
                          )
                        }
                      />
                    </SelectContainer>
                  </FilterRow>
                  : null
              );
            } else {
              return (
                !hasFeature(Features.HIDE_PLANT_IN_PSL_FILTERS) ?
                  <FilterRow key={key}>
                    <InputLabel>{d.label}</InputLabel>
                    <SelectContainer>
                      <CustomFastAsyncSelect
                        disabled={isViewOnly}
                        isMulti={true}
                        onLoadLimit={2}
                        onLoad={(x) => MasterService.fetchPlantsBySearchOrZoneOrMarketOrCompCode(x, null, null, null, null, (data: any) => {
                          return data.map(v => ({
                            value: v.value,
                            label: `${v.value} - ${v.label}`
                          }))
                        }
                        )}
                        initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                          value: el.id,
                          label: el.title
                        }))}
                        onChange={(v: any[]) =>
                          handleChange(
                            v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                            d
                          )
                        }
                      />
                    </SelectContainer>
                  </FilterRow>
                  :
                  null
              )
            }
          } else {
            return (
              !hasFeature(Features.HIDE_PLANT_IN_PSL_FILTERS)
                ?
                <FilterRow key={key}>
                  <InputLabel>{d.label}</InputLabel>
                  <SelectContainer>
                    <CustomFastSelect
                      disabled={isViewOnly}
                      isMulti={true}
                      options={plants.map(v => ({
                        value: v.value,
                        label: `${v.value} - ${v.label}`
                      }))}
                      initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                        value: el.id,
                        label: el.title
                      }))}
                      onChange={(v: any[]) =>
                        handleChange(
                          v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                          d
                        )
                      }
                    />
                  </SelectContainer>
                </FilterRow>
                : null
            );
          }
        case PslFilterKeys.BUSINESS_UNITS:
          return (
            <FilterRow key={key}>
              <InputLabel>{d.label}</InputLabel>
              <SelectContainer>
                <CustomFastSelect
                  disabled={isViewOnly}
                  isMulti={true}
                  options={businessUnits.map(v => ({
                    value: v.id,
                    label: v.name
                  }))}
                  initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                    value: el.id,
                    label: el.title
                  }))}
                  customDisplay={(o: { label: any; }) => `${o.label}`}
                  onChange={(v: any[]) =>
                    handleChange(
                      v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                      d
                    )
                  }
                />
              </SelectContainer>
            </FilterRow>
          );
        case PslFilterKeys.BUYERS:
          return (
            <FilterRow key={key}>
              <InputLabel>{d.label}</InputLabel>
              <SelectContainer>
                <CustomFastAsyncSelect
                  disabled={isViewOnly}
                  onLoad={PSLService.fetchPslUserEmails}
                  onLoadLimit={4}
                  isMulti={true}
                  initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                    value: el.id,
                    label: el.title
                  }))}
                  onChange={v =>
                    handleChange(
                      v?.map((v: { value: any; label: any; }) => ({ id: v.value, title: v.label })),
                      d
                    )
                  }
                />
              </SelectContainer>
            </FilterRow>
          );
        case PslFilterKeys.SUPPLIER_DIVERISTY:
          return (<FilterRow key={key}>
            <InputLabel>{d.label}</InputLabel>
            <SelectContainer>
              <SupplierDiversityFilterComponent
                initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                  value: el.title,
                  title: supplierDiversityLabels[el.title]
                }))}
                onChange={(
                  value: any[]
                ) =>
                  handleChange(
                    value?.map(el => ({ id: el, title: el })),
                    d
                  )
                }
              ></SupplierDiversityFilterComponent>
            </SelectContainer>
          </FilterRow>
          )
        case PslFilterKeys.WAY_OF_BUYING:
          return (hasFeature(Features.PSL_WAYS_OF_BUYING) && <>
            <FilterRow key={key}>
              <InputLabel>{d.label}</InputLabel>
              <SelectContainer>
                <WaysOfBuyingComponent
                  initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => (parseInt(el.id, 10)))}
                  onChange={(
                    value: any[]
                  ) =>
                    handleChange(
                      value?.map(el => ({ id: el, title: WOBLabel(el) })),
                      d
                    )
                  }
                ></WaysOfBuyingComponent>
              </SelectContainer>
            </FilterRow>
          </> || null);
        case PslFilterKeys.STATUS:
        case PslFilterKeys.MARKETS:
          return null;
        case PslFilterKeys.PSLIDS:
          return <FilterRow key={key}>
            <InputLabel>{d.label}</InputLabel>
            <CustomTextareaField
              placeholder={'Add PSL ID(s)'}
              rows={2}
              rowsMax={'Infinity'}
              initialValue={filters[d.key]?.selected?.map(el => (el.id)).join('\n')}
              onChange={(v) => handleChange(v ? v.split(/\r?\n/).filter(v => v.replace(/\s/g, '').length).map(v => ({ id: v })) : null, d)} />
          </FilterRow>

        case PslFilterKeys.MATERIALIDS:
          if (hasFeature(Features.PSL_168068_MATERIAL_ID_FILTER)) {
            return props.mainTab === SupplierTabs.MY_TASKS ? null : <FilterRow key={key}>
              <InputLabel>{d.label}</InputLabel>
              <CustomTextareaField
                placeholder={'Add PSL Material ID(s)'}
                rows={2}
                rowsMax={'Infinity'}
                initialValue={filters[d.key]?.selected?.map(el => (el.id)).join('\n')}
                onChange={(v) => handleChange(v ? v.split(/\r?\n/).filter(v => v.replace(/\s/g, '').length).map(v => ({ id: v })) : null, d)} />
            </FilterRow>
          } else {
            return null
          }

        case PslFilterKeys.SPEND_CAT_1:
          return ((!hasFeature(Features.SPEND_CATEGORY_IN_PSL_FILTERS)
            && (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval)
          ) || (
              (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval) &&
              (spendCatL1Selected || spendCatL2Selected || spendCatL3Selected)) ?
            <FilterRow key={key}>
              <FilterSelector
                label={d.label}
                disabled={
                  spendCatL1Selected ||
                  spendCatL2Selected ||
                  spendCatL3Selected ||
                  isViewOnly
                }
                selected={
                  spendCatL1Selected ? spendCatL1 : filters[d.key]?.selected
                }
                source={d.data}
                onChange={(value: any) => handleChange(value, d)}
              />
            </FilterRow> : null
          );
        case PslFilterKeys.SPEND_CAT_2:
          const spendCatL1Ids = spendCatL1.map(item => item.id);
          const spendCat2Data = spendCatL1Selected
            ? d.data.filter(item =>
              spendCatL1Ids.find(e => e === item.parentId.toString())
            )
            : d.data;
          return ((!hasFeature(Features.SPEND_CATEGORY_IN_PSL_FILTERS)
            && (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval)
          ) || (
              (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval) &&
              (spendCatL1Selected || spendCatL2Selected || spendCatL3Selected)) ?
            <FilterRow key={key}>
              <FilterSelector
                label={d.label}
                disabled={
                  spendCatL2Selected || spendCatL3Selected || isViewOnly
                }
                selected={
                  spendCatL2Selected ? spendCatL2 : filters[d.key]?.selected
                }
                source={spendCat2Data}
                onChange={(value: any) => handleChange(value, d)}
              />
            </FilterRow> : null
          );
        case PslFilterKeys.SPEND_CAT_3:
          let spendCat3Data: any[];
          if (spendCatL1Selected || spendCatL2Selected) {
            let idsForL1 = [];
            if (spendCatL1Selected) {
              const spendCatL1Ids = spendCatL1
                .map(item => item.title.slice(0, 3))
                .join("|");
              const validIds = new RegExp("^" + spendCatL1Ids);
              idsForL1 = d.data.filter(item =>
                validIds.test(item.id.toString())
              );
            }
            let idsForL2: TextFilter[];
            if (filters.SpendCat2Code.selected.length > 0) {
              const selectedL2Ids = filters.SpendCat2Code.selected.map(
                (item: { id: any; }) => item.id
              );
              spendCat3Data = d.data.filter(item =>
                selectedL2Ids.find((e: string) => e === item.parentId)
              );
            } else {
              const spendCatL2Is = spendCatL2.map(item => item.id);
              idsForL2 = d.data.filter(item =>
                spendCatL2Is.find(e => e === item.parentId.toString())
              );
              spendCat3Data = idsForL1.concat(idsForL2);
            }
          } else {
            spendCat3Data = d.data;
          }
          return ((!hasFeature(Features.SPEND_CATEGORY_IN_PSL_FILTERS)
            && (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval)
          ) || (
              (hasFeature(Features.MY_TASK_STATIC_FILTER) ? true : !props.isApproverInApproval) &&
              (spendCatL1Selected || spendCatL2Selected || spendCatL3Selected)) ?
            <FilterRow key={key}>
              <FilterSelector
                disabled={spendCatL3Selected || isViewOnly}
                label={d.label}
                selected={
                  spendCatL3Selected ? spendCatL3 : filters[d.key]?.selected
                }
                source={spendCat3Data}
                onChange={(value: any) => handleChange(value, d)}
              />
            </FilterRow> : null
          );
        case PslFilterKeys.PARENT_PSL:
        case PslFilterKeys.TO_SPEND:
        case PslFilterKeys.CURRENCY_SPEND:
          return null;
        case PslFilterKeys.FROM_SPEND:
          return (
            <FilterRow key={key}>
              <Grid container spacing={2} wrap={"wrap"}>
                <Grid item sm={6}>
                  <InputLabel>Yearly Spend Estimation</InputLabel>
                </Grid>
                <Grid item sm={6}>
                  <CustomFastSelect
                    disabled={isViewOnly}
                    options={currencies}
                    initialValue={
                      filters[PslFilterKeys.CURRENCY_SPEND]?.selected
                        ? {
                          value:
                            filters[PslFilterKeys.CURRENCY_SPEND]?.selected,
                          label:
                            filters[PslFilterKeys.CURRENCY_SPEND]?.selected
                        }
                        : null
                    }
                    onChange={(v: { value: any; }) =>
                      handleSimpleChange(
                        v.value,
                        data[PslFilterKeys.CURRENCY_SPEND]
                      )
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputLabel>{d.label}</InputLabel>
                  <CustomFastInput
                    disabled={isViewOnly}
                    style={{ height: "auto" }}
                    type={"number"}
                    initialValue={filters[d.key]?.selected}
                    onChange={(v: any) => handleSimpleChange(v, d)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputLabel>{data[PslFilterKeys.TO_SPEND].label}</InputLabel>
                  <CustomFastInput
                    disabled={isViewOnly}
                    style={{ height: "auto" }}
                    type={"number"}
                    initialValue={filters[PslFilterKeys.TO_SPEND]?.selected}
                    onChange={(v: any) =>
                      handleSimpleChange(v, data[PslFilterKeys.TO_SPEND])
                    }
                  />
                </Grid>
              </Grid>
            </FilterRow>
          );

        case PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE:
          return <CreationAndValidityComponent
            filters={filters}
            isViewOnly={isViewOnly}
            setFilters={setFilters}
          />

        case PslFilterKeys.SCOPE:
          return isScopeFilterVisible ?
            <FilterRow key={key}>
              <InputLabel>{d.label}</InputLabel>
              <SelectContainer>
                {
                  hasFeature(Features.PSL_167882_SHOW_API_DATA_FOR_SCOPE_FILTER) ? 
                  <NewPslScopeFilterComponent
                    initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => {
                      return ({
                        value: Number(el.id),
                        title: el.title
                      })
                    })}
                    data={pslScopeTitles}
                    onChange={(value: any[]) => {
                        handleChange(value?.map(el => ({ id: el, title: getTitleWithId(el,pslScopeTitles)})) , d)
                      }
                    }
                  ></NewPslScopeFilterComponent>
                  :
                  <PslScopeFilterComponent
                    initialValue={filters[d.key]?.selected?.map((el: { id: any; title: any; }) => ({
                      value: el.title,
                      title: el.title
                    }))}
                    user={props.user}
                    onChange={(value: any[]) => handleChange(value?.map(el => ({ id: el, title: el })), d)
                  }
                  ></PslScopeFilterComponent>
                }
              </SelectContainer>
            </FilterRow> : null;
        case PslFilterKeys.SHOW_OBSOLETE:
          return hasFeature(Features.PSL_OBSOLETE_ENTRIES) ? <CompCodeFilterRow key={key}>
            <FormControlLabel
              control={<Checkbox
                defaultChecked={d.selected as any}
                onChange={(_, checked) => handleSimpleChange(checked, d)}
                size="small"
                color="primary" />}
              label={<InputLabel>{d.label}</InputLabel>}
            />
          </CompCodeFilterRow>
            : null;
        default:
          return null;
      }
    },
    data
  );

  return (
    <>
      <Toolbar>
        <FilterHeader onCloseClick={() => props.onClose(false)} />
      </Toolbar>
      <FiltersContainerWrapper>
        <FiltersContainer>
          {/* Add Tree structure here .. hehehehehe :> :> */}
          {
            (hasFeature(Features.SPEND_CATEGORY_IN_PSL_FILTERS)
              && !props.isApproverInApproval
            ) && !(spendCatL1Selected || spendCatL2Selected || spendCatL3Selected) ? <CategoryTreeComponent
              spendCatL1={spendCatL1}
              spendCatL2={spendCatL2}
              spendCatL3={spendCatL3}
              filters={filters}
              spendCatTree={spendCatTree}
              data={data}
              setFilters={setFilters}
              changeTheFilters={changeTheFilters}
              allDisabled={isViewOnly}
            /> : null
          }
          {R.keys(filterCmps).map(o => filterCmps[o])}
          <FilterRow>
            <StyledButtons>
              <ApplyButton
                onClick={() => {
                  props.updateFilters(
                    R.mergeDeepWithKey(
                      (k, l, r) => (k === "data" ? r : l),
                      filters,
                      data
                    ),
                    props.mainTab, props.isApproverInApproval
                  );
                  props.onClose(false);
                }}
                variant="contained"
                color="primary"
                autoFocus
              >
                Apply
              </ApplyButton>
              <ResetButton
                onClick={() => {
                  resetFilters();
                  props.onClose(false);
                }}
                variant="contained"
                color="primary"
              >
                Reset
              </ResetButton>
            </StyledButtons>
          </FilterRow>
        </FiltersContainer>
      </FiltersContainerWrapper>
    </>
  );
}
