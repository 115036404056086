import React from "react";
import { withRouter } from 'react-router-dom';

export class ScrollToTop extends React.Component<any> {
  componentDidUpdate() {
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth'}), 200);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(
  class ScrollToTopRouter extends React.Component<any> {
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
      }
    }

    render() {
      return this.props.children;
    }
  }
);
