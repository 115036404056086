import ODataClientWrapper, {
  List, ListWithStatusInv
} from "../../../../shared/utils/odataClient.wrapper";
import DocumentFlow, { DocFlowFilterTabs, DocumentFlowIdGenerator } from "../../../domains/documentFlow/documentFlow";
import { InvoiceStatus, NewNetworkInvoiceStatusDTO } from "./invoiceStatus";
import {
  DocumentFlowStage,
  NetworkDocumentFlowStageDTO
} from "../../../domains/documentFlow/documentFlowStage";
import { ListResponse } from "../../../../contracts/domains/contract/contract.service";
import { Query } from "../../../../shared/queryable/query";
import { isEmpty, mergeRight } from "ramda";
import { newInvoicesQuery } from "./InvoiceQuery";
import { AxiosWrapper } from "../../../../shared/utils/axios.wrapper";
import { withSystemAlias } from "../../../../shared/domains/auth/authentication.service";
import { DocFlowItem } from "../../../domains/prpo/poItem";
import { AxiosRequestConfig } from "axios";
import { Invoice, InvoiceDTO } from "./Invoice";
import { UserInteractionType } from "../../../../shared/domains/user/userInteractionType";
import _ from 'lodash';

export class InvoiceService {
  static async fetch(
    query: Query = newInvoicesQuery(),
    config: { alias: string, docFlowType: DocFlowFilterTabs } | string = null
  ): Promise<ListResponse<Invoice>> {
    const q: Partial<Query> = {
      resource: "InvWrkFlwHdrSet"
    };

    let queryClone: Query = _.cloneDeep(query);

    // below code is to ensure chat and notes filter isnt set to SAP, also to filter based on chat and notes
    // we need to get full list of data then apply the filter
    if (queryClone.filters["chatFilter"]["selected"] || queryClone.filters["notesFilter"]["selected"]) {
      delete queryClone["rows"];
      delete queryClone["page"];
      queryClone.filters["chatFilter"]["selected"] = null;
      queryClone.filters["notesFilter"]["selected"] = null;
    };

    const odata = ODataClientWrapper.get().withSystemAlias(config['alias'] ? config['alias'] : config);
    const response: List<InvoiceDTO> = await odata.fromQInvoices(mergeRight(q, queryClone))
      .count()
      .run();
    let transformedOutput: List<Invoice> = response.map(Invoice.FromBackend);

    if (query.filters["chatFilter"]["selected"] || query.filters["notesFilter"]["selected"]) {
      let tempData: Invoice[] = transformedOutput.data;

      if (query.filters["chatFilter"]["selected"]) {
        const chatFilterSelected = query.filters["chatFilter"]["selected"][0]["id"];

        if (chatFilterSelected === "newMessages") {
          tempData = tempData.filter(docItem => docItem.CountOfUnreadComments > 0);
        }
        if (chatFilterSelected === "MessageAvailable") {
          tempData = tempData.filter(docItem => docItem.TotalCountOfComments > 0 && docItem.CountOfUnreadComments === 0);
        }
        if (chatFilterSelected === "noMessages") {
          tempData = tempData.filter(docItem => docItem.TotalCountOfComments === 0);
        };
      }

      if (query.filters["notesFilter"]["selected"]) {
        const notesFilterSelected = query.filters["notesFilter"]["selected"][0]["id"];

        if (notesFilterSelected === "newMessages") {
          tempData = tempData.filter(docItem => docItem.isThereAnyNewNotes === true);
        }
        if (notesFilterSelected === "MessageAvailable") {
          tempData = tempData.filter(docItem => docItem.IsNotesPresent === true && docItem.isThereAnyNewNotes === false);
        }
        if (notesFilterSelected === "noMessages") {
          tempData = tempData.filter(docItem => docItem.IsNotesPresent === false);
        };
      }

      let tempCount: number = tempData.length;
      let top: number = query.top;
      let skip: number = 0;
      if (query.page) { skip = (query.page * query.rows) };
      if (query.rows) {
        if (query.rows !== 10) top = query.rows;
      }
      tempData = tempData.slice(skip, skip + top);
      transformedOutput = new List(tempData, tempCount);
    };
    return transformedOutput;
  }


  static async fetchExcel(
    query: Query = newInvoicesQuery(),
    config: { alias: string, docFlowType: DocFlowFilterTabs } | string = null
  )
  // : Promise<ListResponse<Invoice>> 
  {
    const q: Partial<Query> = {
      resource: "InvWrkFlwExportSet"
    };
    const odata = ODataClientWrapper.get().withSystemAlias(config['alias'] ? config['alias'] : config);
    const response = await odata.fromQInvoicesExcel(mergeRight(q, query))
      .count()
      .run();
    // return response.map(Invoice.FromBackend);
    return response;
  }

  static async fetchStatus(
    query: Query = newInvoicesQuery(),
    config: { alias: string, docFlowType: DocFlowFilterTabs } | string = null
  ): Promise<ListResponse<InvoiceStatus>> {
    const q: Partial<Query> = {
      resource: "InvWrkFlwHdrSet"
    };    
    
    // below code is to ensure DocFlwOviewSet call isnt made with chat and notes filter to sap,
    // chat and notes filter was agreed to be made only for DocFlowHeadSet call, which BE handles
    const newQuery = _.cloneDeep(query);
    newQuery.filters.chatFilter && (newQuery.filters.chatFilter.selected = null);
    newQuery.filters.notesFilter && (newQuery.filters.notesFilter.selected = null);

    const odata = ODataClientWrapper.get().withSystemAlias(config['alias'] ? config['alias'] : config);
    const response: ListWithStatusInv<NewNetworkInvoiceStatusDTO> = await odata.fromQstatusInv(mergeRight(q, newQuery))
      .count()
      .run();
    return response.map(InvoiceStatus.FromBackend).reduceInv(response.data);
  }

  static async fetchStage(df: DocumentFlow): Promise<DocumentFlowStage> {
    const result: List<NetworkDocumentFlowStageDTO> = await ODataClientWrapper.get()
      .withSystemAlias(df.SystemAlias, true)
      .resource("ProgressSet")
      .run();

    return result.map(x =>
      DocumentFlowStage.FromBackend(DocumentFlowIdGenerator(df), x)
    ).data[0];
  }

  static async fecthParkingReasonCode(systemAlias: string): Promise<any> {
    const response = await ODataClientWrapper.get()
      .resource("InvParkRsnCodeSet")
      .withSystemAlias(systemAlias, true)
      .filter({field: "ParkReasonCode", value: 'NBR'})
      .and({field: "ParkRsnCodeDesc", value: 'DESCR'})
      .execute();
    return response.data.d.results.map((data)=>({'value': data.ParkReasonCode, 'label': `${data.ParkReasonCode} - ${data.ParkRsnCodeDesc}`}));
  }

  static async updateSpender(df: DocFlowItem): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + UserInteractionType.UpdatedSpenderRequestCockpit + "&query=IdSet";
    const isPrPresent = !isEmpty(df.PrNo);

    const body = isPrPresent
      ? {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToDocFlowItem: {
            results: [
              {
                IdNo: "D2",
                PrNo: df.PrNo,
                PrItem: df.PrItem,
                Requisitioner: df.Requisitioner
              }
            ]
          }
        }
      }
      : {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToDocFlowItem: {
            results: [
              {
                IdNo: "D2",
                PoNo: df.PoNo,
                PoItem: df.PoItem,
                Requisitioner: df.Requisitioner
              }
            ]
          }
        }
      };
    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async sendAttachment(formData): Promise<any> {
    const options: AxiosRequestConfig = { timeout: 15000 }
    const response = await AxiosWrapper.post(`/api/sapdata/GosUploadSet`, formData, options);
    return response.data;
  }
  static async checkPO(poNumber: string): Promise<any> {
    const path = `/api/CompetitiveBidding/GetPOApprovedState?poNumber=${poNumber}`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }
  static async closePO(df: DocFlowItem): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + UserInteractionType.ClosedPORequestCockpit + "&query=IdSet";
    const sendFinalInv = df.ClosePoInd === "2";
    const sendDci = df.ClosePoInd === "3";

    const body = sendFinalInv ?
      {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToPOItem: {
            results: [
              {
                IdNo: "D2",
                PurchaseOrdNo: df.PoNo,
                PurchaseOrdItemNo: df.PoItem,
                FinalInv: "X"
              }
            ]
          }
        }
      }
      : sendDci ?
        {
          results: {
            IdNo: "D2",
            DataChanged: "X",
            IdToPOItem: {
              results: [
                {
                  IdNo: "D2",
                  PurchaseOrdNo: df.PoNo,
                  PurchaseOrdItemNo: df.PoItem,
                  Dci: "X",
                }
              ]
            }
          }
        } : null;
    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async addToFavorite(
    df: DocumentFlow,
    PrItem: string,
    PoItem: string,
    setFavorite: boolean
  ): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + UserInteractionType.AddedRequestCockpitToFavorites + "&query=IdSet";

    const body = {
      results: {
        IdNo: "D1",
        DataChanged: "X",
        IdToDocFlowItem: {
          results: [
            {
              IdNo: "D1",
              PrNo: df.PurchaseReqNo,
              PoNo: df.PurchaseOrdNo,
              PrItem: PrItem,
              PoItem: PoItem,
              IsFavorite: setFavorite ? "X" : ""
            }
          ]
        }
      }
    };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async addToFavoriteHeaderLevel(
    df: DocumentFlow,
    setFavorite: boolean
  ): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + UserInteractionType.AddedRequestCockpitToFavorites + "&query=IdSet";

    const body = {
      results: {
        IdNo: "D1",
        DataChanged: "X",
        IdToDocFlowItem: {
          results: [
            {
              IdNo: "D1",
              PrNo: df.PurchaseReqNo,
              PoNo: df.PurchaseOrdNo,
              IsFavorite: setFavorite ? "X" : ""
            }
          ]
        }
      }
    };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async removeCbLink(
    cbId: string,
    df: Partial<DocumentFlow>
  ): Promise<boolean> {
    const path = `/api/CompetitiveBidding/RemoveCbAssociationWithEntity`;
    const body = {
      competitiveBiddingId: cbId,
      prId: {
        aribaValue: df?.AribaPR,
        sapValue: df?.PurchaseReqNo,
        systemAlias: df?.SystemAlias,
        InnerItems: []
      },
      poId: {
        aribaValue: df?.AribaPO,
        sapValue: df?.PurchaseOrdNo,
        systemAlias: df?.SystemAlias,
        InnerItems: []
      }
    };
    const result = await AxiosWrapper.post(path, body);
    return result.data;
  }

  static async getyBlockedInvoices(compCodes: string[]): Promise<any> {
    let path = `/api/sapdata/GetBlockedInvoices`;
    const response = await AxiosWrapper.get(path, {
      params: {
        insertChatMessages: true,
        companyCodes: compCodes
      }
    });
    return response.data;
  }
}
