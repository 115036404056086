import * as R from "ramda";
import {uniqBy} from "lodash";
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'
import {ContractFilterActionTypes, GetFiltersError, GetFiltersSuccess} from "../../actions/contractFilter.actions";
import {ContractFilterService} from "../../domains/filter.service";
import {ContractsStateStore} from "../../reducers";
import {ContractActionTypes} from "../../actions/contract.actions";
import {initialContractFilters} from "../../reducers/filters/contract.filters.reducer";
import {UserActionTypes} from "../../../shared/actions/user.actions";
import {RootStateStore} from "../../../application.reducers";
import {SharedStateStore} from "../../../shared/reducers";
import {defaultStrategicBuyer, User} from "../../../shared/domains/user/user";
import {TextFilter} from "../../../shared/domains/query/filter";
import {PataflagService} from "../../../shared/services/pataflag.service";
import {Features} from "../../../shared/domains/core/pataflag";

function* initFilters(): any {
  const user: User = yield select((state: RootStateStore) => state.shared.user.currentUser);
  const filters = R.mergeRight(initialContractFilters, user.filters.contracts);
  let validFilters = R.assoc('companies', R.filter((c: TextFilter) => !!c.id)(filters.companies), filters);
  if (R.either(R.isNil, R.isEmpty)(R.prop('validity', validFilters))) {
    validFilters = R.assoc('validity', initialContractFilters.validity, validFilters);
  }
  yield put({type: ContractActionTypes.NEW_FILTER, filters: validFilters});
}

function* fetch(): any {
  try {
    const result: any[] = yield(call(ContractFilterService.fetchAllFilters));
    const mapper = value => ({id: value.id, title: value.label });
    const keyEq = R.propEq('key');
    const companies = uniqBy(R.find(keyEq('CompanyCode'))(result).data.map(mapper), 'id');
    const pOrgs = R.find(keyEq('PurchOrganization'))(result).data.map(mapper);
    const pGroups = R.find(keyEq('PurchGroup'))(result).data.map(mapper);
    const docTypes = uniqBy(R.find(keyEq('DocumentType'))(result).data.map(mapper), 'id');
    yield put(new GetFiltersSuccess({
      companies,
      pOrgs,
      pGroups,
      docTypes
    }));
  } catch (error) {
    yield put(new GetFiltersError(error));
  }
}

function* deleteFilter(action) {
  const activeFilters = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.filters.contractFilters);
  const newActiveFilters = {
    ...activeFilters,
    [action.filter.kind]: action.filter.id ? activeFilters[action.filter.kind].filter(x => x.id !== action.filter.id) : null
  };
  yield all([
    put({type: ContractActionTypes.NEW_FILTER, filters: newActiveFilters}),
    put({type: UserActionTypes.SAVE_CONTRACT_FILTERS, filters: newActiveFilters})
  ]);
}

function* resetFilters() {
  const user: User = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
  let newResetFilters = initialContractFilters;
  if (user.npdBuyer) {
    newResetFilters = R.assoc('strategicBuyers', [defaultStrategicBuyer(user)], newResetFilters);
  }
  yield all([
    put({type: ContractActionTypes.NEW_FILTER, filters: newResetFilters}),
    put({type: UserActionTypes.SAVE_CONTRACT_FILTERS, filters: newResetFilters})
  ]);
}

function* watchInitFilters() {
  yield takeLatest(ContractFilterActionTypes.INIT_FILTERS, initFilters);
}

function* watchGetFilters() {
  yield takeLatest(ContractFilterActionTypes.GET_FILTERS, fetch);
}

function* watchDeleteFilters() {
  yield takeLatest(ContractFilterActionTypes.DELETE_FILTER, deleteFilter);
}

function* watchResetFilters() {
  yield takeLatest(ContractFilterActionTypes.RESET_FILTERS, resetFilters);
}

export default function* contractFilterSaga() {
  yield all([
    fork(watchInitFilters),
    fork(watchGetFilters),
    fork(watchDeleteFilters),
    fork(watchResetFilters),
  ])
};
