import React, {useState, useEffect} from 'react';
import * as Yup from "yup";
import {isNil} from "ramda";
import {useDispatch, useSelector} from "react-redux";
import {StyledDialogTitle} from "../../../../components/layout/styles";
import {ContextForm} from "../../../../components/form";
import {SavePreferredCompany} from "../../../../actions/user.actions";
import {RootStateStore} from "../../../../../application.reducers";
import {UserState} from "../../../../reducers/user.reducer";
import {UserSettingsContent} from "./userSettings.component";
import {FeaturesContext} from "../../../../domains/core/pataflag.context";
import {StyledUserSettingsDialog, UserSettingsForm} from "./userSettings.styles";
import {IUserRole} from "../../../../domains/user/user";
import {UserService} from "../../../../domains/user/user.service";

export interface IUserSettingsForm {
  companyCode: string;
  companyName: string;
  buyers: {code: string, name:string}[],
  clusters: {code: string, name: string}[],
  zones: {code: string, name: string}[],
  markets: {name: string}[],
  spendCategoryCodes: {id?: number, code: string, name: string}[],
  companyCodes: {code: string, name: string}[],
  plants: {code: string, name: string}[];
  businesses: {code: string, name: string}[];
  zone: number;
}

interface IUserPreferredCompanyProps {
  onClose?: Function;
}

export function UserSettingsPopup(props: IUserPreferredCompanyProps) {
  const dispatch = useDispatch();
  const user: UserState = useSelector((state: RootStateStore) => state.shared.user);
  const {hasFeature} = React.useContext(FeaturesContext);
  const currentUser = user.currentUser;
  const userSettings: IUserSettingsForm = {
    companyCode: currentUser.userPreferredCompanyCode,
    companyName: currentUser.userPreferredCompanyName,
    buyers: currentUser.profileSettings?.contractSpecificProfile?.buyers,
    clusters: currentUser.profileSettings?.contractSpecificProfile?.clusters,
    zones: currentUser.profileSettings?.contractSpecificProfile?.zones,
    markets: currentUser.profileSettings?.contractSpecificProfile?.markets,
    spendCategoryCodes: currentUser.profileSettings?.contractSpecificProfile?.spendCategoryCodes,
    companyCodes: currentUser.profileSettings?.contractSpecificProfile?.companyCodes,
    plants: currentUser.profileSettings?.contractSpecificProfile?.plants,
    businesses: currentUser.profileSettings?.contractSpecificProfile?.businesses,
    zone: currentUser.selectedZoneId,
  };
  let schema = Yup.object().shape({
    companyCode: Yup.string().nullable().required(),
    companyName: Yup.string().nullable().required()
  });

  const codeNameMapper = el => ({label: el.name, value: el.code});
  const [selectedBuyers, setSelectedBuyers] = useState(userSettings.buyers?.map(codeNameMapper));
  const [selectedClusters, setSelectedClusters] = useState(userSettings.clusters?.map(codeNameMapper));
  const [selectedZones, setSelectedZones] = useState(userSettings.zones?.map(codeNameMapper));
  const [selectedMarkets, setSelectedMarkets] = useState(userSettings.markets?.map(m => ({label: m, value: m})));
  const [selectedCategories, setSelectedCategories] = useState(userSettings.spendCategoryCodes?.map(codeNameMapper));
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState(userSettings.companyCodes?.map(codeNameMapper));
  const [selectedPlants, setSelectedPlants] = useState(userSettings.plants?.map(codeNameMapper));
  const [selectedBusinesses, setSelectedBusinesses] = useState(userSettings.businesses?.map(codeNameMapper));

  const [prpoSettings, setPrpoSettings] = useState({
    role: {options: [], selected: null},
    markets: currentUser.filters.userScope.markets,
    companyCodes: currentUser.filters.userScope.companies,
    spendCatL1s: currentUser.filters.userScope.spendCatL1s,
    spendCatL2s: currentUser.filters.userScope.spendCatL2s,
    spendCatL3s: currentUser.filters.userScope.spendCatL3s,
    pslExpiryDays: currentUser.pslExpiryDays
  });

  useEffect(() => {
    const roleOptions = [{value: IUserRole.AT_MARKET, label: "At Market Buyer"},
      {value: IUserRole.ABOVE_MARKET, label: "Above Market Buyer"},
      {value: IUserRole.REQUESTOR, label: "Spender"},
      {value: IUserRole.FACTORY_ANALYST, label: "Factory"},
      {value: IUserRole.PPA_AT_MARKET, label: "PPA At Market"},
      {value: IUserRole.PPA_ABOVE_MARKET, label: "PPA Above Market"},
      {value: IUserRole.OPERATIONAL_BUYER, label: "Operational Buyer"}];
    setPrpoSettings({
      ...prpoSettings,
      role: {
        options: roleOptions,
        selected: null
      }
    });
  }, []);
  const codeNameUnmapper = el => ({code: el.value, name: el.label});
  const handleSubmit = ((value, {setSubmitting}) => {
    setSubmitting(false);
    dispatch(new SavePreferredCompany({
      ...value,
      userPreferredCompanyCode: value.companyCode,
      userPreferredCompanyName: value.companyName,
      userPreferredPslPersona: !isNil(prpoSettings.role.selected?.value) ? prpoSettings.role.selected.value : null,
      pslExpiryDays: !isNil(prpoSettings.pslExpiryDays) ? prpoSettings.pslExpiryDays : null,
      selectedZoneId: value.zone,
      dateTimeFormat: currentUser.profileSettings.dateTimeFormat,
      timeZone: currentUser.profileSettings.timeZone,
      selectedZone: {id: value.zone},
      contractSpecificProfile: {
        buyers: selectedBuyers?.map(codeNameUnmapper),
        clusters: selectedClusters?.map(codeNameUnmapper),
        zones: selectedZones?.map(codeNameUnmapper),
        markets: selectedMarkets?.map(codeNameUnmapper),
        spendCategoryCodes: selectedCategories?.map(codeNameUnmapper),
        companyCodes: selectedCompanyCodes?.map(codeNameUnmapper),
        plants: selectedPlants?.map(codeNameUnmapper),
        businesses: selectedBusinesses?.map(codeNameUnmapper)
      }
    }, () => {
      props.onClose();
      setTimeout(()=>window.location.reload(), 2000);
    }));
    //Reset CB list company filter
    UserService.saveCbFilters({
      ...user.currentUser.filters.competitiveBidding,
      companies: [{id: value.companyCode, title: value.companyName}]
    });
    //Reset DocFlow list company filter
    UserService.saveDocflowFilters({
      ...user.currentUser.filters.documentFlow,
      prCreationDateRange: null,
      poCreationDateRange: null,
      deliveryDateRange: null,
      companies: [{id: value.companyCode, title: value.companyName}]
    });
    //We persist UserScope
    UserService.saveUserScopeFilters({
      ...user.currentUser.filters.userScope,
      markets: prpoSettings.markets,
      companies: prpoSettings.companyCodes,
      spendCatL1s: prpoSettings.spendCatL1s,
      spendCatL2s: prpoSettings.spendCatL2s,
      spendCatL3s: prpoSettings.spendCatL3s,
    });
  });
  return (
    <StyledUserSettingsDialog open={true} maxWidth={"xs"} scroll={'paper'} fullWidth={true}>
      <ContextForm validationSchema={schema}
                   initialValues={userSettings}
                   onSubmit={handleSubmit}>
        <UserSettingsForm>
          <StyledDialogTitle>My profile</StyledDialogTitle>
          <UserSettingsContent
            selectedBuyers={selectedBuyers}
            setBuyers={setSelectedBuyers}
            selectedClusters={selectedClusters}
            setClusters={setSelectedClusters}
            selectedZones={selectedZones}
            setZones={setSelectedZones}
            selectedMarkets={selectedMarkets}
            setMarkets={setSelectedMarkets}
            selectedPlants={selectedPlants}
            setPlants={setSelectedPlants}
            selectedCategories={selectedCategories}
            setCategories={setSelectedCategories}
            selectedCompanyCodes={selectedCompanyCodes}
            setCompanyCodes={setSelectedCompanyCodes}
            selectedBusinesses={selectedBusinesses}
            setBusinesses={setSelectedBusinesses}
            prpoSettings={prpoSettings}
            setPrpoSettings={setPrpoSettings}
            user={user}
            onClose={props.onClose}
          />
        </UserSettingsForm>
      </ContextForm>
    </StyledUserSettingsDialog>
  )
}
