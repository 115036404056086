import { PRSubstitutionRequest } from "./prSubstitution";
import ODataClientWrapper from "../../../../shared/utils/odataClient.wrapper";
import {
  AxiosWrapper,
} from "../../../../shared/utils/axios.wrapper";
import { prSubstitutionQuery, ODataQuery, PartialODataQuery } from "./query";
import { SimpleODataFilter } from "../../../../../lib/odata";
import { withSystemAlias } from "src/app/shared/domains/auth/authentication.service";
import { PataflagService } from "src/app/shared/services/pataflag.service";
import { Features } from "src/app/shared/domains/core/pataflag";
import { AxiosRequestHeaders } from "axios";

export interface ListResponse<T> {
  count: number;
  data: T[];
}

export class PRSubstitutionService {
  public static fetchUsersBySearchTerm(
    term: string = "",
    userData: any,
    callback
  ) {
    if (term.length < 4) return Promise.resolve([]);
    return AxiosWrapper.get("/api/sapdata/Get", {
      params: {
        query: `PRValueHelpSet?$filter=(Type eq '${userData.type}') and (Query eq '${term}') and (User eq '${userData.UserID}') and (Position eq '${userData.Delegator}')`
      }
    }).then(res => {
      callback(res.data.d.results);
    });
  }

  private static async fetchAll(
    query: ODataQuery, zone
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    const zoneTab = PataflagService.getInstance().flag(Features.SUBSTITUTION_ZONE_TAB);
    query.top = 10;
    const response = zoneTab ? await ODataClientWrapper.get().withSystemAlias(zone)
      .fromQuery(query)
      .count()
      .execute() : await ODataClientWrapper.get()
        .fromQuery(query)
        .count()
        .execute();

    if (
      !response.data ||
      !response.data.d ||
      !Array.isArray(response.data.d.results)
    ) {
      throw new Error(
        "Backend malformed json response:\n\n" + JSON.stringify(response)
      );
    }

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(PRSubstitutionRequest.FromBackend)
    };
  }

  private static async fetchAllCO(
    query: ODataQuery, zone:string
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    const resource = query.resource;
    console.log("query: ", query);
    const response = await ODataClientWrapper.get()
    .withSystemAlias(zone)
      .fromQuery(query)
      .count()
      .execute();

    if (
      !response.data ||
      !response.data.d ||
      !Array.isArray(response.data.d.results)
    ) {
      throw new Error(
        "Backend malformed json response:\n\n" + JSON.stringify(response)
      );
    }

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(PRSubstitutionRequest.FromBackend)
    };
  }

  private static async fetchAllST(
    query: ODataQuery
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    const resource = query.resource;
    const response = await ODataClientWrapper.get()
      .fromQuery(query)
      .count()
      .execute();

    if (
      !response.data ||
      !response.data.d ||
      !Array.isArray(response.data.d.results)
    ) {
      throw new Error(
        "Backend malformed json response:\n\n" + JSON.stringify(response)
      );
    }

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(PRSubstitutionRequest.FromBackend)
    };
  }

  static async fetch(
    query: ODataQuery = prSubstitutionQuery(), zone
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return PRSubstitutionService.fetchPRs(query, zone);
  }

  static async fetchCostObject(
    query: ODataQuery = prSubstitutionQuery(), zone:string
  ): Promise<ListResponse<any>> {
    return PRSubstitutionService.fetchCO(query,zone);
  }
  static async fetchSubstitutee(
    query: ODataQuery = prSubstitutionQuery()
  ): Promise<ListResponse<any>> {
    return PRSubstitutionService.fetchST(query);
  }

  private static async fetchPRs(
    query: ODataQuery = prSubstitutionQuery(), zone
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return await PRSubstitutionService.fetchAll(query, zone);
  }
  private static async fetchCO(
    query: ODataQuery = prSubstitutionQuery(), zone:string
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return await PRSubstitutionService.fetchAllCO(query, zone);
  }
  private static async fetchST(
    query: ODataQuery = prSubstitutionQuery()
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return await PRSubstitutionService.fetchAllST(query);
  }

  static async fetchDetail(
    query: any
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return await PRSubstitutionService.fetchPRDetailAll(query);
  }

  private static async fetchPRDetailAll(query: any): Promise<any> {
    const response = await ODataClientWrapper.get()
      .resource(
        "PRLines(SapPR='" +
        encodeURIComponent(query.SapPR) +
        "',ItemNo='" +
        encodeURIComponent(query.ItemNo) +
        "')"
      )
      .execute();

    return response.data.d;
  }

  static async fetchAccount(
    query: any
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return PRSubstitutionService.fetchPRAccount(query);
  }

  private static async fetchPRAccount(query: any): Promise<any> {
    const response = await ODataClientWrapper.get()
      .resource(
        "PRLines(SapPR='" +
        encodeURIComponent(query.SapPR) +
        "',ItemNo='" +
        encodeURIComponent(query.ItemNo) +
        "')/ToAccAssignment"
      )
      .execute();
    if (
      !response.data ||
      !response.data.d ||
      !Array.isArray(response.data.d.results)
    ) {
      throw new Error(
        "Backend malformed json response:\n\n" + JSON.stringify(response)
      );
    }
    return response.data.d.results;
  }

  static async fetchPricing(
    query: any
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return PRSubstitutionService.fetchPRPricing(query);
  }

  private static async fetchPRPricing(query: any): Promise<any> {
    const response = await ODataClientWrapper.get()
      .resource(
        "PRLines(SapPR='" +
        encodeURIComponent(query.SapPR) +
        "',ItemNo='" +
        encodeURIComponent(query.ItemNo) +
        "')/ToPricingCondition"
      )
      .execute();

    if (
      !response.data ||
      !response.data.d ||
      !Array.isArray(response.data.d.results)
    ) {
      throw new Error(
        "Backend malformed json response:\n\n" + JSON.stringify(response)
      );
    }
    return response.data.d.results;
  }

  static async fetchApprovalIndicator(
    query: any
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return PRSubstitutionService.fetchPRApprovalIndicator(query);
  }

  /**
   * @name fetchPRApprovalIndicator
   * @param query
   * @method GET
   * @description fetch Approval Indicator for the API
   */
  private static async fetchPRApprovalIndicator(query: any): Promise<any> {
    const response = await ODataClientWrapper.get()
      .resource(
        "PRApprovals_BAs(Aribapr='" +
        encodeURIComponent(query.Aribapr) +
        "',SapPR='" +
        encodeURIComponent(query.SapPR) +
        "',ItemNo='" +
        encodeURIComponent(query.ItemNo) +
        "',CompCode='" +
        encodeURIComponent(query.CompCode) +
        "',PurchOrg='" +
        encodeURIComponent(query.PurchOrg) +
        "',RelStrategy='" +
        encodeURIComponent(query.RelStrategy) +
        "',RelGroup='" +
        encodeURIComponent(query.RelGroup) +
        "',ApprovalLevel='" +
        encodeURIComponent(query.ApprovalLevel) +
        "')"
      )
      .execute();

    if (!response.data || !response.data.d) {
      throw new Error(
        "Backend malformed json response:\n\n" + JSON.stringify(response)
      );
    }
    return response.data.d.results;
  }

  /**
   * @name substitutionService
   * @param PRDetail
   * @description Post the data for approval of PR
   */
  static async substitutionServiceAddition(data: any): Promise<any> {
    const massDeletion = PataflagService.getInstance().flag(Features.SUBSTITUTION_MASS_DELETION);
    const zoneTab = PataflagService.getInstance().flag(Features.SUBSTITUTION_ZONE_TAB);
    const sysAlias = data.zone;

    let body = {
      Active: data.Active,
      AppType: data.AppType,
      CoPg: data.CoPg,
      Delegator: `${data.Delegator}`,
      SubEDate: data.SubEDate,
      SubSDtae: data.SubSDtae,
      Substitutee: data.Substitutee,
      UserID: data.UserID,
      IdNo: "PS"
    };

    !massDeletion && delete body.IdNo;
    const headers: any = zoneTab ? {
      headers: withSystemAlias(sysAlias, {}, true)
    } : {};
    //
    let response = await AxiosWrapper.put(
      `/api/sapdata/Put?query=PRSubstitutionSet(AppType='${data.AppType}',Substitutee='${data.Substitutee}',Delegator='${data.Delegator}',CoPg='',UserID='${data.UserID}')`,
      body, headers
    );
    return response.data;
  }
  /**
  * @name substitutionService
  * @param PRDetail
  * @description Post the data for approval of PR
  */

  static async substitutionServiceDeletion(data: any): Promise<any> {
    const sysAlias = data[0].SystemAlias;
    let body = {
      results: {
        IdNo: "PS",
        DataChanged: "X",
        IdtoPRSubstitution: {
          results: data.map(el => ({
            Active: el.Active,
            AppType: el.AppType,
            CoPg: el.CoPg,
            Delegator: `${el.Delegator}`,
            SubEDate: el.SubEDate,
            SubSDtae: el.SubSDtae,
            Substitutee: el.Substitutee,
            UserID: el.UserID
          }))
        }
      }
    };
    const path = "/api/sapdata/PostFromBody?query=IdSet";

    let response = await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(sysAlias, {}, true)
    });
  }

  private static emailBody(body: any): any {
    return body;
  }

  /**
   * function to fetch PR line items
   * @param query
   * @returns {Promise<ListResponse<PurchaseRequest>>}
   */
  static async fetchLines(
    query: any
  ): Promise<ListResponse<PRSubstitutionRequest>> {
    return await PRSubstitutionService.fetchPRLinesAll(query);
  }

  private static async fetchPRLinesAll(query: any): Promise<any> {
    const resource = "PRApproversSet";

    /*Loop for filter array*/
    let filterArray = [];
    if (query.length > 0) {
      query.map((item: any, index: number) => {
        let oDataFilterObj: SimpleODataFilter = {
          field: item.field,
          value: item.value
        };
        filterArray.push(oDataFilterObj);
      });
    }

    /*Merge all variables*/
    const favoriteQuery: PartialODataQuery = {
      resource,
      filters: filterArray,
      orderBy: []
    };

    const response = await ODataClientWrapper.get()
      .fromQuery(favoriteQuery)
      .count()
      .execute();

    if (
      !response.data ||
      !response.data.d ||
      !Array.isArray(response.data.d.results)
    ) {
      throw new Error(
        "Backend malformed json response:\n\n" + JSON.stringify(response)
      );
    }

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results
    };
  }
}
