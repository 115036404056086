import {DateTime} from "luxon";
import {IAction} from "../../../shared/domains/core/actions";
import {ContractFilterActionTypes} from "../../actions/contractFilter.actions";
import {assoc, defaultTo, merge, filter} from "ramda";
import {ContractActionTypes} from "../../actions/contract.actions";
import {TextFilter} from "../../../shared/domains/query/filter";
import {IContractFilters} from "../../../shared/domains/user/user";
import {DatalakeContract} from "../../domains/contract/contract";
import {error} from "../../../shared/reducers/utils";

export interface ContractFiltersState {
  companies: TextFilter[],
  pOrgs: TextFilter[],
  pGroups: TextFilter[],
  docTypes: TextFilter[],
  mGroups: TextFilter[],
  materials: TextFilter[],
  plants: TextFilter[],
  strategicBuyers: TextFilter[],
  datalakeContracts: DatalakeContract[],
  contractFilters: IContractFilters,
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialContractFilters = {
  companies: [],
  pOrgs: [],
  pGroups: [],
  docTypes: [],
  mGroups: [],
  materials: [],
  plants: [],
  strategicBuyers: [],
  validity: DateTime.local().minus({years: 2}).toFormat('yyyyMMdd'),
  expiryRange: [],
  datalakeContracts: []
};

export const initialFiltersState: ContractFiltersState = {
  companies: [],
  pOrgs: [],
  pGroups: [],
  docTypes: [],
  mGroups: [],
  materials: [],
  plants: [],
  strategicBuyers: [],
  datalakeContracts: [],
  contractFilters: initialContractFilters,
  loading: false,
  loaded: false,
  error: null,
};

const loading = {loading: true, loaded: false, error: null};

export function ContractFiltersReducer(state: ContractFiltersState = initialFiltersState, action: IAction<ContractFilterActionTypes | ContractActionTypes>): ContractFiltersState {
  switch (action.type) {

    case ContractFilterActionTypes.GET_FILTERS:
      return merge(state, loading);
    case ContractFilterActionTypes.GET_FILTERS_FAILURE:
      return error(action.error)(state);
    case ContractFilterActionTypes.GET_FILTERS_SUCCESS:
      const filtersCompanies = state.contractFilters.companies.map((c: TextFilter) => {
        const com = action.companies.find((co: TextFilter) => c && c.id === co.id);
        return com ? com : c;
      });
      return {
        ...state,
        companies: defaultTo(state.companies, action.companies),
        pOrgs: defaultTo(state.pOrgs, action.pOrgs),
        pGroups: defaultTo(state.pGroups, action.pGroups),
        docTypes: defaultTo(state.docTypes, action.docTypes),
        mGroups: defaultTo(state.mGroups, action.mGroups),
        plants: defaultTo(state.plants, action.plants),
        strategicBuyers: defaultTo(state.strategicBuyers, action.strategicBuyers),
        contractFilters: assoc('companies', filtersCompanies, state.contractFilters),
        loading: false,
        loaded: true
      };
    case ContractActionTypes.NEW_FILTER:
      return {
        ...state,
        contractFilters: {
          companies: filter((f: TextFilter) => !!f.id, action.filters.companies),
          pOrgs: filter((f: TextFilter) => !!f.id, action.filters.pOrgs),
          pGroups: filter((f: TextFilter) => !!f.id, action.filters.pGroups),
          docTypes: filter((f: TextFilter) => !!f.id, action.filters.docTypes),
          mGroups: filter((f: TextFilter) => !!f.id, action.filters.mGroups),
          materials: filter((f: TextFilter) => !!f.id, action.filters.materials),
          plants: filter((f: TextFilter) => !!f.id, action.filters.plants),
          strategicBuyers: filter((f: TextFilter) => !!f.id, action.filters.strategicBuyers),
          validity: action.filters.validity,
        }
      };
    case ContractActionTypes.FETCH_DATALAKE_CONTRACTS:
      return {...state, datalakeContracts: []};
    case ContractActionTypes.FETCH_DATALAKE_CONTRACTS_SUCCESS:
      return {...state, datalakeContracts: action.contracts};
    case ContractFilterActionTypes.RESET_FILTERS:
      return assoc('contractFilters', initialContractFilters, state);
    default:
      return state;

  }
}
