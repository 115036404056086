import {PurchaseRequestReducers, PurchaseRequestState} from "./purchaseRequest.reducers";
import {combineReducers, Reducer} from "redux";
import { PAFiltersReducer, PAFiltersState } from "./filter/pa.filters.reducer";

export interface procurementApprovalsStateStore {
    PurchaseRequest : PurchaseRequestState,
    filters: PAFiltersState,
}

export const procurementApprovalsCombinedReducers: Reducer<procurementApprovalsStateStore> = combineReducers({
    PurchaseRequest : PurchaseRequestReducers ,
    filters: PAFiltersReducer,
});
