import styled from "styled-components";

export const Landing = styled.div`
  background: #F2F4F8;
  height: calc(100% - 65px);
`;

export const LandingHead = styled.div`
  font-size: 18px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .welcome {
    font-size: 30px;
  }
  .name {
    color: #2689EB;
  }
  img {
    background: #dcd9d9;
    border-radius: 50%;
    width: 80px;
  }
`;

export const LandingContainer = styled.div`
  background: inherit;
`;

export const LandingContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;

export const Tile = styled.div`
  border-radius: 5px;
  height: 240px;
  margin: 0 10px;
  padding: 0;
  width: 280px;
  position: relative;
`;

export const TileInner = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  height: 210px;
  margin: 0;
  padding: 0;
  width: 100%;
  :hover .front {
    transform: rotateY(-180deg);
  }
  :hover .back {
    transform: rotateY(0);
  }
`;

export const Card = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 220px;
  padding: 20px;
  margin: 0;
  cursor: pointer;
  backface-visibility: hidden;
  transition: transform 0.3s;
  justify-content: flex-end;
  &.front {
    background-color: #F19001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      color: #fff;
      margin-top: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  &.back {
    background-color: #d8d7df;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotateY(-180deg);
    .title {
      color: #000;
      margin-top: 0;
      text-transform: uppercase;
    }
    .desc {
      color: #63666a;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .link {
      color: #702082;
      display: flex;
      align-items: center;
      align-self: flex-end;
      margin-bottom: 0;
      svg {
        font-size: 16px;
      }
    }
  }
`;

export const LandingIcon = styled.div`
  background: #fff;
  border-radius: 50%;
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  && .MuiIcon-root {
    color: #F19001;
    font-size: 40px;
  }
`;
