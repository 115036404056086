import {
  Action,
  AzureErrorAction,
  DownloadCompletedAction,
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../shared/domains/core/actions";
import {IPslVendorWithMaterials, ISupplier, SupplierFilterTabs, SupplierTabs, VendorInfo} from "../domains/psl";
import { SuccessNotificationAction } from "../../shared/domains/core/notifications";
import { Query } from "../../shared/queryable/query";
import { IUserRole } from "../../shared/domains/user/user";
import {IMaterial} from "../domains/pslMaterial";

export enum PSLTypes {
  SHOW_EMPTY_DATA = 'SHOW_EMPTY_DATA',
  FETCH_PSL = "FETCH_PSL",
  FETCH_PSL_REQUEST = "FETCH_PSL_REQUEST",
  FETCH_PSL_REQUEST_SUCCESS = "FETCH_PSL_REQUEST_SUCCESS",
  FETCH_PSL_REQUEST_FAILURE = "FETCH_PSL_REQUEST_FAILURE",

  FETCH_PSL_MATERIAL_REQUEST = "FETCH_PSL_MATERIAL_REQUEST",
  FETCH_PSL_MATERIAL_REQUEST_SUCCESS = "FETCH_PSL_MATERIAL_REQUEST_SUCCESS",
  FETCH_PSL_MATERIAL_REQUEST_FAILURE = "FETCH_PSL_MATERIAL_REQUEST_FAILURE",

  SAVE_PSL = "SAVE_PSL",
  SAVE_PSL_FROM_FILE = "SAVE_PSL_FROM_FILE",
  SAVE_PSL_SUCCESS = "SAVE_PSL_SUCCESS",
  SAVE_PSL_FAILURE = "SAVE_PSL_FAILURE",

  UPDATE_PSL = "UPDATE_PSL",
  UPDATE_PSL_SUCCESS = "UPDATE_PSL_SUCCESS",
  UPDATE_PSL_FAILURE = "UPDATE_PSL_FAILURE",
  UPDATE_FAVOURITE = "UPDATE_FAVOURITE",
  UPDATE_FAVOURITE_SUCCESS = "UPDATE_FAVOURITE_SUCCESS",

  LOCALIZE_PSL_MATERIALS = "LOCALIZE_PSL_MATERIALS",
  LOCALIZE_PSL_SUCCESS = "LOCALIZE_PSL_SUCCESS",
  LOCALIZE_PSL_FAILURE = "LOCALIZE_PSL_FAILURE",

  REACTIVATE_PSL = "REACTIVATE_PSL",
  REACTIVATE_PSL_AND_FIELDS = "REACTIVATE_PSL_AND_FIELDS",
  REACTIVATE_PSL_SUCCESS = "REACTIVATE_PSL_SUCCESS",
  REACTIVATE_PSL_FAILURE = "REACTIVATE_PSL_FAILURE",

  FETCH_SINGLE_PSL = "FETCH_SINGLE_PSL",
  FETCH_SINGLE_PSL_SUCCESS = "FETCH_SINGLE_PSL_SUCCESS",
  FETCH_SINGLE_PSL_FAILURE = "FETCH_SINGLE_PSL_FAILURE",

  CHECK_MATERIALS_FOR_DUPLICATES = "CHECK_MATERIALS_FOR_DUPLICATES",
  CHECK_MATERIALS_FOR_DUPLICATES_FAILURE = "CHECK_MATERIALS_FOR_DUPLICATES_FAILURE",

  UPDATE_BULK_MATERIALS = "UPDATE_BULK_MATERIALS",
  UPDATE_MATERIALS_SUCCESS = "UPDATE_MATERIALS_SUCCESS",
  UPDATE_MATERIALS_FAILURE = "UPDATE_MATERIALS_FAILURE",

  ADD_MATERIALS = "ADD_MATERIALS",
  ADD_MATERIALS_FROM_FILE = "ADD_MATERIALS_FROM_FILE",
  ADD_MATERIALS_SUCCESS = "ADD_MATERIALS_SUCCESS",
  ADD_MATERIALS_FAILURE = "ADD_MATERIALS_FAILURE",

  FETCH_SINGLE_MATERIAL = "FETCH_SINGLE_MATERIAL",
  FETCH_SINGLE_MATERIAL_SUCCESS = "FETCH_SINGLE_MATERIAL_SUCCESS",
  FETCH_SINGLE_MATERIAL_FAILURE = "FETCH_SINGLE_MATERIAL_FAILURE",

  FETCH_MATERIALS = "FETCH_MATERIALS",
  FETCH_MATERIALS_SUCCESS = "FETCH_MATERIALS_SUCCESS",
  FETCH_MATERIALS_FAILURE = "FETCH_MATERIALS_FAILURE",

  IMPORT_PSL = "IMPORT_PSL",
  IMPORT_PSL_SUCCESS = "IMPORT_PSL_SUCCESS",
  IMPORT_PSL_FAILURE = "IMPORT_PSL_FAILURE",
  IMPORT_PSL_TIMEOUT = "IMPORT_PSL_TIMEOUT",
  RESET_PSL_IMPORT_ERROR = "RESET_PSL_IMPORT_ERROR",

  MATERIALS_FROM_FILE_FAILURE = "MATERIALS_FROM_FILE_FAILURE",
  RESET_MATERIALS_FROM_FILE_IMPORT_ERROR = "RESET_MATERIALS_FROM_FILE_IMPORT_ERROR",

  EXPORT_PSL_TEMPLATE = "EXPORT_PSL_TEMPLATE",
  EXPORT_PSL_TEMPLATE_SUCCESS = "EXPORT_PSL_TEMPLATE_SUCCESS",
  EXPORT_PSL_TEMPLATE_FAILURE = "EXPORT_PSL_TEMPLATE_FAILURE",

  EXPORT_PSL_LIST_DATA = "EXPORT_PSL_LIST_DATA",
  EXPORT_PSL_LIST_DATA_SUCCESS = "EXPORT_PSL_LIST_DATA_SUCCESS",
  EXPORT_PSL_LIST_DATA_FAILURE = "EXPORT_PSL_LIST_DATA_FAILURE",
  EXPORT_PSL_LIST_DATA_BY_EMAIL = "EXPORT_PSL_LIST_DATA_BY_EMAIL",
  EXPORT_PSL_LIST_DATA_SUCCESS_BY_EMAIL = "EXPORT_PSL_LIST_DATA_SUCCESS_BY_EMAIL",
  EXPORT_PSL_LIST_DATA_FAILURE_BY_EMAIL = "EXPORT_PSL_LIST_DATA_FAILURE_BY_EMAIL",

  APPROVE_PSLS = "APPROVE_PSLS",
  APPROVE_PSLS_SUCCESS = "APPROVE_PSLS_SUCCESS",
  APPROVE_PSLS_FAILURE = "APPROVE_PSLS_SUCCESS",

  REJECT_PSLS = "REJECT_PSLS",
  REJECT_PSLS_SUCCESS = "REJECT_PSLS_SUCCESS",
  REJECT_PSLS_FAILURE = "REJECT_PSLS_FAILURE",

  SAVE_PSL_APPROVERS_FILTER = "SAVE_PSL_APPROVERS_FILTER",
  SAVE_PSL_APPROVERS_FILTER_SUCCESS = "SAVE_PSL_APPROVERS_FILTER_SUCCESS",
  SAVE_PSL_APPROVERS_FILTER_FAILURE = "SAVE_PSL_APPROVERS_FILTER_FAILURE",

  DELETE_PSLS = "DELETE_PSLS",
  DELETE_PSLS_SUCCESS = "DELETE_PSLS_SUCCESS",
  DELETE_PSLS_FAILURE = "DELETE_PSLS_FAILURE",

  DELETE_MATERIAL_PSLS = "DELETE_MATERIAL_PSLS",
  DELETE_MATERIAL_PSLS_SUCCESS = "DELETE_MATERIAL_PSLS_SUCCESS",
  DELETE_MATERIAL_PSLS_FAILURE = "DELETE_MATERIAL_PSLS_FAILURE",

  ADD_PSL_APPROVER_FAILURE = "ADD_PSL_APPROVER_FAILURE",
  ADD_PSL_APPROVER_PERMISSION_FAILURE = "ADD_PSL_APPROVER_PERMISSION_FAILURE",

  UPDATE_LIST_TABS = "UPDATE_LIST_TABS",
  PSL_AND_MATERIALS_SEARCH = "PSL_AND_MATERIALS_SEARCH",

  CREATE_PSL_VENDOR_WITH_MATERIALS = "CREATE_PSL_VENDOR_WITH_MATERIALS",
  CREATE_PSL_VENDOR_WITH_MATERIALS_SUCCESS = "CREATE_PSL_VENDOR_WITH_MATERIALS_SUCCESS",
  CREATE_PSL_VENDOR_WITH_MATERIALS_FAILURE = "CREATE_PSL_VENDOR_WITH_MATERIALS_FAILURE",

  UPDATE_PSL_VENDOR_WITH_MATERIALS = "UPDATE_PSL_VENDOR_WITH_MATERIALS",
  UPDATE_PSL_VENDOR_WITH_MATERIALS_SUCCESS = "UPDATE_PSL_VENDOR_WITH_MATERIALS_SUCCESS",
  UPDATE_PSL_VENDOR_WITH_MATERIALS_FAILURE = "UPDATE_PSL_VENDOR_WITH_MATERIALS_FAILURE",

  GET_VENDOR_INFO = "GET_VENDOR_INFO",
  SAVE_VENDOR_INFO = "SAVE_VENDOR_INFO",
  TOGGLE_VENDOR_LOADING = "TOGGLE_VENDOR_LOADING"
}

export class PSLAndMaterialSearch extends PendingAction<PSLTypes>{
  public readonly type = PSLTypes.PSL_AND_MATERIALS_SEARCH;

  constructor(public mainTab: SupplierTabs, public isApproverInApproval: boolean, public searchObj: any){
    super();
  }
}


export class FetchPSL extends PendingAction<PSLTypes> {
  public type = PSLTypes.FETCH_PSL;

  constructor(public list: SupplierTabs, public userRole: IUserRole, public exactId?: number,
     public newFilters?: any, public pslSearch?: any, public materialSearch?: any, public isFavorite?: boolean) {
    super();
  }
}

export class FetchPSLRequest extends PendingAction<PSLTypes> {
  public type = PSLTypes.FETCH_PSL_REQUEST;
}

export class FetchPSLRequestFailure extends ErrorAction<PSLTypes> {
  public type = PSLTypes.FETCH_PSL_REQUEST_FAILURE;

  constructor(public error: string) {
    super();
  }
}

export class FetchPSLRequestSuccess extends SuccessAction<PSLTypes> {
  public type = PSLTypes.FETCH_PSL_REQUEST_SUCCESS;

  constructor(public payload: ISupplier[]) {
    super();
  }
}

export class FetchPslMaterialRequest extends PendingAction<PSLTypes> {
  public type = PSLTypes.FETCH_PSL_MATERIAL_REQUEST;
}

export class FetchPslMaterialRequestFailure extends ErrorAction<PSLTypes> {
  public type = PSLTypes.FETCH_PSL_MATERIAL_REQUEST_FAILURE;

  constructor(public error: string) {
    super();
  }
}
export class FetchPslMaterialRequestSuccess extends SuccessAction<PSLTypes> {
  public type = PSLTypes.FETCH_PSL_MATERIAL_REQUEST_SUCCESS;
  constructor(public payload: IMaterial[]) {
    super();
  }
}

export class UpdateFavourite extends PendingAction<PSLTypes> {
  public type = PSLTypes.UPDATE_FAVOURITE;
  constructor(public isFav: boolean, public index: any[], public id: number) {
    super();
  }
}

export class GetVendorInfo extends PendingAction<PSLTypes> {
  public type = PSLTypes.GET_VENDOR_INFO;
  constructor(public vendorId: number | string, public errorAction: (error: string) => void) {
    super();
  }
}

export class SaveVendorInfo extends SuccessAction<PSLTypes> {
  public type = PSLTypes.SAVE_VENDOR_INFO;
  constructor(public payload: VendorInfo) {
    super();
  }
}

export class ToggleVendorLoading extends SuccessAction<PSLTypes> {
  public type = PSLTypes.TOGGLE_VENDOR_LOADING;
  constructor(public payload: boolean) {
    super();
  }
}

export class UpdateFavouriteSuccess extends SuccessAction<PSLTypes> {
  public type = PSLTypes.UPDATE_FAVOURITE_SUCCESS;
  constructor(public isFav: boolean, public index: any[]) {
    super();
  }
}

export class SavePSL extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.SAVE_PSL;
  constructor(public psl: ISupplier, public materials: IMaterial[], public done: Function, public onError: Function) {
    super();
  }
}
export class SavePSLFromFile extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.SAVE_PSL_FROM_FILE;
  constructor(public psl: ISupplier, public data: any, public done: Function, public onError: Function) {
    super();
  }
}
export class SavePSLSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.SAVE_PSL_SUCCESS;
  constructor(public pslId: string) {
      super({message: "Psl created successfully. ID: " + pslId});
  }
}
export class SavePSLFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.SAVE_PSL_FAILURE;
}

export class UpdatePSL extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_PSL;
  constructor(public psl: ISupplier, public done: Function, public onError: Function) {
    super();
  }
}
export class UpdatePSLSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_PSL_SUCCESS;
    constructor() {
        super({message: "Psl updated successfully"});
    }
}
export class UpdatePSLFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_PSL_FAILURE;
}
export class LocalizePSLWithMaterials extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.LOCALIZE_PSL_MATERIALS;
  constructor(public psl: IPslVendorWithMaterials, public done: Function, public onError: Function) {
    super();
  }
}
export class LocalizePSLSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.LOCALIZE_PSL_SUCCESS;
    constructor(public pslId: string) {
        super({message: "Psl localized successfully. ID: " + pslId});
    }
}
export class LocalizePSLFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.LOCALIZE_PSL_FAILURE;
}
export class ReactivateV2Psl extends PendingAction<PSLTypes> {
    public readonly type = PSLTypes.REACTIVATE_PSL_AND_FIELDS;
    constructor(public psl: IPslVendorWithMaterials, public done: Function, public onError: Function) {
        super();
    }
}
export class ReactivatePsl extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.REACTIVATE_PSL;
  constructor(public reactivatePsl : {pslId?: number,validTo?: string}, public done: Function) {
    super();
  }
}
export class ReactivatePslSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.REACTIVATE_PSL_SUCCESS;
    constructor() {
        super({message: "Psl reactivated successfully"});
    }
}
export class ReactivatePslFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.REACTIVATE_PSL_FAILURE;
}
export class SavePslApproverFilters extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.SAVE_PSL_APPROVERS_FILTER;
  constructor(public filters: any) {
    super();
  }
}
export class SavePslApproverFiltersSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.SAVE_PSL_APPROVERS_FILTER_SUCCESS;
}
export class SavePslApproverFiltersFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.SAVE_PSL_APPROVERS_FILTER_FAILURE;
}
export class UpdateBulkMaterials extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_BULK_MATERIALS;
  constructor(public materials: IMaterial[], public done: Function, public onError: Function) {
    super();
  }
}
export class UpdateMaterialsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_MATERIALS_SUCCESS;
    constructor() {
        super({message: "Psl updated successfully"});
    }
}
export class UpdateMaterialsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_MATERIALS_FAILURE;
}
export class CheckMaterialsForDuplicates extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.CHECK_MATERIALS_FOR_DUPLICATES;
  constructor(public psl: ISupplier, public materials: IMaterial[], public done) {
    super();
  }
}
export class CheckMaterialsForDuplicatesFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.CHECK_MATERIALS_FOR_DUPLICATES_FAILURE;
}
export class AddMaterials extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.ADD_MATERIALS;
  constructor(public pslId: number, public materials: IMaterial[], public done: Function, public onError: Function) {
    super();
  }
}
export class AddMaterialsFromFile extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.ADD_MATERIALS_FROM_FILE;
  constructor(public pslId: number, public data: any, public done: Function, public onError: Function) {
    super();
  }
}
export class AddMaterialsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.ADD_MATERIALS_SUCCESS;
    constructor() {
        super({message: "Psl created successfully"});
    }
}
export class AddMaterialsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.ADD_MATERIALS_FAILURE;
}
export class FetchSinglePSL extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_SINGLE_PSL;
  constructor(public id: number, public done?: Function) {
    super();
  }
}
export class FetchSinglePSLSuccess extends SuccessAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_SINGLE_PSL_SUCCESS;
  constructor(public psl: ISupplier) {
    super();
  }
}
export class FetchSinglePSLFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_SINGLE_PSL_FAILURE;
}

export class FetchSingleMaterial extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_SINGLE_MATERIAL;
  constructor(public id: number) {
    super();
  }
}
export class FetchSingleMaterialSuccess extends SuccessAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_SINGLE_MATERIAL_SUCCESS;
  constructor(public material: IMaterial) {
    super();
  }
}
export class FetchSingleMaterialFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_SINGLE_MATERIAL_FAILURE;
}
export class FetchMaterials extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_MATERIALS;
  constructor(public pslId: number) {
    super();
  }
}
export class FetchMaterialsSuccess extends SuccessAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_MATERIALS_SUCCESS;
  constructor(public materials: IMaterial[]) {
    super();
  }
}
export class FetchMaterialsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.FETCH_MATERIALS_FAILURE;
}

export class ImportPSL extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.IMPORT_PSL;

  constructor(public formData) {
    super();
  }
}
export class ImportPSLSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.IMPORT_PSL_SUCCESS;

  constructor(public message) {
    super();
  }
}
export class ImportPSLError extends ErrorAction<PSLTypes> {
  public readonly type = PSLTypes.IMPORT_PSL_FAILURE;
  constructor(
    public file: string,
    public validationErrors: { key: number, value: { code: string, message: string }[] }[],
    public parsingErrors: { lineId: number, failedPropertyToParse: string, parsingExceptionMessage: string }[]
  ) {
    super();
  }
}

export class ImportPSLTimeout extends ErrorAction<PSLTypes> {
  public readonly type = PSLTypes.IMPORT_PSL_TIMEOUT;
}

export class AddMaterialsFromFileFailure extends ErrorAction<PSLTypes> {
  public readonly type = PSLTypes.MATERIALS_FROM_FILE_FAILURE;
  constructor(
    public customError: string,
  ) {
    super();
  }
}
export class ResetPSLImportError extends Action<PSLTypes> {
  public readonly type = PSLTypes.RESET_PSL_IMPORT_ERROR;
}
export class ResetMaterialsImportError extends Action<PSLTypes> {
  public readonly type = PSLTypes.RESET_MATERIALS_FROM_FILE_IMPORT_ERROR;
}
export class ExportPSLTemplate extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_TEMPLATE;
  constructor(public list: SupplierTabs) {
    super();
  }
}
export class ExportPSLTemplateSuccess extends DownloadCompletedAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_TEMPLATE_SUCCESS;
}
export class ExportPSLTemplateError extends ErrorAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_TEMPLATE_FAILURE;
}

export class ExportPSLListData extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_LIST_DATA;
  constructor(public query: Query) {
    super();
  }
}

export class ExportPSLListDataSuccess extends DownloadCompletedAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_LIST_DATA_SUCCESS;
}

export class ExportPSLListDataError extends ErrorAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_LIST_DATA_FAILURE;
}

export class ExportPSLListDataEmail extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_LIST_DATA_BY_EMAIL;
  constructor(public query: Query) {
    super();
  }
}

export class ExportPSLListDataEmailSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_LIST_DATA_SUCCESS_BY_EMAIL;
  constructor(public message) {
    super();
  }
}

export class ExportPSLListDataEmailError extends ErrorAction<PSLTypes> {
  public readonly type = PSLTypes.EXPORT_PSL_LIST_DATA_FAILURE_BY_EMAIL;
}

export class UpdateListTabs extends Action<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_LIST_TABS;
  constructor(public listTabs: [SupplierTabs, SupplierFilterTabs]) {
    super();
  }
}

export class ApprovePSLs extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.APPROVE_PSLS;
  constructor(public ids: number[], public done: Function) {
    super();
  }
}
export class ApprovePSLsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.APPROVE_PSLS_SUCCESS;
    constructor() {
        super({message: "Psl approved successfully"});
    }
}
export class ApprovePSLsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.APPROVE_PSLS_FAILURE;
}

export class RejectPSLs extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.REJECT_PSLS;
  constructor(public ids: number[], public reason: string, public done: Function) {
    super();
  }
}
export class RejectPSLsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.REJECT_PSLS_SUCCESS;
    constructor() {
        super({message: "Psl rejected successfully"});
    }
}
export class RejectPSLsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.REJECT_PSLS_FAILURE;
}
export class DeletePSLs extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.DELETE_PSLS;
  constructor(public ids: number[], public done: Function) {
    super();
  }
}
export class DeletePSLsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.DELETE_PSLS_SUCCESS;
    constructor() {
        super({message: "Psl deleted successfully"});
    }
}
export class DeletePSLsFailure extends AzureErrorAction<PSLTypes> {
    public readonly type = PSLTypes.DELETE_PSLS_FAILURE;
}

export class DeleteMaterialPSLs extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.DELETE_MATERIAL_PSLS;
  constructor(public ids: number[], public done: Function) {
    super();
  }
}
export class DeleteMaterialPSLsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.DELETE_MATERIAL_PSLS_SUCCESS;
    constructor() {
        super({message: "Psl Material deleted successfully"});
    }
}
export class DeleteMaterialPSLsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.DELETE_MATERIAL_PSLS_FAILURE;
}

export class AddPslApproverFailure extends ErrorAction<PSLTypes> {
  public type = PSLTypes.ADD_PSL_APPROVER_FAILURE;

  constructor(public error: string) {
    super();
  }
}

export class AddPslApproverPermissionFailure extends ErrorAction<PSLTypes> {
    public type = PSLTypes.ADD_PSL_APPROVER_PERMISSION_FAILURE;

    constructor(public error: string) {
        super();
    }
}

// Create PSL vendor with meterials API call success and failure actions

export class CreatePslVendorWithMaterials extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.CREATE_PSL_VENDOR_WITH_MATERIALS;
  constructor(public psl: IPslVendorWithMaterials, public done: Function, public onError: Function) {
    super();
  }
}

export class CreatePslVendorWithMaterialsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.CREATE_PSL_VENDOR_WITH_MATERIALS_SUCCESS;
  constructor(public pslId: string) {
    super({message: "Psl created successfully. ID: " + pslId});
  }
}

export class CreatePslVendorWithMaterialsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.CREATE_PSL_VENDOR_WITH_MATERIALS_FAILURE;
}

// Update PSL vendor with meterials API call success and failure actions

export class UpdatePslVendorWithMaterials extends PendingAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_PSL_VENDOR_WITH_MATERIALS;
  constructor(public psl: IPslVendorWithMaterials, public done: Function, public onError: Function) {
    super();
  }
}

export class UpdatePslVendorWithMaterialsSuccess extends SuccessNotificationAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_PSL_VENDOR_WITH_MATERIALS_SUCCESS;
  constructor() {
    super({message: "Psl updated successfully"});
  }
}

export class UpdatePslVendorWithMaterialsFailure extends AzureErrorAction<PSLTypes> {
  public readonly type = PSLTypes.UPDATE_PSL_VENDOR_WITH_MATERIALS_FAILURE;
}

