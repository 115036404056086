import { useMutation, useQuery } from "react-query";
import { PSLService } from "./psl.service";
import { CompetitiveBiddingService } from "../../competitiveBidding/domains/cb.service";
import { CurrencyData } from "../../competitiveBidding/domains/selector/currency";

export const useFetchSpendCategories = () => {
  return useQuery("categoryTree", () => PSLService.fetchCategoryTree(), { staleTime: Infinity })
}

export const useFetchCurrencies = () => {
  return useQuery("currencies",
    () => CompetitiveBiddingService.fetchCurrencies().then((res: CurrencyData[]) => res.map(CurrencyData.toSelect)), { staleTime: Infinity })
}

export const useFetchPslCatalogs = (query: any, userScope) => {
  return useQuery("pslCatalogs" + JSON.stringify(query),
    () => PSLService.fetchMaterials_PslCatalogs(query, userScope), { staleTime: Infinity, retry: 2 });
};

export const usePslFavToggle = () => useMutation((pslId: number ) => PSLService.updateFavouriteToggle(pslId))

export const useSavePslCatalogsFilters = () => {
  return useMutation((filters: any) => PSLService.savePslCatalogsFilters(filters))
}