import * as R from 'ramda';
import {ItemPrice} from "../../domains/itemPrice/itemPrice";
import {ItemPriceActionTypes} from "../../actions/itemPricing.actions";
import {IAction} from "../../../shared/domains/core/actions";
import {ScalePrice} from "../../domains/itemPrice/scalePrice";

export interface ItemsPriceState {
  itemsPrice: { [key: string]: ItemPrice[] };
  totalCount: { [key: string]: number };
  scalesLoading: boolean;
  scalesPrice: ScalePrice[];
  loading: { [key: string]: boolean };
  error: string;
}

export const initialItemsPriceState: ItemsPriceState = {
  itemsPrice: {},
  totalCount: {},
  scalesLoading: false,
  scalesPrice: [],
  loading: {},
  error: null
};

export function ItemsPriceReducer(state: ItemsPriceState = initialItemsPriceState, action: IAction<ItemPriceActionTypes>): ItemsPriceState {
  switch (action.type) {
    case ItemPriceActionTypes.FETCH_ITEMSPRICE:
      return {
        ...state,
        itemsPrice: R.dissoc(action.itemNo, state.itemsPrice),
        error: null,
        loading: {
          ...state.loading,
          [action.itemNo]: true
        }
      };

    case ItemPriceActionTypes.ADD_ITEM_PRICE:
    case ItemPriceActionTypes.DELETE_ITEM_PRICE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemPrice.ItemNo]: true
        }
      };

    case ItemPriceActionTypes.ADD_ITEM_PRICE_SUCCESS:
    case ItemPriceActionTypes.DELETE_ITEM_PRICE_SUCCESS:
    case ItemPriceActionTypes.ADD_ITEM_PRICE_FAILURE:
    case ItemPriceActionTypes.DELETE_ITEM_PRICE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemPrice.ItemNo]: false
        }
      };

    case ItemPriceActionTypes.FETCH_ITEMSPRICE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemNo]: false
        },
        itemsPrice: {
          ...state.itemsPrice,
          [action.itemNo]: action.items
        },
        totalCount: {
          ...state.totalCount,
          [action.itemNo]: action.totalCount
        }
      };

    case ItemPriceActionTypes.FETCH_ITEMSPRICE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemNo]: false
        },
        error: action.error
      };

    case ItemPriceActionTypes.UPDATE_ITEMSPRICE_SUCCESS:
      const newItemPrices = state.itemsPrice[action.itemPrice.ItemNo].map((itemPrice: ItemPrice) => {
        if (itemPrice.ConditionRecord === action.itemPrice.ConditionRecord &&
          itemPrice.ConditionItem == action.itemPrice.ConditionItem &&
          itemPrice.ItemNo == action.itemPrice.ItemNo &&
          itemPrice.AgreementNo == action.itemPrice.AgreementNo) {
          return action.itemPrice;
        }
        return itemPrice;
      });

      return {
        ...state,
        itemsPrice: {
          ...state.itemsPrice,
          [action.itemPrice.ItemNo]: newItemPrices
        }
      };

    case ItemPriceActionTypes.FETCH_SCALESPRICE:
      return {...state, scalesLoading: true, scalesPrice: [] };
    case ItemPriceActionTypes.FETCH_SCALESPRICE_SUCCESS:
      return {
        ...state,
        scalesLoading: false,
        scalesPrice: action.scalesPrice
      };
    case ItemPriceActionTypes.FETCH_SCALESPRICE_FAILURE:
      return {...state, scalesLoading: false, scalesPrice: [] };
    case ItemPriceActionTypes.UPDATE_SCALESPRICE:
      return {...state, scalesLoading: true};
    case ItemPriceActionTypes.UPDATE_SCALESPRICE_SUCCESS:
    case ItemPriceActionTypes.UPDATE_SCALESPRICE_FAILURE:
      return {...state, scalesLoading: false};
    default:
      return state;
  }
}
