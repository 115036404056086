import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { approvalStatusStateStore } from "../reducers";
import { ApprovalStatusService } from "../domains/approvalStatus/approvalStatus.service";
import * as Sentry from '@sentry/browser';
import {
    FetchPRDetailSuccess,
    FetchPRDetailError,
    FetchPRs,
    FetchPRsError,
    FetchPRsSuccess,
    ApprovalStatusActionTypes,
    FetchPRLinesSuccess,
    FetchPRLinesError,
    ApprovedPRSuccess,
    ApprovedPRError,
    MailFailure,
    MailSuccess
} from "../actions/approvalStatus.action";
import { newPage, newResource, updateTerm, newFilters, approvalStatusQuery, updateOrder } from "../domains/approvalStatus/query";
import { positions } from '@material-ui/system';

export function* fetch(action: any): any {
    const getAliys = state => state.approvalStatus.ApprovalStatus.defaultAliys
    let aliys = yield select(getAliys)
    let newQuery = action.query;
    const getuser = state => state.shared.user.currentUser
    const currentUser = yield select(getuser)
    if (aliys == null) {
        try {
            const getAliys = yield call(ApprovalStatusService.getDefaultAliys, currentUser.userPreferredCompanyCode);
            aliys = getAliys;

        } catch (error) {
            aliys = 'R3AE1'
        }
    }

    yield put({ type: ApprovalStatusActionTypes.AS_DEFAULT_ALIYS, aliys });

    try {
        if (!action.query) {
            let query = approvalStatusQuery();
            newQuery = newResource(query, action.resource);
            newQuery = newFilters(newQuery, [
                { field: "Aribapr", value: action.Aribapr }
            ]);
        }

        const result = yield call(ApprovalStatusService.fetch, newQuery, aliys);
        yield put(new FetchPRsSuccess({ items: result.data, totalCount: result.count, newQuery: newQuery }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new FetchPRsError({ error: error, message: error.message }))
    }
}

export function* fetchDetail(action: any): any {
    const query = {
        Aribapr: action.Aribapr,
        SapPR: action.SapPR,
        ItemNo: action.ItemNo,
        CompCode: action.CompCode,
        PurchOrg: action.PurchOrg,
        RelGroup: action.RelGroup,
        RelStrategy: action.RelStrategy,
        ApprovalLevel: action.Frgco
    };
    try {
        const result = yield call(ApprovalStatusService.fetchDetail, query);
        const result1 = yield call(ApprovalStatusService.fetchAccount, query);
        const result2 = yield call(ApprovalStatusService.fetchPricing, query);
        const result3 = yield call(ApprovalStatusService.fetchApprovalIndicator, query);
        yield put(new FetchPRDetailSuccess({ info: result, accountList: result1, pricing: result2, approvalIndecator: result3 }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new FetchPRDetailError({ error: error, message: error.message }))
    }
}
export function* fetchLines(action: any): any {
    const getAliys = state => state.approvalStatus.ApprovalStatus.defaultAliys
    const aliys = yield select(getAliys)
    const query = {
        postQuery: [{ field: "AribaPr", value: encodeURIComponent(action.Aribapr) },
        { field: "ApprovalType", value: encodeURIComponent(action.ApprovalType) },
        { field: "ApprovalLevel", value: encodeURIComponent(action.Frgco) },
        { field: "AgentId", value: encodeURIComponent(action.positionId) },
        { field: "Counter", value: encodeURIComponent(action.Counter) },
        { field: "SapPr", value: encodeURIComponent(action.Banfn) },
        ],
        SAP__Origin: aliys
    };
    if (action.ApprovalType == 'BA') {
        query.postQuery.push({ field: "ItemNo", value: encodeURIComponent(action.ItemNo) })
    }
    try {
        const result = yield call(ApprovalStatusService.fetchLines, query);
        yield put(new FetchPRLinesSuccess({ items: result.data, Aribapr: action.AribaPr }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new FetchPRLinesError({ error: error, message: error.message, Aribapr: action.AribaPr }))
    }
}

export function* approveRequest(action: any): any {
    const query = { SapPR: action.SapPR, ItemNo: action.ItemNo };
    try {
        const result = yield call(ApprovalStatusService.fetchLines, query);
        yield put(new ApprovedPRSuccess({ items: result.data, SapPR: action.SapPR }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new ApprovedPRError({ error: error, message: error.message }))
    }
}
function* emailTrigger(action) {
    try {
        const result = yield call(ApprovalStatusService.sendEmail, action.payload);
        yield put(new MailSuccess())
    } catch (error) {
        Sentry.captureException(error);
        yield put(new MailFailure(error))
    }
}


function* updatePage(action) {
    const query = yield select(({ approvalStatus }: { approvalStatus: approvalStatusStateStore }) => approvalStatus.ApprovalStatus.query);
    const newQuery = newPage(query, action.page);
    yield put({ type: ApprovalStatusActionTypes.NEW_AS_QUERY, query: newQuery });
}


function* newTerm(action) {
    const query = yield select(({ approvalStatus }: { approvalStatus: approvalStatusStateStore }) => approvalStatus.ApprovalStatus.query);
    const newQuery = updateTerm(query, action.term);
    yield put({ type: ApprovalStatusActionTypes.NEW_AS_QUERY, query: newQuery });
}

function* newOrder(action) {
    const query = yield select(({ approvalStatus }: { approvalStatus: approvalStatusStateStore }) => approvalStatus.ApprovalStatus.query);
    const newQuery = updateOrder(query, action.field, action.direction);
    yield put({ type: ApprovalStatusActionTypes.NEW_AS_QUERY, query: newQuery });
}

function * ASDefaultAliys (action){
    yield put({ type: ApprovalStatusActionTypes.AS_DEFAULT_ALIYS_SUCCESS, aliys: action.aliys });
  }


function* watchBAFetchQuery() {
    yield takeLatest(ApprovalStatusActionTypes.FETCH_AS, fetch)
}

function* watchNewActiveQuery() {
    yield takeLatest(ApprovalStatusActionTypes.NEW_AS_QUERY, fetch)
}

function* watchDetailActiveQuery() {
    yield takeLatest(ApprovalStatusActionTypes.FETCH_AS_DETAIL, fetchDetail)
}

function* watchLinesQuery() {
    yield takeLatest(ApprovalStatusActionTypes.FETCH_AS_LINES, fetchLines)
}

function* watchPRApprovalQuery() {
    yield takeLatest(ApprovalStatusActionTypes.APPROVAL_STATUS_APPROVED_PR, approveRequest)
}

function* watchNewPage() {
    yield takeLatest(ApprovalStatusActionTypes.NEW_PAGE_AS, updatePage)
}

function* watchNewTerm() {
    yield takeLatest(ApprovalStatusActionTypes.NEW_TERM_AS, newTerm)
}

function* watchNewOrder() {
    yield takeLatest(ApprovalStatusActionTypes.NEW_ORDER_AS, newOrder);
}

function* watchEmailTrigger() {
    yield takeLatest(ApprovalStatusActionTypes.EMAIL_TRIGGER, emailTrigger);
}

function * watchASDefaultAliys(){
    yield takeLatest(ApprovalStatusActionTypes.AS_DEFAULT_ALIYS, ASDefaultAliys);
  }

export default function* approvalStatusSaga() {
    yield all([
        fork(watchBAFetchQuery),
        fork(watchNewActiveQuery),
        fork(watchNewPage),
        fork(watchDetailActiveQuery),
        fork(watchLinesQuery),
        fork(watchPRApprovalQuery),
        fork(watchNewTerm),
        fork(watchNewOrder),
        fork(watchEmailTrigger),
        fork(watchASDefaultAliys)
    ])
};