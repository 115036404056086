import {
  Contract,
  ContractNumber,
  ContractType,
  ContractSource,
  DraftContractSource
} from "../domains/contract/contract";
import {
  Action, DownloadCompletedAction,
  ErrorAction,
  IPromiseExecutor,
  PendingAction,
  SuccessAction
} from "../../shared/domains/core/actions";
import {SuccessNotificationAction} from "../../shared/domains/core/notifications";
import {SAPBoolean} from "../../shared/domains/core/sapBoolean";
import {ContractClmLog, ContractClmResultLog} from "../domains/contract/clm/contract.clm";
import {ProcSpecs} from "../domains/contract/mrp/procSpecs";

export enum ContractActionTypes {
  NEW_CONTRACT_TYPE = "NEW_CONTRACT_TYPE",
  NEW_CONTRACT_SOURCE = "NEW_CONTRACT_SOURCE",
  NEW_DRAFT_CONTRACT_SOURCE = "NEW_DRAFT_CONTRACT_SOURCE",

  RESET = "RESET_SEARCH",
  NEW_TERM = "NEW_TERM",
  NEW_PAGE = "NEW_PAGE",
  NEW_ORDER = "NEW_ORDER",
  NEW_FILTER = "NEW_FILTER",
  NEW_ACTIVE_QUERY = "NEW_ACTIVE_QUERY",
  NEW_ACTIVE_QUERIES = "NEW_ACTIVE_QUERIES",
  NEW_SAP_CONNECTION = "NEW_SAP_CONNECTION",

  TOGGLE_FAVORITE_CONTRACT = "TOGGLE_FAVORITE_CONTRACT",
  TOGGLE_FAVORITE_CONTRACT_SUCCESS = "TOGGLE_FAVORITE_CONTRACT_SUCCESS",
  TOGGLE_FAVORITE_CONTRACT_FAILURE = "TOGGLE_FAVORITE_CONTRACT_FAILURE",

  FETCH_CONTRACT = "FETCH_CONTRACT",
  FETCH_CONTRACTS = "FETCH_CONTRACTS",
  FETCH_EXPIRED_CONTRACTS = "FETCH_EXPIRED_CONTRACTS",
  FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS",
  FETCH_CONTRACTS_PER_SERVERS_SUCCESS = "FETCH_CONTRACTS_PER_SERVERS_SUCCESS",
  FETCH_CONTRACTS_FAILURE = "FETCH_CONTRACTS_FAILURE",

  FETCH_DATALAKE_CONTRACTS = "FETCH_DATALAKE_CONTRACTS",
  FETCH_DATALAKE_CONTRACTS_SUCCESS = "FETCH_DATALAKE_CONTRACTS_SUCCESS",
  FETCH_DATALAKE_CONTRACTS_FAILURE = "FETCH_DATALAKE_CONTRACTS_FAILURE",

  FETCH_ORIGINAL_MRP_CONTRACTS = "FETCH_ORIGINAL_MRP_CONTRACTS",
  FETCH_ORIGINAL_MRP_CONTRACTS_SUCCESS = "FETCH_ORIGINAL_MRP_CONTRACTS_SUCCESS",
  FETCH_ORIGINAL_MRP_CONTRACTS_FAILURE = "FETCH_ORIGINAL_MRP_CONTRACTS_FAILURE",

  SET_SELECTED_CONTRACT = "SET_SELECTED_CONTRACT",
  SET_CONTRACT_TYPE = "SET_CONTRACT_TYPE",
  SET_CONTRACT_SOURCE = "SET_CONTRACT_SOURCE",
  SET_DRAFT_CONTRACT_SOURCE = "SET_DRAFT_CONTRACT_SOURCE",

  UPDATE_CONTRACT = "UPDATE_CONTRACT",
  UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS",
  UPDATE_CONTRACT_FAILURE = "UPDATE_CONTRACT_FAILURE",

  UPDATE_DRAFT = "UPDATE_DRAFT",
  UPDATE_DRAFT_SUCCESS = "UPDATE_DRAFT_SUCCESS",
  UPDATE_DRAFT_FAILURE = "UPDATE_DRAFT_FAILURE",

  CLONE_CONTRACT = "CLONE_CONTRACT",
  CLONE_CONTRACT_SUCCESS = "CLONE_CONTRACT_SUCCESS",
  CLONE_CONTRACT_FAILURE = "CLONE_CONTRACT_FAILURE",
  DRAFT_CONTRACT_FAILURE = "DRAFT_CONTRACT_FAILURE",

  DELETE_DRAFT_CONTRACT = "DELETE_DRAFT_CONTRACT",
  DELETE_DRAFT_CONTRACT_SUCCESS = "DELETE_DRAFT_CONTRACT_SUCCESS",
  DELETE_DRAFT_CONTRACT_FAILURE = "DELETE_DRAFT_CONTRACT_FAILURE",

  FETCH_DRAFT_ERRORS = "FETCH_DRAFT_ERRORS",
  FETCH_DRAFT_ERRORS_SUCCESS = "FETCH_DRAFT_ERRORS_SUCCESS",
  FETCH_DRAFT_ERRORS_FAILURE = "FETCH_DRAFT_ERRORS_FAILURE",

  PUBLISH_DRAFT_CONTRACT = "PUBLISH_DRAFT_CONTRACT",
  PUBLISH_DRAFT_CONTRACT_SUCCESS = "PUBLISH_DRAFT_CONTRACT_SUCCESS",
  PUBLISH_DRAFT_CONTRACT_FAILURE = "PUBLISH_DRAFT_CONTRACT_FAILURE",

  VALIDATE_DRAFT = "VALIDATE_DRAFT",
  VALIDATE_DRAFT_SUCCESS = "VALIDATE_DRAFT_SUCCESS",
  VALIDATE_DRAFT_FAILURE = "VALIDATE_DRAFT_FAILURE",

  SUBMIT_MRP = "SUBMIT_MRP",
  SUBMIT_MRP_SUCCESS = "SUBMIT_MRP_SUCCESS",
  SUBMIT_MRP_FAILURE = "SUBMIT_MRP_FAILURE",

  FETCH_PROC_SPECS = "FETCH_PROC_SPECS",
  FETCH_PROC_SPECS_SUCCESS = "FETCH_PROC_SPECS_SUCCESS",
  FETCH_PROC_SPECS_FAILURE = "FETCH_PROC_SPECS_FAILURE",

  SUBMIT_MRP_FOR_APPROVAL = "SUBMIT_MRP_FOR_APPROVAL",
  SUBMIT_MRP_FOR_APPROVAL_SUCCESS = "SUBMIT_MRP_FOR_APPROVAL_SUCCESS",
  SUBMIT_MRP_FOR_APPROVAL_FAILURE = "SUBMIT_MRP_FOR_APPROVAL_FAILURE",

  APPROVE_MRP = "APPROVE_MRP",
  APPROVE_MRP_SUCCESS = "APPROVE_MRP_SUCCESS",
  APPROVE_MRP_FAILURE = "APPROVE_MRP_FAILURE",

  REJECT_MRP = "REJECT_MRP",
  REJECT_MRP_SUCCESS = "REJECT_MRP_SUCCESS",
  REJECT_MRP_FAILURE = "REJECT_MRP_FAILURE",

  FETCH_CHILD_CONTRACT_HEADER = "FETCH_CHILD_CONTRACT_HEADER",
  FETCH_CHILD_CONTRACT_HEADER_SUCCESS = "FETCH_CHILD_CONTRACT_HEADER_SUCCESS",
  FETCH_CHILD_CONTRACT_HEADER_FAILURE = "FETCH_CHILD_CONTRACT_HEADER_FAILURE",

  FETCH_CLM_LOGS = "FETCH_CLM_LOGS",
  FETCH_CLM_LOGS_SUCCESS = "FETCH_CLM_LOGS_SUCCESS",
  FETCH_CLM_LOGS_FAILURE = "FETCH_CLM_LOGS_FAILURE",

  FETCH_CLM_RESPONSE_LOGS = "FETCH_CLM_RESPONSE_LOGS",
  FETCH_CLM_RESPONSE_LOGS_SUCCESS = "FETCH_CLM_RESPONSE_LOGS_SUCCESS",
  FETCH_CLM_RESPONSE_LOGS_FAILURE = "FETCH_CLM_RESPONSE_LOGS_FAILURE",

  TEST_CLM_CONNECTION = "TEST_CLM_CONNECTION",
  TEST_CLM_CONNECTION_SUCCESS = "TEST_CLM_CONNECTION_SUCCESS",
  TEST_CLM_CONNECTION_FAILURE = "TEST_CLM_CONNECTION_FAILURE",

  DOWNLOAD_CSV_CLM = "DOWNLOAD_CSV_CLM",
  DOWNLOAD_CSV_CLM_SUCCESS = "DOWNLOAD_CSV_CLM_SUCCESS",
  DOWNLOAD_CSV_CLM_FAILURE = "DOWNLOAD_CSV_CLM_FAILURE",

  SUBMIT_CLM = "SUBMIT_CLM",
  SUBMIT_CLM_SUCCESS = "SUBMIT_CLM_SUCCESS",
  SUBMIT_CLM_FAILURE = "SUBMIT_CLM_FAILURE",
  SUBMIT_CLM_NOTIFICATION = "SUBMIT_CLM_NOTIFICATION",

  SUBMIT_PARENT_CLM_SUCCESS = "SUBMIT_PARENT_CLM_SUCCESS",
  SUBMIT_PARENT_CLM_FAILURE = "SUBMIT_PARENT_CLM_FAILURE",

  RESUBMIT_CLM = "RESUBMIT_CLM",
  RESUBMIT_CLM_SUCCESS = "RESUBMIT_CLM_SUCCESS",
  RESUBMIT_CLM_FAILURE = "RESUBMIT_CLM_FAILURE",

  CLM_CONTRACT_CHANGE = "CLM_CONTRACT_CHANGE",
  CLM_PARENT_CONTRACT_CHANGE = "CLM_PARENT_CONTRACT_CHANGE",

  UNLOCK_CLM = "UNLOCK_CLM",
  UNLOCK_CLM_SUCCESS = "UNLOCK_CLM_SUCCESS",
  UNLOCK_CLM_FAILURE = "UNLOCK_CLM_FAILURE",

  DELETE_CLM_CONTRACT = "DELETE_CLM_CONTRACT",
  DELETE_CLM_CONTRACT_SUCCESS = "DELETE_CLM_CONTRACT_SUCCESS",
  DELETE_CLM_CONTRACT_FAILURE = "DELETE_CLM_CONTRACT_FAILURE",

  RESET_INVALID_DRAFT = "RESET_INVALID_DRAFT",
  UPDATE_NEW_STATE_CONTRACTS = "UPDATE_NEW_STATE_CONTRACTS",

  UPDATE_MRP = "UPDATE_MRP",
  UPDATE_MRP_SUCCESS = "UPDATE_MRP_SUCCESS",
  UPDATE_MRP_FAILURE = "UPDATE_MRP_FAILURE",

  DELETE_MRP = "DELETE_MRP",
  DELETE_MRP_SUCCESS = "DELETE_MRP_SUCCESS",
  DELETE_MRP_FAILURE = "DELETE_MRP_FAILURE"
}

export class NewFilters extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.NEW_FILTER;

  constructor(public filters) {
    super();
  }
}

export class NewActiveQuery extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.NEW_ACTIVE_QUERY;

  constructor(public query) {
    super();
  }
}

export class NewActiveQueries extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.NEW_ACTIVE_QUERIES;

  constructor(public queries, public page = 0, public serverPage = 0, public localPage = 0) {
    super();
  }
}

export class FetchContracts extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CONTRACTS;
}

export class FetchContractsSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CONTRACTS_SUCCESS;

  constructor(public items, public totalCount = 0) {
    super();
  }
}

export class FetchContractsPerServersSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CONTRACTS_PER_SERVERS_SUCCESS;

  constructor(public queries, public items, public totalCount, public countServers, public serverPage, public localPage) {
    super();
  }
}

export class FetchContractsError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CONTRACTS_FAILURE;
}

export class FetchDatalakeContracts extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_DATALAKE_CONTRACTS;
}

export class FetchDatalakeContractsError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_DATALAKE_CONTRACTS_FAILURE;
}

export class FetchDatalakeContractsSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_DATALAKE_CONTRACTS_SUCCESS;

  constructor(public contracts) {
    super();
  }
}

export class FetchOriginalMrpContracts extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_ORIGINAL_MRP_CONTRACTS;
}

export class FetchOriginalMrpContractsError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_ORIGINAL_MRP_CONTRACTS_FAILURE;
}

export class FetchOriginalMrpContractsSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_ORIGINAL_MRP_CONTRACTS_SUCCESS;

  constructor(public contracts) {
    super();
  }
}

export class FetchExpiredContracts extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_EXPIRED_CONTRACTS;
}

export class FetchContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CONTRACT;

  constructor(public contractId: ContractNumber) {
    super();
  }
}

export class ResetInvalidContract extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.RESET_INVALID_DRAFT;
}

export class ValidateDraft extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.VALIDATE_DRAFT;

  constructor(public contract) {
    super();
  }
}

export class ValidateDraftSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.VALIDATE_DRAFT_SUCCESS;

  constructor(public message) {
    super();
  }
}

export class ValidateDraftError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.VALIDATE_DRAFT_FAILURE;

  constructor(public contract, public error) {
    super();
  }
}

export class PublishDraftContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.PUBLISH_DRAFT_CONTRACT;

  constructor(public contract) {
    super();
  }
}

export class PublishDraftContractSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.PUBLISH_DRAFT_CONTRACT_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class PublishDraftContractError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.PUBLISH_DRAFT_CONTRACT_FAILURE;

  constructor(public contract, public error) {
    super();
  }
}

export class ToggleFavoriteContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.TOGGLE_FAVORITE_CONTRACT;

  constructor(public contractId: string, public systemAlias: string, public toggle: SAPBoolean) {
    super();
  }
}

export class ToggleFavoriteContractSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.TOGGLE_FAVORITE_CONTRACT_SUCCESS;

  constructor(public agreementNo, public newFav) {
    super();
  }
}

export class ToggleFavoriteContractError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.TOGGLE_FAVORITE_CONTRACT_FAILURE;
}

export class UpdateContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_CONTRACT;

  constructor(public executor: IPromiseExecutor, public contract) {
    super();
  }
}

export class UpdateContractSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_CONTRACT_SUCCESS;

  constructor(public executor: IPromiseExecutor, public contract) {
    super();

    this.executor.resolve();
  }
}

export class UpdateContractError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_CONTRACT_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class UpdateDraft extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_DRAFT;

  constructor(public executor: IPromiseExecutor, public contract) {
    super();
  }
}

export class UpdateDraftSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_DRAFT_SUCCESS;

  constructor(public executor: IPromiseExecutor, public contract) {
    super();

    this.executor.resolve();
  }
}

export class UpdateDraftError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_DRAFT_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class CloneContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.CLONE_CONTRACT;

  constructor(public contract: Contract, public copyFlags: boolean) {
    super();
  }
}

export class CloneContractSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.CLONE_CONTRACT_SUCCESS;
}

export class CloneContractError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.CLONE_CONTRACT_FAILURE;
}

export class FetchDraftErrors extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_DRAFT_ERRORS;

  constructor(public agreementNo, public timestamp, public refContractNo, public systemAlias) {
    super();
  }
}

export class FetchDraftErrorsSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_DRAFT_ERRORS_SUCCESS;
}

export class FetchDraftErrorsError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_DRAFT_ERRORS_FAILURE;
}

export class DeleteDraftContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_DRAFT_CONTRACT;

  constructor(public contract: Contract) {
    super();
  }
}

export class DeleteDraftContractSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_DRAFT_CONTRACT_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class DeleteDraftContractError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_DRAFT_CONTRACT_FAILURE;
}

export class SetContractType extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.SET_CONTRACT_TYPE;

  constructor(public contractType: ContractType) {
    super();
  }
}

export class SetContractSource extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.SET_CONTRACT_SOURCE;

  constructor(public contractSource: ContractSource) {
    super();
  }
}

export class UpdateContractSource extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.NEW_CONTRACT_SOURCE;

  constructor(public contractSource: ContractSource) {
    super();
  }
}

export class SetDraftContractSource extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.SET_DRAFT_CONTRACT_SOURCE;

  constructor(public contractSource: DraftContractSource) {
    super();
  }
}

export class UpdateDraftContractSource extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.NEW_DRAFT_CONTRACT_SOURCE;

  constructor(public contractSource: DraftContractSource) {
    super();
  }
}

export class SetSelectedContract extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.SET_SELECTED_CONTRACT;

  constructor(public contract) {
    super();
  }
}

export class UpdateNewStateContracts extends Action<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_NEW_STATE_CONTRACTS;

  constructor(public contracts, public selectedContract) {
    super();
  }
}

export class SubmitMRP extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_MRP;

  constructor(public contract) {
    super();
  }
}

export class SubmitMRPSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_MRP_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class SubmitMRPError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_MRP_FAILURE;

  constructor(public contract, public error) {
    super();
  }
}

export class UpdateMrp extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_MRP;

  constructor(public executor: IPromiseExecutor, public contract: Contract) {
    super();
  }
}

export class UpdateMrpSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_MRP_SUCCESS;

  constructor(public executor: IPromiseExecutor, public contract) {
    super();

    this.executor.resolve();
  }
}

export class UpdateMrpError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UPDATE_MRP_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);
    this.executor.reject();
  }
}

export class DeleteMrpContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_MRP;

  constructor(public contract: Contract) {
    super();
  }
}

export class DeleteMrpContractSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_MRP_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class DeleteMrpContractError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_MRP_FAILURE;
}

export class FetchChildContractHeader extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CHILD_CONTRACT_HEADER;

  constructor(public contract) {
    super();
  }
}

export class FetchChildContractHeaderSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CHILD_CONTRACT_HEADER_SUCCESS;

  constructor(public incoTerms, public paymentTerms, public supplierTerm) {
    super();
  }
}

export class FetchChildContractHeaderError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CHILD_CONTRACT_HEADER_FAILURE;
}

export class FetchClmLogs extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CLM_LOGS;

  constructor(public contractNo) {
    super();
  }
}

export class FetchClmLogsSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CLM_LOGS_SUCCESS;

  constructor(public contractClmLogs: ContractClmLog[]) {
    super();
  }
}

export class FetchClmLogsError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CLM_LOGS_FAILURE;
}

export class FetchClmResponseLogs extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CLM_RESPONSE_LOGS;

  constructor(public contractNo) {
    super();
  }
}

export class FetchClmResponseLogsSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CLM_RESPONSE_LOGS_SUCCESS;

  constructor(public contractClmResultLogs: ContractClmResultLog[]) {
    super();
  }
}

export class FetchClmResponseLogsError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_CLM_RESPONSE_LOGS_FAILURE;
}

export class DownloadCSVCLM extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DOWNLOAD_CSV_CLM;

  constructor(public doNotPrint: boolean) {
    super();
  }
}

export class DownloadCSVCLMSuccess extends DownloadCompletedAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DOWNLOAD_CSV_CLM_SUCCESS;
}

export class DownloadCSVCLMError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DOWNLOAD_CSV_CLM_FAILURE;
}

export class TestCLMConnection extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.TEST_CLM_CONNECTION;
}

export class TestCLMConnectionSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.TEST_CLM_CONNECTION_SUCCESS;

  constructor(public message) {
    super();
  }
}

export class TestCLMConnectionError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.TEST_CLM_CONNECTION_FAILURE;
}

export class SubmitCLM extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_CLM;

  constructor(public doNotPrint: boolean) {
    super();
  }
}

export class SubmitCLMSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_CLM_SUCCESS;

  constructor(public contract, public items) {
    super();
  }
}

export class SubmitCLMNotification extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_CLM_NOTIFICATION;

  constructor(public message) {
    super();
  }
}

export class SubmitCLMError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_CLM_FAILURE;

  constructor(public contract, public error) {
    super(error);
  }
}

export class ReSubmitCLM extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.RESUBMIT_CLM;

  constructor(public contract) {
    super();
  }
}

export class ReSubmitCLMSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.RESUBMIT_CLM_SUCCESS;

  constructor(public contract) {
    super();
  }
}

export class ReSubmitCLMError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.RESUBMIT_CLM_FAILURE;
}

export class UnlockCLM extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UNLOCK_CLM;

  constructor(public contract) {
    super();
  }
}

export class UnlockCLMSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UNLOCK_CLM_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class UnlockCLMError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.UNLOCK_CLM_FAILURE;
}

export class DeleteCLMContract extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_CLM_CONTRACT;

  constructor(public contract) {
    super();
  }
}

export class DeleteCLMContractSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_CLM_CONTRACT_SUCCESS;

  constructor(public contract) {
    super();
  }
}

export class DeleteCLMContractError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.DELETE_CLM_CONTRACT_FAILURE;
}

export class SubmitParentCLMSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_PARENT_CLM_SUCCESS;

  constructor() {
    super();
  }
}

export class SubmitParentCLMError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_PARENT_CLM_FAILURE;
}

export class ClmContractChange extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.CLM_CONTRACT_CHANGE;

  constructor(public contract) {
    super();
  }
}

export class ClmParentContractChange extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.CLM_PARENT_CONTRACT_CHANGE;

  constructor(public contract) {
    super();
  }
}

export class FetchProcSpecs extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_PROC_SPECS;

  constructor(public companyCode: string, public purchOrg: string) {
    super();
  }
}

export class FetchProcSpecsSuccess extends SuccessAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_PROC_SPECS_SUCCESS;

  constructor(public procSpecsList: ProcSpecs[]) {
    super();
  }
}

export class FetchProcSpecsError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.FETCH_PROC_SPECS_FAILURE;
}

export class SubmitMRPForApproval extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL;

  constructor(public contract, public approver, public done) {
    super();
  }
}

export class SubmitMRPForApprovalSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class SubmitMRPForApprovalError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL_FAILURE;
}

export class ApproveMRP extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.APPROVE_MRP;

  constructor(public contract, public done?) {
    super();
  }
}

export class ApproveMRPSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.APPROVE_MRP_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class ApproveMRPError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.APPROVE_MRP_FAILURE;
}

export class RejectMRP extends PendingAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.REJECT_MRP;

  constructor(public contract, public reason, public done?) {
    super();
  }
}

export class RejectMRPSuccess extends SuccessNotificationAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.REJECT_MRP_SUCCESS;

  constructor(public contract, public message) {
    super();
  }
}

export class RejectMRPError extends ErrorAction<ContractActionTypes> {
  public readonly type = ContractActionTypes.REJECT_MRP_FAILURE;
}
