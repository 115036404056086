import {mergeRight} from "ramda";
import {CompetitiveBiddingTypes} from "../actions/competitiveBiddings.actions";
import {IAction} from "../../shared/domains/core/actions";
import {error, loaded, loading} from "../../shared/reducers/utils";
import {ShortCompetitiveBidding} from "../domains/shortCompetitiveBidding";
import {QueryableReducer} from "../../shared/queryable/query.reducer";
import {newCbQuery} from "../domains/cbQuery";

export interface ListState {
  data: ShortCompetitiveBidding[];
  associatingCbId: string;
  totalCount: number;
  isAssociating: boolean;
  loading: boolean;
  error: any;
}

export const initialListState: ListState = {
  data: [],
  associatingCbId: null,
  totalCount: 0,
  isAssociating: false,
  loading: true,
  error: null
};

export function CbListReducer(state: ListState = initialListState, action: IAction<CompetitiveBiddingTypes>): ListState {
  switch (action.type) {
    case CompetitiveBiddingTypes.FETCH_CB_LIST_REQUEST:
      return loading(state);
    case CompetitiveBiddingTypes.FETCH_CB_LIST_SUCCESS:
      return loaded(mergeRight(state, {data: action.data.data, totalCount: action.data.count}));
    case CompetitiveBiddingTypes.LINK_PR_TO_CB:
      return mergeRight(state, {isAssociating: true, associatingCbId: action.cbId});
    case CompetitiveBiddingTypes.LINK_PR_TO_CB_SUCCESS:
      return mergeRight(state, {isAssociating: false, associatingCbId: null});
    case CompetitiveBiddingTypes.LINK_PR_TO_CB_FAILURE:
      return error(action.error)(mergeRight(state, {isAssociating: false, associatingCbId: null}));
    case CompetitiveBiddingTypes.FETCH_CB_LIST_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}

export const cbListStateSelector = ['cb', 'list'];
export const cbListDomainName = 'CB_LIST';
export const {reducer: QueryableCBListReducer, sagas: queryCBListSagas, actions: QueryCBListActions} = QueryableReducer<ListState>(cbListDomainName, cbListStateSelector, CbListReducer, newCbQuery());
