import React, {useState} from "react";
import {push} from "connected-react-router";
import {
  ContextMenu,
  MyAccountText,
  StyledAvatar,
  StyledIconButton,
  StyledToolbar,
} from "./header.styles";
import AccountCircle from '@material-ui/icons/AccountCircle';
import {connect, useDispatch} from "react-redux";
import {Dispatch} from "redux";
import {SavePreferredCompany} from "../../actions/user.actions";
import {IConfigurableSettings, User} from "../../domains/user/user";
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined";
import {IAction} from "../../domains/core/actions";
import UserSettingsMenu from "../menu/index";
import {VerticalDivider} from "../layout/styles";
import {SharedStateStore} from "../../reducers";

interface HeaderProps {
  currentUser: User;
}

interface HeaderDispatcher {
  saveSettings: (data: IConfigurableSettings, done: any)=>{};
}

interface ToolbarHeaderProps extends HeaderProps, HeaderDispatcher {
  sidebarOpen: boolean;
}

function mapStateToProps({shared}: { shared: SharedStateStore }): HeaderProps {
  return {
    currentUser: shared.user.currentUser
  }
}

function mapDispatchToProps(dispatch: Dispatch<IAction>): HeaderDispatcher {
  return {
    saveSettings: (data: IConfigurableSettings, done: any) => dispatch(new SavePreferredCompany(data, done))
  }
}

function ToolbarHeader(props: ToolbarHeaderProps) {
  const {sidebarOpen, currentUser} = props;
  const dispatch = useDispatch();
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const settingMenuOpen = Boolean(settingsAnchorEl);

  return (
    <StyledToolbar>
      {!sidebarOpen && <a onClick={() => dispatch(push('/'))}><img src={process.env.PUBLIC_URL + '/images/white-logo.png'}/></a>}
      <div style={{flexGrow: 1}}/>
      <VerticalDivider/>
      <ContextMenu onClick={(event) => setSettingsAnchorEl(event.currentTarget)}>
        {currentUser && currentUser.photoUrl ? <StyledAvatar src={currentUser.photoUrl} alt="user avatar"/> :
          <StyledIconButton
            edge="end"
            aria-label="Account of current user"
            aria-haspopup="true"
            color="inherit">
            <AccountCircle/>
          </StyledIconButton>}
        {
          currentUser && <MyAccountText>
            {currentUser.name}
            <span>{currentUser.userRole}</span>
          </MyAccountText>
        }
        <KeyboardArrowDownOutlined/>
      </ContextMenu>
      {
        currentUser && <UserSettingsMenu isAdmin={currentUser.isAdmin}
                                         currentUser={currentUser}
                                         showFeedback={currentUser.isUserFeedbackRequired}
                                         open={settingMenuOpen} anchorEl={settingsAnchorEl}
                                         onClose={() => setSettingsAnchorEl(null)}
                                         onApply={props.saveSettings}/>
      }
    </StyledToolbar>
  )
}

export const Header = connect(mapStateToProps, mapDispatchToProps)(ToolbarHeader);
