import {ApprovalStatusReducers, ApprovalStatusState} from "./approvalStatus.reducers";
import {combineReducers, Reducer} from "redux";

export interface approvalStatusStateStore {
    ApprovalStatus : ApprovalStatusState
}

export const approvalStatusCombinedReducers: Reducer<approvalStatusStateStore> = combineReducers({
    ApprovalStatus : ApprovalStatusReducers
});
