import "antd/dist/antd.css";
import { TreeSelect } from "antd";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        treeSelectClass: {
            "&& div": {
                height: "100%"
            },
            "&& .ant-select-selector": {
                color: "rgba(0, 0, 0, 0.65)"
            }
        }
    })
);
const prPoTypeTreeData = [
    {
        "title": "NB - Standard PR/PO",
        "value": "NB",
        "children": [
            {
                "title": "NB - SAP",
                "value": "NB - SAP",
            },
            {
                "title": "NB - EasyRequest",
                "value": "NB - EasyRequest",
            },
            {
                "title": "NB - ServiceNow",
                "value": "NB - ServiceNow",
            }
        ]
    },
    {
        "title": "FO - Framework Requisition/Order",
        "value": "FO",
        "children": [
            {
                "title": "FO - SAP",
                "value": "FO - SAP",
            },
            {
                "title": "FO - EasyRequest",
                "value": "FO - EasyRequest",
            },
            {
                "title": "FO - ServiceNow",
                "value": "FO - ServiceNow",
            }
        ]
    },
    {
        "title": "Z004 - Capex PR/PO",
        "value": "Z004",
        "children": [
            {
                "title": "Z004 - SAP",
                "value": "Z004 - SAP",
            },
            {
                "title": "Z004 - EasyRequest",
                "value": "Z004 - EasyRequest",
            }
        ]
    },
    {
        "title": "Z008 - Call Off PR/PO",
        "value": "Z008",
    },
    {
        "title": "ZARB - ARIBA Purchase Requisition/Order",
        "value": "ZARB",
    },
    {
        "title": "Z014 - Transportation PR/PO",
        "value": "Z014"
    }
];
export const PrPoTypeComponent = ({ onChange, initialValue }) => {
    const classes = useStyles({});

    const [selectedPrPoType, setSelectedPrPoType] = React.useState(initialValue ? initialValue : []);

    const onChangePrPoType = (
        value: any[]
    ) => {
        console.log([...selectedPrPoType, ...value]);
        setSelectedPrPoType(value);
        onChange(value);
    };

    const userScopeProps = {
        treeData: prPoTypeTreeData,
        value: selectedPrPoType,
        onChange: onChangePrPoType,
        disabled: false,
        treeCheckable: true,
        treeCheckStrictly: false,
        dropdownMatchSelectWidth: false,
        // labelInValue: true,
        treeNodeFilterProp: "title",
        showCheckedStrategy: TreeSelect.SHOW_PARENT,
        placeholder: "Click here to select PR/PO Type",
        style: { width: "100%", minHeight: "38px" }
    };

    return (
        <TreeSelect
            {...userScopeProps}
            dropdownStyle={{ zIndex: 99999 }}
            className={classes.treeSelectClass}
        />
    );
}

export enum prPoTypeLabels {
    "NB" = 'Standard PR/PO',
    "NB - SAP" = 'Standard PR/PO',
    "NB - EasyRequest" = 'Standard PR/PO',
    "NB - ServiceNow" = 'Standard PR/PO',
    "FO" = 'Framework Requisition/Order',
    "FO - SAP" = 'Framework Requisition/Order',
    "FO - EasyRequest" = 'Framework Requisition/Order',
    "FO - ServiceNow" = 'Framework Requisition/Order',
    "Z004" = 'Capex PR/PO',
    "Z004 - SAP" = 'Capex PR/PO',
    "Z004 - EasyRequest" = 'Capex PR/PO',
    "ZARB" = "ARIBA Purchase Requisition/Order",
    "Z014" = "Transportation PR/PO",
    "Z008" = "Call Off PR/PO"
} 