import {PRSubstitutionReducers, PRSubstitutionState} from "./prSubstitution.reducers";
import {combineReducers, Reducer} from "redux";

export interface prSubstitutionStateStore {
    PRSubstitution : PRSubstitutionState
}

export const prSubstitutionCombinedReducers: Reducer<prSubstitutionStateStore> = combineReducers({
    PRSubstitution : PRSubstitutionReducers
});
