import {ContractActionTypes} from "../../actions/contract.actions";
import {IAction} from "../../../shared/domains/core/actions";

export interface ValidationErrorsState {
  validationErrors: any[],
  loading: boolean,
  error: any,
}

export const initialValidationErrorsState: ValidationErrorsState = {
  validationErrors: [],
  loading: false,
  error: null,
};

export function ValidationErrorsReducer(state: ValidationErrorsState = initialValidationErrorsState, action: IAction<ContractActionTypes>): ValidationErrorsState {
  switch (action.type) {
    case ContractActionTypes.FETCH_DRAFT_ERRORS:
      return {
        ...state,
        loading: true,
        validationErrors: []
      };
    case ContractActionTypes.FETCH_DRAFT_ERRORS_SUCCESS:
      return {
        ...state,
        loading: false,
        validationErrors: action.items,
      };
    case ContractActionTypes.FETCH_DRAFT_ERRORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
