export class DocflowChat {
  constructor(
    public readonly spender: string[],
    public readonly budgetApprover: string,
    public readonly poCreator: string,
    public readonly poApprover: string,
    public readonly goodReceiver: string,
    public readonly invoiceReceiver: string,
    public readonly supplier: string,
  ) {
  }

  static fromBackend({spender}): DocflowChat {
    return new DocflowChat(spender.split(";"), null, null, null, null, null, null)
  }
}

