import {
  Action,
  DownloadCompletedAction,
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../shared/domains/core/actions";
import {SuccessNotificationAction} from "../../shared/domains/core/notifications";
import {ContractSource, ExcelJobStatus} from "../domains/contract/contract";

export enum ContractDownloadUploadActionTypes {
  RESET_CONTRACT_UPLOAD_PENDING = "RESET_CONTRACT_UPLOAD_PENDING",

  DOWNLOAD_CONTRACT = "DOWNLOAD_CONTRACT",
  DOWNLOAD_CONTRACT_SUCCESS = "DOWNLOAD_CONTRACT_SUCCESS",
  DOWNLOAD_CONTRACT_FAILURE = "DOWNLOAD_CONTRACT_FAILURE",

  UPLOAD_CONTRACT = "UPLOAD_CONTRACT",
  UPLOAD_CONTRACT_SUCCESS = "UPLOAD_CONTRACT_SUCCESS",
  UPLOAD_CONTRACT_FAILURE = "UPLOAD_CONTRACT_FAILURE",

  EXPORT_CONTRACT_TEMPLATE = "EXPORT_CONTRACT_TEMPLATE",
  EXPORT_CONTRACT_TEMPLATE_SUCCESS = "EXPORT_CONTRACT_TEMPLATE_SUCCESS",
  EXPORT_CONTRACT_TEMPLATE_FAILURE = "EXPORT_CONTRACT_TEMPLATE_FAILURE",

  FETCH_EXCEL_HAS_PENDING_JOB = "FETCH_EXCEL_HAS_PENDING_JOB",
  FETCH_EXCEL_HAS_PENDING_JOB_SUCCESS = "FETCH_EXCEL_HAS_PENDING_JOB_SUCCESS",
  FETCH_EXCEL_HAS_PENDING_JOB_FAILURE = "FETCH_EXCEL_HAS_PENDING_JOB_FAILURE",

  FETCH_EXCEL_JOB_STATUSES = "FETCH_EXCEL_JOB_STATUSES",
  FETCH_EXCEL_JOB_STATUSES_SUCCESS = "FETCH_EXCEL_JOB_STATUSES_SUCCESS",
  FETCH_EXCEL_JOB_STATUSES_FAILURE = "FETCH_EXCEL_JOB_STATUSES_FAILURE",

  FETCH_EXCEL_JOB_ERROR_DETAILS = "FETCH_EXCEL_JOB_ERROR_DETAILS",
  FETCH_EXCEL_JOB_ERROR_DETAILS_SUCCESS = "FETCH_EXCEL_JOB_ERROR_DETAILS_SUCCESS",
  FETCH_EXCEL_JOB_ERROR_DETAILS_FAILURE = "FETCH_EXCEL_JOB_ERROR_DETAILS_FAILURE",

  FETCH_EXCEL_JOB_HISTORY = "FETCH_EXCEL_JOB_HISTORY",
  FETCH_EXCEL_JOB_HISTORY_SUCCESS = "FETCH_EXCEL_JOB_HISTORY_SUCCESS",
  FETCH_EXCEL_JOB_HISTORY_FAILURE = "FETCH_EXCEL_JOB_HISTORY_FAILURE",

  RESET_CONTRACT_UPLOAD_ERROR = "RESET_CONTRACT_UPLOAD_ERROR",
}

export class ResetContractUploadPending extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.RESET_CONTRACT_UPLOAD_PENDING;
}

export class DownloadContract extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.DOWNLOAD_CONTRACT;

  constructor(public contract) {
    super();
  }
}

export class DownloadContractSuccess extends DownloadCompletedAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.DOWNLOAD_CONTRACT_SUCCESS;
}

export class DownloadContractError extends ErrorAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.DOWNLOAD_CONTRACT_FAILURE;
}

export class UploadContract extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.UPLOAD_CONTRACT;

  constructor(public formData, public isCreatingNew, public contractSource: ContractSource) {
    super();
  }
}

export class UploadContractSuccess extends SuccessNotificationAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.UPLOAD_CONTRACT_SUCCESS;

  constructor(public message) {
    super();
  }
}

export class UploadContractError extends ErrorAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.UPLOAD_CONTRACT_FAILURE;
}


export class FetchExcelJobStatuses extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_STATUSES;

  constructor(public contractNo?) {
    super();
  }
}

export class FetchExcelJobStatusesSuccess extends SuccessAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_STATUSES_SUCCESS;

  constructor(public statuses: ExcelJobStatus[]) {
    super();
  }
}

export class FetchExcelJobStatusesError extends ErrorAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_STATUSES_FAILURE;
}

export class FetchExcelJobErrorDetails extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_ERROR_DETAILS;

  constructor(public batchId) {
    super();
  }
}

export class FetchExcelJobErrorDetailsSuccess extends SuccessAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_ERROR_DETAILS_SUCCESS;

  constructor(public batchId, public statuses: ExcelJobStatus[]) {
    super();
  }
}

export class FetchExcelJobErrorDetailsError extends ErrorAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_ERROR_DETAILS_FAILURE;
}

export class FetchExcelHasPendingJob extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_HAS_PENDING_JOB;

  constructor(public contractNo?) {
    super();
  }
}

export class FetchExcelHasPendingJobSuccess extends SuccessAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_HAS_PENDING_JOB_SUCCESS;

  constructor(public hasPending: boolean) {
    super();
  }
}

export class FetchExcelHasPendingJobError extends ErrorAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_HAS_PENDING_JOB_FAILURE;
}

export class FetchExcelJobHistory extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_HISTORY;
}

export class FetchExcelJobHistorySuccess extends SuccessAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_HISTORY_SUCCESS;

  constructor(public statuses: ExcelJobStatus[]) {
    super();
  }
}

export class FetchExcelJobHistoryError extends ErrorAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_HISTORY_FAILURE;
}

export class ResetContractUploadError extends Action<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.RESET_CONTRACT_UPLOAD_ERROR;
}

export class ExportContractTemplate extends PendingAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.EXPORT_CONTRACT_TEMPLATE;
}

export class ExportContractTemplateSuccess extends DownloadCompletedAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.EXPORT_CONTRACT_TEMPLATE_SUCCESS;
}

export class ExportContractTemplateError extends ErrorAction<ContractDownloadUploadActionTypes> {
  public readonly type = ContractDownloadUploadActionTypes.EXPORT_CONTRACT_TEMPLATE_FAILURE;
}
