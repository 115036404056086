import * as R from "ramda";
import { Dictionary } from "ramda";
import { CreationDateFilter, FilterCategoryView, newTextFilterCategory } from "src/app/shared/domains/query/filter";
import { IPslFilters, IUserScopeFilters } from "src/app/shared/domains/user/user";
import { Query, newQuery, setSelectedFiltersBykey } from "src/app/shared/queryable/query";
import { capitalize } from "src/app/shared/utils/global";
import moment from "moment";
import { PataflagService } from "src/app/shared/services/pataflag.service";
import { Features } from "src/app/shared/domains/core/pataflag";
import { SupplierFilterTabs } from "src/app/preferredSuppliers/domains/psl";
import { checkPslExactId } from "src/app/preferredSuppliers/containers/list/preferredSuppliers.page";

export enum PslCatalogsFilterKeys {
  SPEND_CAT_1 = 'SpendCat1Code',
  SPEND_CAT_2 = 'SpendCat2Code',
  SPEND_CAT_3 = 'SpendCat3Code',
  COMP_CODE = 'CompanyCode',
  MARKETS = 'Markets',
  STATUS = 'StatusId',
  SUPPLIER = 'SupplierId',
  MATERIAL_NUMBER = 'MaterialNumber',
  BUSINESS_UNITS = 'BusinessUnits',
  FROM_SPEND = 'fromSpend',
  TO_SPEND = 'toSpend',
  CURRENCY_SPEND = 'currencySpend',
  BUYERS = 'StrategicBuyers',
  PLANT = "plants",
  PARENT_PSL = "childrenOf",
  SUPPLIER_DIVERISTY = "SupplierDiversity",
  SCOPE = "Scope",
  PSL_CREATION_AND_VALIDITY_DATE = "creationAndValidityDate",
  LOCATION = "location",
  ZONE = 'zones',
  PSLIDS = 'pslIds',
  WAY_OF_BUYING = 'WayOfBuying',
  SHOW_OBSOLETE = 'ShowObsolete',
  SUPPLIER_DISCRIPTION = 'supplierDescription',
}

export const pslCatalogsSearchOptions = [
  { value: 'all', label: "All" },
  { value: 'material', label: "Material" },
  { value: 'materialDescription', label: 'Material Description' },
  { value: 'plant', label: 'Plant' },
  { value: 'supplier', label: 'Supplier' },
  { value: 'supplierPartId', label: 'Supplier Part iD' },
  { value: 'shortDescription', label: 'Short Description' },
  { value: 'scopeOfApplication', label: 'Scope of Application' },
  { value: 'keywords', label: 'Keywords' },
  { value: 'manufacturerName', label: 'Manufacturer Name' },
  { value: 'manufacturerPartNo', label: 'Manufacturer Part Number' }
];


export const PslCatalogsFilters = [
  { key: PslCatalogsFilterKeys.SPEND_CAT_1, label: 'Spend Category L1', showAdd: true },
  { key: PslCatalogsFilterKeys.SPEND_CAT_2, label: 'Spend Category L2', showAdd: true },
  { key: PslCatalogsFilterKeys.SPEND_CAT_3, label: 'Spend Category L3', showAdd: true },
  { key: PslCatalogsFilterKeys.LOCATION, label: 'Location', showAdd: false },
  { key: PslCatalogsFilterKeys.COMP_CODE, label: 'Company code', showAdd: true },
  { key: PslCatalogsFilterKeys.PLANT, label: 'Plant', showAdd: true },
  { key: PslCatalogsFilterKeys.MARKETS, label: 'Markets', showAdd: true },
  { key: PslCatalogsFilterKeys.STATUS, label: 'Status', showAdd: true },
  { key: PslCatalogsFilterKeys.SUPPLIER, label: 'Supplier', showAdd: true },
  { key: PslCatalogsFilterKeys.MATERIAL_NUMBER, label: 'Material Number', showAdd: true },
  { key: PslCatalogsFilterKeys.SCOPE, label: 'Scope', showAdd: true },
  { key: PslCatalogsFilterKeys.PSLIDS, label: 'PSL IDs', showAdd: true },
  { key: PslCatalogsFilterKeys.BUSINESS_UNITS, label: 'Business Unit', showAdd: true },
  { key: PslCatalogsFilterKeys.CURRENCY_SPEND, label: 'Currency', showAdd: false },
  { key: PslCatalogsFilterKeys.FROM_SPEND, label: 'From', showAdd: false },
  { key: PslCatalogsFilterKeys.TO_SPEND, label: 'To', showAdd: false },
  { key: PslCatalogsFilterKeys.PARENT_PSL, label: 'Parent PSL', showAdd: false },
  { key: PslCatalogsFilterKeys.PSL_CREATION_AND_VALIDITY_DATE, label: 'PSL Creation and Validity Date', showAdd: true },
  { key: PslCatalogsFilterKeys.BUYERS, label: 'Strategic Buyer', showAdd: true },
  { key: PslCatalogsFilterKeys.WAY_OF_BUYING, label: 'Way Of Buying', showAdd: true },
  { key: PslCatalogsFilterKeys.SUPPLIER_DIVERISTY, label: 'Supplier Diversity', showAdd: true },
  { key: PslCatalogsFilterKeys.ZONE, label: 'Zones', showAdd: true },
  { key: PslCatalogsFilterKeys.SHOW_OBSOLETE, label: 'Display Obsolete PSLs', showAdd: true },
];

const initial = PslCatalogsFilters.map(x => newTextFilterCategory(x.key, x.label));
const initialDictionary = R.indexBy(R.prop('key'), initial);

const setShowObsolete = (value: any = false) => {
  if ((Array.isArray(value) && value?.length === 0) || !value) return false;
  return true
};

export function pslCatalogsQuery(defaultFilters?: Partial<IPslFilters>, term?: string): Query {
  let initialFilter: Dictionary<FilterCategoryView> = initialDictionary;
  initialFilter = R.assocPath([PslCatalogsFilterKeys.SHOW_OBSOLETE, 'selected'], setShowObsolete(initialFilter?.ShowObsolete?.selected), initialFilter);

  console.log(defaultFilters);
  if (defaultFilters) {
    initialFilter = R.assocPath([PslCatalogsFilterKeys.PSLIDS, 'selected'], [], initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.SPEND_CAT_1, defaultFilters.spendCatL1s, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.SPEND_CAT_2, defaultFilters.spendCatL2s, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.SPEND_CAT_3, defaultFilters.spendCatL3s, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.PLANT, defaultFilters.plants, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.COMP_CODE, defaultFilters.companies, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.STATUS, defaultFilters.statuses, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.SUPPLIER, defaultFilters.suppliers, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.MATERIAL_NUMBER, defaultFilters.materials, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.MARKETS, defaultFilters.markets, initialFilter);
    initialFilter = R.assocPath([PslCatalogsFilterKeys.LOCATION, 'selected'], [], initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.BUSINESS_UNITS, defaultFilters.businessUnits, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.BUYERS, defaultFilters.strategicBuyers, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.SUPPLIER_DIVERISTY, defaultFilters.supplierDiversity, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.SCOPE, defaultFilters.scope, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.ZONE, defaultFilters.zones, initialFilter);
    initialFilter = R.assocPath([PslCatalogsFilterKeys.FROM_SPEND, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslCatalogsFilterKeys.TO_SPEND, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslCatalogsFilterKeys.PARENT_PSL, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslCatalogsFilterKeys.CURRENCY_SPEND, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslCatalogsFilterKeys.SHOW_OBSOLETE, 'selected'], setShowObsolete(defaultFilters.showObsolete), initialFilter);
    initialFilter = R.assocPath([PslCatalogsFilterKeys.SUPPLIER_DISCRIPTION, 'selected'], defaultFilters.supplierDescription, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.WAY_OF_BUYING, defaultFilters.wayOfBuying, initialFilter);
    if (defaultFilters.pslCreationAndValidityDate) {
      initialFilter = setSelectedFiltersBykey(PslCatalogsFilterKeys.PSL_CREATION_AND_VALIDITY_DATE, defaultFilters.pslCreationAndValidityDate[0], initialFilter);
    }
  }
  return {
    ...newQuery(initialFilter),
    term: term,
  };
}

export function pslCatalogs_pslFiltersToBackend(query: any, userScope: IUserScopeFilters, time: SupplierFilterTabs = null, exactId = null, pslSearch = null, materialSearch = null) {
  let tempId: any = checkPslExactId(exactId);
  let PSL_163770_VIEW_CHILD_PSL_FROM_PARENT = PataflagService.getInstance().flag(Features.PSL_163770_VIEW_CHILD_PSL_FROM_PARENT);
  let isChildrenOf = query?.filters["childrenOf"]?.selected?.toString().length > 0;
  return {
    spendCatL1s: (query.filters?.SpendCat1Code?.selected ?? []).concat(userScope?.spendCatL1s ?? []),
    spendCatL2s: (query.filters?.SpendCat2Code?.selected ?? []).concat(userScope?.spendCatL2s ?? []),
    spendCatL3s: (query.filters?.SpendCat3Code?.selected ?? []).concat(userScope?.spendCatL3s ?? []),
    companies: (query.filters?.CompanyCode?.selected ?? []).concat(userScope?.companies ?? []),
    markets: (query.filters?.Markets?.selected ?? []).concat(userScope?.markets ?? []),
    statuses: query.filters?.statusId?.selected ?? [],
    complianceStatuses: query.filters?.ComplianceStatuses?.selected ? query.filters.ComplianceStatuses.selected : [],
    suppliers: query.filters?.SupplierId?.selected ? query.filters.SupplierId.selected : [],
    materials: query.filters?.MaterialNumber?.selected ? query.filters.MaterialNumber.selected : [],
    businessUnits: query.filters?.BusinessUnits?.selected ? query.filters.BusinessUnits.selected : [],
    strategicBuyers: query.filters?.StrategicBuyers?.selected ? query.filters.StrategicBuyers.selected : [],
    pslStatuses: query.filters?.PslStatuses?.selected ? query.filters.PslStatuses.selected : [{ id: 1 }],
    supplierDiversity: query.filters?.SupplierDiversity?.selected ? query.filters.SupplierDiversity.selected : [],
    wayOfBuying: query.filters?.WayOfBuying?.selected ? query.filters.WayOfBuying.selected : [],
    scope: query?.filters?.taskKind !== "APPROVAL" ? query.filters?.Scope?.selected ? query.filters.Scope.selected : null : null,
    time: 'active',
    exactId: PSL_163770_VIEW_CHILD_PSL_FROM_PARENT ? (exactId && parseInt(tempId) && !isChildrenOf) ? parseInt(tempId) : null : (exactId && parseInt(tempId)) ? parseInt(tempId) : null,
    taskKind: query?.filters?.taskKind ? query.filters.taskKind : null,
    createdByUserId: query?.filters?.createdByUserId ? query.filters.createdByUserId : null,
    fromSpend: query.filters?.fromSpend?.selected?.length > 0 ? query.filters?.fromSpend?.selected : "",
    toSpend: query.filters?.toSpend?.selected?.length > 0 ? query.filters?.toSpend?.selected : "",
    childrenOf: query.filters?.childrenOf?.selected?.toString().length > 0 ? query.filters?.childrenOf?.selected?.id : "",
    currencySpend: query.filters?.currencySpend?.selected?.length > 0 ? query.filters?.currencySpend?.selected : "",
    plants: !R.isNil(query.filters?.plants?.selected) ? query.filters?.plants?.selected : "",
    orderBy: (query.orderBy && query.orderBy.length > 0 && query.orderBy[0].field) ? {
      field: capitalize(query.orderBy[0].field),
      direction: query.orderBy[0].direction
    } : null,
    pslCreationAndValidityDate:
      (Array.isArray(query?.filters?.creationAndValidityDate?.selected) && query?.filters?.creationAndValidityDate?.selected?.length === 0) ? null :
        (query?.filters?.creationAndValidityDate?.selected?.type && query?.filters?.creationAndValidityDate?.selected?.startDate &&
          query?.filters?.creationAndValidityDate?.selected?.endDate) ?
          {
            startDate: moment.utc(query?.filters?.creationAndValidityDate?.selected?.startDate, 'YYYYMMDD'),
            endDate: moment.utc(query?.filters?.creationAndValidityDate?.selected?.endDate, 'YYYYMMDD').add(23, 'hours').add(59, 'minutes').add(59, 'seconds').add(999, 'milliseconds'),
            type: query?.filters?.creationAndValidityDate?.selected?.type
          } : null,
    zones: (query.filters?.zones?.selected) ? query.filters?.zones?.selected : [],
    pslIds: (query.filters?.pslIds?.selected) ? query.filters?.pslIds?.selected?.map(el => ({ id: el.id })) : [],
    pslSearch: !PSL_163770_VIEW_CHILD_PSL_FROM_PARENT ? pslSearch : !isChildrenOf ? pslSearch : null,
    materialSearch: materialSearch,
    showObsolete: setShowObsolete(query.filters?.ShowObsolete?.selected),
    FavoriteSwitchOn: query?.FavoriteSwitchOn ? query.FavoriteSwitchOn : false,
    supplierDescription: query.filters?.supplierDescription?.selected ?? []
  };
}

export const saveFilters = (filters: IPslFilters) => {
  const dateRange: CreationDateFilter = R.path(['creationAndValidityDate', 'selected'], filters);
  const tempFilters: IPslFilters = {
    spendCatL1s: R.path(['SpendCat1Code', 'selected'], filters) ?? [],
    spendCatL2s: R.path(['SpendCat2Code', 'selected'], filters) ?? [],
    spendCatL3s: R.path(['SpendCat3Code', 'selected'], filters) ?? [],
    companies: R.path(['CompanyCode', 'selected'], filters) ?? [],
    markets: R.path(['Markets', 'selected'], filters) ?? [],
    zones: R.path(['zones', 'selected'], filters) ?? [],
    suppliers: R.path(['SupplierId', 'selected'], filters) ?? [],
    materials: R.path(['MaterialNumber', 'selected'], filters) ?? [],
    statuses: R.path(['StatusId', 'selected'], filters) ?? [],
    businessUnits: R.path(['BusinessUnits', 'selected'], filters) ?? [],
    strategicBuyers: R.path(['StrategicBuyers', 'selected'], filters) ?? [],
    supplierDiversity: R.path(['SupplierDiversity', 'selected'], filters) ?? [],
    wayOfBuying: R.path(['WayOfBuying', 'selected'], filters) ?? [],
    scope: R.path(['Scope', 'selected'], filters) ?? [],
    showObsolete: setShowObsolete(R.path(['ShowObsolete', 'selected'], filters)) ?? false,
    supplierDescription: R.path(['supplierDescription', 'selected'], filters) ?? [],
    plants: R.path(['plants', 'selected'], filters),
    pslCreationAndValidityDate: (dateRange?.startDate && dateRange?.endDate) ?
      {
        startDate: moment.utc(dateRange.startDate, 'YYYYMMDD'),
        endDate: moment.utc(dateRange.endDate, 'YYYYMMDD'),
        type: dateRange.type,
        buttonName: null
      } : null,
  };

  return tempFilters;
}
