import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Application from "./app/application";
import {unregister} from './registerServiceWorker'
import './i18n';
import {msalAuthProvider} from "./app/shared/domains/auth/msal.provider";
import {AzureAD} from "react-aad-msal";
import {isLocalEnvironment} from "./app/shared/utils/global";

unregister();

if ((module as any).hot) {
  (module as any).hot.accept();
}

renderApp();

function renderApp() {
  const render = () => {
    if (!isLocalEnvironment()) {
      return (
        <AzureAD provider={msalAuthProvider} forceLogin={true}>
          <Application/>
        </AzureAD>
      );
    }
    return <Application/>;
  };

  ReactDOM.render(
    render(),
    document.getElementById('root') as HTMLElement
  );
};

