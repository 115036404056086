import {ErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";

export enum DocumentCounterTypes {
  FETCH_DOCCOUNTERS = "FETCH_DOCCOUNTERS",
  FETCH_DOCCOUNTERS_SUCCESS = "FETCH_DOCCOUNTERS_SUCCESS",
  FETCH_DOCCOUNTERS_FAILURE = "FETCH_DOCCOUNTERS_FAILURE",
}

export class DocumentCountersAction extends PendingAction<DocumentCounterTypes> {
  public readonly type = DocumentCounterTypes.FETCH_DOCCOUNTERS;
}

export class DocumentCountersSuccessAction extends SuccessAction<DocumentCounterTypes> {
  public readonly type = DocumentCounterTypes.FETCH_DOCCOUNTERS_SUCCESS;
}

export class DocumentCountersFailureAction extends ErrorAction<DocumentCounterTypes> {
  public readonly type = DocumentCounterTypes.FETCH_DOCCOUNTERS_FAILURE;
}
