import {keys} from 'ramda';
import {isNumeric} from "rxjs/internal-compatibility";

export enum FeedbackType {
  BUG = "bug",
  IMPROVEMENT = "improvement",
  FEATURE = 'feature'
}

export interface UserFeedbackNetworkDTO {
  Id: string;
  UserName: string;x
  Email: string;
  Type: string;
  Message: string;
  Status:string;
  Rating: number;
  NetPromoterScore: number;
  CreatedOn: string;
  Module: string;
  voteCount: number;
  VotedSelf: boolean;
}

export interface UserNewFeedbackNetworkDTO {
  admin: string;
  id: string;
  userName: string;
  email: string;
  type: string;
  message: string;
  status: number;
  rating: number;
  netPromoterScore: number;
  createdOn: string;
  module: string;
  voteCount: number;
  votedSelf: boolean;
}

export class UserNewFeedback {
  constructor(
    public admin: string,
    public id: string,
    public userName: string,
    public email: string,
    public type: string,
    public message: string,
    public status: number,
    public rating: number,
    public netPromoterScore: number,
    public createdOn: string,
    public module: string,
    public voteCount: number,
    public votedSelf: boolean,
  ) {}

  static FromBackend(feedback: UserNewFeedbackNetworkDTO): UserNewFeedback {
    return new UserNewFeedback(
      feedback.admin,
      feedback.id,
      feedback.userName,
      feedback.email,
      feedback.type,
      feedback.message,
      feedback.status,
      feedback.rating,
      feedback.netPromoterScore,
      feedback.createdOn,
      feedback.module,
      feedback.voteCount || 0,
      feedback.votedSelf || false,
    )
  }
}

export class UserFeedback {
  constructor(
    public Id: string,
    public UserName: string,
    public Email: string,
    public Type: string,
    public Message: string,
    public Status: string,
    public Rating: number,
    public NetPromoterScore: number,
    public CreatedOn: string,
    public Module: string,
    public voteCount: number,
    public VotedSelf: boolean,
  ) {}

  static FromBackend(feedback: UserFeedbackNetworkDTO): UserFeedback {
    return new UserFeedback(
      feedback.Id,
      feedback.UserName,
      feedback.Email,
      feedback.Type,
      feedback.Message,
      feedback.Status,
      feedback.Rating,
      feedback.NetPromoterScore,
      feedback.CreatedOn,
      feedback.Module,
      feedback.voteCount || 0,
      feedback.VotedSelf || false,
    )
  }

  static FeedbackTypeFilterable() {
    return keys(FeedbackType).filter((k => !isNumeric(k)))
      .map(key => ({
        id: FeedbackType[key],
        title: key
      }))
  }

  static RatingFilterable() {
    return new Array(6).fill(0).map((v, idx) => ({ id: idx, title: idx.toString() })).filter(v => !!v.id);
  }

  static NetPromoterScoreFilterable() {
    return new Array(11).fill(0).map((v, idx) => ({ id: idx, title: idx.toString() })).filter(v => !!v.id);
  }
}

export class UserRatingNetworkDTO {
  Id: number;
  UserName: string;
  Email: string;
  Rating: string;
  NetPromoterScore: string;
  CreatedOn: string;
  Module: string;
}

export class UserRating {
  constructor(
    public Id: number,
    public UserName: string,
    public Email: string,
    public Rating: number,
    public NetPromoterScore: number,
    public CreatedOn: string,
    public Module: string
  ) {}

  static FromBackend(feedback: UserRatingNetworkDTO): UserRating {
    return new UserRating(
      feedback.Id,
      feedback.UserName,
      feedback.Email,
      parseInt(feedback.Rating),
      parseInt(feedback.NetPromoterScore),
      feedback.CreatedOn,
      feedback.Module
    )
  }
}
