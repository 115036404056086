import styled from "styled-components";
import {Form} from "formik";
import {StyledDialog, StyledDialogContent} from "../../../../components/layout/styles";

export const StyledUserFeedbackDialog = styled(StyledDialog)`
  && .MuiDialog-paperWidthXs {
    width: 550px;
    padding: 0;
  }
`;

export const StyledConfirmDialog = styled(StyledDialog)`
  && .MuiDialog-paperWidthXs {
    max-width: 600px;
    padding: 0;
  }
`;

export const StyledEditPrDialog = styled(StyledDialog)`
  && .MuiDialog-paperWidthXs {
    width : 1000px
    padding: 0;
    max-width: 1000px!important;
  }
`;
export const StyledFeedbackForm = styled(Form)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const FeedbackDialogContent = styled(StyledDialogContent)`
  overflow-y: auto!important;
  overflow-x: hidden;
`;

export const FeedbackDetails = styled.div`
  textarea {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    height: 100px;
    margin-bottom: 10px;
  }
`;

export const FeedbackList = styled.ul`
  display: flex;
  list-style: none;
  margin-bottom: 10px;
  padding: 0;
  .item {
    color: rgb(51, 51, 51);
    border-color: rgb(0, 136, 255);
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
    margin-right: -1px;
    border: 1px solid rgb(208, 216, 225);
    text-align: center;
    .text {
      padding: 5px;
    }
    &.selected {
      background: #F19001;
      color: #fff;
    }
    &.type {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 50%;
    }
    &.score {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: calc(100% / 11);
    }
    :last-child {
      margin-left: -1px;
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

