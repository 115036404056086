import { Icon, IconButton } from "@material-ui/core";
import * as React from "react";
import { Fragment } from "react";
import { NotificationType } from "src/app/shared/components/notification";
import { SnackbarWrapper, StyledSnackbar } from "src/app/shared/components/notification/notification.styles";
import CloseIcon from '@material-ui/icons/Close';

interface NotificationProps {
    message: string;
    type: NotificationType;
    open:boolean;
    setOpen:Function;
  }

export const NbsNotification = (props: NotificationProps) => {
    const { message, type,open,setOpen } = props;
  
    const handleClose = () => {
      setOpen(null);
    }
  
    return <SnackbarWrapper
      className={type === NotificationType?.success ? 'success' : (type === NotificationType?.warning ? 'warning' : 'error')}>
      <StyledSnackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        message={
          <span className="message">
            {
              type === NotificationType?.success ? <Icon>done</Icon> :
                type === NotificationType?.warning ? <Icon>warning</Icon> :
                  <Icon>error</Icon>
            }
            {
              type === NotificationType?.success ? (message ? message : 'Saved successfully') :
                type === NotificationType?.error ? <>{message?.split('###').map((x, i) => <Fragment key={i}>{x.includes(": ") ? x.split(": ")[1] : x}<br /></Fragment>)}</> :
                  message
            }
          </span>
        }
        autoHideDuration={5000}
        onClose={() => handleClose()}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </SnackbarWrapper>
  };