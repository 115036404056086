import Button from "@material-ui/core/Button";
import styled from "styled-components";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";

export const StyledFilterButton = styled(Button)`
  box-shadow: none!important;
  border: solid 1px #707070!important;
  border-radius: 3px!important;
  background-color: #F5F5F5!important;
  color: #526069!important;
  font-weight: 600!important;
  margin-left: 10px!important;
  height: 36px!important;
  text-transform: capitalize!important;
  width: 80px!important;
  svg {
    font-size: 1.2rem!important;
    margin-right: 4px;
  }
`;

export const StyledEditColumnsButton = styled(Button)`
  box-shadow: none!important;
  border: solid 1px #707070!important;
  border-radius: 3px!important;
  background-color: #F5F5F5!important;
  color: #526069!important;
  font-weight: 600!important;
  margin-left: 10px!important;
  text-transform: capitalize!important;
  width: 130px!important;
  svg {
    font-size: 1.2rem!important;
  }
`;

export const StyledPrimaryButton = styled(Button)`
  box-shadow: none !important;
  border: solid 1px #f19002 !important;
  border-radius: 3px!important;
  background-color: #f19002 !important;
  color: #ffffff!important;
  padding-left:10px !important;
  padding-right:10px !important;
  font-weight: 600!important;
  margin-left: 10px!important;
  text-transform: capitalize!important;
  width: auto!important;
  height: 36px!important;
  white-space: nowrap;
  svg {
    font-size: 1.2rem!important;
  }
  &:disabled {
    opacity: 0.4 !important;
  }
`;

export const StyledSecondaryButton = styled(Button)`
  box-shadow: none !important;
  border: solid 1px #f19002 !important;
  border-radius: 3px!important;
  background-color: transparent !important;
  color: #f19002 !important;
  padding-left:10px !important;
  padding-right:10px !important;
  font-weight: 600!important;
  margin-left: 10px!important;
  text-transform: capitalize!important;
  width: auto !important;
  height:36px;
  svg {
    font-size: 1.2rem!important;
  }
`;

export const StyledDefaultButton = styled(Button)`
    box-shadow: none!important;
    border: solid 1px #707070!important;
    border-radius: 3px!important;
    background-color: #F5F5F5!important;
    color: #526069!important;
    font-weight: 600!important;
    margin-left: 10px!important;
    text-transform: capitalize!important;
    width: 80px!important;
    min-height:36px;
    svg {
      font-size: 1.2rem!important;
    }
`;

export const ChatButton = styled.div`
  @media (max-width: 1024px) {
    button {
      padding: 12px 4px;
    }
  }
`;

export const StyledBadge = withStyles({
  badge: {
    color: "#FFF",
    backgroundColor: "rgb(241, 144, 1)"
  }
})(Badge);



