import {assoc, filter, flatten} from "ramda";
import {ODataFilter, Operators, OrderDirection, SimpleODataFilter, SimpleODataOrder} from "../../../../lib/odata";
import {ContractSource, ContractType, DraftContractSource} from "./contract";
import {IContractFilters} from "../../../shared/domains/user/user";

export const UNKNOWN_VALUES = ['UNKNOWN_VALUES'];
export const SAP_CONTRACT_TYPES = ['MK', 'WK', 'ZK', 'ZS', 'TRSP'];
export const CLM_CONTRACT_TYPES = ['ZCLM', 'ZCL2', 'ZCL3', 'ZCL4'];

export interface ODataQuery {
  path: string;
  resource: string;
  page: number;
  term: string;
  filters: SimpleODataFilter[];
  orderBy: SimpleODataOrder[];
  top: number;
  isHanaSearch?: boolean;
  type?: ContractType;
  source?: ContractSource|DraftContractSource;
}

export type PartialODataQuery = Partial<ODataQuery>;

export function newContractQuery(): ODataQuery {
  return {
    path: "",
    resource: "",
    page: 0,
    term: "",
    filters: [],
    orderBy: [],
    isHanaSearch: false,
    type: ContractType.ACTIVE,
    source: ContractSource.SAP,
    top: 10
  }
}

const FilterGenerator = (field, value) => ({ field: field, value: value});
const RangeFilterGenerator = (field, value, op) => ({ field: field, value: value, operator: op});

function getDocTypesFilters(query: ODataQuery, newFilters: IContractFilters) {
  let validDocTypes = [], invalidDocTypes = [];
  if (query.source === ContractSource.SAP) {
    validDocTypes = newFilters.docTypes.filter(t => SAP_CONTRACT_TYPES.includes(t.id as string));
    invalidDocTypes = newFilters.docTypes.filter(t => !SAP_CONTRACT_TYPES.includes(t.id as string));
  } else if (query.source === ContractSource.CLM_CHILD) {
    validDocTypes = newFilters.docTypes.filter(t => CLM_CONTRACT_TYPES.includes(t.id as string));
    invalidDocTypes = newFilters.docTypes.filter(t => !CLM_CONTRACT_TYPES.includes(t.id as string));
  }

  let docTypes = ODataFilter.empty();
  if (query.source !== DraftContractSource.CLONE_CONTRACT && query.source !== DraftContractSource.CHANGES_REQUESTS) {
    if (validDocTypes.length) {
      docTypes = validDocTypes.reduce((acc, f) => acc.or(FilterGenerator("AgreementType", f.id)), ODataFilter.empty());
    } else if (invalidDocTypes.length) {
      docTypes = UNKNOWN_VALUES.reduce((acc, f) => acc.or(FilterGenerator("AgreementType", f)), ODataFilter.empty());
    } else {
      if (query.source === ContractSource.SAP) {
        docTypes = SAP_CONTRACT_TYPES.reduce((acc, f) => acc.or(FilterGenerator("AgreementType", f)), ODataFilter.empty());
      } else {
        docTypes = CLM_CONTRACT_TYPES.reduce((acc, f) => acc.or(FilterGenerator("AgreementType", f)), ODataFilter.empty());
      }
    }
  }

  return docTypes;
}

export function updateFilters(query: ODataQuery, newFilters: IContractFilters, agreementNoList = []): ODataQuery {
  const companies = newFilters.companies.reduce((acc, f) => acc.or(FilterGenerator("CompanyCode", f.id)), ODataFilter.empty());
  const pGroups = newFilters.pGroups.reduce((acc, f) => acc.or(FilterGenerator("PurchGroup", f.id)), ODataFilter.empty());
  const pOrgs = newFilters.pOrgs.reduce((acc, f) => acc.or(FilterGenerator("PurchOrganization", f.id)), ODataFilter.empty());
  const agreements = agreementNoList.reduce((acc, f) => acc.or(FilterGenerator("AgreementNo", f)), ODataFilter.empty());
  const validity = ODataFilter.empty().and(FilterGenerator('ValidityStart', newFilters.validity));
  const strategicbuyers = newFilters.strategicBuyers.reduce((acc, f) => acc.or(FilterGenerator("StrategicBuyer", f.id)), ODataFilter.empty());
  const expiryDate = newFilters.expiryRange?.reduce((acc, f, idx) => acc.or(RangeFilterGenerator("ExpiryRange", f, idx === 0?Operators.GE:Operators.LE)), ODataFilter.empty());
  const docTypes = getDocTypesFilters(query, newFilters);
  const filters = filter((f: ODataFilter) => !!f?.toString())(flatten([companies, docTypes, pGroups, pOrgs, strategicbuyers, agreements, validity, expiryDate]));
  return assoc<ODataFilter[], ODataQuery, keyof ODataQuery>("filters", filters, query);
}

export function updateValidity(validity: string, filters: IContractFilters): IContractFilters {
  return assoc("validity", validity, filters);
}

export function updateExpiredDate(expireRange, filters: IContractFilters): IContractFilters {
  return assoc("expiryRange", expireRange, filters);
}

function resetOrder(query: ODataQuery): ODataQuery {
  return assoc("orderBy", [], query)
}

export function updatePath(query: ODataQuery, newPath: string): ODataQuery {
  let newQuery = query.path !== newPath ? resetOrder(query) : query;
  return assoc("path", newPath, newQuery);
}

export function updateType(query: ODataQuery, type: string): ODataQuery {
  let newQuery = query.type !== type ? resetOrder(query) : query;
  return assoc("type", type, newQuery);
}

export function updateSource(query: ODataQuery, source: ContractSource|DraftContractSource): ODataQuery {
  let newQuery = query.source !== source ? resetOrder(query) : query;
  return assoc("source", source, newQuery);
}

export function updateOrder(query: ODataQuery, field: string, direction: OrderDirection): ODataQuery {
  return assoc("orderBy", [{field, direction}], query);
}

export function updateTerm(query: ODataQuery, newTerm: string, isDebug: boolean = false): ODataQuery {
  const withNewTerm = assoc("term", newTerm, query);
  return assoc("page", 0, withNewTerm);
}

export function updateHanaSearch(query: ODataQuery, isHanaSearch: boolean = false): ODataQuery {
  return assoc("isHanaSearch", isHanaSearch, query);
}

export function updatePage(query: ODataQuery, newPage: number): ODataQuery {
  return assoc("page", newPage, query);
}
