import {assoc, assocPath, mergeRight, remove, update, insert} from "ramda";
import {CompetitiveBiddingData} from "../domains/competitiveBidding";
import {CompetitiveBiddingTypes} from "../actions/competitiveBiddings.actions";
import {IAction} from "../../shared/domains/core/actions";
import {CurrencyData} from "../domains/selector/currency";
import {CompanyData} from "../../shared/domains/master/company";
import {SpendCategory} from "../../shared/domains/master/category";
import {error, explicitLoaded, explicitLoading, ExplicitLoadState, loaded, loading} from "../../shared/reducers/utils";
import {defaultCompany} from "../../shared/domains/user/user";

export interface CbFormState extends ExplicitLoadState {
  competitiveBidding: CompetitiveBiddingData;
  companies: CompanyData[];
  currencies: CurrencyData[];
  catsL1: SpendCategory[];
  catsL2: Array<SpendCategory[]>;
  catsL3: Array<SpendCategory[]>;
  pslStatus: {
    0: any,
    1: any,
    2: any,
  };
  saving: boolean;
  complianceAlert: boolean;
}

export const initialCbFormState: CbFormState = {
  competitiveBidding: CompetitiveBiddingData.empty(),
  companies: [],
  currencies: [],
  catsL1: [],
  catsL2: [],
  catsL3: [],
  pslStatus: {
    0: null,
    1: null,
    2: null,
  },
  loading: false,
  loaded: false,
  saving: false,
  complianceAlert: false,
  error: null
};

export function CbReducer(state: CbFormState = initialCbFormState, action: IAction<CompetitiveBiddingTypes>): CbFormState {
  let cb: CompetitiveBiddingData = state.competitiveBidding;
  switch (action.type) {
    case CompetitiveBiddingTypes.PSL_STATUS_SUCCESS:
    case CompetitiveBiddingTypes.RESET_PSL_STATUS:
      return assocPath(['pslStatus', `${action.supplierIndex}`], action.pslStatus, state);
    case CompetitiveBiddingTypes.INITIAL_COMPETITIVEBIDDING:
      return loading(mergeRight(assoc('competitiveBidding',
        CompetitiveBiddingData.empty(action.CompanyCode), state), {
        catsL1: [],
        catsL2: [],
        catsL3: [],
        complianceAlert: false
      }));
    case CompetitiveBiddingTypes.FETCHONE_COMPETITIVEBIDDING:
      return explicitLoading(state);
    case CompetitiveBiddingTypes.FETCH_CB_CATS_L2:
      let catL3 = state.catsL3;
      catL3[action.index] = [];
      return mergeRight(state, {catsL3: catL3});
    case CompetitiveBiddingTypes.UPLOAD_QUOTATION:
      cb.Vendors = assocPath([action.key, 'uploading', action.index], true, cb.Vendors) as any;
      return mergeRight(state, {competitiveBidding: cb});
    case CompetitiveBiddingTypes.UPLOAD_QUOTATION_FAILURE:
      cb.Vendors = assocPath([action.key, 'uploading', action.index], false, cb.Vendors) as any;
      return mergeRight(state, {competitiveBidding: cb});
    case CompetitiveBiddingTypes.UPLOAD_QUOTATION_SUCCESS:
    case CompetitiveBiddingTypes.REMOVE_QUOTATION:
      cb.Vendors = assocPath([action.key, 'AttachedQuotation', action.index], action.fileName, cb.Vendors) as any;
      cb.Vendors = assocPath([action.key, 'uploading', action.index], false, cb.Vendors) as any;
      return mergeRight(state, {competitiveBidding: cb});
    case CompetitiveBiddingTypes.INITIAL_COMPETITIVEBIDDING_SUCCESS:
      if (!cb.CompanyCode) {
        cb.CompanyCode = <string>defaultCompany(action.user).id;
      }
      return loaded(mergeRight(state, {
        competitiveBidding: cb,
        catsL1: action.catsL1,
        companies: action.companies,
        currencies: action.currencies
      }));
    case CompetitiveBiddingTypes.FETCH_CB_CATS_L2_SUCCESS:
      const catsL2 = state.catsL2[action.index] ? update(action.index, action.cats, state.catsL2) :
        insert(action.index, action.cats, state.catsL2);
      return mergeRight(state, {catsL2: catsL2 as any});
    case CompetitiveBiddingTypes.FETCH_CB_CATS_L3_SUCCESS:
      const catsL3 = state.catsL3[action.index] ? update(action.index, action.cats, state.catsL3) :
        insert(action.index, action.cats, state.catsL3);
      return mergeRight(state, {catsL3: catsL3 as any});
    case CompetitiveBiddingTypes.SAVE_COMPETITIVEBIDDING:
      return mergeRight(state, {competitiveBidding: action.competitiveBidding, loading: true, saving: true});
    case CompetitiveBiddingTypes.SAVE_COMPETITIVEBIDDING_SUCCESS:
      return mergeRight(state, {competitiveBidding: <any>assoc('Id', action.id, cb), loading: false, saving: false});
    case CompetitiveBiddingTypes.FETCHONE_COMPETITIVEBIDDING_SUCCESS:
      return explicitLoaded(mergeRight(state, {competitiveBidding: action.competitiveBidding}));
    case CompetitiveBiddingTypes.FETCH_CB_SUPPLIERS_SUCCESS:
      return mergeRight(state, {vendors: action.vendors});
    case CompetitiveBiddingTypes.GET_COMPLIANCE_ALERT_SUCCESS:
      return mergeRight(state, {complianceAlert: action.alert});
    case CompetitiveBiddingTypes.CLOSE_COMPLIANCE_ALERT:
      return mergeRight(state, {complianceAlert: false});
    case CompetitiveBiddingTypes.INITIAL_COMPETITIVEBIDDING_FAILURE:
    case CompetitiveBiddingTypes.SAVE_COMPETITIVEBIDDING_FAILURE:
      return error(action.error)(mergeRight(state, {saving: false}));
    case CompetitiveBiddingTypes.FETCHONE_COMPETITIVEBIDDING_FAILURE:
    case CompetitiveBiddingTypes.FETCH_CB_SUPPLIERS_FAILURE:
    case CompetitiveBiddingTypes.GET_COMPLIANCE_ALERT_FAILURE:
      return error(action.error)(state);
    case CompetitiveBiddingTypes.CLEAR_L2_L3_INDEX:
      return mergeRight(state, {
        catsL2: remove(action.index, 1, state.catsL2) as any,
        catsL3: remove(action.index, 1, state.catsL3) as any
      });
    default:
      return state;
  }
}
