import { Dispatch } from "redux";
import { ApprovalStatusService } from "../domains/approvalStatus/approvalStatus.service";
import { ErrorAction, IAction, PendingAction, SuccessAction } from "../../../shared/domains/core/actions";
import { ApprovalStatusRequest } from "../domains/approvalStatus/approvalStatus";
import any from 'ramda/es/any';
import { SuccessNotificationAction } from "../../../shared/domains/core/notifications";
import { AxiosWrapper } from 'src/app/shared/utils/axios.wrapper';

export enum ApprovalStatusActionTypes {
    FETCH_AS = "FETCH_AS",
    FETCH_AS_SUCCESS = "FETCH_AS_SUCCESS",
    FETCH_AS_FAILURE = "FETCH_AS_FAILURE",
    NEW_PAGE_AS = "NEW_PAGE_AS",
    NEW_ORDER_AS = "NEW_ORDER_AS",
    NEW_TERM_AS = "NEW_TERM_AS",
    NEW_AS_QUERY = "NEW_AS_QUERY",
    SET_SELECTED_AS = "SET_SELECTED_AS",
    FETCH_AS_DETAIL = "FETCH_AS_DETAIL",
    FETCH_AS_DETAIL_SUCCESS = "FETCH_AS_DETAIL_SUCCESS",
    FETCH_AS_DETAIL_FAILURE = "FETCH_AS_DETAIL_FAILURE",
    FETCH_AS_LINES = "FETCH_AS_LINES",
    FETCH_AS_LINES_SUCCESS = "FETCH_AS_LINES_SUCCESS",
    FETCH_AS_LINES_FAILURE = "FETCH_AS_LINES_FAILURE",
    APPROVAL_STATUS_APPROVED_PR = "APPROVAL_STATUS_APPROVED_PR",
    APPROVAL_STATUS_APPROVED_PR_SUCCESS = "APPROVAL_STATUS_APPROVED_PR_SUCCESS",
    APPROVAL_STATUS_APPROVED_PR_FAILURE = "APPROVAL_STATUS_APPROVED_PR_FAILURE",
    MAIL_SUCCESS ="SUCCESS",
    MAIL_FAILURE="ERROR",
    EMAIL_TRIGGER="EMAIL_TRIGGER",

    AS_DEFAULT_ALIYS = "AS_DEFAULT_ALIYS",
    AS_DEFAULT_ALIYS_SUCCESS = "AS_DEFAULT_ALIYS_SUCCESS",
}

/** Fetch all PR list */
export class FetchPRs extends PendingAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS;
}

export class FetchPRsSuccess extends SuccessAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_SUCCESS;
}

export class FetchPRsError extends ErrorAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

class SetSelectedRow extends SuccessAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.SET_SELECTED_AS;
}

export function setSelectedRow(purchaseRequest: ApprovalStatusRequest, dispatch: Dispatch<IAction>) {
    dispatch((new SetSelectedRow({ purchaseRequest: purchaseRequest })));
}

/** Fetch PR Details */
export class FetchPRDetail extends PendingAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_DETAIL;
}

export class FetchPRDetailSuccess extends SuccessAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_DETAIL_SUCCESS;
}

export class FetchPRDetailError extends ErrorAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_DETAIL_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRDetail(purchaseRequest: ApprovalStatusRequest, dispatch: Dispatch<IAction>) {
    dispatch((new FetchPRDetail(purchaseRequest)));
}

/** Get list of PR Line according to the PR Number (From Master Detail) */
export class FetchPRLines extends PendingAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_LINES;
}

export class FetchPRLinesSuccess extends SuccessAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_LINES_SUCCESS;
}

export class FetchPRLinesError extends ErrorAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.FETCH_AS_LINES_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRLines(purchaseRequest: ApprovalStatusRequest, AribaPr: string, ApprovalType: string, ApprovalLevel: string, positionId: string, dispatch: Dispatch<IAction>) {
    let purchaseRequestTemp: any = {};
    purchaseRequestTemp = purchaseRequest;
    purchaseRequestTemp.AribaPr = AribaPr;
    purchaseRequestTemp.ApprovalType = ApprovalType;
    purchaseRequestTemp.ApprovalLevel = ApprovalLevel;
    purchaseRequestTemp.positionId = positionId;
    dispatch((new FetchPRLines(purchaseRequestTemp)));
}

/** Approval Process of the PR Line */
export class ApprovedPR extends PendingAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.APPROVAL_STATUS_APPROVED_PR;
}

export class EmailTrigger extends PendingAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.EMAIL_TRIGGER;
}

export class ApprovedPRSuccess extends SuccessAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.APPROVAL_STATUS_APPROVED_PR_SUCCESS;
}

export class MailSuccess extends SuccessAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.MAIL_SUCCESS;
    public readonly message = "Mail has been sent.";
}

export class ApprovedPRError extends ErrorAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.APPROVAL_STATUS_APPROVED_PR_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export class MailFailure extends ErrorAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.MAIL_FAILURE;
    public readonly message = "Something went wrong. Please Try again later.";
    constructor(public error) {
        super(error, true);
    }
}

export function approvedPR(purchaseRequest: ApprovalStatusRequest, dispatch: Dispatch<IAction>) {
    dispatch((new ApprovedPR(purchaseRequest)));
}

class PRApproval extends PendingAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.APPROVAL_STATUS_APPROVED_PR;
}

class TriggerEmail extends PendingAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.EMAIL_TRIGGER;
    constructor(public payload: any) {
        super();
      }
}

class PRApprovalSuccess extends SuccessNotificationAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.APPROVAL_STATUS_APPROVED_PR_SUCCESS;
    public readonly message = "PR line approved successfully.";
}
class PRRejectionSuccess extends SuccessNotificationAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.APPROVAL_STATUS_APPROVED_PR_SUCCESS;
    public readonly message = "PR line rejected successfully.";
}

class EmailSendSuccess extends SuccessNotificationAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.MAIL_SUCCESS;
    public readonly message = "Mail has been sent.";
}
class EmailSendFailed extends ErrorAction<ApprovalStatusActionTypes> {
    public readonly type = ApprovalStatusActionTypes.MAIL_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

class PRApprovalError extends ErrorAction<ApprovalStatusActionTypes> {
    constructor(public error) {
        super(error, true);
    }
}

export function prApproval(newpurchaseRequest: ApprovalStatusRequest, callback, dispatch: Dispatch<IAction>, updatePRService = ApprovalStatusService.approvalRequest) {
    dispatch(new PRApproval());
    return updatePRService(newpurchaseRequest)
        .then(() => {
            callback('success');
            if (newpurchaseRequest.ApprovalIndicator == 'A')
                return new PRApprovalSuccess(newpurchaseRequest)
            else
                return new PRRejectionSuccess(newpurchaseRequest)

        }).catch((error: any) => {
            callback('failure');
            return new PRApprovalError(error)
        })
        .then(dispatch);
}

export function emailTrigger(rowData: any, selectedRow:any, dispatch: Dispatch<IAction>) {
    dispatch(new TriggerEmail({rowData,selectedRow}));
    // return emailService(rowData,selectedRow)
    //     .then(() => {
    //         console.log("then")
    //         return new EmailSendSuccess(selectedRow) 
    //     }).catch((error: any) => {
    //         console.log("catch",error)
    //         return new EmailSendFailed(error)
    //     })  
}



