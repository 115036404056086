import {combineReducers, Reducer} from "redux";
import {Queryable} from "../../shared/queryable/query.reducer";
import {MrpListReducer, MRPListState} from "./mrpList.reducer";
import {mrpReducer, MrpState} from "./mrpReducer";

export interface MRPStateStore {
  mrpList: Queryable<MRPListState>;
  mrp: MrpState;
}

export const MRPCombinedReducers: Reducer<MRPStateStore> = combineReducers({
  mrpList: MrpListReducer,
  mrp: mrpReducer
});
