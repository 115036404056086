import { all, call, fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { GetChatMessagesSuccess } from "../actions/invoiceChat.actions";
import { GetChatGroupsSuccess } from "../actions/invoiceChat.actions";
import { DocflowChatService } from "../../domains/docflowChat/docflowChat.service";
import { RoomChatService } from "../../../shared/domains/chat/roomChat.service";
import { ChatActions, makeInvoiceChatId } from "../../reducers/invoiceChat.reducer";
import { InvoicesStateStore } from "../../reducers";
import { PataflagService } from 'src/app/shared/services/pataflag.service';
import { Features } from 'src/app/shared/domains/core/pataflag';

function* startRoom(action): any {
  try {
    const room = RoomChatService.newInstance(
      'invoiceChat',
      makeInvoiceChatId(action),
      'ReceiveInvoiceChatMessage'
    );
    yield call(room.join.bind(room));
    yield put(new ChatActions.startRoomSuccess(room));
  } catch (error) {
    yield put(new ChatActions.startRoomFailure(error));
  }
}

function* fetchChatMessages(action): any {
  try {
    yield put({ type: ChatActions.startRoom, invoiceNumber: action.invoiceNumber });
    const result = yield call(DocflowChatService.fetchInvoiceChatMessages, action.invoiceNumber);
    const result1 = yield call(DocflowChatService.fetchNewChatMessages, action.prNo, action.poNo);
    yield put({ type: ChatActions.onLoadMessagesSuccess, invoiceNumber: action.invoiceNumber, messages: [...result1, ...result] });
  } catch (error) {
    yield put(new ChatActions.onLoadMessagesFailure(error));
  }
}

function* fetchChatMessagesSuccess(action) {
  yield put(new GetChatMessagesSuccess(action.invoiceNumber, action.messages));
}

function* fetchChatGroups(action): any {
  try {
    const result = yield call(DocflowChatService.fetchChatGroups, action.prNo, action.poNo);
    yield put({ type: ChatActions.onLoadGroupsSuccess, prNo: action.prNo, poNo: action.poNo, chatGroups: result });
  } catch (error) {
    yield put(new ChatActions.onLoadGroupsFailure(error));
  }
}

function* fetchChatGroupsSuccess(action) {
  yield put(new GetChatGroupsSuccess(action.prNo, action.poNo, action.chatGroups));
}

function* sendMessage(action): any {
  try {
    const result = yield call(DocflowChatService.sendInvoiceChatMessageNew, action.invoiceNumber, action.message, action.mentionedEmailAddresses, action.year, action.company, action.companyName, action.systemAlias, action.msgSendTo, action.attachments);
    yield put(new ChatActions.onSendMessageSuccess(result));
    const room = yield select(({ invoices }: { invoices: InvoicesStateStore }) => {
      invoices.chat[makeInvoiceChatId(action)].room
    });
    if (room) {
      yield call(room.send.bind(room, result.id));
    }
  } catch (error) {
    yield put(new ChatActions.onSendMessageFailure(error));
  }
}
function* newSendMessage(action): any {
  try {
    const result = yield call(DocflowChatService.newLatestSendMessage, action.payload);
    yield put(new ChatActions.onSendMessageSuccess(result));
    const room = yield select(({ invoices }: { invoices: InvoicesStateStore }) => {
      invoices.chat[makeInvoiceChatId(action)].room
    });
    if (room) {
      yield call(room.send.bind(room, result.id));
    }
  } catch (error) {
    yield put(new ChatActions.onSendMessageFailure(error));
  }
}

function* watchGetMessages() {
  yield takeLatest(ChatActions.onLoadMessages, fetchChatMessages);
}

function* watchGetGroups() {
  yield takeLatest(ChatActions.onLoadGroups, fetchChatGroups);
}

function* watchSendMessage() {
  yield takeEvery(ChatActions.onSendMessage, sendMessage);
}

function* watchNewSendMessage() {
  yield takeEvery(ChatActions.onNewSendMessage, newSendMessage);
}

function* watchStartRoom() {
  yield takeLatest(ChatActions.startRoom, startRoom);
}

function* watchGetMessagesSuccess() {
  yield (ChatActions.onLoadMessagesSuccess, fetchChatMessagesSuccess);
}

function* watchGetGroupsSuccess() {
  yield (ChatActions.onLoadGroupsSuccess, fetchChatGroupsSuccess);
}

export default function* invoiceChatSaga() {
  yield all([
    fork(watchStartRoom),
    fork(watchGetMessages),
    fork(watchGetGroups),
    fork(watchSendMessage),
    fork(watchNewSendMessage),
    fork(watchStartRoom),
    fork(watchGetMessagesSuccess),
    fork(watchGetGroupsSuccess),
  ])
}

