import React, { useState, useEffect, CSSProperties } from "react";
import { Field, Formik, FastField } from "formik";
import { prop, remove } from "ramda";
import clsx from 'clsx';
import { Provider, withContext } from "../../services/context.service";
import { debounce, get } from 'lodash';
import AsyncSelect from 'react-select/async';
import { createStyles, Input, makeStyles, Theme, TextField, Tooltip } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { FixedSizeList as List } from "react-window";
import Select, { components } from "react-select";
import ChipInput from "material-ui-chip-input";
import { muiTheme } from "../../../application.theme";
import { MenuPosition } from "react-select/src/types";
import { DateTime } from "luxon";
import { Option } from "react-select/src/filters";
import { FeaturesContext } from "../../domains/core/pataflag.context";
import { Features } from "../../domains/core/pataflag";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useStylesForToolTip } from "../tooltip/toolTip.styles";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import _ from "lodash";
import './multiple.css';
import SearchIcon from '@material-ui/icons/Search';
import { InfoPopUpIcon } from "src/app/preferredSuppliers/containers/pslForm/component/InfoPopUpIcon";
import { InfoPopUpHoverIcon } from "src/app/preferredSuppliers/containers/pslForm/component/InfoPopUpHoverIcon";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: 0,
      fontSize: 14,
    },
    checkbox: {
      padding: '0!important'
    },
    textarea: {
      border: 'none',
      fontSize: '0.875rem',
      fontFamily: 'Roboto, sans-serif !important',
      padding: '10px',
      width: '100%',
      height: '100%'
    },
    typehead: {
      fontSize: 14,
      lineHeight: '20px',
      width: '100%',
      background: 'white',
    },
    date: {
      width: '100%'
    },
    select: {
      fontSize: 14,
      height: 32
    },
    error: {
      border: '1px solid red!important',
      borderRadius: 4
    },
    errorBold: {
      borderBottom: '2px solid red!important',
      '& div:after': {
        content: 'none'
      },
      '& div:before': {
        content: 'none'
      }
    },
    noResultMessage: {
      color: 'hsl(0,0%,50%)',
      height: '44px',
      lineHeight: '44px',
      textAlign: 'center'
    },
    fullWidth: {
      width: '100%'
    }
  })
);

export const MAX_DATE = DateTime.local(9999, 12, 31);
export const MIN_DATE = DateTime.local(1900, 1, 1);

export function ContextForm(props) {
  const { children, ...other } = props;

  return (
    <Formik {...other}>
      {(form) => {
        return (
          <Provider value={{ form }}>
            {children}
          </Provider>
        )
      }}
    </Formik>
  )
}

export const AsyncSelectField = ({
  name, labelName, customDisplay, options = [], limited = 0, customError = false,
  menuPosition = 'absolute', components = null, clearable = false, disabled = false,
  isMulti = false, returnFullOnChange = false, returnFullSelected = false,
  selected = null, onLoad, onChange = null, placeholder = "Select",
  menuPortalTarget = null, form: { values, setFieldValue, errors }
}) => {
  const { typehead, error } = useStyles({});
  const [value, setValue] = React.useState<any>(get(values, name));
  const getValue = () => {
    if (returnFullSelected) {
      return selected;
    }
    const value = get(values, name);
    const label = get(values, labelName);
    if (value) {
      return { label: label, value: value };
    }
    return null;
  };

  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };

  return (
    <AsyncSelect
      value={getValue()}
      isMulti={isMulti}
      defaultOptions={options}
      noOptionsMessage={({ inputValue: value }) => {
        if (value.length < limited) return `Type to start searching (${limited} chars min.)`;
        return value ? `No results for "${value}"` : 'No results'
      }}
      menuPortalTarget={menuPortalTarget ? menuPortalTarget : null}
      className={`${typehead} ${(get(errors, name) || customError) ? error : null}`}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPosition={menuPosition as MenuPosition}
      loadOptions={(value) => {
        if (value.length >= limited) {
          return onLoad(value)
        }
        return Promise.resolve([]);
      }}
      isClearable={clearable}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={(option: any) => {
        const optValue = prop('value', option);
        const optLabel = prop('label', option);
        setFieldValue(name, optValue);
        setFieldValue(labelName, optLabel);

        if (onChange) {
          if (returnFullOnChange) {
            return onChange(option, setFieldValue);
          } else {
            return onChange(optValue);
          }
        }
      }}
      getOptionLabel={getLabel}
      onInputChange={setValue}
      components={components}
    />
  )
};
export const float13 = (float: string) => /^[0-9]{1,13}(\.[0-9]{1,2})?$/.test(float);
export const float9 = (float: string) => /^[0-9]{1,9}(\.[0-9]{1,2})?$/.test(float);
export const CustomFloatInput = ({ type, onChange, initialValue, disabled = false, error = false }) => {
  const { input, fullWidth } = useStyles({});
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      if (value === '' || type(value)) {
        setValue(value);
      }
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = () => {
    onChange(value);
  }

  return <Input
    error={error}
    type={'number'}
    inputProps={{ min: "0" }}
    value={value}
    disabled={disabled}
    onChange={handleChange}
    onBlur={handleBlur}
    className={clsx(input, fullWidth)}
  />
}
export const CustomFastInput = ({ type, onChange, initialValue, disabled = false, error = false, style = null, normalInput = false, placeholder = '', maxInputLength = 0 }) => {
  const { input, fullWidth } = useStyles({});
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    if (maxInputLength > 0) {
      if (e.target.value.length <= maxInputLength) {
        if (e.target instanceof HTMLInputElement) {
          const value = e.target.value;
          setValue(value);
        }
      }
    } else {
      if (e.target instanceof HTMLInputElement) {
        const value = e.target.value;
        setValue(value);
      }
    }
  };
  const handleNumericChange = (e) => {
    const value = e.target.value;
    if (maxInputLength > 0) {
      if (value.length <= maxInputLength) {
        if (e.target instanceof HTMLInputElement && (/^\d+$/.test(value) || value === "")) {
          setValue(value);
        }
      }
    } else {
      if (e.target instanceof HTMLInputElement && (/^\d+$/.test(value) || value === "")) {
        setValue(value);
      }
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = () => {
    onChange(value);
  }

  if (normalInput) {
    return <input value={value} onChange={handleChange} onBlur={handleBlur}
      placeholder={placeholder}
      style={style}
    />
  }

  if (type === 'positive') {
    return <Input
      error={error}
      type={'text'}
      inputProps={{ min: "0" }}
      value={value}
      disabled={disabled}
      onChange={handleNumericChange}
      onBlur={handleBlur}
      className={clsx(input, fullWidth)}
    />
  }

  return <Input
    style={style}
    error={error}
    type={type}
    value={value}
    disabled={disabled}
    onChange={handleChange}
    onBlur={handleBlur}
    className={clsx(input, fullWidth)}
  />
}

export const CustomFastInputForMRPSignAgreeNo = ({ type, onChange, initialValue, disabled = false, error = false, style = null,
  signedAgreementNumberError }) => {
  const { input, fullWidth } = useStyles({});
  const [value, setValue] = useState(initialValue);
  const [inputError, setInputError] = useState('')

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);


  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      let regEx = /^$|^[0-9a-zA-Z]+$/;
      if (value.length > 12 || !value.match(regEx)) {
        setInputError('Field will allow 12 Alpha numeric characters only');
        setValue(value);
      }

      else {
        setInputError('')
        setValue(value);
      }
      // const value = (e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '';

      // value.length <= 12 && setValue(value);
    }
  };

  const handleBlur = () => {
    onChange(value);
  }

  return <div><Input
    style={style}
    error={error || !!signedAgreementNumberError.error}
    type={type}
    value={value}
    disabled={disabled}
    onChange={handleChange}
    onBlur={handleBlur}
    className={clsx(input, fullWidth)}
  />
    {!!inputError && <span style={{
      display: 'block', color: 'red', fontSize: 12,
      marginBottom: 5
    }}>{inputError}</span>}
  </div>
  //   <Tooltip title = {`"Signed Agreement Number" field will allow 12 Alpha numeric characters only`}
  // placement = "bottom-end"
  // open = {(value.length >12 || !value.match(/^$|^[0-9a-zA-Z]+$/))? true: false}
  // arrow = {true}
  // >
  // </Tooltip>
}

export const CustomFastInputForPriceRange = ({ type, onChange, initialValue, disabled = false, error = false, style = null }) => {
  const { input, fullWidth } = useStyles({});
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue === null) setValue("")
    else setValue(initialValue)

  }, [initialValue])

  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      setValue(value);
    }
  };

  const handleBlur = () => {
    onChange(value);
  }

  if (type === 'positive') {
    return <Input
      error={error}
      type={'number'}
      inputProps={{ min: "0" }}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      className={clsx(input, fullWidth)}
    />
  }

  return <Input
    style={style}
    error={error}
    type={type}
    value={value}
    disabled={disabled}
    onChange={handleChange}
    onBlur={handleBlur}
    className={clsx(input, fullWidth)}
  />
}



const InputField = ({ name, onChange, disabled = false, placeholder = "Enter", type = "text", form: { values, setFieldValue, errors }, ...props }) => {
  const { input, fullWidth } = useStyles({});
  let onDebounceChange = null;
  if (onChange) {
    onDebounceChange = debounce(onChange, 800);
  }
  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const value = e.target.value;
      setFieldValue(name, value);
      if (onDebounceChange) {
        onDebounceChange(value);
      }
    }
  };

  return (
    <FastField
      {...props}
      name={name}
      margin="none"
      placeholder={placeholder}
      value={get(values, name)}
    >
      {({ field }) => (
        <Input
          {...field}
          type={type}
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
          error={!!get(errors, name) ? true : false}
          className={clsx(input, fullWidth)}
        />
      )}
    </FastField>
  )
};

const PositiveNumericInputField = ({ name, onChange, disabled = false, placeholder = "Enter", form: { values, setFieldValue, errors } }) => {
  const { input, fullWidth } = useStyles({});
  const [value, setValue] = React.useState("");
  let onDebounceChange = null;
  if (onChange) {
    onDebounceChange = debounce(onChange, 800);
  }
  const handleChange = (e) => {
    if (e.target instanceof HTMLInputElement) {
      const targetValue = e.target.value;
      if (targetValue === '' || /^[0-9.]+$/.test(targetValue)) {
        setValue(targetValue);
        setFieldValue(name, targetValue);
        if (onDebounceChange) {
          onDebounceChange(targetValue);
        }
      }
    }
  };

  return (
    <FastField
      name={name}
      margin="none"
      placeholder={placeholder}
      value={value}
    >
      {({ field }) => (
        <Input
          {...field}
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
          error={!!get(errors, name) ? true : false}
          className={clsx(input, fullWidth)}
        />
      )}
    </FastField>
  )
};

const TextareaField = ({ name, placeholder = "Enter", type = "text", form: { values, setFieldValue, errors } }) => {
  const { textarea, error } = useStyles({});
  const onChange = (e) => {
    if (e.target instanceof HTMLTextAreaElement) {
      setFieldValue(name, e.target.value)
    }
  };
  return (
    <Field
      name={name}
      render={({ field }) => (
        <textarea
          {...field}
          type="text"
          placeholder={placeholder}
          className={`${textarea} ${get(errors, name) ? error : null}`}
          value={get(values, name)}
          onChange={onChange} />
      )} />
  )
};

export const CustomTextareaField = ({ placeholder, rows, rowsMax, initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    if (e.target instanceof HTMLTextAreaElement) {
      const value = e.target.value;
      setValue(value);
      onChange(value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <TextField
    placeholder={placeholder}
    fullWidth
    multiline
    rows={rows}
    rowsMax={rowsMax}
    value={value}
    onChange={handleChange}
  />
}

const DateField = ({ name, dateFormat = 'dd/MM/yyyy', onChange, disabled = false, minDate = MIN_DATE, maxDate = MAX_DATE, form: { values, setFieldValue, errors } }) => {
  const { input, date } = useStyles({});
  return (
    <MuiThemeProvider theme={muiTheme}>
      <KeyboardDatePicker
        margin="none"
        variant="inline"
        autoOk={true}
        className={date}
        disabled={disabled}
        InputProps={{
          classes: {
            input: input
          },
        }}
        format={dateFormat}
        error={!!get(errors, name)}
        value={get(values, name)}
        minDate={minDate}
        maxDate={maxDate}
        onChange={value => {
          setFieldValue(name, value);
          if (onChange) {
            onChange(value);
          }
        }}>
      </KeyboardDatePicker>
    </MuiThemeProvider>
  )
};

export const CustomFastDate = ({ dateFormat = 'dd/MM/yyyy', hasError = false, initialValue = null, onChange, disabled = false,
  minDate = MIN_DATE, maxDate = MAX_DATE, disableToolbar = false }) => {
  const { input, date, error } = useStyles({});
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <MuiThemeProvider theme={muiTheme}>
      <KeyboardDatePicker
        disableToolbar={disableToolbar}
        margin="none"
        variant="inline"
        autoOk={true}
        className={clsx(date, hasError ? error : null)}
        disabled={disabled}
        InputProps={{
          classes: {
            input: input
          },
        }}
        format={dateFormat}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}>
      </KeyboardDatePicker>
    </MuiThemeProvider>
  )
};

const SelectField = ({ name, labelName, options, customDisplay = null, onChange = null,
  clearable = false, isMulti = false, returnFullOnChange = false, returnFullSelected = false,
  selected = null, disabled = false,
  menuPortalTarget = null, form: { values, setFieldValue, errors } }) => {
  const [value, setValue] = React.useState<any>("");
  const { typehead, error } = useStyles({});
  const getValue = () => {
    if (returnFullSelected) {
      return selected;
    }
    let selectedValue = get(values, name);
    if (!selectedValue && typeof selectedValue !== 'boolean') {
      selectedValue = value;
    }
    const option = options.find(option => option.value === selectedValue);
    if (labelName) {
      const oldLabel = get(values, labelName);
      const newLabel = prop('label', option);
      if (oldLabel !== newLabel) {
        setFieldValue(labelName, newLabel);
      }
    }
    return option;
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };
  const handleChange = (selected) => {
    const option = options.find(option => option.value === prop('value', selected));
    const value = prop('value', option);
    const label = prop('label', option);
    setValue(value);

    if (name) {
      setFieldValue(name, value);
    }

    if (labelName) {
      setFieldValue(labelName, label);
    }

    if (onChange) {
      if (returnFullOnChange) {
        return onChange(selected);
      } else {
        return onChange(value);
      }
    }
  };

  return (
    <Select
      isMulti={isMulti}
      isClearable={clearable}
      isDisabled={disabled}
      className={`${typehead} ${get(errors, name) ? error : null}`}
      menuPosition="fixed"
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          minHeight: '30px'
        })
      }}
      menuPortalTarget={menuPortalTarget ? menuPortalTarget : null}
      error={!!get(errors, name) ? true : false}
      options={options}
      value={getValue()}
      onChange={handleChange}
      getOptionLabel={getLabel}>
    </Select>
  )
};

const TypeAheadSelectField = ({ name, labelName, loading, options = [], customDisplay = null, onChange = null, clearable = false, disabled = false,
  returnWholeOption = false, form: { values, setFieldValue, errors } }) => {
  const { typehead, error } = useStyles({});
  const handleChange = (e) => {
    const value = prop('value', e);
    setFieldValue(name, value);
    if (labelName) {
      setFieldValue(labelName, prop('label', e));
    }
    if (onChange) {
      onChange(returnWholeOption ? e : value);
    }
  };
  const getValue = () => {
    const value = options.find(option => option.value === get(values, name));
    return value ? value : null;
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };
  return (
    <Select
      isLoading={loading}
      isClearable={clearable}
      isDisabled={disabled}
      className={`${typehead} ${get(errors, name) ? error : null}`}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      options={options}
      value={getValue()}
      onChange={handleChange}
      getOptionLabel={getLabel} />
  )
};

export const CustomFastSelect = ({ onChange, initialValue, options = [], customDisplay = null, hasError = false, onOpen = null, searchByKey = null,
  isMulti = false, disabled = false, clearable = true, menuPortalTarget = null, placeholder = "", changeBorderRadius = false, indicatorStyles = null,controlStyles = null, styles = null, loading = false, maxHeight = 250 }) => {
  const [value, setValue] = useState(initialValue);
  const { error } = useStyles({});

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Select
      isLoading={loading}
      onMenuOpen={onOpen}
      placeholder={placeholder ? placeholder : 'Select...'}
      isMulti={isMulti}
      filterOption={searchByKey ? (option: Option, rawInput: string) => {
        return option?.data[searchByKey]?.toUpperCase().indexOf(rawInput.toUpperCase()) !== -1;
      } : undefined}
      menuPortalTarget={menuPortalTarget}
      className={hasError ? error : null}
      isClearable={clearable}
      isDisabled={disabled}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }), ...styles,
        control: (baseStyles, state) => ({
          ...baseStyles,
          ...controlStyles
        }),
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          ...indicatorStyles
        })

      }}
      options={options}
      value={value}
      getOptionLabel={getLabel}
      onChange={handleChange}
      components={{
        MenuList: (props) => <MenuList {...props} maxHeight={maxHeight} />,
      }}
    />
  )
}

export const CustomFastStyledSelect = ({ onChange, initialValue, options = [], customDisplay = null, hasError = false, onOpen = null, searchByKey = null,
  isMulti = false, disabled = false, clearable = true, menuPortalTarget = null, placeholder = "", changeBorderRadius = false, styles = null, loading = false, maxHeight = 250 }) => {
  const [value, setValue] = useState(initialValue);
  const { error } = useStyles({});

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const formatOptionLabel = (props) => (
    <>
      {
        isMulti === true ?
          <div style={{ display: "flex", overflowY: 'hidden', overflowX: 'hidden'}}>
            <span style={{whiteSpace: 'nowrap'}}>{`${props.value} - ${props.label}`}</span>
          </div>
          :
          <div style={{ display: "flex", overflowY: 'hidden', overflowX: 'hidden'}}>
            <span style={{whiteSpace: 'nowrap'}}>{`${props.id} - ${props.title}`}</span>
          </div>
      }
    </>
  );

  return (
    <Select
      isLoading={loading}
      onMenuOpen={onOpen}
      placeholder={placeholder ? placeholder : 'Select...'}
      isMulti={isMulti}
      filterOption={searchByKey ? (option: Option, rawInput: string) => {
        return option?.data[searchByKey]?.toUpperCase().indexOf(rawInput.toUpperCase()) !== -1;
      } : undefined}
      menuPortalTarget={menuPortalTarget}
      className={hasError ? error : null}
      isClearable={clearable}
      isDisabled={disabled}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }), ...styles
      }}
      options={options}
      value={value}
      getOptionLabel={getLabel}
      onChange={handleChange}
      components={{
        MenuList: (props) => <MenuList {...props} maxHeight={maxHeight} />,
      }}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export const CustomFastStyledAsyncSelect = ({ initialValue, onChange = (_) => { },
  defaultOptions = false,
  onLoad = null,
  loadOnOpen = false,
  hasError = false,
  isMulti = false,
  customDisplay = null,
  onLoadLimit = 0,
  disabled = false,
  clearable = true,
  styles = {},
  menuPortalTarget = null,
  customTooltipMessage = "" }) => {
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  const { error } = useStyles({});
  let fetchDataTimer;

  const { hasFeature } = React.useContext(FeaturesContext);


  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };
  useEffect(() => {
    setValue(initialValue);
    setOptions([initialValue])
  }, [initialValue]);

  useEffect(() => {

    // clearing the timeout when component unmounts
    return () => {
      if (fetchDataTimer) clearTimeout(fetchDataTimer);
    }
  }
    , [])


  function newApicallMethod(onLoad, value, callback) {
    let result;
    if (fetchDataTimer) clearTimeout(fetchDataTimer);

    return new Promise((resolve, reject) => {
      fetchDataTimer = setTimeout(() => {
        result = onLoad(value, callback)
        resolve(result)
      }, 500)
    })
  }

  const formatOptionLabel = (props) => (
    <>
      {
        isMulti === true ?
          <div style={{ display: "flex" }}>
            <div>{props.value}{' - '}{props.label}</div>
            <div style={{ marginLeft: "5px" }}>
              <InfoPopUpHoverIcon vendorId={props.value} marginLeft={"3px"} marginBottom={"-5px"} />
            </div>
          </div>
          :
          <div style={{ display: "flex" }}>
            <div>{props.id}{' - '}{props.name}</div>
            <div style={{ marginLeft: "5px" }}>
              <InfoPopUpHoverIcon vendorId={props.id} marginLeft={"3px"} marginBottom={"-5px"} />
            </div>
          </div>
      }
    </>
  );

  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={clearable}
      loadOptions={(value, callback) => {
        if (value.length >= onLoadLimit) {
          // return onLoad(value, callback)
          return newApicallMethod(onLoad, value, callback);
        }
        return Promise.resolve([]);
      }}
      className={hasError ? error : null}
      isDisabled={disabled}
      getOptionLabel={getLabel}
      noOptionsMessage={({ inputValue: value }) => {
        if (value.length < onLoadLimit) {
          return customTooltipMessage.length
            ? customTooltipMessage
            : `Type to start searching (${onLoadLimit} chars min.)`;
        }
        return value ? `No results for "${value}"` : 'No results'
      }}
      onMenuOpen={loadOnOpen ? onLoad : () => { }}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), ...styles }}
      options={options}
      menuPortalTarget={menuPortalTarget}
      value={value}
      onChange={handleChange}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export const CustomFastAsyncSelect = ({ initialValue, onChange = (_) => { },
  defaultOptions = false,
  onLoad = null,
  loadOnOpen = false,
  hasError = false,
  isMulti = false,
  customDisplay = null,
  onLoadLimit = 0,
  disabled = false,
  clearable = true,
  styles = {},
  menuPortalTarget = null,
  customTooltipMessage = "" }) => {
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  const { error } = useStyles({});
  let fetchDataTimer;

  const { hasFeature } = React.useContext(FeaturesContext);


  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };
  useEffect(() => {
    setValue(initialValue);
    setOptions([initialValue])
  }, [initialValue]);

  useEffect(() => {

    // clearing the timeout when component unmounts
    return () => {
      if (fetchDataTimer) clearTimeout(fetchDataTimer);
    }
  }
    , [])


  function newApicallMethod(onLoad, value, callback) {
    let result;
    if (fetchDataTimer) clearTimeout(fetchDataTimer);

    return new Promise((resolve, reject) => {
      fetchDataTimer = setTimeout(() => {
        result = onLoad(value, callback)
        resolve(result)
      }, 500)
    })
  }

  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={clearable}
      loadOptions={(value, callback) => {
        if (value.length >= onLoadLimit) {
          // return onLoad(value, callback)
          return newApicallMethod(onLoad, value, callback);
        }
        return Promise.resolve([]);
      }}
      className={hasError ? error : null}
      isDisabled={disabled}
      onKeyDown={(e) => { e.stopPropagation()}}
      getOptionLabel={getLabel}
      noOptionsMessage={({ inputValue: value }) => {
        if (value.length < onLoadLimit) {
          return customTooltipMessage.length
            ? customTooltipMessage
            : `Type to start searching (${onLoadLimit} chars min.)`;
        }
        return value ? `No results for "${value}"` : 'No results'
      }}
      onMenuOpen={loadOnOpen ? onLoad : () => { }}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), ...styles }}
      options={options}
      menuPortalTarget={menuPortalTarget}
      value={value}
      onChange={handleChange}
      defaultOptions={defaultOptions}
    />
  )
}

export const CustomFastAsyncSelectWithoutState = ({ initialValue, onChange = (_) => { },
  // only difference between this and CustomFastAsyncSelect, is that once value is selected its not saved in this component state
  defaultOptions = false,
  onLoad = null,
  loadOnOpen = false,
  hasError = false,
  isMulti = false,
  customDisplay = null,
  onLoadLimit = 0,
  disabled = false,
  clearable = true,
  styles = {},
  menuPortalTarget = null,
  customTooltipMessage = "" }) => {
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  const { error } = useStyles({});
  let fetchDataTimer;

  const { hasFeature } = React.useContext(FeaturesContext);


  const handleChange = (value) => {
    // setValue(value);
    onChange(value);
  };
  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };
  useEffect(() => {
    // setValue(initialValue);
    setOptions([initialValue])
  }, [initialValue]);

  useEffect(() => {

    // clearing the timeout when component unmounts
    return () => {
      if (fetchDataTimer) clearTimeout(fetchDataTimer);
    }
  }
    , [])


  function newApicallMethod(onLoad, value, callback) {
    let result;
    if (fetchDataTimer) clearTimeout(fetchDataTimer);

    return new Promise((resolve, reject) => {
      fetchDataTimer = setTimeout(() => {
        result = onLoad(value, callback)
        resolve(result)
      }, 500)
    })
  }

  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={clearable}
      loadOptions={(value, callback) => {
        if (value.length >= onLoadLimit) {
          // return onLoad(value, callback)
          return newApicallMethod(onLoad, value, callback);
        }
        return Promise.resolve([]);
      }}
      className={hasError ? error : null}
      isDisabled={disabled}
      getOptionLabel={getLabel}
      noOptionsMessage={({ inputValue: value }) => {
        if (value.length < onLoadLimit) {
          return customTooltipMessage.length
            ? customTooltipMessage
            : `Type to start searching (${onLoadLimit} chars min.)`;
        }
        return value ? `No results for "${value}"` : 'No results'
      }}
      onMenuOpen={loadOnOpen ? onLoad : () => { }}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), ...styles }}
      options={options}
      menuPortalTarget={menuPortalTarget}
      value={value}
      onChange={handleChange}
      defaultOptions={defaultOptions}
    />
  )
}

const MenuList = (props) => {
  const { children, options, getValue, maxHeight, noResultMessage, limited, selectProps } = props;
  if (selectProps.inputValue.length < limited) {
    return <div className={noResultMessage}>Type to start searching ({limited} chars min.)</div>;
  }
  if (options.length) {
    const height = 35;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    return (
      <List
        width={"100%"}
        height={Math.min(maxHeight, Math.max(children ? children.length : 1, 1) * height)}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}>
        {({ index, style }) => <div key={index} style={style}>{children[index]}</div>}
      </List>
    )
  }

  return <div className={noResultMessage}>No results</div>
};

const LargeAsyncSelectField = (props) => {
  const { noResultMessage } = useStyles({});
  const { limited } = props;

  return (
    <AsyncSelectField {...props}
      components={{
        MenuList: (props) => <MenuList {...props} limited={limited} noResultMessage={noResultMessage} />,
      }} />
  );
};

export const CustomFastChipInput = ({ onChange, initialValue, disabled = false, error = false }) => {
  const [value, setValue] = useState(initialValue);

  const handleAddChip = (chip) => {
    const newValue = value.concat(chip);
    setValue(newValue);
    onChange(newValue);
  };

  const handleDeleteChip = (chip, index) => {
    const newValues = remove(index, 1, value);
    setValue(newValues);
    onChange(newValues);
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <ChipInput
    style={{ width: '100%' }}
    value={value}
    error={error}
    disabled={disabled}
    onAdd={(chip) => handleAddChip(chip)}
    onDelete={(chip, index) => handleDeleteChip(chip, index)}
  />
}

const ChipInputField = ({ name, placeholder = "Enter", type = "text", form: { values, setFieldValue, errors } }) => {
  const { input, errorBold } = useStyles({});
  const getValue = () => {
    const chips = get(values, name);
    return Array.isArray(chips) ? chips : null;
  };
  return (
    <FastField
      name={name}
      fullWidth
      className={`${get(errors, name) ? errorBold : null}`}
      type={type}
      margin="none"
      placeholder={placeholder}
      defaultValue={getValue()}
      onChange={(chips) => setFieldValue(name, chips)}
      InputProps={{
        classes: {
          input: input
        },
      }}
      component={(props) => <ChipInput {...props} fullWidthInput={true} />} />
  )
};

export const CustomFastAsyncSelectWithPaste = ({ initialValue, onChange = (_) => { },
  defaultOptions = false,
  onLoad = null,
  loadOnOpen = false,
  hasError = false,
  isMulti = false,
  customDisplay = null,
  onLoadLimit = 0,
  disabled = false,
  clearable = true,
  styles = {},
  menuPortalTarget = null,
  customTooltipMessage = "", filterName = "" }) => {
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  const { error } = useStyles({});
  let fetchDataTimer;

  const { hasFeature } = React.useContext(FeaturesContext);

  const msgStyles = {
    background: 'black',
    color: 'white',
  };

  const [showMultiValuesInput, setShowMultiValuesInput] = useState(false)
  const [data, setData] = useState([])

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  const handleMultiValuesChange = (values) => {
    let filteredValues = checkAndAppendZerosIfApplicable(values.filter(Boolean));
    if (filteredValues.length > 0) {

      let items = [];
      let newData = []

      let newValues = filteredValues.map((value) => {
        if (/\r\n/.exec(value)) {
          items = value.split('\r\n').join(',').split(',');
        } else {
          return value
        }
      })

      if (items.length > 0) {
        let newItems = newValues.concat(items).filter(item => item !== undefined);
        newData = newItems.map((item) => {
          if (_.isString(item)) {
            return { value: item, label: item }
          } else {
            return item;
          }
        });
        setData(newItems);
      } else {
        newData = filteredValues.map((item) => {
          if (_.isString(item)) {
            return { value: item, label: item }
          } else {
            return item;
          }
        }).filter(item => item !== '')
        setData(filteredValues);
      }
      console.log(newData)
      onChange(newData)
    }
  }

  const getLabel = (option) => {
    if (customDisplay) {
      return customDisplay(option)
    }
    return option.label;
  };

  useEffect(() => {
    setValue(initialValue);
    setOptions([initialValue])
  }, [initialValue]);

  useEffect(() => {

    // clearing the timeout when component unmounts
    return () => {
      if (fetchDataTimer) clearTimeout(fetchDataTimer);
    }
  }
    , [])

  function newApicallMethod(onLoad, value, callback) {
    let result;
    if (fetchDataTimer) clearTimeout(fetchDataTimer);

    return new Promise((resolve, reject) => {
      fetchDataTimer = setTimeout(() => {
        result = onLoad(value, callback)
        resolve(result)
      }, 500)
    })
  }

  const toolTipclass = useStylesForToolTip({});

  const PasteMultipleButton = () => {
    return <>
      <div style={{ display: 'flex', justifyContent: 'right', height: '35px', backgroundColor: 'white', padding: '8px' }}>
        <div style={{ display: 'flex', cursor: 'pointer', gap: '5px' }} onClick={() => setShowMultiValuesInput(true)}>
          <div style={{ alignItems: 'center' }}>
            <span style={{
              height: '20px',
              width: '20px',
              backgroundColor: 'rgb(241 144 1)',
              borderRadius: '50%',
              display: 'inline-block'
            }}>
              <CalendarTodayIcon style={{ fontSize: '12px', marginLeft: '4px', marginTop: '4px', color: 'white' }} />
            </span>
          </div>
          <div style={{ alignItems: 'center' }}>
            <span style={{ color: 'gray' }}>Paste Multiple</span>
          </div>
        </div>
      </div>
    </>
  }

  const NoOptionsMessage = (props) => {
    return (
      // <Tooltip content="Custom NoOptionsMessage Component">
      //   <components.NoOptionsMessage {...props} />
      // </Tooltip>
      <Tooltip
        interactive
        classes={{ tooltip: toolTipclass.pasteButtonTooltip }}
        title={''}
        placement="bottom"
      >
        <>
          <components.NoOptionsMessage {...props} />
          <hr />
          <PasteMultipleButton />
        </>
      </Tooltip>
    );
  };

  const readTextFromClipboard = () => {
    let copiedData = [];
    let filteredCopiedData = [];
    navigator.clipboard.readText()
      .then(text => {
        if (/\r\n/.exec(text)) {
          copiedData = text.split('\r\n').join(',').split(',').filter(item => item !== '');
          filteredCopiedData = checkAndAppendZerosIfApplicable(copiedData.filter(Boolean));
          console.log('Data Copied From Excel Sheet')
          handleMultiValuesChange(filteredCopiedData)
        } else {
          copiedData = text.split(' ').join(',').split(',').filter(item => item !== '');
          filteredCopiedData = checkAndAppendZerosIfApplicable(copiedData.filter(Boolean));
          console.log('Data Copied Form Not Excel Sheet')
          handleMultiValuesChange(filteredCopiedData)
        }
      })
      .catch(err => {
        handleMultiValuesChange([])
        // console.error('Failed to read clipboard contents: ', err);
      });
  };

  const checkAndAppendZerosIfApplicable = (data) => {
    const tempData = data.map(item => item.trim());
    let newData;
    if (tempData.length > 0 && (filterName === 'PurchaseReqNo' || filterName === 'PrNo' || filterName === 'VendorNo' || filterName === 'InvPartner' || filterName === 'Supplier')) {
      newData = tempData.map((item) => {
        if (item[0] !== '0' && item.length === 9) {
          return item.padStart(10, '0')
          // return "0"+item
        } else {
          return item
        }
      })
      return removeDuplicateData(newData);
    }
    else if (tempData.length > 0 && (filterName === 'MaterialNo')) {
      newData = tempData.map((item) => {
        if (item[0] !== '0' && item.length < 18) {
          return item.padStart(18, '0')
        } else {
          return item
        }
      })
      return removeDuplicateData(newData);
    }
    else {
      return removeDuplicateData(tempData)
    }
  }

  function removeDuplicateData(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  useEffect(() => {
    if (showMultiValuesInput) {
      readTextFromClipboard();
    }
  }, [showMultiValuesInput])

  // const checkForLeadingZerosAndRemove = (data) => {
  //   if(filterName === 'PurchaseReqNo' || filterName === 'PrNo' || filterName === 'VendorNo' || filterName === 'InvPartner' || filterName === 'MaterialNo'){
  //     console.log(filterName)
  //     console.log(data)
  //     // return data
  //     return data?.map((item)=>item['label'].replace(/^0+/, ''))
  //   }
  //   return data
  // }

  const handleClose = () => {
    setData([]);
    setShowMultiValuesInput(false);
  }

  return (
    <>
      {
        showMultiValuesInput === true ?
          <TagsInput
            value={data.length > 0 ? data.map((item) => item?.replace(/^0+/, '')) : data}
            onChange={(data) => handleMultiValuesChange(data)}
            tagProps={{ className: 'react-tagsinput-tag', classNameRemove: 'react-tagsinput-remove' }}
            inputProps={{ className: 'react-tagsinput-input', placeholder: 'Enter or Paste data here' }}
            addOnPaste={true}
            onlyUnique={true}
            preventSubmit={false}
            renderTag={(props) => {
              let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
              return (
                <span key={key} {...other}>
                  {getTagDisplayValue(tag)}
                  {!disabled &&
                    <a className={classNameRemove} onClick={(e) => onRemove(key)} />
                  }
                </span>
              )
            }}
            renderInput={(props) => {
              let { onChange, value, addTag, focus, ...other } = props
              return (
                <>
                  <div style={{ display: 'flex' }}>
                    <div><input autoFocus type='text' onChange={onChange} value={value} {...other} /></div>
                    <div style={{ marginLeft: 'auto' }}>
                      <div className="buttons-container">
                        <div aria-hidden="true" className="close-and-arrow" style={{ cursor: 'pointer' }} onClick={handleClose}>
                          <svg height="25" width="25" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="svg-button">
                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                          </svg>
                        </div>
                        <span className="line-bettwen"></span>
                        <div aria-hidden="true" className="close-and-arrow" style={{ cursor: 'pointer' }} onClick={() => setShowMultiValuesInput(false)}>
                          {/* <svg height="15" width="15" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"> */}
                          {/* <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path> */}
                          {/* <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"></path> */}
                          {/* </svg> */}
                          <SearchIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }}
            addKeys={[9, 13, 32]}
            pasteSplit={(data) => {
              if (/\r\n/.exec(data)) {
                return data.split('\r\n').join(',').split(',').filter(item => item !== '');
              } else {
                return data.split(' ').join(',').split(',').filter(item => item !== '');
              }
              // return data.split(' ').map(d => d.trim())
            }}
          />
          :
          <AsyncSelect
            components={{ NoOptionsMessage }}
            isMulti={isMulti}
            isClearable={clearable}
            loadOptions={(value, callback) => {
              if (value.length >= onLoadLimit) {
                // return onLoad(value, callback)
                return newApicallMethod(onLoad, value, callback);
              }
              return Promise.resolve([]);
            }}
            className={hasError ? error : null}
            isDisabled={disabled}
            getOptionLabel={getLabel}
            noOptionsMessage={({ inputValue: value }) => {
              if (value.length < onLoadLimit) {
                return customTooltipMessage.length
                  ? customTooltipMessage
                  : `Type to start searching (${onLoadLimit} chars min.)`;
              }
              return value ? `No results for "${value}"` : 'No results'
            }}
            onMenuOpen={loadOnOpen ? onLoad : () => { }}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), ...styles, noOptionsMessage: (base) => ({ ...base }) }}
            options={options}
            menuPortalTarget={menuPortalTarget}
            value={value}
            onChange={handleChange}
            defaultOptions={defaultOptions}
          />
      }
    </>
  )
}

export const TextareaFieldWrapper = withContext(TextareaField);
export const TextFieldWrapper = withContext(InputField);
export const TypeaheadAsyncSelectWrapper = withContext(AsyncSelectField);
export const LargeTypeaheadAsyncSelectWrapper = withContext(LargeAsyncSelectField);
export const TypeaheadSelectWrapper = withContext(TypeAheadSelectField);
export const DateFieldWrapper = withContext(DateField);
export const SelectFieldWrapper = withContext(SelectField);
export const PositiveNumericInputFieldWrapper = withContext(PositiveNumericInputField);
