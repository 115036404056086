interface InvDocDTO {
    __metadata: { id: string; type: string; uri: string };
    Amount: string,
    BlckgRsnDate: string,
    BlckgRsnEnh: string,
    BlckgRsnItmAmt: string,
    BlckgRsnManual: string,
    BlckgRsnOpq: string,
    BlckgRsnPrice: string,
    BlckgRsnPrjBdgt: string,
    BlckgRsnQlty: string,
    BlckgRsnQty: string,
    ClrDate: string,
    ClrDoc: string,
    Currency: string,
    DebitCreditInd: string,
    FiscalYear: string,
    HasGos: string,
    IReceiver: string,
    IdNo: string,
    InvDate: string,
    InvDocItemNo: string,
    InvDocNo: string,
    InvDueDate: string,
    InvQty: string,
    InvQtyUom: string,
    InvStatus: string,
    InvStatusTxt: string,
    MiroUrl: string,
    MyGrLevel: string,
    MyGrTab: string,
    MyGrUrl: string,
    Paydate: string,
    PendingFor: number,
    PmntBlck: string,
    PmntBlckTxt: string,
    PoItemNo: string,
    PoNo: string,
    PostingDate: string,
    SAP__Origin: string,
    SuppInvRef: string,
    SystemAlias: string,
    TransactionType: string,
}

export class InvDoc {
    constructor(
        public Amount: string,
        public BlckgRsnDate: string,
        public BlckgRsnEnh: string,
        public BlckgRsnItmAmt: string,
        public BlckgRsnManual: string,
        public BlckgRsnOpq: string,
        public BlckgRsnPrice: string,
        public BlckgRsnPrjBdgt: string,
        public BlckgRsnQlty: string,
        public BlckgRsnQty: string,
        public ClrDate: string,
        public ClrDoc: string,
        public Currency: string,
        public DebitCreditInd: string,
        public FiscalYear: string,
        public HasGos: string,
        public IReceiver: string,
        public IdNo: string,
        public InvDate: string,
        public InvDocItemNo: string,
        public InvDocNo: string,
        public InvDueDate: string,
        public InvQty: string,
        public InvStatus: string,
        public InvStatusTxt: string,
        public MiroUrl: string,
        public MyGrLevel: string,
        public MyGrTab: string,
        public MyGrUrl: string,
        public Paydate: string,
        public PendingFor: number,
        public PmntBlck: string,
        public PmntBlckTxt: string,
        public PoItemNo: string,
        public PoNo: string,
        public PostingDate: string,
        public SAP__Origin: string,
        public SuppInvRef: string,
        public SystemAlias: string,
        public TransactionType: string,
    ) {
    }

    static FromBackend(data: InvDocDTO): InvDoc {
        return new InvDoc(
            data.Amount,
            data.BlckgRsnDate,
            data.BlckgRsnEnh,
            data.BlckgRsnItmAmt,
            data.BlckgRsnManual,
            data.BlckgRsnOpq,
            data.BlckgRsnPrice,
            data.BlckgRsnPrjBdgt,
            data.BlckgRsnQlty,
            data.BlckgRsnQty,
            data.ClrDate,
            data.ClrDoc,
            data.Currency,
            data.DebitCreditInd,
            data.FiscalYear,
            data.HasGos,
            data.IReceiver,
            data.IdNo,
            data.InvDate,
            data.InvDocItemNo,
            data.InvDocNo,
            data.InvDueDate,
            data.InvQty,
            data.InvStatus,
            data.InvStatusTxt,
            data.MiroUrl,
            data.MyGrLevel,
            data.MyGrTab,
            data.MyGrUrl,
            data.Paydate,
            data.PendingFor,
            data.PmntBlck,
            data.PmntBlckTxt,
            data.PoItemNo,
            data.PoNo,
            data.PostingDate,
            data.SAP__Origin,
            data.SuppInvRef,
            data.SystemAlias,
            data.TransactionType,
        )
    }

    static toSelect(data: InvDoc) {
      return {
        value: data.InvDocNo,
        label: `${data.InvDocNo} - ${data.FiscalYear}`
      }
    }
}
