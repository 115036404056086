import {Observable} from "rxjs";

export type MonadicSubscriptionCallback<T, V> = (result: T) => V;
export type SubscriptionCallback<T> = MonadicSubscriptionCallback<T, void>;
export type ErrorCallback = (error: any) => void;
export type VoidMethod = () => void;
export type CompleteCallback = VoidMethod;
export type Disposable = VoidMethod;
export type SubscriptionFunction<T> = (okFunction: SubscriptionCallback<T>,
                                       errorFunction?: ErrorCallback,
                                       completeFunction?: CompleteCallback) => VoidMethod;

export function newEvent<T>(observable: Observable<T>): SubscriptionFunction<T> {
  return (okFunction: SubscriptionCallback<T>, errorFunction?: ErrorCallback, completeFunction?: CompleteCallback): Disposable => {
    const subscription = observable.subscribe(okFunction, errorFunction, completeFunction);

    return () => {
      subscription.unsubscribe();
    };
  };
}
