import {ItemPrice} from "../../domains/itemPrice/itemPrice";
import {IAction} from "../../../shared/domains/core/actions";
import {ItemPriceActionTypes} from "../../actions/itemPricing.actions";
import {ItemActionTypes} from "../../actions/item.actions";

export interface ClmItemsPriceState {
  itemsPrice: { [key: string]: ItemPrice[] };
  loading: { [key: string]: boolean };
  error: string;
}

export const initialClmItemsPriceState: ClmItemsPriceState = {
  itemsPrice: {},
  loading: {},
  error: null
}

export function ClmItemsPriceReducer(state: ClmItemsPriceState = initialClmItemsPriceState, action: IAction<ItemPriceActionTypes|ItemActionTypes>): ClmItemsPriceState {
  switch (action.type) {
    case ItemActionTypes.INIT:
      return initialClmItemsPriceState;
    case ItemPriceActionTypes.FETCH_CLM_ITEMSPRICE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemNo]: true
        }
      };
    case ItemPriceActionTypes.FETCH_CLM_ITEMSPRICE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemNo]: false
        },
        itemsPrice: {
          ...state.itemsPrice,
          [action.itemNo]: action.items.map((p, idx) => ({...p, ConditionItem: (idx+1).toString().padStart(2, '0')}))
        }
      };
    case ItemPriceActionTypes.FETCH_CLM_ITEMSPRICE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemNo]: false
        },
        error: action.error
      };
    case ItemPriceActionTypes.CLM_ITEM_PRICE_CHANGE:
      const newItemPrices = state.itemsPrice[action.itemPrice.ItemNo].map((itemPrice: ItemPrice) => {
        if (itemPrice.ConditionItem == action.itemPrice.ConditionItem &&
          itemPrice.ItemNo == action.itemPrice.ItemNo &&
          itemPrice.AgreementNo == action.itemPrice.AgreementNo) {
          return action.itemPrice;
        }
        return itemPrice;
      });
      return {
        ...state,
        itemsPrice: {
          ...state.itemsPrice,
          [action.itemPrice.ItemNo]: newItemPrices
        }
      };
    case ItemPriceActionTypes.ADD_CLM_ITEM_PRICE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemPrice.ItemNo]: true
        }
      };
    case ItemPriceActionTypes.ADD_CLM_ITEM_PRICE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemPrice.ItemNo]: false
        },
        itemsPrice: {
          ...state.itemsPrice,
          [action.itemPrice.ItemNo]: action.newItemsPrice
        }
      };
    default:
      return state;
  }
}
