import {AxiosWrapper} from "../../shared/utils/axios.wrapper";

export class EasyRequestService {
  static async addMaterial(materialId: number): Promise<any> {
    const response = await AxiosWrapper.post(`/api/easyRequest/AddPslMaterialById/` + materialId);
    return response.data;
  }

  static async getCounter(): Promise<Counter> {
    const response = await AxiosWrapper.get(`/api/easyRequest/Counter`);
    return response.data;
  }
}

export interface Counter {
  count: number
}
