import {Item} from "../../domains/item/item";
import { ItemActionTypes } from "../../actions/item.actions";
import {IAction} from "../../../shared/domains/core/actions";

export interface DraftItemsState {
  items: Item[];
  totalCount: number;
  loading: boolean;
  error: any;
}

export const initialItemState: DraftItemsState = {
  items: [],
  totalCount: 0,
  loading: false,
  error: null
};

export function DraftItemsReducer(state: DraftItemsState = initialItemState, action: IAction<ItemActionTypes>): DraftItemsState {
  switch (action.type) {
    case ItemActionTypes.FETCH_DRAFT_ITEMS:
    case ItemActionTypes.DELETE_DRAFT_ITEM:
      return {
        ...state,
        error: null,
        loading: true
      };
    case ItemActionTypes.FETCH_DRAFT_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items,
        totalCount: action.totalCount
      };
    case ItemActionTypes.FETCH_DRAFT_ITEMS_FAILURE:
    case ItemActionTypes.DELETE_DRAFT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ItemActionTypes.UPDATE_DRAFTITEM_SUCCESS:
      const updatedItems = state.items.map((item: Item) => {
        if (item.ItemNo === action.item.ItemNo && item.TimeStamp === action.item.TimeStamp) {
          return action.item;
        }
        return item;
      });

      return {
        ...state,
        loading: false,
        items: updatedItems
      };
    case ItemActionTypes.DELETE_DRAFT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((item: Item) => item.ItemNo !== action.item.ItemNo)
      };
    default:
      return state;
  }
}
