import {IAction} from "../../shared/domains/core/actions";
import {QueryableReducer} from "../../shared/queryable/query.reducer";
import {error, loaded, loading} from "../../shared/reducers/utils";
import {MRPTypes} from "../actions/mrp.actions";
import {newMrpQuery} from "../domains/mrpQuery";
import {Mrp} from "../domains/mrp";

export interface MRPListState {
  loading: boolean;
  contractLoading: boolean;
  error: string;
  totalCount: number;
  mrpList: Mrp[];
}

export const initialMRPlistState: MRPListState = {
  loading: false,
  contractLoading: false,
  error: null,
  totalCount: 0,
  mrpList: [],
};

export function mrpListReducer(state: MRPListState = initialMRPlistState, action: IAction<MRPTypes>): MRPListState {
  switch (action.type) {
    case MRPTypes.UPDATE_MRP_ENTITY:
    case MRPTypes.SAVE_MRP_ENTITY:
      return {...state, loading: true};
    case MRPTypes.UPDATE_MRP_ENTITY_SUCCESS:
    case MRPTypes.SAVE_MRP_ENTITY_SUCCESS:
    case MRPTypes.UPDATE_MRP_ENTITY_FAILURE:
    case MRPTypes.SAVE_MRP_ENTITY_FAILURE:
      return {...state, loading: false};
    case MRPTypes.SEND_MRP_AS_CONTRACTS:
      return {...state, contractLoading: true};
    case MRPTypes.SEND_MRP_AS_CONTRACTS_SUCCESS:
    case MRPTypes.SEND_MRP_AS_CONTRACTS_FAILURE:
      return {...state, contractLoading: false};
    case MRPTypes.FETCH_MRP_ENTITY_REQUEST_SUCCESS:
      return loaded({mrpList: action.payload?.data, totalCount: action.payload?.total, contractLoading: state.contractLoading});
    case MRPTypes.FETCH_MRP_ENTITY_REQUEST_FAILURE:
      return error(action.error)(state);
    case MRPTypes.FETCH_MRP_ENTITY_REQUEST:
      return loading(state);
    case MRPTypes.MARK_AS_DELETED:
      return loading(state);
    case MRPTypes.MARK_AS_DELETED_SUCCESS:
      return {...state, loading: false};
    case MRPTypes.MARK_AS_DELETED_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return state;
  }
}

export const mrpStateSelector = ['mrp', 'mrpList'];
export const mrpDomainName = 'MRP';
export const {reducer: MrpListReducer, sagas: MRPListSagas, actions: QueryMRPActions} = QueryableReducer<MRPListState>(mrpDomainName, mrpStateSelector, mrpListReducer, newMrpQuery());
