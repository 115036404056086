import * as R from "ramda";
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {DateTime} from "luxon";
import {
  TenderCalendarFilterActionTypes,
  DeleteCalendarFilter,
  FetchHierarchyCompanyCodesByMarket,
  FetchHierarchyCompanyCodesByMarketError,
  FetchHierarchyCompanyCodesByMarketSuccess,
  FetchHierarchyMarketsByZone,
  FetchHierarchyMarketsByZoneError,
  FetchHierarchyMarketsByZoneSuccess,
  FetchHierarchyPlantsByCompanyCode,
  FetchHierarchyPlantsByCompanyCodeError,
  FetchHierarchyPlantsByCompanyCodeSuccess,
  FetchInfoForNewEventError,
  FetchInfoForNewEventSuccess,
  RestoreCalendarFilters,
  SynchronizeFilters,
  SynchronizeFiltersError,
  SynchronizeFiltersSuccess
} from "../actions/calendarFilter.actions";
import {defaultCalendarFilters, newFilters} from "../domains/calendar.query";
import {
  NewCalendarQuery
} from "../actions/calendar.actions";
import {newQuery} from "../../shared/queryable/query";
import {SaveContractCalendarFilters, TenderCalendarSynchronized} from "../../shared/actions/user.actions";
import {CalendarViewMode, CodeNameDTO} from "../domains/calendar";
import {IContractCalendarFilters} from "../../shared/domains/user/user";
import {RootStateStore} from "../../application.reducers";
import {UserService} from "../../shared/domains/user/user.service";
import {CalendarService} from "../domains/calendar.service";
import {buyerToFilter} from "../containers/components/filter/calendarFilters.container";

export function* withDateFilters(filters) {
  const {viewMode, startDate} = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters);
  const isoStartDate = DateTime.fromISO(new Date(startDate).toISOString());
  switch (viewMode) {
    case CalendarViewMode.WEEK:
      const startWeekDate = isoStartDate.weekday === 7 ? isoStartDate.plus({days: 1}).startOf("week") : isoStartDate.startOf("week");
      filters = R.assocPath(['From', 'selected'], {value: startWeekDate.minus({days:1}).toFormat('yyyyMMdd')}, filters);
      filters = R.assocPath(['To', 'selected'], {value: startWeekDate.plus({days: 6}).toFormat('yyyyMMdd')}, filters);
      break;
    case CalendarViewMode.MONTH:
      const startMonthDate = isoStartDate.startOf("month").minus({month:1});
      const endMonthDate = isoStartDate.endOf("month").plus({month: 1});
      filters = R.assocPath(['From', 'selected'], {value: startMonthDate.toFormat('yyyyMMdd')}, filters);
      filters = R.assocPath(['To', 'selected'], {value: endMonthDate.toFormat('yyyyMMdd')}, filters);
      break;
  }
  return filters;
}

function* fetchInfo() {
  try {
    const result = yield call(CalendarService.fetchInfo);
    yield put(new FetchInfoForNewEventSuccess(result));
  } catch (error) {
    yield put(new FetchInfoForNewEventError(error));
  }
}

function* fetchInitialEvents() {
  const {liteDataLoaded} = yield select((state: RootStateStore) => state.shared.user);
  if (liteDataLoaded) {
    const activeFilters: IContractCalendarFilters = yield select((state: RootStateStore) => state.shared.user.currentUser.filters.contractCalendar);
    const {currentUser} = yield select((state: RootStateStore) => state.shared.user);
    let activeFiltersWithBuyer = activeFilters;
    if (currentUser.npdBuyer && R.isEmpty(activeFiltersWithBuyer.buyers)) {
      activeFiltersWithBuyer = {...activeFiltersWithBuyer, buyers: [buyerToFilter(`${currentUser.accountName.toLowerCase()}@nestle.com`)]}
    }
    yield put(new RestoreCalendarFilters(activeFiltersWithBuyer));
    const filters = yield withDateFilters({...newFilters(activeFiltersWithBuyer),...defaultCalendarFilters});
    yield put(new NewCalendarQuery(newQuery(filters)));
  }
}

function* deleteFilter(action: DeleteCalendarFilter) {
  const isPreview = yield select((state: RootStateStore) => state.tenderCalendar.calendar.isPreviewMode);
  const {activeFilters} = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters);
  const newActiveFilters = R.assoc(action.key, activeFilters[action.key].filter(f => f.id !== action.id), activeFilters);
  let filters = newFilters(newActiveFilters);
  if (!isPreview) {
    filters = yield withDateFilters({...filters, ...defaultCalendarFilters});
  }
  yield put(new NewCalendarQuery(newQuery(filters)));
  yield put(new SaveContractCalendarFilters(newActiveFilters));
}

function* resetFilter() {
  const isPreview = yield select((state: RootStateStore) => state.tenderCalendar.calendar.isPreviewMode);
  const {activeFilters} = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters);
  let filters = newFilters(activeFilters);
  if (!isPreview) {
    filters = yield withDateFilters({...filters, ...defaultCalendarFilters});
  }
  yield put(new NewCalendarQuery(newQuery(filters)));
  yield put(new SaveContractCalendarFilters(activeFilters));
}

function* restoreFilter(action: RestoreCalendarFilters) {
  const filters = action.filters;
  if (filters.zones) {
    yield put(new FetchHierarchyMarketsByZone(filters.zones.map(z => z.id as string)));
  }
  if (filters.markets) {
    yield put(new FetchHierarchyCompanyCodesByMarket(filters.markets.map(m => m.id as string)));
  }
  if (filters.companyCodes) {
    yield put(new FetchHierarchyPlantsByCompanyCode(filters.companyCodes.map(c => c.id as string)));
  }
}

function* synchronizeFilters(action: SynchronizeFilters) {
  try {
    const filters: IContractCalendarFilters = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters.activeFilters);
    const user = yield select((state: RootStateStore) => state.shared.user.currentUser);
    const emptyIfNull = (v) =>  R.defaultTo([], v);
    const mapper = s => ({code: s.id as string, name: s.title});
    const buyerMapper = s => ({name: s.id as string, email: s.title});
    const settings = {
      buyers: action.isSync ? emptyIfNull(filters.buyers).map(mapper) : [],
      clusters: action.isSync ? emptyIfNull(filters.clusters).map(mapper) : [],
      zones: action.isSync ? emptyIfNull(filters.zones).map(mapper) : [],
      markets: action.isSync ? emptyIfNull(filters.markets).map(mapper) : [],
      spendCategoryCodes: action.isSync ? emptyIfNull(filters.spendCategories).map(mapper) : [],
      companyCodes: action.isSync ? emptyIfNull(filters.companyCodes).map(mapper) : [],
      plants: action.isSync ? emptyIfNull(filters.plants).map(mapper) : [],
      businesses: action.isSync ? emptyIfNull(filters.businesses).map(mapper) : []
    };

    yield call(UserService.saveUserSettings, {...user, contractSpecificProfile: settings });
    yield call(CalendarService.syncEvents, action.isSync);

    yield put(new SynchronizeFiltersSuccess());
    yield put(new TenderCalendarSynchronized({
      ...settings,
      markets: settings.markets.map(m => m.name)
    }));
  } catch (error) {
    yield put(new SynchronizeFiltersError(error));
  }
}

function* fetchHierarchyMarketsByZone(action: FetchHierarchyMarketsByZone) {
  try {
    let results = [];
    let zoneCodes = action.zoneCodes;
    if (zoneCodes?.length) {
      results = yield all(zoneCodes.map(zoneCode => call(CalendarService.fetchMarketsByZone, zoneCode)));
    }
    const markets: any = R.uniqWith(CodeNameDTO.Equality, R.flatten(results));
    yield put(new FetchHierarchyMarketsByZoneSuccess(markets));
  } catch (error) {
    yield put(new FetchHierarchyMarketsByZoneError(error));
  }
}

function* fetchHierarchyCompanyCodesByMarket(action: FetchHierarchyCompanyCodesByMarket) {
  try {
    let results = [];
    let markets = action.markets;
    if (markets?.length) {
      results = yield call(CalendarService.fetchCompanyCodesByMarket, markets.join(','));
    }
    const companyCodes: any = R.uniqWith(CodeNameDTO.Equality, R.flatten(results));
    yield put(new FetchHierarchyCompanyCodesByMarketSuccess(companyCodes));
  } catch (error) {
    yield put(new FetchHierarchyCompanyCodesByMarketError(error));
  }
}

function* fetchHierarchyPlantsByCompanyCode(action: FetchHierarchyPlantsByCompanyCode) {
  try {
    let results = [];
    let companyCodes = action.companyCodes;
    if (companyCodes?.length) {
      results = yield call(CalendarService.fetchPlantsByCompanyCode, companyCodes.join(','));
    }
    yield put(new FetchHierarchyPlantsByCompanyCodeSuccess(R.uniqWith(CodeNameDTO.Equality, R.flatten(results))));
  } catch (error) {
    yield put(new FetchHierarchyPlantsByCompanyCodeError(error));
  }
}

function* watchFetchInfo() {
  yield takeLatest(TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT, fetchInfo);
}

function* watchFetchInitialEvents() {
  yield takeLatest([
    TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT_SUCCESS,
    TenderCalendarFilterActionTypes.FETCH_INITIAL_EVENTS
  ], fetchInitialEvents);
}

function* watchDeleteFilter() {
  yield takeLatest(TenderCalendarFilterActionTypes.DELETE_CALENDAR_FILTER, deleteFilter);
}

function* watchResetFilter() {
  yield takeLatest(TenderCalendarFilterActionTypes.RESET_CALENDAR_FILTER, resetFilter);
}

function* watchRestoreFilter() {
  yield takeLatest(TenderCalendarFilterActionTypes.RESTORE_CALENDAR_FILTERS, restoreFilter);
}

function* watchSynchronizeFilters() {
  yield takeLatest(TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS, synchronizeFilters);
}

function* watchFetchHierarchyMarketsByZone() {
  yield takeLatest(TenderCalendarFilterActionTypes.FETCH_HIERARCHY_MARKETS_BY_ZONE, fetchHierarchyMarketsByZone);
}

function* watchFetchHierarchyCompanyCodesByMarket() {
  yield takeLatest(TenderCalendarFilterActionTypes.FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET, fetchHierarchyCompanyCodesByMarket);
}

function* watchFetchHierarchyPlantsByCompanyCode() {
  yield takeLatest(TenderCalendarFilterActionTypes.FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE, fetchHierarchyPlantsByCompanyCode);
}

export default function* tenderCalendarFilterSaga() {
  yield all([
    fork(watchFetchInfo),
    fork(watchFetchInitialEvents),
    fork(watchResetFilter),
    fork(watchRestoreFilter),
    fork(watchDeleteFilter),
    fork(watchSynchronizeFilters),
    fork(watchFetchHierarchyMarketsByZone),
    fork(watchFetchHierarchyCompanyCodesByMarket),
    fork(watchFetchHierarchyPlantsByCompanyCode)
  ]);
}
