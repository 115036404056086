import styled from "styled-components";
import { Form } from "formik";
import { StyledDialog } from "../../../../components/layout/styles";
import { ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";

export const SettingsTheme = {
  overrides: {
    MuiTableCell: {
      root: {
        color: '#707070',
        padding: '10px 5px',
        '@media (max-width: 1024px)': {
          fontSize: '12px!important',
          padding: '5px 0',
          '& button': {
            padding: '6px'
          }
        }
      }
    }
  }
};

export const StyledUserSettingsDialog = styled(StyledDialog)`
  && .MuiDialog-paperWidthXs {
    width: 400px;
    padding: 0;
  }
`;

export const UserSettingsForm = styled(Form)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const StyledUserSettingsDialogContent = styled(DialogContent)`
  color: #707070;
  font-size: 16px;
  opacity: 0.8;
  max-height: 600px;
`;

export const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  padding: 0 5px!important;
`;

export const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  display: block!important;
  padding: 5px!important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
`;
export const PABAExpansionPanelDetails = styled(ExpansionPanelDetails)`
  display: block!important;
  padding: 5px!important;
  max-height: 10000px;
`;
