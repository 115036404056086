import React from "react";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Card, LandingIcon, Tile, TileInner} from "../landing.styles";
import {Icon} from "@material-ui/core";

export default function TileComponent({icon, title, desc, url, extLink = false}: {icon:string | JSX.Element, title: string, desc: string, url: string, extLink?: boolean} ) {
  const dispatch = useDispatch();
  return (
    <Tile>
      <TileInner onClick={() => {
        extLink? window.open(url, '_blank'): dispatch(push(url)) }}>
        <Card className="front">
          <LandingIcon>
           {typeof icon === 'string' ? <Icon>{icon}</Icon> : <>{icon}</>}
            </LandingIcon>
          <p className="title">{title}</p>
        </Card>
        <Card className="back">
          <p className="title">{title}</p>
          <p className="desc" style={{overflow:"auto", scrollbarWidth:"thin", scrollbarColor: "#8b8b8b #d8d7df"}}>{desc}</p>
          <p className="link">Details <ArrowForwardIcon/></p>
        </Card>
      </TileInner>
    </Tile>
  )
}
