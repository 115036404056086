export interface NetworkPRItemDTO {
  __metadata: { id: string; type: string; uri: string };
  Currency: string,
  DataChanged: string;
  DelInd: string;
  DeliveryDate: string;
  DocType: string;
  GrInd: string;
  IdNo: string;
  InvRecpInd: string;
  Material: string;
  Plant: string;
  Price: string;
  PriceUnit: string;
  PurchGrp: string;
  PurchOrg: string;
  PurchaseReqItemNo: string;
  PurchaseReqNo: string;
  Quantity: string;
  Requester: string;
  SortText: string;
  TotalPrice: string;
  UnitOfMeasure: string;
  Vendor: string;
}

export class PRItem {
  constructor(
    public readonly Currency: string,
    public readonly DataChanged: string,
    public readonly DelInd: string,
    public readonly DeliveryDate: string,
    public readonly DocType: string,
    public readonly GrInd: string,
    public readonly IdNo: string,
    public readonly InvRecpInd: string,
    public readonly Material: string,
    public readonly Plant: string,
    public readonly Price: string,
    public readonly PriceUnit: string,
    public readonly PurchGrp: string,
    public readonly PurchOrg: string,
    public readonly PurchaseReqItemNo: string,
    public readonly PurchaseReqNo: string,
    public readonly Quantity: string,
    public readonly Requester: string,
    public readonly SortText: string,
    public readonly TotalPrice: string,
    public readonly UnitOfMeasure: string,
    public readonly Vendor: string
  ) {
    this.Quantity = (Quantity || "").trim();
    this.Price = (Price || "").trim();
  }

  static FromBackend(data: NetworkPRItemDTO): PRItem {
    return new PRItem(
      data.Currency,
      data.DataChanged,
      data.DelInd,
      data.DeliveryDate,
      data.DocType,
      data.GrInd,
      data.IdNo,
      data.InvRecpInd,
      data.Material,
      data.Plant,
      data.Price,
      data.PriceUnit,
      data.PurchGrp,
      data.PurchOrg,
      data.PurchaseReqItemNo,
      data.PurchaseReqNo,
      data.Quantity,
      data.Requester,
      data.SortText,
      data.TotalPrice,
      data.UnitOfMeasure,
      data.Vendor
    )
  }
}

export interface NetworkPOItemDTO {
  __metadata: { id: string; type: string; uri: string };
  IdNo: string;
  PurchaseOrdNo: string;
  PurchaseOrdItemNo: string;
  SortText: string;
  Quantity: string;
  UnitOfMeasure: string;
  Price: string;
  Currency: string;
  DeliveryCompleted: string;
  FinalInvoice: string;
  DataChanged: string;
  PriceUnit: string;
  OrderPriceUnit: string;
  NetPrice: string;
  TotalPrice: string;
}

export class POItem {
  constructor(
    public readonly PurchaseOrdNo: string,
    public readonly PurchaseOrdItemNo: string,
    public readonly SortText: string,
    public readonly Quantity: string,
    public readonly UnitOfMeasure: string,
    public readonly Price: string,
    public readonly Currency: string,
    public readonly DeliveryCompleted: string,
    public readonly FinalInvoice: string,
    public readonly PriceUnit: string,
    public readonly OrderPriceUnit: string,
    public readonly NetPrice: string,
    public readonly TotalPrice: string,
  ) {
    this.Quantity = (Quantity || "").trim();
    this.Price = (Price || "").trim();
  }

  static FromBackend(data: NetworkPOItemDTO): POItem {
    return new POItem(
      data.PurchaseOrdNo,
      data.PurchaseOrdItemNo,
      data.SortText,
      data.Quantity,
      data.UnitOfMeasure,
      data.Price,
      data.Currency,
      data.DeliveryCompleted,
      data.FinalInvoice,
      data.PriceUnit,
      data.OrderPriceUnit,
      data.NetPrice,
      data.TotalPrice,
    )
  }
}

export interface NetworkCompliantItemDTO {
  PurchaseDocNo: string;
  ItemNo: string;
  DocCat: string;
  MaterialNo: string;
  MatText: string;
  MatGrp: string;
}

export interface CompliantItemFromAzure {
  materialNumber: string,
  materialGroup: string,
  matText: string,
  item: string
}

export class CompliantItem {
  constructor(
    public readonly PurchaseDocNo: string,
    public readonly ItemNo: string,
    public readonly DocCat: string,
    public readonly MaterialNo: string,
    public readonly MatText: string,
    public readonly MatGrp: string
  ) {
  }

  static FromBackend(data: NetworkCompliantItemDTO): CompliantItem {
    return new CompliantItem(
      data.PurchaseDocNo,
      data.ItemNo,
      data.DocCat,
      data.MaterialNo,
      data.MatText,
      data.MatGrp
    )
  }
}
