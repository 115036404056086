import { o } from 'odata';
import { SpendCategory } from "./category";
import { AxiosWrapper } from "../../utils/axios.wrapper";
import { withAuthHeader, withSystemAlias } from "../auth/authentication.service";
import ODataClientWrapper from "../../utils/odataClient.wrapper";
import { PurchaseOrg } from "./purchaseOrg";
import { CompanyData } from "./company";
import { Plant } from "./plant";
import { ODataFilter, Operators } from "../../../../lib/odata";
import { IUserTenderCalendarItem } from "../user/user";
import { CodeNamePair, IdNamePair } from "../../../preferredSuppliers/domains/psl";
import { Requestor } from "./requestor";
import { CostCenter } from "./costCenter";
import { SAPBoolean } from "../core/sapBoolean";
import { environment } from "../core/environment";
import { CurrencyData } from "./currency";
import { Material } from "./material";
import { ProjectIdWBS } from "./projectIdWBS";
import { PoNumber } from "./poNumber";
import { PrNumber } from "./prNumber";
import { UomData } from "./uom";
import { Invoice } from "./invoice";
import { InvDoc } from "./invDoc";
import { NestleSupplierInv } from './nestleSupplierInvoice'
import { ExtContactNumber } from './exactContactNumber';
import { ExtReqNumber } from './exactReqNumber';
import { PPMCostPlan } from './ppmCostPlan';
import { SourcingID } from './sourcingId';
import { PslID } from './pslId';
import { MaterialType } from './materialType';
import { MrpSpendCat } from './mrpSpendCat';

export interface ILanguage {
  id: number,
  name: string,
  languageTag: string,
  languageCode2Letters: string,
  defaultCountryCode2Letters: string
}

export interface ITaxCode {
  id: number,
  proc: string,
  code: string,
  description: string
}

export interface IIncoTerms {
  id: number,
  value: string,
  description: string
}

export interface IPurchasingGroup {
  code: string,
  name: string
}
interface ChildCodeResults {
  results: any;
}

export class MasterService {
  static async fetchSubCategoriesById(id: number, expand = false): Promise<SpendCategory[]> {
    const response = await AxiosWrapper.get(`/api/PslSpendCat/GetSubSpendCategoriesById?parentSpendCatId=${id}&expand=${expand}`);
    return response.data.map(SpendCategory.FromBackendCamel);
  }
  static async fetchSpendCatL1(query?): Promise<SpendCategory[]> {
    const url = environment.REACT_APP_API_BASE + "/odata/PslSpendCat";
    const result = await o(url, { headers: await withAuthHeader(), fragment: null }).get().query(query);
    return result.value.map(SpendCategory.FromBackend);
  }

  static async fetchSpendCatL3(term: string = ""): Promise<SpendCategory[]> {
    const response = await AxiosWrapper.get(`/api/PslSpendCat/GetLevel3SpendCategories?searchTerm=${term}`);
    return response.data.map(SpendCategory.FromBackendCamel);
  }

  static async fetchParentSpendCatsOfL3(level3SpendCatCode: string): Promise<SpendCategory[]> {
    const response = await AxiosWrapper.get(`/api/psl/GetParentSpendCatsOfL3SpendCat?level3SpendCatCode=${level3SpendCatCode}`);
    return response.data;
  }

  static async fetchSuppliersFromAzure(text: string = "", locationCompanyCodes: string = "", isCreatePSL: boolean = false): Promise<any[]> {
    const response = await AxiosWrapper.get('/api/HackathonPsl/GetPslAmdrSuppliers',
      { params: { searchTerm: text, locationCompanyCodes, isCreatePSL } }
    );
    return response.data;
  }

  static async fetchChildrenSuppliersV2(locationCompanyCodes: string = "", parentId: string, pslId?: number, zones: string = ""): Promise<ChildCodeResults> {
    const response = await AxiosWrapper.post('/api/HackathonPsl/GetPslAmdrSuppliersChildren',
      { locationCompanyCodes, childrenOf: parentId, parentId: pslId, zones }
    );
    return response.data;
  }

  static async fetchParentSuppliers(text: string = ""): Promise<any[]> {
    const response = await AxiosWrapper.get('/api/HackathonPsl/GetPslAmdrSuppliersHierarchy',
      { params: { searchTerm: text } }
    );
    return response.data;
  }

  static async fetchMRPTaxCodes(companyCode: string = ""): Promise<ITaxCode[]> {
    const response = await AxiosWrapper.get('/api/Mrp/GetTaxCodes',
      { params: { companyCode } }
    );
    return response.data;
  }

  static async fetchMRPIncoTerms(): Promise<IIncoTerms[]> {
    const response = await AxiosWrapper.get('/api/Mrp/GetIncoTerms');
    return response.data;
  }

  static async fetchMRPPurchasingGroups(): Promise<IPurchasingGroup[]> {
    const response = await AxiosWrapper.get('/api/mrp/GetPurchasingGroups');
    return response.data;
  }

  static async fetchMaterialFromAzure(matGroup: string, term: string = "", locationCompanyCodes: string = ""): Promise<{ id: string, label: string, matGrp: string, uoM: string }[]> {
    const response = await AxiosWrapper.get('/api/HackathonPsl/GetPslAmdrMaterials?locationCompanyCodes=' + locationCompanyCodes,
      { params: { searchTerm: term, materialgroup: matGroup } }
    );
    return response.data.map(el => ({ value: el.id, label: el.name, matGrp: el.materialGroup, uoM: el.uoM }));
  }

  static async fetchPaymentTermsSet(compCode, systemAlias) {
    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PaymentTermsSet")
      .filter({ field: "CompCode", value: compCode });
    const response = await query.execute();
    return response.data.d.results ? response.data.d.results.map(el => ({ value: el.Terms, label: el.Terms })) : [];

  }

  static async fetchCompanyCode(term?: string, systemAlias?: string): Promise<CompanyData[]> {
    let query = ODataClientWrapper.get()
      .resource("CompanyCodeSet")
      .filter({ field: 'CompCodeDesc', value: term })

    if (systemAlias) {
      query = query.withSystemAlias(systemAlias)
    } else {
      query = query.withMultiOrigin()
    }

    const response = await query.execute();
    const companies = response.data.d.results.map(CompanyData.FromBackend);
    return MasterService.getDistinctCompanies(companies)
  }

  static async fetchCompanyCodeFromAzure(term?: string): Promise<CompanyData[]> {
    const response = await AxiosWrapper.get('/api/sapdata/GetCompanyCodesByDefaultAlias',
      { params: { filter: term } });
    const companies = response.data.d.results.map(CompanyData.FromBackend);

    return MasterService.getDistinctCompanies(companies)
  }

  static async fetchCompanyCodeBySystemAlias(term: string = '', systemAlias: string = ''): Promise<CompanyData[]> {
    let oData = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("CompanyCodeSet")
      .filter({ field: 'CompCodeDesc', value: term });

    if (systemAlias) {
      oData = oData.withSystemAlias(systemAlias);
    }

    const response = await oData.execute();
    const companies = response.data.d.results.map(CompanyData.FromBackend);

    return MasterService.getDistinctCompanies(companies)
  }

  static getDistinctCompanies(companies: CompanyData[]) {
    const distinctCompanies = [];

    let map = new Map();
    companies.forEach(company => {
      if (!map.has(company.CompCode)) {
        map.set(company.CompCode, true);
        distinctCompanies.push(company);
      }
    })

    return distinctCompanies
  }

  static async fetchTenderClusters(): Promise<IUserTenderCalendarItem[]> {
    const path = `/api/ContractsCalendar/GetClustersById`;
    const result: any = await AxiosWrapper.get(path);
    return result.data;
  }

  static async fetchTenderMarkets(): Promise<string[]> {
    const path = `/api/ContractsCalendar/GetMarkets`;
    const result: any = await AxiosWrapper.get(path);
    return result.data.map((m, idx) => ({ code: `${idx}`, name: m }));
  }

  static async fetchTenderSpendCategories(): Promise<IUserTenderCalendarItem[]> {
    const response = await AxiosWrapper.get('/api/Contracts/GetContractBrowserSpendCategoriesLevel1');
    return response.data;
  }

  static async fetchTenderBuyers(): Promise<IUserTenderCalendarItem[]> {
    const response = await AxiosWrapper.get('/api/ContractsCalendar/GetBuyers');
    return response.data;
  }

  static async fetchTenderCompanyCodes(): Promise<IUserTenderCalendarItem[]> {
    const response = await AxiosWrapper.get('/api/ContractsCalendar/GetCompanyCodes');
    return response.data;
  }

  static async fetchTenderBusinesses(): Promise<IUserTenderCalendarItem[]> {
    const response = await AxiosWrapper.get('/api/ContractsCalendar/GetBusinesses');
    return response.data;
  }

  static async fetchPurchaseOrgWithCompanyCodes(term: string = "", companyCode: { id: string }[], systemAlias: string): Promise<PurchaseOrg[]> {
    let filter = ODataFilter.for({ field: 'PurchaseOrgName', value: term });

    const query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PurchOrgSet")
      .filter(filter)

    let newQuery = query;

    if (companyCode.length > 0) {
      newQuery = query
        .filter(companyCode.reduce((acc, f) => acc.or({ field: "CompCode", value: f.id }), ODataFilter.empty()));
    }

    const result = await newQuery.execute()
    return MasterService.getDistinctPurchaseOrganizations(result.data.d.results.map(PurchaseOrg.FromBackend))
      .map(x => x.toSelect());
  }

  static getDistinctPurchaseOrganizations(organizations: PurchaseOrg[]) {
    const distincts = [];

    let map = new Map();
    organizations.forEach(company => {
      if (!map.has(company.PurchaseOrg)) {
        map.set(company.PurchaseOrg, true);
        distincts.push(company);
      }
    })

    return distincts
  }

  static async fetchPurchaseGroup(term: string, systemAlias: string): Promise<any[]> {
    const response = await ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PurchGroupSet")
      .filter({ field: 'PurchasingGroupName', value: term })
      .execute();

    return response.data.d.results?.map(v => ({ value: v.PurchasingGroup, label: v.PurchasingGroupName }));
  }

  static async fetchMeterialTypes(term: string, systemAlias: string): Promise<any[]> {
    const response = await ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("MaterialTypeSet")
      .filter({ field: 'Mat_Type', value: term })
      .execute();

    return response.data.d.results?.map(v => ({ value: v.Mat_Type, label: v.Mtype_desc }));
  }

  static async fetchReasonCodes(systemAlias: string): Promise<any[]> {
    const response = await ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("ReasonCodeSet")
      .execute();

    return response.data.d.results?.map(v => ({ value: v.NBR, label: v.ACTIVITY }));
  }

  static async fetchGlAccount(term: string, systemAlias: string): Promise<any[]> {
    const response = await ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("GLAccountSet")
      .filter({ field: 'GLAccountNo', value: term })
      .execute();
    console.log(response.data.d.results?.map(v => ({ value: v.GLAccountNo, label: v.Description })));
    return response.data.d.results?.map(v => ({ value: v.GLAccountNo, label: v.Description }));
  }

  static async fetchZones(): Promise<IdNamePair[]> {
    const response = await AxiosWrapper.get('/api/Locations/Zones');
    return response.data;
  }

  static async fetchLanguages(): Promise<ILanguage[]> {
    const response = await AxiosWrapper.get('/api/HackathonPsl/GetLanguages');
    return response.data;
  }

  static async fetchBusinessUnits(): Promise<IdNamePair[]> {
    const response = await AxiosWrapper.get('/api/HackathonPsl/GetPslBusinessUnits');
    return response.data;
  }

  static async fetchPlants(text: string = "", locationCompanyCodes: string = "", callback?: any, materialNumber?: string): Promise<CodeNamePair[]> {
    const response = AxiosWrapper.get('/api/HackathonPsl/GetPslAmdrPlants',
      { params: { searchTerm: text, locationCompanyCodes, materialNumber } }
    );
    return response.then(result => {
      let formatedItems = result.data.map(m => ({ value: m.code, label: m.name }))
      if (callback) {
        callback(formatedItems);
      }
      else {
        return formatedItems;
      }
    });
  }

  static async fetchPlantsBySearchOrZoneOrMarketOrCompCode(text: string = "", locationZones: string = "", locationMarkets: string = "", locationCompanyCodes: string = "", materialNumber: string = "", callback?: any): Promise<CodeNamePair[]> {
    const response = AxiosWrapper.get('/api/HackathonPsl/GetPslAmdrPlantsV2',
      { params: { searchTerm: text, locationZones, locationMarkets, locationCompanyCodes, materialNumber } }
    );

    return response.then(result => {
      let formatedItems = result.data.map(m => ({ value: m.code, label: m.name }));
      return callback ? callback(formatedItems) : formatedItems
    });
  }

  static async fetchPlant(term: string = "", materialCode: string = "", compCode: string = ""): Promise<Plant[]> {
    if (term.length < 2) return Promise.resolve([]);

    let query = ODataClientWrapper.get()
      .resource("PlantSet")
      .filter({ field: "PlantName", value: term });
    if (materialCode && materialCode.trim() !== "") {
      query.filter({ field: "MaterialNo", value: materialCode });
    }
    if (compCode && compCode.trim() !== "") {
      query.filter({ field: "CompCode", value: compCode });
    }
    const response = await query.execute();

    return response.data.d.results.map(Plant.FromBackend);
  }

  static async fetchPlantsWithCompanyCodes(term: string = "", companyCodes: { id: string }[], systemAlias: string): Promise<Plant[]> {
    if (term.length < 2) return Promise.resolve([]);

    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PlantSet")
      .filter({ field: "PlantName", value: term })
      .filter(companyCodes.reduce((acc, f) => acc.or({ field: "CompCode", value: f.id }), ODataFilter.empty()))
      .filter({ field: "PlantOnly", value: SAPBoolean.TRUE });

    const response = await query.execute();

    return response.data.d.results.map(Plant.FromBackend);
  }

  static fetchUsersBySearchTerm(term: string = "", callback) {
    if (term.length < 4) return Promise.resolve([]).then(callback);
    return AxiosWrapper.get('/api/Users/GetUserNameAndEmail', { params: { searchTerm: term } })
      .then(res => res.data.map(user => ({ display: user.emailAddress, id: user.userName })))
      .then(callback);
  }

  static async fetchDefaultPaymentTerm(supplierId: string, purchOrg: string): Promise<string> {
    const response = await AxiosWrapper.get('/api/mrp/GetDefaultPaymentTerms',
      { params: { supplierId: supplierId, purchasingOrg: purchOrg } });
    return response.data ? response.data : null;
  }

  static async fetchPaymentTerms(withSystemAlias: boolean, compCode: string, purchOrg: string): Promise<{ value: any, label: any }[]> {
    const response = await AxiosWrapper.get('/api/sapdata/GetPaymentTerms',
      { params: { companyCode: compCode, purchaseOrg: purchOrg, routingBasedOnHistoricalData: withSystemAlias } });
    return response.data ? response.data.map(el => ({ value: el.terms, label: el.description })) : [];
  }

  static async fetchUserEmails(email: string): Promise<{ value: string, label: string }[]> {
    if (email.length < 4) return Promise.resolve([]);

    const response = await AxiosWrapper.get('/api/CompetitiveBidding/ApproverEmails', { params: { searchTerm: email } });
    return response.data.map(x => ({ value: x, label: x }));
  }

  static async fetchAdminsOrUsers(searchTerm: string, adminFlag: boolean): Promise<{ value: string, label: string }[]> {
    if (searchTerm.length < 4) return Promise.resolve([]);

    const response = await AxiosWrapper.get('/Feedback/GetUserDetails', { params: { keyword: searchTerm, adminFlag: adminFlag } });
    return response.data.map(x => ({ value: x.email, label: x.userName, id: x.id }));
  }

  static async fetchCostCenter(term: string = "", companyCodes: { id: string }[], systemAlias: string): Promise<CostCenter[]> {
    if (term.length < 2) return Promise.resolve([]);

    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("CostCenterSet")
      .filter({ field: "Description", value: term });

    const newQuery = query
      .filter(companyCodes.reduce((acc, f) => acc.or({ field: "CompCode", value: f.id }), ODataFilter.empty()));

    const response = await newQuery.execute();
    return response.data.d.results.map(CostCenter.FromBackend).map(CostCenter.toSelect);
  }
  static async fetchCountryRegion() {
    let query = ODataClientWrapper.get()
      .resource("CountryRegion")
      .filter({ field: "Langu", value: "E" });
    const response = await query.execute();
    return response.data.d.results ? response.data.d.results.map(el => ({ value: el.Country, label: el.Description, Region: el.Region })) : [];
  }

  static async fetchTaxCode(compCode: string, systemAlias: string) {
    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("TaxCodeSet")
      .filter({ field: "CompCode", value: compCode });
    const response = await query.execute();
    return response.data.d.results ? response.data.d.results.map(el => ({ value: el.TaxCodeId, label: el.TaxCodeTxt })) : [];
  }
  static async fetchCurrencyDecimals(systemAlias: string) {
    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("CurrencyDecimal")
    const response = await query.execute();
    return response.data.d.results ? response.data.d.results.map(el => ({ currency: el.CurrencyKey, decimal: el.Decimal })) : [];
  }
  static async fetchProfitCenter(term: string, systemAlias: string): Promise<any> {
    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("ProfitCenter")
      .filter({ field: "Profit_Center", value: term });
    const response = await query.execute();
    return response.data.d.results ? response.data.d.results.map(el => ({ value: el.Profit_Center, label: el.ProfitCenterTxt })) : [];
  }
  static async fetchConditionTypes(Purchaseorg: string, systemAlias: string): Promise<{ value: string, label: string, calculationType: string }[]> {
    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("MASS_TABLE_UPDTSet")
      .filter({ field: "Purchaseorg", value: Purchaseorg });
    const response = await query.execute();
    console.log(response.data.d.results);
    return response.data.d.results ? response.data.d.results?.map(el => ({ value: el.Cond_Type, label: el.VTEXT, calculationType: el.Status })) : [];
  }
  static async fetchRequestor(term: string = "", fn: Function, IdNo: string, systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    let query = IdNo ?
      ODataClientWrapper.get()
        .resource("UserDetails")
        .filter({ field: "SearchStr", value: term })
        .filter({ field: "IdNo", value: IdNo }) :
      ODataClientWrapper.get()
        .resource("UserDetails")
        .filter({ field: "SearchStr", value: term })

    if (systemAlias) {
      query = query.withSystemAlias(systemAlias)
    }
    const response = await query.execute();
    return response.data.d.results.map(Requestor.FromBackend).map(Requestor.toSelect);
  }

  static async fetchSpendCat(systemAlias: string = ""): Promise<{ id: string, title: string }[]> {

    let query = ODataClientWrapper.get()
      .resource("MrpSpendCat")

    if (systemAlias) {
      query = query.withSystemAlias(systemAlias)
    }
    const response = await query.execute();
    return response.data.d.results.map(MrpSpendCat.FromBackend);
    return []
  }

  static async fetchMaterialType(term: string = "", systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    let query = ODataClientWrapper.get()
      .resource("MaterialTypeSet")
      .filter({ field: "Mat_Type", value: term })

    if (systemAlias) {
      query = query.withSystemAlias(systemAlias)
    }
    const response = await query.execute();
    return response.data.d.results.map(MaterialType.FromBackend).map(MaterialType.toSelect);
  }

  static async fetchRequestorForEditRequest(term: string = "", fn: Function, IdNo: string, systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    let query = IdNo ?
      ODataClientWrapper.get()
        .resource("UserDetails")
        .filter({ field: "SearchStr", value: term })
        .filter({ field: "IdNo", value: IdNo }) :
      ODataClientWrapper.get()
        .resource("UserDetails")
        .filter({ field: "SearchStr", value: term })

    if (systemAlias) {
      query = query.withSystemAlias(systemAlias)
    }
    const response = await query.execute();
    return response.data.d.results.map(Requestor.FromBackend).map(Requestor.toSelect);
  }

  static async fetchProjectId(term: string = "", comapnyCode: { id: string }[], systemAlias: string) {
    if (term.length < 2) return Promise.resolve([]);

    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("WBSSet")
      .filter({ field: "Desc", value: term });

    const newQuery = query
      .filter(comapnyCode.reduce((acc, f) => acc.or({ field: "CompCode", value: f.id }), ODataFilter.empty()));
    const response = await newQuery.execute();

    return response.data.d.results.map(ProjectIdWBS.FromBackend).map(ProjectIdWBS.toSelect);
  }

  static async fetchNestleSupplierInvoice(term: string = "", fn: Function, IdNo: string, systemAlias: string = "") {
    if (term.length < 2) return Promise.resolve([]);
    let query = ODataClientWrapper.get()
      .resource("InvDetailSet")
      .filter({ field: "SuppInvRef", value: term })
    if (systemAlias) {
      query = query.withSystemAlias(systemAlias)
    }

    const response = await query.execute();
    return response.data.d.results.map(NestleSupplierInv.FromBackend).map(NestleSupplierInv.toSelect);
  }

  static fetchComplianceIndicator() {
    return [
      { id: "C", title: "Compliant", azureId: "Compliant" },
      { id: "N", title: "Non Compliant", azureId: "Non Compliant" },
      { id: "M", title: "Missing CB", azureId: "Missing CB" },
      { id: "V", title: "Pending OB Validation", azureId: "Pending OB Validation" },
      { id: "O", title: "Rejected by OB", azureId: "Rejected by OB" },
    ]
  }

  static async fetchPrNumbers(term: string = "", systemAlias: string): Promise<{ value: string, label: string }[]> {
    if (term.length < 2) {
      return Promise.resolve([]);
    }
    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PurchReqItemSet")
      .filter({ field: "PurchaseReqNo", value: term.toLocaleUpperCase() })
      .filter({ field: "IdNo", value: 'S' })

    const response = await query.execute();
    return response.data.d.results.map(PrNumber.FromBackend).map(PrNumber.toSelect);
  }

  static async fetchPrNumbersInv(term: string = "", systemAlias: string): Promise<{ value: string, label: string }[]> {
    if (term.length < 2) {
      return Promise.resolve([]);
    }

    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PurchReqItemSet")
      .filter({ field: "PurchaseReqNo", value: term.toLocaleUpperCase() })
      .filter({ field: "IdNo", value: 'S' })

    const response = await query.execute();
    return response.data.d.results.map(PrNumber.FromBackend).map(PrNumber.toSelect);
  }

  static async fetchPoNumbers(term: string = "", systemAlias: string, companyCode?: { id: string }[]): Promise<{ value: string, label: string }[]> {
    if (term.length < 2) {
      return Promise.resolve([]);
    }

    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("POItemSet")
      .filter({ field: "PurchaseOrdNo", value: term })
      .filter({ field: "IdNo", value: 'S' })

    const newQuery = query;
    if (companyCode) {
      newQuery.filter(companyCode.reduce((acc, f) => acc.or({ field: "CompCode", value: f.id }), ODataFilter.empty()));
    }

    const response = await newQuery.execute();
    return response.data.d.results.map(PoNumber.FromBackend).map(PoNumber.toSelect);
  }

  static async fetchInvNumbers(term: string = "", systemAlias: string): Promise<{ value: string, label: string }[]> {
    if (term.length < 2) {
      return Promise.resolve([]);
    }

    let query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("InvDetailSet")
      .filter({ field: "InvDocNo", value: term })
      .filter({ field: "IdNo", value: 'VH' })

    const response = await query.execute();
    return response.data.d.results.map(InvDoc.FromBackend).map(InvDoc.toSelect);
  }

  static async fetchCurrencies(term: string = "", systemAlias: string = ""): Promise<{ value: string, label: string }[]> {
    let query = await ODataClientWrapper.get()
      .resource("CurrencySet")

    if (systemAlias) {
      query = query.withSystemAlias(systemAlias)
    }

    const response = await query.execute();
    const values = response.data.d.results.map(CurrencyData.FromBackend).map(CurrencyData.toSelect);

    const searchPhrase = term.toUpperCase()
    return values.filter(x => x.value.indexOf(searchPhrase) !== -1)
  }

  static async fetchMaterial(term: string = "", plantCodes: { id: string }[], systemAlias: string): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    const query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("MaterialSet")
      .filter(plantCodes.reduce((acc, f) => acc.or({ field: "PlantCode", value: f.id }), ODataFilter.empty())).filter({ field: "MaterialDesc", value: term })
      .filter({ field: "IdNo", value: 'S' })
    const response = await query.execute();
    return response.data.d.results.map(Material.FromBackend).map(Material.toSelect)
  }

  static async fetchExatContactNumber(term: string = "", systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    const query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("ExtContractNoSet")
      .filter({ field: "ContractNo", value: term.toLocaleUpperCase() })

    const response = await query.execute();
    return response.data.d.results.map(ExtContactNumber.FromBackend).map(ExtContactNumber.toSelect)
  }

  static async fetchPPMCostPlan(term: string = "", systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    const query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PPMCostPlanSet")
      .filter({ field: "PPMCstPln", value: term.toLocaleUpperCase() })

    const response = await query.execute();
    return response.data.d.results.map(PPMCostPlan.FromBackend).map(PPMCostPlan.toSelect)
  }

  static async fetchSourcingID(term: string = "", systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    const query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("SourcingIDSet")
      .filter({ field: "Sourcing_ID", value: term.toLocaleUpperCase() })

    const response = await query.execute();
    return response.data.d.results.map(SourcingID.FromBackend).map(SourcingID.toSelect)
  }

  static async fetchExatReqNumber(term: string = "", systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    const query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("ExtRequestNoSet")
      .filter({ field: "RequestNo", value: term.toLocaleUpperCase() })

    const response = await query.execute();
    return response.data.d.results.map(ExtReqNumber.FromBackend).map(ExtReqNumber.toSelect)
  }

  static async fetchPslID(term: string = "", systemAlias: string = ""): Promise<{ id: string, title: string }[]> {
    if (term.length < 2) return Promise.resolve([]);

    const query = ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("PslIDSet")
      .filter({ field: "PSL_ID", value: term.toLocaleUpperCase() })

    const response = await query.execute();
    return response.data.d.results.map(PslID.FromBackend).map(PslID.toSelect)
  }

  static async fetchUoms(term: string = "", material: string): Promise<{ value: string, label: string }[]> {
    const response = await ODataClientWrapper.get()
      .resource("UnitSet")
      .filter({ field: "Material", value: material })
      .execute();

    const values = response.data.d.results.map(UomData.FromBackend).map(UomData.toSelect);

    const searchPhrase = term.toUpperCase()
    return values.filter(x => x.label.indexOf(searchPhrase) !== -1)
  }

  static async fetchInvoices(Instid: string, Typeid: string, systemAlias: string): Promise<Invoice[]> {
    const response = await ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource("GosAttachmentSet")
      .filter({ field: "Instid", value: Instid })
      .filter({ field: "Typeid", value: Typeid })
      .filter({ field: "Catid", value: 'BO' })
      .execute();

    return response.data.d.results.map(Invoice.FromBackend);
  }

  static async fetchInvoiceData(Instid: string, TypeId: string, AttaId: string, systemAlias: string): Promise<any> {
    const response = await AxiosWrapper.getWithSystemAlias(`/api/sapdata/GosDownloadSet?Instid=${Instid}&Typeid=${TypeId}&Catid=BO&AttaId=${encodeURIComponent(AttaId)}`, {
      responseType: 'blob',
    }, systemAlias);
    return response.data;
  }

  static async fetchInvoiceDataInNewTab(Instid: string, TypeId: string, AttaId: string, systemAlias: string): Promise<any> {
    const response = await AxiosWrapper.getWithSystemAlias(`/api/sapdata/GosDownloadSet?Instid=${Instid}&Typeid=${TypeId}&Catid=BO&AttaId=${encodeURIComponent(AttaId)}`, {
    }, systemAlias);

    return response.data;
  }

  static async exportRcLogs(systemAlias, exportInputs) {
    const response = await AxiosWrapper.post(`/api/sapdata/Export`, exportInputs, {
      responseType: 'blob',
      headers: withSystemAlias(systemAlias, {}, true)
    })
    console.log(response)
    return response.data;
  }

  static async getLineManager(email: string): Promise<any[]> {
    const response = await AxiosWrapper.get(`/api/Users/lineManager?email=${email}`);
    return response.data;
  }

  static async fetchPslScopeTitles() {
    const response = await AxiosWrapper.get(`/api/HackathonPsl/GetPslScopeFilterTitles`);
    return response.data;
  }
}

