import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';

export const DataWithPopover = ({ item, hoverItem }: { item: string, hoverItem: string  }) => {

    const useStyles = makeStyles((theme) => ({
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
        customTooltip: {
            backgroundColor: "#fff",
            color: '#777777',
            boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)", borderRadius: "5px",
            marginTop: 0,
        },
          
    }));

    const classes = useStyles({});

    return (
        <div>
            <Tooltip
                interactive
                classes={{ tooltip: classes.customTooltip }}
                title={
           
                    <div 
                        style={{
                            fontSize: 14,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }} 
                        key={hoverItem}
                    >
                        {hoverItem}
                    </div>
                    
                }
                placement="bottom-start"
            >
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    {item}
                </div>
              
            </Tooltip>
        </div>
    );
}
