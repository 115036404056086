import { all, call, fork, put, select, takeLatest, delay } from 'redux-saga/effects'
import {
  ExportMRPListDataError,
  ExportMRPListDataSuccess,
  ExportMRPTemplateError,
  ExportMRPTemplateSuccess,
  ExportMRPListDataViaEmailSuccess,
  ExportMRPListDataViaEmailError,
  ResetMRPExport,
  FetchMRP,
  FetchMRPRequest,
  FetchMRPRequestFailure,
  FetchMRPRequestSuccess,
  ImportMRPError,
  ImportMRPSuccess,
  MRPTypes,
  ResetMRPImportError,
  SaveMRPFailure,
  SaveMRPSuccess,
  SaveMRP,
  FetchSingleMRP,
  FetchSingleMRPSuccess,
  FetchSingleMRPFailure,
  UpdateMRP,
  UpdateMRPFailure,
  UpdateMRPSuccess,
  MarkAsDeleted,
  MarkAsDeletedSuccess,
  MarkAsDeletedError,
  CheckMrpForDuplicates,
  CheckMrpForDuplicatesFailure,
  GetMrpScraping,
  GetMrpScrapingSuccess,
  GetMrpScrapingError,
  PostMrpScraping,
  PostMrpScrapingSuccess,
  PostMrpScrapingError,
  SendMrpAsContracts,
  SendMrpAsContractsError,
  SendMrpAsContractsSuccess,
  FetchSingleMrpAsList, ImportMRPTimeout, ApproveMrpSuccess, ApproveMrpFailure, ApproveMrpItem, ApproveMrps, SearchMRP
} from "../actions/mrp.actions";
import { MrpService } from "../domains/mrp.service";
import { QueryMRPActions } from '../reducers/mrpList.reducer';
import { RootStateStore } from "../../application.reducers";
import { push } from "connected-react-router";
import { NbsService } from 'src/app/nbsCockpit/services/nbs.service';

function* fetch(action): any {
  try {
    yield put(new FetchMRPRequest());
    const result = yield call(MrpService.fetch, action.query);
    yield put(new FetchMRPRequestSuccess(result));
  } catch (error) {
    yield put(new FetchMRPRequestFailure(error));
  }
}

function* initialFetch(action: FetchMRP) {
  const query: any = yield select(({ mrp }: RootStateStore) => mrp.mrpList.query);
  yield put({ type: QueryMRPActions.onNewQuery, query: query });
}
function* searchMRP(action: SearchMRP) {
  try {
    yield put(new FetchMRPRequest());
    const query: any = yield select(({ mrp }: RootStateStore) => mrp.mrpList.query);
    const result = yield call(MrpService.fetch, query, true, action.mrpSearch);
    yield put(new FetchMRPRequestSuccess(result));
  } catch (error) {
    yield put(new FetchMRPRequestFailure(error));
  }
}
function* fetchSingleMrp(action: FetchSingleMRP) {
  try {
    const result = yield call(MrpService.fetchSingleMrp, action.id);
    yield put(new FetchSingleMRPSuccess(result));
  } catch (error) {
    yield put(new FetchSingleMRPFailure(error));
  }
}
function* fetchSingleMrpAsList(action: FetchSingleMrpAsList) {
  try {
    const result = yield call(MrpService.fetchSingleMrp, action.id);
    yield put(new FetchMRPRequestSuccess({ data: [result], total: 1 }));
  } catch (error) {
    yield put(new FetchMRPRequestFailure(error));
  }
}
function* saveMRP(action: SaveMRP) {
  try {
    const result = yield call(MrpService.save, action.mrp);
    yield put(new SaveMRPSuccess(result));
    action.done();
  } catch (error) {
    yield put(new SaveMRPFailure(error));
    action.doneWithErrors(error);
  }
}

function* updateMRP(action: UpdateMRP) {
  try {
    yield call(MrpService.update, action.mrp);
    yield put(new UpdateMRPSuccess());
    action.done();
  } catch (error) {
    yield put(new UpdateMRPFailure(error));
    action.doneWithErrors(error);
  }
}

function* importMRP(action) {
  try {
    yield call(MrpService.import, action.formData);
    yield put(new ImportMRPSuccess('MRP Import successful. You will receive an email with all details. However, you may check the tool now for your entries,if not uploaded wait for the notification'));
    yield delay(5000);
    yield put(push(`/mrp`));
  } catch (error) {
    if (error && error.response === undefined) {
      yield put(new ImportMRPTimeout());
      yield delay(3000);
      yield put(push(`/mrp`));
    } else {
      const validationErrors: { key: number, value: { code: string, message: string }[] }[] = error.response.data.validationErrors ?? [];
      const parsingErrors: { lineId: number, failedPropertyToParse: string, parsingExceptionMessage: string }[] = error.response.data.parsingErrors ?? [];
      const file: string = error.response.data.file ?? "";
      yield put(new ImportMRPError(file, validationErrors, parsingErrors));
    }
  } finally {
    yield put(new ResetMRPImportError());
  }
}

function* exportMRPData() {
  try {
    const query: any = yield select(({ mrp }: RootStateStore) => mrp.mrpList.query);
    const result = yield call(MrpService.exportData, query);
    yield put(new ExportMRPListDataSuccess(`export_mrp.xlsx`, result));
  } catch (error) {
    yield put(new ExportMRPListDataError(error));
  }
}

function* exportMRPDataByEmail() {
  try {
    const query: any = yield select(({ mrp }: RootStateStore) => mrp.mrpList.query);
    yield call(MrpService.exportDataByEmail, query);
    yield put(new ExportMRPListDataViaEmailSuccess("Your file is big and it will take time to export. You will receive an email providing a link to download it when it is ready."));
    yield delay(5000);
  } catch (error) {
    yield put(new ExportMRPListDataViaEmailError(error));
  } finally {
    yield put(new ResetMRPExport());
  }
}

function* exportTemplate() {
  try {
    const result = yield call(MrpService.exportTemplate);
    yield put(new ExportMRPTemplateSuccess(`MRP_Template.xlsx`, result));
  } catch (error) {
    yield put(new ExportMRPTemplateError(error));
  }
}

function* exportNbsMaintenanceTemplate() {
  try {
    const result = yield call(NbsService.exportTemplate);
    yield put(new ExportMRPTemplateSuccess(`NbsMaintenance_Template.xlsx`, result));
  } catch (error) {
    yield put(new ExportMRPTemplateError(error));
  }
}

function* markAsDeleted(action: MarkAsDeleted): any {
  try {
    yield all(action.mrpIds.map((id: number) => call(MrpService.markAsDeleted, id, action.comment)));
    yield put(new MarkAsDeletedSuccess("MRP(s) marked as deleted"));
    if (action.done) action.done();
  } catch (error) {
    yield put(new MarkAsDeletedError(error));
  }
}
function* approveMrp(action: ApproveMrpItem): any {
  try {
    yield all(action.mrpIds.map((id: number) => call(MrpService.approveMrp, id)));
    yield put(new ApproveMrpSuccess("MRP(s) are Approved"));
    if (action.done) action.done();
  } catch (error) {
    yield put(new ApproveMrpFailure(error));
  }
}
function* approveMrps(action: ApproveMrps): any {
  try {
    yield call(MrpService.approveMrps, action.mrpIds);
    yield put(new ApproveMrpSuccess("MRP(s) are Approved"));
    if (action.done) action.done();
  } catch (error) {
    yield put(new ApproveMrpFailure(error));
  }
}
function* checkForDuplicates(action: CheckMrpForDuplicates) {
  try {
    const result: { id: number, duplicate: boolean } = yield call(MrpService.checkIfDuplicate, action.mrp);
    action.done(result);
  } catch (error) {
    yield put(new CheckMrpForDuplicatesFailure(error));
  }
}
function* getMrpScraping(action: GetMrpScraping): any {
  try {
    yield call(MrpService.getMrpScraping);
    yield put(new GetMrpScrapingSuccess("Get MRP Scraping success"));
  } catch (error) {
    yield put(new GetMrpScrapingError(error));
  }
}
function* postMrpScraping(action: PostMrpScraping): any {
  try {
    yield call(MrpService.postMrpScraping);
    yield put(new PostMrpScrapingSuccess("Post MRP Scraping success"));
  } catch (error) {
    yield put(new PostMrpScrapingError(error));
  }
}
function* sendMrpAsContracts(action: SendMrpAsContracts): any {
  try {
    const query: any = yield select(({ mrp }: RootStateStore) => mrp.mrpList.query);
    const contractResults = yield call(MrpService.sendMrpAsContracts, query);
    const result = yield call(MrpService.fetch, query); // refresh main list, without "onNewQuery", to avoid triggering "RESET" action
    yield put(new FetchMRPRequestSuccess(result));
    const existingContracts = contractResults.filter(contract => contract["contractNumber"] !== null)
    const newContracts = contractResults.filter(contract => contract["contractNumber"] === null)
    if (existingContracts != null &&
      existingContracts.length > 0 &&
      existingContracts[0].sapResponseStatusCode != null &&
      existingContracts[0].sapResponseStatusCode != 200 &&
      existingContracts[0].sapResponseStatusCode != 201) {
      const message = JSON.parse(existingContracts[0].sapResponseContent);
      if (message != null && message != "") {
        let azureError = message.error?.message?.value ?? "Unknown error";
        yield put(new SendMrpAsContractsError(azureError));
      }
    }
    else {
      let message: string;
      if (contractResults.length === 0 || (existingContracts.length && !newContracts.length)) {
        message = "MRP(s) sent as Contracts successfully ok";
      } else if (!existingContracts.length && newContracts.length) {
        message = "Your request is submitted, please come back when you receive an email notification after the completion of the request";
      } else {
        message = "Contract(s) Successfully created for some entries & for the rest You will be notified via email when it will be created"
      }
      yield put(new SendMrpAsContractsSuccess(message));
    }
  } catch (error) {
    yield put(new SendMrpAsContractsError(error));
  }
}
function* watchMRPFetch() {
  yield takeLatest(MRPTypes.FETCH_MRP_ENTITY, initialFetch);
}
function* watchMRPSearch() {
  yield takeLatest(MRPTypes.SEARCH_MRP, searchMRP);
}
function* watchSaveMRP() {
  yield takeLatest(MRPTypes.SAVE_MRP_ENTITY, saveMRP)
}
function* watchUpdateMRP() {
  yield takeLatest(MRPTypes.UPDATE_MRP_ENTITY, updateMRP)
}
function* watchNewQuery() {
  yield takeLatest(QueryMRPActions.onNewQuery, fetch);
}
function* watchImport() {
  yield takeLatest(MRPTypes.IMPORT_MRP_ENTITY, importMRP)
}
function* watchExportListData() {
  yield takeLatest(MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA, exportMRPData)
}
function* watchExportListDataByEmail() {
  yield takeLatest(MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_EMAIL, exportMRPDataByEmail)
}
function* watchExportTemplate() {
  yield takeLatest(MRPTypes.EXPORT_MRP_ENTITY_TEMPLATE, exportTemplate)
}
function* watchNbsMaintenanceExportTemplate() {
  yield takeLatest(MRPTypes.EXPORT_NBS_MAINTENANCE_ENTITY_TEMPLATE, exportNbsMaintenanceTemplate)
}
function* watchFetchSingleMrp() {
  yield takeLatest(MRPTypes.FETCH_SINGLE_MRP_ENTITY, fetchSingleMrp)
}
function* watchFetchSingleMrpAsList() {
  yield takeLatest(MRPTypes.FETCH_SINGLE_MRP_ENTITY_AS_LIST, fetchSingleMrpAsList)
}
function* watchMarkAsDeleted() {
  yield takeLatest(MRPTypes.MARK_AS_DELETED, markAsDeleted)
}
function* watchMrpApprove() {
  yield takeLatest(MRPTypes.APPROVE_MRP_ITEM, approveMrp)
}
function* watchMrpsApprove() {
  yield takeLatest(MRPTypes.APPROVE_MRP_ITEMS, approveMrps)
}
function* watchCheckFotDuplicate() {
  yield takeLatest(MRPTypes.CHECK_MRP_ENTITY_FOR_DUPLICATES, checkForDuplicates)
}
function* watchGetMrpScraping() {
  yield takeLatest(MRPTypes.GET_MRP_SCRAPING, getMrpScraping)
}
function* watchPostMrpScraping() {
  yield takeLatest(MRPTypes.POST_MRP_SCRAPING, postMrpScraping)
}
function* watchSendMrpAsContracts() {
  yield takeLatest(MRPTypes.SEND_MRP_AS_CONTRACTS, sendMrpAsContracts)
}
export default function* mrpSaga() {
  yield all([
    fork(watchMRPFetch),
    fork(watchMRPSearch),
    fork(watchSaveMRP),
    fork(watchUpdateMRP),
    fork(watchNewQuery),
    fork(watchImport),
    fork(watchExportTemplate),
    fork(watchNbsMaintenanceExportTemplate),
    fork(watchExportListData),
    fork(watchExportListDataByEmail),
    fork(watchFetchSingleMrp),
    fork(watchFetchSingleMrpAsList),
    fork(watchMarkAsDeleted),
    fork(watchMrpApprove),
    fork(watchMrpsApprove),
    fork(watchCheckFotDuplicate),
    fork(watchGetMrpScraping),
    fork(watchPostMrpScraping),
    fork(watchSendMrpAsContracts),
  ])
};
