export interface NetworkPRDTO {
    __metadata: { id: string; type: string; uri: string };
    Aribapr: string,
    ItemNo: string,
    Bukrs: string,
    Ekorg: string,
    ApprovalType: string,
    Frgsx: string,
    Frggr: string,
    Frgco: string,
    Frgkx: string,
    ApprovalInd: string,
    Ebeln: string,
    Banfn: string,
    Werks: string,
    Ekgrp: string,
    Comments: string,
    ApprovedBy: string,
    ApprovedDate: string,
    ApprovedTime: string,
    UpdatedInArb: string,
    Preis: string,
    Peinh: string,
    Waers: string,
    MailSent: string,
    MailTime: string,
    MailDate: string,
    ApprovalIndicator: string,
    SapPR: string,
    CompCode: string,
}


export enum SAPBoolean {
    TRUE = "X",
    FALSE = "",
}

export class ApprovalHistoryRequest {
    constructor(
        public readonly Aribapr: string,
        public readonly ItemNo: string,
        public readonly Bukrs: string,
        public readonly Ekorg: string,
        public readonly ApprovalType: string,
        public readonly Frgsx: string,
        public readonly Frggr: string,
        public readonly Frgco: string,
        public readonly Frgkx: string,
        public readonly ApprovalInd: string,
        public readonly Ebeln: string,
        public readonly Banfn: string,
        public readonly Werks: string,
        public readonly Ekgrp: string,
        public readonly Comments: string,
        public readonly ApprovedBy: string,
        public readonly ApprovedDate: string,
        public readonly ApprovedTime: string,
        public readonly UpdatedInArb: string,
        public readonly Preis: string,
        public readonly Peinh: string,
        public readonly Waers: string,
        public readonly MailSent: string,
        public readonly MailTime: string,
        public readonly MailDate: string,
        public readonly ApprovalIndicator: string,
        public readonly SapPR: string,
        public readonly CompCode: string,
    ) {}

    static FromBackend(data: NetworkPRDTO): ApprovalHistoryRequest {
        return new ApprovalHistoryRequest(
            data.Aribapr,
            data.ItemNo,
            data.Bukrs,
            data.Ekorg,
            data.ApprovalType,
            data.Frgsx,
            data.Frggr,
            data.Frgco,
            data.Frgkx,
            data.ApprovalInd,
            data.Ebeln,
            data.Banfn,
            data.Werks,
            data.Ekgrp,
            data.Comments,
            data.ApprovedBy,
            data.ApprovedDate,
            data.ApprovedTime,
            data.UpdatedInArb,
            data.Preis,
            data.Peinh,
            data.Waers,
            data.MailSent,
            data.MailTime,
            data.MailDate,
            data.ApprovalIndicator,
            data.SapPR,
            data.CompCode,
        )
    }
}
