import {is, prop, flatten, path, hasPath, either, isEmpty, isNil, mergeDeepRight} from "ramda";
import {stringify} from 'qs';
import {addBreadcrumb, Severity} from "@sentry/browser";
import axios, {AxiosRequestConfig} from "axios";
import {withAuthHeader} from "../domains/auth/authentication.service";
import {environment} from "../domains/core/environment";
import {Features} from "../../../app/shared/domains/core/pataflag";
import {PataflagService} from "../../../app/shared/services/pataflag.service";

export const MultiOriginValue = "X";

export enum Headers {
  SYSTEM_ALIAS = 'X-SystemAlias',
  MULTIORIGINS = 'X-MultiOrigin'
}

function printErr(err: any) {
  console.log(err);
  const data = path(['response', 'data']);

  addBreadcrumb({
    level: Severity.Error,
    data: {
      ...err,
      responseData: data(err)
    },
    category: 'http',
  });
}

function fromSAP(errorPath, error) {
  const errorDetails: { message: string }[] = path(errorPath, error);
  let errorMessage = "";
  if (!either(isNil, isEmpty)(errorDetails)) {
    errorMessage = errorDetails.map(x => x.message).filter(x => x !== 'Id').join("###");
  } else if (hasPath(['error', 'message', 'value'], error)) {
    errorMessage = path(['error', 'message', 'value'], error);
  }
  return Promise.reject(errorMessage || error);
}

function fromAzure(errorDetailsPath, error) {
  const errorFromSap = JSON.parse(error.responseContent);
  return fromSAP(errorDetailsPath, errorFromSap);
}

function onSuccess() {
  return (response: any) => {
    if (response.data.error) {
      return onRejected()({response: response})
    }
    return response;
  }
}

function onRejected() {
  return (e: any) => {
    printErr(e);
    const simplePath = ['innererror', 'errordetails'];
    if (hasPath(simplePath, e)) {
      return fromSAP(simplePath, e);
    } else if (e.response) {
      const error = e.response.data;

      const advancedPath = ['error', 'innererror', 'errordetails'];
      if (hasPath(advancedPath, error)) {
        return fromSAP(advancedPath, error);
      }

      if (is(Array, error)) {
        const sapErrors = flatten(error.map(x => {
          return x.sapExceptions ? flatten(x.sapExceptions.map(y => y.error?.innererror?.errordetails?.map(z => z.message))) : null
        }).filter(x=>!!x))
        const errors = (sapErrors && sapErrors.length>0) ? sapErrors.concat(error.map(x => x.message)) : error.map(x => x.message);
        return Promise.reject(errors.join('###'))
      }

      if (prop('isSuccess', error)) {
        return fromAzure(advancedPath, error);
      }
    }
    return Promise.reject(e)
  };
}

const AxiosDefaultRequestConfig = {Pragma: 'no-cache'};

export class AxiosWrapper {
  static async get<T = any>(path: string, options: AxiosRequestConfig = {}): Promise<T> {
    const config = {
      ...options,
      paramsSerializer: params => stringify(params),
      headers: await withAuthHeader(mergeDeepRight(options.headers, AxiosDefaultRequestConfig))
    };
    const url = environment.REACT_APP_API_BASE + path;
    return axios.get(url, config)
      .then(onSuccess())
      .catch(onRejected());
  }

  static async post<T, V = any>(path: string, body?: T, options: AxiosRequestConfig = {}): Promise<V> {
    const config = {
      ...options,
      headers: await withAuthHeader(mergeDeepRight(options.headers, AxiosDefaultRequestConfig))
    };
    return axios.post(environment.REACT_APP_API_BASE + path, body, config)
      .then(onSuccess())
      .catch(onRejected());
  }

  static async put<T, V = any>(path: string, body?: T, options: AxiosRequestConfig = {}): Promise<V> {
    const config = {
      ...options,
      headers: await withAuthHeader(mergeDeepRight(options.headers, AxiosDefaultRequestConfig))
    };
    return axios.put(environment.REACT_APP_API_BASE + path, body, config)
      .then(onSuccess())
      .catch(onRejected());
  }

  static async delete<T, V = any>(path: string, options: AxiosRequestConfig = {}): Promise<V> {
    const config = {
      ...options,
      headers: await withAuthHeader(mergeDeepRight(options.headers, AxiosDefaultRequestConfig))
    };
    return axios.delete(environment.REACT_APP_API_BASE + path, config)
      .then(onSuccess())
      .catch(onRejected());
  }

  static async getWithSystemAlias<T = any>(path: string, options: AxiosRequestConfig = {}, systemAlias: string): Promise<T> {
    const config = {
      ...options,
      paramsSerializer: params => stringify(params),
      headers: await withAuthHeader(mergeDeepRight(options.headers, {...AxiosDefaultRequestConfig, [Headers.SYSTEM_ALIAS]: systemAlias}))
    };
    const url = environment.REACT_APP_API_BASE + path;
    return axios.get(url, config)
      .then(onSuccess())
      .catch(onRejected());
  }
}
