import { assoc, flatten, filter, merge } from "ramda";
import { PRSubstitutionActionTypes } from "../actions/prSubstitution.action";
import { IAction } from "../../../shared/domains/core/actions";
import {
  prSubstitutionQuery,
  ODataQuery
} from "../domains/prSubstitution/query";
import { PRSubstitutionRequest } from "../domains/prSubstitution/prSubstitution";

export interface PRSubstitutionState {
  query: ODataQuery;
  prSubstitutionList: PRSubstitutionRequest[];
  loading: boolean;
  itemLoading: boolean;
  error: any;
  totalCount: number;
  selectedRow: PRSubstitutionRequest;
  purchaseRequestInfo: any;
  purchaseRequestAccount: any;
  purchaseRequestPricing: any;
  approvalItems: any;
  costObjectList: any;
  substituteeList: any;
  userData:any;
}

export const initialPRSubstitutionState: PRSubstitutionState = {
  query: prSubstitutionQuery(),
  prSubstitutionList: [],
  loading: true,
  itemLoading: true,
  error: null,
  totalCount: 0,
  selectedRow: null,
  purchaseRequestInfo: null,
  purchaseRequestAccount: [],
  purchaseRequestPricing: [],
  costObjectList: [],
  substituteeList: [],
  approvalItems: [],
  userData:{}
};

const loading = { loading: true, error: null };
const emptyAndLoading = {
  loading: true,
  error: null,
  prSubstitutionList: [],
  totalCount: 0
};

export function PRSubstitutionReducers(
  state: PRSubstitutionState = initialPRSubstitutionState,
  action: IAction<PRSubstitutionActionTypes>
): PRSubstitutionState {
  switch (action.type) {
	case PRSubstitutionActionTypes.SET_SUBSTITUTION_BEHALF_USER :
		console.log('actionaction',action)
		return {
			...state,
			loading: false,
			userData:action.userData
		  };
    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION:
      return {
        ...state,
        loading: true,
        prSubstitutionList: [],
        totalCount: 0
      };
    case PRSubstitutionActionTypes.FETCH_COST_OBJECT:
      return {
        ...state,
        loading: true,
        costObjectList: [],
        totalCount: 0
      };
    case PRSubstitutionActionTypes.FETCH_SUBSTITUTEE:
      return {
        ...state,
        //loading: true,
        substituteeList: [],
        totalCount: 0
      };
    case PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION:
      return {
        ...state,
        loading: true
      };

    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        prSubstitutionList: action.items,
        totalCount: action.totalCount ? action.totalCount : 0,
        query: action.newQuery
      };
    case PRSubstitutionActionTypes.FETCH_COST_OBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        costObjectList: action.items,
        totalCount: action.totalCount ? action.totalCount : 0,
        query: action.newQuery
      };
    case PRSubstitutionActionTypes.FETCH_SUBSTITUTEE_SUCCESS:
      return {
        ...state,
        //loading: false,
        substituteeList: action.items,
        totalCount: action.totalCount ? action.totalCount : 0,
        query: action.newQuery
      };
    case PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case PRSubstitutionActionTypes.NEW_AS_QUERY:
      assoc("query", action.query, state);
      const loaderState = { loading: true };
      return merge(state, loaderState);
    case PRSubstitutionActionTypes.SET_SELECTED_AS:
      const partialState = {
        selectedRow: action.purchaseRequest,
        loading: false
      };
      return merge(state, partialState);
    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_DETAIL:
      return {
        ...state,
        itemLoading: true
      };
    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_DETAIL_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        purchaseRequestInfo: action.info,
        purchaseRequestAccount: action.accountList,
        purchaseRequestPricing: action.pricing
      };
    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_LINES:
      return {
        ...state,
        itemLoading: true
        /*prSubstitutionList: state.prSubstitutionList.map(purchaseRequest => purchaseRequest.SapPR === action.SapPR ?

                    { ...purchaseRequest, innerLoading: true } :
                    purchaseRequest
                )*/
      };
    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_LINES_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        approvalItems: action.items
        /*prSubstitutionList: state.prSubstitutionList.map(purchaseRequest => purchaseRequest.Aribapr === action.Aribapr ?

                    { ...purchaseRequest, PRLines: action.items, innerLoading: false } :
                    purchaseRequest
                )*/
      };

    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_LINES_FAILURE:
      return {
        ...state,
        /*prSubstitutionList: state.prSubstitutionList.map(purchaseRequest => purchaseRequest.Aribapr === action.error.Aribapr ?

                    { ...purchaseRequest, innerLoading: false } :
                    purchaseRequest
                ),*/
        error: action.error,
        itemLoading: false
      };

    case PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_FAILURE:
      const failurePartialState = { error: action.error, loading: false };
      return merge(state, failurePartialState);
    case PRSubstitutionActionTypes.FETCH_COST_OBJECT_FAILURE:
      const failureState = { error: action.error, loading: false };
      return merge(state, failureState);
    case PRSubstitutionActionTypes.FETCH_SUBSTITUTEE_FAILURE:
      const failurePartial = {
        error: action.error
        //loading: false
      };
      return merge(state, failurePartial);
    case PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION_FAILURE:
      const addFailure = { error: action.error, loading: false };
	  return merge(state, addFailure);
	default:
      return state;
  }
}
