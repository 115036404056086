interface NetworkcontactNumberDTO {
    __metadata: { id: string; type: string; uri: string };
    ContractNo: string
    PurchaseReq: string
}

export class ExtContactNumber {
    constructor(
        public ContractNo: string,
        public PurchaseReq: string
    ) {
    }

    static FromBackend(data: NetworkcontactNumberDTO): ExtContactNumber {
        return new ExtContactNumber(
            data.ContractNo,
            data.PurchaseReq,
        )
    }

    static toSelect(data: ExtContactNumber) {
        return {
            value: data.ContractNo,
            label: data.ContractNo
        }
    }
}
