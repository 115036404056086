interface NetworkPurchaseOrgDTO {
  PurchaseOrg: string;
  PurchaseOrgName: string;
}

export class PurchaseOrg {
  constructor(
    public PurchaseOrg: string,
    public PurchaseOrgName: string
  ) {
  }

  static FromBackend(data: NetworkPurchaseOrgDTO): PurchaseOrg {
    return new PurchaseOrg(
      data.PurchaseOrg,
      data.PurchaseOrgName
    )
  }

  toSelect() {
    return {
      value: this.PurchaseOrg,
      label: this.PurchaseOrgName
    }
  }
}
