import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {MTableCell} from "material-table";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {HorizontalView, MainTitle} from "../../shared/components/layout/styles";

export const useCbStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailContainer: {
      display: 'flex',
      width: '100%'
    },
    titleItem: {
      height: '60px',
      minWidth: '350px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '25px',
      color: 'rgba(0, 0, 0, 0.6)',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    cbItem: {
      height: '60px',
      width: '100%',
      minWidth: '350px',
      justifyItems: 'center',
      display: 'flex',
      borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    cbItemSummary: {
      color: 'rgba(0, 0, 0, 0.6)',
      alignItems: 'center',
      justifyContent: 'center'
    },
    cbContainer: {
      display: 'flex',
      width: '100%'
    },
    mainContainer: {
      marginTop: '50px',
      overflowX: 'auto',
      overflowY: 'hidden',
      backgroundColor: 'white'
    },
    root: {
      flexGrow: 1,
      boxShadow: '0 0 0 1px rgba(224, 224, 224, 1)',
      backgroundColor: 'white'
    },
    addBtnContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingBottom: '10px'
    },
    itemsContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '25%',
      flexDirection: 'column',
      justifyContent: 'center',
      color: '#4A4A4A'
    },
    compliantItemsContainer: {
      '& div': {
        width: '100%'
      }
    },
    compliantItemSeparator: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 0'
    },
    compliantItem: {
      padding: '5px 0'
    },
    removeBtn: {
      color: theme.palette.primary.main,
      position: 'absolute',
      right: 40
    },
    removeCatBtn: {
      color: theme.palette.primary.main,
      height: 46,
      width: 46,
      alignSelf: 'flex-end'
    },
    pslStatus: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "-5px 10px 10px",
    },
    title: {
      fontSize: 22,
      fontWeight: 600,
      color: '#4A4A4A'
    },
    label: {
      display: "flex",
      alignItems: "center",
      color: 'rgba(0, 0, 0, 0.6)',
      overflowWrap: 'break-word',
      wordBreak: 'break-all',
      msWordBreak: 'break-all',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    file: {
      opacity: 1
    },
    strongLabel: {
      fontWeight: 600
    },
    normal: {
      minHeight: '48px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 0,
      '&::after': {
        content: '""',
        display: 'block',
        minHeight: 'inherit'
      }
    },
    medium: {
      minHeight: '68px',
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 0,
      display: 'flex',
      alignItems: 'center',
      '&::after': {
        content: '""',
        display: 'block',
        minHeight: 'inherit'
      }
    },
    mediumWrap: {
      '& div': {
        flexWrap: 'wrap'
      }
    },
    large: {
      height: '90px',
      lineHeight: '90px',
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 0
    },
    inputLabel: {
      height: '40px',
      lineHeight: '40px',
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    typehead: {
      fontSize: 14,
      lineHeight: '20px',
      width: '100%'
    },
    fullHeight: {
      height: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    noPadding: {
      paddingLeft: 0,
      paddingRight: 0
    },
    uploadButton: {
      alignSelf: 'center',
      backgroundColor: '#F19001!important',
      color: '#fff!important',
      padding: '0!important',
      minWidth: '30px!important',
      height: '30px'
    },
    removeButton: {
      alignSelf: 'center',
      backgroundColor: '#F19001!important',
      color: '#fff!important',
      padding: '0!important',
      marginLeft: 5,
      minWidth: '30px!important',
      height: '30px'
    },
    uploadInput: {
      display: 'none'
    },
    uploadLabel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row'
    },
    error: {
      border: '1px solid red',
      borderRadius: 4
    }
  }),
);

export const StyledMainTitle = styled(MainTitle)`
  align-self: center;
  span {
    color: #2689EB;
  }
`;

export const Main = styled.div`
  padding: 30px;
`;

export const Sub = styled.div`
  margin-bottom: 30px;
`;

export const Footer = styled.div`
  margin: 45px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DefaultFooter = styled.div`
  margin: 45px 0;
  display: flex;
  justify-content: center;
`;

export const StyledButtons = styled(HorizontalView)`
  justify-content: center;
  button {
    margin: 0 10px;
    border-radius: 4px;
    box-shadow: none;
    color: #F19001;
    font-size: 18px;
    font-weight: 600;
    padding: 8px;
    width: 120px;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  width: 100%;
`;

export const FileName = styled.span`
  color: #2689EB;
  cursor: pointer;
  flex-grow: 1;
  height: 38px;
  line-height: 38px;
  padding: 0 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UploadButton = styled(Button)`
  align-self: center;
  background-color: #F19001!important; 
  color: #fff!important;
  padding: 0!important;
  min-width: 30px!important
  height: 30px;
`;

export const SaveButton = styled(Button)`
  background-color: #F19001; 
  color: #fff!important;
`;

export const CancelButton = styled(Button)`
  border: 1px solid #F19001!important;
  background-color: transparent!important;
`;

const commonButton = styled(Button)`
  background-color: #F19001!important;
  box-shadow: none;
  color: #fff!important;
  font-size: 18px!important;
  font-weight: 600!important;
  padding: 8px!important;
`;

export const AddRndButton = styled(commonButton)`
  border-radius: 50% !important;
  min-height: 45px !important;
  min-width: 45px !important;
`;

export const StyledCell = styled(MTableCell)`
  && {
    padding: 10px 5px;
  } 
`;

export const Error = styled.div`
  color: #f44336;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  width: 400px;
  ul {
    list-style: none;
  }
`;
