import * as R from "ramda";
import {DateTime} from "luxon";
import {FilterCategoryView, newTextFilterCategory} from "../../shared/domains/query/filter";
import {IContractCalendarFilters} from "../../shared/domains/user/user";
import {Dictionary} from "ramda";
import {setSelectedFiltersBykey} from "../../shared/queryable/query";

export const startDateByWeek = DateTime.local().startOf("week").minus({days:1});

const initialFilters = [
  {key: "From", label: "From", selected: { value: startDateByWeek.toFormat('yyyyMMdd')} },
  {key: "To", label: "To", selected: { value: startDateByWeek.plus({days: 6}).toFormat('yyyyMMdd') }}
];

export const CalendarFilters = [
  {key: 'BuyerEmail', label: 'Buyer', source: 'buyers'},
  {key: 'SpendCategoryLevel1s', label: 'Spend Categories', source: 'spendCategories'},
  {key: 'Clusters', label: 'Clusters', source: 'clusters'},
  {key: 'Zones', label: 'Zones', source: 'zones'},
  {key: 'Markets', label: 'Markets', source: 'markets'},
  {key: 'Companies', label: 'Company Codes', source: 'companyCodes'},
  {key: 'Plants', label: 'Plants', source: 'plants'},
  {key: 'BusinessCode', label: 'Businesses', source: 'businesses'},
  {key: 'NegotiationTypeId', label: 'Negotiation Types', source: 'negotiationType'},
];

const initial = CalendarFilters.map(x => newTextFilterCategory(x.key, x.label));
const initialDictionary = R.indexBy(R.prop('key'), initial);

export function newFilters(filters: IContractCalendarFilters): Dictionary<FilterCategoryView> {
  let initialFilter: Dictionary<FilterCategoryView> = initialDictionary;
  if (filters) {
    initialFilter = setSelectedFiltersBykey('BuyerEmail', filters.buyers, initialFilter);
    initialFilter = setSelectedFiltersBykey('SpendCategoryLevel1s', filters.spendCategories, initialFilter);
    initialFilter = setSelectedFiltersBykey('Clusters', filters.clusters, initialFilter);
    initialFilter = setSelectedFiltersBykey('Zones', filters.zones, initialFilter);
    initialFilter = setSelectedFiltersBykey('Markets', filters.markets, initialFilter);
    initialFilter = setSelectedFiltersBykey('Companies', filters.companyCodes, initialFilter);
    initialFilter = setSelectedFiltersBykey('Plants', filters.plants, initialFilter);
    initialFilter = setSelectedFiltersBykey('BusinessCode', filters.businesses, initialFilter);
    initialFilter = setSelectedFiltersBykey('NegotiationTypeId', filters.negotiationType, initialFilter);
  }
  return initialFilter;
}

export const defaultCalendarFilters: any = R.indexBy(R.prop('key'), initialFilters);
