export enum SAPBoolean {
  TRUE = "X",
  FALSE = "",
}

export function SAPBooleanToBoolean(sap: SAPBoolean): boolean {
  switch (sap) {
    case SAPBoolean.TRUE:
      return true;
    case SAPBoolean.FALSE:
    default:
      return false;
  }
}
