import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";

export const SnackbarWrapper = styled.div`
  &.success {
      .MuiSnackbarContent-root {
        background-color: #43a047 !important;
      }
    }
  &.error {
    .MuiSnackbarContent-root {
      background-color: #d32f2f !important;
    }
  }
`;

export const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-message {
    color: #fff;
    font-size: 16px;
  }
  .MuiSnackbarContent-message > .message {
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .MuiSnackbarContent-message > .message > span {
    margin-right: 10px;
  }
  .MuiSnackbarContent-action {
    color: #fff;
  }
`;
