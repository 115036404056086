import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import {
  FetchCompliantItems,
  FetchCompliantItemsFailure,
  FetchCompliantItemsSuccess,
  FetchGRInvItems,
  FetchGRInvItemsFailure,
  FetchGRInvItemsSuccess,
  FetchGRItems,
  FetchGRItemsFailure,
  FetchGRItemsSuccess,
  FetchIRInvItems,
  FetchIRInvItemsFailure,
  FetchIRInvItemsSuccess,
  FetchIRItems,
  FetchIRItemsFailure,
  FetchIRItemsSuccess,
  FetchPOItems,
  FetchPOItemsFailure,
  FetchPOItemsSuccess,
  FetchPRItems,
  FetchPRItemsFailure,
  FetchPRItemsSuccess,
  PRPOItemsTypes,
  UpdatePRPOItemError,
  UpdatePRPOItemSuccess,
} from "../actions/items.actions";
import {CompliantItemPRPO, PRPOItemService} from "../domains/prpo/item.service";
import {DocumentFlowService} from "../domains/documentFlow/documentFlow.service";
import {UserInteractionType} from "../../shared/domains/user/userInteractionType";

export function* fetchPRItems(action: any): any {
  try {
    const result = yield call(PRPOItemService.fetchPRItems, {purchaseReqNo: action.purchaseReqNo, systemAlias: action.systemAlias});
    yield put(new FetchPRItemsSuccess(result.data, result.count));
  } catch (error) {
    yield put(new FetchPRItemsFailure(error))
  }
}

export function* fetchPOItems(action: any): any {
  try {
    const result = yield call(PRPOItemService.fetchPOItems, {purchaseOrdNo: action.purchaseOrdNo, systemAlias: action.systemAlias});
    yield put(new FetchPOItemsSuccess(result.data, result.count));
  } catch (error) {
    yield put(new FetchPOItemsFailure(error))
  }
}


export function* fetchGRItems(action: FetchGRItems): any {
  try {
    const result = yield call(PRPOItemService.fetchGRItems, {
      prNo: action.prNo,
      poNo: action.poNo,
      systemAlias: action.systemAlias,
    });
    DocumentFlowService.logUserInteractionData(UserInteractionType.CheckedRequestCockpitReceipts);
    yield put(new FetchGRItemsSuccess(result.data, result.count, action.poNo, action.prNo, action.systemAlias));
  } catch (error) {
    yield put(new FetchGRItemsFailure(action.poNo, action.prNo, action.systemAlias))
  }
}

export function* fetchGRInvItems(action: FetchGRInvItems): any {
  try {
    const result = yield call(PRPOItemService.fetchGRInvItems, {
      invDocNo: action.InvDocNo,
      fiscalYear: action.FiscalYr,
      systemAlias: action.systemAlias,
    });
    DocumentFlowService.logUserInteractionData(UserInteractionType.CheckedRequestCockpitReceipts);
    yield put(new FetchGRInvItemsSuccess(result.data, result.count, action.InvDocNo, action.FiscalYr, action.systemAlias));
  } catch (error) {
    yield put(new FetchGRInvItemsFailure(action.InvDocNo, action.FiscalYr, action.systemAlias))
  }
}

export function* fetchIRItems(action: FetchIRItems): any {
  try {
    const result = yield call(PRPOItemService.fetchIRItems, {
      poNo: action.poNo,
      prNo: action.prNo,
      systemAlias: action.systemAlias
    });
    DocumentFlowService.logUserInteractionData(UserInteractionType.CheckedRequestCockpitInvoices);
    yield put(new FetchIRItemsSuccess(result.data, result.count, action.poNo, action.prNo, action.systemAlias));
  } catch (error) {
    yield put(new FetchIRItemsFailure(action.poNo, action.prNo, action.systemAlias))
  }
}

export function* fetchIRInvItems(action: FetchIRInvItems): any {
  try {
    const result = yield call(PRPOItemService.fetchIRInvItems, {
      invDocNo: action.InvDocNo,
      systemAlias: action.systemAlias
    });
    DocumentFlowService.logUserInteractionData(UserInteractionType.CheckedRequestCockpitInvoices);
    yield put(new FetchIRInvItemsSuccess(result.data, result.count, action.InvDocNo, action.systemAlias));
  } catch (error) {
    yield put(new FetchIRInvItemsFailure(action.InvDocNo, action.systemAlias))
  }
}

export function* updatePRItems(action: any): any {
  const {executor} = action;
  try{
    yield call(PRPOItemService.updatePRItem, action.item, action.systemAlias);
    yield put(new UpdatePRPOItemSuccess(executor));
    yield put(new FetchPRItems(action.item.PurchaseReqNo, action.systemAlias));
  } catch (error) {
    yield put(new UpdatePRPOItemError(executor, {error, message: "Error updating item"}));
  }
}

export function* updatePOItems(action: any): any {
  const {executor} = action;
  try{
    yield call(PRPOItemService.updatePOItem, action.item, action.systemAlias);
    yield put(new UpdatePRPOItemSuccess(executor));
    yield put(new FetchPOItems(action.item.PurchaseOrdNo, action.systemAlias));
  } catch (error) {
    yield put(new UpdatePRPOItemError(executor, {error, message: "Error updating item"}));
  }
}

export function* fetchCompliantItems(action: FetchCompliantItems): any {
  try {
    const result: CompliantItemPRPO = yield call(PRPOItemService.fetchCompliantItemsFromDf, action.df);
    yield put(new FetchCompliantItemsSuccess(result));
  } catch (error) {
    yield put(new FetchCompliantItemsFailure(error))
  }
}

function* watchFetchPRItems() {
  yield takeLatest(PRPOItemsTypes.FETCH_PR_ITEMS, fetchPRItems);
}

function* watchFetchPOItems() {
  yield takeLatest(PRPOItemsTypes.FETCH_PO_ITEMS, fetchPOItems);
}

function* watchFetchGRItems() {
  yield takeLatest(PRPOItemsTypes.FETCH_GR_ITEMS, fetchGRItems);
}

function* watchFetchGRInvItems() {
  yield takeLatest(PRPOItemsTypes.FETCH_GR_ITEMS_INV, fetchGRInvItems);
}

function* watchFetchIRItems() {
  yield takeLatest(PRPOItemsTypes.FETCH_IR_ITEMS, fetchIRItems);
}

function* watchFetchIRInvItems() {
  yield takeLatest(PRPOItemsTypes.FETCH_IR_ITEMS_INV, fetchIRInvItems);
}

function* watchUpdatePRItems() {
  yield takeLatest(PRPOItemsTypes.UPDATE_PR_ITEM, updatePRItems);
}

function* watchUpdatePOItems() {
  yield takeLatest(PRPOItemsTypes.UPDATE_PO_ITEM, updatePOItems);
}

function* watchFetchCompliantItems() {
  yield takeLatest(PRPOItemsTypes.FETCH_COMPLIANT_ITEMS, fetchCompliantItems);
}

export default function* PRItemsSaga() {
  yield all([
    fork(watchFetchPRItems),
    fork(watchFetchPOItems),
    fork(watchFetchGRItems),
    fork(watchFetchGRInvItems),
    fork(watchFetchIRItems),
    fork(watchFetchIRInvItems),
    fork(watchUpdatePRItems),
    fork(watchUpdatePOItems),
    fork(watchFetchCompliantItems)
  ])
}
