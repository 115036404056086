import "antd/dist/antd.css";
import { TreeSelect } from "antd";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import { convertToScopeTitles } from "src/app/preferredSuppliers/helpers/functions";

const useStyles = makeStyles(() =>
    createStyles({
        treeSelectClass: {
            "&& div": {
                height: "100%"
            },
            "&& .ant-select-selector": {
                color: "rgba(0, 0, 0, 0.65)"
            }
        }
    })
);
const atMarketChilds = [
    {
        "title": "At Market-Child",
        "value": "At Market-Child",
    },
    {
        "title": "At Market-Local",
        "value": "At Market-Local",
    }
];
const aboveMarketTreeData = [
    {
        "title": "Above Market",
        "value": "Above Market",
    },
    {
        "title": "At Market",
        "value": "At Market",
        "children": atMarketChilds
    }
];
const atMarketTreeData = [
    {
        "title": "At Market",
        "value": "At Market",
        "children": atMarketChilds
    }
];
export const PslScopeFilterComponent = ({ onChange, initialValue, user }) => {
    const classes = useStyles({});
    const isAtMarket = user.hasAtMarketRole() || user.isRequestor();

    const [selectedpslScope, setSelectedpslScope] = React.useState(initialValue ? initialValue : []);

    const onChangeSuperDiversity = (
        value: any[]
    ) => {
        setSelectedpslScope(value);
        onChange(value);
    };

    const userScopeProps = {
        treeData: isAtMarket ? atMarketTreeData : aboveMarketTreeData,
        value: selectedpslScope,
        onChange: onChangeSuperDiversity,
        disabled: false,
        treeCheckable: true,
        treeCheckStrictly: false,
        dropdownMatchSelectWidth: false,
        treeNodeFilterProp: "title",
        showCheckedStrategy: TreeSelect.SHOW_PARENT,
        placeholder: "Click here to select Scope",
        style: { width: "100%", minHeight: "38px" }
    };

    return (
        <TreeSelect
            {...userScopeProps}
            dropdownStyle={{ zIndex: 99999 }}
            className={classes.treeSelectClass}
        />
    );
}

export const NewPslScopeFilterComponent = ({ onChange, initialValue, data }) => {
    const classes = useStyles({});
    // const isAtMarket = data.hasAtMarketRole() || data.isRequestor();
    
    const newData = convertToScopeTitles(data)

    const [selectedpslScope, setSelectedpslScope] = React.useState(initialValue ? initialValue : []);

    const onChangeSuperDiversity = (
        value: any[]
    ) => {
        setSelectedpslScope(value);
        onChange(value);
    };

    const userScopeProps = {
        treeData: newData,
        value: selectedpslScope,
        onChange: onChangeSuperDiversity,
        disabled: false,
        treeCheckable: true,
        treeCheckStrictly: false,
        dropdownMatchSelectWidth: false,
        treeNodeFilterProp: "title",
        showCheckedStrategy: TreeSelect.SHOW_PARENT,
        placeholder: "Click here to select Scope",
        style: { width: "100%", minHeight: "38px" }
    };

    return (
        <TreeSelect
            {...userScopeProps}
            dropdownStyle={{ zIndex: 99999 }}
            className={classes.treeSelectClass}
        />
    );
}
