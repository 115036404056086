import { all, call, fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    GetChatMessagesSuccess,
    GetChatGroupsSuccess
} from "../actions/docflowChat.actions";
import { DocflowChatService } from "../domains/docflowChat/docflowChat.service";
import { RoomChatService } from "../../shared/domains/chat/roomChat.service";
import { ChatActions, makeChatId } from "../reducers/docflowChat.reducer";
import { DocFlowsStateStore } from "../reducers";
import { PataflagService } from 'src/app/shared/services/pataflag.service';
import { Features } from 'src/app/shared/domains/core/pataflag';

function* startRoom(action): any {
    try {
        const room = RoomChatService.newInstance(
            'docflowChat',
            makeChatId(action),
            'ReceiveDocFlowChatMessage'
        );
        yield call(room.join.bind(room));
        yield put(new ChatActions.startRoomSuccess(room));
    } catch (error) {
        yield put(new ChatActions.startRoomFailure(error));
    }
}

function* fetchChatMessages(action): any {
    try {
        yield put({ type: ChatActions.startRoom, prNo: action.prNo, poNo: action.poNo });
        const result = yield call(DocflowChatService.fetchChatMessages, action.prNo, action.poNo);
        const result1 = yield call(DocflowChatService.fetchNewChatMessages, action.prNo, action.poNo);
        console.log(result1);
        yield put({ type: ChatActions.onLoadMessagesSuccess, prNo: action.prNo, poNo: action.poNo, messages: [...result1, ...result] });
    } catch (error) {
        yield put(new ChatActions.onLoadMessagesFailure(error));
    }
}

function* fetchChatMessagesSuccess(action) {
    yield put(new GetChatMessagesSuccess(action.prNo, action.poNo, action.messages));
}

function* fetchChatGroups(action): any {
    try {
        const result = yield call(DocflowChatService.fetchChatGroups, action.prNo, action.poNo);
        yield put({ type: ChatActions.onLoadGroupsSuccess, prNo: action.prNo, poNo: action.poNo, chatGroups: result });
    } catch (error) {
        yield put(new ChatActions.onLoadGroupsFailure(error));
    }
}

function* fetchChatGroupsSuccess(action) {
    yield put(new GetChatGroupsSuccess(action.prNo, action.poNo, action.chatGroups));
}

function* sendMessage(action): any {
    try {
        const result = yield call(DocflowChatService.newSendMessage, action);
        yield put(new ChatActions.onSendMessageSuccess(result));
        const room = yield select(({ docFlows }: { docFlows: DocFlowsStateStore }) => {
            docFlows.chat[makeChatId(action)].room
        });
        if (room) {
            yield call(room.send.bind(room, result.id));
        }
    } catch (error) {
        yield put(new ChatActions.onSendMessageFailure(error));
    }
}

function* newSendMessage(action): any {
    try {
        const result = yield call(DocflowChatService.newLatestSendMessage, action.payload);
        yield put(new ChatActions.onSendMessageSuccess(result));
        const room = yield select(({ docFlows }: { docFlows: DocFlowsStateStore }) => {
            docFlows.chat[makeChatId(action)].room
        });
        if (room) {
            yield call(room.send.bind(room, result.id));
        }
    } catch (error) {
        yield put(new ChatActions.onSendMessageFailure(error));
    }
}

function* watchGetMessages() {
    yield takeLatest(ChatActions.onLoadMessages, fetchChatMessages);
}

function* watchGetGroups() {
    yield takeLatest(ChatActions.onLoadGroups, fetchChatGroups);
}

function* watchSendMessage() {
    yield takeEvery(ChatActions.onSendMessage, sendMessage);
}
function* watchNewSendMessage() {
    yield takeEvery(ChatActions.onNewSendMessage, newSendMessage);
}

function* watchStartRoom() {
    yield takeLatest(ChatActions.startRoom, startRoom);
}

function* watchGetMessagesSuccess() {
    yield (ChatActions.onLoadMessagesSuccess, fetchChatMessagesSuccess);
}

function* watchGetGroupsSuccess() {
    yield (ChatActions.onLoadGroupsSuccess, fetchChatGroupsSuccess);
}

export default function* docflowChatSaga() {
    yield all([
        fork(watchGetMessages),
        fork(watchGetGroups),
        fork(watchSendMessage),
        fork(watchNewSendMessage),
        fork(watchStartRoom),
        fork(watchGetMessagesSuccess),
        fork(watchGetGroupsSuccess),
    ])
}
