import * as React from "react";
import { createStyles, IconButton, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { useStylesForToolTip } from "../tooltip/toolTip.styles";
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const chipColor: string = "#F19001";
const chipBgColor: string = "#fff";

type filterChipsProps = {
    source: { id: string, title: string }[],
    minimumItems: number,
    onDelete: Function
    enableDeletion: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterChips: {
            border: `1px solid ${chipColor}`,
            padding: "0 10px",
            display: 'flex',
            borderRadius: "25px",
            color: chipColor,
            fontSize: "0.8125rem",
            height: "32px",
            alignItems: "center",
            "&:hover": {
                backgroundColor: chipColor,
                color: chipBgColor,
                cursor: "pointer",
                "& p": {
                    backgroundColor: chipBgColor,
                    color: chipColor,
                }
            }
        },
        pills: {
            backgroundColor: chipColor,
            color: chipBgColor,
            padding: "2px 5px 0px 5px",
            borderRadius: "25px",
            height: "25px",
            margin: 0,
            marginLeft: "5px",
            display: 'flex',
            alignItems: "center",
            // "&:hover": {
            //     cursor: "pointer"
            // }
        },
        tooltip: {
            margin: 0,
            padding: 0,
            color: chipColor,
            fontFamily: "Roboto, sans-serif !important",
            fontWeight: 400,
            lineHeight: "1.43",
            maxWidth: "max-content",
        },
        filterItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: chipColor,
            height: "35px",
            "&:hover": {
                backgroundColor: "#F2F4F8",
            }
        },
        filterTitle: { margin: "0 10px", fontSize: "0.8125rem", color: chipColor, },
        filterHover: { display: "flex", flexDirection: "column" }
    }),
);

const FilterChipHover = ({ source, minimumItems, onDelete, enableDeletion }) => {
    const classes = useStyles({});
    return <div className={classes.filterHover}>
        {
            source.map((filter, i) => <div key={filter.id} className={classes.filterItem} >
                <div className={classes.filterTitle}>
                    {filter?.title ? filter?.title : filter?.id}
                </div>
                {(((source.length > minimumItems) || enableDeletion)) && <IconButton aria-label="delete filter" onClick={() => onDelete(filter)}>
                    <CloseIcon style={{ color: chipColor, fontSize: ".8rem" }} />
                </IconButton>}
            </div>)
        }
    </div>
}

export function FilterChipsComponent(props: filterChipsProps) { //expects source to be an array of {id:"", title: ""}

    const classes = useStyles(props);
    const classesForToolTip = useStylesForToolTip({});
    const { source, minimumItems, onDelete, enableDeletion } = props;
    const isItMoreItem: boolean = source.length > 1;
    const descp: string = isItMoreItem ? (source[0]?.title ? source[0]?.title : source[0]?.id) + ` | ` : (source[0]?.title ? source[0]?.title : source[0]?.id);
    const countPill: JSX.Element = isItMoreItem ? <p className={classes.pills}>{(source?.length < 10 ? "+0" : "+") + `${source?.length - 1}`}</p> : null;

    return (
        <Tooltip
            classes={{ tooltip: clsx(classesForToolTip.customTooltip, classes.tooltip) }}
            interactive
            title={<FilterChipHover source={source} minimumItems={minimumItems} onDelete={onDelete} enableDeletion={enableDeletion} />}
        >
            <div className={classes.filterChips}>
                {descp}
                { }
                {countPill}
            </div>
        </Tooltip>
    )
}