import { template } from "lodash";
import * as R from 'ramda';
import { sapUrl } from "src/app/shared/utils/urlBuilders";

export interface NetworkIRItemDTO {
    __metadata: { id: string; type: string; uri: string };
    Amount: string;
    BlckgRsnDate: string;
    BlckgRsnEnh: string;
    BlckgRsnItmAmt: string;
    BlckgRsnManual: string;
    BlckgRsnOpq: string;
    BlckgRsnPrice: string;
    BlckgRsnPrjBdgt: string;
    BlckgRsnQlty: string;
    BlckgRsnQty: string;
    ClrDate: string;
    ClrDoc: string;
    Currency: string;
    DebitCreditInd: string;
    FiscalYear: string;
    HasGos: string;
    InvDate: string;
    InvDocItemNo: string;
    InvDocNo: string;
    InvDueDate: string;
    InvQty: string;
    InvQtyUom: string;
    InvStatus: string;
    InvStatusTxt: string;
    MiroUrl: string;
    MyGrLevel: string;
    MyGrUrl: string;
    Paydate: string;
    PendingFor: string;
    PmntBlck: string;
    PmntBlckTxt: string;
    PoCategory: string;
    PoItemNo: string;
    PoNo: string;
    PostingDate: string;
    SuppInvRef: string;
    SystemAlias: string;
    TransactionType: string;
    CategoryText: string;
}
const ViewIRTemplate = template(
    "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2fevery_user!2fgeneral!2fuwl!2fcom.sap.netweaver.bc.uwl.uwlSapLaunch?System=<%=system%>&TCode=MIR4&DynamicParameter=RBKP-BELNR=<%=invoice%>&RBKP-GJAHR=<%=year%>&AutoStart=true"
);
export class IRItem {
    constructor(
        public readonly Amount: string,
        public readonly BlckgRsnDate: string,
        public readonly BlckgRsnEnh: string,
        public readonly BlckgRsnItmAmt: string,
        public readonly BlckgRsnManual: string,
        public readonly BlckgRsnOpq: string,
        public readonly BlckgRsnPrice: string,
        public readonly BlckgRsnPrjBdgt: string,
        public readonly BlckgRsnQlty: string,
        public readonly BlckgRsnQty: string,
        public readonly ClrDate: string,
        public readonly ClrDoc: string,
        public readonly Currency: string,
        public readonly DebitCreditInd: string,
        public readonly FiscalYear: string,
        public readonly HasGos: string,
        public readonly InvDate: string,
        public readonly InvDocItemNo: string,
        public readonly InvDocNo: string,
        public readonly InvDueDate: string,
        public readonly InvQty: string,
        public readonly InvQtyUom: string,
        public readonly InvStatus: string,
        public readonly InvStatusTxt: string,
        public readonly MiroUrl: string,
        public readonly MyGrLevel: string,
        public readonly MyGrUrl: string,
        public readonly Paydate: string,
        public readonly PendingFor: string,
        public readonly PmntBlck: string,
        public readonly PmntBlckTxt: string,
        public readonly PoCategory: string,
        public readonly PoItemNo: string,
        public readonly PoNo: string,
        public readonly PostingDate: string,
        public readonly SuppInvRef: string,
        public readonly SystemAlias: string,
        public readonly TransactionType: string,
        public readonly CategoryText: string,
        public ViewIRLink: string,

    ) {
        this.Amount = (Amount || "").trim();
    }

    static FromBackend(data: NetworkIRItemDTO): IRItem {
        const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());
        const sapPrefixed = R.mergeRight({prefix: sapPrefix, system: data.SystemAlias});
    
        const ViewIRLink = ViewIRTemplate(sapPrefixed({invoice: data.InvDocNo, year : data.FiscalYear}));
        return new IRItem(
            data.Amount,
            data.BlckgRsnDate,
            data.BlckgRsnEnh,
            data.BlckgRsnItmAmt,
            data.BlckgRsnManual,
            data.BlckgRsnOpq,
            data.BlckgRsnPrice,
            data.BlckgRsnPrjBdgt,
            data.BlckgRsnQlty,
            data.BlckgRsnQty,
            data.ClrDate,
            data.ClrDoc,
            data.Currency,
            data.DebitCreditInd,
            data.FiscalYear,
            data.HasGos,
            data.InvDate,
            data.InvDocItemNo,
            data.InvDocNo,
            data.InvDueDate,
            data.InvQty,
            data.InvQtyUom,
            data.InvStatus,
            data.InvStatusTxt,
            data.MiroUrl,
            data.MyGrLevel,
            data.MyGrUrl,
            data.Paydate,
            data.PendingFor,
            data.PmntBlck,
            data.PmntBlckTxt,
            data.PoCategory,
            data.PoItemNo,
            data.PoNo,
            data.PostingDate,
            data.SuppInvRef,
            data.SystemAlias,
            data.TransactionType,
            data.CategoryText,
            ViewIRLink
        )
    }
}
