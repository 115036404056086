interface CostCenterDTO {
  __metadata: { id: string; type: string; uri: string };
  CompCode: string;
  CostCenterNo: string;
  Description: string;
  Plant: string;
}

export class CostCenter {
  constructor(
    public CompCode: string,
    public CostCenterNo: string,
    public Description: string,
    public Plant: string
  ) {
  }

  static FromBackend(data: CostCenterDTO): CostCenter {
    return new CostCenter(
      data.CompCode,
      data.CostCenterNo,
      data.Description,
      data.Plant
    )
  }

  static toSelect(data: CostCenter) {
    return {
      value: data.CostCenterNo,
      label: data.Description
    }
  }

  static toFilter(data: CostCenter) {
    return {
      id: data.CostCenterNo,
      title: data.Description
    }
  }
}
