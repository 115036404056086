import { Col, Row } from "antd";
import React from "react";
import { IdNameCode, IdNamePair } from "src/app/preferredSuppliers/domains/psl";

export interface LocationCollections {
    zones: IdNamePair[],
    markets: IdNamePair[],
    companyCodes: IdNameCode[],
}

export enum LocationType {
    ZONE = "ZONE",
    CODE = "CODE",
    MARKET = "MARKET"
}

export const LocationInfo = (info: { locationCollections: LocationCollections, showInfo: LocationType }) => {

    const { markets, zones, companyCodes } = info.locationCollections;

    return (
        <div>
            <Row style={{
                padding: "24px",
                fontSize: "14px",
                lineHeight: "1.5715",
                overflowX: "auto",
                overflowY: "auto",
                maxHeight: "calc(50vh - 54px)",
            }}>
                <>
                    {info.showInfo === LocationType.ZONE &&
                        <Col span={24}>
                            <p><b>Zone - {zones?.length}</b></p>
                            {zones?.map((item: IdNamePair) =>
                                <p key={item.id}><div>{item.name}</div></p>
                            )}
                        </Col>}

                    {info.showInfo === LocationType.MARKET &&
                        <Col span={24}>
                            <p><b>Market - {markets?.length}</b></p>
                            {markets?.map((item: IdNamePair) =>
                                <p key={item.id}><div>{item.name}</div></p>
                            )}
                        </Col>}

                    {info.showInfo === LocationType.CODE &&
                        <Col span={24}>
                            <p><b>Company Code - {companyCodes?.length}</b></p>
                            {companyCodes?.map((item: IdNameCode) =>
                                <p key={item.id}><div>{item.code} - {item.name}</div></p>
                            )}
                        </Col>}
                </>
            </Row>
        </div>)
}