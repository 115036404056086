import {template} from 'lodash';
import {Contract, ContractNumber} from "../contract/contract";
import {not, or, propEq} from "ramda";
import {sapUrl} from "../../../shared/utils/urlBuilders";

export enum ItemStatus {
  DELETE = "1",
  UNDELETE = "2",
  BLOCK = "3",
  UNBLOCK = "4",
  ACTIVE = " "
}

const SapLinkTemplate = template(
"https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=alias%>&TCode=ME01&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=<%=alias%>&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=EORD-MATNR%3d<%=material%>%26EORD-WERKS=<%=plant%>&AutoStart=true"
);

const PIRLinkTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=alias%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=<%=alias%>&SAP_Source=EXT&SAP_IViewDesc=<%=code%>&DynamicParameter=EINA-LIFNR%3d<%=vendor%>%26EINA-MATNR%3d<%=material%>%26EINE-EKORG%3d<%=purchOrg%>%26EINE-WERKS%3d<%=plant%>&AutoStart=true"
);

interface NetworkMaterialDTO {
  id: string;
  name: string;
}

export class MaterialDTO {
  constructor(
    public readonly Id: string,
    public readonly Name: string
  ) {}

  static FromBackend(data: NetworkMaterialDTO): MaterialDTO {
    return new MaterialDTO(
      data.id,
      data.name
    )
  }
}

export interface NetworkItemDTO {
  __metadata: { id: string; type: string; uri: string };
  IdNo: string;
  AgreementNo: string;
  ItemNo: string;
  Material: string;
  ShortText: string;
  TargetQty: string;
  OrderUnit: string;
  NetPrice: string;
  Per: string;
  OrderPriceUnit: string;
  Plant: string;
  MaterialGroup: string;
  MaterialGroupDesc: string;
  DeletionInd: string;
  DataChanged: string;
  PriceChanged: string;
  PlantName: string;
  SearchTerm: string;
  Favourite: string;
  Action: string;
  DeleteCount: number;
  BlockCount: number;
  TimeStamp: string;
  RefAgreementNo: string;
  Specification: string;
  ItemCat: string,
  ItemCatText: string,
  Currency: string,
  ItemToPricing: { __deferred: { uri: string } };
}

export type ItemNumber = string;

export class Item {
  constructor(
    public readonly AgreementNo: string,
    public readonly ItemNo: ItemNumber,
    public readonly Material: string,
    public readonly ShortText: string,
    public readonly TargetQty: string,
    public readonly OrderUnit: string,
    public readonly NetPrice: string,
    public readonly Per: string,
    public readonly OrderPriceUnit: string,
    public readonly Plant: string,
    public readonly MaterialGroup: string,
    public readonly MaterialGroupDesc: string,
    public readonly Specification: string,
    public readonly Action: ItemStatus,
    public readonly RefAgreementNo: ContractNumber = "",
    public readonly TimeStamp: string = "",
    public readonly ItemCat: string = "",
    public readonly ItemCatText: string = "",
    public readonly Currency: string = "",
    public readonly SystemAlias: string = "",
  ) {
    this.TargetQty = TargetQty.trim();
    this.NetPrice = NetPrice.trim();
    this.ItemCat = ItemCat === "" ? "Std" : ItemCat;
  }

  static FromBackend(data: NetworkItemDTO, systemAlias: string): Item {
    return new Item(
      data.AgreementNo,
      data.ItemNo,
      data.Material,
      data.ShortText,
      data.TargetQty,
      data.OrderUnit,
      data.NetPrice,
      data.Per,
      data.OrderPriceUnit,
      data.Plant,
      data.MaterialGroup,
      data.MaterialGroupDesc,
      data.Specification,
      data.Action as ItemStatus,
      data.RefAgreementNo,
      data.TimeStamp,
      data.ItemCat,
      data.ItemCatText,
      data.Currency,
      systemAlias
    )
  }

  static getSAPLink(item: Item, contract: Contract): string {
    const prefix = sapUrl(process.env.REACT_APP_ENV.trim());
    return SapLinkTemplate({
      prefix: prefix,
      alias: contract.SystemAlias,
      code: contract.ChangeCode,
      material: item.Material,
      plant: item.Plant
    });
  }

  static getCreatePIRLink(item: Item, contract: Contract): string {
    const prefix = sapUrl(process.env.REACT_APP_ENV.trim());
    return PIRLinkTemplate({
      prefix: prefix,
      alias: contract.SystemAlias,
      code: 'ME11',
      vendor: contract.VendorNo,
      material: item.Material,
      plant: item.Plant,
      purchOrg: contract.PurchOrganization
    });
  }

  static getChangePIRLink(item: Item, contract: Contract): string {
    const prefix = sapUrl(process.env.REACT_APP_ENV.trim());
    return PIRLinkTemplate({
      prefix: prefix,
      alias: contract.SystemAlias,
      code: 'ME12',
      vendor: contract.VendorNo,
      material: item.Material,
      plant: item.Plant,
      purchOrg: contract.PurchOrganization
    });
  }
}

export interface NetworkDeletedBlockedItemDTO {
  __metadata: { id: string; type: string; uri: string };
  BlockCount: number;
  DeleteCount: number;
  TargetValue: string;
}

export class DeletedBlockedItem {
  constructor(
    public readonly BlockCount: number = 0,
    public readonly DeleteCount: number = 0,
    public readonly TargetValue: string = "",
  ) {}

  static FromBackend(data: NetworkDeletedBlockedItemDTO): DeletedBlockedItem {
    return new DeletedBlockedItem(
      data.BlockCount,
      data.DeleteCount,
      data.TargetValue.trim(),
    )
  }
}

export const changeStatusOld = (item: DeletedBlockedItem, action: ItemStatus) => {
  switch (action) {
    case ItemStatus.DELETE:
      return {...item, DeleteCount: item.DeleteCount - 1};
    case ItemStatus.BLOCK:
      return {...item, BlockCount: item.BlockCount - 1};
    default:
      return item;
  }
};

export const changeStatus = (item: DeletedBlockedItem, oldAction: ItemStatus, newAction: ItemStatus) => {
  switch (newAction) {
    case ItemStatus.DELETE:
      return changeStatusOld({...item, DeleteCount: item.DeleteCount + 1}, oldAction);
    case ItemStatus.UNDELETE:
      if (item.DeleteCount <= 0) return {...item, DeleteCount: 0};
      return {...item, DeleteCount: item.DeleteCount - 1};
    case ItemStatus.BLOCK:
      return changeStatusOld({...item, BlockCount: item.BlockCount + 1}, oldAction);
    case ItemStatus.UNBLOCK:
      if (item.BlockCount <= 0) return {...item, BlockCount: 0};
      return {...item, BlockCount: item.BlockCount - 1};
    default:
      return item;
  }
};

export const isDeleted = (item: Item): boolean => propEq('Action', ItemStatus.DELETE, item);
export const isBlocked = (item: Item): boolean => propEq('Action', ItemStatus.BLOCK, item);
export const priceZero = (item: Item): boolean => propEq('NetPrice', "0.00", item);
export const isActive = (item: Item): boolean => not(or(isDeleted(item), isBlocked(item)));
export const isDisabled = (item: Item): boolean => or(isDeleted(item), isBlocked(item));
export const orDisabledOrPriceZero = (item: Item): boolean => not(or(isDisabled(item), priceZero(item)));
