import {IAction} from "../../shared/domains/core/actions";
import {PRPOItemsTypes} from "../actions/items.actions";
import {mergeRight} from "ramda";
import {CompliantItemPRPO} from "../domains/prpo/item.service";
import {GRItem} from "../domains/prpo/gr.item";
import {IRItem} from "../domains/prpo/ir.item";

export function generateItemId(prNo, poNo, systemAlias) {
    return `${prNo}-${poNo}-${systemAlias}`
}

export function generateItemInvId(invNo, systemAlias) {
  return `${invNo}-${systemAlias}`
}

export interface PRItemState {
    items: [];
    totalCount: number;
    compliantItems: CompliantItemPRPO;
    grItems: {
        [key: string]: {
            items: GRItem[]
            loading: boolean;
            total: number;
        }
    };
    grItemsInv: {
      [key: string]: {
        items: GRItem[]
        loading: boolean;
        total: number;
      }
    };
    irItems: {
        [key: string]: {
            items: IRItem[]
            loading: boolean;
            total: number;
        }
    };
    irItemsInv: {
      [key: string]: {
        items: IRItem[]
        loading: boolean;
        total: number;
      }
    };
    loading: boolean;
    error: any;
}

export const initialPRItemState: PRItemState = {
    items: [],
    totalCount: 0,
    compliantItems: {pr: [], po: []},
    grItems: {},
    grItemsInv: {},
    irItems: {},
    irItemsInv: {},
    loading: false,
    error: null
}

const loading = {loading: true, error: false};
const loaded = {loading: false, error: false};
const failure = {loading: false, error: true};

export function PRPOItemsReducer(state: PRItemState = initialPRItemState, action: IAction<PRPOItemsTypes>): PRItemState {
    switch (action.type) {
        case PRPOItemsTypes.FETCH_PR_ITEMS:
        case PRPOItemsTypes.FETCH_PO_ITEMS:
        case PRPOItemsTypes.UPDATE_PR_ITEM:
            return mergeRight(state, loading);
        case PRPOItemsTypes.UPDATE_PRPO_ITEM_SUCCESS:
            return mergeRight(state, loaded);
        case PRPOItemsTypes.FETCH_PR_ITEMS_SUCCESS:
        case PRPOItemsTypes.FETCH_PO_ITEMS_SUCCESS:
            return mergeRight(state, {loading: false, items: action.items, totalCount: action.totalCount});
        case PRPOItemsTypes.FETCH_PR_ITEMS_FAILURE:
        case PRPOItemsTypes.FETCH_PO_ITEMS_FAILURE:
        case PRPOItemsTypes.UPDATE_PRPO_ITEM_FAILURE:
            return mergeRight(state, failure);
        case PRPOItemsTypes.FETCH_COMPLIANT_ITEMS_SUCCESS:
            return mergeRight(state, {compliantItems: action.items});

        case PRPOItemsTypes.FETCH_GR_ITEMS:
            return mergeRight(state,
                {
                    grItems: {
                        ...state.grItems,
                        [generateItemId(action.prNo, action.poNo, action.systemAlias)]: {
                            items: [],
                            loading: true,
                            total: 0
                        }
                    }
                });
        case PRPOItemsTypes.FETCH_GR_ITEMS_INV:
          return mergeRight(state,
            {
              grItemsInv: {
                ...state.grItemsInv,
                [generateItemInvId(action.InvDocNo, action.systemAlias)]: {
                  items: [],
                  loading: true,
                  total: 0
                }
              }
            });
        case PRPOItemsTypes.FETCH_GR_ITEMS_SUCCESS:
          return mergeRight(state,
            {
              grItems: {
                ...state.grItems,
                [generateItemId(action.prNo, action.poNo, action.systemAlias)]: {
                  items: action.items,
                  loading: false,
                  total: action.totalCount
                }
              }
            });
        case PRPOItemsTypes.FETCH_GR_ITEMS_INV_SUCCESS:
            return mergeRight(state,
                {
                  grItemsInv: {
                        ...state.grItemsInv,
                        [generateItemInvId(action.InvDocNo, action.systemAlias)]: {
                            items: action.items,
                            loading: false,
                            total: action.totalCount
                        }
                    }
                });
        case PRPOItemsTypes.FETCH_GR_ITEMS_FAILURE:
            return mergeRight(state,
                {
                    grItems: {
                        ...state.grItems,
                        [generateItemId(action.prNo, action.poNo, action.systemAlias)]: {
                            items: [],
                            loading: false,
                            total: 0
                        }
                    }
                });
        case PRPOItemsTypes.FETCH_GR_ITEMS_INV_FAILURE:
          return mergeRight(state,
            {
              grItemsInv: {
                ...state.grItemsInv,
                [generateItemInvId(action.InvDocNo, action.systemAlias)]: {
                  items: [],
                  loading: false,
                  total: 0
                }
              }
            });
        case PRPOItemsTypes.FETCH_IR_ITEMS:
            return mergeRight(state,
                {
                    irItems: {
                        ...state.irItems,
                        [generateItemId(action.prNo, action.poNo, action.systemAlias)]: {
                            items: [],
                            loading: true,
                            total: 0
                        }
                    }
                });
        case PRPOItemsTypes.FETCH_IR_ITEMS_SUCCESS:
            return mergeRight(state,
                {
                    irItems: {
                        ...state.irItems,
                        [generateItemId(action.prNo, action.poNo, action.systemAlias)]: {
                            items: action.items,
                            loading: false,
                            total: action.totalCount
                        }
                    }
                });
        case PRPOItemsTypes.FETCH_IR_ITEMS_FAILURE:
            return mergeRight(state,
                {
                    irItems: {
                        ...state.irItems,
                        [generateItemId(action.prNo, action.poNo, action.systemAlias)]: {
                            items: [],
                            loading: false,
                            total: 0
                        }
                    }
                });
        case PRPOItemsTypes.FETCH_IR_ITEMS_INV:
          return mergeRight(state,
            {
              irItemsInv: {
                ...state.irItemsInv,
                [generateItemInvId(action.InvDocNo, action.systemAlias)]: {
                  items: [],
                  loading: true,
                  total: 0
                }
              }
            });
        case PRPOItemsTypes.FETCH_IR_ITEMS_INV_SUCCESS:
          return mergeRight(state,
            {
              irItemsInv: {
                ...state.irItemsInv,
                [generateItemInvId(action.InvDocNo, action.systemAlias)]: {
                  items: action.items,
                  loading: false,
                  total: action.totalCount
                }
              }
            });
        case PRPOItemsTypes.FETCH_IR_ITEMS_INV_FAILURE:
          return mergeRight(state,
            {
              irItemsInv: {
                ...state.irItemsInv,
                [generateItemInvId(action.InvDocNo, action.systemAlias)]: {
                  items: [],
                  loading: false,
                  total: 0
                }
              }
            });
        default:
            return state;
    }
}
