import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const FullWidthLoading = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 65px);
  position: absolute;
`;

export const FullWidthHeightLoading = styled(FullWidthLoading)`
  height: 100%;
  min-height: 300px;
  position: relative;
`;

export const LabelLoading = styled.div`
  margin-top: 10px;
`;

export const MarginLoadingContainer = styled(LoadingContainer)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

export const TableLoadingContainer = styled(LoadingContainer)`
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const StyledPopupLoading = styled(FullWidthLoading)`
  position: relative;
  padding: 10px;
`;
