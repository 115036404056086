class UomDTO {
  __metadata: { id: string; type: string; uri: string };
  Material: string;
  Uom: string;
  UomTxt: string;
}

export class UomData {
  constructor(public Material: string,
              public Uom: string,
              public UomTxt: string) {
  }

  static FromBackend(data: UomDTO): UomData {
    return new UomData(
      data.Material,
      data.Uom,
      data.UomTxt
    )
  }

  static toSelect(data: UomData) {
    return {
      value: data.UomTxt,
      label: data.Uom
    }
  }
}
