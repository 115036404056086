import { DownloadCompletedAction } from "src/app/shared/domains/core/actions";
import { AxiosWrapper } from "../../../shared/utils/axios.wrapper";
import ODataClientWrapper from "../../../shared/utils/odataClient.wrapper";
import { DocflowChat } from "./docflowChat";

export class DocflowChatService {
  static async fetchChatMessages(prNo: string, poNo: string): Promise<any[]> {
    const path = `/api/DocFlowChat/GetDocFlowChatMessages?prId=${prNo}&poId=${poNo}`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }
  static async fetchNewChatMessages(prNo: string, poNo: string): Promise<any[]> {
    const path = `/api/Chat/GetChatMessages?prId=${prNo}&poId=${poNo}`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async oldSendMessage(prNo: string, poNo: string, message: string, mentionedEmailAddresses: string[]): Promise<any> {
    const path = `/api/DocFlowChat/SendMessage`;
    const result = await AxiosWrapper.post(path, { prId: prNo, poId: poNo, message: message, mentionedEmailAddresses: mentionedEmailAddresses });
    return result.data;
  }
  static async newSendMessage(action: any): Promise<any> {
    const path = `/api/DocFlowChat/SendMessage`;
    const result = await AxiosWrapper.post(path, {
      companyName: action.companyName,
      companyId: action.companyId,
      prId: action.prNo,
      poId: action.poNo,
      alias: action.alias,
      docDate: action.docDate,
      prPotype: action.prPotype,
      message: action.message,
      mentionedPersons: action.msgSendTo,
      mentionedEmailAddresses: action.mentionedEmailAddresses,
      attachments: action.attachments,
    });
    return result.data;
  }
  static async newLatestSendMessage(messageBody: any): Promise<any> {
    const path = `/api/Chat/SendChatMessages${messageBody?.reminderFlag ? "?reminderFlag=true" : ""}`;
    const result = await AxiosWrapper.post(path, {
      CompanyName: messageBody.companyName,
      CompanyId: messageBody.companyId,
      prId: messageBody.prNo ? messageBody.prNo : "",
      PoId: messageBody.poNo ? messageBody.poNo : "",
      SystemAlias: messageBody.systemAlias,
      DocDate: messageBody.docDate,
      PrPotype: messageBody.prPotype,
      Message: messageBody.message,
      MentionedPersons: messageBody.msgSendTo,
      MentionedEmailAddresses: messageBody.mentionedEmailAddresses,
      Attachments: messageBody.attachments,
      InvoiceNumber: messageBody.invoiceNumber ? messageBody.invoiceNumber : "",
      Year: messageBody.year ? messageBody.year : "",
      ApplicationCreated: messageBody.applicationCreated,
      TabCreated: messageBody.tabCreated
    });
    return result.data;
  }
  static async fetchChatGroups(prNo: string, poNo: string): Promise<any[]> {
    const response = await ODataClientWrapper.get()
      .resource(`UserGroupSet`)
      .filter({ field: 'DocNo', value: poNo })
      .and({ field: 'PrNo', value: prNo })
      .toDocFlowChat()
      .toJson()
      .execute();

    const results = response.data.d.results;
    return results.map(item => DocflowChat.fromBackend(item));
  }

  static async fetchChatDetails(data: any): Promise<any> {
    let query = ODataClientWrapper.get()
      .withSystemAlias(data.systemAlias)
      .resource("PRPOApprovalSet")
    if (data.PoNo && data.PoNo.trim() !== "") {
      query.filter({ field: "PoNo", value: data.PoNo });
    }
    if (data.PrNo && data.PrNo.trim() !== "") {
      query.filter({ field: "PrNo", value: data.PrNo });
    }
    const response = await query.execute();
    if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
      throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
    }
    console.log(response);
    const results = response.data.d.results;
    return results;
  }

  static async fetchInvoiceChatMessages(invoiceNumber: string): Promise<any[]> {
    const path = `/api/InvChat/GetInvoiceChatMessages?invoiceNumber=${invoiceNumber}`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async sendInvoiceChatMessage(invoiceNumber: string, message: string, mentionedEmailAddresses: string[], year: string, company: string, companyName: string, systemAlias: string): Promise<any> {
    const path = `/api/InvChat/SendMessage`;
    const result = await AxiosWrapper.post(path, { invoiceNumber, message, mentionedEmailAddresses, year, company, companyName, systemAlias });
    return result.data;
  }

  static async sendInvoiceChatMessageNew(invoiceNumber: string, message: string, mentionedEmailAddresses: string[], year: string, company: string, companyName: string, systemAlias: string,
    msgSendTo: Object[], attachments: string): Promise<any> {
    const path = `/api/InvChat/SendMessage`;
    const result = await AxiosWrapper.post(path, { invoiceNumber, message, mentionedEmailAddresses, year, company, companyName, systemAlias, mentionedPersons: msgSendTo, attachments: attachments });
    return result.data;
  }

  static async uploadAttachments(formData, options?) {
    const path = `/api/DocFlowChat/UploadAttachment`;
    const result = await AxiosWrapper.post(path, formData, options);
    return result.data;
  }

  static async deleteAttachments(attachments) {
    const path = '/api/DocFlowChat/DeleteAttachment/';
    const result = await AxiosWrapper.post(path, attachments);
    return result.data;
  }

  static async downloadAttachments(fileNameAndUniqId) {
    const path = `/api/DocFlowChat/DownloadAttachment`;
    const uniqId = fileNameAndUniqId.uniqId
    const fileName = fileNameAndUniqId.fileName
    const response = await AxiosWrapper.post(path, { attachments: uniqId },
      {
        responseType: 'blob'
      }
    );
    new DownloadCompletedAction(fileName, response.data);
    return response.data;
  }



  static async uploadAttachmentsForInvoice(formData, options?) {
    const path = `/api/InvChat/UploadAttachment`;
    const result = await AxiosWrapper.post(path, formData, options);
    return result.data;
  }


  static async deleteAttachmentsForInvoice(attachments) {
    const path = '/api/InvChat/DeleteAttachment/';
    const result = await AxiosWrapper.post(path, attachments);
    return result.data;
  }

  static async downloadAttachmentsForInvoice(fileNameAndUniqId) {
    const path = `/api/InvChat/DownloadAttachment`;
    const uniqId = fileNameAndUniqId.uniqId
    const fileName = fileNameAndUniqId.fileName
    const response = await AxiosWrapper.post(path, { attachments: uniqId },
      {
        responseType: 'blob'
      }
    );
    new DownloadCompletedAction(fileName, response.data);
    return response.data;
  }

}
