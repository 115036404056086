import {
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../../shared/domains/core/actions";

export enum PAActionTypes {

  INIT_PA_FILTERS = "INIT_PA_FILTERS",

  PA_GET_FILTERS = "PA_GET_FILTERS",
  PA_GET_FILTERS_SUCCESS = "PA_GET_FILTERS_SUCCESS",
  PA_GET_FILTERS_FAILURE = "PA_GET_FILTERS_FAILURE",

  PA_NEW_FILTER = 'PA_NEW_FILTER',
  PA_NEW_FILTER_SUCCESS = 'PA_NEW_FILTER_SUCCESS',
  PA_NEW_FILTER_FAILURE = 'PA_NEW_FILTER_FAILURE',

  DELETE_PA_FILTER = "DELETE_PA_FILTER",
  DELETE_PA_FILTER_SUCCESS = 'DELETE_PA_FILTER_SUCCESS',
  DELETE_PA_FILTER_FAILURE = 'DELETE_PA_FILTER_FAILURE',

  RESET_PA_FILTERS = "RESET_PA_FILTERS",
  RESET_PA_FILTERS_SUCCESS = "RESET_PA_FILTERS_SUCCESS",

  UPDATE_PA_FILTER_SUCCESS = 'UPDATE_PA_FILTER_SUCCESS',
  UPDATE_PA_FILTER_FAILURE = 'UPDATE_PA_FILTER_FAILURE',

}

export class NewFilters extends PendingAction<PAActionTypes> {
  public readonly type = PAActionTypes.PA_NEW_FILTER;
  constructor(public filters, public query, public prefferedCode, public alias) {
    super();
  }
}

export class InitPAFilters extends PendingAction<PAActionTypes> {
  public readonly type = PAActionTypes.INIT_PA_FILTERS;
  constructor(public filters) {
    super();
  }
}

export class GetFilters extends PendingAction<PAActionTypes> {
  public readonly type = PAActionTypes.PA_GET_FILTERS;
 
}

export class GetFiltersSuccess extends SuccessAction<PAActionTypes> {
  public readonly type = PAActionTypes.PA_GET_FILTERS_SUCCESS;
}

export class GetFiltersError extends ErrorAction<PAActionTypes> {
  public readonly type = PAActionTypes.PA_GET_FILTERS_FAILURE;
  constructor(public error) {
    super(error, true);
}
}

export class ResetPAFilters extends PendingAction<PAActionTypes> {
  public readonly type = PAActionTypes.RESET_PA_FILTERS;
  constructor(public prefferedCode, public companyCodeName, public positionId) {
    super();
  }
}

export class ResetPAFiltersSuccess extends PendingAction<PAActionTypes> {
  public readonly type = PAActionTypes.RESET_PA_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}