import * as React from "react";
import { Button, Col, Modal, Row, Space, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { GetVendorInfo } from "src/app/preferredSuppliers/actions/psl.actions";
import { RootStateStore } from "src/app/application.reducers";
import { Company, PurchasingOrg } from "src/app/preferredSuppliers/domains/psl";
import { ApplyButton } from "../filters/filters.styles";

interface vendorInfo {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    vendorId: string | number;
}

export const PslVendorInfo = ({ isModalOpen, setIsModalOpen, vendorId }: vendorInfo) => {
    const { Text } = Typography;
    const [errorString, setErrorString] = React.useState<string>("");

    const [purchOrgMoreButton, setPurchOrgMoreButton] = React.useState<boolean>(false);
    const [compCodeMoreButton, setCompCodeMoreButton] = React.useState<boolean>(false);

    const vendorInfo = useSelector((state: RootStateStore) => state.psl.pslList.data.vendorInfo);
    const { data, loading } = vendorInfo;
    const dispatch = useDispatch();

    const purchOrgs = purchOrgMoreButton ? data.purchasingOrgs : data.purchasingOrgs.slice(0, 5);
    const compCodes = compCodeMoreButton ? data.companies : data.companies.slice(0, 5);

    React.useEffect(() => {
        if (isModalOpen && vendorId != "") {
            dispatch(new GetVendorInfo(vendorId, error));
        }
    }, [isModalOpen]);

    const error = (typeOfError: string) => {
        setErrorString(typeOfError);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setErrorString("");
    };

    return (<><Modal
        title={errorString !== "" ? "Alert" : `${data.code} - ${data.description}`}
        visible={isModalOpen}
        width={900}
        footer={null}
        onCancel={handleCancel}
        className="pslVendorInfo"
    >
        <Spin spinning={loading} size="default" tip="Loading...">
            <Row
                style={{
                    overflowX: "auto",
                    overflowY: "auto",
                    maxHeight: "50vh",
                }}
            >
                {errorString !== "" ? <div style={{
                    padding: "20px", fontSize: "20px",color: "#939292", fontWeight: 500,
                    width: "100%", display: "flex", justifyContent: "center", alignItems: "center"
                }}>
                    <div>
                        This Vendor is not extended to any Company codes or Purchasing Org.
                    </div>
                </div>
                    :
                    <>
                        <Col span={8}>
                            <p><b>Vendor</b>
                                <div>{data.code}</div>
                            </p>

                            <p><b>Description</b>
                                <div>{data.description}</div>
                            </p>

                            <p><b>Address</b></p>
                            <p>
                                <div><span className="subHeadingFont">Street House:</span> {data.street}</div>
                                <div><span className="subHeadingFont">Postal Codee:</span> {data.postalCode}</div>
                                <div><span className="subHeadingFont">City:</span> {data.city}</div>
                                <div><span className="subHeadingFont">District:</span> {data.district}</div>
                                <div><span className="subHeadingFont">Region:</span> {data.region}</div>
                                <div><span className="subHeadingFont">Country:</span> {data.country}</div>
                                <div><span className="subHeadingFont">Tax Jurisdiction:</span> {data.taxJurisdiction}</div>
                            </p>
                        </Col>
                        <Col span={8}>
                            <p><b>Extended to PurOrg in SAP Master Data</b></p>
                            {purchOrgs.map((purOrg: PurchasingOrg) => {
                                return (<p>
                                    <div><span className="subHeadingFont">{purOrg.code} - </span>{purOrg.name}</div>
                                </p>)
                            })}
                            {(data.purchasingOrgs.length > 5) && <ApplyButton
                                variant="contained" color="primary" size="small"
                                onClick={() => setPurchOrgMoreButton(prevState => !prevState)}>
                                {purchOrgMoreButton ? "Show Less" : "Show More"}
                            </ApplyButton>
                            }
                        </Col>
                        <Col span={8}>
                            <p><b>Extended to Comp Code in SAP Master Data</b></p>
                            {compCodes.map((compCode: Company) => {
                                return (<p>
                                    <div><span className="subHeadingFont">{compCode.code} - </span>{compCode.name}</div>
                                </p>)
                            })}
                            {(data.companies.length > 5) && <ApplyButton
                                variant="contained" color="primary" size="small"
                                onClick={() => setCompCodeMoreButton(prevState => !prevState)}>
                                {compCodeMoreButton ? "Show Less" : "Show More"}
                            </ApplyButton>
                            }
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col span={8} offset={16}>
                    {/* <p>
                        Click <a href="/#">here</a> to more details on Vendor Life cycle process
                    </p> */}
                </Col>
            </Row>
            {/* <Row justify="center">
                <Col span={8} offset={3}>
                    <Button size="large" style={{ background: '#f19001', width: '10vw', color: 'white' }} onClick={handleCancel}> Back </Button>
                </Col>
            </Row> */}
        </Spin>
    </Modal></>)
}