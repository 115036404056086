import {ApprovalHistoryReducers, ApprovalHistoryState} from "./approvalHistory.reducers";
import {combineReducers, Reducer} from "redux";

export interface approvalHistoryStateStore {
    ApprovalHistory : ApprovalHistoryState
}

export const approvalHistoryCombinedReducers: Reducer<approvalHistoryStateStore> = combineReducers({
    ApprovalHistory : ApprovalHistoryReducers
});
