import { MrpCatalogType, Mrp } from "./mrp";
import { IdNo } from "../../contracts/domains/contract/contract.service";
import { AxiosWrapper } from "../../shared/utils/axios.wrapper";
import { mrpFiltersToBackend } from "./mrpQuery";
import { dissocPath, isNil, omit, take } from "ramda";
import { IMrpForm } from "../containers/mrpForm/mrpForm.utils";
import { IdNamePair } from "../../preferredSuppliers/domains/psl";
import { SpendCategory } from "../../shared/domains/master/category";
import ODataClientWrapper from "../../shared/utils/odataClient.wrapper";
import { ODataFilter } from "../../../lib/odata";
import { AxiosRequestConfig } from "axios";
import { environment } from "../../shared/domains/core/environment";

const MAX_AMDR_RESULTS = 100;

export interface MrpMaterial {
  id: string,
  materialGroup: string,
  name: string,
  unitOfMeasureList: any,
  mtart: string
}

export enum MrpAccountAssignmentTypes {
  TYPE_U = "U",
  TYPE_K = "K",
  TYPE_BLANK = "B"
}

export const MrpAccountAssignmentOptionsLimited = [
  { label: 'K', value: MrpAccountAssignmentTypes.TYPE_K },
  { label: 'U', value: MrpAccountAssignmentTypes.TYPE_U },
]
export const MrpAccountAssignmentOptions = [
  { label: '‎‎‎‎‏‏‎ ‎', value: MrpAccountAssignmentTypes.TYPE_BLANK },
  { label: 'K', value: MrpAccountAssignmentTypes.TYPE_K },
  { label: 'U', value: MrpAccountAssignmentTypes.TYPE_U },
]
export class MrpService {

  static async fetch(query: { filters: any, term: string, top: number, page: number }, isItSearch?: boolean, mrpSearch?: any): Promise<{
    data: Mrp[],
    total: number,
    skip: number,
    take: number
  }> {
    const newFilters = isItSearch ? { ...mrpFiltersToBackend(query.filters), mrpSearch: mrpSearch } : mrpFiltersToBackend(query.filters);
    const response = await AxiosWrapper.post(`/api/Mrp/GetMrpMappings`,
      newFilters, {
      params: {
        skip: query.page * query.top,
        take: query.top,
        searchTerm: isItSearch ? "" : query.term
      }
    });
    return {
      ...response.data,
      data: (response.data.data && Array.isArray(response.data.data)) ? response.data.data.map(Mrp.FromBackend) : [],
    };
  }

  static async fetchSingleMrp(id: number): Promise<Mrp> {
    const response = await AxiosWrapper.get(`/api/Mrp/GetMrpMapping?id=${id}`);
    return Mrp.FromBackend(response.data);
  }

  static async save(mrp: IMrpForm): Promise<Mrp> {
    const response = await AxiosWrapper.post(`/api/Mrp/InsertMrpMapping`,
      omit(['id'], dissocPath(['priceInfo', 'id'], MrpService.prepareMRPtoSave(mrp)))
    );
    return Mrp.FromBackend(response.data);
  }

  static async update(mrp: IMrpForm): Promise<Mrp> {
    const body = mrp.priceInfo.id ? mrp : dissocPath(['priceInfo', 'id'], mrp);
    const response = await AxiosWrapper.post(`/api/Mrp/UpdateMrpMapping`, MrpService.prepareMRPtoSave(body));
    return Mrp.FromBackend(response.data);
  }

  static async refreshMrpData(): Promise<Mrp> {
    const response = await AxiosWrapper.get(`/api/Users/MeLiteRefreshMrp`);
    return Mrp.FromBackend(response.data);
  }
  static async getSpendCategory(materialNumber: string): Promise<any> {
    const response = await AxiosWrapper.get(`/api/Mrp/GetMrpAmdrSpendCategory?materialNumber=${materialNumber}`);
    return response.data;
  }
  static async getContractLanguage(supplierNumber: string, plantNumber: string): Promise<any> {
    const response = await AxiosWrapper.get(`/api/Mrp/GetMrpAmdrSupplierLanguage?supplierNumber=${supplierNumber}&plantNumber=${plantNumber}`);
    return response.data;
  }
  static async getMrpHistory(mrpId: string): Promise<any> {
    const response = await AxiosWrapper.get(`/api/Mrp/GetMrpHistory?mrpId=${mrpId}`);
    return response.data;
  }
  static async fetchByIdNo(idNo: IdNo, keyMap): Promise<[]> {
    return Promise.resolve([]);
  }

  static async import(formData): Promise<any> {
    const options: AxiosRequestConfig = { timeout: parseInt(environment.REACT_APP_MRP_IMPORT_FILE_TIMEOUT) }
    const response = await AxiosWrapper.post(`/api/Mrp/PostMrpMappingsFromExcel`, formData, options);
    return response.data;
  }

  static async exportTemplate(): Promise<any> {
    const response = await AxiosWrapper.get('/api/Mrp/GetMrpMappingsExcelTemplate', {
      responseType: 'blob'
    });
    return response.data;
  }

  static async exportData(query: { filters: any, term: string }): Promise<any> {
    const response = await AxiosWrapper.post('/api/Mrp/GetMrpMappingsAsExcel',
      mrpFiltersToBackend(query.filters), {
      responseType: 'blob',
      params: { searchTerm: query.term }
    });
    return response.data;
  }

  static async exportDataByEmail(query: { filters: any, term: string }): Promise<any> {
    const options: AxiosRequestConfig = { timeout: parseInt(environment.REACT_APP_MRP_EXPORT_FILE_TIMEOUT) }
    const response = await AxiosWrapper.post('/api/Mrp/GetMrpMappingsAsExcelByEmail',
      mrpFiltersToBackend(query.filters), {
      ...options,
      responseType: 'blob',
      params: { searchTerm: query.term }
    });
    return response.data;
  }

  static async fetchMrpMaterials(plant: string, term: string = ""): Promise<any[]> {
    const response = await AxiosWrapper.get('/api/Mrp/GetMrpAmdrMaterials',
      { params: { searchTerm: term, plant: plant } });
    const trimmed: MrpMaterial[] = response.data ? take(MAX_AMDR_RESULTS, response.data) : [];
    return trimmed.map((el: MrpMaterial) => ({
      value: el.id,
      label: el.name,
      matGrp: el.materialGroup,
      unitOfMeasureList: el.unitOfMeasureList,
      mtart: el.mtart
    }));
  }

  static async fetchMrpPlants(companyCode: string, term: string = ""): Promise<any[]> {
    //This endpoint does not return PlantName. Specific requirement from Nibir.
    const response = await AxiosWrapper.get('/api/Mrp/GetMrpAmdrPlants',
      { params: { searchTerm: term, companyCode: companyCode } });
    const trimmed: IdNamePair[] = response.data ? take(MAX_AMDR_RESULTS, response.data) : [];
    return trimmed.map((el: any) => ({
      value: el.plantCode
    }));
  }

  static async fetchMrpSuppliers(companyCode: string, purchasingOrg: string, searchTerm: string = ""): Promise<any[]> {
    const response = await AxiosWrapper.get('/api/Mrp/GetMrpAmdrSuppliers',
      { params: { companyCode, purchasingOrg, searchTerm } });
    const trimmed: IdNamePair[] = response.data ? take(MAX_AMDR_RESULTS, response.data) : [];
    return trimmed.map((el: IdNamePair) => ({
      value: el.id,
      label: el.name
    }));
  }

  static async markAsDeleted(mrpId: number, comment: string): Promise<any> {
    const response = await AxiosWrapper.post('/api/Mrp/MarkMrpMappingAsDeleted',
      {}, { params: { mappingId: mrpId, comment: comment } });
    return response.data;
  }
  static async approveMrp(mrpId: number): Promise<any> {
    const response = await AxiosWrapper.post('/api/Mrp/ApproveMrpStatus',
      {}, { params: { mappingId: mrpId } });
    return response.data;

  }
  static async approveMrps(mrpIds: number[]): Promise<any> {
    const query = mrpIds.map(id => "mrpIds=" + id).join("&");
    const response = await AxiosWrapper.post(`/api/Mrp/ApproveMrpStatusBulk?${query}`, {});
    return response.data;
  }

  static async fetchMrpPurchaseOrgs(term: string = ""): Promise<SpendCategory[]> {
    const filter = ODataFilter.for({ field: 'PurchaseOrgName', value: term });
    const response = await ODataClientWrapper.get()
      .resource("MrpPurchOrgs")
      .filter(filter)
      .execute();
    return response.data.d.results.map(el => ({ value: el.PurchaseOrg, label: el.PurchaseOrgName }));
  }

  static async checkIfDuplicate(mrp: IMrpForm): Promise<{ id: number, duplicate: boolean, catalogType: MrpCatalogType }> {
    const response = await AxiosWrapper.post(
      `/api/Mrp/CheckExistingMrpMapping`,
      dissocPath(['priceInfo', 'id'], omit(['id'], MrpService.prepareMRPtoSave(mrp)))
    );

    if (isNil(response.data) || isNil(response.data.id) || response.data.id === mrp.id) return { id: null, catalogType: null, duplicate: false };

    return { id: response.data.id, duplicate: true, catalogType: parseInt(response.data.catalogType) };
  }

  static async getMrpScraping(): Promise<any> {
    const response = await AxiosWrapper.get('/api/MrpScraping/ScrapingGetTest');
    return response.data;
  }
  static async postMrpScraping(): Promise<any> {
    const response = await AxiosWrapper.post('/api/MrpScraping/ScrapingPostTest', {}, { params: { downloadOnly: false } });
    return response.data;
  }
  static async sendMrpAsContracts(query: any): Promise<any> {
    const response = await AxiosWrapper.post('/api/Mrp/SendMrpMappingsAsContractsToSapOnDemand',
      mrpFiltersToBackend(query.filters), { params: { searchTerm: query.term } });
    if (!response.data || !Array.isArray(response.data)) return [];
    return response.data;
  }
  static async fetchGlAccounts(compCode: string, term: string): Promise<any[]> {
    let filter = ODataFilter
      .for({ field: 'CompCode', value: compCode })
      .and({ field: 'Description', value: term });
    const response = await ODataClientWrapper.get()
      .resource("GLAccountSet")
      .filter(filter)
      .execute();
    return response.data.d.results?.map(v => ({ value: v.GLAccountNo, label: v.Description }));
  }
  static async fetchCostCenter(compCode: string, plantCode: string, term: string): Promise<any[]> {
    let filter = ODataFilter
      .for({ field: 'CompCode', value: compCode })
      .and({ field: 'Plant', value: plantCode })
      .and({ field: 'Description', value: term });
    const response = await ODataClientWrapper.get()
      .resource("CostCenterSet")
      .filter(filter)
      .execute();
    return response.data.d.results?.map(v => ({ value: v.CostCenterNo, label: v.Description }));
  }

  static prepareMRPtoSave(mrp: any) {
    return {
      ...mrp,
      targetValue: mrp.targetValue.replace(/\.00$/, ""),
      plannedDeliveryTime: +mrp.plannedDeliveryTime,
      priceInfo: {
        ...mrp.priceInfo,
        validityEndDate: mrp.priceInfo?.validityEndDate ? mrp.priceInfo.validityEndDate.toISODate() : null,
        validityStartDate: mrp.priceInfo?.validityStartDate ? mrp.priceInfo.validityStartDate.toISODate() : null,
        priceGrid: Mrp.PreparePriceGrid(mrp.priceInfo?.priceGrid),
      },
      contractStartValidityDate: mrp.contractStartValidityDate ? mrp.contractStartValidityDate.toISODate() : null,
      contractEndValidityDate: mrp.contractEndValidityDate ? mrp.contractEndValidityDate.toISODate() : null,
    }
  }
}
