import {Mrp, PriceGridItem} from "../../domains/mrp";
import {isNil} from "ramda";
import {MrpAccountAssignmentTypes} from "../../domains/mrp.service";
import {DateTime} from "luxon";
import { IdNamePair } from "src/app/preferredSuppliers/domains/psl";

export enum MrpScope {
  AboveMarket = 'AboveMarket',
  AtMarket = 'AtMarket',
}

export interface IMrpForm {
  id: number,
  supplierId: string,
  supplierName: string,
  supplierPartId: string,
  supplierPartIdDescription: string,
  manufacturePart: string,
  paymentTerms: string,
  paymentTermsDesc: string,
  upc: string,
  materialDescription: string,
  materialGroup: string,
  materialNumber: string,
  unspsc: string,
  taxCode: string,
  taxCodeDesc: string,
  purchasingOrgCode: string,
  purchasingOrgName: string,
  companyCode: string,
  companyName: string,
  plantCode: string,
  plantName: string,
  catalogName: string,
  buyerEmail: string,
  additionalContacts:string,
  catalogType: number,
  incoTerm: string,
  incoTermDesc: string,
  signedAgreementNumber: string,
  contractNumber: string,
  accountAssignmentCategory: any,
  glAccount: string,
  glAccountDescription: string,
  costCenter: string,
  costCenterDescription: string,
  scope: MrpScope,
  plannedDeliveryTime:string,
  requestId:string,
  priceInfo: {
    id: number,
    supplierId: string,
    supplierPartId: string,
    price: string,
    pricePer: number,
    currency: string,
    quantity: string,
    unitOfMeasure: string,
    itemDescription: string,
    fullDescription: string,
    errorComment: string,
    catalogName: string,
    validityStartDate: DateTime,
    validityEndDate: DateTime,
    validityEndDateModifiedFlag?: boolean,
    priceGrid: PriceGridItem[],
  },
  contractStartValidityDate: DateTime,
  contractEndValidityDate: DateTime,
  targetValue: string,
  purchasingGroup: string,
  status:number,
  spendCategory:string,
  contractLanguage:string,
  priceListLink?: string,
}

export interface IMrpFormErrors {
  supplierId: boolean,
  supplierPartId: boolean,
  purchasingOrgCode: boolean,
  companyCode: boolean,
  plantCode: boolean,
  catalogType: boolean,
  incoTerms: boolean,
  purchasingGroup: boolean,
  contractStartValidityDate: boolean,
  contractEndValidityDate: boolean,
  priceValidityStartDate: boolean,
  priceValidityEndDate: boolean,
  targetValue: boolean,
  currency: boolean,
  price: boolean,
  pricePer: boolean,
  taxCode: boolean,
  accountAssignmentCategory: boolean,
  glAccount: boolean,
  costCenter: boolean,
  scope: boolean
}

function hasContract(mrp: IMrpForm) {
  return !isNil(mrp.contractNumber);
}

const validateFloat13 = (float: string) => /^[0-9]{1,13}(\.[0-9]{1,2})?$/.test(float);
const validateFloat9 = (float: string) => /^[0-9]{1,9}(\.[0-9]{1,2})?$/.test(float);

export const buildMrpErrors = (mrp?: IMrpForm): IMrpFormErrors => {
  return {
    supplierId: mrp ? !mrp.supplierId : false,
    supplierPartId: mrp ? !mrp.supplierPartId : false,
    purchasingOrgCode: mrp ? !mrp.purchasingOrgCode : false,
    companyCode: mrp ? !mrp.companyCode : false,
    plantCode: mrp ? !mrp.plantCode : false,
    incoTerms: mrp && hasContract(mrp) ? !mrp.incoTerm : false,
    purchasingGroup: mrp && hasContract(mrp) ? !mrp.purchasingGroup : false,
    contractStartValidityDate: mrp && hasContract(mrp) ? !mrp.contractStartValidityDate : false,
    contractEndValidityDate: mrp && hasContract(mrp) ? !mrp.contractEndValidityDate : false,
    priceValidityStartDate: mrp && hasContract(mrp) ? !mrp.priceInfo.validityStartDate : false,
    priceValidityEndDate: mrp && hasContract(mrp) ? !mrp.priceInfo.validityEndDate : false,
    targetValue: mrp ? !mrp.targetValue || !validateFloat13(mrp.targetValue): mrp && !(mrp.targetValue == "" || validateFloat13(mrp.targetValue)),
    currency: mrp ? !mrp.priceInfo.currency : false,
    price: mrp && hasContract(mrp) ? !mrp.priceInfo.price || !validateFloat9(mrp.priceInfo.price): mrp && !(mrp.priceInfo.price == "" || validateFloat9(mrp.priceInfo.price)),
    pricePer: mrp && hasContract(mrp) ? !mrp.priceInfo.pricePer : false,
    taxCode: mrp && hasContract(mrp) ? !mrp.taxCode : false,
    catalogType: mrp ? isNil(mrp.catalogType) : false,
    scope: mrp ? isNil(mrp.scope) : false,
    accountAssignmentCategory: mrp ? isNil(mrp.accountAssignmentCategory) : false,
    glAccount: (mrp && mrp.accountAssignmentCategory === MrpAccountAssignmentTypes.TYPE_K) ? isNil(mrp.glAccount) : false,
    costCenter: (mrp && mrp.accountAssignmentCategory === MrpAccountAssignmentTypes.TYPE_K) ? isNil(mrp.costCenter) : false
  }
};

export const buildMrp = (mrp?: Mrp): IMrpForm => {

  return {
    id: mrp?.id || null,
    supplierId: mrp?.supplierId || null,
    supplierName: mrp?.supplierName || null,
    supplierPartId: mrp?.supplierPartId || null,
    supplierPartIdDescription: mrp?.supplierPartIdDescription || null,
    manufacturePart: mrp?.manufacturePart || null,
    paymentTerms: mrp?.paymentTerms || null,
    paymentTermsDesc: mrp?.paymentTermsDesc || null,
    upc: mrp?.upc || null,
    materialDescription: mrp?.materialDescription || null,
    materialGroup: mrp?.materialGroup || null,
    materialNumber: mrp?.materialNumber || null,
    unspsc: mrp?.unspsc || null,
    taxCode: mrp?.taxCode || null,
    taxCodeDesc: mrp?.taxCodeDesc || null,
    purchasingOrgCode: mrp?.purchasingOrgCode || null,
    purchasingOrgName: mrp?.purchasingOrgName || null,
    companyCode: mrp?.companyCode || null,
    companyName: mrp?.companyName || null,
    plantCode: mrp?.plantCode || null,
    plantName: mrp?.plantName || null,
    catalogName: mrp?.catalogName || null,
    buyerEmail: mrp?.buyerEmail || null,
    additionalContacts: mrp?.additionalContacts || null,
    catalogType: !isNil(mrp?.catalogType) ? parseInt(mrp.catalogType as any) : null,
    incoTerm: mrp?.incoTerm || null,
    incoTermDesc: mrp?.incoTermDesc || null,
    signedAgreementNumber: mrp?.signedAgreementNumber || null,
    contractNumber: mrp?.contractNumber || null,
    accountAssignmentCategory: mrp?.accountAssignmentCategory || "",
    glAccount: mrp?.glAccount || null,
    glAccountDescription: mrp?.glAccountDescription || null,
    costCenter: mrp?.costCenter || null,
    costCenterDescription: mrp?.costCenterDescription || null,
    scope: mrp?.scope || null,
    plannedDeliveryTime: mrp?.plannedDeliveryTime?.toString() || "",
    requestId:mrp?.requestId,
    priceInfo: {
      id: mrp?.priceInfo?.id || null,
      supplierId: mrp?.priceInfo?.supplierId || "",
      supplierPartId: mrp?.priceInfo?.supplierPartId || "",
      price: mrp?.priceInfo?.price || null,
      pricePer: mrp?.priceInfo?.pricePer || 1,
      priceGrid: mrp?.priceInfo?.priceGrid || [],
      currency: mrp?.priceInfo?.currency || null,
      quantity: mrp?.priceInfo?.quantity || null,
      unitOfMeasure: mrp?.priceInfo?.unitOfMeasure || "",
      itemDescription: mrp?.priceInfo?.itemDescription || null,
      fullDescription: mrp?.priceInfo?.fullDescription || null,
      errorComment: mrp?.priceInfo?.errorComment || null,
      catalogName: mrp?.priceInfo?.catalogName || null,
      validityStartDate: mrp?.priceInfo?.validityStartDate ? DateTime.fromFormat(mrp.priceInfo.validityStartDate.split(".")[0], "yyyy'-'MM'-'dd'T'HH':'mm':'ss") : DateTime.local(),
      validityEndDate: mrp?.priceInfo?.validityEndDate ? DateTime.fromFormat(mrp.priceInfo.validityEndDate.split(".")[0], "yyyy'-'MM'-'dd'T'HH':'mm':'ss") : DateTime.fromFormat("12/31/9999", "MM/dd/yyyy"),
      validityEndDateModifiedFlag: false
    },
    contractStartValidityDate: mrp?.contractStartValidityDate ? DateTime.fromFormat(mrp.contractStartValidityDate.split(".")[0], "yyyy'-'MM'-'dd'T'HH':'mm':'ss") : DateTime.local(),
    contractEndValidityDate: mrp?.contractEndValidityDate ? DateTime.fromFormat(mrp.contractEndValidityDate.split(".")[0], "yyyy'-'MM'-'dd'T'HH':'mm':'ss") : DateTime.fromFormat("12/31/9999", "MM/dd/yyyy"),
    targetValue: mrp?.targetValue || "999999999.00",
    purchasingGroup: mrp?.purchasingGroup || "ZZZ",
    // status: mrp?.status? mrp.status === 1 ? "Factory validated" : "" : ""
    status: mrp?.status || 0,
    spendCategory: mrp?.spendCategory || "",
    contractLanguage: mrp?.contractLanguage || "",
    priceListLink: mrp?.priceListLink || '',
  }
};
