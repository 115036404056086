import { template } from 'lodash';
import * as R from 'ramda';
import { TCode } from 'src/app/docFlows/domains/documentFlow/documentFlow';
import { ebRealm, ebUrl, sapUrl } from "src/app/shared/utils/urlBuilders";

export const PAAribaTemplate = template(
  "https://<%=prefix%>.ariba.com/Buyer/Main/ad/webjumper?realm=<%=realm%>&itemUniqueName=<%=pr%>&itemClassName=ariba.purchasing.core.Requisition"
);
const ViewPRSAPTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=P_BANFN%3d<%=pr%>%26&AutoStart=true"
);

export enum PRsPAColumns {
  CHAT = 'CHAT',
  SAP_PR_NO = 'sap_pr_no',
  ARIBA_PR_NO = 'ariba_pr_no',
  REQUESTOR_NAME = 'requestor_name',
  PR_CREATOR = 'pr_creator',
  APPROVAL_TYPES = 'approval_types',
  TOTAL_AMMOUNT = 'total_ammount',
  CURRENCY = 'currency',
  COMPANY_CODE = 'company_code',
  VENDOR = 'vendor',
  PURCHASING_GROUP = 'purchasing_group',
  EXTERNAL_REQUEST_NO = 'ExtReqNo',
  ACTIONS = 'actions',
}

export interface NetworkPRDTO {
  __metadata: { id: string; type: string; uri: string };
  AribaPR: PRNumber,
  ApprovalType: string,
  SapPR: string,
  ItemNo: string,
  ApprovalLevel: string,
  MaterialGroup: string,
  MaterialGroupDesc: string,
  MaterialNo: string,
  MaterialDesc: string,
  RequsitionQty: string,
  RequisitionUoM: string,
  PriceInPR: string,
  CurrencyKey: string,
  FixedVendor: string,
  FixedVendorName: string,
  ExtReqNo:string,
  DelvDate: string,
  PurchaseGroup: string,
  PurchGrpDesc: string,
  Plant: string,
  PlantName: string,
  PrCreator:string,
  AgentID: string,
  CompCode: string,
  PRLines: string,
  PurchOrg: string,
  PurchOrgDesc: string,
  RelStrategy: string,
  Requestor: string,
  RelGroup: string,
  Comments: string,
  ApprovalIndicator: string,
  PRCreateDate?: string,
  SearchTerm: string,
  SystemAlias: string,
  TotAmt: string,
  CompCodeDescr: string,
  SAP__Origin: string,
  PASapPrLink: string,
  PAAribaLink: string,
  PRAttachmentFlag: string,
}

export type PRNumber = string;

export class PurchaseRequest {
  constructor(
    public readonly AribaPR: PRNumber,
    public readonly ApprovalType: string,
    public readonly SapPR: string,
    public readonly ItemNo: string,
    public readonly ApprovalLevel: string,
    public readonly MaterialGroup: string,
    public readonly MaterialGroupDesc: string,
    public readonly MaterialNo: string,
    public readonly MaterialDesc: string,
    public readonly RequsitionQty: string,
    public readonly RequisitionUoM: string,
    public readonly PriceInPR: string,
    public readonly CurrencyKey: string,
    public readonly FixedVendor: string,
    public readonly FixedVendorName: string,
    public readonly ExtReqNo:string,
    public readonly DelvDate: string,
    public readonly PurchaseGroup: string,
    public readonly PurchGrpDesc: string,
    public readonly Plant: string,
    public readonly PlantName: string,
    public readonly PrCreator: string,
    public readonly AgentID: string,
    public readonly CompCode: string,
    public readonly PRLines: string,
    public readonly PurchOrg: string,
    public readonly PurchOrgDesc: string,
    public readonly RelStrategy: string,
    public readonly Requestor: string,
    public readonly RelGroup: string,
    public readonly Comments: string,
    public readonly ApprovalIndicator: string,
    public readonly PRCreateDate: string,
    public readonly SearchTerm: string,
    public readonly SystemAlias: string,
    public readonly TotAmt: string,
    public readonly CompCodeDescr: string,
    public readonly SAP__Origin: string,
    public readonly PRAttachmentFlag: string,
    public readonly PASapPrLink: string,
    public readonly PAAribaLink,
  ) { }

  static FromBackend(data: NetworkPRDTO): PurchaseRequest {
    const ebPrefix = ebUrl(process.env.REACT_APP_ENV.trim());
    const realm = ebRealm(process.env.REACT_APP_ENV.trim());
    const ebPrefixed = R.mergeRight({ prefix: ebPrefix, realm: realm });
    const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());

    const sapPrefixed = R.mergeRight({ prefix: sapPrefix, system: data.SystemAlias });

    const PAAribaLink = PAAribaTemplate(ebPrefixed({ pr: data.AribaPR }));
    const PASapPrLink = ViewPRSAPTemplate(sapPrefixed({ pr: data.SapPR, code: TCode.PR_DISPLAY }));

    return new PurchaseRequest(
      data.AribaPR,
      data.ApprovalType,
      data.SapPR,
      data.ItemNo,
      data.ApprovalLevel,
      data.MaterialGroup,
      data.MaterialGroupDesc,
      data.MaterialNo,
      data.MaterialDesc,
      data.RequsitionQty,
      data.RequisitionUoM,
      data.PriceInPR,
      data.CurrencyKey,
      data.FixedVendor,
      data.FixedVendorName,
      data.ExtReqNo,
      data.DelvDate,
      data.PurchaseGroup,
      data.PurchGrpDesc,
      data.Plant,
      data.PlantName,
      data.PrCreator,
      data.AgentID,
      data.CompCode,
      data.PRLines,
      data.PurchOrg,
      data.PurchOrgDesc,
      data.RelStrategy,
      data.Requestor,
      data.RelGroup,
      data.Comments,
      data.ApprovalIndicator,
      data.PRCreateDate,
      data.SearchTerm,
      data.SystemAlias,
      data.TotAmt,
      data.CompCodeDescr,
      data.SAP__Origin,
      data.PRAttachmentFlag,
      PASapPrLink,
      PAAribaLink
    )
  }

  static GetPRsPAColumns(useDefault = false): { id: PRsPAColumns, show: boolean, hidden: boolean }[] {
    const userCols = [
      { id: PRsPAColumns.CHAT, show: true, hidden: false },
      { id: PRsPAColumns.SAP_PR_NO, show: true, hidden: false },
      { id: PRsPAColumns.ARIBA_PR_NO, show: true, hidden: false },
      { id: PRsPAColumns.EXTERNAL_REQUEST_NO, show: true, hidden: false },
      { id: PRsPAColumns.PURCHASING_GROUP, show: true, hidden: false },
      { id: PRsPAColumns.REQUESTOR_NAME, show: true, hidden: false },
      { id: PRsPAColumns.PR_CREATOR, show: true, hidden: false },
      { id: PRsPAColumns.APPROVAL_TYPES, show: true, hidden: false },
      { id: PRsPAColumns.TOTAL_AMMOUNT, show: true, hidden: false },
      { id: PRsPAColumns.CURRENCY, show: true, hidden: false },
      { id: PRsPAColumns.COMPANY_CODE, show: true, hidden: false },
      { id: PRsPAColumns.VENDOR, show: true, hidden: false },
      { id: PRsPAColumns.ACTIONS, show: true, hidden: false },
    ];

    const columns = localStorage.getItem('PRsPA_table_config');
    if (!useDefault) {
      if (columns) {
        return JSON.parse(columns) as { id: PRsPAColumns, show: boolean, hidden: boolean }[];
      }
    }
    return userCols;
  }
  static SetPRsPAColumns(columns: { id: PRsPAColumns, show: boolean, hidden: boolean }[]): void {
    localStorage.setItem('PRsPA_table_config', JSON.stringify(columns));
  }
}

export interface NewPurchaseRequest extends PurchaseRequest {
  positionId: number;
}
