import {LogicGate, Operators, OrderDirection, SimpleODataFilter, SimpleODataOrder} from "../../../lib/odata";
import * as R from "ramda";
import {Dictionary} from "ramda";
import {FilterCategoryView, Filters, TextFilter} from "../domains/query/filter";
import _ from "lodash";

export const DEFAULT_PAGE_SIZE = 10;

export interface Query {
  resource?: string,
  page: number,
  orderBy: SimpleODataOrder[],
  filters: Dictionary<FilterCategoryView>,
  loading: boolean,
  loaded: boolean,
  term: string,
  top: number,
  rows: number
}

export const newQuery = (defaultFilters: Dictionary<FilterCategoryView> = {}): Query => {
  return {
    page: 0,
    orderBy: [],
    filters: defaultFilters,
    loading: false,
    loaded: false,
    term: "",
    top: DEFAULT_PAGE_SIZE,
    rows: DEFAULT_PAGE_SIZE
  };
};

export function setSelectedFiltersBykey(key: string, selectedItems: Filters, initial: Dictionary<FilterCategoryView>) {
  return R.assocPath([key, 'selected'], selectedItems, initial);
}

export function newPage(query: Query, newPage: number): Query {
  return R.assoc("page", newPage, query);
}

export function newPageRows(query: Query, newPageRows: number): Query {
  let newQuery =  R.assoc("page", 0, query);
  return  R.assoc("rows", newPageRows, newQuery);
}

export function newPageSize(query: Query, newPageSize: number): Query {
  return R.assoc("top", newPageSize, query);
}

export function updateOrder(query: Query, field: string, direction: OrderDirection): Query {
  return R.assoc("orderBy", [{field, direction}], query);
}

export function updateFilters(query: Query, filters: Dictionary<FilterCategoryView>): Query {
  return R.assoc("filters", filters, {...query, page: 0});
}

export function resetFiltersForQuery(query: Query, initialFilters: Dictionary<FilterCategoryView> = null): Query {
  const withoutSelectedFilters = R.mapObjIndexed(value => R.assoc('selected', [], value), query.filters);
  const newWithInitialFilters = R.mergeDeepWithKey((k, l, r) => k === 'data' ? r : l, initialFilters, withoutSelectedFilters);
  return R.assoc('filters', newWithInitialFilters, query);
}

export function setSelectedFilter(query: Query, filter: TextFilter, key: string): Query {
  const newFilters = [filter];
  return R.assocPath(['filters', key, 'selected'], newFilters, query);
}

export function resetFiltersForKey(query: Query, key: string): Query {
  return R.assocPath(['filters', key, 'selected'], [], query);
}

export function deleteSelectedFilter(query: Query, filter): Query {

  // for feedback createdon date range filter

  if(filter.endDate && filter.startDate && filter.type && query.filters.CreatedOn){
    let queryCopy = _.cloneDeep(query);
    queryCopy.filters.CreatedOn.selected = [];
    return queryCopy;
  }

  // for psl validation and creation filter

  if(filter.endDate && filter.startDate && filter.type && query.filters.creationAndValidityDate){
    let queryCopy = _.cloneDeep(query);
    queryCopy.filters.creationAndValidityDate.selected = [];
    return queryCopy;
  }

  const filterCond = R.cond([
    [
      R.compose((i)=>!R.isNil(i) && typeof i === "string"), (i) => i === filter.id ? null : i
    ],
    [
      R.compose((i)=>!R.isNil(i) && i.length), R.reject(R.propEq('id', filter.id))
    ],
    [
      R.T, (value) => value
    ]
  ]);
  const filtersNotNull = R.filter(value => value !== null, query.filters)
  const withoutDeletedFilters = R.mapObjIndexed(value => {
      if (value.selected === undefined) {
        return value
      }
      return R.assoc('selected', filterCond(value.selected), value);
    },
    filtersNotNull
  );
  return R.assoc('filters', withoutDeletedFilters, {...query, page: 0});
}

export function updateTerm(query: Query, term: string): Query {
  return newPage(R.assoc("term", term, query), 0);
}

export function updateTermI(query: Query, term: string): Query {
  return newPage(R.assoc("term", term, query), 0);
}

export function toODataFilter2(fcv: FilterCategoryView, op?: Operators, nestedField: string = undefined): {
  operator:   LogicGate,
  filters: SimpleODataFilter[]
} {

  if (Array.isArray(fcv.selected)) {
    const filters = fcv.selected.map(value => ({
      field: fcv.key,
      value: value['id']
    }));
    return {
      operator: LogicGate.OR,
      filters
    }
  } else if (fcv.selected != null) {
    const value = R.prop('value', <any>fcv.selected);
    if (value) {
      const filters = [
        {
          field: fcv.key,
          value: value,
          operator: op,
          nestedField: nestedField
        }
      ];
      return {
        operator: LogicGate.OR,
        filters
      }
      // priceFromTo
    } else if (!!R.prop('priceFrom', <any>fcv.selected) || !!R.prop('priceTo', <any>fcv.selected)) {
      const from = R.prop('priceFrom', <any>fcv.selected);
      const to = R.prop('priceTo', <any>fcv.selected);
      let result = []

      if (from) {
        result.push({
          field: fcv.key,
          value: from,
          operator: Operators.GE
        })
      }
      if (to) {
        result.push({
          field: fcv.key,
          value: to,
          operator: Operators.LE
        })
      }
      return {
        operator: LogicGate.AND,
        filters: result
      }
    } else {
      const startDate = R.prop('startDate', <any>fcv.selected);
      const endDate = R.prop('endDate', <any>fcv.selected);
      if (startDate == null || endDate == null) return {
        filters: [],
        operator: LogicGate.AND
      }

      const filters = [
        {
          field: fcv.key,
          value: startDate,
          operator: Operators.GE
        },
        {
          field: fcv.key,
          value: endDate,
          operator: Operators.LE
        },
      ]

      return {
        operator: LogicGate.OR,
        filters
      }
    }
  } else {
    return {
      filters: [],
      operator: LogicGate.AND
    }
  }
}

export function toODataFilter(fcv: FilterCategoryView, op?: Operators, nestedField: string = undefined): SimpleODataFilter[] {
  if (Array.isArray(fcv.selected)) {
    return fcv.selected.map(value => ({
      field: fcv.key,
      value: value['id']
    }));
  } else if (fcv.selected != null) {
    const value = R.prop('value', <any>fcv.selected);
    if (value) {
      return [
        {
          field: fcv.key,
          value: value,
          operator: op,
          nestedField: nestedField
        }
      ];
    } else if (!!R.prop('rangeFrom', <any>fcv.selected) || !!R.prop('rangeTo', <any>fcv.selected)) {
      const from = R.prop('rangeFrom', <any>fcv.selected);
      const to = R.prop('rangeTo', <any>fcv.selected);
      let result = []

      if (from) {
        result.push({
          field: fcv.key,
          value: from,
          operator: Operators.GE
        })
      }
      if (to) {
        result.push({
          field: fcv.key,
          value: to,
          operator: Operators.LE
        })
      }
      return result
    } else {
      const startDate = R.prop('startDate', <any>fcv.selected);
      const endDate = R.prop('endDate', <any>fcv.selected);
      if (startDate == null || endDate == null) return [];

      return [
        {
          field: fcv.key,
          value: startDate,
          operator: Operators.GE
        },
        {
          field: fcv.key,
          value: endDate,
          operator: Operators.LE
        },
      ]
    }
  } else {
    return []
  }
}
