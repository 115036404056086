import * as R from 'ramda';
import {IAction} from "../../../shared/domains/core/actions";
import {PersonalNoteActions} from "../actions/invoicePersonalNote.actions";
import {error, loading} from "../../../shared/reducers/utils";

export interface IPersonalNoteState {
  notes: {
    [key:string]: {
      note:string;
      loading:boolean;
    };
  };
}

const initialState = (): IPersonalNoteState => {
  return {
    notes: {},
  }
};

export function generateInvoiceItemId(invoiceNumber, systemAlias) {
  return `${invoiceNumber}-${systemAlias}`
}


export function InvoicePersonalNoteReducer(state: IPersonalNoteState = initialState(), action: IAction<PersonalNoteActions> ): IPersonalNoteState {
  switch (action.type) {
     case PersonalNoteActions.GET_INVOICE_NOTE_REQUEST:
      return R.mergeRight(state, {
          notes:{
            ...state.notes,
            [generateInvoiceItemId(action.invoiceNumber, action.systemAlias)]: {
              note: "",
              loading:true
            }}
        }
      );
    case PersonalNoteActions.GET_INVOICE_NOTE_REQUEST_SUCCESS:
      return R.mergeRight(state, {
        notes:{
          ...state.notes,
          [generateInvoiceItemId(action.invoiceNumber, action.systemAlias)]: {
            note: action.note,
            loading:false
          }}
      });
    case PersonalNoteActions.SAVE_INVOICE_NOTE_REQUEST:
      return R.mergeRight(state, {
        notes:{
          ...state.notes,
          [generateInvoiceItemId(action.invoiceNumber, action.systemAlias)]: {
            note: action.note,
            loading:true
          }}
      });
    case PersonalNoteActions.SAVE_INVOICE_NOTE_REQUEST_SUCCESS:
      return R.mergeRight(state, {
        notes:{
          ...state.notes,
          [generateInvoiceItemId(action.invoiceNumber, action.systemAlias)]: {
            note: action.note,
            loading:false
          }}
      });
    case PersonalNoteActions.GET_INVOICE_NOTE_REQUEST_FAILURE:
    case PersonalNoteActions.SAVE_INVOICE_NOTE_REQUEST_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}
