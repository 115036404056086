import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {push} from "connected-react-router";
import {isEmpty} from "ramda";
import {
  ApproveMRP,
  ApproveMRPError,
  ApproveMRPSuccess,
  ContractActionTypes,
  DeleteMrpContract,
  DeleteMrpContractError,
  DeleteMrpContractSuccess,
  FetchContractsSuccess,
  FetchOriginalMrpContracts,
  FetchOriginalMrpContractsError,
  FetchOriginalMrpContractsSuccess,
  FetchProcSpecs,
  FetchProcSpecsError,
  FetchProcSpecsSuccess,
  RejectMRP,
  RejectMRPError,
  RejectMRPSuccess,
  SubmitMRP,
  SubmitMRPError,
  SubmitMRPForApproval,
  SubmitMRPForApprovalError,
  SubmitMRPForApprovalSuccess,
  UpdateMrp,
  UpdateMrpError,
  UpdateMrpSuccess
} from "../../actions/contract.actions";
import {SharedStateStore} from "../../../shared/reducers";
import {ContractService} from "../../domains/contract/contract.service";
import {ContractType, DraftContractSource} from "../../domains/contract/contract";
import {ContractsStateStore} from "../../reducers";
import {newContractQuery, updateFilters} from "../../domains/contract/query";
import {initialContractFilters} from "../../reducers/filters/contract.filters.reducer";
import {ItemService} from "../../domains/item/item.service";

function* fetchProcSpecs(action: FetchProcSpecs) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    const result = yield call(ContractService.fetchProcSpecs, action.companyCode, action.purchOrg);
    result.unshift({UserId: 'PACHACONMI'}, {UserId: 'PLBORKOWMA1'}, {UserId: 'PUBEDDINNI'}, {UserId: 'PURIVALO'});
    if (process.env.REACT_APP_ENV.trim() === "dev") {
      result.unshift({UserId: user.accountName});
    }
    yield put(new FetchProcSpecsSuccess(result));
  } catch (error) {
    yield put(new FetchProcSpecsError(error));
  }
}

function* fetchMrpOriginalContracts(action: FetchContractsSuccess) {
  try {
    const {contractType, draftContractSource} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts);
    if (action.items.length > 0 && contractType === ContractType.DRAFT && draftContractSource === DraftContractSource.CHANGES_REQUESTS) {
      yield put(new FetchOriginalMrpContracts());
      const agreementNoList = action.items.map(c => c.AgreementNo);
      const query = updateFilters(newContractQuery(), initialContractFilters, agreementNoList);
      const result = yield call(ContractService.fetch, query);
      yield put(new FetchOriginalMrpContractsSuccess(result.data));
    }
  } catch (error) {
    yield put(new FetchOriginalMrpContractsError(error));
  }
}

function* submitMRP(action: SubmitMRP) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    yield call(ContractService.submitMRP, action.contract, user);
    yield put(push(`/contracts?type=${ContractType.MRP}`));
  } catch (error) {
    yield put(new SubmitMRPError(action.contract, error));
  }
}

function* submitMRPForApproval(action: SubmitMRPForApproval) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    const query = updateFilters(newContractQuery(), initialContractFilters, [action.contract.AgreementNo]);
    let results = yield call(ContractService.fetch, query);
    let originalContract = action.contract;
    if (!isEmpty(results.data)) {
      originalContract = results.data[0];
    }
    results = yield all([
      call(ItemService.fetchMrpItems, action.contract.AgreementNo, action.contract.SystemAlias),
      call(ItemService.fetchItems, action.contract.AgreementNo, action.contract.SystemAlias)
    ]);
    yield call(ContractService.submitMRPForApproval, action.contract, originalContract, results[0].data, results[1].data, action.approver, user);
    yield put(new SubmitMRPForApprovalSuccess(action.contract, `Contract ${action.contract.AgreementNo} has been submitted to ${action.approver} for review`));
    if (action.done) {
      action.done();
    }
  } catch (error) {
    yield put(new SubmitMRPForApprovalError(error));
  }
}

function* approveMRP(action: ApproveMRP) {
  try {
    yield call(ContractService.approveMrp, action.contract);
    yield put(new ApproveMRPSuccess(action.contract, `Contract ${action.contract.AgreementNo} has been approved`));
    if (action.done) {
      action.done();
    }
  } catch (error) {
    yield put(new ApproveMRPError(error));
  }
}

function* rejectMRP(action: RejectMRP) {
  try {
    yield call(ContractService.rejectMrp, action.contract, action.reason);
    yield put(new RejectMRPSuccess(action.contract, `Contract ${action.contract.AgreementNo} has been rejected`));
    if (action.done) {
      action.done();
    }
  } catch (error) {
    yield put(new RejectMRPError(error));
  }
}

function* updateMRP(action: UpdateMrp) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    yield call(ContractService.updateMrp, action.contract, user);
    yield put(new UpdateMrpSuccess(action.executor, action.contract));
  } catch (error) {
    yield put(new UpdateMrpError(action.executor, error));
  }
}

function* deleteMrp(action: DeleteMrpContract) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    yield call(ContractService.deleteMrp, action.contract, user);
    yield put(new DeleteMrpContractSuccess(action.contract, `MRP deleted successfully.`));
  } catch (error) {
    yield put(new DeleteMrpContractError(error));
  }
}

function* watchFetchProcSpecs() {
  yield takeLatest(ContractActionTypes.FETCH_PROC_SPECS, fetchProcSpecs);
}

function* watchDeleteMrp() {
  yield takeLatest(ContractActionTypes.DELETE_MRP, deleteMrp);
}

function* watchSubmitMRP() {
  yield takeLatest(ContractActionTypes.SUBMIT_MRP, submitMRP);
}

function* watchSubmitMRPForApproval() {
  yield takeLatest(ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL, submitMRPForApproval);
}

function* watchApproveMRP() {
  yield takeLatest(ContractActionTypes.APPROVE_MRP, approveMRP);
}

function* watchRejectMRP() {
  yield takeLatest(ContractActionTypes.REJECT_MRP, rejectMRP);
}

function* watchUpdateMRP() {
  yield takeLatest(ContractActionTypes.UPDATE_MRP, updateMRP);
}

function* watchFetchOriginalMrpContracts() {
  yield takeLatest(ContractActionTypes.FETCH_CONTRACTS_SUCCESS, fetchMrpOriginalContracts);
}

export default function* mrpContractSaga() {
  yield all([
    fork(watchFetchProcSpecs),
    fork(watchFetchOriginalMrpContracts),
    fork(watchSubmitMRP),
    fork(watchSubmitMRPForApproval),
    fork(watchApproveMRP),
    fork(watchRejectMRP),
    fork(watchUpdateMRP),
    fork(watchDeleteMrp)
  ])
};
