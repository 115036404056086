import * as R from "ramda";
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { GetFiltersError, GetFiltersSuccess } from "../actions/baFilters";
import { BAFilterService } from "../domains/filter.service";
import { BAActionTypes } from "../actions/baFilters";
import { PataflagService } from "../../../shared/services/pataflag.service";
import { Features } from "../../../shared/domains/core/pataflag";
import { CompanyData } from "src/app/shared/domains/master/company";
import { SharedStateStore } from "src/app/shared/reducers";
import { MasterService } from "src/app/shared/domains/master/master.service";


function* fetch(): any {
  try {
    const {currentUser} = yield select(({shared}: { shared: SharedStateStore }) => shared.user);
    let companiesx: CompanyData[] = yield call(MasterService.fetchCompanyCodeBySystemAlias, null, !R.isEmpty(currentUser.defaultSystemAlias) ? currentUser.defaultSystemAlias[0] : '');
    const mapper = value => ({ id: value.CompCode, title: value.CompCodeDesc });
    const companies = yield companiesx.map(mapper);
    yield put(new GetFiltersSuccess({
      companies,
    }));
  } catch (error) {
    yield put(new GetFiltersError(error));
  }
}

function* watchGetFilters() {
  yield takeLatest(BAActionTypes.BA_GET_FILTERS, fetch);
}

export default function* filterSaga() {
  yield all([
    fork(watchGetFilters),
  ])
};
