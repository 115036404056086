export interface NetworkScalePriceDTO {
  __metadata: { id: string; type: string; uri: string };
  IdNo: string;
  AgreementNo: string;
  ItemNo: string;
  ConditionRecord: string;
  ConditionItem: string;
  LineNo: string;
  CondValue: string;
  ScaleQuantity: string;
}

export class ScalePrice {
  constructor(
    public readonly IdNo: string,
    public readonly AgreementNo: string,
    public readonly ItemNo: string,
    public readonly ConditionRecord: string,
    public readonly ConditionItem: string,
    public readonly LineNo: string,
    public readonly CondValue: string,
    public readonly ScaleQuantity: string
  ) {
    this.CondValue = this.CondValue.trim();
    this.ScaleQuantity = this.ScaleQuantity.trim();
  }

  static FromBackend(data: NetworkScalePriceDTO): ScalePrice {
    return new ScalePrice(
      data.IdNo,
      data.AgreementNo,
      data.ItemNo,
      data.ConditionRecord,
      data.ConditionItem,
      data.LineNo,
      data.CondValue,
      data.ScaleQuantity
    )
  }
}
