import * as R from 'ramda';
import {IdNo} from "../../contracts/domains/contract/contract.service";
import {IPurchaseNos} from "./shortCompetitiveBidding";
import {isNumeric} from "rxjs/internal-compatibility";

export enum CbStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  NoNeeded = 4
}

export interface VendorDTO {
  vendorCode: string;
  vendorName: string;
  total: string;
  currency: string;
  equivalentTotal: string;
  equivalentCurrency: string;
  paymentTermsDays: string;
  attachedQuotation: string[];
  comments: string;
}

export interface CBComplianceQuery {
  selectedSupplierCode: string;
  numberOfFilledSuppliers: number;
  companyCode: string;
  spendCatLevel3Code: string;
  value: number;
  currency: string;
}

export interface ISpendCategory {
  categoryCode: string;
  categoryName: string;
  subcategoryCode: string;
  subcategoryName: string;
  productCategoryCode: string;
  productCategoryName: string;
}


export interface CBNetworkDTO {
  idNo: IdNo;
  id: string;
  spendCategories: ISpendCategory[];
  companyCode: string;
  companyName: string;
  purchaseDescription: string;
  purchaseReqNos: IPurchaseNos[];
  purchaseOrderNos: IPurchaseNos[];

  validFrom: string;
  validTo: string;

  vendors: VendorDTO[];

  selectedSupplierCode: string;
  exceptionJustification: string;

  cbStatusId: number;

  creatorUserName: string;
  creatorUserEmail: string;
  createdOn: string;
  isBestPrice: boolean;
  isBestQuality: boolean;
  isBestDelivery: boolean;
  isException: boolean;
}

export class Vendor {
  constructor(
    public VendorCode: any = "",
    public VendorName: any = "",
    public Total: string = "",
    public Currency: string = "",
    public EquivalentTotal: string = "",
    public EquivalentCurrency: string = "",
    public PaymentTermsDays: string = "",
    public AttachedQuotation: string[] = [],
    public Comments: string = ""
  ) {}

  static validate(data: any): boolean {
    const either = R.reduce(<any>R.either, R.isNil)([
      R.prop('VendorCode'),
      R.prop('Total'),
      R.prop('Currency'),
      R.prop('AttachedQuotation')
    ]);

    if (!!either(data)) {
      return !!R.prop('VendorCode')(data) &&
        !!R.prop('Total')(data) &&
        !!R.prop('Currency')(data) &&
        !!R.prop('AttachedQuotation')(data);
    }

    return true;
  }

  static isEmpty(data: any): boolean {
    const either = R.reduce(<any>R.either, R.isNil)([
      R.prop('VendorCode'),
      R.prop('Total'),
      R.prop('Currency'),
      R.prop('AttachedQuotation')
    ]);
    return !either(data);
  }

  static isTouched(data: any): boolean {
    const either = R.reduce(<any>R.either, R.isNil)([
      R.prop('VendorCode'),
      R.prop('Total'),
      R.prop('Currency'),
      R.prop('EquivalentTotal'),
      R.prop('PaymentTermsDays'),
      R.prop('AttachedQuotation'),
    ]);
    return !either(data);
  }

  static FromBackend(data: VendorDTO): Vendor {
    return new Vendor(
      data.vendorCode,
      data.vendorName,
      data.total,
      data.currency,
      data.equivalentTotal,
      data.equivalentCurrency,
      data.paymentTermsDays,
      Array.isArray(data.attachedQuotation) ? data.attachedQuotation : [data.attachedQuotation],
      data.comments
    )
  }

  static empty(): Vendor {
    return new Vendor();
  }
}

export interface ChangeStatusData {
  competitiveBiddingId: string;
  statusId: number;
  comment: string;
}

export class CompetitiveBiddingData {
  constructor(
    public PurchaseReqNos: IPurchaseNos[] = [],
    public PurchaseOrderNos: IPurchaseNos[] = [],
    public Id: string = "",
    public spendCategories: ISpendCategory[] = [{
      categoryCode: "",
      categoryName: "",
      subcategoryCode: "",
      subcategoryName: "",
      productCategoryCode: "",
      productCategoryName: ""
    }],
    public CompanyCode: string = "",
    public PurchaseDescription: string = "",
    public Vendors: Vendor[] = [Vendor.empty(), Vendor.empty(), Vendor.empty()],
    public SelectedSupplier: string = "",
    public isBestPrice: boolean = false,
    public isBestQuality: boolean = false,
    public isBestDelivery: boolean = false,
    public isException: boolean = false,
    public ExceptionJustification: string = "",
    public ValidFrom: any = new Date(),
    public ValidTo: any = null,
    public CreatedOn: any = null,
    public CbStatusId: number = CbStatus.Pending,

    public CompanyName: string = "",
    public CreatorUserName: string = "",
    public CreatorUserEmail: string = ""
  ) {
  }

  static empty(CompanyCode: string = null): CompetitiveBiddingData {
    return new CompetitiveBiddingData([], [], "", [{
      categoryCode: "",
      categoryName: "",
      subcategoryCode: "",
      subcategoryName: "",
      productCategoryCode: "",
      productCategoryName: ""
    }], CompanyCode);
  }

  static StatuesFilterable() {
    return R.keys(CbStatus).filter((k => !isNumeric(k))).filter(key => key !== "NoNeeded")
      .map(key => ({
        id: CbStatus[key],
        title: key
      }))
  }

  static FromBackend(data: CBNetworkDTO): CompetitiveBiddingData {
    return new CompetitiveBiddingData(
      data.purchaseReqNos,
      data.purchaseOrderNos,
      data.id.toString(),

      data.spendCategories,
      data.companyCode,
      data.purchaseDescription,

      data.vendors.map(Vendor.FromBackend),

      R.findIndex(R.propEq('vendorCode', data.selectedSupplierCode), data.vendors).toString(),
      data.isBestPrice,
      data.isBestQuality,
      data.isBestDelivery,
      data.isException,
      data.exceptionJustification || "",

      data.validFrom,
      data.validTo,
      data.createdOn,
      data.cbStatusId,

      data.companyName,
      data.creatorUserName,
      data.creatorUserEmail
    )
  }
}

export enum QueryableListEnum {
  ActiveCompetitiveBiddingList = 'ActiveCompetitiveBiddingList',
  CompetitiveBiddingList = 'CompetitiveBiddingList',
}
