import * as R from "ramda";
import {all, fork, put, takeLatest} from 'redux-saga/effects';
import {QueryFeedbackActions} from "../../admin/reducers/feedback.reducer";
import {UserFeedback} from "../domains/feedback";
import {UiModule} from "../../shared/domains/core/ui";

function* fetch(): any {
  try {
    const filters = R.indexBy(R.prop('key'), [
      { key: 'Module', data: UiModule.toNewFilter() },
      { key: 'Type', data: UserFeedback.FeedbackTypeFilterable() },
      { key: 'Rating', data: UserFeedback.RatingFilterable()},
      { key: 'NetPromoterScore', data: UserFeedback.NetPromoterScoreFilterable() }
    ]);
    yield put(new QueryFeedbackActions.onLoadFiltersSuccess(filters));
  } catch (error) {
    yield put(new QueryFeedbackActions.onLoadFiltersError(error));
  }
}

function* watchGetFilters() {
  yield takeLatest(QueryFeedbackActions.onLoadFilters, fetch);
}

export default function* feedbackFilterSaga() {
  yield all([
    fork(watchGetFilters),
  ])
}
