interface NetworkPlantDTO {
  PlantCode: string;
  PlantName: string;
}

export class Plant {
  constructor(
    public PlantCode: string,
    public PlantName: string
  ) {
  }

  static FromBackend(data: NetworkPlantDTO): Plant {
    return new Plant(
      data.PlantCode,
      data.PlantName
    )
  }

  toSelect() {
    return {
      value: this.PlantCode,
      label: this.PlantName
    }
  }
  toFilter() {
    return {
      id: this.PlantCode,
      title: this.PlantName
    }
  }
}
