import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid/Grid";
import clsx from 'clsx';
import { CompetitiveBiddingType } from "../../domains/documentFlow/documentFlow";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '@material-ui/core/Typography';

interface ComplianceIndicatorProps {
  type: CompetitiveBiddingType,
  tooltip?: boolean,
  children?: any,
}

const complianceIndicatorStyles = makeStyles(() =>
  createStyles({
    wrapIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginTop: 3,
      marginRight: 6,
      fontSize: '20px',
      color: 'rgba(0, 0, 0, 0.5)'
    },
    [CompetitiveBiddingType.MissingCB]: {
      color: '#F19100 !important',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    [CompetitiveBiddingType.Approved]: {
      color: '#96C35C !important',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    [CompetitiveBiddingType.Compliant]: {
      color: '#64A709 !important',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    [CompetitiveBiddingType.NoNeeded]: {
      color: '#B6B6B6 !important',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    [CompetitiveBiddingType.Rejected]: {
      color: '#DE5575 !important',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    [CompetitiveBiddingType.NonCompliant]: {
      color: '#D00132 !important',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    ["R"]: {
      color: '#D00132 !important',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    ["P"]: {
      color: '#F19100 !important',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    null: {
      color: '#949494 !important',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    recordIcon: {
      fontSize: 'small',
      marginRight: '8px'
    },
  }),
);

export function getLabel(type: CompetitiveBiddingType) {
  switch (type) {
    case CompetitiveBiddingType.MissingCB:
      return "Missing CB"
    case CompetitiveBiddingType.Approved:
      return "Approved"
    case CompetitiveBiddingType.Compliant:
      return "Compliant"
    case CompetitiveBiddingType.NoNeeded:
      return "NoNeeded"
    case CompetitiveBiddingType.Rejected:
      return "Rejected"
    case CompetitiveBiddingType.NonCompliant:
      return "Non compliant"
    default:
      return "Unknown"
  }
}
export function getRejectReasonLabel(type: string) {
  switch (type) {
    case "R":
      return "Rejected by OB"
    case "P":
      return "Pending OB Validation"
    default:
      return "Unknown"
  }
}

export function ComplianceIndicator({ type, children, tooltip = false }: ComplianceIndicatorProps) {
  const styles = complianceIndicatorStyles({});

  return (
    <Grid container direction="row" alignItems="center">
      {tooltip && <Tooltip placement={"bottom-end"} title={<>CB ID: <u>{tooltip}</u></>}>
        <Typography className={clsx(styles[type], styles.wrapIcon)}>
          <FiberManualRecordIcon className={clsx(styles.recordIcon, styles[type])} />
          {getLabel(type)}
        </Typography>
      </Tooltip>}
      {!tooltip &&
        <Typography className={clsx(styles[type], styles.wrapIcon)}>
          <FiberManualRecordIcon className={clsx(styles.recordIcon, styles[type])} />
          {getLabel(type)}
        </Typography>
      }
      {children}
    </Grid>)
}

export function RejectReason({ reason }: { reason: string }) {
  const styles = complianceIndicatorStyles({});

  return (
    <Grid container direction="row" alignItems="center">
      <Typography className={clsx(styles[reason], styles.wrapIcon)}>
        <FiberManualRecordIcon className={clsx(styles.recordIcon, styles[reason])} />
        {getRejectReasonLabel(reason)}
      </Typography>
    </Grid>)
}