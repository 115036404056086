import {error, loaded, loading, LoadState} from "../../shared/reducers/utils";
import {mergeRight} from "ramda";
import {UserFeedback} from "../domains/feedback";
import {IAction} from "../../shared/domains/core/actions";
import {FeedbackTypes} from "../actions/feedback.actions";
import {QueryableReducer} from "../../shared/queryable/query.reducer";
import {newFeedbackQuery} from "../domains/feedbackQuery";

export interface FeedbackState extends LoadState {
  feedbackList: UserFeedback[],
  totalCount: number;
}

export const initialFeedbackState: FeedbackState = {
  feedbackList: [],
  totalCount: 0,
  loading: false,
  error: null
};

export function feedbackReducer(state: FeedbackState = initialFeedbackState, action: IAction<FeedbackTypes>): FeedbackState {
  switch (action.type) {
    case FeedbackTypes.FETCH_FEEDBACK_LIST_REQUEST:
      return loading(state);
    case FeedbackTypes.FETCH_FEEDBACK_LIST_REQUEST_SUCCESS:
      return loaded(mergeRight(state, {feedbackList: action.payload.data, totalCount: action.payload.count}));
    case FeedbackTypes.FETCH_FEEDBACK_LIST_REQUEST_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}

export const feedbackStateSelector = ['admin', 'feedbackList'];
export const feedbackDomainName = 'FEEDBACK';
export const {reducer: FeedbackReducer, sagas: FeedbackListSagas, actions: QueryFeedbackActions} = QueryableReducer<FeedbackState>(feedbackDomainName, feedbackStateSelector, feedbackReducer, newFeedbackQuery());
