interface NetworkReqNumberDTO {
    __metadata: { id: string; type: string; uri: string };
    PurchaseReq: string
    RequestNo: string
}

export class ExtReqNumber {
    constructor(
        public PurchaseReq: string,
        public RequestNo: string
    ) {
    }

    static FromBackend(data: NetworkReqNumberDTO): ExtReqNumber {
        return new ExtReqNumber(
            data.PurchaseReq,
            data.RequestNo,
        )
    }

    static toSelect(data: ExtReqNumber) {
        return {
            value: data.RequestNo,
            label: data.RequestNo
        }
    }
}
