interface ProjectIdWBSDTO {
  CompCode: string;
  Desc: string;
  WBSElement: string;
  WbsIntNum: string;
  __metadata: { id: string; type: string; uri: string };
}

export class ProjectIdWBS {
  constructor(
    public Desc: string,
    public WBSElement: string,
    public WbsIntNum: string
  ) {
  }

  static FromBackend(data: ProjectIdWBSDTO): ProjectIdWBS {
    return new ProjectIdWBS(
      data.Desc,
      data.WBSElement,
      data.WbsIntNum
    )
  }

  static toSelect(data: ProjectIdWBS) {
    return {
      value: data.WbsIntNum,
      label: `${data.WBSElement} - ${data.Desc}`,
      WBSElement: data.WBSElement
    }
  }
}
