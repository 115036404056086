import * as React from "react";
import { createStyles, IconButton, makeStyles, MenuItem, Select, Tooltip } from "@material-ui/core";
import LastPageSharpIcon from '@material-ui/icons/LastPageSharp';
import FirstPageSharpIcon from '@material-ui/icons/FirstPageSharp';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { useStylesForToolTip } from "../tooltip/toolTip.styles";

const useTableFooterStyles = makeStyles(theme =>
    createStyles({
        orange: {
            color: "#F19001",
        },
        root: {
            color: "#F19001",
            fontSize: "14px"
        },
        menuItem: {
            fontSize: "14px",
            "&.Mui-selected": {
                backgroundColor: "rgb(241 144 1)"
            }
        },
        paginationContainer: {
            display: "flex", justifyContent: "right", backgroundColor: "#F9F9F9", borderSpacing: "0 10px",
            borderCollapse: "separate", paddingRight: "20px"
        },
        selectContainer: {
            display: "flex", gap: "10px", padding: "10px", alignItems: "center"
        },
        pageNumberContainer: { display: "flex", alignItems: "center", color: "grey" }
    })
)

export const PaginationComponent = ({
    count,
    currentPage,
    onChangePage,
    rowsPerPage,
    setRowsPerPage,
    defaultRowsPerPage = [10, 25, 50, 100]
}: {
    count: number,
    currentPage: number,
    onChangePage: (page: number) => void,
    rowsPerPage: number,
    setRowsPerPage: Function,
    defaultRowsPerPage?: number[]
}) => {
    const classes = useTableFooterStyles({});
    const classesForToolTip = useStylesForToolTip({});
    const paginationOptions: { label: string, value: number }[] = defaultRowsPerPage.map(rowPerPage => ({ label: `${rowPerPage} / Page`, value: rowPerPage }));

    return <div className={classes.paginationContainer}>
        <div className={classes.selectContainer}>
            <div>Show Line:</div>
            <Select
                disableUnderline={true}
                classes={{ icon: classes.orange, root: classes.root, }}
                MenuProps={{
                    MenuListProps: {
                        disablePadding: true
                    }
                }}
                value={rowsPerPage}
                onChange={e => {
                    setRowsPerPage(currentPage, e.target.value)
                }}
            >
                {paginationOptions.map(pageSize => (
                    <MenuItem key={pageSize.value} value={pageSize.value}
                        classes={{ root: classes.menuItem }}
                    >
                        {pageSize.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
        <div className={classes.pageNumberContainer}>
            <Tooltip
                classes={{ tooltip: classesForToolTip.customTooltip }}
                interactive
                title={"First Page"}
                placement={"bottom-start"}>
                <IconButton onClick={() => onChangePage(0)} disabled={currentPage === 0}>
                    <FirstPageSharpIcon />
                </IconButton>
            </Tooltip>
            <Tooltip
                classes={{ tooltip: classesForToolTip.customTooltip }}
                interactive
                title={"Previous Page"}
                placement={"bottom-start"}>
                <IconButton onClick={() => onChangePage(currentPage - 1)} disabled={currentPage === 0}>
                    <KeyboardArrowLeftSharpIcon />
                </IconButton>
            </Tooltip>
            <div><span style={{ color: "orange" }}>{`${(currentPage * rowsPerPage) + 1} - ${((currentPage * rowsPerPage) + rowsPerPage) > count ? count : (currentPage * rowsPerPage) + rowsPerPage}`}</span>{` of ${count}`}</div>
            <Tooltip
                classes={{ tooltip: classesForToolTip.customTooltip }}
                interactive
                title={"Next Page"}
                placement={"bottom-start"}>
                <IconButton onClick={() => onChangePage(currentPage + 1)}
                    disabled={count !== -1 ? currentPage >= Math.ceil(count / rowsPerPage) - 1 : false}
                >
                    <KeyboardArrowRightSharpIcon />
                </IconButton>
            </Tooltip>
            <Tooltip
                classes={{ tooltip: classesForToolTip.customTooltip }}
                interactive
                title={"Last Page"}
                placement={"bottom-start"}>
                <IconButton onClick={() => onChangePage(Math.ceil(count / rowsPerPage) - 1)}
                    disabled={count !== -1 ? currentPage >= Math.ceil(count / rowsPerPage) - 1 : false}>
                    <LastPageSharpIcon />
                </IconButton>
            </Tooltip>
        </div>
    </div >
};