interface PricingConditionsDTO {
    __metadata: { id: string; type: string; uri: string };
    ARIBAPRNo: string;
    BaseCurr: string
    CalculationType: string;
    ChngeType: string;
    CompCode: string;
    CondAmtInBaseCurr: string;
    CondBaseVal: string;
    CondItemCounter: number;
    CondRate: string;
    CondType: string;
    CondTypeDesc: string;
    CondValue: string;
    ConditionUnit: string;
    Currency: string;
    ItemNumber: string;
    PoCond_Flag: string;
    PricingDate: string;
    PricingUnit: string;
    SAPPRNo: string;
    TotalconditionAmt: string;
    VendorNo: string;
    VendorName: string;
}

export class PricingConditions {
    constructor(
        public aribaPrNo: string,
        public baseCurr: string,
        public calculationType: string,
        public chngeType: string,
        public compCode: string,
        public condAmtInBaseCurr: string,
        public condBaseVal: string,
        public condItemCounter: number,
        public condRate: string,
        public condType: string,
        public condTypeDesc: string,
        public condValue: string,
        public conditionUnit: string,
        public currency: string,
        public itemNumber: string,
        public poCond_Flag: string,
        public pricingDate: string,
        public pricingUnit: string,
        public prNo: string,
        public totalconditionAmt: string,
        public vendorNo: string,
        public VendorName: string,
    ) {
    }

    static FromBackend(data: PricingConditionsDTO): PricingConditions {
        return new PricingConditions(
            data.ARIBAPRNo,
            data.BaseCurr,
            data.CalculationType,
            data.ChngeType,
            data.CompCode,
            data.CondAmtInBaseCurr,
            data.CondBaseVal,
            data.CondItemCounter,
            data.CondRate,
            data.CondType,
            data.CondTypeDesc,
            data.CondValue,
            data.ConditionUnit,
            data.Currency,
            data.ItemNumber,
            data.PoCond_Flag,
            data.PricingDate,
            data.PricingUnit,
            data.SAPPRNo,
            data.TotalconditionAmt,
            data.VendorNo,
            data.VendorName,
        )
    }
}
