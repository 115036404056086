import * as R from "ramda";
import {ItemPrice} from "../../domains/itemPrice/itemPrice";
import { ItemPriceActionTypes } from "../../actions/itemPricing.actions";
import {IAction} from "../../../shared/domains/core/actions";

export interface DraftItemsPriceState {
  draftItemsPrice: { [key: string]: ItemPrice[] };
  totalCount: { [key: string]: number };
  loading: { [key: string]: boolean };
  error: string;
}

export const initialItemsPriceState: DraftItemsPriceState = {
  draftItemsPrice: {},
  totalCount: {},
  loading: {},
  error: null
};

export function DraftItemsPriceReducer(state: DraftItemsPriceState = initialItemsPriceState, action: IAction<ItemPriceActionTypes>): DraftItemsPriceState {
  switch (action.type) {
    case ItemPriceActionTypes.FETCH_DRAFT_ITEMSPRICE:
      return {
        ...state,
        draftItemsPrice: R.dissoc(action.itemNo, state.draftItemsPrice),
        error: null,
        loading: {
          ...state.loading,
          [action.itemNo]: true
        }
      };
    case ItemPriceActionTypes.FETCH_DRAFT_ITEMSPRICE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemNo]: false
        },
        draftItemsPrice: {
          ...state.draftItemsPrice,
          [action.itemNo]: action.items
        },
        totalCount: {
          ...state.totalCount,
          [action.itemNo]: action.totalCount
        }
      };
    case ItemPriceActionTypes.FETCH_DRAFT_ITEMSPRICE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.itemNo]: false
        },
        error: action.error
      };
    case ItemPriceActionTypes.UPDATE_ITEMSPRICE_DRAFT_SUCCESS:
      const itemPrices = state.draftItemsPrice[action.itemPrice.ItemNo];

      const newItemPrices = itemPrices.map((itemPrice: ItemPrice) => {
        if (itemPrice.ConditionRecord === action.itemPrice.ConditionRecord &&
          itemPrice.RefAgreementNo == action.itemPrice.RefAgreementNo &&
          itemPrice.ConditionItem == action.itemPrice.ConditionItem &&
          itemPrice.ItemNo == action.itemPrice.ItemNo &&
          itemPrice.AgreementNo == action.itemPrice.AgreementNo) {
          return action.itemPrice;
        }
        return itemPrice;
      });

      return {
        ...state,
        draftItemsPrice: {
          ...state.draftItemsPrice,
          [action.itemPrice.AgreementNo]: newItemPrices
        }
      };
    default:
      return state;
  }
}
