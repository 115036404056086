import {PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {IChatCounter} from "../../shared/domains/chat/roomChat.service";

export enum ContractChatTypes {
  GET_MESSAGES_SUCCESS = 'GET_CONTRACT_CHAT_MESSAGES_SUCCESS',
  NEW_CONTRACT_CHAT_COUNTER = "NEW_CONTRACT_CHAT_COUNTER",
  GET_CHAT_COUNTERS = "GET_CHAT_COUNTERS"
}

export class NewContractChatCounter extends PendingAction<ContractChatTypes> {
  public readonly type = ContractChatTypes.NEW_CONTRACT_CHAT_COUNTER;

  constructor(public counter: IChatCounter, public currentUserId?: number) {
    super();
  }
}

export class GetChatCounters extends PendingAction<ContractChatTypes> {
  public readonly type = ContractChatTypes.GET_CHAT_COUNTERS;

  constructor(public currentUserId: number) {
    super();
  }
}

export class GetChatMessagesSuccess extends SuccessAction<ContractChatTypes> {
  public readonly type = ContractChatTypes.GET_MESSAGES_SUCCESS;

  constructor(public contractId: string, public messages) {
    super();
  }
}

