import {createStyles, makeStyles} from "@material-ui/core";

export const useTableStyles = makeStyles(() =>
  createStyles({
    subTableContainer: {
      border: '2px dashed #F0CE9C !important',
      maxHeight: '350px',
      borderLeft: '1px solid #F9F9F9',
      overflow: 'auto',
      '&& .MuiTableRow-root': {
        height: 'unset !important'
      },
      '&& .MuiTableCell-body': {
        backgroundColor: '#FFF6E9'
      }
    },
    pslTableClass: {
      '&& .MuiTableRow-root': {
        height: 'unset !important'
      },
      '&& .MuiPaper-root': {
        padding: '0 25px',
        boxShadow: 'none',
        '& div': {
          maxHeight: 'calc(100vh - 400px)',   
        }
      },
      '&& .MuiTable-root': {
        borderCollapse: 'separate',
        borderSpacing: '0 10px'
      },
      '&& .MuiTableCell-root': {
        border: 'none'
      },
      '&& .MuiTableCell-body': {
        color: '#777777',
        fontSize: '15px'
      },
      '&& .MuiIconButton-colorSecondary': {
        color: '#2689EB'
      },
      '&& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26) !important'
      }
    },
    mrpTableClass: {
      '&& .MuiTableRow-root': {
        height: 'unset !important'
      },
      '&& .MuiPaper-root': {
        margin: '0',
        boxShadow: 'none',
        '& div': {
          maxHeight: '60vh'
        }
      },
      '&& .MuiTable-root': {
        borderCollapse: 'separate',
        borderSpacing: '0 10px'
      },
      '&& .MuiTableCell-root': {
        border: 'none'
      },
      '&& .MuiTableCell-body': {
        color: '#777777',
        fontSize: '15px'
      },
      '&& .MuiIconButton-colorSecondary': {
        color: '#2689EB'
      },
      '&& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26) !important'
      }
    },
    keywordChipClass: {
      height: '30px',
      backgroundColor: '#2680EB',
      color: '#FFFFFF',
      fontSize: '14px',
      '&& .MuiChip-label': {
        padding: '0 20px'
      }
    },
    statusChipClass: {
      height: '28px',
      backgroundColor: '#B1B1B1',
      color: '#FFFFFF',
      fontSize: '14px',
      '&& .MuiChip-label': {
        padding: '0 16px'
      }
    },
    moreClass: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: '6px',
      cursor: 'pointer',
      fontSize: '17px',
      '&& svg': {
        marginLeft: '5px'
      }
    }
  })
);
