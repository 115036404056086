import React from 'react'
import { useSelector } from "react-redux"
import { RootStateStore } from './application.reducers';
import { Redirect, Route, useLocation } from 'react-router';
import { useFeatureFlag } from './shared/domains/core/pataflag.context';
import { Features } from './shared/domains/core/pataflag';

const OpsBuyerOnlyRoute = (props) => {
  const location = useLocation();
  const { hasFeature } = useFeatureFlag();
  const currentUser = useSelector((state: RootStateStore) => state.shared.user.currentUser);
  const isOperationalBuyer = hasFeature(Features.SHOW_NBS_FOR_OP_BUYER_ONLY) ? currentUser.operationalBuyer : true;

  return isOperationalBuyer ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/notauthorized",
        state: { from: location }
      }}
    />
  );
};

export default OpsBuyerOnlyRoute;