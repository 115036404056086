import * as R from 'ramda';
import {IAction} from "../domains/core/actions";
import {chatActionName, ChatActions} from "./chat.actions";
import {chatSagaBuilder} from "./chat.saga";
import {IRoomChatService} from "../domains/chat/roomChat.service";
import {error, loading} from "../reducers/utils";

export interface IChatState {
  messages: any[],
  chatGroups: any[],
  room: IRoomChatService;
  loading: boolean;
  sending: boolean;
  error: string;
}

const newChat = (): IChatState => {
  return {
    messages: [],
    chatGroups: [],
    room: null,
    loading: false,
    sending: false,
    error: null
  }
};

function reducer(domain: string, initialChat: IChatState = newChat()) {
  const actionName = chatActionName(domain);
  return (state: IChatState = initialChat, action: IAction<ChatActions>): IChatState => {
    switch (action.type) {
      case actionName(ChatActions.START_ROOM):
      case actionName(ChatActions.LOAD_MESSAGES):
        return loading(R.assoc('messages', [], state));
      case actionName(ChatActions.SEND_MESSAGE):
      case actionName(ChatActions.SEND_NEW_MESSAGE):
        return R.mergeRight(state, {sending: true});
      case actionName(ChatActions.SEND_MESSAGE_SUCCESS):
        return R.mergeRight(state, {sending: false});
      case actionName(ChatActions.START_ROOM_SUCCESS):
        return R.mergeRight(state,{room: action.room, loading: false});
      case actionName(ChatActions.LOAD_MESSAGES_SUCCESS):
        return R.mergeRight(state, {messages: action.messages, loading: false});
      case actionName(ChatActions.LOAD_GROUPS_SUCCESS):
        return R.mergeRight(state, {chatGroups: action.chatGroups, loading: false});  
      case actionName(ChatActions.NEW_MESSAGES):
        return R.mergeRight(state, {messages: [...state.messages, action.message], loading: false});
      case actionName(ChatActions.START_ROOM_FAILURE):
      case actionName(ChatActions.LOAD_MESSAGES_FAILURE):
      case actionName(ChatActions.SEND_MESSAGE_FAILURE):
      case actionName(ChatActions.LOAD_GROUPS_FAILURE):
        return error(action.error)(state);
      default:
        return state;
    }
  }
}

export const ChatReducer = <T>(domain: string, stateSelector: string[]): any => {
  const builtSaga = chatSagaBuilder(stateSelector, chatActionName(domain));
  return ({
    reducer: reducer(domain, newChat()),
    sagas: builtSaga.sagas,
    actions: builtSaga.actions
  });
}
