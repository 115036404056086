import {ApiService} from "./api.service";
import ODataClientWrapper from "../utils/odataClient.wrapper";
import {ODataFilter} from "../../../lib/odata";

export interface PriceConversionRequestDTO {
  supplierIndex?: number;
  fromAmount: string
  fromCurrency: string
  toCurrency: string
}

export interface ConvertedPriceNetworkDTO {
  FromCurrency: string
  ToCurrency: string
  Amount: string
}

export class ConvertedPrice {
  constructor(
    public FromAmount: string,
    public FromCurrency: string,
    public ToCurrency: string,
    public ToAmount: string,
    public SupplierIndex: number = 0,
  ) {
    this.ToAmount = ToAmount.trim();
  }

  isConverted(): boolean {
    return this.ToAmount !== "";
  }

  static FromBackend(fromAmount: string, cp: ConvertedPriceNetworkDTO, supplierIndex: number): ConvertedPrice {
    return new ConvertedPrice(fromAmount, cp.FromCurrency, cp.ToCurrency, cp.Amount, supplierIndex)
  }

  static NotConverted(fromAmount: string, fromCurrency: string, supplierIndex: number): ConvertedPrice {
    return new ConvertedPrice(fromAmount, fromCurrency, "", "", supplierIndex)
  }
}

export class PriceService extends ApiService {
  // CurrencyConvertSet?$filter=Amount eq '10.00' and FromCurrency eq 'CHF' and ToCurrency eq 'INR'
  // CurrencyConvertSet?$filter=Amount eq '421' and ToCurrency eq 'USD' and FromCurrency eq 'AFA'
  static async convertPrice({fromAmount, fromCurrency, toCurrency, supplierIndex}: PriceConversionRequestDTO): Promise<ConvertedPrice> {
    if (!fromAmount || !fromCurrency) return ConvertedPrice.NotConverted(fromAmount, fromCurrency, supplierIndex);

    const filter = ODataFilter.for({field: 'Amount', value: parseFloat(fromAmount).toFixed(2)})
      .and({field: 'ToCurrency', value: toCurrency})
      .and({field: 'FromCurrency', value: fromCurrency});

    const result = await ODataClientWrapper.get()
      .resource('CurrencyConvertSet')
      .filter(filter)
      .run();

    if (result.data.length === 0) {
      return ConvertedPrice.NotConverted(fromAmount, fromCurrency, supplierIndex)
    }

    return ConvertedPrice.FromBackend(fromAmount, result.data[0], supplierIndex);
  }
  

  static async convertPrices(currentPrices: PriceConversionRequestDTO[]): Promise<ConvertedPrice[]> {
    const promises = currentPrices.map(PriceService.convertPrice);
    return await Promise.all(promises);
  }
}


export class PriceService2 extends ApiService{
  static async convertPrice(fromAmount, fromCurrency, toCurrency){
    
    const filter = ODataFilter.for({field: 'Amount', value: parseFloat(fromAmount).toFixed(2)})
      .and({field: 'ToCurrency', value: toCurrency})
      .and({field: 'FromCurrency', value: fromCurrency});

      const result = await ODataClientWrapper.get()
      .resource('CurrencyConvertSet')
      .filter(filter)
      .run();

      console.log(result);

      if (result.data.length === 0) {
        return ConvertedPrice.NotConverted(fromAmount, fromCurrency, 0)
      }

      return ConvertedPrice.FromBackend(fromAmount, result.data[0], 0);

  }
}




