import Button from "@material-ui/core/Button";
import { HorizontalView } from "../../shared/components/layout/styles";
import styled from "styled-components";

export const BannerWrapper = styled.span`
  display: flex;
  font-weight: 600;
  padding: 17px;
  align-items: center;
  color: white;
  background-color: white;
  @media only screen and (max-width: 768px) {
    padding: 10px;
}
`;

export const BannerTitle = styled.span`
  font-size: 22px;
  color: black;
  margin-left: 8px;
  @media only screen and (max-width: 768px) {
    font-size: 18px
}
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  overflow-y: auto;
  height: calc(100% - 65px);
`;

export const Container = styled.div`
  padding: 0 25px;
`;

export const CommonRow = styled.div`
  width: 100%;
    .MuiSelect-select {
      padding-right: 32px!important;
      width: 425px;
      height: auto;
    }
    label {
      font-size: 15px;
    }
`;

export const Row = styled(CommonRow)`
  padding: 20px 0 0 0;
`;

export const StyledButtons = styled(HorizontalView)`
  justify-content: center;
  button {
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: none;
    color: #F19001;
    font-weight: 600;
    padding: 4px;
    width: 80px;
  }
`;

export const ApplyButton = styled(Button)`
  background-color: #F19001!important; 
  color: #fff!important;
  
  &:disabled {
    opacity: 0.4 !important;
  }
`;

export const ResetButton = styled(Button)`
  border: 1px solid #F19001!important;
  background-color: transparent!important; 
`;


export const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between; 
  min-height: 65px;
  position: relative;
`;