import {ErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";


export enum PurchaseRequisitionAction {
    FETCH_PR_TYPE = "FETCH_PR_TYPE",
    FETCH_PR_TYPE_SUCCESS = "FETCH_PR_TYPE_SUCCESS",
    FETCH_PR_TYPE_FAILURE = "FETCH_PR_TYPE_FAILURE",
}

export class FetchPRType extends PendingAction<PurchaseRequisitionAction> {
    public readonly type = PurchaseRequisitionAction.FETCH_PR_TYPE;
}

export class FetchPRTypeSuccess extends SuccessAction<PurchaseRequisitionAction> {
    public readonly type = PurchaseRequisitionAction.FETCH_PR_TYPE_SUCCESS;
}

export class FetchPRTypeError extends ErrorAction<PurchaseRequisitionAction> {
    public readonly type = PurchaseRequisitionAction.FETCH_PR_TYPE_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}
