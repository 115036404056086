import * as React from 'react';
import {debounce} from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import {StyledIconButton, StyledInput, StyledPaper} from "./search.styles";
import Tooltip from "@material-ui/core/Tooltip";
import {useEffect} from "react";
import {useState} from "react";

interface SearchInputProps {
  onSearch: (term: string) => any;
  helpMessage?: string;
  defaultValue?: string;
  width?: number;
  searchOnKeyUp?: boolean;
}

export const toSearchableList = (values) => `<ul style="padding-left: 20px">${values.map(t => `<li>${t}</li>`).join('')}</ul>`;

export function SearchOnChange(props: SearchInputProps) {
  const onChange = debounce((term: string) => props.onSearch(term), 400);

  return (
    <StyledPaper elevation={0}>
      <StyledInput
        placeholder="Search..."
        inputProps={{'aria-label': 'Search...'}}
        defaultValue={props.defaultValue}
        onChange={e => onChange(e.target.value)}
      />
      <Tooltip title={
        props.helpMessage ? <span dangerouslySetInnerHTML={{__html: props.helpMessage}}></span> : ""
      }>
        <StyledIconButton aria-label="Search">
          <SearchIcon/>
        </StyledIconButton>
      </Tooltip>
    </StyledPaper>
  );
}

export default function Search(props: SearchInputProps) {
  const [value, setValue] = useState(props.defaultValue);
  const [lastSearchedValue, setLastSearchedValue] = useState(props.defaultValue);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleKeyUp = (e) => {
    if (props.searchOnKeyUp) {
      onSearch();
    } else if (e.key === 'Enter' || e.target.value === "") {
      onSearch();
    }
  }
  const onSearch = () => {
    if(value !== lastSearchedValue) {
      props.onSearch(value.trim());
      setLastSearchedValue(value);
    }
  }
  return (
    <StyledPaper elevation={0} style={{width: props.width ? props.width : 'inherit'}}>
      <StyledInput
        placeholder="Search..."
        inputProps={{'aria-label': 'Search...'}}
        value={value}
        onKeyUp={handleKeyUp}
        onChange={e => setValue(e.target.value)}
      />
      <Tooltip title={
        props.helpMessage ? <span dangerouslySetInnerHTML={{__html: props.helpMessage}}></span> : ""
      }>
        <StyledIconButton aria-label="Search" onClick={onSearch}>
          <SearchIcon/>
        </StyledIconButton>
      </Tooltip>
    </StyledPaper>
  );
}

export function InputWithIcon({controlledValue, onAction, placeholder, icon, width}) {
  const [value, setValue] = useState(controlledValue ?? "");

  useEffect(()=>{
    setValue(controlledValue ?? "");
  }, [controlledValue]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      performAction();
    }
  }
  const performAction = () => {
    onAction(value);
  }
  return (
    <StyledPaper elevation={0} style={{width: width+'px'}}>
      <StyledInput
        placeholder={placeholder}
        inputProps={{'aria-label': 'Search...'}}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={e => setValue(e.target.value)}
      />
      <StyledIconButton aria-label="Search" onClick={performAction}>
        {icon}
      </StyledIconButton>
    </StyledPaper>
  );
}
