import styled from "styled-components";
import {StyledDialog} from "../../../../components/layout/styles";
import DialogContent from "@material-ui/core/DialogContent";

export const SettingsTheme = {
  overrides: {
    MuiTableCell: {
      root: {
        color: '#707070',
        padding: '10px 5px',
        '@media (max-width: 1024px)': {
          fontSize: '12px!important',
          padding: '5px 0',
          '& button': {
            padding: '6px'
          }
        }
      }
    }
  }
};

export const StyledAddCompCodeDialog = styled(StyledDialog)`
  && .MuiDialog-paperWidthXs {
    width: 400px;
    padding: 0;
  }
`;

export const StyledAddCompCodeDialogContent = styled(DialogContent)`
  color: #707070;
  font-size: 16px;
  opacity: 0.8;
  max-height: 600px;
`;