import {template} from 'lodash';
import * as R from 'ramda';
import {ebRealm, ebUrl, sapUrl} from "../../../shared/utils/urlBuilders";

export enum CB_STATUS {
  CBPending = 1,
  CBApproved = 2,
  CBRejected = 3,
  CBCompliant = 4,
  CBNonCompliant = 5
}

export enum DocFlowFilterTabs {
  FAVORITE = "FAVORITES",
  DOCUMENTS = "DOCUMENTS",
  INVOICE_ISSUES = "INVOICE ISSUES"
}

export type CompBiddingDTO = CB_STATUS;

export interface NetworkDocumentFlowDTO {
  __metadata: { id: string; type: string; uri: string };
  AribaPO: string;
  AribaPR: string;
  AribaReqNum:string;
  CollectiveNumber: string;
  CompetitiveBiddingCreatorUserId: number;
  CompetitiveBiddingCreatorUserName: string;
  CompetitiveBiddingFormattedIdInAzure: string;
  CompetitiveBiddingIdInAzure: string;
  CompetitiveBiddingShortFormattedIdInAzure: string;
  CompetitiveBiddingStatusIdInAzure: CB_STATUS;
  CostCenter: string;
  CountOfUnreadComments: number;
  isThereAnyNewNotes:boolean;
  IsNotesPresent:boolean;
  Creator: string;
  Creatorfnam: string;
  Creatorlnam: string;
  Currency: string;
  DateRange: string;
  DocType:string;
  GRDoc: string;
  HasDiff: string;
  HasIrBlock: string,
  HasIrPark: string,
  IRDoc: string;
  IdNo: string;
  IsCbActive: string;
  IsFavorite: string;
  MatGrp: string;
  MaterialNo: string;
  OB_Indicator:string,
  OB_Rej_Ind:string;
  Our_Reference:string;
  POBlocked: string;
  POCompanyCode: string;
  POCompanyCodeDesc: string;
  POCreationDate: string;
  PODocType: string;
  POItemNo: string;
  POTotAmount: string;
  POUrl: string;
  PRBlocked: string;
  PRCreationDate: string;
  PRCompanyCode: string;
  PRItemNo: string;
  PRPlant: string;
  PRPlantName: string;
  PoCurrency: string;
  Progress: string;
  PurchGroup: string;
  PurchGroupDesc: string;
  PurchOrg: string;
  PurchOrgDesc: string;
  PurchaseOrdNo: string;
  PurchaseReqNo: string;
  Requisitioner: string;
  Reqfnam: string;
  Reqlnam: string;
  SAP__Origin: string;
  SapAttchScn: string;
  SearchTerm: string;
  Status: string;
  SystemAlias: string;
  TotalAmount: string;
  TotalCountOfComments: number;
  VendorName: string;
  VendorNo: string;
  WbsElement: string;
  PrCreatorName: string,
  Prcreatorfn: string,
  Prcreatorln: string,
  ProjOrderTitle: string
}

export enum CompetitiveBiddingType {
  Approved = 'approved',
  NoNeeded = 'noNeeded',
  Rejected = 'rejected',
  Compliant = 'compliant',
  NonCompliant = 'nonCompliant',
  MissingCB = 'missingCB'
}

const ViewPRSAPTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=P_BANFN%3d<%=pr%>%26&AutoStart=true"
);

const ViewPOSAPTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=/GLB/RGTPT01_ME23N&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME23N&DynamicParameter=P_EBELN%3d<%=po%>%26&AutoStart=true",
  // "https://globe7prep.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=R3AE1&TCode=/GLB/RGTPT01_ME23N&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=/GLB/RGTPT01_ME23N&DynamicParameter=P_EBELN%3d4500001055&AutoStart=true"
  // "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=v%3d<%=pr%>%26&AutoStart=true"
);

const ViewPREBTemplate = template(
  "https://<%=prefix%>.ariba.com/Buyer/Main/ad/webjumper?realm=<%=realm%>&itemUniqueName=<%=pr%>&itemClassName=ariba.purchasing.core.Requisition"
);

export type DocumentFlowId = string;

export enum DocCatForSap {
  PO = "F",
  PR = "B"
}

export function DocumentFlowIdGenerator(df: DocumentFlow): DocumentFlowId {
  const sapOrigin = df.SapOrigin ? df.SapOrigin.trim() : "missing"
  const idNo = df.IdNo ? df.IdNo.trim() : "missing"
  const vendorNo = df.VendorNo ? df.VendorNo.trim() : "missing"
  const purchaseOrdNo = df.PurchaseOrdNo ? df.PurchaseOrdNo.trim() : "missing"
  const purchaseReqNo = df.PurchaseReqNo ? df.PurchaseReqNo.trim() : "missing"
  const gRDoc = df.GRDoc ? df.GRDoc.trim() : "missing"
  const iRDoc = df.IRDoc ? df.IRDoc.trim() : "missing"

  return `${sapOrigin}-${idNo}-${vendorNo}-${purchaseOrdNo}-${purchaseReqNo}-${gRDoc}-${iRDoc}`
}

export enum TCode {
  PR_DISPLAY = "/GLB/RGTPP01_ME53N",
  PR_EDIT = "ME52N",
  PO_DISPLAY = "/GLB/RGTPP01_ME23N",
}

export default class DocumentFlow {
  public id: DocumentFlowId;

  constructor(
    public AribaPO: string,
    public AribaPR: string,
    public AribaReqNum: string,
    public CollectiveNumber: string,
    public CompetitiveBiddingCreatorUserId: number,
    public CompetitiveBiddingCreatorUserName: string,
    public CompetitiveBiddingFormattedIdInAzure: string,
    public CompetitiveBiddingIdInAzure: string,
    public CompetitiveBiddingShortFormattedIdInAzure: string,
    public CompetitiveBiddingStatusIdInAzure: CompetitiveBiddingType,
    public CostCenter: string,
    public CountOfUnreadComments: number,
    public isThereAnyNewNotes: boolean,
    public IsNotesPresent: boolean,
    public Creator: string,
    public Creatorfnam: string,
    public Creatorlnam: string,
    public Currency: string,
    public DateRange: string,
    public DocType:string,
    public GRDoc: string,
    public HasDiff: string,
    public HasIrBlock: string,
    public HasIrPark: string,
    public IRDoc: string,
    public IdNo: string,
    public IsCbActive: string,
    public IsFavorite: string,
    public MatGrp: string,
    public MaterialNo: string,
    public OB_Indicator:string,
    public OB_Rej_Ind:string,
    public Our_Reference:string,
    public POBlocked: string,
    public POCompanyCode: string,
    public POCompanyCodeDesc: string,
    public POCreationDate: string,
    public PODocType: string,
    public POItemNo: string,
    public POTotAmount: string,
    public POUrl: string,
    public PRBlocked: string,
    public PRCreationDate: string,
    public PRCompanyCode: string,
    public PRItemNo: string,
    public PRPlant: string,
    public PRPlantName: string,
    public PoCurrency: string,
    public Progress: number,
    public PurchGroup: string,
    public PurchGroupDesc: string,
    public PurchOrg: string,
    public PurchOrgDesc: string,
    public PurchaseOrdNo: string,
    public PurchaseReqNo: string,
    public Requisitioner: string,
    public Reqfnam: string,
    public Reqlnam: string,
    public SapOrigin: string,
    public SapAttchScn: string,
    public SearchTerm: string,
    public Status: string,
    public SystemAlias: string,
    public TotalAmount: string,
    public TotalCountOfComments: number,
    public VendorName: string,
    public VendorNo: string,
    public WbsElement: string,
    public ViewPRSAPLink: string,
    public ViewPREBLink: string,
    public EditPRSAP: string,
    public ViewPOSAPLink: string,
    public PrCreatorId: string,
    public PrCreatorFirstName: string,
    public PrCreatorLastName: string,
    public ProjOrderTitle: string,
  ) {
    this.id = DocumentFlowIdGenerator(this);
    this.TotalAmount = TotalAmount ? TotalAmount.trim() : "";
    this.GRDoc = GRDoc ? GRDoc.trim() : "";
    this.IRDoc = IRDoc ? IRDoc.trim() : "";
    this.POUrl = POUrl ? POUrl.trim() : "";
    this.AribaPR = AribaPR ? AribaPR.trim() : "";
    this.AribaReqNum = AribaReqNum ? AribaReqNum.trim() : "";
    this.AribaPO = AribaPO ? AribaPO.trim() : "";
  }

  static isPRFromSAP(df: DocumentFlow): boolean {
    return !df.AribaPR;
  }

  static isPOFromSAP(df: DocumentFlow): boolean {
    return !df.AribaPO;
  }

  static getCompanyCode(df: DocumentFlow): string {
    return (df.PRCompanyCode && df.PRCompanyCode !== "") ?
      df.PRCompanyCode : df.POCompanyCode;
  }

  static isPO(df: DocumentFlow): boolean {
    return (df.PurchaseOrdNo && df.PurchaseOrdNo !== "") || (df.AribaPO && df.AribaPO !== "");
  }
  static isPR(df: DocumentFlow): boolean {
    return (df.PurchaseReqNo && df.PurchaseReqNo !== "") || (df.AribaPR && df.AribaPR !== "");
  }
  static getPurchaseNoForPR(df: DocumentFlow): string {
    if(df.PurchaseReqNo && df.PurchaseReqNo!=="") return df.PurchaseReqNo;
    if(df.AribaPR && df.AribaPR!=="") return df.AribaPR;
    return "";
  }
  static getPurchaseNoForPO(df: DocumentFlow): string {
    if(df.PurchaseOrdNo && df.PurchaseOrdNo!=="") return df.PurchaseOrdNo;
    if(df.AribaPO && df.AribaPO!=="") return df.AribaPO;
    return "";
  }
  static getPRblock(df: DocumentFlow): any {
    return {
      systemAlias: df?.SystemAlias,
      aribaValue: df?.AribaPR,
      sapValue: df?.PurchaseReqNo,
      plantCode: df?.PRPlant,
      purchasingGroupCode: df?.PurchGroup,
      purchasingOrgCode: df?.PurchOrg,
      companyCode: df?.PRCompanyCode || df?.POCompanyCode,
      VendorNo: df?.VendorNo,
      InnerItems: []
    };
  }
  static getPOblock(df: DocumentFlow): any {
    return {
      systemAlias: df?.SystemAlias,
      aribaValue: df?.AribaPO,
      sapValue: df?.PurchaseOrdNo,
      plantCode: df?.PRPlant,
      purchasingGroupCode: df?.PurchGroup,
      purchasingOrgCode: df?.PurchOrg,
      companyCode: df?.POCompanyCode || df?.PRCompanyCode,
      VendorNo: df?.VendorNo,
      InnerItems: []
    };
  }
  static amountToString(df: DocumentFlow): string {
    return `${df.TotalAmount} ${df.Currency}`;
  }

  static FromBackend(data: NetworkDocumentFlowDTO): DocumentFlow {
    const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());
    const ebPrefix = ebUrl(process.env.REACT_APP_ENV.trim());
    const realm = ebRealm(process.env.REACT_APP_ENV.trim());
    const sapPrefixed = R.mergeRight({prefix: sapPrefix, system: data.SystemAlias});
    const ebPrefixed = R.mergeRight({prefix: ebPrefix, realm: realm});

    const ViewPRSAP = ViewPRSAPTemplate(sapPrefixed({pr: data.PurchaseReqNo, code: TCode.PR_DISPLAY}));
    const EditPRSAP = ViewPRSAPTemplate(sapPrefixed({pr: data.PurchaseReqNo, code: TCode.PR_EDIT}));
    const ViewPOSAP = ViewPOSAPTemplate(sapPrefixed({po: data.PurchaseOrdNo, code: TCode.PO_DISPLAY}));
    const ViewPREBLink = ViewPREBTemplate(ebPrefixed({pr: data.AribaPR}));

    return new DocumentFlow(
      data.AribaPO,
      data.AribaPR,
      data.AribaReqNum,
      data.CollectiveNumber,
      data.CompetitiveBiddingCreatorUserId,
      data.CompetitiveBiddingCreatorUserName,
      data.CompetitiveBiddingFormattedIdInAzure,
      data.CompetitiveBiddingIdInAzure,
      data.CompetitiveBiddingShortFormattedIdInAzure,
      cbMapper(data.CompetitiveBiddingStatusIdInAzure),
      data.CostCenter,
      data.CountOfUnreadComments,
      data.isThereAnyNewNotes,
      data.IsNotesPresent,
      data.Creator,
      data.Creatorfnam,
      data.Creatorlnam,
      data.Currency,
      data.DateRange,
      data.DocType,
      data.GRDoc,
      data.HasDiff,
      data.HasIrBlock,
      data.HasIrPark,
      data.IRDoc,
      data.IdNo,
      data.IsCbActive,
      data.IsFavorite,
      data.MatGrp,
      data.MaterialNo,
      data.OB_Indicator,
      data.OB_Rej_Ind,
      data.Our_Reference,
      data.POBlocked,
      data.POCompanyCode,
      data.POCompanyCodeDesc,
      data.POCreationDate,
      data.PODocType,
      data.POItemNo,
      data.POTotAmount,
      data.POUrl,
      data.PRBlocked,
      data.PRCreationDate,
      data.PRCompanyCode,
      data.PRItemNo,
      data.PRPlant,
      data.PRPlantName,
      data.PoCurrency,
      parseInt(data.Progress),
      data.PurchGroup,
      data.PurchGroupDesc,
      data.PurchOrg,
      data.PurchOrgDesc,
      data.PurchaseOrdNo,
      data.PurchaseReqNo,
      data.Requisitioner,
      data.Reqfnam,
      data.Reqlnam,
      data.SAP__Origin,
      data.SapAttchScn,
      data.SearchTerm,
      data.Status,
      data.SystemAlias,
      data.TotalAmount,
      data.TotalCountOfComments,
      data.VendorName,
      data.VendorNo,
      data.WbsElement,
      ViewPRSAP,
      ViewPREBLink,
      EditPRSAP,
      ViewPOSAP,
      data.PrCreatorName.trim(),
      data.Prcreatorfn.trim(),
      data.Prcreatorln.trim(),
      data.ProjOrderTitle,
    );
  }
}

export function cbMapper(dto: CompBiddingDTO): CompetitiveBiddingType {
  switch (dto) {
    case 2:
      return CompetitiveBiddingType.Approved;
    case 3:
      return CompetitiveBiddingType.Rejected;
    case 4:
      return CompetitiveBiddingType.Compliant;
    case 5:
    default:
      return CompetitiveBiddingType.NonCompliant;
  }
}
