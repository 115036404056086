import { assoc, flatten, filter, merge } from "ramda";
import { ApprovalStatusActionTypes } from "../actions/approvalStatus.action";
import { IAction } from "../../../shared/domains/core/actions";
import { approvalStatusQuery, ODataQuery } from "../domains/approvalStatus/query";
import { ApprovalStatusRequest } from "../domains/approvalStatus/approvalStatus";

export interface ApprovalStatusState {
	query: ODataQuery;
	approvalStatusList: ApprovalStatusRequest[];
	loading: boolean;
	itemLoading: boolean;
	error: any;
	totalCount: number;
	selectedRow: ApprovalStatusRequest;
	purchaseRequestInfo: any;
	purchaseRequestAccount: any,
	purchaseRequestPricing: any,
	approvalItems: any,
	defaultAliys: string
}

export const initialApprovalStatusState: ApprovalStatusState = {
	query: approvalStatusQuery(),
	approvalStatusList: [],
	loading: true,
	itemLoading: true,
	error: null,
	totalCount: 0,
	selectedRow: null,
	purchaseRequestInfo: null,
	purchaseRequestAccount: [],
	purchaseRequestPricing: [],
	approvalItems: [],
	defaultAliys: null
};

const loading = { loading: true, error: null };
const emptyAndLoading = { loading: true, error: null, approvalStatusList: [], totalCount: 0 };

export function ApprovalStatusReducers(state: ApprovalStatusState = initialApprovalStatusState, action: IAction<ApprovalStatusActionTypes>): ApprovalStatusState {

	switch (action.type) {
		case ApprovalStatusActionTypes.FETCH_AS:
			return {
				...state,
				loading: true,
				approvalStatusList: [],
				totalCount: 0
			};
		case ApprovalStatusActionTypes.FETCH_AS_SUCCESS:
			return {
				...state,
				loading: false,
				approvalStatusList: action.items,
				totalCount: action.totalCount ? action.totalCount : 0,
				query: action.newQuery
			};
		case ApprovalStatusActionTypes.NEW_AS_QUERY:
			assoc('query', action.query, state);
			const loaderState = { loading: true };
			return merge(state, loaderState);
		case ApprovalStatusActionTypes.SET_SELECTED_AS:
			const partialState = { selectedRow: action.purchaseRequest, loading: false };
			return merge(state, partialState);
		case ApprovalStatusActionTypes.FETCH_AS_DETAIL:
			return {
				...state,
				itemLoading: true,
			};
		case ApprovalStatusActionTypes.FETCH_AS_DETAIL_SUCCESS:
			return {
				...state,
				itemLoading: false,
				purchaseRequestInfo: action.info,
				purchaseRequestAccount: action.accountList,
				purchaseRequestPricing: action.pricing,
			};
		case ApprovalStatusActionTypes.FETCH_AS_LINES:
			return {
				...state,
				itemLoading: true
				/*approvalStatusList: state.approvalStatusList.map(purchaseRequest => purchaseRequest.SapPR === action.SapPR ?

					{ ...purchaseRequest, innerLoading: true } :
					purchaseRequest
				)*/
			};
		case ApprovalStatusActionTypes.FETCH_AS_LINES_SUCCESS:

			return {
				...state,
				itemLoading: false,
				approvalItems: action.items
				/*approvalStatusList: state.approvalStatusList.map(purchaseRequest => purchaseRequest.Aribapr === action.Aribapr ?

					{ ...purchaseRequest, PRLines: action.items, innerLoading: false } :
					purchaseRequest
				)*/
			};

		case ApprovalStatusActionTypes.FETCH_AS_LINES_FAILURE:
			return {
				...state,
				/*approvalStatusList: state.approvalStatusList.map(purchaseRequest => purchaseRequest.Aribapr === action.error.Aribapr ?

					{ ...purchaseRequest, innerLoading: false } :
					purchaseRequest
				),*/
				error: action.error,
				itemLoading: false
			};

		case ApprovalStatusActionTypes.FETCH_AS_FAILURE:
			const failurePartialState = { error: action.error, loading: false, approvalStatusList: [],
				totalCount: 0 };
			return merge(state, failurePartialState);
		case ApprovalStatusActionTypes.AS_DEFAULT_ALIYS:
			return {
				...state,
			};
		case ApprovalStatusActionTypes.AS_DEFAULT_ALIYS_SUCCESS:
			return {
				...state,
				defaultAliys: action.aliys
			};
		default:
			return state;
	}
}