import {
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../../shared/domains/core/actions";

export enum BAActionTypes {

  INIT_BA_FILTERS = "INIT_BA_FILTERS",

  BA_GET_FILTERS = "BA_GET_FILTERS",
  BA_GET_FILTERS_SUCCESS = "BA_GET_FILTERS_SUCCESS",
  BA_GET_FILTERS_FAILURE = "BA_GET_FILTERS_FAILURE",

  BA_NEW_FILTER = 'BA_NEW_FILTER',
  DELETE_BA_FILTER = "DELETE_BA_FILTER",
  RESET_BA_FILTERS = "RESET_BA_FILTERS",
  RESET_BA_FILTERS_SUCCESS = "RESET_BA_FILTERS_SUCCESS",
  
  UPDATE_BA_FILTER_SUCCESS = 'UPDATE_BA_FILTER_SUCCESS',
  UPDATE_BA_FILTER_FAILURE = 'UPDATE_BA_FILTER_FAILURE',
}

export class NewFilters extends PendingAction<BAActionTypes> {
  public readonly type = BAActionTypes.BA_NEW_FILTER;
  constructor(public filters, public query, public prefferedCode, public alias) {
    super();
  }
}

export class InitBAFilters extends PendingAction<BAActionTypes> {
  public readonly type = BAActionTypes.INIT_BA_FILTERS;
  constructor(public filters) {
    super();
  }
}

export class GetFilters extends PendingAction<BAActionTypes> {
  public readonly type = BAActionTypes.BA_GET_FILTERS;
}

export class GetFiltersSuccess extends SuccessAction<BAActionTypes> {
  public readonly type = BAActionTypes.BA_GET_FILTERS_SUCCESS;
}

export class GetFiltersError extends ErrorAction<BAActionTypes> {
  public readonly type = BAActionTypes.BA_GET_FILTERS_FAILURE;
  constructor(public error) {
    super(error, true);
}
}

export class ResetBAFilters extends PendingAction<BAActionTypes> {
  public readonly type = BAActionTypes.RESET_BA_FILTERS;
  constructor(public prefferedCode, public positionId) {
    super();
  }
}

export class ResetBAFiltersSuccess extends PendingAction<BAActionTypes> {
  public readonly type = BAActionTypes.RESET_BA_FILTERS_SUCCESS;
  constructor(public filters) {
    super();
  }
}