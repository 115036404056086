import { IMessage } from "src/app/shared/domains/chat/roomChat.service";
import { IColumnsList } from "src/app/shared/domains/user/columns.hook";


export enum NbsCockpitTabs {
  EASY_REQUEST = "EASY_REQUEST",
  BLOCKED_PARKED = "BLOCKED_PARKED",
  SAP_SCOPE = "SAP_SCOPE",
  TRACKER = "TRACKER",
}

export enum NbsBlockedParkedTabs {
  PENDING = "Pend_flag",
  WAITING = "Wait_Flag",
  COMPLETED = "Comp_Flag",
}

export enum Accgroup {
  Z003 = "One Time Vendor",
  Z012 = "Collective Medical Vendor"
}

export const NbsCockpitSearchOptions = [
  { value: "PRNo_Search", label: "Purchase Requisition" },
  { value: "PONo_Search", label: "Purchase Order" }
];

export enum NbsCockpitEasyRequestTabColumns {
  CHAT = 'Chat',
  REQUEST_DATE = 'Request Date',
  AGING = 'Aging',
  CATEGORY = 'Category',
  PR = 'PR',
  PO = 'PO',
  SPENDER = 'Spender',
  REQUESTOR = 'Requestor',
  PURCHASING_ORGANIZATION = 'Purchasing Organization',
  COMPANY_CODE = 'Company Code',
  COMPLIANCE_INDICATOR = 'Compliance Indicator',
  FILEDS_TO_VALIDATE = 'Fields To Validate'
}

export enum NbsBlockedParkedTabColumns {
  CHAT = 'Chat',
  NOTES = 'Notes',
  DUE_DATE = 'Due Date',
  AGING = 'Aging',
  SUPPLIER = 'Supplier',
  NESTLE_INVOICE_NUMBER = 'Nestle Invoice',
  COMPANY_CODE = 'Company Code',
  PURCHASING_ORGANIZATION = 'Purchasing Organization',
  AMOUNT = 'Amount',
  ISSUE_TYPE = 'Issue Type',
  REASON = 'Reason',
  WORKFLOW_AGENT = 'Workflow Agent',
  TO_ACTOR_ON = 'To Actor On',
  TASK_DATE = 'Task Date',
  INSTRUCTION_DATE = 'Instruction Date',
  PURCHASING_GROUP = 'Purchasing Group',
  PLANT = 'Plant',
  REQUESTER = 'Requestor',
  SPENDER = 'Spender',
  SUPPLIER_INVOICE_NUMBER = 'Supplier Invoice Number',
  TASK_RESPONSIBLE = 'Task Responsible',
}

export enum NbsSapScopeTabColumns {
  PR = 'PR',
  PO = 'PO',
  AMOUNT = 'Amount EUR',
  THRESHOLD_AMOUNT = 'Threshold Amount',
  THRESHOLD_STATUS = 'Threshold Status',
  COMPLIANCE_INDICATOR = 'Compliance Indicator',
  CREATOR = 'Creator'
}

export enum NbsSapScopeLineItemsColumns {
  ITEM = 'Item',
  FIELD = 'Field',
  OLD_VALUE = 'Old Value',
  NEW_VALUE = 'New Value',
  TIME = 'Time',
  DATE = 'Date',
  UPDATED_BY = 'Updated By'
}

export enum NbsTrackerTabColumns {
  NESTLE_INVOICE_NUMBER = 'Nestle Invoice',
  PO_NO = "PO Number",
  REQUESTER = 'Requestor',
  SUPPLIER = 'Supplier',
  SUPPLIER_INVOICE_NUMBER = 'Supplier Invoice Number',
  PURCHASING_GROUP = 'Purchasing Group',
  POSTING_DATE = 'Posting Date',
  REASON = 'Reason',
  PROCESSING_DATE = "Processing Date",
  TASK_RESPONSIBLE = 'Task Responsible',
  TASK_TAGGED_TO = "Task Tagged To",
  STATUS = "Status",
  INSTRUCTION_DATE = "Instruction Date",
  COMPANY_CODE = 'Company Code',
  COMMENTs = "Comments",
  DUE_DATE = "Due Date",
  TO_ACTOR_ON = "To Actor On",
  AGING = "Aging"
}

export interface NbsCockpitEasyRequestTabColumnsList extends IColumnsList {
  id: NbsCockpitEasyRequestTabColumns;
}

export interface NbsBlockedParkedTabColumnsList extends IColumnsList {
  id: NbsBlockedParkedTabColumns;
}

export interface NbsSapScopeTabColumnsList extends IColumnsList {
  id: NbsSapScopeTabColumns;
}


export interface NbsTrackerTabColumnsList extends IColumnsList {
  id: NbsTrackerTabColumns;
}

export function GetNbsEasyReqColumns(columns?: NbsCockpitEasyRequestTabColumnsList[]): NbsCockpitEasyRequestTabColumnsList[] {
  return columns?.length > 0 ? columns : DefaultNbsCockpitEasyRequestTabColumns();
}

export function GetNbsBlockedParkedColumns(columns?: NbsBlockedParkedTabColumnsList[]): NbsBlockedParkedTabColumnsList[] {
  return columns?.length > 0 ? columns : DefaultNbsBlockedParkedTabColumns();
}

export function GetNbsSapScopeColumns(columns?: NbsSapScopeTabColumnsList[]): NbsSapScopeTabColumnsList[] {
  return columns?.length > 0 ? columns : DefaultNbsSapScopeTabColumns();
}

export function GetNbsTrackerColumns(columns?: NbsTrackerTabColumnsList[]): NbsTrackerTabColumnsList[] {
  return columns?.length > 0 ? columns : DefaultNbsTrackerTabColumns();
}

export function DefaultNbsCockpitEasyRequestTabColumns() {
  return [
    { id: NbsCockpitEasyRequestTabColumns.CHAT, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.CATEGORY, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.REQUEST_DATE, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.AGING, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.PR, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.PO, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.REQUESTOR, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.SPENDER, show: false, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.PURCHASING_ORGANIZATION, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.COMPANY_CODE, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.COMPLIANCE_INDICATOR, show: true, hidden: false },
    { id: NbsCockpitEasyRequestTabColumns.FILEDS_TO_VALIDATE, show: true, hidden: false },
  ];
}

export function DefaultNbsBlockedParkedTabColumns() {
  return [
    { id: NbsBlockedParkedTabColumns.CHAT, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.NOTES, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.DUE_DATE, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.AGING, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.SUPPLIER, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.NESTLE_INVOICE_NUMBER, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.COMPANY_CODE, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.PURCHASING_ORGANIZATION, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.AMOUNT, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.ISSUE_TYPE, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.REASON, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.WORKFLOW_AGENT, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.TO_ACTOR_ON, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.TASK_DATE, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.INSTRUCTION_DATE, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.PURCHASING_GROUP, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.PLANT, show: true, hidden: false },
    { id: NbsBlockedParkedTabColumns.REQUESTER, show: false, hidden: false },
    { id: NbsBlockedParkedTabColumns.SPENDER, show: false, hidden: false },
    { id: NbsBlockedParkedTabColumns.SUPPLIER_INVOICE_NUMBER, show: false, hidden: false },
    { id: NbsBlockedParkedTabColumns.TASK_RESPONSIBLE, show: true, hidden: false },
  ];
}

export function DefaultNbsSapScopeTabColumns() {
  return [
    { id: NbsSapScopeTabColumns.PR, show: true, hidden: false },
    { id: NbsSapScopeTabColumns.PO, show: true, hidden: false },
    { id: NbsSapScopeTabColumns.AMOUNT, show: true, hidden: false },
    { id: NbsSapScopeTabColumns.THRESHOLD_AMOUNT, show: true, hidden: false },
    { id: NbsSapScopeTabColumns.THRESHOLD_STATUS, show: true, hidden: false },
    { id: NbsSapScopeTabColumns.COMPLIANCE_INDICATOR, show: true, hidden: false },
    { id: NbsSapScopeTabColumns.CREATOR, show: true, hidden: false }
  ];
}

export function DefaultNbsSapScopeLineItemsColumns() {
  return [
    { id: NbsSapScopeLineItemsColumns.ITEM, show: true, hidden: false },
    { id: NbsSapScopeLineItemsColumns.FIELD, show: true, hidden: false },
    { id: NbsSapScopeLineItemsColumns.OLD_VALUE, show: true, hidden: false },
    { id: NbsSapScopeLineItemsColumns.NEW_VALUE, show: true, hidden: false },
    { id: NbsSapScopeLineItemsColumns.TIME, show: true, hidden: false },
    { id: NbsSapScopeLineItemsColumns.DATE, show: true, hidden: false },
    { id: NbsSapScopeLineItemsColumns.UPDATED_BY, show: true, hidden: false }
  ];
}

export function DefaultNbsTrackerTabColumns() {
  return [
    { id: NbsTrackerTabColumns.NESTLE_INVOICE_NUMBER, show: true, hidden: false },
    { id: NbsTrackerTabColumns.PO_NO, show: true, hidden: false },
    { id: NbsTrackerTabColumns.REQUESTER, show: true, hidden: false },
    { id: NbsTrackerTabColumns.SUPPLIER, show: true, hidden: false },
    { id: NbsTrackerTabColumns.SUPPLIER_INVOICE_NUMBER, show: true, hidden: false },
    { id: NbsTrackerTabColumns.PURCHASING_GROUP, show: true, hidden: false },
    { id: NbsTrackerTabColumns.POSTING_DATE, show: true, hidden: false },
    { id: NbsTrackerTabColumns.REASON, show: true, hidden: false },
    { id: NbsTrackerTabColumns.PROCESSING_DATE, show: true, hidden: false },
    { id: NbsTrackerTabColumns.TASK_RESPONSIBLE, show: true, hidden: false },
    { id: NbsTrackerTabColumns.TASK_TAGGED_TO, show: true, hidden: false },
    { id: NbsTrackerTabColumns.COMMENTs, show: true, hidden: false },
    { id: NbsTrackerTabColumns.STATUS, show: true, hidden: false },
    { id: NbsTrackerTabColumns.INSTRUCTION_DATE, show: true, hidden: false },
    { id: NbsTrackerTabColumns.COMPANY_CODE, show: true, hidden: false },
    { id: NbsTrackerTabColumns.DUE_DATE, show: true, hidden: false },
    { id: NbsTrackerTabColumns.TO_ACTOR_ON, show: true, hidden: false },
    { id: NbsTrackerTabColumns.AGING, show: true, hidden: false },
  ];
}

export const easyReqAgingOptions = [
  {
    "label": "Less than a day", "value": "<1",
  },
  {
    "label": "From 1 to 2 days", "value": ">=1<=2",
  },
  {
    "label": "More than 2 days", "value": ">2"
  }
]
export const blockedParkedAgingOptions = [
  {
    "label": "Red (≥ 0 day)", "value": ">=0",
  },
  {
    "label": "Yellow (≥ -10 and < 0 day)", "value": ">=-10<0",
  },
  {
    "label": "Green (≥ -10 and < 0 day)", "value": "<-10"
  }
]
export const trackerStatusOptions = [
  {
    "value": "READY", "label": "Ready",
  },
  {
    "value": "STARTED", "label": "Started",
  },
  {
    "value": "COMMITTED", "label": "Commited"
  },
  {
    "value": "WAITING", "label": "Waiting"
  },
  {
    "value": "COMPLETED", "label": "Completed"
  }
]
export const blockedParkedToActorOnOptions = [
  {
    "value": "AP-CLERK", "label": "AP-Clerk",
  },
  {
    "value": "AP-SUPERVISOR", "label": "AP-Supervisor",
  },
  {
    "value": "GOODS RECEIVER", "label": "Goods Receiver"
  },
  {
    "value": "OPERATIONAL BUYER", "label": "Operational Buyer"
  },
  {
    "value": "QUALITY INSPECTOR", "label": "Quality Inspector"
  },
  {
    "value": "STRATEGIC BUYER", "label": "Strategic Buyer"
  },
  {
    "value": "VENDOR MD INITIATOR", "label": "Vendor MD Initiator"
  },
  {
    "value": "MARKET AP SUPERVISOR", "label": "Market AP Supervisor"
  }
]

export const tempBlockedParkedToActorOnOptions = [
  {
    "value": "GOODS RECEIVER", "label": "Goods Receiver"
  },
  {
    "value": "OPERATIONAL BUYER", "label": "Operational Buyer"
  },
  {
    "value": "QUALITY INSPECTOR", "label": "Quality Inspector"
  },
  {
    "value": "STRATEGIC BUYER", "label": "Strategic Buyer"
  },
  {
    "value": "VENDOR MD INITIATOR", "label": "Vendor MD Initiator"
  },
  {
    "value": "MARKET AP SUPERVISOR", "label": "Market AP Supervisor"
  }
]

export const statusOptions = [
  {
    "label": "Blocked", "value": "Blocked",
  },
  {
    "label": "Parked", "value": "Parked",
  }
]

export const ftvvalidationOptions = [
  {
    "label": "Tax code", "value": "Taxcode"
  },
  {
    "label": "One Time Vendor / CMV", "value": "OneTimeVendor"
  },
  {
    "label": "Payment Terms", "value": "PaymentTerms"
  },
  {
    "label": "Competitive Bidding", "value": "CompetitiveBidding"
  },
  {
    "label": "Way of Buying", "value": "WayofBuying"
  },
  {
    "label": "Purchasing Group", "value": "PurchasingGroup"
  }
];

export enum NbsCockpitDataTypes {
  Compliant = 'Compliant',
  NonCompliant = 'NonCompliant',
  Change = 'Change',
  Creation = 'Creation'
}

export enum WaysOfBuying {
  NB = 'Standard Requisition',
  FO = 'Framework Requisition',
  Z004 = 'Capex Requisition',
}

// Easy Request Filters

export const defaultNbsEasyRequestFiltersData = {
  PurchOrg: {
    "data": [],
    "key": "PurchOrg",
    "label": "Purchasing Organization",
    "selected": []
  },
  CompanyCode: {
    "data": [],
    "key": "CompanyCode",
    "label": "Company Code",
    "selected": []
  },
  ChangedOn: {
    "data": [],
    "key": "ChangedOn",
    "label": "Request Date",
    "selected": {}
  },
  AgingDays: {
    "data": [],
    "key": "AgingDays",
    "label": "Aging Days",
    "selected": []
  },
  Category: {
    "data": [],
    "key": "Category",
    "label": "Category",
    "selected": []
  },
  FTValidation: {
    "data": [],
    "key": "FTValidation",
    "label": "Fields To Validate",
    "selected": []
  },
  PurchaseReqNo: {
    "data": [],
    "key": "PurchaseReqNo",
    "label": "Purchase Requisition Number",
    "selected": []
  },
  PurchaseOrdNo: {
    "data": [],
    "key": "PurchaseOrdNo",
    "label": "Purchase Order Number",
    "selected": []
  },
  PrCreatorName: {
    "data": [],
    "key": "PrCreatorName",
    "label": "Requestor",
    "selected": []
  },
  Requisitioner: {
    "data": [],
    "key": "Requisitioner",
    "label": "Spender",
    "selected": []
  },
  CollectiveNumber: {
    "data": [],
    "key": "CollectiveNumber",
    "label": "Compliance Indicator",
    "selected": []
  },
  Resend_po: {
    "data": [],
    "key": "Resend_po",
    "label": "Resend Po",
    "selected": []
  },
  PRNo_Search: {
    "data": [],
    "key": "PRNo_Search",
    "label": "Pr Number Search",
    "selected": []
  },
  PONo_Search: {
    "data": [],
    "key": "PONo_Search",
    "label": "PO Number Search",
    "selected": []
  }
}

export const defaultNbsBlockedParkedFiltersData = {
  FiscalYr: {
    "data": [],
    "key": "FiscalYr",
    "label": "Fiscal Year",
    "selected": []
  },
  CompCode: {
    "data": [],
    "key": "CompCode",
    "label": "Company Code",
    "selected": []
  },
  InvDueDate: {
    "data": [],
    "key": "InvDueDate",
    "label": "Due Date",
    "selected": null
  },
  AgingDays: {
    "data": [],
    "key": "AgingDays",
    "label": "Aging Days",
    "selected": []
  },
  InvPartner: {
    "data": [],
    "key": "InvPartner",
    "label": "Supplier",
    "selected": []
  },
  PurchOrg: {
    "data": [],
    "key": "PurchOrg",
    "label": "Purchasing Organisation",
    "selected": []
  },
  InvDocNo: {
    "data": [],
    "key": "InvDocNo",
    "label": "Nestle Invoice Number",
    "selected": []
  },
  PriceRangeFrom: {
    "data": [],
    "key": "PriceRangeFrom",
    "label": "Price Range From",
    "selected": null
  },
  PriceRangeTo: {
    "data": [],
    "key": "PriceRangeTo",
    "label": "Price Range To",
    "selected": null
  },
  NbsStatus_Value: {
    "data": [],
    "key": "NbsStatus_Value",
    "label": "Issue Type",
    "selected": []
  },
  ParkRsnCode: {
    "data": [],
    "key": "ParkRsnCode",
    "label": "Parking Reason Code",
    "selected": []
  },
  BlckRsn: {
    "data": [],
    "key": "BlckRsn",
    "label": "Blocking Reason Code",
    "selected": []
  },
  WfAgent: {
    "data": [],
    "key": "WfAgent",
    "label": "Workflow Agent",
    "selected": []
  },
  To_actor: {
    "data": [],
    "key": "To_actor",
    "label": "To Actor On",
    "selected": []
  },
  PurchGrp: {
    "data": [],
    "key": "PurchGrp",
    "label": "Purchasing Group",
    "selected": []
  },
  Plant: {
    "data": [],
    "key": "Plant",
    "label": "Plant",
    "selected": []
  },
  taskResponsible: {
    "data": [],
    "key": "taskResponsible",
    "label": "Task Responsible",
    "selected": []
  },
  PrCreatorName: {
    "data": [],
    "key": "PrCreatorName",
    "label": "Requestor",
    "selected": []
  },
  Requisitioner: {
    "data": [],
    "key": "Requisitioner",
    "label": "Spender",
    "selected": []
  },
  RefDoc: {
    "data": [],
    "key": "RefDoc",
    "label": "Supplier Invoice Number",
    "selected": []
  },
  WfCd: {
    "data": [],
    "key": "WfCd",
    "label": "Task Date",
    "selected": null
  },
  InstructionDate: {
    "data": [],
    "key": "InstructionDate",
    "label": "Instruction Date",
    "selected": null
  },
  remainderFilter: {
    "data": [],
    "key": "remainderFilter",
    "label": "Remainder Filter",
    "selected": null
  }
}

export const defaultNbsSapScopeFiltersData = {
  prNo: {
    "data": [],
    "key": "prNo",
    "label": "PR No",
    "selected": []
  },
  poNo: {
    "data": [],
    "key": "poNo",
    "label": "PO No",
    "selected": []
  },
  priceRangeTo: {
    "data": [],
    "key": "priceRangeTo",
    "label": "Price Range To",
    "selected": null
  },
  priceRangeFrom: {
    "data": [],
    "key": "priceRangeFrom",
    "label": "Price Range From",
    "selected": null
  },
  thresholdRangeTo: {
    "data": [],
    "key": "thresholdRangeTo",
    "label": "Threshold Range To",
    "selected": null
  },
  thresholdRangeFrom: {
    "data": [],
    "key": "thresholdRangeFrom",
    "label": "Threshold Range From",
    "selected": null
  },
  thresholdStatus: {
    "data": [],
    "key": "thresholdStatus",
    "label": "Threshold Status",
    "selected": []
  },
  complianceIndicator: {
    "data": [],
    "key": "complianceIndicator",
    "label": "Compliance Indicator",
    "selected": []
  },
  creator: {
    "data": [],
    "key": "creator",
    "label": "Creator",
    "selected": []
  }
}
export const defaultNbsTrackerFiltersData = {
  companyCode: {
    "data": [],
    "key": "companyCode",
    "label": "Company Code",
    "selected": []
  },
  fiscalYear: {
    "data": [],
    "key": "fiscalYear",
    "label": "Fiscal Year",
    "selected": []
  },
  invoiceNumber: {
    "data": [],
    "key": "invoiceNumber",
    "label": "Invoice Number",
    "selected": []
  },
  poNumber: {
    "data": [],
    "key": "poNumber",
    "label": "Po No",
    "selected": []
  },
  requestorID: {
    "data": [],
    "key": "requestorID",
    "label": "Requestor",
    "selected": []
  },
  vendorNumber: {
    "data": [],
    "key": "vendorNumber",
    "label": "Supplier",
    "selected": []
  },
  supplierInvoiceNumber: {
    "data": [],
    "key": "supplierInvoiceNumber",
    "label": "Supplier Invoice Number",
    "selected": []
  },
  postingDate: {
    "data": [],
    "key": "postingDate",
    "label": "Blocking Reason Date",
    "selected": null
  },
  parkingBlockingReason: {
    "data": [],
    "key": "parkingBlockingReason",
    "label": "Blocking Reason Code",
    "selected": []
  },
  dateOfProcessing: {
    "data": [],
    "key": "dateOfProcessing",
    "label": "Processing Date",
    "selected": null
  },
  purchashingGroup: {
    "data": [],
    "key": "purchashingGroup",
    "label": "Purchasing Group",
    "selected": []
  },
  taskResponsible: {
    "data": [],
    "key": "taskResponsible",
    "label": "Task Responsible",
    "selected": []
  },
  taskTaggedTo: {
    "data": [],
    "key": "taskTaggedTo",
    "label": "Task Reffered To",
    "selected": []
  },
  status: {
    "data": [],
    "key": "status",
    "label": "Status",
    "selected": []
  },
  instructionDate: {
    "data": [],
    "key": "instructionDate",
    "label": "Instruction Date",
    "selected": null
  },
  dueDate: {
    "data": [],
    "key": "dueDate",
    "label": "Due Date",
    "selected": null
  },
  aging: {
    "data": [],
    "key": "aging",
    "label": "Aging",
    "selected": []
  },
  toActorOn: {
    "data": [],
    "key": "toActorOn",
    "label": "To Actor On",
    "selected": []
  },
}

export enum EasyRequestFilterKeys {
  PURCHASING_ORGANIZATION = 'PurchOrg',
  FILEDS_TO_VALIDATE = 'FTValidation',
  CATEGORY = 'Category',
  REQUEST_DATE = 'ChangedOn',
  AGING = 'AgingDays',
  COMPLIANCE_INDICATOR = 'CollectiveNumber',
  COMPANY_CODE = 'CompanyCode',
  PR_NUMBER = 'PurchaseReqNo',
  PO_NUMBER = 'PurchaseOrdNo',
  SPENDER = 'Requisitioner',
  REQUESTOR = 'PrCreatorName',
  RESEND_PO = 'Resend_po'
}

export enum BlockedParkedtFilterKeys {
  AGING = 'AgingDays',
  ASSIGNED_TO = 'WfAgent',
  TO_ACTOR_ON = "To_actor",
  BLOCKING_REASON_CODE = 'BlckRsn',
  FISCAL_YEAR = 'FiscalYr',
  DUE_DATE = 'InvDueDate',
  COMPANY_CODE = 'CompCode',
  PURCHASING_ORGANIZATION = 'PurchOrg',
  NESTLE_INVOICE_NUMBER = 'InvDocNo',
  PRICE_RANGE_FROM = 'PriceRangeFrom',
  PRICE_RANGE_TO = 'PriceRangeTo',
  STATUS = 'NbsStatus_Value',
  PARKING_REASON_CODE = "ParkRsnCode",
  PURCH_GROUP = 'PurchGrp',
  PLANT = 'Plant',
  SPENDER = 'Requisitioner',
  REQUESTOR = 'PrCreatorName',
  NESTLE_SUPPLIER_INVOICE = 'RefDoc',
  TASK_DATE = 'WfCd',
  INSTRUCTION_DATE = 'InstructionDate',
  REMAINDER_FILTER = "remainderFilter",
  Task_Responsible = "taskResponsible",
  VENDOR_CODE = "InvPartner"
}

export enum SapScopeFilterKeys {
  PR_NO = "prNo",
  PO_NO = "poNo",
  PRICE_RANGE_TO = 'priceRangeTo',
  PRICE_RANGE_FROM = 'priceRangeFrom',
  THRESHOLD_RANGE_TO = 'thresholdRangeTo',
  THRESHOLD_RANGE_FROM = 'thresholdRangeFrom',
  THRESHOLD_STATUS = "thresholdStatus",
  COMPLIANCE_INDICATOR = "complianceIndicator",
  CREATOR = "creator"
}

export enum TrackerFilterKeys {
  Invoice_Number = "invoiceNumber",
  Po_No = "poNumber",
  Requestor = "requestorID",
  Vendor_No = "vendorNumber",
  Supplier_Invoice_Number = "supplierInvoiceNumber",
  Purchasing_Group = "purchasingGroup",
  Posting_Date = "postingDate",
  Blocking_Reason_Code = "parkingBlockingReason",
  Processing_Date = "dateOfProcessing",
  Task_Responsible = "taskResponsible",
  Task_Reffered_To = "taskTaggedTo",
  Comments = "comments",
  Fiscal_Year = "fiscalYear",
  Status = "status",
  Instruction_Date = "instructionDate",
  Company_Code = "companyCode",
  Due_Date = "dueDate",
  Aging = "aging",
  to_Actor_On = "toActorOn"
}

export const EasyRequestFilters = [
  { key: EasyRequestFilterKeys.PURCHASING_ORGANIZATION, label: 'Purchasing Organization' },
  { key: EasyRequestFilterKeys.COMPANY_CODE, label: 'Company Code' },
  { key: EasyRequestFilterKeys.REQUEST_DATE, label: 'Request Date' },
  { key: EasyRequestFilterKeys.AGING, label: 'Aging Days' },
  { key: EasyRequestFilterKeys.CATEGORY, label: 'Category' },
  { key: EasyRequestFilterKeys.FILEDS_TO_VALIDATE, label: 'Fields To Validate' },
  { key: EasyRequestFilterKeys.PR_NUMBER, label: 'Purchase Requisition Number' },
  { key: EasyRequestFilterKeys.PO_NUMBER, label: 'Purchase Order Number' },
  { key: EasyRequestFilterKeys.REQUESTOR, label: 'Requestor' },
  { key: EasyRequestFilterKeys.SPENDER, label: 'Spender' },
  { key: EasyRequestFilterKeys.COMPLIANCE_INDICATOR, label: 'Compliance Indicator' },
  { key: EasyRequestFilterKeys.RESEND_PO, label: 'Resend PO' }
];

export const BlockedParkedFilters = [
  { key: BlockedParkedtFilterKeys.COMPANY_CODE, label: 'Company Code' },
  { key: BlockedParkedtFilterKeys.FISCAL_YEAR, label: 'Fiscal Year' },
  { key: BlockedParkedtFilterKeys.AGING, label: 'Aging Days' },
  { key: BlockedParkedtFilterKeys.DUE_DATE, label: 'Due Date' },
  { key: BlockedParkedtFilterKeys.STATUS, label: 'Issue Type' },
  { key: BlockedParkedtFilterKeys.PURCHASING_ORGANIZATION, label: 'Purchasing Organisation' },
  { key: BlockedParkedtFilterKeys.NESTLE_INVOICE_NUMBER, label: 'Neslte Invoice Number' },
  { key: BlockedParkedtFilterKeys.PRICE_RANGE_FROM, label: 'Price From' },
  { key: BlockedParkedtFilterKeys.PRICE_RANGE_TO, label: 'Price To' },
  { key: BlockedParkedtFilterKeys.BLOCKING_REASON_CODE, label: 'Blocking Reason Code' },
  { key: BlockedParkedtFilterKeys.PARKING_REASON_CODE, label: 'Parking Reason Code' },
  { key: BlockedParkedtFilterKeys.ASSIGNED_TO, label: 'Workflow Agent' },
  { key: BlockedParkedtFilterKeys.TO_ACTOR_ON, label: 'To Actor On' },
  { key: BlockedParkedtFilterKeys.TASK_DATE, label: 'Task Date' },
  { key: BlockedParkedtFilterKeys.INSTRUCTION_DATE, label: 'Instruction date' },
  { key: BlockedParkedtFilterKeys.PURCH_GROUP, label: 'Purchasing Group' },
  { key: BlockedParkedtFilterKeys.PLANT, label: 'Plant' },
  { key: BlockedParkedtFilterKeys.REQUESTOR, label: 'Requestor' },
  { key: BlockedParkedtFilterKeys.SPENDER, label: 'Spender' },
  { key: BlockedParkedtFilterKeys.NESTLE_SUPPLIER_INVOICE, label: 'Supplier Invoice Number' },
  { key: BlockedParkedtFilterKeys.REMAINDER_FILTER, label: 'Remainder Filters' },
  { key: BlockedParkedtFilterKeys.Task_Responsible, label: 'Task Responsible' },
  { key: BlockedParkedtFilterKeys.VENDOR_CODE, label: 'Supplier' },
];

export const TrackerFilters = [
  { key: TrackerFilterKeys.Company_Code, label: 'Company' },
  { key: TrackerFilterKeys.Fiscal_Year, label: 'Fiscal Year' },
  { key: TrackerFilterKeys.Invoice_Number, label: 'invoice Number' },
  { key: TrackerFilterKeys.Po_No, label: 'Po No' },
  { key: TrackerFilterKeys.Requestor, label: 'Requestor' },
  { key: TrackerFilterKeys.Vendor_No, label: 'Vendor' },
  { key: TrackerFilterKeys.Supplier_Invoice_Number, label: 'Supplier Invoice Number' },
  { key: TrackerFilterKeys.Purchasing_Group, label: 'Purchasing Group' },
  { key: TrackerFilterKeys.Posting_Date, label: 'Posting Date' },
  { key: TrackerFilterKeys.Blocking_Reason_Code, label: 'Blocking Reason Code' },
  { key: TrackerFilterKeys.Processing_Date, label: 'Processing Date' },
  { key: TrackerFilterKeys.Task_Responsible, label: 'Task Responsible' },
  { key: TrackerFilterKeys.Task_Reffered_To, label: 'Task Reffered To' },
  { key: TrackerFilterKeys.Comments, label: 'Comments' },
  { key: TrackerFilterKeys.Status, label: 'Status' },
  { key: TrackerFilterKeys.Instruction_Date, label: 'Instruction Date' },
  { key: TrackerFilterKeys.Due_Date, label: 'Due Date' },
  { key: TrackerFilterKeys.Aging, label: 'Aging' },
  { key: TrackerFilterKeys.to_Actor_On, label: 'To Actor On' },
];

export const SapScopeFilters = [
  { key: SapScopeFilterKeys.PO_NO, label: 'PO Number' },
  { key: SapScopeFilterKeys.PR_NO, label: 'PR Number' },
  { key: SapScopeFilterKeys.PRICE_RANGE_TO, label: 'Price To' },
  { key: SapScopeFilterKeys.PRICE_RANGE_FROM, label: 'Price From' },
  { key: SapScopeFilterKeys.THRESHOLD_RANGE_TO, label: 'Threshold To' },
  { key: SapScopeFilterKeys.THRESHOLD_RANGE_FROM, label: 'Threshold From' },
  { key: SapScopeFilterKeys.THRESHOLD_STATUS, label: 'Threshold Status' },
  { key: SapScopeFilterKeys.COMPLIANCE_INDICATOR, label: 'Compliance Indicator' },
  { key: SapScopeFilterKeys.CREATOR, label: 'Creator' },
];

export const nbsCompIndicatorOptions = [
  { value: "P", label: "Compliant" },
  { value: "X", label: "Non Compliant" },
];

export const nbsThresholdOptions = [
  { value: "Exceeded", label: "Exceeded" },
  { value: "Not Exceeded", label: "Not Exceeded" },
];
// Easy Request Flyout

export enum TCode {
  PR_DISPLAY = "/GLB/RGTPP01_ME53N"
}

// Easy Request Chat

interface reFormatDataI {
  fname: string,
  Lastname: string,
  Id: string,
  email: string,
  role: string
}

interface IMessageWithModifiedMentionedPerson extends IMessage {
  modifiedMentionPersons?: reFormatDataI[]
}

export interface chatDataWithRefsI {
  chatData: IMessageWithModifiedMentionedPerson;
  refs: any
}

export enum NbsMaintencePopUpFieldsKeys {
  PURCH_ORG = 'PurchOrg',
  COMP_CODE = 'POCompanyCode',
  PURCH_GROUP = 'PurchGroup',
  PLANT = 'PRPlant',
  MATERIAL_TYPE = 'MeterialType',
  PARKING_OR_BLOCKING_REASON = 'ParkingBlockingReason',
  RECEIVER = 'Receiver',
  INSTRUCTIONS = 'Instructions'
}

export const NbsMaintencePopUpFields = [
  { key: NbsMaintencePopUpFieldsKeys.PURCH_ORG, label: 'Purchase Organization' }
]

// NBS Maintenance Table Columns

export interface NbsMainteanceTableColumnsList extends IColumnsList {
  id: NbsMainteanceTableColumns;
}

export function GetNbsMainteanceColumns(columns?: NbsMainteanceTableColumnsList[]): NbsMainteanceTableColumnsList[] {
  return columns?.length > 0 ? columns : DefaultNbsMaintenanceTableColumns();
}

export enum NbsMainteanceTableColumns {
  COMPANY_CODE = 'Company Code',
  PURCHASING_ORGANIZATION = 'Purchasing Organization',
  PURCHASE_GROUP = 'Purchase Group',
  PLANT = 'Plant',
  MATERIAL_TYPE = 'Material Type',
  PARKING_OR_BLOCKING_REASON = 'Parking Blocking Reason Code',
  RECEIVER = 'Receiver',
  INSTRUCTIONS = 'Instructions',
  CREATED_BY = 'Created By',
  CHANGED_BY = 'Changed By',
  CREATED_ON = 'Created On',
  CHANGED_ON = 'Changed On'
}

export function DefaultNbsMaintenanceTableColumns() {
  return [
    { id: NbsMainteanceTableColumns.COMPANY_CODE, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.PURCHASING_ORGANIZATION, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.PURCHASE_GROUP, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.PLANT, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.MATERIAL_TYPE, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.PARKING_OR_BLOCKING_REASON, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.RECEIVER, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.INSTRUCTIONS, show: true, hidden: false },
    { id: NbsMainteanceTableColumns.CREATED_BY, show: false, hidden: false },
    { id: NbsMainteanceTableColumns.CHANGED_BY, show: false, hidden: false },
    { id: NbsMainteanceTableColumns.CREATED_ON, show: false, hidden: false },
    { id: NbsMainteanceTableColumns.CHANGED_ON, show: false, hidden: false }
  ];
}

// NBS Maintence Page Filters

export const defaultNbsMaintenanceFiltersData = {
  CompanyCode: {
    "data": [],
    "key": "CompanyCode",
    "label": "Company Code",
    "selected": []
  },
  PurchasingOrganization: {
    "data": [],
    "key": "PurchasingOrganization",
    "label": "Purchasing Organization",
    "selected": []
  },
  PurchasingGroup: {
    "data": [],
    "key": "PurchasingGroup",
    "label": "Purchasing Group",
    "selected": []
  },
  Plant: {
    "data": [],
    "key": "Plant",
    "label": "Plant",
    "selected": []
  },
  MaterialType: {
    "data": [],
    "key": "MaterialType",
    "label": "Material Type",
    "selected": []
  },
  ParkingBlockingReason: {
    "data": [],
    "key": "ParkingBlockingReason",
    "label": "Parking Blocking Reason",
    "selected": []
  },
  Receiver: {
    "data": [],
    "key": "Receiver",
    "label": "Receiver",
    "selected": []
  },
  Instructions: {
    "data": [],
    "key": "Instructions",
    "label": "Instructions",
    "selected": null
  }
}

export enum NbsMaintenanceFilterKeys {
  COMPANY_CODE = 'CompanyCode',
  PURCHASING_ORGANIZATION = 'PurchasingOrganization',
  PURCHASE_GROUP = 'PurchasingGroup',
  PLANT = 'Plant',
  MATERIAL_TYPE = 'MaterialType',
  PARKING_OR_BLOCKING_REASON = 'ParkingBlockingReason',
  RECEIVER = 'Receiver',
  INSTRUCTIONS = "Instructions"
}

export const NbsMaintenanceFilter = [
  { key: NbsMaintenanceFilterKeys.COMPANY_CODE, label: 'Company Code' },
  { key: NbsMaintenanceFilterKeys.PURCHASING_ORGANIZATION, label: 'Purchasing Organization' },
  { key: NbsMaintenanceFilterKeys.PURCHASE_GROUP, label: 'Purchase Group' },
  { key: NbsMaintenanceFilterKeys.PLANT, label: 'Plant' },
  { key: NbsMaintenanceFilterKeys.MATERIAL_TYPE, label: 'Material Type' },
  { key: NbsMaintenanceFilterKeys.PARKING_OR_BLOCKING_REASON, label: 'Parking Blocking Reason' },
  { key: NbsMaintenanceFilterKeys.RECEIVER, label: 'Receiver' },
  { key: NbsMaintenanceFilterKeys.INSTRUCTIONS, label: 'Instructions' },
];
























