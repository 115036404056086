import {assoc, assocPath, mergeRight} from "ramda";
import {IAction} from "../../shared/domains/core/actions";
import {TenderCalendarActionTypes} from "../actions/calendar.actions";
import {BuyerDTO, CalendarViewMode, CodeNameDTO, IdNameDTO} from "../domains/calendar";
import {startDateByWeek} from "../domains/calendar.query";
import {IContractCalendarFilters} from "../../shared/domains/user/user";
import {TenderCalendarFilterActionTypes} from "../actions/calendarFilter.actions";
import {error, loaded, loading} from "../../shared/reducers/utils";

export interface TenderCalendarFiltersState {
  loading: boolean;
  viewMode: CalendarViewMode;
  startDate: string;
  spendCategories: CodeNameDTO[],
  clusters: CodeNameDTO[],
  zones: CodeNameDTO[],
  markets: CodeNameDTO[],
  companyCodes: CodeNameDTO[],
  plants: CodeNameDTO[],
  businesses: CodeNameDTO[],
  negotiationType: IdNameDTO[],
  buyers: BuyerDTO[];
  activeFilters: IContractCalendarFilters;
  hierarchyFilters: {
    markets: CodeNameDTO[],
    companyCodes: CodeNameDTO[],
    plants: CodeNameDTO[]
  }
}

const inititalActiveFilters = {
  buyers: [],
  spendCategories: [],
  clusters: [],
  zones: [],
  markets: [],
  companyCodes: [],
  plants: [],
  businesses: [],
  negotiationType: []
};

export const initialCalendarFilters: TenderCalendarFiltersState = {
  loading: false,
  viewMode: CalendarViewMode.MONTH,
  startDate: startDateByWeek.toFormat('yyyy-MM-dd'),
  spendCategories: [],
  clusters: [],
  zones: [],
  markets: [],
  companyCodes: [],
  plants: [],
  businesses: [],
  negotiationType: [],
  buyers: [],
  activeFilters: inititalActiveFilters,
  hierarchyFilters: {
    markets: [],
    companyCodes: [],
    plants: []
  }
};

export function TenderCalendarFiltersReducer(state: TenderCalendarFiltersState = initialCalendarFilters, action: IAction<TenderCalendarActionTypes|TenderCalendarFilterActionTypes>): TenderCalendarFiltersState {
  switch (action.type) {
    case TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT:
    case TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS:
      return loading(state);
    case TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT_SUCCESS:
      return loaded(mergeRight(state, {
        buyers: action.data.buyers,
        spendCategories: action.data.spendCategories,
        clusters: action.data.clusters,
        zones: action.data.zones,
        markets: action.data.markets,
        companyCodes: action.data.companyCodes,
        plants: action.data.plants,
        businesses: action.data.businesses,
        negotiationType: action.data.negotiationType
      }));
    case TenderCalendarFilterActionTypes.FETCH_HIERARCHY_MARKETS_BY_ZONE:
      return assoc('hierarchyFilters', initialCalendarFilters.hierarchyFilters, state);
    case TenderCalendarFilterActionTypes.FETCH_HIERARCHY_MARKETS_BY_ZONE_SUCCESS:
      return assocPath(['hierarchyFilters', 'markets'], action.markets, state);
    case TenderCalendarFilterActionTypes.FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET:
      return assoc('hierarchyFilters', {...state.hierarchyFilters, companyCodes: [], plants: []}, state);
    case TenderCalendarFilterActionTypes.FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET_SUCCESS:
      return assocPath(['hierarchyFilters', 'companyCodes'], action.companyCodes, state);
    case TenderCalendarFilterActionTypes.FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE:
      return assoc('hierarchyFilters', {...state.hierarchyFilters, plants: []}, state);
    case TenderCalendarFilterActionTypes.FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE_SUCCESS:
      return assocPath(['hierarchyFilters', 'plants'], action.plants, state);
    case TenderCalendarActionTypes.NEW_CALENDAR_START_DATE:
      return mergeRight(state, {viewMode: action.viewMode, startDate: action.startDate});
    case TenderCalendarActionTypes.NEW_CALENDAR_FILTER:
      return mergeRight(state, {
        activeFilters: {
          buyers: action.filters.buyers.selected,
          spendCategories: action.filters.spendCategories.selected,
          clusters: action.filters.clusters.selected,
          zones: action.filters.zones.selected,
          markets: action.filters.markets.selected,
          companyCodes: action.filters.companyCodes.selected,
          plants: action.filters.plants.selected,
          businesses: action.filters.businesses.selected,
          negotiationType: action.filters.negotiationType.selected
        }
      });
    case TenderCalendarFilterActionTypes.RESTORE_CALENDAR_FILTERS:
      return mergeRight(state, {activeFilters: action.filters});
    case TenderCalendarFilterActionTypes.DELETE_CALENDAR_FILTER:
      return mergeRight(state, {
        activeFilters: <any>assoc(action.key, state.activeFilters[action.key].filter(f => f.id !== action.id), state.activeFilters)
      });
    case TenderCalendarFilterActionTypes.RESET_CALENDAR_FILTER:
      return mergeRight(state, {activeFilters: inititalActiveFilters});
    case TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS_SUCCESS:
      return loaded(state);
    case TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}
