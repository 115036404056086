
const initialSpendCategoryTree = [];

export function spendCategoryTreeReducer(state = initialSpendCategoryTree, action) {
switch(action.type){
    case 'LOAD_SPEND_CATEGORY_TREE':
        return action.payload?action.payload: []
    
    default:
        return state;
}
}