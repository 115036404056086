interface NetworkPPMCostPlanDTO {
    __metadata: { id: string; type: string; uri: string };
    PurchaseReq: string
    PPMCstPln: string
}

export class PPMCostPlan {
    constructor(
        public PurchaseReq: string,
        public PPMCstPln: string
    ) {
    }

    static FromBackend(data: NetworkPPMCostPlanDTO): PPMCostPlan {
        return new PPMCostPlan(
            data.PurchaseReq,
            data.PPMCstPln,
        )
    }

    static toSelect(data: PPMCostPlan) {
        return {
            value: data.PPMCstPln,
            label: data.PPMCstPln
        }
    }
}
