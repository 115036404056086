import {OrderDirection} from "../../../lib/odata";
import {DateRangeFilter, FilterCategoryView, TextFilter} from "../domains/query/filter";
import {ErrorAction} from "../domains/core/actions";
import {Dictionary} from "ramda";
import { Query} from "./query";

export enum QueryActions {
  NEW_PAGE = 'NEW_PAGE',
  NEW_ROWS = 'NEW_ROWS',
  NEW_PAGE_SIZE = 'NEW_PAGE_SIZE',
  NEW_ORDER = 'NEW_ORDER',
  NEW_QUERY = 'NEW_QUERY',
  NEW_FILTERS = 'NEW_FILTERS',
  SAVE_FILTERS = 'SAVE_FILTERS',
  NEW_FILTER = 'NEW_FILTER',
  RESET_FILTERS_FOR_KEY = 'RESET_FILTERS_FOR_KEY',
  NEW_TERM = 'NEW_TERM',
  RESET_FILTERS = 'RESET_FILTERS',
  DELETE_FILTER = 'DELETE_FILTER',
  LOAD_FILTERS = 'LOAD_FILTERS',
  LOAD_FILTERS_SUCCESS = 'LOAD_FILTERS_SUCCESS',
  LOAD_FILTERS_ERROR = 'LOAD_FILTERS_ERROR',
  SET_DEFAULT_QUERY_DOC = 'SET_DEFAULT_QUERY_DOC'
}

export const richActionName = (domain: string) => (action: QueryActions): string => `@@${domain}/${action}`;

export interface NewFiltersAction extends WithListTypeAction {
  filters: Dictionary<FilterCategoryView>;
  skipPersist?: boolean;
}

export interface WithListTypeAction extends QueryAction {
  list?: string;
}

export interface QueryAction {
  type: string;
}

export function newPageAction(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.NEW_PAGE);

    constructor(public newPage: number, public list?: string) {}
  }
}

export function newPageRowAction(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.NEW_ROWS);

    constructor(public newRows: number, public list?: string) {}
  }
}

export function newPageSizeAction(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.NEW_PAGE_SIZE);

    constructor(public newPageSize: number, public list?: string) {}
  }
}

export function newOrderAction(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.NEW_ORDER);

    constructor(public column: string, public direction: OrderDirection, public list?: string) {}
  }
}

export function newFiltersAction(actionGenerator: Function) {
  return class implements NewFiltersAction {
    public type = actionGenerator(QueryActions.NEW_FILTERS);
    constructor(public filters: Dictionary<FilterCategoryView>, public list?: any, public skipPersist?: boolean) {}
  }
}

export function setDefaultDocQueryAction(actionGenerator: Function){
  return class {
    public type = actionGenerator(QueryActions.SET_DEFAULT_QUERY_DOC);
    constructor(public query: Query) {}
  }
}

export function resetFiltersAction(actionGenerator: Function) {
  return class implements WithListTypeAction {
    public type = actionGenerator(QueryActions.RESET_FILTERS);
    constructor(public list?: string) {}
  }
}

export function newFilterAction(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.NEW_FILTER);

    constructor(public filter: TextFilter | DateRangeFilter, public key: string) {}
  }
}

export function resetFiltersForKeyAction(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.RESET_FILTERS_FOR_KEY);

    constructor(public key: string) {}
  }
}

export function newTermAction(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.NEW_TERM);

    constructor(public term: string, public list?) {}
  }
}

export function filtersLoadSuccess(actionGenerator: Function) {
  return class implements QueryAction {
    public type = actionGenerator(QueryActions.LOAD_FILTERS_SUCCESS);

    constructor(public filters) {}
  }
}

export function filtersLoadError(actionGenerator: Function) {
  return class extends ErrorAction<QueryActions> implements QueryAction {
    public type = actionGenerator(QueryActions.LOAD_FILTERS_ERROR);

    constructor(public error) {
      super();
    }
  }
}

export function deleteFilter(actionGenerator: Function) {
  return class implements WithListTypeAction {
    public type = actionGenerator(QueryActions.DELETE_FILTER);

    constructor(public filter: TextFilter, public list?: any) {}
  }
}

