import React from 'react';

export const FeaturesContext = React.createContext<any>(null);

export const useFeatureFlag = () => React.useContext(FeaturesContext);

export const hocToUseHooks = (Component) => {
    return function WrappedComponent(props) {
        const { hasFeature } = useFeatureFlag();
        return <Component {...props} hasFeature={hasFeature} />;
    }
}

// below is how to use feature flag in services
// const hasNewFitlers = PataflagService.getInstance().flag(Features.FAVORITE_DOCFLOW_FILTERS);
