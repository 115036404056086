
export interface NewNetworkInvoiceStatusDTO {
    __metadata: { id: string; type: string; uri: string };
    BlckCnt: string;
    BlckRsn: string;
    BlckRsnTxt: string;
    CompCode: string;
    Currency: string;
    FiscalYr: string;
    IdNo: string;
    InvAmount: string;
    InvDate: string;
    InvDocNo: string;
    InvDueDate: string;
    InvPartner: string;
    InvPartnerName: string;
    Plant: string;
    PoNo: string;
    PostDate: string;
    PrNo: string;
    PrkCnt: string;
    PurchGrp: string;
    PurchOrg: string;
    RefDoc: string;
    SAP__Origin: string;
    Scenario: string;
    SearchTerm: string;
    StratBuyer: string;
    SystemAlias: string;
    TopWfId: string;
    WfAed: string;
    WfAgent: string;
    WfCd: string;
    WfId: string;
    WfStartDur: string;
    WfStatusCurrent: string;
  }

export class InvoiceStatus {
  constructor(
    public BlckCnt: number,
    public PrkCnt: number
  ) {
  }

  static FromBackend(dto: NewNetworkInvoiceStatusDTO): InvoiceStatus {
    return new InvoiceStatus(
      parseInt(dto.BlckCnt),
      parseInt(dto.PrkCnt)
    );
  }
}
