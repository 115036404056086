import * as R from "ramda";
import {DateTime, Interval} from "luxon";

export interface TextFilter {
  parentId?: string;
  azureId?: number;
  id: string | number;
  title: string | number;
}

export interface DateRangeFilter {
  startDate: string;
  endDate: string;
}
export interface CreationDateFilter {
  type: string;
  buttonName:string | null;
  startDate: string;
  endDate: string;
}
export interface SimpleTextFilter {
  value: string;
}

export interface SimpleRangeFilter {
  priceFrom: number;
  priceTo: number;
}

export type Filters = TextFilter[] | DateRangeFilter | SimpleTextFilter | SimpleRangeFilter | CreationDateFilter;

export interface FilterCategoryView<T = Filters> {
  data: T,
  selected: T,
  key: string,
  label: string,
}

export interface LoadFilterData<T = Filters> {
  data: T,
  key: string,
}

export function dateRangeToInterval(date: DateRangeFilter): Interval {
  const hasData = R.has(R.__, date);

  if (R.and(hasData('startDate'), hasData('endDate'))) {
    const start = DateTime.fromFormat(date['startDate'], 'mmddyyyy');
    const end = DateTime.fromFormat(date['endDate'], 'mmddyyyy');

    return Interval.fromDateTimes(start, end);
  }

  return null
}

export function newDateRangeFilterCategory(key: string, label: string,
                                           dateRange: DateRangeFilter = null,
                                           selectedDateRange: DateRangeFilter = null): FilterCategoryView<DateRangeFilter> {
  return {
    data: dateRange ? {startDate: dateRange.startDate, endDate: dateRange.endDate} : null,
    key: key,
    label: label,
    selected: selectedDateRange
      ? {startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate}
      : {startDate: null, endDate: null}
  }
}

export function newDateFilterCategory(key: string, label: string, date: SimpleTextFilter = null, selectedDate: SimpleTextFilter = null): FilterCategoryView<SimpleTextFilter> {
  return {
    data: date,
    key: key,
    label: label,
    selected: selectedDate
  }
}

export function newTextFilterCategory(key: string, label: string, data: TextFilter[] = []): FilterCategoryView<TextFilter[]> {
  return {
    data: data,
    key: key,
    label: label,
    selected: []
  }
}
