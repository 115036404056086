import {all, call, delay, fork, put, select, takeLatest} from 'redux-saga/effects';
import {push} from "connected-react-router";
import {
  ContractDownloadUploadActionTypes,
  DownloadContract,
  DownloadContractError,
  DownloadContractSuccess,
  ExportContractTemplateError,
  ExportContractTemplateSuccess,
  FetchExcelHasPendingJob,
  FetchExcelHasPendingJobError,
  FetchExcelHasPendingJobSuccess,
  FetchExcelJobErrorDetails,
  FetchExcelJobErrorDetailsError,
  FetchExcelJobErrorDetailsSuccess, 
  FetchExcelJobHistory, 
  FetchExcelJobHistoryError, 
  FetchExcelJobHistorySuccess,
  FetchExcelJobStatusesError,
  FetchExcelJobStatusesSuccess,
  ResetContractUploadError,
  UploadContract,
  UploadContractError,
  UploadContractSuccess
} from "../../actions/downloadUpload.actions";
import {ContractsStateStore} from "../../reducers";
import {ContractService} from "../../domains/contract/contract.service";
import {ContractSource, ContractType} from "../../domains/contract/contract";

function* downloadContract(action: DownloadContract) {
  try {
    const contractSource = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractSource);
    const result = yield call(ContractService.download, action.contract, contractSource);
    yield put(new DownloadContractSuccess(`${contractSource === ContractSource.CLM_CHILD ? 'Clm':''}Contract_${action.contract.AgreementNo}.xlsm`, result));
  } catch (error) {
    yield put(new DownloadContractError(error));
  }
}

function* exportContractTemplate() {
  try {
    const result = yield call(ContractService.exportTemplate);
    yield put(new ExportContractTemplateSuccess(`Contract_Template.xlsm`, result));
  } catch (error) {
    yield put(new ExportContractTemplateError(error));
  }
}

function* uploadContract(action: UploadContract) {
  try {
    const result = yield call(ContractService.upload, action.formData, action.isCreatingNew, action.contractSource);
    if (action.contractSource === ContractSource.SAP) {
      yield put(new FetchExcelHasPendingJob(result.d.AgreementNo));
    } else {
      yield put(new UploadContractSuccess(`Contract has been uploaded to CLM successful`));
      yield delay(500);
      yield put(push(`/contracts?source=${action.contractSource}`));
    }
  } catch (error) {
    yield put(new UploadContractError(error));
  } finally {
    yield put(new ResetContractUploadError());
  }
}

function* fetchExcelHasPendingJob(action: FetchExcelHasPendingJob) {
  try {
    const hasPending = yield call(ContractService.fetchExcelHasPendingJob);
    yield put(new FetchExcelHasPendingJobSuccess(hasPending));
    if (!hasPending && action.contractNo) {
      yield put(new UploadContractSuccess(`Contract ${action.contractNo} has been uploaded successful`));
      yield delay(500);
      yield put(push(`/contracts?type=${ContractType.RECENT}&agreementNo=${action.contractNo}`));
    }
  } catch (error) {
    yield put(new FetchExcelHasPendingJobError(error));
  }
}

function* fetchExcelJobStatuses() {
  try {
    const statuses = yield call(ContractService.fetchExcelJobStatuses);
    yield put(new FetchExcelJobStatusesSuccess(statuses));
  } catch (error) {
    yield put(new FetchExcelJobStatusesError(error));
  }
}

function* fetchExcelJobHistory() {
  try {
    const statuses = yield call(ContractService.fetchExcelJobHistory);
    yield put(new FetchExcelJobHistorySuccess(statuses));
  } catch (error) {
    yield put(new FetchExcelJobHistoryError(error));
  }
}

function* fetchExcelJobErrors(action: FetchExcelJobErrorDetails) {
  try {
    const statuses = yield call(ContractService.fetchExcelJobErrors, action.batchId);
    yield put(new FetchExcelJobErrorDetailsSuccess(action.batchId, statuses));
  } catch (error) {
    yield put(new FetchExcelJobErrorDetailsError(error));
  }
}

function* watchDownload() {
  yield takeLatest(ContractDownloadUploadActionTypes.DOWNLOAD_CONTRACT, downloadContract);
}

function* watchExportTemplate() {
  yield takeLatest(ContractDownloadUploadActionTypes.EXPORT_CONTRACT_TEMPLATE, exportContractTemplate);
}

function* watchUpload() {
  yield takeLatest(ContractDownloadUploadActionTypes.UPLOAD_CONTRACT, uploadContract);
}

function* watchFetchExcelHasPendingJob() {
  yield takeLatest(ContractDownloadUploadActionTypes.FETCH_EXCEL_HAS_PENDING_JOB, fetchExcelHasPendingJob);
}

function* watchFetchExcelJobStatuses() {
  yield takeLatest(ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_STATUSES, fetchExcelJobStatuses);
}

function* watchFetchExcelJobHistory() {
  yield takeLatest(ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_HISTORY, fetchExcelJobHistory);
}

function* watchFetchExcelJobErrorDetails() {
  yield takeLatest(ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_ERROR_DETAILS, fetchExcelJobErrors);
}

export default function* contractUploadDownloadSaga() {
  yield all([
    fork(watchDownload),
    fork(watchExportTemplate),
    fork(watchUpload),
    fork(watchFetchExcelHasPendingJob),
    fork(watchFetchExcelJobStatuses),
    fork(watchFetchExcelJobHistory),
    fork(watchFetchExcelJobErrorDetails)
  ])
};
