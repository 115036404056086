import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { lensPath, lensProp, set } from 'ramda';
import { DocumentFlowService } from "../domains/documentFlow/documentFlow.service";
import {
  ClosePO,
  ClosePOError,
  ClosePOSuccess, DocumentFlowFetchAction,
  DocumentFlowFetchFailureAction,
  DocumentFlowFetchLoad,
  DocumentFlowFetchSuccessAction,
  DocumentFlowHeaderLevelFavAction,
  DocumentFlowHeaderLevelFavFailureAction,
  DocumentFlowHeaderLevelFavSuccessAction,
  DocumentFlowItemsFavAction,
  DocumentFlowItemsFavSuccessAction,
  DocumentFlowItemsFetchFailureAction,
  DocumentFlowItemsFetchLoad,
  DocumentFlowItemsFetchSuccessAction,
  DocumentFlowStageFetchAction,
  DocumentFlowStageFetchFailureAction,
  DocumentFlowStageFetchSuccessAction,
  DocumentFlowStatusFetchFailureAction,
  DocumentFlowStatusFetchSuccessAction,
  DocumentFlowTypes,
  DownloadDocument,
  DownloadDocumentSuccess,
  FetchDocumentFlowsLinkCb,
  FetchDocumentFlowsLinkCbFailure,
  FetchDocumentFlowsLinkCbSuccess,
  GetBlockedInvoices,
  GetBlockedInvoicesFailure,
  GetBlockedInvoicesSuccess,
  NewRequestChangeAllDocFlowItems,
  RemoveLinkCb,
  RemoveLinkCbFailure,
  RemoveLinkCbSuccess,
  RequestChangeAllDocFlowItems,
  RequestChangeAllDocFlowItemsError,
  RequestChangeAllDocFlowItemsSuccess,
  RequestChangeDocFlowItem,
  RequestChangeDocFlowItemError,
  RequestChangeDocFlowItemSuccess,
  SaveDocFlowItem,
  SaveDocFlowItemError,
  SaveDocFlowItemSuccess,
  SendEmailPO,
  SendEmailPOApproval,
  SendEmailPOApprovalError,
  SendEmailPOApprovalSuccess,
  SendEmailPOError,
  SendEmailPOSuccess,
  UpdateDocFlowItem,
  UpdateDocFlowItemError,
  UpdateDocFlowItemSuccess,
} from "../actions/documentFlow.actions";
import { ListWithStatus } from "../../shared/utils/odataClient.wrapper";
import DocumentFlow, { DocFlowFilterTabs } from "../domains/documentFlow/documentFlow";
import { QueryActions } from '../reducers/queryableDocumentFlowReducer';
import { RootStateStore } from "../../application.reducers";
import { newDocumentFlowQuery } from "../domains/documentFlow/documentFlowQuery";
import { defaultCompany, User } from "../../shared/domains/user/user";
import { CompetitiveBiddingService } from "../../competitiveBidding/domains/cb.service";
import { CompetitiveBiddingData } from "../../competitiveBidding/domains/competitiveBidding";
import { ListResponse } from "../../contracts/domains/contract/contract.service";
import { PRPOItemService } from "../domains/prpo/item.service";
import { ExportPSLTemplateError } from "../../preferredSuppliers/actions/psl.actions";
import { MasterService } from "../../shared/domains/master/master.service";
import { UserInteractionType } from "../../shared/domains/user/userInteractionType";
import { DefaultZone } from 'src/app/shared/components/zones/genericZones.component';

export function* fetchDocFlows(action): any {
  try {
    yield put(new DocumentFlowFetchLoad());
    const result: ListWithStatus<DocumentFlow> = yield call(DocumentFlowService.fetch, action.query, action.list);
    yield put(new DocumentFlowFetchSuccessAction(result.data, result.count));
  } catch (error) {
    yield put(new DocumentFlowFetchFailureAction(error));
  }
}

export function* fetchDocFlowStatus(action): any {
  try {
    const result: ListWithStatus<DocumentFlow> = yield call(DocumentFlowService.fetchStatus, action.query, action.list);
    yield put(new DocumentFlowStatusFetchSuccessAction(result.data, result.count, result.status));
  } catch (error) {
    yield put(new DocumentFlowStatusFetchFailureAction(error));
  }
}

export function* fetchDocFlowStage(action: DocumentFlowStageFetchAction): any {
  try {
    const result = yield call(DocumentFlowService.fetchStage, action.documentFlow);
    yield put(new DocumentFlowStageFetchSuccessAction(result));
  } catch (error) {
    yield put(new DocumentFlowStageFetchFailureAction(action.dfId, error));
  }
}

function* initialFetchDocumentFlow(action: DocumentFlowFetchAction) {
  const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
  const term: string = yield select(({ docFlows }: RootStateStore) => docFlows.documentFlows.query.term);
  let filters = user.filters.documentFlow;
  if (action.tab === DocFlowFilterTabs.FAVORITE) {
    filters = user.filters.favDocumentFlow;
  }
  const query = newDocumentFlowQuery(defaultCompany(user), filters, term);
  // const result: ListWithStatus<DocumentFlow> = yield call(DocumentFlowService.fetchStatus, query);
  // yield put(new DocumentFlowStatusFetchSuccessAction(result.data, result.count, result.status));
  const zone = user?.defaultSystemAlias?.[0] || DefaultZone.value;
  yield put({ type: QueryActions.onNewQuery, query: query, list: { alias: zone, docFlowType: DocFlowFilterTabs.DOCUMENTS } });
}

function* fetchDocumentFlowItems(action: DocumentFlowItemsFetchLoad) {
  try {
    const results = yield call(PRPOItemService.fetchDocFlowItemSet, action.df);
    yield put(new DocumentFlowItemsFetchSuccessAction(results.data, action.df));
  } catch (error) {
    yield put(new DocumentFlowItemsFetchFailureAction(error, action.df));
  }
}

function* setDocumentFlowItemSpender(action: UpdateDocFlowItem) {
  try {
    yield call(DocumentFlowService.updateSpender, action.docflowItem);
    yield put(new UpdateDocFlowItemSuccess(action.executor, action.docflowItem, action.itemId, action.docflow));
    yield put(new DocumentFlowItemsFetchLoad(action.docflow));
  } catch (error) {
    yield put(new UpdateDocFlowItemError(action.executor, error));
  }
}


function* requestChangeDocFlowItemArr(action) {
  try {
    console.log(action)
    const results = yield call(DocumentFlowService.saveDocFlowArr, UserInteractionType.EditedRequestCockpit, action.docflowItemDiff);
    if (results.status === 200) {

      let arrNew = [];
      let arrOld = [];
      let ReleaseIndArr = []
      arrNew = action.docflowItemNew.filter((el, index) => {
        if (!!action.sendEmailToCreator[index]) {
          arrOld.push(action.docflowItemOld[index]);
          ReleaseIndArr.push(results.data.d.IdToPRItem.results[index].ReleaseInd === 'X')
          return true;
        }
        else return false;
      })

      if (!!arrNew.length) {
        try {
          // const ReleaseIndArr = results.data.d.IdToPRItem.results.map(el => {
          //   return el.ReleaseInd === 'X'? true: false
          // })
          // const ReleaseInd = results.data.d.IdToPRItem.results[0].ReleaseInd === 'X' ? true : false;
          const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
          yield call(DocumentFlowService.sendEmailToCreatorArr, arrOld, arrNew, user, ReleaseIndArr, action.formData);
          if (action.onDone) {
            action.onDone();
          }
          //yield put(new DocumentFlowFetchLoad());
          yield put(new DocumentFlowItemsFetchLoad(action.docflow));

          // I do not know why we are sending below all parameters to this 'RequestChangeDocFlowItemSuccess'
          // function even though we are not using it anywhere :(

          yield put(new RequestChangeDocFlowItemSuccess(action.executor,
            action.docflowItemDiff[0],
            action.onDone,
            action.docflow,
            action.docflowItemOld[0],
            action.sendEmailToCreator[0],
            action.docflowItemNew[0],
            action.docflowItemDiff[0]));
        } catch (error) {
          yield put(new RequestChangeDocFlowItemError(action.executor, error));
        }
      } else {
        if (action.onDone) {
          action.onDone();
        }
        //yield put(new DocumentFlowFetchLoad());
        yield put(new DocumentFlowItemsFetchLoad(action.docflow));

        // below also I do not know why we are sending these data.. :(
        yield put(new SaveDocFlowItemSuccess(action.executor,
          action.docflowItemDiff[0], // diff 
          action.onDone,
          action.docflow));
      }
    }
  } catch (error) {
    yield put(new RequestChangeDocFlowItemError(action.executor, error));
  }
}


function* requestChangeDocFlowItem(action: RequestChangeDocFlowItem) {
  try {
    const results = yield call(DocumentFlowService.saveDocFlow, UserInteractionType.EditedRequestCockpit, action.docflowItem);
    if (results.status === 200) {
      if (action.sendEmailToCreator) {
        try {
          const ReleaseInd = results.data.d.IdToPRItem.results[0].ReleaseInd === 'X' ? true : false;
          const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
          yield call(DocumentFlowService.sendEmailToCreator, action.docflowItemOld, action.docflowItemNew, user, ReleaseInd, action.formData);
          if (action.onDone) {
            action.onDone();
          }
          //yield put(new DocumentFlowFetchLoad());
          yield put(new DocumentFlowItemsFetchLoad(action.docflow));
          yield put(new RequestChangeDocFlowItemSuccess(action.executor, action.docflowItem, action.onDone, action.docflow, action.docflowItemOld, action.sendEmailToCreator, action.docflowItemNew, action.docflowItem));
        } catch (error) {
          yield put(new RequestChangeDocFlowItemError(action.executor, error));
        }
      } else {
        if (action.onDone) {
          action.onDone();
        }
        //yield put(new DocumentFlowFetchLoad());
        yield put(new DocumentFlowItemsFetchLoad(action.docflow));
        yield put(new SaveDocFlowItemSuccess(action.executor, action.docflowItem, action.onDone, action.docflow));
      }
    }
  } catch (error) {
    yield put(new RequestChangeDocFlowItemError(action.executor, error));
  }
}

function* requestChangeAllDocFlowItems(action: RequestChangeAllDocFlowItems) {
  try {
    let results;
    if (action.docflowItems.length > 0) {
      results = yield call(DocumentFlowService.newSaveDocFlow, UserInteractionType.EditedRequestCockpit, action.docflowItems);
    }
    if (action.docflowItems.length === 0 || results.status === 200) {
      if (action.sendEmailToCreator) {
        const ReleaseInd = results && results.data.d.IdToPRItem.results[0].ReleaseInd === 'X' ? true : false;
        const sendEmailIndicator = action.sendEmail;
        const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
        yield call(DocumentFlowService.newSendEmailToCreator, action.docflowItemsOld, action.docflowItemsNew, user, ReleaseInd, action.formData, sendEmailIndicator);
        if (action.onDone) {
          action.onDone();
        }
      } else {
        action.onDone();
      }
      yield put(new DocumentFlowItemsFetchLoad(action.docflow));
      yield put(new RequestChangeAllDocFlowItemsSuccess());
    }
  } catch (error) {
    yield put(new RequestChangeAllDocFlowItemsError(error));
  }
}

function* newRequestChangeAllDocFlowItems(action: NewRequestChangeAllDocFlowItems) {
  try {
    let results;
    if (action.docflowItems.length > 0) {
      results = yield call(DocumentFlowService.newSaveDocFlow, UserInteractionType.EditedRequestCockpit, action.docflowItems);
    }
    if (action.docflowItems.length === 0 || results.status === 200) {
      if (action.sendEmailToCreator) {
        const ReleaseInd = results && results.data.d.IdToPRItem.results[0].ReleaseInd === 'X' ? true : false;
        const sendEmailIndicator = action.sendEmail;
        const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
        yield call(DocumentFlowService.sendEditDetailsToSapScope, action.docflow, action.docflowItemsOld, action.docflowItemsNew, user, ReleaseInd, action.formData, sendEmailIndicator);
        if (action.onDone) {
          action.onDone();
        }
      } else {
        action.onDone();
      }
      yield put(new DocumentFlowItemsFetchLoad(action.docflow));
      yield put(new RequestChangeAllDocFlowItemsSuccess());
    }
  } catch (error) {
    yield put(new RequestChangeAllDocFlowItemsError(error));
  }
}

function* sendEmailPO(action: SendEmailPO) {
  try {
    const results = yield call(DocumentFlowService.checkPO, action.poNumber, action.ItemNo);
    if (results === 2) {
      const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
      yield call(DocumentFlowService.sendEmailPO, action.approved, action.POmodifier, user.name, action.poNumber, action.ItemNo, action.comment);
      if (action.onDone) {
        action.onDone('success');
      }
      yield put(new SendEmailPOSuccess(`PO ${action.poNumber} rejected successfully`));
    } else {
      // if (action.onDone) {
      //   action.onDone('reject');
      //   // yield put(new SendEmailPOError('PO already modified'));
      //   yield put(new SendEmailPOError('Change Request Rejected'));

      // }

      if (action.onDone && results === 0) {
        action.onDone('reject');
        // yield put(new SendEmailPOError('PO already modified'));
        yield put(new SendEmailPOError('PO Change Request already Approved'));
      }

      else if (action.onDone && results === 1) {
        action.onDone('reject');
        yield put(new SendEmailPOError('PO Change Request already Rejected'));


      }

    }
  } catch (error) {
    action.onDone('reject');
    yield put(new SendEmailPOError(error));
  }
}

function* requestChangePO(action: SendEmailPOApproval) {
  try {
    const results = yield call(DocumentFlowService.checkPO, action.poNumber, action.poItemNumber);
    if (results === 2) {
      try {
        const results = yield call(DocumentFlowService.savePO, action.poNumber, action.poItemNumber, action.prItemNumber, action.resendPDF, action.SystemAlias, action.compIndicatorSelected);
        if (results.status === 200) {
          const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
          yield call(DocumentFlowService.sendEmailPO, action.approved, action.POmodifier, user.name, action.poNumber, action.poItemNumber, action.comment);
          if (action.onDone) {
            action.onDone('success');
          }
          yield put(new SendEmailPOApprovalSuccess(`PO ${action.poNumber} approved successfully`));
        }
      } catch (error) {
        action.onDone('reject');
        yield put(new SendEmailPOError(error));
      }
    } else {
      if (action.onDone && results === 0) {
        action.onDone('reject');
        // yield put(new SendEmailPOError('PO already modified'));
        yield put(new SendEmailPOError('PO Change Request already Approved'));
      }

      else if (action.onDone && results === 1) {
        action.onDone('reject');
        yield put(new SendEmailPOError('PO Change Request already Rejected'));
      }

    }
  } catch (error) {
    action.onDone('reject');
    yield put(new SendEmailPOApprovalError(error));
  }
}

function* saveDocumentFlowItem(action: SaveDocFlowItem) {
  try {
    const results = yield call(DocumentFlowService.saveDocFlow, UserInteractionType.EditedRequestCockpitPO, action.docflowItem);
    if (results.status === 200) {
      if (action.onDone) {
        action.onDone();
      }
      //yield put(new DocumentFlowFetchLoad());
      yield put(new DocumentFlowItemsFetchLoad(action.docflow));
      yield put(new SaveDocFlowItemSuccess(action.executor, action.docflowItem, action.onDone, action.docflow));
    }
  } catch (error) {
    yield put(new SaveDocFlowItemError(action.executor, error));
  }
}

function* closePO(action: ClosePO) {
  try {
    yield call(DocumentFlowService.closePO, action.docflowItem);
    if (action.onDone) {
      action.onDone();
    }
    //yield put(new DocumentFlowFetchLoad());
    yield put(new DocumentFlowItemsFetchLoad(action.docflow));
    yield put(new ClosePOSuccess(action.executor, "PO closed successfully"));
  } catch (error) {
    yield put(new ClosePOError(action.executor, error));
  }
}

function* setDocumentFlowItemAsFavorite(action: DocumentFlowItemsFavAction) {
  try {
    const results = yield call(DocumentFlowService.addToFavorite, action.df, action.PrItem, action.PoItem, action.fav);
    action.fav
      ? DocumentFlowService.logUserInteractionData(UserInteractionType.AddedRequestCockpitToFavorites)
      : DocumentFlowService.logUserInteractionData(UserInteractionType.RemovedRequestCockpitFromFavorites);
    yield put(new DocumentFlowItemsFavSuccessAction(action.PrItem, action.PoItem, action.df));
    yield put(new DocumentFlowItemsFetchLoad(action.df));
  } catch (error) {
    yield put(new DocumentFlowItemsFetchFailureAction(error, action.df));
  }
}

function* setDocumentFlowHeaderLevelAsFavorite(action: DocumentFlowHeaderLevelFavAction) {
  try {
    const results = yield call(DocumentFlowService.addToFavoriteHeaderLevel, action.df, action.fav);
    action.fav
      ? DocumentFlowService.logUserInteractionData(UserInteractionType.AddedRequestCockpitToFavorites)
      : DocumentFlowService.logUserInteractionData(UserInteractionType.RemovedRequestCockpitFromFavorites);
    yield put(new DocumentFlowHeaderLevelFavSuccessAction(action.fav, action.df));
  } catch (error) {
    yield put(new DocumentFlowHeaderLevelFavFailureAction(error, action.df));
  }
}

function* removeCbLink(action: RemoveLinkCb) {
  try {
    yield call(DocumentFlowService.removeCbLink, action.cbId, action.df);
    yield put(new RemoveLinkCbSuccess(action.df?.PurchaseReqNo, action.df?.PurchaseOrdNo));
    if (action.done) {
      action.done();
    }
  } catch (error) {
    yield put(new RemoveLinkCbFailure(error));
  }
}

function* fetchBlockedInvoices(action: GetBlockedInvoices) {
  try {
    yield call(DocumentFlowService.getyBlockedInvoices, action.compCode);
    yield put(new GetBlockedInvoicesSuccess("Blocked Invoices get successfully"));
    action.done();
  } catch (error) {
    yield put(new GetBlockedInvoicesFailure(error));
  }
}

function* fetchDocumentFlowsLinkCb(action: FetchDocumentFlowsLinkCb) {
  try {
    const cb: CompetitiveBiddingData = yield call(CompetitiveBiddingService.get, action.cbId);
    const query1 = set(lensProp('top'), 20, newDocumentFlowQuery());
    const query2 = set(
      lensPath(['filters', 'VendorNo']),
      { key: 'VendorNo', selected: [{ id: cb.Vendors[cb.SelectedSupplier]?.VendorCode }] },
      query1
    );
    const query3 = set(
      lensPath(['filters', 'POCompanyCode']),
      { key: 'POCompanyCode', selected: [{ id: cb.CompanyCode }] },
      query2
    );
    const query4 = set(
      lensPath(['filters', 'MatGrp']),
      { key: 'MatGrp', selected: cb.spendCategories.map(el => ({ id: el.productCategoryCode })) },
      query3
    );
    const user: User = yield select(({ shared }: RootStateStore) => shared.user.currentUser);
    const zone = user?.defaultSystemAlias?.[0] || DefaultZone.value;
    const result: ListResponse<DocumentFlow> = yield call(DocumentFlowService.fetch, query4, { alias: zone, docFlowType: DocFlowFilterTabs.DOCUMENTS });
    yield put(new FetchDocumentFlowsLinkCbSuccess(result.data));
  } catch (error) {
    yield put(new FetchDocumentFlowsLinkCbFailure(error));
  }
}

function* fetchDocument(action: DownloadDocument) {
  try {
    const result = yield call(MasterService.fetchInvoiceData, action.instId, action.typeId, action.attaId, action.systemAlias);
    yield put(new DownloadDocumentSuccess(action.fileName, result));
  } catch (error) {
    yield put(new ExportPSLTemplateError(error));
  }
}

function* watchDocumentFlowFetch() {
  yield takeLatest(DocumentFlowTypes.FETCH_DOCFLOW, initialFetchDocumentFlow);
}

function* watchDocumentFlowItemsFetch() {
  yield takeEvery(DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_REQUEST, fetchDocumentFlowItems);
}

// function* watchInvoiceItemsFetch() {
//   yield takeEvery(DocumentFlowTypes.FETCH_INVOICE_ITEMS_REQUEST, fetchInvoiceItems);
// }

function* watchDocumentFlowItemsSetSpender() {
  yield takeEvery(DocumentFlowTypes.UPDATE_DOCFLOW_ITEM, setDocumentFlowItemSpender);
}

function* watchRequestChangeDocumentFlowItem() {
  yield takeEvery(DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM, requestChangeDocFlowItem);
}

function* watchNewRequestChangeAllDocumentFlowItems() {
  yield takeEvery(DocumentFlowTypes.NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS, newRequestChangeAllDocFlowItems);
}

function* watchRequestChangeAllDocumentFlowItems() {
  yield takeEvery(DocumentFlowTypes.REQUEST_CHANGE_ALL_DOCFLOW_ITEMS, requestChangeAllDocFlowItems);
}

function* watchRequestChangeDocumentFlowItemArr() {
  yield takeEvery(DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM_ARR, requestChangeDocFlowItemArr);
}

function* watchRequestChangePO() {
  yield takeEvery(DocumentFlowTypes.SEND_EMAIL_PO_APPROVAL, requestChangePO);
}

function* watchSendEmailPO() {
  yield takeEvery(DocumentFlowTypes.SEND_EMAIL_PO, sendEmailPO);
}

function* watchSaveDocumentFlowItem() {
  yield takeEvery(DocumentFlowTypes.SAVE_DOCFLOW_ITEM, saveDocumentFlowItem);
}

function* watchClosePO() {
  yield takeEvery(DocumentFlowTypes.CLOSE_PO, closePO);
}

function* watchDocumentFlowItemsSetToFavorite() {
  yield takeEvery(DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_FAV_REQUEST, setDocumentFlowItemAsFavorite);
}

function* watchDocumentFlowHeaderLevelSetToFavorite() {
  yield takeEvery(DocumentFlowTypes.FETCH_DOCFLOW_HEADER_LEVEL_FAV_REQUEST, setDocumentFlowHeaderLevelAsFavorite);
}

function* watchDocumentFlowStageFetch() {
  yield takeLatest(DocumentFlowTypes.FETCH_DOCFLOWSTAGE, fetchDocFlowStage);
}

function* watchNewQuery() {
  yield takeLatest(QueryActions.onNewQuery, fetchDocFlows);
}

function* watchNewQueryStatus() {
  yield takeLatest(QueryActions.onNewQuery, fetchDocFlowStatus);
}

function* watchRemoveCbLink() {
  yield takeLatest(DocumentFlowTypes.REMOVE_LINK_CB, removeCbLink);
}

function* watchFetchDocumentFlowsLinkCb() {
  yield takeLatest(DocumentFlowTypes.FETCH_DOCFLOWS_LINK_CB, fetchDocumentFlowsLinkCb);
}

function* watchgetBlockedInvoices() {
  yield takeLatest(DocumentFlowTypes.GET_BLOCKED_INVOICES, fetchBlockedInvoices);
}

function* watchFetchDocument() {
  yield takeLatest(DocumentFlowTypes.FETCH_DOCFLOW_DOCUMENT, fetchDocument);
}

export default function* documentFlowSaga() {
  yield all([
    fork(watchDocumentFlowFetch),
    fork(watchDocumentFlowItemsFetch),
    fork(watchDocumentFlowStageFetch),
    fork(watchNewQuery),
    fork(watchNewQueryStatus),
    fork(watchRemoveCbLink),
    fork(watchFetchDocumentFlowsLinkCb),
    fork(watchgetBlockedInvoices),
    fork(watchDocumentFlowItemsSetToFavorite),
    fork(watchDocumentFlowHeaderLevelSetToFavorite),
    fork(watchDocumentFlowItemsSetSpender),
    fork(watchRequestChangeDocumentFlowItem),
    fork(watchRequestChangeAllDocumentFlowItems),
    fork(watchNewRequestChangeAllDocumentFlowItems),
    fork(watchRequestChangeDocumentFlowItemArr),
    fork(watchSendEmailPO),
    fork(watchRequestChangePO),
    fork(watchSaveDocumentFlowItem),
    fork(watchClosePO),
    fork(watchFetchDocument),
  ])
};
