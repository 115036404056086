import { template } from "lodash";
import * as R from 'ramda';
import { ebRealm, ebUrl, sapUrl } from "src/app/shared/utils/urlBuilders";

export interface FromNetworkInvoiceItemDTO {
  __metadata: { id: string; type: string; uri: string };
  FiscalYr: string;
  GrQty: string;
  GrQtyUom: string;
  InvDocNo: string;
  InvDocNoItm: string;
  InvQty: string;
  InvQtyUom: string;
  IrAmount: string;
  IrAmountCurr: string;
  IsPartOf: string;
  MatNo: string;
  MaterialDesc:string;
  PoCurrency: string;
  PoItmNo: string;
  PoNetPrice: string;
  PoNetPricePerUnit: string;
  PoNetPriceRaw: string;
  PoNetPriceUnit: string;
  PoNo: string;
  PoQuantity: string;
  PoQuantityRaw: string;
  PoQuantityUom: string;
  PoTotalPrice: string;
  PrCreatedBy: string;
  PrItmNo: string;
  PrNo: string;
  DocType: string;
  AribaPR: string;
  PrTrackingNo: string;
  PurchOrg: string;
  Requisitioner: string;
  SystemAlias: string;
  ToActor: string;
  TopWfId: string;
  Po_Type: string;
  Doc_Txt: string;
  Oa_lifnr: string;
  Pi_lifnr: string;
  Oa_name1: string;
  Pi_name1: string;
  WfAed: string;
  WfAgent: string;
  WfCd: string;
  WfId: string;
  WfStartDur: string;
  Del_Ind: string;
  Del_Ind_Desc: string;
  Rel_Ind: string;
  Rel_Ind_Desc: string;
}
const ViewPRTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=/GLB/RGTPP01_ME53N&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=P_BANFN%3d<%=pr%>%26&AutoStart=true"
);
const ViewPOTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=/GLB/RGTPT01_ME23N&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME23N&DynamicParameter=P_EBELN%3d<%=po%>%26&AutoStart=true",
);
const ViewAribaPRTemplate = template(
  "https://<%=prefix%>.ariba.com/Buyer/Main/ad/webjumper?realm=<%=realm%>&itemUniqueName=<%=aribapr%>&itemClassName=ariba.purchasing.core.Requisition"
);
export class InvoiceItem {
  constructor(
   public readonly FiscalYr,
   public readonly GrQty,
   public readonly GrQtyUom,
   public readonly InvDocNo,
   public readonly InvDocNoItm,
   public readonly InvQty,
   public readonly InvQtyUom,
   public readonly IrAmount,
   public readonly IrAmountCurr,
   public readonly IsPartOf,
   public readonly MatNo,
   public readonly MaterialDesc,
   public readonly PoCurrency,
   public readonly PoItmNo,
   public readonly PoNetPrice,
   public readonly PoNetPricePerUnit,
   public readonly PoNetPriceRaw,
   public readonly PoNetPriceUnit,
   public readonly PoNo,
   public readonly PoQuantity,
   public readonly PoQuantityRaw,
   public readonly PoQuantityUom,
   public readonly PoTotalPrice,
   public readonly PrCreatedBy,
   public readonly PrItmNo,
   public readonly PrNo,
   public readonly AribaPR,
   public readonly DocType,
   public readonly PrTrackingNo,
   public readonly PurchOrg,
   public readonly Requisitioner,
   public readonly SystemAlias,
   public readonly ToActor,
   public readonly TopWfId,
   public readonly Po_Type,
   public readonly Doc_Txt,
   public readonly Oa_lifnr,
   public readonly Pi_lifnr,
   public readonly Oa_name1,
   public readonly Pi_name1,
   public readonly WfAed,
   public readonly WfAgent,
   public readonly WfCd,
   public readonly WfId,
   public readonly WfStartDur,
   public readonly ViewPOLink,
   public readonly ViewPRLink,
   public readonly ViewAribaPRLink,
   public readonly Del_Ind,
   public readonly Del_Ind_Desc,
   public readonly Rel_Ind,
   public readonly Rel_Ind_Desc,
  ) {
  }

  static FromBackend(data: FromNetworkInvoiceItemDTO): InvoiceItem {
    const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());
    const ebPrefix = ebUrl(process.env.REACT_APP_ENV.trim());
    const realm = ebRealm(process.env.REACT_APP_ENV.trim());
    const sapPrefixed = R.mergeRight({prefix: sapPrefix, system: data.SystemAlias});
    const ebPrefixed = R.mergeRight({prefix: ebPrefix, realm: realm});
    
    const ViewPOLink = ViewPOTemplate(sapPrefixed({po: data.PoNo}));
    const ViewPRLink = ViewPRTemplate(sapPrefixed({pr: data.PrNo}));
    const ViewAribaPRLink = ViewAribaPRTemplate(ebPrefixed({aribapr: data.AribaPR}));

    return new InvoiceItem(
      data.FiscalYr,
      data.GrQty,
      data.GrQtyUom,
      data.InvDocNo,
      data.InvDocNoItm,
      data.InvQty,
      data.InvQtyUom,
      data.IrAmount,
      data.IrAmountCurr,
      data.IsPartOf,
      data.MatNo,
      data.MaterialDesc,
      data.PoCurrency,
      data.PoItmNo,
      data.PoNetPrice,
      data.PoNetPricePerUnit,
      data.PoNetPriceRaw,
      data.PoNetPriceUnit,
      data.PoNo,
      data.PoQuantity,
      data.PoQuantityRaw,
      data.PoQuantityUom,
      data.PoTotalPrice,
      data.PrCreatedBy,
      data.PrItmNo,
      data.PrNo,
      data.AribaPR,
      data.DocType,
      data.PrTrackingNo,
      data.PurchOrg,
      data.Requisitioner,
      data.SystemAlias,
      data.ToActor,
      data.TopWfId,
      data.Po_Type,
      data.Doc_Txt,
      data.Oa_lifnr,
      data.Pi_lifnr,
      data.Oa_name1,
      data.Pi_name1,
      data.WfAed,
      data.WfAgent,
      data.WfCd,
      data.WfId,
      data.WfStartDur,
      ViewPOLink,
      ViewPRLink,
      ViewAribaPRLink,
      data.Del_Ind,
      data.Del_Ind_Desc,
      data.Rel_Ind,
      data.Rel_Ind_Desc,
    )
  }
}
