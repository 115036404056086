export interface FromNetworkAccTabItemDTO {
    PRNo: string;
    PONo: string;
    PRNo_Item: string;
    PONo_Item: string;
    PrMaterial: string;
    PrMaterialTxt: string;
    SequenceNo: string;
    AccCategory: string,
    DistributionInd: string,
    Quantity: string,
    DistributionPercent: string,
    NetOrderValue: string,
    GLAccount: string,
    Pr_GlAccountTxt: string,
    CostCenter: string,
    Pr_CostCenterTxt: string,
    ProfitCenter: string,
    PR_ProfitcenterTxt: string,
    Pr_WBSelement: string,
    Pr_WbsElementTxt: string,
    PrCurrency: string,
    Tot_value: string,
    Units: string
}

export class AccTabItem {
    constructor(
        public readonly PRNo,
        public readonly PONo,
        public readonly PRNo_Item,
        public readonly PONo_Item,
        public readonly PrMaterial,
        public readonly PrMaterialTxt,
        public readonly SequenceNo,
        public readonly AccCategory,
        public readonly DistributionInd,
        public readonly Quantity,
        public readonly DistributionPercent,
        public readonly NetOrderValue,
        public readonly GLAccount,
        public readonly Pr_GlAccountTxt,
        public readonly CostCenter,
        public readonly Pr_CostCenterTxt,
        public readonly ProfitCenter,
        public readonly PR_ProfitcenterTxt,
        public readonly Pr_WBSelement,
        public readonly Pr_WbsElementTxt,
        public readonly Tot_value,
        public readonly PrCurrency,
        public readonly Units,
    ) {
    }

    static FromBackend(data: FromNetworkAccTabItemDTO): AccTabItem {
        return new AccTabItem(
            data.PRNo || "",
            data.PONo || "",
            data.PRNo_Item,
            data.PONo_Item || "",
            data.PrMaterial || "",
            data.PrMaterialTxt || "",
            data.SequenceNo || "",
            data.AccCategory || "",
            data.DistributionInd || "",
            data.Quantity || "",
            data.DistributionPercent || "",
            data.NetOrderValue || "",
            data.GLAccount || "",
            data.Pr_GlAccountTxt || "",
            data.CostCenter || "",
            data.Pr_CostCenterTxt || "",
            data.ProfitCenter || "",
            data.PR_ProfitcenterTxt || "",
            data.Pr_WBSelement || "",
            data.Pr_WbsElementTxt || "",
            data.Tot_value || "",
            data.PrCurrency || "",
            data.Units || ""
        )
    }
}
