import {ErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {ChangeStatusData, CompetitiveBiddingData} from "../domains/competitiveBidding";
import {CompetitiveBiddingTypes} from "./competitiveBiddings.actions";
import {SuccessNotificationAction} from "../../shared/domains/core/notifications";

export class FetchSummary extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_SUMMARY;

  constructor(public id: string) {
    super();
  }
}

export class FetchSummarySuccess extends SuccessAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_SUMMARY_SUCCESS;

  constructor(public summary: CompetitiveBiddingData) {
    super();
  }
}

export class FetchSummaryError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_SUMMARY_FAILURE;
}
