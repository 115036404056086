import { ApprovalHistoryRequest } from "./approvalHistory";
import ODataClientWrapper from "../../../../shared/utils/odataClient.wrapper";
import { ApprovalHistoryQuery, ODataQuery, PartialODataQuery } from "./query";
import { SimpleODataFilter } from "../../../../../lib/odata";

export interface ListResponse<T> {
	count: number;
	data: T[]
}

export class ApprovalHistoryService {

	private static async fetchAll(query: ODataQuery): Promise<ListResponse<ApprovalHistoryRequest>> {
		console.log("fetchAll query", query);
		const resource = query.resource;
		const response = await ODataClientWrapper.get()
			.fromQuery(query)
			.count()
			.execute();
		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results.map(ApprovalHistoryRequest.FromBackend)
		}
	}


	static async fetch(query: ODataQuery = ApprovalHistoryQuery()): Promise<ListResponse<ApprovalHistoryRequest>> {
		return ApprovalHistoryService.fetchPRs(query);
	}

	private static async fetchPRs(query: ODataQuery = ApprovalHistoryQuery()): Promise<ListResponse<ApprovalHistoryRequest>> {
		return await ApprovalHistoryService.fetchAll(query);
	}


	static async fetchDetail(query: any): Promise<ListResponse<ApprovalHistoryRequest>> {
		return await ApprovalHistoryService.fetchPRDetailAll(query);
	}

	private static async fetchPRDetailAll(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "')")
			.execute();
		return response.data.d;
	}

	static async fetchAccount(query: any): Promise<ListResponse<ApprovalHistoryRequest>> {
		return ApprovalHistoryService.fetchPRAccount(query);
	}

	private static async fetchPRAccount(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "')/ToAccAssignment")
			.execute();
		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}


	static async fetchPricing(query: any): Promise<ListResponse<ApprovalHistoryRequest>> {
		return ApprovalHistoryService.fetchPRPricing(query);
	}

	private static async fetchPRPricing(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "')/ToPricingCondition")
			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}

	static async fetchApprovalIndicator(query: any): Promise<ListResponse<ApprovalHistoryRequest>> {
		return ApprovalHistoryService.fetchPRApprovalIndicator(query);
	}

	/**
	 * @name fetchPRApprovalIndicator
	 * @param query 
	 * @method GET
	 * @description fetch Approval Indicator for the API
	 */
	private static async fetchPRApprovalIndicator(query: any): Promise<any> {

		const response = await ODataClientWrapper.get()
			.resource("PRApprovals_BAs(Aribapr='" + encodeURIComponent(query.Aribapr) + "',SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "',CompCode='" + encodeURIComponent(query.CompCode) + "',PurchOrg='" + encodeURIComponent(query.PurchOrg) + "',RelStrategy='" + encodeURIComponent(query.RelStrategy) + "',RelGroup='" + encodeURIComponent(query.RelGroup) + "',ApprovalLevel='" + encodeURIComponent(query.ApprovalLevel) + "')")
			.execute();

		if (!response.data || !response.data.d) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}

	/**
	 * @name approvalRequest
	 * @param PRDetail 
	 * @description Post the data for approval of PR
	 */
	static async approvalRequest(PRDetail: ApprovalHistoryRequest): Promise<any> {
		const bodyToUpdate = ApprovalHistoryService.approvalRequestBody(PRDetail);
		/*const response = await AxiosWrapper.put("/api/sapdata/Put?query=PRApprovals_BAs(Aribapr='"+PRDetail.Aribapr+"',SapPR='"+PRDetail.SapPR+"',ItemNo='"+PRDetail.ItemNo+"',CompCode='"+PRDetail.CompCode+"',PurchOrg='"+PRDetail.PurchOrg+"',RelStrategy='"+PRDetail.RelStrategy+"',RelGroup='"+PRDetail.RelGroup+"',ApprovalLevel='"+PRDetail.ApprovalLevel+"')", bodyToUpdate);
		return response.data;*/
		return [];
	}

	private static approvalRequestBody(PRDetail: ApprovalHistoryRequest): any {
		return {
			d: {
				Aribapr: PRDetail.Aribapr,

			}
		}
	}



    /**
	 * function to fetch PR line items
     * @param query
     * @returns {Promise<ListResponse<PurchaseRequest>>}
     */
	static async fetchLines(query: any): Promise<ListResponse<ApprovalHistoryRequest>> {
		return await ApprovalHistoryService.fetchPRLinesAll(query);
	}

	private static async fetchPRLinesAll(query: any): Promise<any> {
		const resource = "PRApproversSet";

		/*Loop for filter array*/
		let filterArray = [];
		if (query.length > 0) {
			query.map((item: any, index: number) => {
				let oDataFilterObj: SimpleODataFilter = { field: item.field, value: item.value };
				filterArray.push(oDataFilterObj);
			});
		}

		/*Merge all variables*/
		const favoriteQuery: PartialODataQuery = { resource, filters: filterArray, orderBy: [] };

		const response = await ODataClientWrapper.get()
			.fromQuery(favoriteQuery)
			.count()
			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}

		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results
		}
	}

}
