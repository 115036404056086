import styled from "styled-components";

export const MaintenanceContainer = styled.div`
  height: 100%;
  display: flex;
  margin: 0 20px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1{
    font-size: 2.5em;
    font-weight: 100;
    color: #F19001;
    margin: 0;
  }
  h2{
    font-size: 1.4em;
    font-weight: 100;
    margin-bottom: 0;
  }
`;

