import React from 'react'
import { useSelector } from "react-redux"
import { Redirect, Route, useLocation } from 'react-router';
import { RootStateStore } from 'src/app/application.reducers';
import { Features } from 'src/app/shared/domains/core/pataflag';
import { useFeatureFlag } from 'src/app/shared/domains/core/pataflag.context';

const PslCatalogsRoute = (props) => {
  const location = useLocation();
  const { hasFeature } = useFeatureFlag();
  const currentUser = useSelector((state: RootStateStore) => state.shared.user.currentUser);
  const isItSpender = hasFeature(Features.PSL_166241_NEW_SPENDER_VIEW) ? currentUser.isRequestor() : false;

  return isItSpender ? (
    <Redirect
      to={{
        pathname: "/pslCatalogs",
        state: { from: location }
      }}
    />
  ) : (
    <Route {...props} />
  )
};

export default PslCatalogsRoute;