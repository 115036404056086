import {DeletedBlockedItem, Item} from "./item";
import {AxiosWrapper} from "../../../shared/utils/axios.wrapper";
import {ContractNumber} from "../contract/contract";
import {IdNo, ListResponse} from "../contract/contract.service";
import {ODataFilter} from "../../../../lib/odata";
import ODataClientWrapper from "../../../shared/utils/odataClient.wrapper";
import {withSystemAlias} from "../../../shared/domains/auth/authentication.service";
import {User} from "../../../shared/domains/user/user";
import {SAPBoolean} from "../../../shared/domains/core/sapBoolean";
import {UserInteractionType} from "../../../shared/domains/user/userInteractionType";

export class ItemService {
  private static ITEMS_PER_PAGE = 10;

  static async fetchItems(itemNo: ContractNumber, systemAlias: string = null, page: number = 0, term?: string, actions: string[] = []): Promise<ListResponse<Item>> {
    const skip = page * 10;

    const actionFilters = actions.reduce((f: ODataFilter, action: string) => f.or({
      field: 'Action',
      value: action
    }), ODataFilter.empty());

    let response = await ODataClientWrapper.get()
      .resource(`ContractHeadSet(IdNo='${IdNo.CONTRACT}',AgreementNo='${itemNo}')/HeadToItem`)
      .filter(actionFilters)
      .and({field: 'SearchTerm', value: term})
      .top(ItemService.ITEMS_PER_PAGE)
      .skip(skip)
      .withSystemAlias(systemAlias, true)
      .count()
      .execute();

    const results = response.data.d.results;

    return {
      count: parseInt(response.data.d.__count),
      data: results.map((item) => Item.FromBackend(item, systemAlias))
    }
  }

  static async fetchDraftItems(agreementId: string, refAgreementNo: ContractNumber, systemAlias: string = null, timestamp: string, page: number = 0): Promise<ListResponse<Item>> {
    const skip = page * 10;

    const response = await ODataClientWrapper.get()
      .resource(`ContractHeadSet(IdNo='${IdNo.DELETE_DRAFT}',AgreementNo='${agreementId}')/HeadToItem`)
      .filter({field: "RefAgreementNo", value: refAgreementNo})
      .and({field: "TimeStamp", value: timestamp})
      .top(ItemService.ITEMS_PER_PAGE)
      .skip(skip)
      .withSystemAlias(systemAlias, true)
      .count()
      .execute();

    const items = response.data.d.results;
    const total = response.data.d.__count;

    return {
      count: parseInt(total),
      data: items.map((item) => Item.FromBackend(item, systemAlias))
    }
  }

  static async fetchMrpItems(agreementId: string, systemAlias: string, page: number = 0): Promise<ListResponse<Item>> {
    const skip = page * 10;

    const response = await ODataClientWrapper.get()
      .resource(`ContractHeadSet(IdNo='${IdNo.MRP}',AgreementNo='${agreementId}')/HeadToItem`)
      .top(ItemService.ITEMS_PER_PAGE)
      .skip(skip)
      .count()
      .withSystemAlias(systemAlias, true)
      .execute();

    const items = response.data.d.results;
    const total = response.data.d.__count;

    return {
      count: parseInt(total),
      data: items.map((item) => Item.FromBackend(item, systemAlias))
    }
  }

  static async fetchDeletedBlockedCounter(agreementId: string, systemAlias: string = null): Promise<ListResponse<Item>> {
    const response = await ODataClientWrapper.get()
      .resource(`ContractHeadSet`)
      .filter({field: "AgreementNo", value: agreementId})
      .and({field: "IdNo", value: IdNo.ITEM_DELETE_BLOCK_COUNTER})
      .top(ItemService.ITEMS_PER_PAGE)
      .withSystemAlias(systemAlias, true)
      .execute();

    const items = response.data.d.results;
    const total = response.data.d.__count;

    return {
      count: parseInt(total),
      data: items.map(DeletedBlockedItem.FromBackend)
    }
  }

  static async fetchDeletedBlockedDraftItems(agreementId: string, refAgreementNo: ContractNumber, systemAlias: string, timestamp: string): Promise<ListResponse<Item>> {
    const response = await ODataClientWrapper.get()
      .resource(`ContractHeadSet`)
      .filter({field: "AgreementNo", value: refAgreementNo})
      .filter({field: "SearchTerm", value: [timestamp, agreementId].join(',')})
      .and({field: "IdNo", value: IdNo.DRAFT_ITEM_DELETE_BLOCK_COUNTER})
      .top(ItemService.ITEMS_PER_PAGE)
      .withSystemAlias(systemAlias, true)
      .execute();

    const items = response.data.d.results;
    const total = response.data.d.__count;

    return {
      count: parseInt(total),
      data: items.map(DeletedBlockedItem.FromBackend)
    }
  }

  static async addRemoveItemFavorite(itemId: string, systemAlias: string): Promise<number> {
    const path = `/api/Items/AddOrRemoveFavoriteFromItemFavorites/${itemId}`;
    const response = await AxiosWrapper.post(path, {headers: withSystemAlias(systemAlias, {}, true)});
    return response.data;
  }

  static async update(item: Item): Promise<any> {
    const path = `/api/sapdata/PostFromBody?query=IdSet`;
    const bodyToUpdate = ItemService.updateBody(item);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(item.SystemAlias, {},true)});
    return response.data;
  }

  static async updateDraft(item: Item): Promise<any> {
    const path = `/api/sapdata/PostFromBody?action=` + UserInteractionType.EditDraftContract + `&query=IdSet`;
    const bodyToUpdate = ItemService.updateDraftItemBody(item);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(item.SystemAlias, {}, true)});
    return response.data;
  }

  static async updateMrp(item: Item, user: User): Promise<any> {
    const path = `/api/sapdata/PostFromBody?action=` + UserInteractionType.UpdateMrpMapping + `&query=IdSet`;
    const bodyToUpdate = ItemService.updateMRPItemBody(item, user);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(item.SystemAlias, {},true)});
    return response.data;
  }

  static async deleteDraft(item: Item): Promise<any> {
    const path = `/api/sapdata/PostFromBody?query=IdSet`;
    const bodyToUpdate = ItemService.deleteDraftItem(item);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(item.SystemAlias, {}, true)});
    return response.data;
  }

  private static updateBody(item: Item): any {
    return {
      results: {
        IdNo: IdNo.ITEM,
        DataChanged: SAPBoolean.TRUE,
        HeadsToItem: {
          results: [
            {
              IdNo: IdNo.ITEM,
              AgreementNo: item.AgreementNo,
              ItemNo: item.ItemNo,
              Material: item.Material,
              ShortText: item.ShortText,
              TargetQty: item.TargetQty,
              OrderUnit: item.OrderUnit,
              NetPrice: item.NetPrice,
              Per: item.Per,
              OrderPriceUnit: item.OrderPriceUnit,
              Plant: item.Plant,
              DataChanged: SAPBoolean.TRUE,
              Action: item.Action
            }
          ]
        }
      }
    };
  }

  private static updateDraftItemBody(item: Item): any {
    return {
      results: {
        IdNo: IdNo.DRAFT,
        DataChanged: SAPBoolean.TRUE,
        HeadsToItem: {
          results: [
            {
              IdNo: IdNo.DRAFT,
              AgreementNo: item.AgreementNo,
              ItemNo: item.ItemNo,
              Material: item.Material,
              ShortText: item.ShortText,
              TargetQty: item.TargetQty,
              OrderUnit: item.OrderUnit,
              NetPrice: item.NetPrice,
              Per: item.Per,
              OrderPriceUnit: item.OrderPriceUnit,
              Plant: item.Plant,
              DataChanged: SAPBoolean.TRUE,
              Action: item.Action,
              TimeStamp: item.TimeStamp,
              RefAgreementNo: item.RefAgreementNo,
            }
          ]
        }
      }
    };
  }

  private static updateMRPItemBody(item: Item, user: User): any {
    return {
      results: {
        IdNo: IdNo.MI,
        DataChanged: SAPBoolean.TRUE,
        User: user.accountName.toUpperCase(),
        HeadsToItem: {
          results: [
            {
              IdNo: IdNo.MI,
              AgreementNo: item.AgreementNo,
              ItemNo: item.ItemNo,
              TargetQty: item.TargetQty
            }
          ]
        }
      }
    };
  }

  private static deleteDraftItem(item: Item): any {
    return {
      results: {
        IdNo: IdNo.DELETE_DRAFT,
        DataChanged: SAPBoolean.TRUE,
        HeadsToItem: {
          results: [
            {
              IdNo: IdNo.DELETE_DRAFT,
              AgreementNo: item.AgreementNo,
              ItemNo: item.ItemNo,
              Material: item.Material,
              ShortText: item.ShortText,
              TargetQty: item.TargetQty,
              OrderUnit: item.OrderUnit,
              NetPrice: item.NetPrice,
              TimeStamp: item.TimeStamp,
              RefAgreementNo: item.RefAgreementNo,
              Per: item.Per,
              OrderPriceUnit: item.OrderPriceUnit,
              Plant: item.Plant,
              DataChanged: SAPBoolean.TRUE,
              Action: item.Action
            }
          ]
        }
      }
    };
  }
}
