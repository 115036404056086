import { Dispatch } from "redux";
import { PRSubstitutionService } from "../domains/prSubstitution/prSubstitution.service";
import { ErrorAction, IAction, PendingAction, SuccessAction } from "../../../shared/domains/core/actions";
import { PRSubstitutionRequest } from "../domains/prSubstitution/prSubstitution";
import any from 'ramda/es/any';
import { SuccessNotificationAction } from "../../../shared/domains/core/notifications";
import { AxiosWrapper } from 'src/app/shared/utils/axios.wrapper';

export enum PRSubstitutionActionTypes {
    FETCH_PR_SUBSTITUTION = "FETCH_PR_SUBSTITUTION",
    FETCH_COST_OBJECT = "FETCH_COST_OBJECT",
    FETCH_SUBSTITUTEE = "FETCH_SUBSTITUTEE",
    FETCH_COST_OBJECT_SUCCESS = "FETCH_COST_OBJECT_SUCCESS",
    FETCH_SUBSTITUTEE_SUCCESS = "FETCH_SUBSTITUTEE_SUCCESS",
    FETCH_COST_OBJECT_FAILURE = "FETCH_COST_OBJECT_FAILURE",
    FETCH_SUBSTITUTEE_FAILURE = "FETCH_SUBSTITUTEE_FAILURE",
    FETCH_PR_SUBSTITUTION_SUCCESS = "FETCH_PR_SUBSTITUTION_SUCCESS",
    FETCH_PR_SUBSTITUTION_FAILURE = "FETCH_PR_SUBSTITUTION_FAILURE",
    NEW_PAGE_AS = "NEW_PAGE_AS",
    NEW_ORDER_AS = "NEW_ORDER_AS",
    NEW_TERM_AS = "NEW_TERM_AS",
    NEW_AS_QUERY = "NEW_AS_QUERY",
    SET_SELECTED_AS = "SET_SELECTED_AS",
    FETCH_PR_SUBSTITUTION_DETAIL = "FETCH_PR_SUBSTITUTION_DETAIL",
    FETCH_PR_SUBSTITUTION_DETAIL_SUCCESS = "FETCH_PR_SUBSTITUTION_DETAIL_SUCCESS",
    FETCH_PR_SUBSTITUTION_DETAIL_FAILURE = "FETCH_PR_SUBSTITUTION_DETAIL_FAILURE",
    FETCH_PR_SUBSTITUTION_LINES = "FETCH_PR_SUBSTITUTION_LINES",
    FETCH_PR_SUBSTITUTION_LINES_SUCCESS = "FETCH_PR_SUBSTITUTION_LINES_SUCCESS",
    FETCH_PR_SUBSTITUTION_LINES_FAILURE = "FETCH_PR_SUBSTITUTION_LINES_FAILURE",
    PRSUBSTITUTION_APPROVED_PR = "PRSUBSTITUTION_APPROVED_PR",
    PRSUBSTITUTION_APPROVED_PR_SUCCESS = "PRSUBSTITUTION_APPROVED_PR_SUCCESS",
    PRSUBSTITUTION_APPROVED_PR_FAILURE = "PRSUBSTITUTION_APPROVED_PR_FAILURE",
    MAIL_SUCCESS = "SUCCESS",
    MAIL_FAILURE = "ERROR",
    ADD_PR_SUBSTITUTION = "ADD_PR_SUBSTITUTION",
    ADD_PR_SUBSTITUTION_SUCCESS = "ADD_PR_SUBSTITUTION_SUCCESS",
    ADD_PR_SUBSTITUTION_FAILURE = "ADD_PR_SUBSTITUTION_FAILURE",
    SET_SUBSTITUTION_BEHALF_USER = "SET_SUBSTITUTION_BEHALF_USER",
}

/** SET behalf User */
export class behalfUser extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.SET_SUBSTITUTION_BEHALF_USER;
}

export function setBehalfUser(userData: any, dispatch: Dispatch<IAction>) {
    dispatch((new behalfUser({userData})));
}

/** Fetch all PR list */
export class FetchPRs extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION;
}

export class FetchPRsSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_SUCCESS;
}

export class FetchPRsError extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

/** Fetch DropDown data list for  Cost Object*/
export class FetchCostObject extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_COST_OBJECT;
}

export class FetchCostObjectSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_COST_OBJECT_SUCCESS;
}

export class FetchCostObjectFailure extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_COST_OBJECT_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

/** Fetch DropDown data list for  Substitutee*/
export class FetchSubstitutee extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_SUBSTITUTEE;
}

export class FetchSubstituteeSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_SUBSTITUTEE_SUCCESS;
}

export class FetchSubstituteeFailure extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_SUBSTITUTEE_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

class SetSelectedRow extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.SET_SELECTED_AS;
}

export function setSelectedRow(purchaseRequest: PRSubstitutionRequest, dispatch: Dispatch<IAction>) {
    dispatch((new SetSelectedRow({ purchaseRequest: purchaseRequest })));
}

/** Fetch PR Details */
export class FetchPRDetail extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_DETAIL;
}

export class FetchPRDetailSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_DETAIL_SUCCESS;
}

export class FetchPRDetailError extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_DETAIL_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRDetail(purchaseRequest: PRSubstitutionRequest, dispatch: Dispatch<IAction>) {
    dispatch((new FetchPRDetail(purchaseRequest)));
}

/** Get list of PR Line according to the PR Number (From Master Detail) */
export class FetchPRLines extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_LINES;
}

export class FetchPRLinesSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_LINES_SUCCESS;
}

export class FetchPRLinesError extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_LINES_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRLines(purchaseRequest: PRSubstitutionRequest, AribaPr: string, ApprovalType: string, ApprovalLevel: string, positionId: string, dispatch: Dispatch<IAction>) {
    let purchaseRequestTemp: any = {};
    purchaseRequestTemp = purchaseRequest;
    purchaseRequestTemp.AribaPr = AribaPr;
    purchaseRequestTemp.ApprovalType = ApprovalType;
    purchaseRequestTemp.ApprovalLevel = ApprovalLevel;
    purchaseRequestTemp.positionId = positionId;
    dispatch((new FetchPRLines(purchaseRequestTemp)));
}

/** Approval Process of the PR Line */
export class ApprovedPR extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.PRSUBSTITUTION_APPROVED_PR;
}

export class EmailTrigger extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION;
}

export class ApprovedPRSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.PRSUBSTITUTION_APPROVED_PR_SUCCESS;
}

export class AddSubstitutionSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.MAIL_SUCCESS;
    public readonly message = "PR substitution added.";
}
export class DeleteSubstitutionSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.MAIL_SUCCESS;
    public readonly message = "PR substitution Deleted.";
}


export class ApprovedPRError extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.PRSUBSTITUTION_APPROVED_PR_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export class MailFailure extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.MAIL_FAILURE;
    public readonly message = "Something went wrong. Please Try again later.";
    constructor(public error) {
        super(error, true);
    }
}

export function approvedPR(purchaseRequest: PRSubstitutionRequest, dispatch: Dispatch<IAction>) {
    dispatch((new ApprovedPR(purchaseRequest)));
}


class AddSubstitution extends PendingAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION;
    constructor(public payload: any) {
        super();
    }
}

export class AddPRsSuccess extends SuccessAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION_SUCCESS;
}

export class AddPRsError extends ErrorAction<PRSubstitutionActionTypes> {
    public readonly type = PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function addPRSubstitutions(data: any, callback: Function, dispatch: Dispatch<IAction>) {
    dispatch(new AddSubstitution({ data, callback }));
}