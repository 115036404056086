import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";
import { makeStyles } from '@material-ui/core/styles';

export const TextWithPopover = ({ ftvData }: { ftvData: string[] }) => {



    const useStyles = makeStyles((theme) => ({
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
        customTooltip: {
            backgroundColor: "#fff",
            color: '#777777',
            boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)", borderRadius: "5px",
            marginTop: 0,
        },

    }));

    // const classes = useStylesForToolTip({});

    const classes = useStyles({});

    return (
        <div>
            <Tooltip
                interactive
                classes={{ tooltip: classes.customTooltip }}
                title={
                    ftvData.map((item, key) =>
                        <div
                            style={{
                                fontSize: 14,
                                paddingTop: 5,
                                paddingBottom: 5,
                            }}
                            key={key}
                        >
                            {item}
                        </div>
                    )
                }
                placement="bottom-start"
            >
                <div style={{ display: "flex", gap: "8px" }}>
                    <div style={{ color: "#F19001", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer", float: "left" }}>
                        {
                            ftvData.length === 1 && ftvData[0]
                        }
                        {
                            ftvData.length > 1 && ftvData?.slice(0, 2)?.join(" | ")
                        }
                    </div>
                    {
                        ftvData.length > 2 &&
                        <div style={{ borderRadius: "8px", textAlign: "center", width: "12%", backgroundColor: "#F19001", color: "white" }}>+
                            {
                                String(ftvData.length - 2)
                            }
                        </div>
                    }

                </div>

            </Tooltip>
        </div>
    );
}
