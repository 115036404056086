import { template } from "lodash";
import * as R from 'ramda';
import { doWeNeedRteUrl, doWeNeedUrl } from "src/app/docFlows/components/dataTable/docFlow/docFlowTableRelated/PrLink";
import { ebRealm, ebUrl, sapUrl } from "src/app/shared/utils/urlBuilders";

export interface InvoiceDTO {
  __metadata: { id: string; type: string; uri: string };
  BlckRsn: string,
  BlckRsnTxt: string,
  CompCode: string,
  Currency: string,
  FiscalYr: string,
  InvAmount: string,
  InvDate: string,
  InvDocNo: string,
  InvDueDate: string,
  InvPartner: string,
  InvPartnerName: string,
  CountOfUnreadComments: number,
  TotalCountOfComments: number,
  ParkRsnCode: string,
  ParkRsnCodeTxt?: string,
  isThereAnyNewNotes: boolean,
  IsNotesPresent: boolean,
  Plant: string,
  PoNo: string,
  PostDate: string,
  PrNo: string,
  PurchGrp: string,
  PurchOrg: string,
  RefDoc: string,
  SAP__Origin: string,
  Scenario: string,
  SearchTerm: string,
  StratBuyer: string,
  SystemAlias: string,
  AssignedTo: string,
  AgingDays: string,
  InstructionDate: string,
  To_actor: string,
  EnableNotification: boolean,
  NotificationCount: number,
  LastNotificationSentDate: string,
  TopWfId: string,
  WfAed: string,
  WfAgent: string,
  WfCd: string,
  WfId: string,
  WfStartDur: string,
  WfStatusCurrent: string,
  WfUrl: string,
  PrCreatorName: string,
  Requisitioner: string,
  PrCreatorfnam: string,
  PrCreatorlnam: string,
  PRCreatoremail: string,
  PrReqFname: string,
  PrReqLname: string,
  PRReqemail: string,
}
const ViewInvoiceTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2fevery_user!2fgeneral!2fuwl!2fcom.sap.netweaver.bc.uwl.uwlSapLaunch?System=<%=system%>&TCode=MIR4&DynamicParameter=RBKP-BELNR=<%=invoice%>&RBKP-GJAHR=<%=year%>&AutoStart=true"
);

export const DevViewInvoiceTemplate = template(
  // "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2fevery_user!2fgeneral!2fuwl!2fcom.sap.netweaver.bc.uwl.uwlSapLaunch?System=<%=system%>&TCode=MIR4&DynamicParameter=RBKP-BELNR=<%=invoice%>&RBKP-GJAHR=<%=year%>&AutoStart=true"
  "https://<%=prefix%>.nestle.com/irj/servlet/prt/portal/prtroot/com.nestle.globe.ep7core.xx.redirect.Redirect_window?REDIRECTURL=https://erpdev.nestle.com:52802/launchpad%23RGTFC00_MIR4-execute%3FP_BELNR%3d<%=invoice%>%26P_GJAHR=<%=year%>"
);

export const RteViewInvoiceTemplate = template(
  // "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2fevery_user!2fgeneral!2fuwl!2fcom.sap.netweaver.bc.uwl.uwlSapLaunch?System=<%=system%>&TCode=MIR4&DynamicParameter=RBKP-BELNR=<%=invoice%>&RBKP-GJAHR=<%=year%>&AutoStart=true"
  "https://<%=prefix%>.nestle.com/irj/servlet/prt/portal/prtroot/com.nestle.globe.ep7core.xx.redirect.Redirect_window?REDIRECTURL=https://erpaoarte.nestle.com:55802/launchpad%23RGTFC00_MIR4-execute%3FP_BELNR%3d<%=invoice%>%26P_GJAHR=<%=year%>"
);

export class Invoice {
  constructor(
    public readonly BlckRsn: string,
    public readonly BlckRsnTxt: string,
    public readonly CompCode: string,
    public readonly Currency: string,
    public readonly FiscalYr: string,
    public readonly InvAmount: string,
    public readonly InvDate: string,
    public readonly InvDocNo: string,
    public readonly InvDueDate: string,
    public readonly InvPartner: string,
    public readonly InvPartnerName: string,
    public readonly CountOfUnreadComments: number,
    public readonly TotalCountOfComments: number,
    public readonly ParkRsnCode: string,
    public readonly ParkRsnCodeTxt: string,
    public readonly isThereAnyNewNotes: boolean,
    public readonly IsNotesPresent: boolean,
    public readonly Plant: string,
    public readonly PoNo: string,
    public readonly PostDate: string,
    public readonly PrNo: string,
    public readonly PurchGrp: string,
    public readonly PurchOrg: string,
    public readonly RefDoc: string,
    public readonly SAP__Origin: string,
    public readonly Scenario: string,
    public readonly SearchTerm: string,
    public readonly StratBuyer: string,
    public readonly SystemAlias: string,
    public readonly AssignedTo: string,
    public readonly AgingDays: string,
    public readonly InstructionDate: string,
    public readonly To_actor: string,
    public readonly EnableNotification: boolean,
    public readonly NotificationCount: number,
    public readonly LastNotificationSentDate: string,
    public readonly TopWfId: string,
    public readonly WfAed: string,
    public readonly WfAgent: string,
    public readonly WfCd: string,
    public readonly WfId: string,
    public readonly WfStartDur: string,
    public readonly WfStatusCurrent: string,
    public readonly WfUrl: string,
    public ViewInvoiceLink: string,
    public PrCreatorName: string,
    public Requisitioner: string,
    public PrCreatorfnam: string,
    public PrCreatorlnam: string,
    public PRCreatoremail: string,
    public PrReqFname: string,
    public PrReqLname: string,
    public PRReqemail: string,
  ) {
    this.WfStartDur = (WfStartDur || "").trim();
  }

  static FromBackend(data: InvoiceDTO): Invoice {
    const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());
    const sapPrefixed = R.mergeRight({ prefix: sapPrefix, system: data.SystemAlias });

    const ViewInvoiceLink = doWeNeedUrl(data.CompCode) ?
      DevViewInvoiceTemplate(sapPrefixed({ invoice: data.InvDocNo, year: data.FiscalYr })) :
      doWeNeedRteUrl(data.SystemAlias) ?
        RteViewInvoiceTemplate(sapPrefixed({ invoice: data.InvDocNo, year: data.FiscalYr })) :
        ViewInvoiceTemplate(sapPrefixed({ invoice: data.InvDocNo, year: data.FiscalYr }));

    return new Invoice(
      data.BlckRsn,
      data.BlckRsnTxt,
      data.CompCode,
      data.Currency,
      data.FiscalYr,
      data.InvAmount,
      data.InvDate,
      data.InvDocNo,
      data.InvDueDate,
      data.InvPartner,
      data.InvPartnerName,
      data.CountOfUnreadComments,
      data.TotalCountOfComments,
      data.ParkRsnCode,
      data.ParkRsnCodeTxt,
      data.isThereAnyNewNotes,
      data.IsNotesPresent,
      data.Plant,
      data.PoNo,
      data.PostDate,
      data.PrNo,
      data.PurchGrp,
      data.PurchOrg,
      data.RefDoc,
      data.SAP__Origin,
      data.Scenario,
      data.SearchTerm,
      data.StratBuyer,
      data.SystemAlias,
      data.AssignedTo,
      data.AgingDays,
      data.InstructionDate,
      data.To_actor,
      data.EnableNotification,
      data.NotificationCount,
      data.LastNotificationSentDate,
      data.TopWfId,
      data.WfAed,
      data.WfAgent,
      data.WfCd,
      data.WfId,
      data.WfStartDur,
      data.WfStatusCurrent,
      data.WfUrl,
      ViewInvoiceLink,
      data.PrCreatorName,
      data.Requisitioner,
      data.PrCreatorfnam,
      data.PrCreatorlnam,
      data.PRCreatoremail,
      data.PrReqFname,
      data.PrReqLname,
      data.PRReqemail,
    )
  }
}

export type InvoiceId = string;
