import React from "react";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {withContext} from "../../../../services/context.service";
import {ContextForm, SelectFieldWrapper, TextareaFieldWrapper} from "../../../../components/form";
import {
  FeedbackDetails,
  FeedbackDialogContent,
  FeedbackList,
  StyledFeedbackForm,
  StyledUserFeedbackDialog
} from "./feedback.styles";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StarRatings from "react-star-ratings";
import {RefusedSendFeedback, SendFeedback} from "../../../../actions/user.actions";
import {UserFeedback} from "../../../../domains/user/user";
import {RootStateStore} from "../../../../../application.reducers";
import {StyledDialogActions, StyledDialogTitle} from "../../../../components/layout/styles";
import {PopupLoading} from "../../../../components/loading";
import {UiModule} from "../../../../domains/core/ui";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";

enum Types {
  BUG = "bug",
  IMPROVEMENT = "improvement",
  FEATURE = 'feature'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 14,
      fontStyle: 'italic',
      marginBottom: 5
    },
    select: {
      marginBottom: '10px!important'
    },
    hint: {
      color: '#000'
    },
    rating: {
      marginBottom: 10
    },
    center: {
      textAlign: 'center'
    }
  })
);

const FeedbackContent = ({onClose, form: {values, setFieldValue}}) => {

  const { hasFeature } = React.useContext(FeaturesContext);
  const {label, select, hint, rating, center} = useStyles({});
  const [feedbackType, setFeedbackType] = React.useState(Types.IMPROVEMENT);
  const [score, setScore] = React.useState(10);
  const [rate, setRate] = React.useState(0);
  const sending: boolean = useSelector((state: RootStateStore) => state.shared.user.sendingFeedback);
  const handleChangeRate = (rate) => {
    setRate(rate);
    setFieldValue('rating', rate);
  };
  const handleChangeScore = (score) => {
    setScore(score);
    setFieldValue('netPromoterScore', score);
  };
  return (
    <>
      {
        sending ? <PopupLoading text={'Sending...'}/> :
          <FeedbackDialogContent>
            <FeedbackDetails>
              <Grid container>
                <Grid item sm={12} className={label}>Choose appropriate module</Grid>
                <Grid item sm={12} className={select}>
                  <SelectFieldWrapper
                    menuPortalTarget={document.body}
                    name={`module`}
                    options={ UiModule.toNewSelect()}
                  />
                </Grid>
                <Grid item sm={12} className={label}>Feedback Type</Grid>
                <Grid item sm={12}>
                  <FeedbackList>
                    {/*<li key="bug" className={clsx('item', 'type', feedbackType === Types.BUG ? 'selected' : '')}*/}
                        {/*onClick={() => setFeedbackType(Types.BUG)}><div className="text">Bug</div>*/}
                    {/*</li>*/}
                    <li key="improvement" className={clsx('item', 'type', feedbackType === Types.IMPROVEMENT ? 'selected' : '')}
                        onClick={() => setFeedbackType(Types.IMPROVEMENT)}><div className="text">Improvement</div>
                    </li>
                    <li key="selected" className={clsx('item', 'type', feedbackType === Types.FEATURE ? 'selected' : '')}
                        onClick={() => setFeedbackType(Types.FEATURE)}><div className="text">New Feature</div>
                    </li>
                  </FeedbackList>
                </Grid>
                <Grid item sm={12} className={label}>Message</Grid>
                <Grid item sm={12} className={clsx(label, hint)}>{
                  feedbackType === Types.IMPROVEMENT ? 'Please list any ideas for improvement of currently available features':
                    feedbackType === Types.FEATURE ? 'Please describe any new feature that in your opinion should be developed in application': ''
                }</Grid>
                <Grid item sm={12}>
                  {
                    feedbackType === Types.BUG && <TextareaFieldWrapper name="bugMessage" placeholder='Your message...'/>
                  }
                  {
                    feedbackType === Types.IMPROVEMENT && <TextareaFieldWrapper name="improvementMessage" placeholder='Your message...'/>
                  }
                  {
                    feedbackType === Types.FEATURE && <TextareaFieldWrapper name="newFeatureMessage" placeholder='Your message...'/>
                  }
                </Grid>
                <Grid item sm={12} className={label}>How would you rate your experience with application?</Grid>
                <Grid item sm={12} className={clsx(center, rating)}>
                  <StarRatings
                    rating={rate}
                    starDimension="30px"
                    starSpacing="10px"
                    numberOfStars={5}
                    starRatedColor='#F19001'
                    starHoverColor='#F19001'
                    changeRating={(newValue) => handleChangeRate(newValue)}/>
                </Grid>
                <Grid item sm={12} className={label}>How likely would you recommend this tool to a colleague to perform his/her work?</Grid>
                <Grid item sm={12} className={center}>
                  <FeedbackList>
                    {
                      new Array(11).fill(0).map((v, idx) => {
                        return <li key={idx} className={clsx('item', 'score', score === idx ? 'selected' : '')} onClick={() => handleChangeScore(idx)}><div className="text">{idx}</div></li>
                      })
                    }
                  </FeedbackList>
                </Grid>
              </Grid>
            </FeedbackDetails>
          </FeedbackDialogContent>
      }
      <StyledDialogActions>
        <Button color="primary" onClick={() => onClose()}>Cancel</Button>
        <Button color="primary" type="submit">Send</Button>
      </StyledDialogActions>
    </>
  );
};

export const FeedbackPopup = ({onClose}) => {
  const dispatch = useDispatch();
  const {isUserFeedbackRequired} = useSelector((state: RootStateStore) => state.shared.user.currentUser);
  const module = useSelector((state: RootStateStore) => state.shared.ui.module);
  const feedback: UserFeedback = {
    bugMessage: '',
    improvementMessage: '',
    newFeatureMessage: '',
    type: Types.BUG,
    rating: 0,
    netPromoterScore: 10,
    module: module
  };
  const handleSubmit = ((value) => dispatch(new SendFeedback(value, onClose)));
  const handleClose = () => {
    if (isUserFeedbackRequired) {
      dispatch(new RefusedSendFeedback());
    }
    onClose();
  };
  const FeedbackForm = withContext(FeedbackContent);
  return (
    <StyledUserFeedbackDialog open={true} maxWidth={"xs"} scroll={'paper'} fullWidth={true}>
      <ContextForm onSubmit={handleSubmit} initialValues={feedback}>
        <StyledFeedbackForm>
          <StyledDialogTitle>User feedback</StyledDialogTitle>
          <FeedbackForm onClose={handleClose}/>
        </StyledFeedbackForm>
      </ContextForm>
    </StyledUserFeedbackDialog>
  )
};

