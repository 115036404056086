import {ErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";

export enum ContractFilterActionTypes {
  INIT_FILTERS = 'INIT_CONTRACT_FILTERS',

  GET_FILTERS = "GET_CONTRACT_FILTERS",
  GET_FILTERS_SUCCESS = "GET_CONTRACT_FILTERS_SUCCESS",
  GET_FILTERS_FAILURE = "GET_CONTRACT_FILTERS_FAILURE",

  RESET_FILTERS = "RESET_CONTRACT_FILTERS",
  DELETE_FILTER = "DELETE_CONTRACT_FILTER",
}

export class InitFilters extends PendingAction<ContractFilterActionTypes> {
  public readonly type = ContractFilterActionTypes.INIT_FILTERS;
}

export class GetFilters extends PendingAction<ContractFilterActionTypes> {
  public readonly type = ContractFilterActionTypes.GET_FILTERS;
}

export class GetFiltersSuccess extends SuccessAction<ContractFilterActionTypes> {
  public readonly type = ContractFilterActionTypes.GET_FILTERS_SUCCESS;
}

export class GetFiltersError extends ErrorAction<ContractFilterActionTypes> {
  public readonly type = ContractFilterActionTypes.GET_FILTERS_FAILURE;
}

export class ResetFilters extends PendingAction<ContractFilterActionTypes> {
  public readonly type = ContractFilterActionTypes.RESET_FILTERS;
}
