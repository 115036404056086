import { all, fork } from 'redux-saga/effects'
import businessApprovalsSage from "./purchaseRequest.sagas";
import filterSaga from './filter.saga'

export default function* rootSaga() {
  yield all([
    fork(businessApprovalsSage),
    fork(filterSaga)
  ])
};
