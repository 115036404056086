import {IAction} from "../../shared/domains/core/actions";
import {QueryableReducer} from "../../shared/queryable/query.reducer";
import {error, loaded} from "../../shared/reducers/utils";
import {PSLTypes} from "../actions/psl.actions";
import {newPslQuery} from "../domains/pslQuery";
import {ISupplier} from "../domains/psl";

export interface PslApproverListState {
  loading: boolean;
  error: string;
  totalCount: number;
  pslList: ISupplier[];
}

export const initialPslApproverListState: PslApproverListState = {
  loading: true,
  error: null,
  totalCount: 0,
  pslList: [],
};

export function pslListReducer(state: PslApproverListState = initialPslApproverListState, action: IAction<PSLTypes>): PslApproverListState {
  switch (action.type) {
    case PSLTypes.FETCH_PSL_REQUEST_SUCCESS:
      return loaded({...state, pslList: action.payload?.data, totalCount: action.payload?.total});
    case PSLTypes.FETCH_PSL_REQUEST_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}

export const pslApproverStateSelector = ['psl', 'pslApproverList'];
export const pslApproverDomainName = 'PSL_APPROVERS';
export const {reducer: PslApproverListReducer, sagas: PslApproverListSagas, actions: QueryPslApproverActions} = QueryableReducer<PslApproverListState>(
  pslApproverDomainName,
  pslApproverStateSelector,
  pslListReducer,
  newPslQuery()
);
