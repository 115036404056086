import {NotificationType} from "../domains/core/notifications";
import {IAction} from "../domains/core/actions";

export interface NotificationState {
  source: string;
  ignoreSource: boolean;
  success: boolean;
  message: string;
  error: any;
}

export const initialErrorsState: NotificationState = {
  source: null,
  ignoreSource: false,
  success: false,
  message: null,
  error: null
};

export function NotificationsReducer(state: NotificationState = initialErrorsState, action: IAction<NotificationType>): NotificationState {
  switch (action.type) {
    case NotificationType.RESET:
      return initialErrorsState;
    case NotificationType.ERROR:
      return {...state, ignoreSource: action.ignoreErrorType, source: action.source, message: action.message, error: action.error };
    case NotificationType.SUCCESS:
      return { ...state, message: action.message, success: true };
    default: return state;
  }
}
