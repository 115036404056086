import {mergeRight} from "ramda";
import {Item} from "../../domains/item/item";
import {ItemActionTypes} from "../../actions/item.actions";
import {IAction} from "../../../shared/domains/core/actions";
import {error, loading} from "../../../shared/reducers/utils";

export interface MrpItemsState {
  items: Item[];
  originalItems: Item[];
  totalCount: number;
  loading: boolean;
  error: any;
}

export const initialItemState: MrpItemsState = {
  items: [],
  originalItems: [],
  totalCount: 0,
  loading: false,
  error: null
};

export function MrpItemsReducer(state: MrpItemsState = initialItemState, action: IAction<ItemActionTypes>): MrpItemsState {
  switch (action.type) {
    case ItemActionTypes.FETCH_MRP_ITEMS:
    case ItemActionTypes.UPDATE_MRP_ITEM:
      return loading(state);
    case ItemActionTypes.FETCH_ORIGINAL_MRP_ITEMS:
      return loading(mergeRight(state, {originalItems: []}));
    case ItemActionTypes.FETCH_MRP_ITEMS_SUCCESS:
      return {...state, loading: false, items: action.items, totalCount: action.totalCount};
    case ItemActionTypes.FETCH_ORIGINAL_MRP_ITEMS_SUCCESS:
      return {...state, loading: false, originalItems: action.items};
    case ItemActionTypes.UPDATE_MRP_ITEM_SUCCESS:
      const updatedItems = state.items.map((item: Item) => {
        if (item.ItemNo === action.item.ItemNo) {
          return action.item;
        }
        return item;
      });
      return {...state, loading: false, items: updatedItems};
    case ItemActionTypes.FETCH_MRP_ITEMS_FAILURE:
    case ItemActionTypes.FETCH_ORIGINAL_MRP_ITEMS_FAILURE:
    case ItemActionTypes.UPDATE_MRP_ITEM_FAILURE:
      return error(action.error)(state);
    case ItemActionTypes.FETCH_MRP_ITEMS_SUCCESS:
      return {...state, loading: false, items: action.items, totalCount: action.totalCount};
    default:
      return state;
  }
}
