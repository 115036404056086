import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@aspnet/signalr'
import { Subject } from "rxjs";
import { newEvent, SubscriptionFunction } from "../../../contracts/domains/chat/types";
import {ifElse} from "ramda";

export interface IMessage {
  id: number;
  isReadByUser: boolean;
  message: string;
  sentAt: string;
  userId: number;
  userName: string;
  mentionedPersons?: string;
  attachments?: string;
  userEmail?: string;
  applicationCreated?:string;
  tabCreated?:string;
  invoiceNumber?:string;
  poId?:string;
  prId?:string;
  chatId?:string;
}

export interface IChatGroup {
  id: number;
  isReadByUser: boolean;
  message: string;
  sentAt: string;
  userId: number;
  userName: string;
}

export enum ChatCounterType {
  CONTRACT = "Contract",
  DOCFLOW = "DocFlow",
  INVOICE = "Invoice"
}

export interface IChatCounter {
  type: ChatCounterType;
  id: string;
  userId: number;
}

export interface IRoomChatService {
  join(): Promise<boolean>
  send(id: number): Promise<boolean>
  leave(): Promise<boolean>
}

export interface ChatState {
  room: IRoomChatService;
  loading: boolean;
  sending: boolean;
  error: string;
}

export class RoomChatService<T> implements IRoomChatService {
  private message$ = new Subject<T>();
  public onNewMessages: SubscriptionFunction<T>;

  constructor(
    private connection: HubConnection,
    private hubName: string,
    private roomName: string,
    private topicName: string,
  ) {
    this.onNewMessages = newEvent(this.message$);
  }

  async join(): Promise<boolean> {
    this.connection.on(this.topicName, data => {
      this.message$.next(data);
    });

    await this.connection.start().then(() => console.log('Listening...'));

    return await this.connection.invoke("JoinGroup", this.roomName)
      .catch(err => console.log(err))
      .then(() => {
        console.log("Joined group " + this.roomName);
        return true;
      })
  }

  async send(chatId: number): Promise<boolean> {
    return await this.connection.invoke("SendMessage", this.roomName, chatId)
      .catch(err => console.log(err))
      .then(() => true)
  }

  async leave(): Promise<boolean> {
    this.connection.off(this.topicName);

    return await this.connection.invoke("LeaveGroup", this.roomName)
      .catch(err => console.log(err))
      .then(() => {
        console.log("Left group " + this.roomName);
        return true;
      });
  }

  static newInstance<T>(
    hubName: string,
    roomName: string,
    topicName: string
  ): RoomChatService<T> {
    let url = ""
    if (process.env.REACT_APP_ENV === "dev") {
      url = `https://emna-np-contractbrowser-webapi-euwe-001-app.azurewebsites.net/${hubName}`;
    }
    else if(process.env.REACT_APP_ENV === "rte") {
      url = `https://emna-np-contractbrowser-webapi-euwe-001-app.azurewebsites.net/${hubName}`;
    }
    else if(process.env.REACT_APP_ENV === "test") {
      url = `https://glbl-pp-contractbrowser-webapi-euwe-001-app.azurewebsites.net/${hubName}`;
    }
    else if(process.env.REACT_APP_ENV === "prod") {
      url = `https://glbl-pr-contractbrowser-webapi-euwe-001-app.azurewebsites.net/${hubName}`;
    }
    else if (process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "local2") {
      url = `https://localhost:5001/${hubName}`;
    }
    else {
      throw new Error("Unknown environment");
    }

    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    return new RoomChatService<T>(connection, hubName, roomName, topicName);
  }
}
