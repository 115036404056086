import {all, call, fork, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import {InvoiceService} from "../domains/invoices/invoice.service";
import {
  InvoiceItemsFetchLoad,
  InvoiceItemsFetchFailureAction,
  InvoiceItemsFetchSuccessAction,
  InvoicesFetchSuccessAction,
  InvoicesFetchFailureAction,
  InvoicesFetchLoad,
  InvoiceTypes,
  InvoiceStatusFetchSuccessAction,
  InvoicetatusFetchFailureAction,
  InvoiceFetchParkingCodeAction,
  InvoicesFetchParkingCodeSuccessAction,
  InvoicesFetchParkingCodeFailureAction
} from "../actions/invoices.actions";
import {List, ListWithStatus} from "../../../shared/utils/odataClient.wrapper";
import {QueryActionsI} from '../reducers/queryableInvoiceReducer';
import {RootStateStore} from "../../../application.reducers";
import {newInvoicesQuery} from "../domains/invoices/InvoiceQuery";
import {defaultCompany, User} from "../../../shared/domains/user/user";
import {PRPOItemService} from "../../domains/prpo/item.service";
import { Invoice } from '../domains/invoices/Invoice';
import {DateTime} from "luxon";
import {TextFilter} from "../../../shared/domains/query/filter";
import {InvoicesFetchAction} from "../../actions/documentFlow.actions";
import { DefaultZone } from 'src/app/shared/components/zones/genericZones.component';

export function* fetchInvoices(action): any {
  try {
    yield put(new InvoicesFetchLoad());
    const result: ListWithStatus<Invoice> = yield call(InvoiceService.fetch, action.query, action.list);
    yield put(new InvoicesFetchSuccessAction(result.data, result.count));
  } catch (error) {
    yield put(new InvoicesFetchFailureAction(error));
  }
}

export function* fetchInvoiceParkingReasonCodes(action: InvoiceFetchParkingCodeAction): any {
  try {
    const result = yield call(InvoiceService.fecthParkingReasonCode,action.zone);
    yield put(new InvoicesFetchParkingCodeSuccessAction(result));
  } catch (error) {
    yield put(new InvoicesFetchParkingCodeFailureAction(error));
  }
}

export function* fetchInvoicesStatus(action): any {
  try {
    const result : ListWithStatus<Invoice>=yield call(InvoiceService.fetchStatus, action.query, action.list);
    yield put(new InvoiceStatusFetchSuccessAction(result.data, result.count, result.status));
  } catch (error) {
    yield put(new InvoicetatusFetchFailureAction(error));
  }
}

function* initialFetchInvoices(action: InvoicesFetchAction) {
  const user: User = yield select(({shared}: RootStateStore) => shared.user.currentUser);
  const term: string = yield select(({invoices}: RootStateStore) => invoices.documentFlows.query.term);
  const defaultYear: string = DateTime.local().toFormat('yyyy');
  const defaultYearValue: TextFilter = {
    id: defaultYear,
    title: defaultYear
  }
  const query = newInvoicesQuery(defaultCompany(user), defaultYearValue, user.filters.invoices, term);
  const zone = action.zone || user?.defaultSystemAlias?.[0] || DefaultZone.value;
  yield put({type: QueryActionsI.onNewQuery, query: query, list: zone});
}

function* fetchInvoiceItems(action: InvoiceItemsFetchLoad) {
  try {
    const results = yield call(PRPOItemService.fetchInvoiceItemSet, action.i);
    yield put(new InvoiceItemsFetchSuccessAction(results.data, action.i));
  } catch (error) {
    yield put(new InvoiceItemsFetchFailureAction(error, action.i));
  }
}

function* watchInvoiceFetch() {
  yield takeLatest(InvoiceTypes.FETCH_INVOICES, initialFetchInvoices);
}

function* watchInvoiceItemsFetch() {
  yield takeEvery(InvoiceTypes.FETCH_INVOICE_ITEMS_REQUEST, fetchInvoiceItems);
}

function* watchNewQuery() {
  yield takeLatest(QueryActionsI.onNewQuery, fetchInvoices);
}

function* watchNewQueryStatus() {
  yield takeLatest(QueryActionsI.onNewQuery, fetchInvoicesStatus);
}

function* watchInvoiceStatusRequest() {
  yield takeLatest(InvoiceTypes.FETCH_INVOICE_STATUS, fetchInvoicesStatus);
}

function* watchInvoiceParkingReasonCode() {
  yield takeLatest(InvoiceTypes.FETCH_INVOICE_PARKING_REASON_CODE, fetchInvoiceParkingReasonCodes);
}

export default function* invoicesSaga() {
  yield all([
    fork(watchInvoiceFetch),
    fork(watchInvoiceItemsFetch),
    fork(watchNewQuery),
    fork(watchNewQueryStatus),
    fork(watchInvoiceStatusRequest),
    fork(watchInvoiceParkingReasonCode)
  ])
};
