import {useMutation, useQuery, useQueryClient} from "react-query";
import {EasyRequestService} from "./easyRequest.service";

const counterKey = "fetchCounter";
export const useFetchCounter = () => {
  return useQuery(counterKey, () => EasyRequestService.getCounter(),
      {
          refetchOnWindowFocus: false,
          refetchOnMount: false
      })
}

export const useAddMaterial = () => {
  const queryClient = useQueryClient()
  return useMutation( (numberId: number) => EasyRequestService.addMaterial(numberId), {
      onSuccess: () => {
        queryClient.invalidateQueries(counterKey)
      }
    })
}
