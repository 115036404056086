import {Features} from "../core/pataflag";
import {Headers, MultiOriginValue} from "../../utils/axios.wrapper";
import {msalAuthProvider, scopes} from "./msal.provider";
import {PataflagService} from "../../services/pataflag.service";
import {isLocalEnvironment} from "../../utils/global";

export async function withAuthHeader(headers?: {[key: string]: any}): Promise<{ Authorization: string }> {
  const token = await AuthenticationService.GetToken();
  return {...headers, Authorization: `Bearer ${token}` };
}

export function withMultiOrigin(headers: {[key: string]: any} = {}, debug = false): {[key: string]: any} {
  return _addHeader(headers, Headers.MULTIORIGINS, MultiOriginValue);
}

export function withSystemAlias(systemAlias: string, headers: {[key: string]: any} = {}, debug = false): {[key: string]: any} {
  return _addHeader(headers, Headers.SYSTEM_ALIAS, systemAlias);
}

function _addHeader(headers: {[key: string]: any}, key: Headers, value: any): {[key: string]: any} {
  return (value && value.trim() !== "") ?
    {
      ...headers,
      [key]: value
    } : headers;
}

export class AuthenticationService {
  static async GetToken(): Promise<any> {
    if (isLocalEnvironment()) {
      return "TEST_TOKEN";
    }
    const request = { scopes: scopes };
    try {
      const token = await msalAuthProvider.acquireTokenSilent(request);
      return token.accessToken;
    } catch (e) {
      msalAuthProvider.acquireTokenRedirect(request);
    }
  }

  static Signout() {
    msalAuthProvider.logout();
  }
}
