import {
  all,
  call,
  fork,
  put,
  select,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
import { businessApprovalsStateStore } from "../reducers";
import { PurchaseRequestService } from "../domains/purchaseRequest/purchaseRequest.service";
import * as Sentry from "@sentry/browser";
import {
  FetchPRDetailSuccess,
  FetchPRDetailError,
  FetchPRs,
  FetchPRsError,
  FetchPRsSuccess,
  PurchaseRequestActionTypes,
  FetchPRLinesSuccess,
  FetchPRLinesError,
  ApprovedPRSuccess,
  ApprovedPRError,
  PRApprovalSuccess,
  PRApprovalError,
  PRRejectionSuccess
} from "../actions/purchaseRequest.action";
import { BAActionTypes, ResetBAFiltersSuccess } from "../actions/baFilters";
import {
  newPage,
  updateBAFilters,
  newResource,
  updateTerm,
  newFilters,
  purchaseRequestQuery,
  updateOrder
} from "../domains/purchaseRequest/query";



export function* fetch(action: any): any {
  try {
    let newQuery = action.query;
    if (!action.query) {
      let query = purchaseRequestQuery();
      newQuery = newResource(query, action.resource);
      if (!action.filters.companies.length) {
        action.filters.companies.push({
          id: action.companyCode,
          title: action.companyCodeName
        });
      }
      newQuery = updateBAFilters(newQuery, action.filters, [
        { field: "AgentID", value: action.positionId },
        { field: "DisplayType", value: "H" }
      ]);
    }
    const result = yield call(PurchaseRequestService.fetch, newQuery, action.alias);
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
  }
}

export function* fetchDetail(action: any): any {
  const query = {
    AribaPR: action.AribaPR,
    SapPR: action.SapPR,
    ItemNo: action.ItemNo,
    CompCode: action.CompCode,
    Cos: action.Cos,
    Counter: action.Counter,
    PurchOrg: action.PurchOrg,
    RelGroup: action.RelGroup,
    RelStrategy: action.RelStrategy,
    ApprovalLevel: action.ApprovalLevel,
    SAP__Origin: action.SAP__Origin
  };
  try {
    const { result, result1, result2, result3 } = yield all({
      result: call(PurchaseRequestService.fetchDetail, query),
      result1: call(PurchaseRequestService.fetchAccount, query),
      result2: call(PurchaseRequestService.fetchPricing, query),
      result3: call(PurchaseRequestService.fetchApprovalIndicator, query),
    })

    // const result = yield call(PurchaseRequestService.fetchDetail, query);
    // const result1 = yield call(PurchaseRequestService.fetchAccount, query);
    // const result2 = yield call(PurchaseRequestService.fetchPricing, query);
    // const result3 = yield call(
    //   PurchaseRequestService.fetchApprovalIndicator,
    //   query
    // );
    yield put(
      new FetchPRDetailSuccess({
        info: result,
        accountList: result1,
        pricing: result2,
        approvalIndecator: result3
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRDetailError({ error: error, message: error.message }));
  }
}

export function* fetchLines(action: any): any {
  let query1 = [
    { field: "SapPR", value: action.SapPR },
    { field: "CompCode", value: action.CompCode },
    { field: "AribaPR", value: action.AribaPR },
    { field: "AgentID", value: action.PositionId },
  ];
  if (action?.history) {
    query1 = [...query1, { field: "History", value: "X" }]
  }
  const query = {
    query: query1,
    SAP__Origin: action.SAP__Origin
  };

  //const query = {SapPR:action.SapPR, ItemNo:action.ItemNo, CompCode:action.CompCode, AgentID: action.PositionId};
  try {
    const result = yield call(PurchaseRequestService.fetchLines, query);
    const items = yield result.data.filter(item => item.SAP__Origin = action.SAP__Origin)
    yield put(
      new FetchPRLinesSuccess({ items: items, SapPR: action.SapPR })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(
      new FetchPRLinesError({
        error: error,
        message: error.message,
        SapPR: action.SapPR
      })
    );
  }
}

export function* approveRequest(action: any): any {
  const query = { SapPR: action.SapPR, ItemNo: action.ItemNo };
  try {
    const result = yield call(PurchaseRequestService.fetchLines, query);
    yield put(
      new ApprovedPRSuccess({ items: result.data, SapPR: action.SapPR })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new ApprovedPRError({ error: error, message: error.message }));
  }
}

function* newBAFilter(action: any) {
  try {
    let query = purchaseRequestQuery();
    let newQuery = newResource(query, "PRApprovals_BAs");
    let oldQuery = [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ];
    if (action?.filters?.historic) {
      oldQuery = [...oldQuery, { field: "History", value: "X" }]
    }
    newQuery = updateBAFilters(newQuery, action.filters, oldQuery);
    const result = yield call(PurchaseRequestService.fetch, newQuery, action.alias);
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
    yield put({ type: BAActionTypes.UPDATE_BA_FILTER_SUCCESS });
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    yield put({ type: BAActionTypes.UPDATE_BA_FILTER_FAILURE });
  }
}

function* deleteBAFilter(action: any) {
  try {
    let query = purchaseRequestQuery();
    let newQuery = newResource(query, "PRApprovals_BAs");
    newQuery = updateBAFilters(newQuery, action.filters, [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ]);
    const result = yield call(PurchaseRequestService.fetch, newQuery, action.alias);
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
    yield put({ type: BAActionTypes.UPDATE_BA_FILTER_SUCCESS });
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    yield put({ type: BAActionTypes.UPDATE_BA_FILTER_FAILURE });
  }
}

function* resetBAFilter(action: any) {
  try {
    let query = purchaseRequestQuery();
    let newQuery = newResource(query, "PRApprovals_BAs");
    let filters = {
      companies: [
        { id: action.prefferedCode, title: "Service Organization, GBP" },
      ],
      purchaseReqNo: [],
      aribaPr: [],
      prCreatorName: [],
      supplier: [],
      purchGroup: [],
      costCenter: [],
      wbsElement: [],
      glAccount: []
    };
    let post = updateBAFilters(newQuery, filters, [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ]);
    const result = yield call(PurchaseRequestService.fetch, post, action.alias);
    yield put(new ResetBAFiltersSuccess(filters));
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: post
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    yield put({ type: BAActionTypes.UPDATE_BA_FILTER_FAILURE });
  }
}

function* updateBAFilterSuccess(action: any) { }

function* updateBAFilterFailure(action: any) { }

function* baApproved(action: any) {
  const { callback, purchaseRequest } = action;
  try {
    const result = yield PurchaseRequestService.approvalRequest(purchaseRequest)
    if (purchaseRequest.ApprovalIndicator == 'A') {
      yield put(new PRApprovalSuccess(purchaseRequest))
    } else {
      yield put(new PRRejectionSuccess(purchaseRequest))
    }
    yield callback('success');
  } catch (error) {
    callback('failure');
    yield put(new PRApprovalError(error))
  }
}

function* updatePage(action) {
  const query = yield select(
    ({
      businessApprovals
    }: {
      businessApprovals: businessApprovalsStateStore;
    }) => businessApprovals.PurchaseRequest.query
  );
  const newQuery = newPage(query, action.page);
  yield put({ type: PurchaseRequestActionTypes.NEW_PR_QUERY, query: newQuery, alias: action.alias });
}

function* newTerm(action) {
  const query = yield select(
    ({
      businessApprovals
    }: {
      businessApprovals: businessApprovalsStateStore;
    }) => businessApprovals.PurchaseRequest.query
  );
  const newQuery = updateTerm({ ...query, page: 0 }, action.term);
  yield put({ type: PurchaseRequestActionTypes.NEW_PR_QUERY, query: newQuery, alias: action.alias });
}

function* newOrder(action) {
  const query = yield select(
    ({
      businessApprovals
    }: {
      businessApprovals: businessApprovalsStateStore;
    }) => businessApprovals.PurchaseRequest.query
  );
  const newQuery = updateOrder(query, action.field, action.direction);
  yield put({ type: PurchaseRequestActionTypes.NEW_PR_QUERY, query: newQuery, alias: action.alias });
}

function* fetchBAWithDefaultAliys(action) {
  let newQuery = action.query;
  if (!action.query) {
    let query = purchaseRequestQuery();
    newQuery = newResource(query, action.resource);
    // console.log('qResource', qResource)
    // newQuery = newPage(qResource, 0);

    if (!action.filters.companies.length) {
      action.filters.companies.push({
        id: action.companyCode,
        title: action.companyCodeName
      });
    }
    newQuery = updateBAFilters(newQuery, action.filters, [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ]);
  }

  try {
    // const aliys = yield call(PurchaseRequestService.getDefaultAliys, action.companyCode)
    const result = yield call(PurchaseRequestService.fetch, newQuery, action.alias);
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    // try {
    //   const aliys = 'R3AE1';
    //   const result = yield call(PurchaseRequestService.fetch, newQuery, aliys);
    //   yield put(
    //     new FetchPRsSuccess({
    //       items: result.data,
    //       totalCount: result.count,
    //       newQuery: newQuery
    //     })
    //   );
    //   yield put({ type: PurchaseRequestActionTypes.BA_DEFAULT_ALIYS_SUCCESS, aliys });
    // } catch (error) {
    //   Sentry.captureException(error);
    //   yield put(new FetchPRsError({ error: error, message: error.message }));
    // }
  }
}

function* BADefaultAliys(action) {
  yield put({ type: PurchaseRequestActionTypes.BA_DEFAULT_ALIYS_SUCCESS, aliys: action.aliys });
}

function* watchBAFetchQuery() {
  yield takeLatest(PurchaseRequestActionTypes.FETCH_PRS, fetch);
}

function* watchNewActiveQuery() {
  yield takeLatest(PurchaseRequestActionTypes.NEW_PR_QUERY, fetch);
}

function* watchDetailActiveQuery() {
  yield takeLatest(PurchaseRequestActionTypes.FETCH_PRS_DETAIL, fetchDetail);
}

function* watchLinesQuery() {
  //yield takeLatest(PurchaseRequestActionTypes.FETCH_PRS_LINES, fetchLines)
  yield takeEvery(PurchaseRequestActionTypes.FETCH_PRS_LINES, fetchLines);
}

function* watchPRApprovalQuery() {
  yield takeLatest(PurchaseRequestActionTypes.APPROVED_PR, approveRequest);
}

function* watchNewPage() {
  yield takeLatest(PurchaseRequestActionTypes.NEW_PAGE_BA, updatePage);
}

function* watchNewTerm() {
  yield takeLatest(PurchaseRequestActionTypes.NEW_TERM_BA, newTerm);
}

function* watchNewOrder() {
  yield takeLatest(PurchaseRequestActionTypes.NEW_ORDER_BA, newOrder);
}

function* watchNewFilter() {
  yield takeLatest(BAActionTypes.BA_NEW_FILTER, newBAFilter);
}

function* watchDeleteFilter() {
  yield takeLatest(BAActionTypes.DELETE_BA_FILTER, deleteBAFilter);
}

function* watchResetBAFilter() {
  yield takeLatest(BAActionTypes.RESET_BA_FILTERS, resetBAFilter);
}

function* watchUpdateFilterSuccess() {
  yield takeLatest(
    BAActionTypes.UPDATE_BA_FILTER_SUCCESS,
    updateBAFilterSuccess
  );
}

function* watchUpdateFilterFailure() {
  yield takeLatest(
    BAActionTypes.UPDATE_BA_FILTER_FAILURE,
    updateBAFilterFailure
  );
}


function* watchBAApprovelActionViaEmail() {
  yield takeLatest(
    PurchaseRequestActionTypes.APPROVED_OR_REJECT_BA_PR_VIA_EMAIL,
    baApproved
  );
}

function* watchBAApprovelAction() {
  yield takeLatest(
    PurchaseRequestActionTypes.APPROVED_OR_REJECT_BA_PR,
    baApproved
  );
}

function* watchFetchBAWithDefaultAliys() {
  yield takeLatest(PurchaseRequestActionTypes.FETCH_BA_WITH_DEFAULT_ALIYS, fetchBAWithDefaultAliys);
}

function* watchBADefaultAliys() {
  yield takeLatest(PurchaseRequestActionTypes.BA_DEFAULT_ALIYS, BADefaultAliys);
}

export default function* businessApprovalsSage() {
  yield all([
    fork(watchBAFetchQuery),
    fork(watchNewActiveQuery),
    fork(watchNewPage),
    fork(watchDetailActiveQuery),
    fork(watchLinesQuery),
    fork(watchPRApprovalQuery),
    fork(watchNewTerm),
    fork(watchNewOrder),
    fork(watchNewFilter),
    fork(watchDeleteFilter),
    fork(watchResetBAFilter),
    fork(watchUpdateFilterSuccess),
    fork(watchUpdateFilterFailure),
    fork(watchBAApprovelActionViaEmail),
    fork(watchBAApprovelAction),
    fork(watchFetchBAWithDefaultAliys),
    fork(watchBADefaultAliys),
  ]);
}
