import React from "react";
import { PslVendorHoverInfo } from "src/app/shared/components/modal/pslVendorHoverInfo";
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from "@material-ui/core";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";

export const InfoPopUpHoverIcon = ({ vendorId, marginLeft, marginBottom }) => {

    const toolTipclass = useStylesForToolTip({});

    return <Tooltip
        interactive
        classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
        title={
            <PslVendorHoverInfo  vendorId={vendorId} />
        }
        placement="right-end"
    >
        <InfoIcon
            style={{ cursor: "pointer", marginLeft: marginLeft, marginBottom: marginBottom }}
            fontSize="small"
        />
    </Tooltip>
}