import { ChatReducer, IChatState } from "../../shared/chat/chat.reducer";
import { chatActionName, ChatActions as _ChatActions } from "../../shared/chat/chat.actions";
import { IAction } from "../../shared/domains/core/actions";
import * as R from "ramda";

export interface IMultiChatState {
    [key: string]: IChatState
}

const newChat = (): IChatState => {
    return {
        messages: [],
        chatGroups: [],
        room: null,
        loading: false,
        sending: false,
        error: null
    }
};

export function makeChatId({ poNo = null, prNo = null }: IAction<_ChatActions> | { poNo: string, prNo: string }): string {
    return `chat:${poNo ? poNo : ""}-${prNo ? prNo : ""}`
}

export function chatReducer(state: IMultiChatState = {}, action: IAction<_ChatActions>): IMultiChatState {
    const actionName = chatActionName("DOCFLOWS");

    switch (action.type) {
        case actionName(_ChatActions.START_ROOM):
        case actionName(_ChatActions.LOAD_MESSAGES):
        case actionName(_ChatActions.LOAD_GROUPS):
            return R.mergeRight(state, { [makeChatId(action)]: newChat() });
        case actionName(_ChatActions.SEND_MESSAGE):
        case actionName(_ChatActions.SEND_NEW_MESSAGE):
            return R.mergeRight(state, {
                [makeChatId(action)]: R.mergeRight(state[makeChatId(action)], {
                    sending: true
                })
            });
        case actionName(_ChatActions.SEND_MESSAGE_SUCCESS):
            const identifier = makeChatId({ poNo: action.result.poId, prNo: action.result.prId })
            return R.mergeRight(state, {
                [identifier]: R.mergeRight(state[identifier], {
                    messages: [...(state[identifier].messages), action.result],
                    sending: false
                })
            });

        case actionName(_ChatActions.DELETE_ATTACHMENTS):
            let poNo = action.result.poId === null ? '' : action.result.poId;
            let prNo = action.result.prId === null ? '' : action.result.prId;
            const identifier2 = makeChatId({ poNo: poNo, prNo: prNo });
            let messagesCopy = [...state[identifier2].messages];
            const id = action.result.id;
            const changeableObjectIndex = messagesCopy.findIndex(el => el.id === id)
            messagesCopy[changeableObjectIndex] = action.result;

            if (!messagesCopy[changeableObjectIndex].attachments && !messagesCopy[changeableObjectIndex].message) {
                messagesCopy.splice(changeableObjectIndex, 1);
            }
            return R.mergeRight(state, {
                [identifier2]: R.mergeRight(state[identifier2], {
                    messages: messagesCopy,
                    sending: false
                })
            });

        case actionName(_ChatActions.START_ROOM_SUCCESS):
            return R.mergeRight(state, {
                [action.room.roomName]: R.mergeRight(state[action.room.roomName], { room: action.room, loading: false })
            });
        case actionName(_ChatActions.LOAD_MESSAGES_SUCCESS):
            const messages: { id: number }[] = action.messages
            const sortedMessages = messages.sort((a, b) => a.id > b.id ? 1 : -1)
            return R.mergeRight(state, {
                [makeChatId(action)]: R.mergeRight(state[makeChatId(action)], {
                    messages: sortedMessages,
                    loading: false
                })
            });
        case actionName(_ChatActions.LOAD_GROUPS_SUCCESS):
            const chatGroups: { id: number }[] = action.chatGroups
            return R.mergeRight(state, {
                [makeChatId(action)]: R.mergeRight(state[makeChatId(action)], {
                    chatGroups: chatGroups,
                    loading: false
                })
            });
        case actionName(_ChatActions.NEW_MESSAGES):
            return R.mergeRight(state, {
                [makeChatId(action)]: R.mergeRight(state[makeChatId(action)], {
                    messages: [...state[makeChatId(action)].messages, action.message],
                    loading: false
                })
            });
        case actionName(_ChatActions.START_ROOM_FAILURE):
        case actionName(_ChatActions.LOAD_MESSAGES_FAILURE):
        case actionName(_ChatActions.SEND_MESSAGE_FAILURE):
        case actionName(_ChatActions.LOAD_GROUPS_FAILURE):
            return R.mergeRight(state, {
                [makeChatId(action)]: R.mergeRight(state[makeChatId(action)], { loading: false, error: action.error })
            });
        default:
            return state;
    }
}

export const docflowStateSelector = ['docFlows', 'chat'];
export const docflowDomainName = 'DOCFLOWS';
export const { sagas: docflowCoreChatSaga, actions: ChatActions } = ChatReducer<IChatState>(docflowDomainName, docflowStateSelector);

