interface NetworkCompanyDTO {
    __metadata: { id: string; type: string; uri: string };
    CompCode: string
    MaterialDesc: string
    MaterialGrp: string
    MaterialGrpDesc: string
    MaterialNo: string
    PlantCode: string
    PurchaseOrg: string
}

export class Material {
    constructor(
        public CompCode: string,
        public MaterialDesc: string,
        public MaterialGrp: string,
        public MaterialGrpDesc: string,
        public MaterialNo: string,
        public PlantCode: string,
        public PurchaseOrg: string,
    ) {
    }

    static FromBackend(data: NetworkCompanyDTO): Material {
        return new Material(
            data.CompCode,
            data.MaterialDesc,
            data.MaterialGrp,
            data.MaterialGrpDesc,
            data.MaterialNo,
            data.PlantCode,
            data.PurchaseOrg,
        )
    }

    static toSelect(data: Material) {
        return {
            value: data.MaterialNo,
            label: data.MaterialDesc
        }
    }
}
