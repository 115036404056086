import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'
import {CompetitiveBiddingTypes} from "../actions/competitiveBiddings.actions";
import {CompetitiveBiddingService} from "../domains/cb.service";
import {FetchCbListError, FetchCBListRequest, FetchCbListSuccess} from "../actions/cbList.actions";
import {QueryCBListActions} from '../reducers/cbList.reducer';
import {User} from "../../shared/domains/user/user";
import {RootStateStore} from "../../application.reducers";
import {newCbQuery} from "../domains/cbQuery";
import {QueryableListEnum} from "../domains/competitiveBidding";

function* fetch(action): any {
  try {
    yield put(new FetchCBListRequest());

    const result = (action.list === QueryableListEnum.ActiveCompetitiveBiddingList) ?
      yield call(CompetitiveBiddingService.activeList, action.query, action.options)
      :
      yield call(CompetitiveBiddingService.list, action.query);

    yield put(new FetchCbListSuccess(result));
  } catch (error) {
    yield put(new FetchCbListError(error))
  }
}

function* initialFetch(action) {
  const user: User = yield select(({shared}: RootStateStore) => shared.user.currentUser);
  const term: string = yield select(({cb}: RootStateStore) => cb.list.query.term);
  var initialValues;
  if (action.df) {
    const companyCode = (action.df.PRCompanyCode && action.df.PRCompanyCode !== "") ? action.df.PRCompanyCode : action.df.POCompanyCode;
    initialValues = {
      ...user.filters.competitiveBidding,
      companies: companyCode ? [{id: companyCode}] : [],
      suppliers: [
        {id: action.df.VendorNo}
      ]
    }
  } else {
    initialValues = user.filters.competitiveBidding;
  }
  const query = newCbQuery(initialValues, term);
  yield put({type: QueryCBListActions.onNewQuery, query: query, list: action.list});
}

function* watchFetchCbList() {
  yield takeLatest(CompetitiveBiddingTypes.FETCH_CB_LIST, initialFetch);
}

function* watchNewQuery() {
  yield takeLatest(QueryCBListActions.onNewQuery, fetch);
}

export default function* cbListSaga() {
  yield all([
    fork(watchFetchCbList),
    fork(watchNewQuery)
  ])
};
