interface NetworkProcSpecsDTO {
  __metadata: {id: string, uri: string, type: string };
  IdNo: string;
  UserId: string;
  Name: string;
  CompCode: string;
  PurchOrganization: string;
}

export class ProcSpecs {
  constructor(
    public readonly UserId: string,
    public readonly Name: string
  ) {
  }

  static fromBackend(data: NetworkProcSpecsDTO): ProcSpecs {
    return new ProcSpecs(
      data.UserId,
      data.Name
    );
  }
}
