import styled from "styled-components";

export const BannerWrapper = styled.span`
  display: flex;
  font-weight: 600;
  padding: 17px;
  align-items: center;
  color: white;
  background-color: #F19001;
  @media only screen and (max-width: 768px) {
    padding: 10px;
}
`;

export const BannerTitle = styled.span`
  font-size: 22px;
  color: white;
  margin-left: 8px;
  @media only screen and (max-width: 768px) {
    font-size: 18px
}
`;
export const NbsBannerTitle = styled.span`
  font-size: 20px;
  color: #5D5858;
  margin-left: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 18px
}
`;
export const NewBannerWrapper = styled(BannerWrapper)`
justify-content: space-between; 
`;

export const NewBannerWrapperForMultiEditPr = styled(NewBannerWrapper)`
background-color: white;
padding-left: 30px
`;
export const NewBannerWrapperForNbs = styled(NewBannerWrapperForMultiEditPr)`
padding-left: 0
`;

export const BannerSubtitle = styled.span`
  font-size: 14px;
  color: #707070;
  padding: 5px 0;
`;


