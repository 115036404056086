import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { RootStateStore } from "../../../application.reducers";

export const amountFormatter = (price, type, currency = '') => {
    let finalAmount = "00.00";
    if (price) {
        let newPrice = price?.toString().replace(",",".")
        let floatPrice = newPrice?.toString().replace(/[\s]/g, '');
        finalAmount = floatPrice
            ? Number(parseFloat(floatPrice)?.toFixed(2))?.toLocaleString('en', {
                minimumFractionDigits: 2
            })
            : '';
    }
    let decimalPortion = finalAmount?.split('.', 2)
    switch (type) {
        case 'point_thousand_separator':
            decimalPortion[0] = decimalPortion[0]?.replace(/,/g, '.')
            finalAmount = decimalPortion[0] + `,${decimalPortion[1]}`
            break;
        case 'space_thousand_separator':
            decimalPortion[0] = decimalPortion[0]?.replace(/,/g, ' ')
            finalAmount = decimalPortion[0] + `,${decimalPortion[1]}`
            break;
        case 'comma_thousand_separator':
        default:
            finalAmount = decimalPortion[0] + `.${decimalPortion[1]}`
            break;
    }

    if (currency) {
        finalAmount = finalAmount + " " + currency
    }
    return finalAmount
}

export function Formatter({ amount, currency = '', color = null }) {
    const decimalNotation = useSelector((state: RootStateStore) => state.shared.user.currentUser.profileSettings.decimalNotation)
    return (
        <div style={{ color: color ? "red" : "inherit", display: "flex" }}>{amountFormatter(amount, decimalNotation, currency)}</div>
    )
}
