interface PoNumberDTO {
  CollectiveNumber: string
  CompCode: string
  Currency: string
  DataChanged: string
  Dci: string
  FinalInv: string
  GRInd: string
  IRInd: string
  IdNo: string
  MaterialNo: string
  NetPrice: string
  OrderPriceUnit: string
  Plant: string
  PriceUnit: string
  PurchaseOrdItemNo: string
  PurchaseOrdNo: string
  Quantity: string
  Requisitioner: string
  SAP__Origin: string
  ShrtTxt: string
  TotalPrice: string
  UnitOfMeasure: string
  __metadata: { id: string; type: string; uri: string };
}

export class PoNumber {
  constructor(
      public CollectiveNumber: string,
      public CompCode: string,
      public Currency: string,
      public DataChanged: string,
      public Dci: string,
      public FinalInv: string,
      public GRInd: string,
      public IRInd: string,
      public IdNo: string,
      public MaterialNo: string,
      public NetPrice: string,
      public OrderPriceUnit: string,
      public Plant: string,
      public PriceUnit: string,
      public PurchaseOrdItemNo: string,
      public PurchaseOrdNo: string,
      public Quantity: string,
      public Requisitioner: string,
      public SAP__Origin: string,
      public ShrtTxt: string,
      public TotalPrice: string,
      public UnitOfMeasure: string,
  ) {
  }

  static FromBackend(data: PoNumberDTO): PoNumber {
    return new PoNumber(
        data.CollectiveNumber,
        data.CompCode,
        data.Currency,
        data.DataChanged,
        data.Dci,
        data.FinalInv,
        data.GRInd,
        data.IRInd,
        data.IdNo,
        data.MaterialNo,
        data.NetPrice,
        data.OrderPriceUnit,
        data.Plant,
        data.PriceUnit,
        data.PurchaseOrdItemNo,
        data.PurchaseOrdNo,
        data.Quantity,
        data.Requisitioner,
        data.SAP__Origin,
        data.ShrtTxt,
        data.TotalPrice,
        data.UnitOfMeasure,
    )
  }

  static toSelect(data: PoNumber) {
    return {
      value: data.PurchaseOrdNo,
      label: `${data.PurchaseOrdNo}`
    }
  }
}
