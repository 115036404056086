import {assoc, mergeRight} from "ramda";
import {changeStatus, DeletedBlockedItem, Item} from "../../domains/item/item";
import {ItemActionTypes} from "../../actions/item.actions";
import {FavoritedContract} from "../../domains/contract/contract";
import {IAction} from "../../../shared/domains/core/actions";
import {ItemPriceActionTypes} from "../../actions/itemPricing.actions";
import {ContractActionTypes} from "../../actions/contract.actions";

export interface ItemState {
  items: Item[];
  totalCount: number;
  itemCounters: DeletedBlockedItem;
  favoritedSuppliers: FavoritedContract[];
  loading: boolean;
  error: any;
}

export const initialItemState: ItemState = {
  items: [],
  totalCount: 0,
  itemCounters: new DeletedBlockedItem(),
  favoritedSuppliers: [],
  loading: false,
  error: null
};

const loading = {loading: true, error: false};

export function ItemsReducer(state: ItemState = initialItemState, action: IAction<ContractActionTypes|ItemActionTypes|ItemPriceActionTypes>): ItemState {
  switch (action.type) {
    case ItemActionTypes.INIT:
      return assoc('itemCounters', new DeletedBlockedItem(), state);
    case ItemActionTypes.FETCH_ITEMS:
    case ItemActionTypes.FETCH_DELETED_BLOCKED_ITEMS:
    case ItemActionTypes.FETCH_DELETED_BLOCKED_DRAFT_ITEMS:
    case ItemActionTypes.UPDATE_ITEM:
    case ItemActionTypes.TOGGLE_ITEMSTATUS:
      return mergeRight(state, loading);
    case ItemActionTypes.FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items.map((item: Item) => !!action.changes[item.ItemNo] ? action.changes[item.ItemNo] : item),
        totalCount: action.totalCount
      };
    case ItemActionTypes.FETCH_ITEMS_FAILURE:
    case ItemActionTypes.UPDATE_ITEM_FAILURE:
    case ItemActionTypes.TOGGLE_ITEMSTATUS_FAILURE:
    case ItemActionTypes.FETCH_DELETED_BLOCKED_ITEMS_FAILURE:
      return mergeRight(state, {loading: false, error: action.error});
    case ItemActionTypes.FETCH_DELETED_BLOCKED_ITEMS_SUCCESS:
      return assoc('itemCounters', action.itemCounters, state);
    case ItemActionTypes.UPDATE_ITEM_SUCCESS:
      const updatedItems = state.items.map((item: Item) => {
        if (item.ItemNo === action.item.ItemNo) {
          return action.item;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        items: updatedItems
      };
    case ItemActionTypes.CLM_ITEM_CHANGE:
      return {...state, items: state.items.map((item: Item) => item.ItemNo === action.item.ItemNo ? action.item : item)};
    case ItemActionTypes.TOGGLE_ITEMSTATUS_SUCCESS:
    case ItemActionTypes.TOGGLE_CLM_ITEMSTATUS:
      const updateActionItems = state.items.map((item: Item) => {
        if (item.ItemNo === action.item.ItemNo) {
          return {
            ...action.item,
            Action: action.action
          };
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        items: updateActionItems,
        itemCounters: changeStatus(state.itemCounters, action.lastAction, action.action)
      };
    case ItemPriceActionTypes.UPDATE_ITEMSPRICE_SUCCESS:
      return assoc('itemCounters', mergeRight(state.itemCounters, {TargetValue: action.newTargetValue}), state);
    case ContractActionTypes.SUBMIT_CLM_SUCCESS:
      return assoc('items', action.items, state);
    default:
      return state;
  }
}
