import {select} from 'redux-saga/effects';
import {isEmpty, equals, reduceBy, differenceWith} from "ramda";
import {take, drop, slice} from "lodash";
import {ContractsStateStore} from "../../reducers";
import {ODataQuery, updatePage} from "../../domains/contract/query";
import {Contract} from "../../domains/contract/contract";

const simpleDiff = (a, b) => differenceWith((c1: Contract, c2: Contract) => c1.AgreementNo === c2.AgreementNo, a, b);

export function* selectPageableQueriesPerServers(page, newQueries) {
  const {totalCount, currentPage, contracts} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts);
  const {localPage, serverPage, queries, remoteContracts, countServers} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractByServers);
  let nextLocalPage = 0, nextServerPage = 0;
  if (page > 0) {
    nextLocalPage = page > currentPage ? localPage + 1 : localPage - 1;
    nextServerPage = page > currentPage ? serverPage + 1 : (serverPage > 0 ? serverPage - 1 : 0);
    if (nextLocalPage < 0) {
      nextServerPage = serverPage > 0 ? serverPage - 1 : 0;
    }
    let remainContracts = take(drop(remoteContracts, nextLocalPage*10), 10);
    if (page < currentPage) {
      if (remoteContracts.length >= 10) {
        const totalPages = Math.ceil(remoteContracts.length / 10);
        const start = remoteContracts.length - 10*(totalPages - nextLocalPage);
        remainContracts = slice(remoteContracts, start, start + 10);
      } else {
        remainContracts = remoteContracts;
      }
    }
    if (nextLocalPage*10 < remoteContracts.length && !equals(contracts, remainContracts) && remainContracts.length >= 10) {
      if (nextLocalPage >= 0) {
        nextServerPage = serverPage;
      } else if (serverPage > 0) {
        nextServerPage = serverPage - 1;
      }
    }
  }

  const isLastPage = totalCount >= page*10 && totalCount <= page*10 + 10;
  if (isLastPage) {
    nextLocalPage = 0;
    if (!isEmpty(countServers)) {
      const countByServers = reduceBy((acc, next: any) => acc + next.count, 0, (x: any) => x.systemAlias, countServers);
      const maxCount = Math.max(...Object.values(countByServers));
      nextServerPage = Math.ceil(maxCount / (10 * newQueries.length));
    } else {
      nextServerPage = Math.ceil(totalCount / (10 * newQueries.length));
    }
  }

  return {
    queries: serverPage !== nextServerPage ? newQueries.map(q => q.reduce((acc, value) => {
      const dataQuery = Object.values(value)[0] as ODataQuery;
      return {...acc, [Object.keys(value)[0]]: updatePage(dataQuery, nextServerPage)};
    }, {})) : queries,
    nextLocalPage: nextLocalPage,
    nextServerPage: nextServerPage
  };
}

export function* selectPageableContractsPerServers(contracts, page, nextLocalPage, nextServerPage, totalCount) {
  const visibleContracts = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contracts);
  const {localPage, serverPage, remoteContracts} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractByServers);
  const isLastPage = totalCount >= page*10 && totalCount <= page*10 + 10;
  let newLocalPage = nextLocalPage;

  if (isLastPage) {
    newLocalPage = Math.ceil(contracts.length / 10);
  }

  if (Math.abs(nextServerPage - serverPage) === 1) {
    const remainContracts = simpleDiff(remoteContracts, visibleContracts);
    if (nextServerPage > serverPage) {
      newLocalPage = 0;
      if (remainContracts.length > 0) {
        contracts = [...simpleDiff(remainContracts, contracts), ...contracts];
      }
    } else {
      if (remainContracts.length > 0) {
        contracts = [...contracts, ...simpleDiff(remainContracts, contracts)];
      }
    }
  }

  let nextRemoteContracts = contracts;
  let nextVisibleContracts = take(drop(contracts, newLocalPage*10), 10);
  if (isLastPage) {
    nextVisibleContracts = take(drop(contracts, contracts.length - totalCount%(page*10)), (newLocalPage+1)*10);
    nextRemoteContracts = slice(contracts, 0, contracts.length - totalCount%(page*10));
  } else if (newLocalPage < localPage && nextServerPage <= serverPage && Math.abs(nextServerPage - serverPage) <= 1) {
    const totalPages = Math.ceil(contracts.length / 10);
    if (newLocalPage < 0 || nextServerPage < serverPage) {
      newLocalPage = totalPages - 1;
    }
    const start = contracts.length - 10*(totalPages - newLocalPage);
    nextVisibleContracts = slice(contracts, start, start + 10);
  }

  return {
    nextLocalPage: newLocalPage,
    nextVisibleContracts: nextVisibleContracts,
    nextRemoteContracts: nextRemoteContracts
  }
}

