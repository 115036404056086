import React from 'react';
import { AvatarWithPopover } from "src/app/docFlows/components/itemDrilldownTable/AvatarWithPopover";
import { TextWithPopover } from '../components/nbsCockpitTextWithPover';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { DateTime } from 'luxon';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { formatValue } from 'src/app/docFlows/components/itemDrilldownTable/editRequestAllLines/helper';
import { BlockedParkedtFilterKeys, EasyRequestFilterKeys, NbsCockpitDataTypes, TCode, TrackerFilterKeys, defaultNbsBlockedParkedFiltersData, defaultNbsEasyRequestFiltersData, defaultNbsTrackerFiltersData, ftvvalidationOptions, defaultNbsMaintenanceFiltersData, NbsMaintenanceFilterKeys, SapScopeFilterKeys, defaultNbsSapScopeFiltersData } from './constants';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid/Grid";
import clsx from 'clsx';
import { setSelectedFiltersBykey } from 'src/app/shared/queryable/query';
import moment from 'moment';
import * as R from 'ramda';
import { template } from 'lodash';
import { rcUrl, sapUrl } from 'src/app/shared/utils/urlBuilders';
import CachedIcon from '@material-ui/icons/Cached';
import { DataWithPopover } from '../components/nbsCockpitDataWithPopover';
import { DocumentFlowService } from 'src/app/docFlows/domains/documentFlow/documentFlow.service';
import { amountFormatter } from 'src/app/shared/components/amount-formatter';
import { NbsMainteanceTemplateData, NbsSapScopeData } from '../interfaces/nbs';
import { getLabel, getRejectReasonLabel } from 'src/app/docFlows/components/complianceIndicator';
import { getType } from 'src/app/docFlows/components/dataTable/docFlow/docFlowTableRelated/CbLink';
import _ from 'lodash';


export const getNbsCockpitDataTypeStyles = makeStyles(() =>
    createStyles({
        wrapIcon: {
            display: 'flex',
            alignItems: 'center',
        },
        critical: {
            color: "#D00032 !important",
            fontWeight: 'bold',
        },
        button: {
            marginTop: 3,
            marginRight: 6,
            fontSize: '20px',
            color: 'rgba(0, 0, 0, 0.5)'
        },
        [NbsCockpitDataTypes.Change]: {
            color: 'gray !important',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        [NbsCockpitDataTypes.Creation]: {
            color: 'gray !important',
            fontWeight: 'bold',
            fontSize: '14px'
        },
        [NbsCockpitDataTypes.Compliant]: {
            color: '#64A709 !important',
            fontWeight: 'bold',
            fontSize: '14px'
        },
        [NbsCockpitDataTypes.NonCompliant]: {
            color: 'red !important',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        null: {
            color: '#949494 !important',
            fontWeight: 'bold',
            fontSize: '14px'
        },
        recordIcon: {
            fontSize: 'small',
            marginRight: '8px'
        },
    }),
);

export const getNbsCockpitColoredOptionsDataTypeStyles = makeStyles(() =>
    createStyles({
        wrapIcon: {
            display: 'flex',
            alignItems: 'center',
        },
        critical: {
            color: "#D00032 !important",
            fontWeight: 'bold',
        },
        button: {
            marginTop: 3,
            marginRight: 6,
            fontSize: '20px',
            color: 'rgba(0, 0, 0, 0.5)'
        },
        [NbsCockpitDataTypes.Change]: {
            color: 'gray !important',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        [NbsCockpitDataTypes.Creation]: {
            color: 'gray !important',
            fontWeight: 'bold',
            fontSize: '14px'
        },
        [NbsCockpitDataTypes.Compliant]: {
            color: '#64A709 !important',
            fontWeight: 'bold',
            fontSize: '13px'
        },
        [NbsCockpitDataTypes.NonCompliant]: {
            color: 'red !important',
            fontWeight: 'bold',
            fontSize: '13px',
        },
        null: {
            color: '#949494 !important',
            fontWeight: 'bold',
            fontSize: '14px'
        },
        recordIcon: {
            fontSize: 'small',
            marginRight: '8px'
        },
    }),
);

export const getDataType = (collectiveNumber) => {
    if (collectiveNumber == "P") return NbsCockpitDataTypes.Compliant;
    if (collectiveNumber == "X") return NbsCockpitDataTypes.NonCompliant;
    return null;
};

export function getDataName(type) {
    switch (type) {
        case NbsCockpitDataTypes.Change:
            return "Change"
        case NbsCockpitDataTypes.Creation:
            return "Creation"
        case NbsCockpitDataTypes.Compliant:
            return "Compliant"
        case NbsCockpitDataTypes.NonCompliant:
            return "Non Compliant"
        default:
            return "Unknown"
    }
}

export function getNbsTotalValState(nbsItems) {
    return nbsItems?.length > 0 ? nbsItems?.map(item => formatValue(item.PRtotprice))?.reduce((a, b) => a + b).toFixed(2) : 0;
}

export function getNbsCurrConvTotalVal(nbsItems) {
    return nbsItems?.length > 0 ? nbsItems?.map(item => formatValue(item.Currency_diff))?.reduce((a, b) => a + b).toFixed(2) : 0;
}

export const getHoverableData = (item: any, hoverItem: any) => {
    return (
        <DataWithPopover
            item={item}
            hoverItem={hoverItem}
        />
    )
}

export const fieldsToValidate = (item: any) => {
    let columnData = item;
    let arrayData = columnData.split(";").filter(item => item?.trim() != "");
    return (
        <TextWithPopover
            ftvData={arrayData}
        />
    )
}

export const StyledCategoryName = ({ item }) => {
    const styles = getNbsCockpitDataTypeStyles({});
    return (
        <>
            {
                item == 'Creation' &&
                <Grid container direction="row" alignItems="center">
                    <Typography className={clsx(styles[item], styles.wrapIcon)}>
                        <svg id="icon-sparkle2" xmlns="http://www.w3.org/2000/svg" width={"12px"} height={"12px"} viewBox="0 0 32 32">
                            <rect id="Rectangle_1810" data-name="Rectangle 1810" width={"12px"} height={"12px"} fill="gray" opacity="0" />
                            <g id="Layer_1-2" transform="translate(1.001 2.004)">
                                <path id="Path_38616" data-name="Path 38616" d="M14.942,29.947a.969.969,0,0,1-.927-.7c-1.322-2.85-2.651-5.7-4.007-8.53a1.7,1.7,0,0,0-.756-.749C6.473,18.668,3.678,17.4.886,16.12A1.256,1.256,0,0,1,0,15.075c-.049-.595.311-.9.8-1.129,2.573-1.2,5.128-2.443,7.725-3.584A3.4,3.4,0,0,0,10.4,8.481C11.536,5.919,12.761,3.4,13.942.856c.221-.479.47-.88,1.084-.859a1.212,1.212,0,0,1,1.1.863c1.26,2.76,2.541,5.512,3.793,8.276a1.694,1.694,0,0,0,.9.912c2.726,1.247,5.445,2.513,8.165,3.773.1.045.193.094.29.14a1.071,1.071,0,0,1,.011,2.02c-1.183.549-2.361,1.105-3.541,1.655-1.683.783-3.374,1.55-5.046,2.357a1.561,1.561,0,0,0-.685.678c-1.329,2.827-2.631,5.666-3.93,8.506a1.125,1.125,0,0,1-1.144.768Zm-.024-3.732.159,0c.124-.251.255-.5.373-.753.944-2.041,1.9-4.078,2.822-6.128a2.078,2.078,0,0,1,1.1-1.1c1.758-.79,3.509-1.6,5.261-2.4.556-.258,1.1-.534,1.751-.852-.309-.15-.5-.247-.689-.335-2.08-.949-4.153-1.908-6.242-2.835a2.244,2.244,0,0,1-1.217-1.2c-.955-2.133-1.938-4.256-2.915-6.381-.077-.167-.176-.326-.326-.605-.15.281-.245.44-.324.61-.985,2.14-1.977,4.278-2.94,6.428a2.109,2.109,0,0,1-1.146,1.125c-2.121.944-4.228,1.915-6.34,2.878-.182.084-.354.187-.61.322.258.133.41.219.569.292,2.14.989,4.276,1.983,6.42,2.958a2.071,2.071,0,0,1,1.1,1.1c.826,1.839,1.681,3.668,2.526,5.5.215.464.44.923.661,1.382Z" transform="translate(0 0)" />
                            </g>
                        </svg>&nbsp;&nbsp;&nbsp;
                        {getDataName(item)}
                    </Typography>
                </Grid>
            }
            {
                item == 'Change' &&
                <Grid container direction="row" alignItems="center">
                    <Typography className={clsx(styles[item], styles.wrapIcon)}>
                        <CachedIcon className={clsx(styles.recordIcon, styles[item])} />
                        {getDataName(item)}
                    </Typography>
                </Grid>
            }
        </>
    )
}

export const getRequstedDate = (dateStamp: string) => {
    return (
        <>
            {
                (!!(dateStamp || "").trim() && (dateStamp !== "00000000")) &&
                <div>
                    {
                        DateTime.fromFormat(dateStamp, "yyyyMMdd").toLocaleString(DateTime.DATE_MED)
                    }
                </div>
            }
        </>
    )
}

export const findIfItsInTheArray = (inputArray: string[], stringToFind: string) => {
    const isItThere: boolean = inputArray.includes(stringToFind);
    return isItThere;
}

export const GetAgingDays = ({ creationDateStamp }: { creationDateStamp: string }) => {
    const styles = getNbsCockpitDataTypeStyles({});

    const currentDate = DateTime.local().toFormat('yyyyMMdd');;

    let tempDate = moment(creationDateStamp, 'YYYYMMDD');
    let tempCurrentdate = moment(currentDate, 'YYYYMMDD');

    const agingDays = tempCurrentdate.diff(tempDate, 'days');

    return <div className={agingDays > 1 ? styles.critical : null}>{agingDays}</div>
}

export const StyledDataName = ({ item }) => {
    const styles = getNbsCockpitDataTypeStyles({});
    return (
        <Grid container direction="row" alignItems="center">
            <Typography className={clsx(styles[item], styles.wrapIcon)}>
                <FiberManualRecordIcon className={clsx(styles.recordIcon, styles[item])} />
                {getDataName(item)}
            </Typography>
        </Grid>
    )
}

export const accGrpHover = (accGrpHoverData: string) => {

    const accGrpHoverDataArray = accGrpHoverData?.split(";");

    return <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Supplier:</div>
            <div> {accGrpHoverDataArray[1]?.split(":")[1]}</div>
        </div>            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>House Number:</div>
            <div> {accGrpHoverDataArray[2]?.split(":")[1]?.split("/")[0]}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Street:</div>
            <div> {accGrpHoverDataArray[2]?.split(":")[1]?.split("/")[1]}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Postal Code:</div>
            <div> {accGrpHoverDataArray[3]?.split(":")[1]?.split("/")[0]}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>City:</div>
            <div> {accGrpHoverDataArray[3]?.split(":")[1]?.split("/")[1]}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>State:</div>
            <div> {accGrpHoverDataArray[4]?.split(":")[1]?.split("/")[0]}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Country:</div>
            <div> {accGrpHoverDataArray[4]?.split(":")[1]?.split("/")[1]}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Telephone:</div>
            <div> {accGrpHoverDataArray[5]?.split(":")[1]}</div>
        </div>
    </div>

};

export const cbHoverdata = (nbsData: any, decimalNotation: any, status: string) => {

    if (!nbsData) return "";

    const threshold_Amount: string = nbsData[0]?.Acc_Grp === "Z003" ? nbsData[0]?.Threshold_otv_p?.trim() : nbsData[0]?.Threshold_p?.trim();
    const threshold_Curr: string = nbsData[0]?.Acc_Grp === "Z003" ? nbsData[0]?.Threshold_otv_c?.trim() : nbsData[0]?.Threshold_c?.trim();

    const total_Amount: any = `${getNbsTotalValState(nbsData)} ${nbsData[0]?.PrCurrency}`;

    const curr_Converted_Total: any = nbsData[0]?.PrCurrency !== threshold_Curr ? getNbsCurrConvTotalVal(nbsData) : null;

    return <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Total Amount:</div>
            <div> {curr_Converted_Total ? `${amountFormatter(total_Amount, decimalNotation)} (${amountFormatter(curr_Converted_Total, decimalNotation, threshold_Curr)})` : `${amountFormatter(total_Amount, decimalNotation)}`}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Threshold:</div>
            <div> {`${amountFormatter(threshold_Amount, decimalNotation, threshold_Curr)}`}</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", alignItems: "flex-start" }}>
            <div>Supplier Status:</div>
            <div> {status}</div>
        </div>
    </div>
};

export const getFtvSelectedOption = (selectedValue: string) => {
    const tempValue = ftvvalidationOptions.filter(ftvOption => ftvOption.value === selectedValue);
    return tempValue ? tempValue[0] : null;
}

export const getResendPoIcon = (status: string) => {
    return (
        <>
            {
                status && (status === 'Yes' || status === 'X') &&
                <CheckCircleIcon style={{ color: "green", fontSize: "medium" }} />
            }
            {
                (status === 'No' || status === '') &&
                <CancelIcon style={{ color: "red", fontSize: "medium" }} />
            }
        </>
    )
}
export const createNbsFilters = (filters: any) => {
    let body = {
        agingDays: filters.AgingDays.selected,
        category: filters.Category.selected,
        changedOn: filters.ChangedOn.selected?.startDate ? filters.ChangedOn.selected : {},
        collectiveNumber: filters.CollectiveNumber.selected,
        companyCode: filters.CompanyCode.selected,
        ftValidation: filters.FTValidation.selected,
        prCreatorName: filters.PrCreatorName.selected,
        purchOrg: filters.PurchOrg.selected,
        purchaseOrdNo: filters.PurchaseOrdNo.selected,
        purchaseReqNo: filters.PurchaseReqNo.selected,
        requisitioner: filters.Requisitioner.selected,
        resend_po: []
    };
    return body
}
export const createNbsBlockedParkFilters = (filters: any) => {
    let body = {
        pOrg: filters.PurchOrg.selected,
        aging: filters.AgingDays.selected,
        dueDate: filters.InvDueDate.selected?.startDate ? filters.InvDueDate.selected : null,
        invoiceNumber: filters.InvDocNo.selected,
        compCode: filters.CompCode.selected,
        priceRangeFrom: filters.PriceRangeFrom.selected,
        priceRangeTo: filters.PriceRangeTo.selected,
        status: filters.NbsStatus_Value.selected,
        blockingReasonCode: filters.BlckRsn.selected,
        parkingReasonCode: filters.ParkRsnCode.selected,
        assignedTo: filters.WfAgent.selected,
        fiscalYear: filters.FiscalYr.selected,
        taskDate: filters.WfCd.selected?.startDate ? filters.WfCd.selected : null,
        instructionDate: filters.InstructionDate.selected?.startDate ? filters.InstructionDate.selected : null,
        purchasingGroup: filters.PurchGrp.selected,
        plant: filters.Plant.selected,
        requestor: filters.PrCreatorName.selected,
        spender: filters.Requisitioner.selected,
        supplierInvoiceNumber: filters.RefDoc.selected,
        toActorOn: filters.To_actor.selected || [],
        taskResponsible: filters.taskResponsible.selected || [],
        vendorNo: filters.InvPartner.selected || []
    };
    return body
}
export const createNbsTrackerFilters = (filters: any) => {
    let body = {
        invoiceNumber: filters.invoiceNumber.selected,
        companyCode: filters.companyCode.selected,
        poNumber: filters.poNumber.selected,
        requestorID: filters.requestorID.selected,
        vendorNumber: filters.vendorNumber.selected,
        supplierInvoiceNumber: filters.supplierInvoiceNumber.selected,
        postingDate: filters.postingDate.selected?.startDate ? filters.postingDate.selected : null,
        parkingBlockingReason: filters.parkingBlockingReason.selected,
        dateOfProcessing: filters.dateOfProcessing.selected?.startDate ? filters.dateOfProcessing.selected : null,
        purchashingGroup: filters.purchashingGroup.selected,
        taskResponsible: filters.taskResponsible.selected,
        taskTaggedTo: filters.taskTaggedTo.selected,
        fiscalYear: filters.fiscalYear.selected,
        status: filters.status.selected,
        aging: filters.aging.selected,
        toActorOn: filters.toActorOn.selected,
        instructionDate: filters.instructionDate.selected?.startDate ? filters.instructionDate.selected : null,
        dueDate: filters.dueDate.selected?.startDate ? filters.dueDate.selected : null,
    };
    return body
}

export const createNbsSapScopeFilters = (filters: any) => {
    let body = {
        prNo: filters.prNo.selected,
        poNo: filters.poNo.selected,
        priceRangeTo: filters.priceRangeTo.selected,
        priceRangeFrom: filters.priceRangeFrom.selected,
        thresholdRangeTo: filters.thresholdRangeTo.selected,
        thresholdRangeFrom: filters.thresholdRangeFrom.selected,
        thresholdStatus: filters.thresholdStatus.selected ?? null,
        complianceIndicator: filters.complianceIndicator.selected,
        creator: filters.creator.selected,
    };
    return body
}

export const trackerPayloadForBE = (filters: any) => {
    let body = {
        InvoiceNumber: filters.invoiceNumber.selected?.map(item => item.id),
        CompanyCode: filters.companyCode.selected?.map(item => item.id),
        PONumber: filters.poNumber.selected?.map(item => item.id),
        RequestorID: filters.requestorID.selected?.map(item => item.id),
        VendorNumber: filters.vendorNumber.selected?.map(item => item.id),
        SupplierInvoiceNumber: filters.supplierInvoiceNumber.selected?.map(item => item.id),
        ParkingBlockingDate: filters.postingDate.selected?.startDate ? filters.postingDate.selected : null,
        ParkingBlockingReason: filters.parkingBlockingReason.selected?.map(item => item.id),
        DateOfProcessing: filters.dateOfProcessing.selected?.startDate ? filters.dateOfProcessing.selected : null,
        PurchashingGroup: filters.purchashingGroup.selected?.map(item => item.id),
        TaskResponsible: filters.taskResponsible.selected?.map(item => item.id),
        TaskTaggedTo: filters.taskTaggedTo.selected?.map(item => item.id),
        FiscalYear: filters.fiscalYear.selected?.map(item => item.id),
        Status: filters.status.selected?.map(item => item.id),
        InstructionDate: filters.instructionDate.selected?.startDate ? filters.instructionDate.selected : null,
        DueDate: filters.dueDate.selected?.startDate ? filters.dueDate.selected : null,
        Aging: filters.aging.selected?.map(item => item.id),
        ToActor: filters.toActorOn.selected?.map(item => item.id),
    };
    return body
}

export const createNbsTemplateFilters = (filters: any) => {
    let body = {
        CompCode: filters.CompanyCode.selected,
        PurOrg: filters.PurchasingOrganization.selected,
        PurGroup: filters.PurchasingGroup.selected,
        Plant: filters.Plant.selected,
        Material: filters.MaterialType.selected,
        ParkingBlockingReason: filters.ParkingBlockingReason.selected,
        Receiver: filters.Receiver.selected
    };
    return body
}
 
export const templatePayloadForBE = (filters: any) => {
    let body = {
        CompanyCode: filters.CompanyCode.selected,
        PurchasingOrganization: filters.PurchasingOrganization.selected,
        PurchasingGroup: filters.PurchasingGroup.selected,
        Plant: filters.Plant.selected,
        MaterialType: filters.MaterialType.selected,
        ParkingBlockingReason: filters.ParkingBlockingReason.selected,
        Receiver: filters.Receiver.selected,
        Instructions: filters.Instructions.selected
    };
    return body
}

export const sapScopeParentPayloadForBE = (filters: any, hasReleaseStrategy: boolean) => {
    let body = {
        prNo: filters.prNo.selected,
        poNo: filters.poNo.selected,
        priceRangeTo: filters.priceRangeTo.selected,
        priceRangeFrom: filters.priceRangeFrom.selected,
        thresholdRangeTo: filters.thresholdRangeTo.selected,
        thresholdRangeFrom: filters.thresholdRangeFrom.selected,
        thresholdStatus: filters.thresholdStatus.selected,
        complianceIndicator: filters.complianceIndicator.selected,
        creator: filters.creator.selected,
        hasReleaseStrategy: hasReleaseStrategy,
    };
    return body
}

export const sapScopeChildPayloadForBE = (filters: any) => {
    let body = {
        CompanyCode: filters.CompanyCode.selected,
        PurchasingOrganization: filters.PurchasingOrganization.selected,
        PurchasingGroup: filters.PurchasingGroup.selected,
        Plant: filters.Plant.selected,
        MaterialType: filters.MaterialType.selected,
        ParkingBlockingReason: filters.ParkingBlockingReason.selected,
        Receiver: filters.Receiver.selected,
        Instructions: filters.Instructions.selected
    };
    return body
}

export function getInitialFiltersForEasyRequest(initialActiveFilters?: any) {
    if (initialActiveFilters) {
        let filtersData: any = defaultNbsEasyRequestFiltersData;
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.PURCHASING_ORGANIZATION, initialActiveFilters.purchOrg, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.COMPANY_CODE, initialActiveFilters.companyCode, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.REQUEST_DATE,
            {
                startDate: initialActiveFilters.changedOn.startDate ? initialActiveFilters.changedOn.startDate : null,
                endDate: initialActiveFilters.changedOn.endDate ? initialActiveFilters.changedOn.endDate : null
            }, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.AGING, initialActiveFilters.agingDays, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.CATEGORY, initialActiveFilters.category, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.FILEDS_TO_VALIDATE, initialActiveFilters.ftValidation, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.PR_NUMBER, initialActiveFilters.purchaseReqNo, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.PO_NUMBER, initialActiveFilters.purchaseOrdNo, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.REQUESTOR, initialActiveFilters.prCreatorName, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.SPENDER, initialActiveFilters.requisitioner, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.COMPLIANCE_INDICATOR, initialActiveFilters.collectiveNumber, filtersData);
        filtersData = setSelectedFiltersBykey(EasyRequestFilterKeys.RESEND_PO, initialActiveFilters.resend_po, filtersData);
        return filtersData;
    } else {
        return defaultNbsEasyRequestFiltersData
    }
}

export function getInitialFiltersForBlkdPrkd(initialActiveFilters?: any) {
    if (initialActiveFilters) {
        let filtersData: any = defaultNbsBlockedParkedFiltersData;
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.REMAINDER_FILTER, null, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.AGING, initialActiveFilters.aging, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.ASSIGNED_TO, initialActiveFilters.assignedTo, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.BLOCKING_REASON_CODE, initialActiveFilters.blockingReasonCode, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.FISCAL_YEAR, initialActiveFilters.fiscalYear, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.DUE_DATE,
            {
                startDate: initialActiveFilters.dueDate ? initialActiveFilters.dueDate.startDate : null,
                endDate: initialActiveFilters.dueDate ? initialActiveFilters.dueDate.endDate : null
            }, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.COMPANY_CODE, initialActiveFilters.compCode, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.PURCHASING_ORGANIZATION, initialActiveFilters.pOrg, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.NESTLE_INVOICE_NUMBER, initialActiveFilters.invoiceNumber, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.PRICE_RANGE_FROM, initialActiveFilters.priceRangeFrom, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.PRICE_RANGE_TO, initialActiveFilters.priceRangeTo, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.STATUS, initialActiveFilters.status, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.PARKING_REASON_CODE, initialActiveFilters.parkingReasonCode, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.PURCH_GROUP, initialActiveFilters.purchasingGroup, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.PLANT, initialActiveFilters.plant, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.REQUESTOR, initialActiveFilters.requestor, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.SPENDER, initialActiveFilters.spender, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.NESTLE_SUPPLIER_INVOICE, initialActiveFilters.supplierInvoiceNumber, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.TO_ACTOR_ON, initialActiveFilters.toActorOn, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.TASK_DATE,
            {
                startDate: initialActiveFilters.taskDate ? initialActiveFilters.taskDate.startDate : null,
                endDate: initialActiveFilters.taskDate ? initialActiveFilters.taskDate.endDate : null
            }, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.INSTRUCTION_DATE,
            {
                startDate: initialActiveFilters.instructionDate ? initialActiveFilters.instructionDate.startDate : null,
                endDate: initialActiveFilters.instructionDate ? initialActiveFilters.instructionDate.endDate : null
            }, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.Task_Responsible, initialActiveFilters.taskResponsible, filtersData);
        filtersData = setSelectedFiltersBykey(BlockedParkedtFilterKeys.VENDOR_CODE, initialActiveFilters.vendorNo, filtersData);
        return filtersData;
    } else {
        return defaultNbsBlockedParkedFiltersData
    }
}

export function getInitialFiltersForSapScope(initialActiveFilters?: any) {
    if (initialActiveFilters) {
        let filtersData: any = defaultNbsSapScopeFiltersData;
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.PR_NO, initialActiveFilters.prNo, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.PO_NO, initialActiveFilters.poNo, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.PRICE_RANGE_TO, initialActiveFilters.priceRangeTo, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.PRICE_RANGE_FROM, initialActiveFilters.priceRangeFrom, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.THRESHOLD_RANGE_TO, initialActiveFilters.thresholdRangeTo, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.THRESHOLD_RANGE_FROM, initialActiveFilters.thresholdRangeFrom, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.THRESHOLD_STATUS, initialActiveFilters.thresholdStatus, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.COMPLIANCE_INDICATOR, initialActiveFilters.complianceIndicator, filtersData);
        filtersData = setSelectedFiltersBykey(SapScopeFilterKeys.CREATOR, initialActiveFilters.creator, filtersData);
        return filtersData;
    } else {
        return defaultNbsSapScopeFiltersData
    }
}

export function getInitialFiltersForTracker(initialActiveFilters?: any) {
    if (initialActiveFilters) {
        let filtersData: any = defaultNbsTrackerFiltersData;
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Invoice_Number, initialActiveFilters.invoiceNumber, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Po_No, initialActiveFilters.poNumber, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Requestor, initialActiveFilters.requestorID, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Fiscal_Year, initialActiveFilters.fiscalYear, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Vendor_No, initialActiveFilters.vendorNumber, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Task_Responsible, initialActiveFilters.taskTaggedTo, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Task_Reffered_To, initialActiveFilters.taskTaggedTo, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Comments, initialActiveFilters.comments, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Posting_Date,
            {
                startDate: initialActiveFilters.postingDate ? initialActiveFilters.postingDate.startDate : null,
                endDate: initialActiveFilters.postingDate ? initialActiveFilters.postingDate.endDate : null
            }, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Processing_Date,
            {
                startDate: initialActiveFilters.dateOfProcessing ? initialActiveFilters.dateOfProcessing.startDate : null,
                endDate: initialActiveFilters.dateOfProcessing ? initialActiveFilters.dateOfProcessing.endDate : null
            }, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Company_Code, initialActiveFilters.companyCode, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Purchasing_Group, initialActiveFilters.purchashingGroup, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Supplier_Invoice_Number, initialActiveFilters.supplierInvoiceNumber, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Status, initialActiveFilters.status, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Blocking_Reason_Code, initialActiveFilters.parkingBlockingReason, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.to_Actor_On, initialActiveFilters.toActorOn, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Aging, initialActiveFilters.aging, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Instruction_Date,
            {
                startDate: initialActiveFilters.instructionDate ? initialActiveFilters.instructionDate.startDate : null,
                endDate: initialActiveFilters.instructionDate ? initialActiveFilters.instructionDate.endDate : null
            }, filtersData);
        filtersData = setSelectedFiltersBykey(TrackerFilterKeys.Due_Date,
            {
                startDate: initialActiveFilters.dueDate ? initialActiveFilters.dueDate.startDate : null,
                endDate: initialActiveFilters.dueDate ? initialActiveFilters.dueDate.endDate : null
            }, filtersData);
        return filtersData;
    } else {
        return defaultNbsTrackerFiltersData
    }
}

export function getPrSapUrl(SystemAlias, PurchaseReqNo) {
    const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());
    const PrSapLinkTemplate = template(
        "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=P_BANFN%3d<%=pr%>%26&AutoStart=true"
    );
    const sapPrefixed = R.mergeRight({ prefix: sapPrefix, system: SystemAlias });
    const prSapUrl = PrSapLinkTemplate(sapPrefixed({ pr: PurchaseReqNo, code: TCode.PR_DISPLAY }));
    return prSapUrl;
}

export function getPrRequestCockpitUrl(companyId, company, docDate, number, zone) {
    const rcPrefix = rcUrl(process.env.REACT_APP_ENV.trim());
    const RcLinkTemplate = template(
        "<%=prefix%>/document-flow?companyId=<%=companyId%>&company=<%=company%>&docDate=<%=docDate%>&number=<%=number%>&alias=<%=alias%>&type=PR"
    );
    const rcPrefixed = R.mergeRight({ prefix: rcPrefix, alias: zone });
    const prRcUrl = RcLinkTemplate(rcPrefixed({ companyId: companyId, company: company, docDate: docDate, number: number }));
    return prRcUrl;
}

export function getPoRequestCockpitUrl(companyId, company, docDate, number, zone) {
    const rcPrefix = rcUrl(process.env.REACT_APP_ENV.trim());
    const RcLinkTemplate = template(
        "<%=prefix%>/document-flow?companyId=<%=companyId%>&company=<%=company%>&docDate=<%=docDate%>&number=<%=number%>&alias=<%=alias%>&type=PO"
    );
    const rcPrefixed = R.mergeRight({ prefix: rcPrefix, alias: zone });
    const poRcUrl = RcLinkTemplate(rcPrefixed({ companyId: companyId, company: company, docDate: docDate, number: number }));
    return poRcUrl;
}

export const logUserIntraction = async (intractionName) => {
    await DocumentFlowService.logUserInteractionData(intractionName);
}

export const StyledColoredDataName = ({ item }) => {
    const styles = getNbsCockpitColoredOptionsDataTypeStyles({});
    return (
        <Grid container direction="row" alignItems="center">
            <Typography className={clsx(styles[item], styles.wrapIcon)}>
                <FiberManualRecordIcon className={clsx(styles.recordIcon, styles[item])} />
                {getDataName(item)}
            </Typography>
        </Grid>
    )
}

export const getNbsComplianceIndicatorColoredOptions = () => {
    return [
        {
            value: "P", label: <StyledColoredDataName item={NbsCockpitDataTypes.Compliant} />
        },
        {
            value: "X", label: <StyledColoredDataName item={NbsCockpitDataTypes.NonCompliant} />
        }
    ]
}

export const transformEmailsToArray = (emailList: string) => {
    if (!emailList) return [];
    let transformedEmails = emailList?.split(";")?.map(emailId => {
        return { firstName: emailId, lastName: "", email: emailId }
    });
    return transformedEmails;
};

export function getInitialFiltersForNbsMaintenance(initialActiveFilters?: any) {
    if (initialActiveFilters) {
        let filtersData: any = defaultNbsMaintenanceFiltersData;
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.COMPANY_CODE, initialActiveFilters.compCode, filtersData);
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.PURCHASING_ORGANIZATION, initialActiveFilters.purOrg, filtersData);
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.PURCHASE_GROUP, initialActiveFilters.purGroup, filtersData);
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.PLANT, initialActiveFilters.plant, filtersData);
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.MATERIAL_TYPE, initialActiveFilters.material, filtersData);
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.PARKING_OR_BLOCKING_REASON, initialActiveFilters.parkingBlockingReason, filtersData);
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.RECEIVER, initialActiveFilters.receiver, filtersData);
        filtersData = setSelectedFiltersBykey(NbsMaintenanceFilterKeys.INSTRUCTIONS, initialActiveFilters.instructions, filtersData);
        return filtersData;
    } else {
        return defaultNbsMaintenanceFiltersData
    }
}

export const transFormMaintenanceData = (data: NbsMainteanceTemplateData, mode: string) => {
    let finalData = {
        CompanyCode: data?.companyCode ? data.companyCode[0]?.id : "",
        CompanyCodeDesc: data?.companyCode ? data.companyCode[0]?.title : "",
        CompanyCodes: data?.companyCode ? data.companyCode.map(el => ({ Code: el.id, Description: el.title })) : [],
        PurchasingOrganizations: data?.purchasingOrganization ? data.purchasingOrganization.map(el => ({ Code: el.id, Description: el.title })) : [],
        ParkingBlockingReasonCode: data?.parkingBlockingReason ? data.parkingBlockingReason[0]?.id : "",
        ParkingBlockingReasonDesc: data?.parkingBlockingReason ? data.parkingBlockingReason[0]?.title : "",
        ReceiverCode: data?.receiver ? data.receiver[0]?.id : "",
        ReceiverDesc: data?.receiver ? data?.receiver[0]?.title : "",
        Instructions: data?.instructions ? data.instructions : "",
        PurchasingOrganizationCode: data?.purchasingOrganization ? data?.purchasingOrganization[0]?.id : "",
        PurchasingOrganizationDesc: data?.purchasingOrganization ? data?.purchasingOrganization[0]?.title : "",
        PurchasingGroups: data?.purchasingGroup ? data.purchasingGroup.map(el => ({ Code: el.id, Description: el.title })) : [],
        Plants: data?.plant ? data.plant.map(el => ({ Code: el.id, Description: el.title })) : [],
        MaterialTypes: data?.materialType ? data.materialType.map(el => ({ Code: el.id, Description: el.title })) : [],
    }
    if (mode !== 'create') {
        finalData["Id"] = data?.id ? data.id : "";
    };
    return finalData;
}

export const renderArrayDataAsText = (data) =>{
    let newData = data.map(item=>item.id+' - '+item.title)
    let texData = newData.join(' | ');
    return texData;
}

export const getNbsComplianceIndicatorTitle = (prData) => {
    return (prData.OB_Indicator || prData.OB_Rej_Ind) ? getRejectReasonLabel(prData.OB_Indicator || prData.OB_Rej_Ind) :
    getLabel(getType(prData.CollectiveNumber, prData.CompetitiveBiddingIdInAzure, prData.IsCbActive))
}

export const approveSapScopePO = async (data,approvedData,user,setLoading,successMessage,errorMessage,closePopUp,reload) => {
    try {
        const results = await DocumentFlowService.checkPO(data.PurchaseOrdNo, data.ItemNumbers[0]);
        if (results === 2) {
          try {
            const results = await DocumentFlowService.savePO(
                data.PurchaseOrdNo, 
                data.ItemNumbers.toString(), 
                null, 
                false, 
                data.SystemAlias, 
                approvedData === true ? 'P' : 'X'
            );
            if (results.status === 200) {
                await DocumentFlowService.sendEmailPO(
                    true, 
                    user.currentUser.email, 
                    user.currentUser.name, 
                    data.PurchaseOrdNo, 
                    data.ItemNumbers.toString(), 
                    ""
                );
                successMessage(`PO ${data.PurchaseOrdNo} approved successfully`);
                setLoading(false)
                closePopUp();
                reload();
            }
          } catch (error) {
            errorMessage(error);
            setLoading(false)
          }
        } else {
          if (results === 0) {
            errorMessage('PO Change Request already Approved');
            setLoading(false)
          }
          else if (results === 1) {
            errorMessage('PO Change Request already Rejected');
            setLoading(false)
          }
        }
    } catch (error) {
        errorMessage(error);
        setLoading(false)
    }
}

export const rejectSapScopePO = async (data,rejectedData,user,setLoading,successMessage,errorMessage,closePopUp,reload) => {
    try {
        const results = await DocumentFlowService.checkPO(data.PurchaseOrdNo, data.ItemNumbers[0]);
        if (results === 2) {
            await DocumentFlowService.sendEmailPO(
                false, 
                user.currentUser.email, 
                user.currentUser.name, 
                data.PurchaseOrdNo, 
                data.ItemNumbers.toString(), 
                rejectedData
            );
            successMessage(`PO ${data.PurchaseOrdNo} rejected successfully`);
            setLoading(false)
            closePopUp();
            reload();
        } else {
            if (results === 0) {
                errorMessage('PO Change Request already Approved');
                setLoading(false)
            }
            else if(results === 1) {
                errorMessage('PO Change Request already Rejected');
                setLoading(false)
            }
        }
    } catch (error) {
        errorMessage(error);
        setLoading(false)
    }
}

export const bulkApproveSapScopePOs = async (data,approvedData,user,setLoading,successMessage,errorMessage,closePopUp,reload) => {
 
    const resendPDF = false;

    let newData = data.map(item=>{
        return {
            IdNo: "D3",
            PurchaseOrdNo: item.PurchaseOrdNo, 
            ItemNumbers: item.ItemNumbers, 
            RepeatOutput: resendPDF ? "X" : ''
        }
    })

    let newApprovedData = approvedData.map(item=>{
        return {
            PurchaseOrdNo:item.PurchaseOrdNo, 
            CollectiveNumber: item.isComplianceOrNonCompliance === true ? "P" : "X" 
        }
    })

    let postData = newData.map((item, i) => Object.assign({}, item, newApprovedData[i]));

    let tempPostData = postData.map((data)=>{
        return data.ItemNumbers.map((itemNo)=> {
            return {
                "IdNo": data.IdNo,
                "PurchaseOrdNo": data.PurchaseOrdNo,
                "PurchaseOrdItemNo": itemNo,
                "PrItem":  itemNo,
                "RepeatOutput": data.RepeatOutput,
                "CollectiveNumber": data.CollectiveNumber
            }
        });
    }).flat();

    const sapPostData = _.sortBy(tempPostData,"PurchaseOrdNo");

    const azurePostData = postData.map((data=>{
        return {poNumber: data.PurchaseOrdNo,ItemNoPR: data.ItemNumbers[0]}
    }));

    try {

        const results = await DocumentFlowService.checkPOs(azurePostData);
        const alreadyApprovedorRejectedPos = results.filter(item=>item.status!=='2');
   
        if (alreadyApprovedorRejectedPos.length === 0) {
          try {

            const results = await DocumentFlowService.savePOs(
                sapPostData,
                user.defaultSystemAlias?.[0]
            );

            if (results.status === 200) {

                const approvedPosData = data.map(item=>{
                    return {
                        approved: true,
                        poModifier: user.email,
                        approverName: user.name,
                        poNumber: item.PurchaseOrdNo,
                        comment: '',
                        itemNoPR: item.ItemNumbers.toString()
                    }
                })

                const results = await DocumentFlowService.sendEmailToApprovedPOsUpdatedUsers(approvedPosData);
                
                successMessage(`All Purchase Orders approved successfully`);

                setLoading(false)
                closePopUp();
                reload();

            }
          } catch (error) {
            errorMessage(error);
            setLoading(false)
          }
        } else {
            const poNumbers = alreadyApprovedorRejectedPos.map(item=>item.poNumber);
            errorMessage('PO Numbers: '+poNumbers.toString()+' Change Requests already Approved/Rejected, Please refresh/uncheck and resubmit');
            setLoading(false)
        }

    } catch (error) {
        errorMessage(error);
        setLoading(false)
    }
}

export const bulkRejectSapScopePOs = async (data,rejectedData,user,setLoading,successMessage,errorMessage,closePopUp,reload) => {

    const azurePostData = data.map((data=>{
        return {poNumber: data.PurchaseOrdNo,ItemNoPR: data.ItemNumbers[0]}
    }));

    let postData = data.map((item, i) => Object.assign({}, item, rejectedData[i]));

    try {

        const results = await DocumentFlowService.checkPOs(azurePostData);
        const alreadyApprovedorRejectedPos = results.filter(item=>item.status!=='2');

        if (alreadyApprovedorRejectedPos.length === 0) {

            const rejectedPostData = postData.map(item=>{
                return {
                    approved: false,
                    poModifier: user.email,
                    approverName: user.name,
                    poNumber: item.PurchaseOrdNo,
                    comment: item.reason,
                    itemNoPR: item.ItemNumbers.toString()
                }
            })

            const results = await DocumentFlowService.sendEmailToApprovedPOsUpdatedUsers(rejectedPostData);
            successMessage(`All Purchase Orders rejected successfully`);

            setLoading(false)
            closePopUp();
            reload();

        } else {
            const poNumbers = alreadyApprovedorRejectedPos.map(item=>item.poNumber);
            errorMessage('PO Numbers: '+poNumbers.toString()+' Change Requests already Approved/Rejected, Please refresh/uncheck and resubmit');
            setLoading(false)
        }

    } catch (error) {
        errorMessage(error);
        setLoading(false)
    }
}
