import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {channel} from 'redux-saga';
import {
  ContractChatTypes,
  GetChatCounters,
  GetChatMessagesSuccess,
  NewContractChatCounter,
} from "../../actions/chat.actions";
import {ChatCounterType, IChatCounter, IMessage, RoomChatService} from "../../../shared/domains/chat/roomChat.service";
import {ChatService} from "../../domains/chat/chat.service";
import {User} from "../../../shared/domains/user/user";
import {RootStateStore} from "../../../application.reducers";
import {ChatActions} from "../../reducers/contract/chat.reducer";
import {NewDocFlowChatCounter} from "../../../docFlows/actions/docflowChat.actions";

function* startRoom(action): any {
  try {
    const room = RoomChatService.newInstance<IMessage>('contractChat', action.id, 'ReceiveContractChatMessage');
    yield call(room.join.bind(room));
    yield put(new ChatActions.startRoomSuccess(room));
  } catch (error) {
    yield put(new ChatActions.startRoomFailure(error));
  }
}

function* fetchMessages(action): any {
  try {
    yield put({type: ChatActions.startRoom, id: action.id});
    const result = yield call(ChatService.fetchChatMessages, action.id);
    yield put({type: ChatActions.onLoadMessagesSuccess, id: action.id, messages: result});
  } catch (error) {
    yield put(new ChatActions.onLoadMessagesFailure(error));
  }
}

function* fetchMessagesSuccess(action) {
  yield put(new GetChatMessagesSuccess(action.id, action.messages));
}

function* startChatCounters(action: GetChatCounters): any {
  try {
    const user: User = yield select(({shared}: RootStateStore) => shared.user.currentUser);
    const room = RoomChatService.newInstance<IChatCounter>('chatCounts', user.email, 'NewMessage');
    yield call(room.join.bind(room));
    yield put(new ChatActions.startChatCounterSuccess(room, user.id));
  } catch (error) {
    yield put(new ChatActions.startChatCounterFailure(error));
  }
}

function* sendMessage(action): any {
  try {
    const result = yield call(ChatService.sendMessage, action.contractId, action.message, action.mentionedEmailAddresses);
    yield put(new ChatActions.onSendMessageSuccess(result));
  } catch (error) {
    yield put(new ChatActions.onSendMessageFailure(error));
  }
}

function* onNewChatCounters(action: {type: any, counter: IChatCounter, currentUserId?: number}) {
  const counter: IChatCounter = action.counter;
  switch(counter.type) {
    case ChatCounterType.CONTRACT:
      yield put(new NewContractChatCounter(action.counter, action.currentUserId));
      break;
    case ChatCounterType.DOCFLOW:
      yield put(new NewDocFlowChatCounter(action.counter, action.currentUserId));
      break;
    default:
  }
}

function* watchStartRoom() {
  yield takeLatest(ChatActions.startRoom, startRoom);
}

function* watchNewChatCounters() {
  yield takeLatest(ChatActions.onNewChatCounter, onNewChatCounters);
}

function* watchStartChatCounters() {
  yield takeLatest(ContractChatTypes.GET_CHAT_COUNTERS, startChatCounters);
}

function* watchGetMessages() {
  yield takeLatest(ChatActions.onLoadMessages, fetchMessages);
}

function* watchGetMessagesSuccess() {
  yield takeLatest(ChatActions.onLoadMessagesSuccess, fetchMessagesSuccess);
}

function* watchSendMessage() {
  yield takeLatest(ChatActions.onSendMessage, sendMessage);
}

export default function* contractChatSaga() {
  yield all([
    fork(watchStartRoom),
    fork(watchGetMessages),
    fork(watchGetMessagesSuccess),
    fork(watchSendMessage),
    fork(watchStartChatCounters),
    fork(watchNewChatCounters),
  ]);
}
