import {all, call, fork, put, select, takeLatest, delay} from 'redux-saga/effects'
import {sort} from 'ramda';
import {
  AddClmItemPrice,
  AddClmItemPriceSuccess,
  AddItemPrice,
  AddItemPriceError,
  AddItemPriceSuccess,
  ClmItemPriceChange,
  DeleteItemPrice,
  DeleteItemPriceError,
  DeleteItemPriceSuccess,
  FetchClmItemsPrice,
  FetchClmItemsPriceError,
  FetchClmItemsPriceSuccess,
  FetchDraftItemsPrice,
  FetchDraftItemsPriceError,
  FetchDraftItemsPriceSuccess,
  FetchItemsPrice,
  FetchItemsPriceError,
  FetchItemsPriceSuccess,
  FetchScalesPrice,
  FetchScalesPriceError,
  FetchScalesPriceSuccess,
  ItemPriceActionTypes,
  UpdateDraftItemsPrice,
  UpdateItemsDraftPriceError,
  UpdateItemsDraftPriceSuccess,
  UpdateItemsPrice,
  UpdateItemsPriceError,
  UpdateItemsPriceSuccess,
  UpdateScalesPrice,
  UpdateScalesPriceError,
  UpdateScalesPriceSuccess
} from "../../actions/itemPricing.actions";
import {ItemPriceService} from "../../domains/itemPrice/itemPrice.service";
import {ClmItemChange, FetchDraftItems, FetchItems} from "../../actions/item.actions";
import {ContractsStateStore} from "../../reducers";
import {changeTargetValue, Contract, ContractType} from "../../domains/contract/contract";
import {RootStateStore} from "../../../application.reducers";
import {ItemPrice} from "../../domains/itemPrice/itemPrice";
import {dateTimeFromFormat} from "../../../shared/utils/global";
import {UpdateNewStateContracts} from "../../actions/contract.actions";

const isEqual = (a, b) => {
  return +dateTimeFromFormat(a.ValidFrom) === +dateTimeFromFormat(b.ValidFrom) &&
    +dateTimeFromFormat(a.ValidTo) === +dateTimeFromFormat(b.ValidTo)
};

const isOverlap = (a, b) => {
  return a !== b &&
    dateTimeFromFormat(a.ValidFrom).startOf('day') <= dateTimeFromFormat(b.ValidTo).startOf('day') &&
    dateTimeFromFormat(b.ValidFrom).startOf('day') <= dateTimeFromFormat(a.ValidTo).startOf('day')
};

const isContains = (a, b) => {
  return dateTimeFromFormat(a.ValidFrom).startOf('day') <= dateTimeFromFormat(b.ValidFrom).startOf('day') &&
    dateTimeFromFormat(a.ValidTo).startOf('day') >= dateTimeFromFormat(b.ValidTo).startOf('day')
};

const getConditionRecord = (ip, c) => {
  return !c.find(p => p.ConditionRecord === ip.ConditionRecord) ? ip.ConditionRecord : null;
};

export function* selectClmItemsPriceChange() {
  const {itemsPrice} = yield select((state: RootStateStore) => state.contracts.clmContract.changes);
  if (itemsPrice) {
    const clmItemsPrice = yield select((state: RootStateStore) => state.contracts.clmItemsPrice.itemsPrice);
    return Object.keys(itemsPrice).reduce((prices, key) => {
      if (!prices[key]) {
        prices[key] = clmItemsPrice[key];
      }
      return prices;
    }, {});
  }
  return itemsPrice;
}

function* addNewValidityPeriod(itemPrices: ItemPrice[], newValidityPeriod: ItemPrice) {
  const dateDiff = (a, b) => {
    const diff = dateTimeFromFormat(a.ValidFrom).startOf('day') - dateTimeFromFormat(b.ValidFrom).startOf('day');
    if (diff === 0) {
      return a === newValidityPeriod ? -1 : 1;
    }
    return diff;
  };

  const sortedByFrom = sort(dateDiff, [...itemPrices, {...newValidityPeriod, ConditionRecord: null}]);
  let newValidityPeriods = [sortedByFrom[0]];
  let originalTop: any = {}, newTop: any = {}, period: any = {};

  for(let i = 1; i < sortedByFrom.length; i++) {
    originalTop = newValidityPeriods[newValidityPeriods.length -1];
    period = sortedByFrom[i];
    if (originalTop === newValidityPeriod && isContains(originalTop, period)) continue;
    const to = originalTop.ValidTo;
    if (isOverlap(period, originalTop) && (dateTimeFromFormat(period.ValidFrom) > dateTimeFromFormat(originalTop.ValidFrom))) {
      newValidityPeriods[newValidityPeriods.length -1] = {
        ...originalTop,
        ValidTo: dateTimeFromFormat(period.ValidFrom).minus({days:1}).toFormat('yyyyMMdd')
      };
    }
    newTop = newValidityPeriods[newValidityPeriods.length -1];
    if (dateTimeFromFormat(period.ValidFrom) < dateTimeFromFormat(to) &&
      dateTimeFromFormat(period.ValidTo) > dateTimeFromFormat(to)) {
      const from = dateTimeFromFormat(period.ValidFrom) > dateTimeFromFormat(newTop.ValidTo) ?
        period.ValidFrom : newTop.ValidTo;
      if (dateTimeFromFormat(to) > dateTimeFromFormat(from)) {
        newValidityPeriods.push({...period, ValidFrom: from, ValidTo: to, ConditionRecord: getConditionRecord(period, newValidityPeriods)});
      }
    } else if (!isOverlap(newTop, period)) {
      newValidityPeriods.push({...period, ConditionRecord: getConditionRecord(period, newValidityPeriods)});
    }
  }

  newTop = newValidityPeriods[newValidityPeriods.length -1];
  if (!isEqual(newTop, period)) {
    if (!isOverlap(newTop, period) && dateTimeFromFormat(originalTop.ValidTo) > dateTimeFromFormat(period.ValidTo)) {
      newValidityPeriods.push({
        ...originalTop,
        ConditionRecord: getConditionRecord(originalTop, newValidityPeriods),
        ValidFrom: dateTimeFromFormat(period.ValidTo).plus({days:1}).toFormat('yyyyMMdd')
      });
    } else if (isOverlap(newTop, period)) {
      if (dateTimeFromFormat(period.ValidFrom) > dateTimeFromFormat(originalTop.ValidTo) ||
        dateTimeFromFormat(period.ValidTo) > dateTimeFromFormat(originalTop.ValidTo)) {
        newValidityPeriods.push({
          ...period,
          ConditionRecord: getConditionRecord(period, newValidityPeriods),
          ValidFrom: dateTimeFromFormat(originalTop.ValidTo).plus({days:1}).toFormat('yyyyMMdd')
        });
      }
    }
  }
  return newValidityPeriods.map((p, idx) => ({...p, ConditionItem: (idx+1).toString().padStart(2, '0')}));
}

function* fetchItemPrices(action: FetchItemsPrice) {
  try {
    const contractType = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractType);
    let result;
    if (contractType === ContractType.EXPIRED) {
      result = yield call(ItemPriceService.fetchExpiredItemPrices, action.contractNumber, action.itemNo, action.systemAlias);
    } else {
      result = yield call(ItemPriceService.fetchItemPrices, action.contractNumber, action.itemNo, action.systemAlias);
    }
    yield put(new FetchItemsPriceSuccess(action.itemNo, result.data, result.count));
  } catch (error) {
    yield put(new FetchItemsPriceError(action.itemNo, error));
  }
}

function* fetchClmItemPrices(action: FetchClmItemsPrice) {
  try {
    const result = yield call(ItemPriceService.fetchItemPrices, action.contractNumber, action.itemNo, action.systemAlias);
    yield put(new FetchClmItemsPriceSuccess(action.itemNo, result.data));
  } catch (error) {
    yield put(new FetchClmItemsPriceError(action.itemNo, error));
  }
}

function* fetchDraftItemPrices(action: FetchDraftItemsPrice) {
  try {
    const result = yield call(ItemPriceService.fetchDraftItemPrices, action.contractNumber, action.refContractNumber, action.itemNo, action.systemAlias, action.timestamp);
    yield put(new FetchDraftItemsPriceSuccess(action.itemNo, result.data, result.count));
  } catch (error) {
    yield put(new FetchDraftItemsPriceError(action.itemNo, error));
  }
}

function* updateItemPrices(action: UpdateItemsPrice) {
  try {
    const result = yield call(ItemPriceService.updateItemPrice, action.itemPrice, action.SystemAlias, action.targetValue);
    yield all([
      put(new UpdateItemsPriceSuccess(action.executor, result, action.targetValue)),
      put(new FetchItems(action.itemPrice.AgreementNo, action.SystemAlias, action.currentPage))
    ]);
  } catch (error) {
    yield put(new UpdateItemsPriceError(action.executor, error));
  }
}

function* updateDraftItemPrices(action: UpdateDraftItemsPrice) {
  try {
    const result = yield call(ItemPriceService.updateDraftItemPrice, action.itemPrice, action.SystemAlias, action.targetValue);
    yield all([
      put(new UpdateItemsDraftPriceSuccess(action.executor, result, action.timestamp, action.targetValue)),
      put(new FetchDraftItems(action.itemPrice.AgreementNo, action.itemPrice.RefAgreementNo, action.SystemAlias, action.timestamp, action.currentPage))
    ]);
  } catch (error) {
    yield put(new UpdateItemsDraftPriceError(action.executor, error));
  }
}


function* itemPriceUpdatedSuccess(action: UpdateItemsPriceSuccess) {
  const {contracts, selectedContract} = yield select(({contracts}: RootStateStore) => contracts.contracts);
  const updatedContracts = contracts.map((contract: Contract): Contract =>
    (contract.AgreementNo === action.itemPrice.AgreementNo) ? changeTargetValue(contract, action.newTargetValue) : contract
  );
  yield put(new UpdateNewStateContracts(updatedContracts, changeTargetValue(selectedContract, action.newTargetValue)));
}

function* draftItemPriceUpdatedSuccess(action: UpdateItemsDraftPriceSuccess) {
  const {contracts, selectedContract} = yield select(({contracts}: RootStateStore) => contracts.contracts);
  const updatedContracts = contracts.map((contract: Contract): Contract =>
    (contract.RefAgreementNo === action.itemPrice.RefAgreementNo && contract.TimeStamp === action.timestamp) ?
      changeTargetValue(contract, action.newTargetValue) :
      contract
  );
  yield put(new UpdateNewStateContracts(updatedContracts, changeTargetValue(selectedContract, action.newTargetValue)));
}

function* deleteItemPrice(action: DeleteItemPrice) {
  try {
    const result = yield call(ItemPriceService.deleteItemPrice, action.itemPrice, action.SystemAlias);
    if (action.done) {
      action.done();
    }
    yield all([
      put(new DeleteItemPriceSuccess(result)),
      put(new FetchItems(action.itemPrice.AgreementNo, action.SystemAlias, action.currentPage))
    ]);
  } catch (error) {
    yield put(new DeleteItemPriceError(action.itemPrice, error));
  }
}

function* addItemPrice(action: AddItemPrice) {
  try {
    const result = yield call(ItemPriceService.addItemPrice, action.itemPrice, action.SystemAlias);
    if (action.done) {
      action.done();
    }
    yield all([
      put(new AddItemPriceSuccess(result)),
      put(new FetchItems(action.itemPrice.AgreementNo, action.SystemAlias, action.currentPage))
    ]);
  } catch (error) {
    yield put(new AddItemPriceError(action.itemPrice, error));
  }
}

function* fetchScalesPrice(action: FetchScalesPrice) {
  try {
    const result = yield call(ItemPriceService.fetchScalesPrice, action.itemPrice.ConditionRecord, action.itemPrice.ConditionItem);
    yield put(new FetchScalesPriceSuccess(result));
  } catch (error) {
    yield put(new FetchScalesPriceError(error));
  }
}

function* updateScalesPrice(action: UpdateScalesPrice) {
  try {
    yield call(ItemPriceService.updateScalesPrice, action.itemPrice, action.scalesPrice);
    if (action.done) {
      action.done();
    }
    yield put(new FetchItemsPrice(action.itemPrice.AgreementNo, action.itemPrice.ItemNo, action.systemAlias));
    yield put(new UpdateScalesPriceSuccess('Scales price updated successfully'));
  } catch (error) {
    yield put(new UpdateScalesPriceError(error));
  }
}

function* notifyClmItemChange(itemNo) {
  const currentItems = yield select(({contracts}: RootStateStore) => contracts.items.items);
  const {items} = yield select(({contracts}: RootStateStore) => contracts.clmContract.changes);
  let item = currentItems.find(i => i.ItemNo === itemNo);
  if (items && items[itemNo]) {
    item = items[itemNo];
  }
  yield put(new ClmItemChange(item));
}

function* clmItemPriceChange(action: ClmItemPriceChange) {
  yield notifyClmItemChange(action.itemPrice.ItemNo);
}

function* addClmItemPrice(action: AddClmItemPrice) {
  yield notifyClmItemChange(action.itemPrice.ItemNo);
  const {itemsPrice} = yield select(({contracts}: RootStateStore) => contracts.clmItemsPrice);
  const currentItemsPrice = itemsPrice[action.itemPrice.ItemNo];
  const newItemsPrice = yield addNewValidityPeriod(currentItemsPrice, {...action.itemPrice, IsNew: true});
  yield delay(800);
  yield put(new AddClmItemPriceSuccess(action.itemPrice, newItemsPrice));
  yield all(newItemsPrice.filter(ip => ip.Per !== action.itemPrice.Per).map(ip => put(new ClmItemPriceChange({...ip, Per: action.itemPrice.Per}))));
}

function* watchFetchItemPrices() {
  yield takeLatest(ItemPriceActionTypes.FETCH_ITEMSPRICE, fetchItemPrices);
}

function* watchFetchClmItemPrices() {
  yield takeLatest(ItemPriceActionTypes.FETCH_CLM_ITEMSPRICE, fetchClmItemPrices);
}

function* watchFetchDraftItemPrices() {
  yield takeLatest(ItemPriceActionTypes.FETCH_DRAFT_ITEMSPRICE, fetchDraftItemPrices);
}

function* watchUpdateItemPrices() {
  yield takeLatest(ItemPriceActionTypes.UPDATE_ITEMSPRICE, updateItemPrices);
}

function* watchUpdateDraftItemPrices() {
  yield takeLatest(ItemPriceActionTypes.UPDATE_ITEMSPRICE_DRAFT, updateDraftItemPrices);
}

function* watchUpdateItemPriceSuccess() {
  yield takeLatest(ItemPriceActionTypes.UPDATE_ITEMSPRICE_SUCCESS, itemPriceUpdatedSuccess);
}

function* watchUpdateDraftItemPriceSuccess() {
  yield takeLatest(ItemPriceActionTypes.UPDATE_ITEMSPRICE_DRAFT_SUCCESS, draftItemPriceUpdatedSuccess);
}

function* watchDeleteItemPrice() {
  yield takeLatest(ItemPriceActionTypes.DELETE_ITEM_PRICE, deleteItemPrice);
}

function* watchAddItemPrice() {
  yield takeLatest(ItemPriceActionTypes.ADD_ITEM_PRICE, addItemPrice);
}

function* watchFetchScalesPrice() {
  yield takeLatest(ItemPriceActionTypes.FETCH_SCALESPRICE, fetchScalesPrice);
}

function* watchUpdateScalesPrice() {
  yield takeLatest(ItemPriceActionTypes.UPDATE_SCALESPRICE, updateScalesPrice);
}

function* watchClmItemPriceChange() {
  yield takeLatest(ItemPriceActionTypes.CLM_ITEM_PRICE_CHANGE, clmItemPriceChange);
}

function* watchAddNewValidityPeriod() {
  yield takeLatest(ItemPriceActionTypes.ADD_CLM_ITEM_PRICE, addClmItemPrice);
}

export default function* contractItemPricesSaga() {
  yield all([
    fork(watchFetchItemPrices),
    fork(watchFetchClmItemPrices),
    fork(watchFetchDraftItemPrices),
    fork(watchUpdateItemPrices),
    fork(watchUpdateDraftItemPrices),
    fork(watchUpdateItemPriceSuccess),
    fork(watchUpdateDraftItemPriceSuccess),
    fork(watchDeleteItemPrice),
    fork(watchAddItemPrice),
    fork(watchFetchScalesPrice),
    fork(watchUpdateScalesPrice),
    fork(watchClmItemPriceChange),
    fork(watchAddNewValidityPeriod)
  ])
};
