      import { DateTime } from "luxon";
import { IAction } from "../../../../shared/domains/core/actions";
import { assoc, defaultTo, merge, filter } from "ramda";
import { PAActionTypes } from "../../actions/paFilters";
import { TextFilter } from "../../../../shared/domains/query/filter";
import { IPAFilters } from "../../../../shared/domains/user/user";
import { error } from "../../../../shared/reducers/utils";

export interface PAFiltersState {
  companies: TextFilter[],
  paFilters: IPAFilters,
  loading: boolean;
  loaded: boolean;
  error: any;
  alias: string;
}

export const initialPAFilters = { 
  companies: [],
  purchaseReqNo: [],
  aribaPr : [],
  prCreatorName : [],
  supplier : [],
  purchGroup : [],
  validity: DateTime.local().minus({ months: 3 }).toFormat('yyyyMMdd'),
};

export const initialFiltersState: PAFiltersState = {
  companies: [],
  paFilters: initialPAFilters,
  loading: false,
  loaded: false,
  error: null,
  alias:null
};

const loading = { loading: true, loaded: false, error: null };

export function PAFiltersReducer(state: PAFiltersState = initialFiltersState, action: IAction<PAActionTypes | PAActionTypes>): PAFiltersState {
  switch (action.type) {
    case PAActionTypes.PA_GET_FILTERS:
      return merge(state, loading);
    case PAActionTypes.PA_GET_FILTERS_FAILURE:
      return error(action.error)(state);
    case PAActionTypes.PA_GET_FILTERS_SUCCESS:
      const filtersCompanies = state.paFilters.companies.map((c: TextFilter) => {
        const com = action.companies.find((co: TextFilter) => c && c.id === co.id);
        return com ? com : c;
      });
      return {
        ...state,
        companies: defaultTo(state.companies, action.companies),
        paFilters: assoc('companies', filtersCompanies, state.paFilters),
        loading: false,
        loaded: true,
      };
    case PAActionTypes.PA_NEW_FILTER:
      return {
        ...state,
        paFilters: {
          companies: filter((f: TextFilter) => !!f.id, action.filters.companies),
          validity: action.filters.validity,
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName : action.filters.prCreatorName,
          supplier : action.filters.supplier,
          purchGroup : action.filters.purchGroup,
        }
      };
    case PAActionTypes.INIT_PA_FILTERS:
      return {
        ...state,
        paFilters: {
          companies: action.filters.companies,
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName : action.filters.prCreatorName,
          supplier : action.filters.supplier,
          purchGroup : action.filters.purchGroup,
          validity: DateTime.local().minus({ months: 3 }).toFormat('yyyyMMdd'),
        }
      };
    case PAActionTypes.DELETE_PA_FILTER:
      return {
        ...state,
        paFilters: {
          companies: action.filters.companies,
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName : action.filters.prCreatorName,
          supplier : action.filters.supplier,
          purchGroup : action.filters.purchGroup,
          validity: DateTime.local().minus({ months: 3 }).toFormat('yyyyMMdd'),
        }
      };
    case PAActionTypes.RESET_PA_FILTERS:
      return {
        ...state,
        loading: true, loaded: false
      };
    case PAActionTypes.RESET_PA_FILTERS_SUCCESS:
      return {
        ...state,
        paFilters: {
          companies: action.filters.companies,
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName : action.filters.prCreatorName,
          supplier : action.filters.supplier,
          purchGroup : action.filters.purchGroup,
          validity: DateTime.local().minus({ months: 3 }).toFormat('yyyyMMdd'),
        },
        loading: false, loaded: true
      };
    default:
      return state;
  }
}
