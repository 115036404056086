import {isEmpty, mergeRight, assoc, assocPath} from "ramda";
import {IAction} from "../../../shared/domains/core/actions";
import {ContractDownloadUploadActionTypes} from "../../actions/downloadUpload.actions";
import {error, loaded, loading} from "../../../shared/reducers/utils";
import {ExcelJobError, ExcelJobStatus} from "../../domains/contract/contract";

export interface ContractDownloadUploadState {
  loading: boolean;
  uploadFailed: boolean;
  batchUpload: {
    fetching: boolean;
    hasPending: boolean;
    jobStatuses: ExcelJobStatus[];
    jobsHistory: ExcelJobStatus[];
    errorDetailsFetching: { [key: string]: boolean };
    errorDetails: { [key: string]: ExcelJobError[] };
  },
  error: any;
}

export const initialContractDownloadUploadState: ContractDownloadUploadState = {
  loading: false,
  uploadFailed: false,
  batchUpload: {
    fetching: false,
    hasPending: false,
    jobStatuses: [],
    jobsHistory: [],
    errorDetailsFetching: {},
    errorDetails: {}
  },
  error: null
};

export function ContractDownloadUploadReducer(state: ContractDownloadUploadState = initialContractDownloadUploadState, action: IAction<ContractDownloadUploadActionTypes>): ContractDownloadUploadState {
  switch (action.type) {
    case ContractDownloadUploadActionTypes.DOWNLOAD_CONTRACT:
    case ContractDownloadUploadActionTypes.UPLOAD_CONTRACT:
      return loading(assocPath(['batchUpload', 'hasPending'], false, state));
    case ContractDownloadUploadActionTypes.DOWNLOAD_CONTRACT_SUCCESS:
    case ContractDownloadUploadActionTypes.UPLOAD_CONTRACT_SUCCESS:
      return loaded(state);
    case ContractDownloadUploadActionTypes.DOWNLOAD_CONTRACT_FAILURE:
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_HAS_PENDING_JOB_FAILURE:
      return error(action.error)(state);
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_STATUSES_FAILURE:
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_HISTORY_FAILURE:
      return error(action.error)(assocPath(['batchUpload', 'fetching'], false, state));
    case ContractDownloadUploadActionTypes.UPLOAD_CONTRACT_FAILURE:
      return error(action.error)(assoc('uploadFailed', true, state));
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_HAS_PENDING_JOB:
      return loaded(assoc('batchUpload', mergeRight(state.batchUpload, {hasPending: false, fetching: false}), state));
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_STATUSES:
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_HISTORY:
      return loaded(assocPath(['batchUpload', 'fetching'], true, state));
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_HAS_PENDING_JOB_SUCCESS:
      return loaded(assocPath(['batchUpload', 'hasPending'], action.hasPending, state));
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_STATUSES_SUCCESS:
      return assoc('batchUpload', mergeRight(initialContractDownloadUploadState.batchUpload, {jobStatuses: action.statuses, hasPending: !isEmpty(action.statuses)}), state);
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_HISTORY_SUCCESS:
      return assoc('batchUpload', mergeRight(state.batchUpload, {jobsHistory: action.statuses, fetching: false}), state);
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_ERROR_DETAILS:
      return assocPath(['batchUpload', 'errorDetailsFetching'], {...state.batchUpload.errorDetailsFetching, [action.batchId]: true}, state);
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_ERROR_DETAILS_SUCCESS:
      return mergeRight(state, {
        batchUpload: {
          ...state.batchUpload,
          errorDetailsFetching: {...state.batchUpload.errorDetailsFetching, [action.batchId]: false},
          errorDetails: {...state.batchUpload.errorDetails, [action.batchId]: action.statuses}
        }
      });
    case ContractDownloadUploadActionTypes.FETCH_EXCEL_JOB_ERROR_DETAILS_FAILURE:
      return assocPath(['batchUpload', 'errorDetailsFetching'], {...state.batchUpload.errorDetailsFetching, [action.batchId]: false}, state);
    case ContractDownloadUploadActionTypes.RESET_CONTRACT_UPLOAD_PENDING:
      return assocPath(['batchUpload', 'hasPending'], false, state);
    case ContractDownloadUploadActionTypes.RESET_CONTRACT_UPLOAD_ERROR:
      return mergeRight(state, {uploadFailed: false});
    default:
      return state;
  }
}
