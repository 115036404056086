import {combineReducers, Reducer} from "redux";
import {TenderCalendarReducer, TenderCalendarState} from "./calendar.reducer";
import {TenderCalendarFiltersReducer, TenderCalendarFiltersState} from "./calendar.filters.reducer";

export interface TenderCalendarStateStore {
  calendar: TenderCalendarState,
  calendarFilters: TenderCalendarFiltersState
}

export const TenderCalendarCombinedReducers: Reducer<TenderCalendarStateStore> = combineReducers({
  calendar: TenderCalendarReducer,
  calendarFilters: TenderCalendarFiltersReducer
});
