import * as React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { move, update } from "ramda";
import { useTranslation } from "react-i18next";
import { LayoutThreeColumns } from 'react-bootstrap-icons';
import Tooltip from "@material-ui/core/Tooltip";
import { useStylesForToolTip } from 'src/app/shared/components/tooltip/toolTip.styles';
import { SecondaryButton } from "../button";
interface Props {
    initialColumns: any,
    DefaultColumns: () => any,
    setColumns: (columns) => any,
    heading: string,
    labelForTranslation: string,
    iconStyle: boolean
}

export default function GenericColumnsConfigComponent({initialColumns, DefaultColumns, setColumns, heading,labelForTranslation, iconStyle}: Props) {
    const { t } = useTranslation();
    const [tmpColumns, setTmpColumns] = React.useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const classesForToolTip = useStylesForToolTip({});

    React.useEffect(() => {
        setTmpColumns(initialColumns);
    },[initialColumns]);

    const SortableItem = SortableElement(({ col }) =>
        <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FormControlLabel
                style={{ margin: '0', pointerEvents: "auto" }}
                control={<Checkbox
                    checked={col.show}
                    onChange={() => handleColVisibility(col)}
                    size="small"
                    color="primary" />}
                label={t(`${labelForTranslation}:${col.id}`)} />
            <DragHandleIcon style={{ cursor: 'move' }} />
        </li>);
    const SortableList = SortableContainer(({ columns }) => {
        return (
            <ul style={{ listStyle: 'none', padding: '0' }}>
                {columns.map((col, i) => col.hidden ? null : <SortableItem key={`item-${i}`} index={i} col={col} />)}
            </ul>
        );
    });
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setTmpColumns(move(oldIndex, newIndex, tmpColumns));
    };

    const handleColVisibility = (col) => {
        const columns = tmpColumns;
        const index = columns.map(el => el.id).indexOf(col.id);
        const cols = update(index, {
            ...columns[index],
            show: !columns[index].show
        }, columns);
        setTmpColumns(cols);

    };

    const restoreDefault = () => {
        setTmpColumns(() => DefaultColumns());
    };

    const handleSaveAndClose = () => {
        setColumns(tmpColumns);
        setOpen(false);
    };

    return <>
        {iconStyle && <Tooltip
            title="Edit Columns"
            classes={{ tooltip: classesForToolTip.customTooltip }}

        >
            <Button size="small"
                style={{ color: "#777777", opacity: '0.6', textTransform: "capitalize" }}
                startIcon={<LayoutThreeColumns style={{
                    fontSize: '18px',
                }} />}
                onClick={() => setOpen(true)}></Button>
        </Tooltip>}
        { !iconStyle &&
        <SecondaryButton buttonlabel={"Columns"} onClick={() => setOpen(true)} />
        }
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="max-width-dialog-title">
            <DialogContent style={{ display: 'flex', flexDirection: 'column', maxHeight: '450px', minWidth: '380px' }}>
                <DialogTitle style={{
                    textAlign: 'center',
                    padding: '0'
                }}>{heading}</DialogTitle>
                <SortableList
                    columns={tmpColumns}
                    onSortEnd={onSortEnd}
                    hideSortableGhost={false} />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={restoreDefault} color="secondary">
                    RESTORE DEFAULT
                </Button>
                <span>
                    <Button onClick={() => setOpen(false)} color="primary">
                        CANCEL
                    </Button>
                    <Button onClick={handleSaveAndClose} color="primary">
                        SAVE
                    </Button>
                </span>
            </DialogActions>
        </Dialog>
    </>
}
