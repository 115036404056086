import * as React from 'react';
import { AddRounded, ExitToAppRounded } from '@material-ui/icons';
import { useStylesForToolTip } from 'src/app/shared/components/tooltip/toolTip.styles';
import { Tooltip } from '@material-ui/core';
import { ShareRounded } from 'grommet-icons';
import { SmallCircularLoader } from '../loading';
import CircularProgress from "@material-ui/core/CircularProgress";

function getTitle(componentName: string, disabled: boolean, loading: boolean) {
    if (componentName === 'Export to Excel') {
        if (disabled)
            return "The file you requested is too large. Please use some filters"
        if (loading)
            return "Exporting..."
    }
    return componentName;
}

export const GenericIconButtonsComponent = ({componentName, onClick, disabled = false, loading = false} ) => {
    const [hover, setHover] = React.useState(false)
    const classes = useStylesForToolTip({});
    const color = hover ? '#fff' : disabled ? '##c0c0c0' : '#f19002';
    const title = getTitle(componentName, disabled, loading);
    return <div>
      <Tooltip
        classes={{ tooltip: classes.customTooltip }}
        title={title}
        interactive
        placement='bottom-end'
      >
        <div
          style={{
            border: disabled ? '1px solid #c0c0c0' : '1px solid #f19102',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 10,
            padding: '5px 5px',
            backgroundColor: hover ? '#f19102' : disabled ? '#d1d1d1' : '',
            cursor: hover ? 'pointer' : disabled ? 'not-allowed' : '',
            opacity: disabled ? 0.4 : ''
          }}
          onMouseEnter={() => disabled ? () => { } : setHover(true)}
          onMouseLeave={() => disabled ? () => { } : setHover(false)}
          onClick={!disabled && !loading && onClick ? onClick : undefined}
        >
          {componentName === 'Add New' && <AddRounded htmlColor={color} />}
          {componentName === 'Import' && <ExitToAppRounded htmlColor={color} />}
          {componentName === 'Export to Excel' && !loading && <ShareRounded color={color} />}
          {componentName === 'Export to Excel' && loading && <CircularProgress style={{width: '24px', height: '24px', color:color}}/>}
        </div>
      </Tooltip>
    </div>
  }
