import * as R from "ramda";

export interface IPromiseExecutor {
  resolve: () => void;
  reject: () => void
}

export interface IAction<T = any> {
  type: T,
  [key: string]: any
}

export class Action<T> implements IAction<T> {
  type: T;
  constructor(...props) {
    Object.assign(this, ...props);
  }
}

export class PendingAction<T> extends Action<T> {}

export class SuccessAction<T> extends Action<T> {}

export class DownloadCompletedAction<T> extends Action<T> {
  private openLink(fileName, url) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    // hack for IE11
    if (typeof link.remove === "function") {
      link.remove();
    } else link.parentNode.removeChild(link)
  }

  constructor(public fileName, public data) {
    super();

    if (data instanceof Blob) {
      if (window.navigator.msSaveOrOpenBlob)  {
        window.navigator.msSaveBlob(data, fileName);
      } else {
        this.openLink(fileName, window.URL.createObjectURL(data));
      }
    } else {
      this.openLink(fileName, data);
    }
  }
}

export class ErrorAction<T> extends Action<T> {
  constructor(public error?, public ignoreErrorType?) {
    super();
  }
}

export class AzureErrorAction<T> extends ErrorAction<T> {
  error: any;

  constructor(public azureError) {
    super(azureError);

    const errorPath = ['response', 'data'];
    if (R.hasPath(errorPath)(azureError)) {
      const errorDetails: { message: string }[] = R.path(errorPath, azureError);
      if (errorDetails instanceof Array) {
        this.error = errorDetails.map(x => x.message).join("###");
      } else if (R.has('error', errorDetails)) {
        this.error = R.prop('error', errorDetails);
      }
    }
  }
}
