import {configureScope, captureException} from '@sentry/browser';
import {Action, AzureErrorAction, ErrorAction, PendingAction} from "../domains/core/actions";
import {NotificationType, SuccessNotificationAction} from "../domains/core/notifications";

export const actionMiddleware = ({dispatch, getState}) => next => (action) => {
  if (action instanceof Action) {
    if (action instanceof PendingAction) {
      dispatch({type: NotificationType.RESET});
    } else if (action instanceof SuccessNotificationAction) {
      dispatch({...action, type: NotificationType.SUCCESS});
    } else if (action instanceof ErrorAction || action instanceof AzureErrorAction) {
      const currentUser = getState().shared.user.currentUser;

      configureScope(scope => {
        if (currentUser) {
          scope.setUser({
            id: currentUser.id,
            email: currentUser.email,
            companyCode: currentUser.userPreferredCompanyCode,
            companyName: currentUser.userPreferredCompanyName,
            zoneId: currentUser.selectedZoneId,
            zoneName: currentUser.selectedZoneName,
          });
        }
        scope.setTag("action", action.type);
        scope.setExtras(action);
        scope.setFingerprint([window.location.pathname]);
      });

      captureException(new Error(action.error));

      dispatch({...action, source: action.type, type: NotificationType.ERROR});
    }

    return next({...action});
  }
  return next({...action});
};
