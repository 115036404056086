import styled from 'styled-components';
import AppBar from "@material-ui/core/AppBar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export const StyledAppBar = styled(AppBar)`
  background-color: #F19001!important;
`;

export const StyledSidebar = styled.div`
  border-bottom: 1px solid #E0E0E0;
  padding: 0!important;
`;

export const StyledListItem = styled(ListItem)`
  padding-left: 14px!important;
  padding-right: 14px!important;
` as any;

export const StyledListItemIcon = styled(ListItemIcon)`
  && .MuiSvgIcon-root {
    fill: white;
  }
`;

export const StyledApprovePrViaEmailScreen = styled.div`
  display:flex;
  align-items: center;
  width: 100vh;
  height: 100vh;
  justify-content: center;
  min-width: 100%;
`;
