
import styled from "styled-components";


export const StyledDiv = styled.div`
    .MuiButton-textPrimary {
        color: #525576;
        border: 2px solid #9696b5;
        border-radius: 25px;
        padding: 5px 25px 5px 25px;
    }
    .MuiButton-textPrimary:hover {
        background-color: rgb(255 165 0);
        color: white;
        border: none;
    }
    .MuiInputBase-inputMultiline {
        padding: 5px 10px 5px 10px;
    }
`;

export const ApplyStyledDiv = styled.div`
    .MuiButton-textPrimary {
        color: #525576;
        border: 2px solid #9696b5;
        border-radius: 25px;
        padding: 5px 25px 5px 25px;
        font-size: 10px;
    }
    .MuiButton-textPrimary:hover {
        background-color: rgb(255 165 0);
        color: white;
        border: none;
    }
    .MuiInputBase-inputMultiline {
        padding: 5px 10px 5px 10px;
    }
`;




