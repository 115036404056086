import * as React from "react";
import { createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { CreationDateFilter, TextFilter } from "../../../domains/query/filter";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import { useStylesForToolTip } from "../../tooltip/toolTip.styles";
import { prPoTypeLabels } from "src/app/docFlows/containers/dfFilters/prPoType.component";


interface CustomChipDispatcher {
  onDelete: (filter?: TextFilter) => void;
}

interface ChipProps extends CustomChipDispatcher {
  source: TextFilter[];
  minimumItems: number;
  chipClass?: any;
  chipsClass?: any;
  enableDeletion?: boolean;
}
interface CreationDateChipProps {
  source: CreationDateFilter,
  startDate: string;
  endDate: string;
  kind: string;
  onDelete: any;
  enableDeletion: boolean;
  showToolTip? : boolean
}

interface SimpleChipProps extends CustomChipDispatcher {
  value: any;
  deletable: boolean;
}
interface DiversityChipProps {
  diversity: Array<{ code: string, text: string, defined: boolean }>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      height: '28px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    diversityChip: {
      border: "none"
    },
    customTooltip: {
      backgroundColor: "#fff",
      color: '#777777',
      boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)", borderRadius: "10px", maxWidth: "1000px", maxHeight: "500px",
      overflowX: "auto", overflowY: "auto"
    }
  }),
);

export default function Chips(props: ChipProps) {
  const classes = useStyles(props);
  const { source, minimumItems, onDelete, enableDeletion } = props;
  return (
    <div className={classes.chips}>
      {
        source?.map((s, i) => {
          if (source.length > minimumItems || enableDeletion) {
            return <Chip key={i} label={s.title ? s.title : s.id} color="primary" variant="outlined" className={classes.chip}
              onDelete={() => onDelete(s)} />
          } else {
            return <Chip key={i} label={s.title ? s.title : s.id} color="primary" variant="outlined" className={classes.chip} />
          }
        })
      }
    </div>
  )
}
export const PrPoTypeChips = (props: ChipProps) => {
  const classes = useStyles(props);
  const { source, minimumItems, onDelete, enableDeletion } = props;
  return (
    <div className={classes.chips}>
      {
        source?.map((s, i) => {
          return (source.length > minimumItems || enableDeletion) ?
            <Tooltip title={prPoTypeLabels[s.title]}
            interactive
              classes={{ tooltip: classes.customTooltip }}
            ><Chip key={i} label={s.title ? s.title : s.id} color="primary" variant="outlined" className={classes.chip}
              onDelete={() => onDelete(s)} /></Tooltip>
            :
            <Tooltip title={prPoTypeLabels[s.title]}
            interactive
              classes={{ tooltip: classes.customTooltip }}
            ><Chip key={i} label={s.title ? s.title : s.id} color="primary" variant="outlined" className={classes.chip} /></Tooltip>

        })
      }
    </div>
  )
}

export function CreationDateChips(props: CreationDateChipProps) {
  const classes = useStyles(props);
  const classes2 = useStylesForToolTip({})
  const { source,
    startDate,
    endDate,
    kind,
    onDelete,
    enableDeletion, showToolTip } = props;
  return (
    <>
      {enableDeletion ? <Tooltip
        interactive
        classes={{ tooltip: classes2.customTooltip }}
        title={ `${startDate} to ${endDate}`}
          disableHoverListener = {showToolTip ? false :  true}
        >
        <Chip label={source} color="primary" variant="outlined" className={classes.chips} onDelete={() => onDelete(kind)} />
      </Tooltip> :
        <Tooltip
          classes={{ tooltip: classes2.customTooltip }}
          interactive
          title={ `${startDate} to ${endDate}`}
          disableHoverListener = {showToolTip ? false :  true}>
          <Chip label={source} color="primary" variant="outlined" className={classes.chips} />
        </Tooltip>}
    </>
  )
}


export function SimpleChip(props: SimpleChipProps) {
  const classes = useStyles(props);
  const { value, deletable, onDelete } = props;

  return (
    <div className={classes.chips}>
      {
        deletable && <Chip label={value} color="primary" variant="outlined" className={classes.chip}
          onDelete={() => onDelete()} />
      }
      {
        !deletable && <Chip label={value} color="primary" variant="outlined" className={classes.chip} />
      }
    </div>
  )
}
export function DiversityChip(props: DiversityChipProps) {
  const classes = useStyles(props);
  return (
    <div className={classes.chips}>
      {
        props.diversity.map((el, i) => (
          <Tooltip title={el.text}><Chip key={i} className={classes.diversityChip} variant="outlined" icon={el.defined ? <Done style={{ color: 'green', fontSize: ".8rem" }} ></Done> : <Close style={{ color: 'red', fontSize: ".8rem" }}></Close>} label={el.code} >
          </Chip></Tooltip>
        ))
      }
    </div>
  )
}
