import { Dispatch } from "redux";
import { ProcurementApprovalService } from "../domains/purchaseRequest/purchaseRequest.service";
import { ErrorAction, IAction, PendingAction, SuccessAction } from "../../../shared/domains/core/actions";
import { NewPurchaseRequest, PurchaseRequest } from "../domains/purchaseRequest/purchaseRequest";
import { SuccessNotificationAction } from "../../../shared/domains/core/notifications";


export enum ProcurementApprovalActionTypes {
    FETCH_PAS = "FETCH_PAS",
    FETCH_PAS_SUCCESS = "FETCH_PAS_SUCCESS",
    FETCH_PAS_FAILURE = "FETCH_PAS_FAILURE",
    NEW_PAGE_PA = "NEW_PAGE_PA",
    NEW_PAGE = "NEW_PR_PAGE",
    NEW_ORDER_PA = "NEW_ORDER_PA",
    NEW_FILTER_PA = "NEW_FILTER_PA",
    NEW_PA_QUERY = "NEW_PA_QUERY",
    SET_SELECTED_PRS = "SET_SELECTED_PRS",

    FETCH_PAS_DETAIL = "FETCH_PAS_DETAIL",
    FETCH_PAS_DETAIL_SUCCESS = "FETCH_PAS_DETAIL_SUCCESS",
    FETCH_PAS_DETAIL_FAILURE = "FETCH_PAS_DETAIL_FAILURE",

    FETCH_PAS_LINES = "FETCH_PAS_LINES",
    FETCH_PAS_LINES_SUCCESS = "FETCH_PAS_LINES_SUCCESS",
    FETCH_PAS_LINES_FAILURE = "FETCH_PAS_LINES_FAILURE",

    APPLY_PRS_NUMBER = "APPLY_PRS_NUMBER",
    ACTION_FOR_PA = "ACTION_FOR_PA",
    ACTION_FOR_PA_PR_VIA_EMAIL = "ACTION_FOR_PA_PR_VIA_EMAIL",
    ACTION_FOR_PA_PR = "ACTION_FOR_PA_PR",
    ACTION_FOR_PA_SUCCESS = "ACTION_FOR_PA_SUCCESS",
    ACTION_FOR_PA_FAILURE = "ACTION_FOR_PA_FAILURE",
    APPLY_PRS_NUMBER_SUCCESS = "APPLY_PRS_NUMBER_SUCCESS",
    APPLY_PRS_NUMBER_FAILURE = "APPLY_PRS_NUMBER_FAILURE",

    NEW_TERM_PA = "NEW_TERM_PA",

    FETCH_PA_WITH_DEFAULT_ALIYS = "FETCH_PA_WITH_DEFAULT_ALIYS",
    FETCH_PA_WITH_DEFAULT_ALIYS_SUCCESS = "FETCH_PA_WITH_DEFAULT_ALIYS_SUCCESS",
    FETCH_PA_WITH_DEFAULT_ALIYS_FAILURE = "FETCH_PA_WITH_DEFAULT_ALIYS_FAILURE",

    PA_DEFAULT_ALIYS = "PA_DEFAULT_ALIYS",
    PA_DEFAULT_ALIYS_SUCCESS = "PA_DEFAULT_ALIYS_SUCCESS",

}


export class FetchPRs extends PendingAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS;
}

export class FetchPRsSuccess extends SuccessAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_SUCCESS;
}

export class FetchPRsError extends ErrorAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

class SetSelectedPurchaseRequest extends SuccessAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.SET_SELECTED_PRS;
}

export function setSelectedPurchaseRequest(purchaseRequest: PurchaseRequest, dispatch: Dispatch<IAction>) {
    dispatch((new SetSelectedPurchaseRequest({ purchaseRequest: purchaseRequest })));
}


export class FetchPRDetail extends PendingAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_DETAIL;
}

export class FetchPRDetailSuccess extends SuccessAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_DETAIL_SUCCESS;
}

export class FetchPRDetailError extends ErrorAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_DETAIL_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRDetail(purchaseRequest: NewPurchaseRequest, dispatch: Dispatch<IAction>) {
    dispatch((new FetchPRDetail(purchaseRequest)));
}


export class FetchPRLines extends PendingAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_LINES;
}

export class FetchPRLinesSuccess extends SuccessAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_LINES_SUCCESS;
}

export class FetchPRLinesError extends ErrorAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PAS_LINES_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRLines(purchaseRequest: PurchaseRequest, dispatch: Dispatch<IAction>) {
    dispatch((new FetchPRLines(purchaseRequest)));
}

//Approval process for PA Approver
export class ProcessPRForPAAction extends PendingAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.APPLY_PRS_NUMBER;
}

export class PRForPAApprovalActionSuccess extends SuccessNotificationAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.APPLY_PRS_NUMBER_SUCCESS;
    public readonly message = "Purchase Requisition were approved successfully.";
}
export class PRForPARejectionActionSuccess extends SuccessNotificationAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.APPLY_PRS_NUMBER_SUCCESS;
    public readonly message = "Purchase Requisition were rejected successfully.";
}

export class ProcessPRForPAActionError extends ErrorAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.APPLY_PRS_NUMBER_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

//Approval or reject all PA 
export class ProcessAction extends PendingAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.ACTION_FOR_PA;
}

export class PRApproveActionSuccess extends SuccessNotificationAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.ACTION_FOR_PA_SUCCESS;
    public readonly message = "Purchase Requisition(s) were approved successfully.";
}
export class PRRejectActionSuccess extends SuccessNotificationAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.ACTION_FOR_PA_SUCCESS;
    public readonly message = "Purchase Requisition(s) were rejected successfully.";
}

export class ProcessActionError extends ErrorAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.ACTION_FOR_PA_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export class FetchPRsWithdefaultaliys extends PendingAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PA_WITH_DEFAULT_ALIYS;
}

export class FetchPRsWithdefaultaliysSuccess extends SuccessAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PA_WITH_DEFAULT_ALIYS_SUCCESS;
}

export class FetchPRsWithdefaultaliysError extends ErrorAction<ProcurementApprovalActionTypes> {
    public readonly type = ProcurementApprovalActionTypes.FETCH_PA_WITH_DEFAULT_ALIYS_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

///////////

export function processPRForPAAction(newpurchaseRequest: PurchaseRequest, callback, dispatch: Dispatch<IAction>, updatePRService = ProcurementApprovalService.processPRForPA) {
    dispatch(new ProcessPRForPAAction());

    return updatePRService(newpurchaseRequest)
        .then(() => {
            callback('success');
            if (newpurchaseRequest.ApprovalIndicator == 'A') {
                return new PRForPAApprovalActionSuccess(newpurchaseRequest)
            } else {
                return new PRForPARejectionActionSuccess(newpurchaseRequest)
            }
        }).catch((error: any) => {
            callback('failure');
            return new ProcessPRForPAActionError(error)
        })
        .then(dispatch);
}

export function processAction(data: any, callback, indicator, dispatch: Dispatch<IAction>, origin, updatePRService = ProcurementApprovalService.processForPA) {
    dispatch(new ProcessAction());
    return updatePRService(data, origin)
        .then(() => {
            callback('success');

            if (indicator == 'A') {
                return new PRApproveActionSuccess('A')
            } else {
                return new PRRejectActionSuccess('R')
            }
        }).catch((error: any) => {
            callback('failure');
            return new ProcessActionError(error)
        })
        .then(dispatch);
}

