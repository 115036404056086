import { FilterCategoryView } from "src/app/shared/domains/query/filter";
import { DEFAULT_PAGE_SIZE } from "src/app/shared/queryable/query";

export enum FeedbackCockpitFilterKeys {
    CREATED_BY = 'CreatedBy',
    STATUS = 'Status',
    APPLICATION = 'Application',
    CREATED_DATE_RANGE = 'CreatedDateRange',
    VOTE_RANGE = 'VoteRange',
    APPLICATION_RATING = 'ApplicationRating',
    RECOMMENDATION = 'Recommendation',
    FEEDBACK_BY = 'FeedbackBy',
    FEEDBACK_TYPE = 'FeedbackType',
    ADMIN = 'Admin',
    Id = "Id"
}

export const FeedbackCockpitFilters = [
    { key: FeedbackCockpitFilterKeys.CREATED_BY, label: 'Created By' },
    { key: FeedbackCockpitFilterKeys.STATUS, label: 'Status' },
    { key: FeedbackCockpitFilterKeys.APPLICATION, label: 'Application' },
    { key: FeedbackCockpitFilterKeys.CREATED_DATE_RANGE, label: 'Created On' },
    { key: FeedbackCockpitFilterKeys.VOTE_RANGE, label: 'Vote Range' },
    { key: FeedbackCockpitFilterKeys.APPLICATION_RATING, label: 'Rating' },
    { key: FeedbackCockpitFilterKeys.RECOMMENDATION, label: 'Recommendation' },
    { key: FeedbackCockpitFilterKeys.FEEDBACK_BY, label: 'Feedback By' },
    { key: FeedbackCockpitFilterKeys.FEEDBACK_TYPE, label: 'Feedback Type' },
    { key: FeedbackCockpitFilterKeys.ADMIN, label: 'Admin' },
    { key: FeedbackCockpitFilterKeys.Id, label: 'Feedback Id' },
];

export const statusOptions = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "New", label: "New" },
    { value: "Unknown", label: "Unknown" },
    { value: "UnderReview", label: "UnderReview" },
];

export enum FeedbackStatusTypes {
    "Unknown" = 1,
    "New" = 2,
    "Accepted" = 3,
    "Rejected" = 4,
    "Under Review" = 5,
}

export enum FeedbackApprovalTypes {
    "Approve" = 3,
    "Reject" = 4,
    "Under Review" = 5,
}

export const defaultFeedbackCockpitFiltersData = (feedBackId:number | null  = null) => {
    return {
        "CreatedBy": {
            "data": [],
                "key": "CreatedBy",
                    "label": "Created By",
                        "selected": null
        },
        "Id": {
            "data": [],
                "key": "Id",
                    "label": "Feedback Id",
                        "selected": feedBackId
        },
        "Status": {
            "data": [],
                "key": "Status",
                    "label": "Status",
                        "selected": null
        },
        "Application": {
            "data": [],
                "key": "Application",
                    "label": "Application",
                        "selected": null
        },
        "FeedbackBy": {
            "data": [],
                "key": "FeedbackBy",
                    "label": "Feedback By",
                        "selected": null
        },
        "FeedbackType": {
            "data": [],
                "key": "FeedbackType",
                    "label": "Feedback Type",
                        "selected": null
        },
        "Recommendation": {
            "data": [],
                "key": "Recommendation",
                    "label": "Recommendation",
                        "selected": null
        },
        "Admin": {
            "data": [],
                "key": "Admin",
                    "label": "Admin",
                        "selected": null
        },
        "CreatedDateRange": {
            "data": [],
                "key": "CreatedDateRange",
                    "label": "Created Date Range",
                        "selected": null
        },
        "VoteRange": {
            "data": [],
                "key": "VoteRange",
                    "label": "Vote Range",
                        "selected": null
        },
        "ApplicationRating": {
            "data": [],
                "key": "ApplicationRating",
                    "label": "Application Rating",
                        "selected": null
        }
    }
}

export const prepareFilterForBE = (filters: FilterCategoryView[]) => {
    const newFilters: any = {
        CreatedByFlag: filters["CreatedBy"]["selected"] || 0,
        Status: filters["Status"]["selected"]?.map(item => item.value) || [],
        Application: filters["Application"]["selected"]?.map(item => item.value) || [],
        StartDate: filters["CreatedDateRange"]["selected"] ? filters["CreatedDateRange"]["selected"]["startDate"] : null,
        EndDate: filters["CreatedDateRange"]["selected"] ? filters["CreatedDateRange"]["selected"]["endDate"] : null,
        UpperVoteLimit: filters["VoteRange"]["selected"] ? filters["VoteRange"]["selected"][1] : -1,
        LowerVoteLimit: filters["VoteRange"]["selected"] ? filters["VoteRange"]["selected"][0] : -1,
        ApplicationRating: filters["ApplicationRating"]["selected"] || -1,
        Recommendation: filters["Recommendation"]["selected"]?.map(item => item.value) || [],
        FeedbackType: filters["FeedbackType"]["selected"]?.map(item => item.value) || [],
        FeedbackBy: filters["FeedbackBy"]["selected"]?.map(item => item.value) || [],
        Admin: filters["Admin"]["selected"]?.map(item => item.value) || [],
        Id: filters["Id"]["selected"] ? filters["Id"]["selected"] : 0
    };
    return newFilters
}

export const prepareQueryForBE = (filters: FilterCategoryView[], page: number, rows: number, searchTerm: string) => {
    const newQuery: any = {
        page: page,
        orderBy: [],
        filters: prepareFilterForBE(filters),
        loading: false,
        loaded: false,
        term: searchTerm,
        top: DEFAULT_PAGE_SIZE,
        rows: rows
    };
    return newQuery;
}