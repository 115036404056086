import React from "react";
import { EditDetailsTable, PRStyledCell } from "src/app/purchaseRequisition/PR.styles";
import MaterialTable, { Column } from "material-table";
import { TableLoadingComponent } from "src/app/shared/components/loading";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { isNil } from "ramda";
import { PaginationComponent } from "src/app/shared/components/pagination/paginationComponent";
import { useTableStyles } from "src/app/docFlows/components/dataTable/docFlow/docFlowTable.styles";
import { NbsMainteanceTemplateData } from "../interfaces/nbs";
import { hoverDetails, truncate } from "src/app/docFlows/helpers/functions";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#4caf50"
        },
        secondary: {
            main: "#ff9100"
        }
    }
});

type newItemDetailsTableProps = {
    totalCount: number;
    currentPage: number;
    onNewPage: (page: number) => void;
    rowsPerPage: number;
    setRowsPerPage: Function;
    data: any[];
    loading: boolean;
    setSelectedInstructions: Function
};

export const SendInstructionsTable = (props: newItemDetailsTableProps) => {
    const { docFlowTableClass, removeBorderForCheckBox } = useTableStyles({});

    const materialFromPslColumns = (): Column<any>[] => {
        const columns = [
            {
                sorting: false,
                field: 'ParkingBlockingReason',
                title: "Parking/Blocking Reason Code",
                render: (rowData: NbsMainteanceTemplateData) => rowData.parkingBlockingReason.length > 0 ? `${rowData.parkingBlockingReason[0].id.replace(/^0+/, '')} - ${rowData.parkingBlockingReason[0].title}` : "*",
            },
            {
                sorting: false,
                field: "instructions",
                title: "Instructions", 
                render: (rowData: NbsMainteanceTemplateData) => {
                    return <span style={{ cursor: "pointer" }}>{rowData.instructions.length > 35 ? hoverDetails(truncate(rowData.instructions, 35), rowData.instructions) : rowData.instructions}</span>
                }
            },
            {
                sorting: false,
                field: 'PurchasingGroup',
                title: "Purchase Group",
                render: (rowData: NbsMainteanceTemplateData) => rowData.purchasingGroup.length > 0 ? (rowData.purchasingGroup.map(item => item.id)).join(' , ') : "*",
            },
            {
                sorting: false,
                field: 'Plant',
                title: "Plant",
                render: (rowData: NbsMainteanceTemplateData) => rowData.plant.length > 0 ? (rowData.plant.map(item => item.id)).join(' , ') : "*",
            },
            {
                sorting: false,
                field: 'MaterialType',
                title: "Material Type",
                render: (rowData: NbsMainteanceTemplateData) => rowData.materialType.length > 0 ? (rowData.materialType.map(item => item.id)).join(' , ') : "*",
            },
            {
                sorting: false,
                field: 'Receiver',
                title: "Receiver",
                render: (rowData: NbsMainteanceTemplateData) => rowData.receiver.length > 0 ? rowData.receiver[0].title : "*",
            }
        ]
        return columns
    };

    const theColumns = materialFromPslColumns();
    const columnsNotNull = theColumns.filter(el => !isNil(el));

    return <EditDetailsTable style={{ padding: "15px", minWidth: "1000px" }} className={`${docFlowTableClass} ${removeBorderForCheckBox}`}>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                style={{
                    boxShadow: "none",
                    zIndex: 999,
                    // maxHeight: "55vh"
                }}
                isLoading={props.loading}
                columns={columnsNotNull}
                data={props.data}
                components={{
                    OverlayLoading: () => <TableLoadingComponent />,
                    Cell: props => <PRStyledCell {...props} />
                }}
                onSelectionChange={dataClicked => {
                    props.setSelectedInstructions(dataClicked)
                }
                }
                options={{
                    selection: true,
                    paging: false,
                    toolbar: false,
                    rowStyle: {
                        borderBottom: "hidden", // this is to ensure no border is seen under checkbox
                        padding: "10px 5px",
                        fontSize: ".8rem", color: "#7c7777",
                        whiteSpace: "nowrap",
                        height: "60px"
                    },
                    headerStyle: {
                        fontSize: ".8rem", fontWeight: "bold",
                        height: "60px",
                        padding: "10px 5px",
                        whiteSpace: "nowrap",
                        color: "#5D5858",
                        backgroundColor: "#F5F5F5",
                        borderBottom: 'none'
                    },
                }}
            />
                <PaginationComponent
                    count={props.totalCount}
                    currentPage={props.currentPage}
                    onChangePage={props.onNewPage}
                    rowsPerPage={props.rowsPerPage}
                    setRowsPerPage={props.setRowsPerPage}
                    defaultRowsPerPage = {[5,10,20,50]}
                />
        </MuiThemeProvider>
    </EditDetailsTable>
}