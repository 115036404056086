export function arraysConcat(arrayA, arrayB) {
  return Array.from(new Set(arrayA.concat(arrayB).map(item => item.id)))
    .map(id => [...arrayA, ...arrayB].find(item => item.id === id));
}


export function getAllIndexes(arr: any[], key: string ,val: string | number) {
  var indexes = [], i;
  for (i = 0; i < arr.length; i++)
    if (arr[i][key] === val)
      indexes.push(i);
  return indexes;
}