import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import {StyledDialog, StyledDialogContent, StyledDialogTitle} from "../layout/styles";
import {Link} from "react-router-dom";

export const MenuItemBadge = styled(Badge)`
  margin-right: 10px;
`;

export const StyledSettingsDialogTitle = styled(StyledDialogTitle)`
  border-bottom: 1px solid #E0E0E0;
`;

export const StyledSettingsDialogContent = styled(StyledDialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SettingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  .editable {
    padding-left: 10px;
    padding-right: 10px;
    width: 260px;
  }
`;

export const OffSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 1em;
`;

export const StyledSettingsDialog = styled(StyledDialog)`
  && .MuiDialog-paperWidthXs {
    width: 340px;
    padding: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: #000;
  text-decoration: none;
  &:hover {
    color: #000;
  }
`;

export const MenuItemLink = styled.a`
  color: #000;
  text-decoration: none;
  &:hover {
    color: #000;
  }
`;
