import {AzureErrorAction, DownloadCompletedAction, ErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {UserFeedback} from "../domains/feedback";
import {List} from "../../shared/utils/odataClient.wrapper";
import {newQuery, Query} from "../../shared/queryable/query";

export enum FeedbackTypes {
  FETCH_FEEDBACK_LIST = "FETCH_FEEDBACK_LIST",
  FETCH_FEEDBACK_LIST_REQUEST = "FETCH_FEEDBACK_LIST_REQUEST",
  FETCH_FEEDBACK_LIST_REQUEST_SUCCESS = "FETCH_FEEDBACK_LIST_REQUEST_SUCCESS",
  FETCH_FEEDBACK_LIST_REQUEST_FAILURE = "FETCH_FEEDBACK_LIST_REQUEST_FAILURE",

  EXPORT_FEEDBACK_LIST_DATA_SUCCESS_BY_EMAIL = "EXPORT_PSL_LIST_DATA_SUCCESS_BY_EMAIL",
  EXPORT_FEEDBACK_LIST_DATA_FAILURE_BY_EMAIL = "EXPORT_PSL_LIST_DATA_FAILURE_BY_EMAIL",
}

export class FetchFeedbackList extends PendingAction<FeedbackTypes> {
  public readonly type = FeedbackTypes.FETCH_FEEDBACK_LIST;

  constructor(public readonly query: Query = newQuery()) {
    super();
  }
}
export class ExportFeedbackListDataSuccess extends DownloadCompletedAction<FeedbackTypes> {
  public readonly type = FeedbackTypes.EXPORT_FEEDBACK_LIST_DATA_SUCCESS_BY_EMAIL;
}
export class ExportFeedbackListDataEmailError extends ErrorAction<FeedbackTypes> {
  public readonly type = FeedbackTypes.EXPORT_FEEDBACK_LIST_DATA_FAILURE_BY_EMAIL;
}
export class FetchFeedbackListRequest extends PendingAction<FeedbackTypes> {
  public readonly type = FeedbackTypes.FETCH_FEEDBACK_LIST_REQUEST;
}

export class FetchFeedbackListRequestSuccess extends SuccessAction<FeedbackTypes> {
  public readonly type = FeedbackTypes.FETCH_FEEDBACK_LIST_REQUEST_SUCCESS;

  constructor(public payload: List<UserFeedback>) {
    super();
  }
}

export class FetchFeedbackListRequestError extends AzureErrorAction<FeedbackTypes> {
  public readonly type = FeedbackTypes.FETCH_FEEDBACK_LIST_REQUEST_FAILURE;
}

