import {PendingAction, SuccessAction, ErrorAction} from "../../shared/domains/core/actions";

export enum PersonalNoteActions {
    GET_PERSONAL_NOTE_REQUEST = "GET_PERSONAL_NOTE_REQUEST",
    GET_PERSONAL_NOTE_REQUEST_SUCCESS = "GET_PERSONAL_NOTE_REQUEST_SUCCESS",
    GET_PERSONAL_NOTE_REQUEST_FAILURE = "GET_PERSONAL_NOTE_REQUEST_FAILURE",
    SAVE_PERSONAL_NOTE_REQUEST = "SAVE_PERSONAL_NOTE_REQUEST",
    SAVE_PERSONAL_NOTE_REQUEST_SUCCESS = "SAVE_PERSONAL_NOTE_REQUEST_SUCCESS",
    SAVE_PERSONAL_NOTE_REQUEST_FAILURE = "SAVE_PERSONAL_NOTE_REQUEST_FAILURE",
}

export class GetPersonalNoteRequest extends PendingAction<PersonalNoteActions> {
    public readonly type = PersonalNoteActions.GET_PERSONAL_NOTE_REQUEST;

    constructor(public prNo: string,
                public poNo: string,
                public systemAlias: string) {
        super();
    }
}

export class GetPersonalNoteRequestSuccess extends SuccessAction<PersonalNoteActions> {
    public readonly type = PersonalNoteActions.GET_PERSONAL_NOTE_REQUEST_SUCCESS;

    constructor(public prNo: string,
                public poNo: string,
                public systemAlias: string,
                public note: string) {
        super();
    }
}

export class GetPersonalNoteRequestError extends ErrorAction<PersonalNoteActions> {
    public readonly type = PersonalNoteActions.GET_PERSONAL_NOTE_REQUEST_FAILURE;

    constructor(public prNo: string,
                public poNo: string,
                public systemAlias: string) {
        super();
    }
}

export class SavePersonalNoteRequest extends PendingAction<PersonalNoteActions> {
    public readonly type = PersonalNoteActions.SAVE_PERSONAL_NOTE_REQUEST;

    constructor(public prNo: string,
                public poNo: string,
                public systemAlias: string,
                public note: string) {
        super();
    }
}

export class SavePersonalNoteRequestSuccess extends SuccessAction<PersonalNoteActions> {
    public readonly type = PersonalNoteActions.SAVE_PERSONAL_NOTE_REQUEST_SUCCESS;

    constructor(public prNo: string,
                public poNo: string,
                public systemAlias: string,
                public note: string) {
        super();
    }
}

export class SavePersonalNoteRequestError extends ErrorAction<PersonalNoteActions> {
    public readonly type = PersonalNoteActions.SAVE_PERSONAL_NOTE_REQUEST_FAILURE;

    constructor(public prNo: string,
                public poNo: string,
                public systemAlias: string) {
        super();
    }
}
