import React, { useReducer, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {
  MenuItemBadge,
  MenuItemLink,
  OffSpan,
  SettingRow,
  StyledLink,
  StyledSettingsDialog,
  StyledSettingsDialogContent,
  StyledSettingsDialogTitle
} from "./menu.styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EventIcon from '@material-ui/icons/Event';
import TimerIcon from '@material-ui/icons/Timer';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BarChartIcon from '@material-ui/icons/BarChart';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import { StyledDialogActions } from "../layout/styles";
import { DateTime } from "luxon";
import { IConfigurableSettings, User } from "../../domains/user/user";
import { UserSettingsPopup } from "../../containers/dashboard/components/userSettings/userSettingsPopup";
import { AuthenticationService } from "../../domains/auth/authentication.service";
import { FeedbackPopup } from "../../containers/dashboard/components/feedback/feedback.component";
import { TimeZonesList } from "./zones";
import { UI_MODULE } from "../../domains/core/ui";
import { RootStateStore } from "../../../application.reducers";
import { useQuery } from "react-query";
import { MrpService } from "../../../mrp/domains/mrp.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { useFeatureFlag } from "../../domains/core/pataflag.context";
import { Features } from "../../domains/core/pataflag";
import { AddCompCodePopUp } from "../../containers/dashboard/components/addCompanyCode/addCompanyCode.component";
import { DocumentFlowService } from "../../../docFlows/domains/documentFlow/documentFlow.service";
import { UserInteractionType } from "../../domains/user/userInteractionType";
import { NbsCockpitIcon } from "src/app/landing/containers/icons";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250,
    },
  },
};

function SettingsDialog({ currentUser, open, onClose, onApply }) {
  const [formats, setFormats] = useReducer((state, newState) => ({ ...state, ...newState }), {
    dateTimeFormat: currentUser.profileSettings.dateTimeFormat,
    timeZone: currentUser.profileSettings.timeZone,
    decimalNotation: currentUser.profileSettings.decimalNotation
  });
  const [error, setError] = useState(false);

  function handleFormatChange(format) {
    setFormats({ dateTimeFormat: format });
    setError(!format);
  }

  function handleNotationChange(format) {
    setFormats({ decimalNotation: format });
    setError(!format);
  }

  function handleApply() {
    if (!error) {
      onApply({
        ...currentUser.profileSettings,
        selectedZoneId: currentUser.selectedZoneId,
        userPreferredCompanyCode: currentUser.userPreferredCompanyCode,
        userPreferredCompanyName: currentUser.userPreferredCompanyName,
        dateTimeFormat: formats.dateTimeFormat,
        timeZone: formats.timeZone,
        decimalNotation: formats.decimalNotation,
        userPreferredPslPersona: currentUser.userPreferredPslPersona,
        pslExpiryDays: currentUser.pslExpiryDays
      });
    }
  }

  return (
    <StyledSettingsDialog open={open} maxWidth={"xs"} fullWidth={true}>
      <StyledSettingsDialogTitle>Settings</StyledSettingsDialogTitle>
      <StyledSettingsDialogContent>
        <SettingRow><EventIcon />
          <TextField
            error={error}
            placeholder="Set Date Format"
            value={formats.dateTimeFormat}
            className={'editable'}
            onChange={e => handleFormatChange(e.target.value)}
          />
        </SettingRow>
        <OffSpan>{DateTime.local().toFormat(formats.dateTimeFormat)}</OffSpan>
        <SettingRow><TimerIcon />
          <Select
            MenuProps={MenuProps}
            value={formats.timeZone}
            onChange={(e) => setFormats({ timeZone: e.target.value })}
            className={'editable'}
            placeholder="Set Time Zone">
            {
              TimeZonesList.map(zone => <MenuItem key={zone} value={zone}>{zone}</MenuItem>)
            }
          </Select>
        </SettingRow>
        <OffSpan>{DateTime.local().toFormat(formats.dateTimeFormat)}</OffSpan>
        <SettingRow><LinearScaleIcon />
          <Select
            MenuProps={MenuProps}
            value={formats.decimalNotation}
            onChange={(e) => handleNotationChange(e.target.value)}
            className={'editable'}
            placeholder="Set decimal notations">
            <MenuItem value="comma_thousand_separator">{'Separator (123,456.78)'}</MenuItem>
            <MenuItem value="point_thousand_separator">{'Separator (123.456,78)'}</MenuItem>
            <MenuItem value="space_thousand_separator">{'Separator (123 456,78)'}</MenuItem>
          </Select>
        </SettingRow>
      </StyledSettingsDialogContent>
      <StyledDialogActions>
        <Button color="primary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleApply}>Apply</Button>
      </StyledDialogActions>
    </StyledSettingsDialog>
  )
}

function AdminMenu({ onClose }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleManageFeedbacks = () => {
    setAnchorEl(null);
    onClose();
  };
  return (
    <>
      <MenuItem onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MenuItemBadge>
          <AccountCircle />
        </MenuItemBadge>
        <span>Administrator</span>
        <ArrowRightIcon />
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}>
        <StyledLink to='/admin/feedback-report'>
          <MenuItem onClick={handleManageFeedbacks}>
            <MenuItemBadge color="secondary">
              <FeedbackIcon />
            </MenuItemBadge>
            <span>Manage feedbacks</span>
          </MenuItem>
        </StyledLink>
      </Menu>
    </>
  )
}

interface IUserSettingsMenuProps {
  showFeedback: boolean;
  isAdmin: boolean;
  currentUser: User;
  open: boolean;
  anchorEl: any;
  onClose: any;
  onApply: (data: IConfigurableSettings, done: any) => {};
}

export default function UserSettingsMenu(props: IUserSettingsMenuProps) {
  const { hasFeature } = useFeatureFlag();
  const [showSetting, setShowSetting] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [showAddNewCompCode, setShowAddNewCompCode] = React.useState(false);
  const [showFeedback, setShowFeedback] = React.useState(false);
  const module: UI_MODULE = useSelector((state: RootStateStore) => state.shared.ui.module);
  const hasMaintenance = props.currentUser.hasMaintenance();
  const isOperationalBuyer = hasFeature(Features.SHOW_NBS_FOR_OP_BUYER_ONLY) ? props.currentUser.operationalBuyer : true;

  const { isLoading, refetch } = useQuery("companyCodes", () => MrpService.refreshMrpData(), { cacheTime: 0, enabled: false })

  function handleSetting() {
    props.onClose();
    setShowSetting(true);
  }

  async function handleProfile() {
    props.onClose();
    await DocumentFlowService.logUserInteractionData(UserInteractionType.ViewProfile);
    setShowProfile(true);
  }
  function handleAddNewCompCode() {
    props.onClose();
    setShowAddNewCompCode(true);
  }
  function handleSaveSetting(data: IConfigurableSettings) {
    props.onApply(data, () => setShowSetting(false));
    setTimeout(() => window.location.reload(), 1000)
  }

  function handleFeedback() {
    props.onClose();
    setShowFeedback(true);
  }

  function handlefeedBackReport() {
    props.onClose();
  }

  function handlenNbsMainteance() {
    props.onClose();
  }

  function handleLocalitzation() {
    props.onClose();
  }

  function handleRefreshCompanies() {
    if (isLoading) return
    refetch().then(value => {
      console.log("done", value)
      window.location.reload()
    })
  }

  function handleSignout() {
    props.onClose();
    AuthenticationService.Signout();
  }

  return (
    <>
      <Menu anchorEl={props.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.open}
        onClose={props.onClose}>
        {
          module !== UI_MODULE.DASHBOARD && <MenuItem onClick={handleProfile}>
            <MenuItemBadge color="secondary">
              <AccountCircle />
            </MenuItemBadge>
            <span>My Profile</span>
          </MenuItem>
        }
        {
          module !== UI_MODULE.DASHBOARD && <MenuItem onClick={handleSetting}>
            <MenuItemBadge>
              <SettingsIcon />
            </MenuItemBadge>
            <span>Settings</span>
          </MenuItem>
        }
        {
          module !== UI_MODULE.DASHBOARD && <MenuItem onClick={handleFeedback}>
            <MenuItemBadge>
              <FeedbackIcon />
            </MenuItemBadge>
            <span>Share Your Feedback</span>
          </MenuItem>
        }
        {
          hasFeature(Features.FEEDBACK_COCKPIT_SHARE_FEEDBACK) &&
          module !== UI_MODULE.DASHBOARD && <MenuItem onClick={handlefeedBackReport}>
            <MenuItemBadge>
              <AddCircleOutlineIcon />
            </MenuItemBadge>
            <Link to="/feedback-report" style={{ color: "rgba(0, 0, 0, 0.87)" }} >
              <span>Feedback & Report</span>
            </Link>
          </MenuItem>
        }
        {
          hasFeature(Features.NBS_165964_MAINTEANCE_PAGE) &&
          module !== UI_MODULE.DASHBOARD && isOperationalBuyer && <MenuItem onClick={handlenNbsMainteance}>
            <MenuItemBadge>
              <NbsCockpitIcon fill={"black"} width="25px" height="25px" />
            </MenuItemBadge>
            <Link to="/nbs-cockpit/maintenance" style={{ color: "rgba(0, 0, 0, 0.87)" }} >
              <span>NBS Maintenance</span>
            </Link>
          </MenuItem>
        }
        {
          hasMaintenance && <MenuItem onClick={handleLocalitzation}>
            <MenuItemBadge color="secondary">
              <SettingsIcon />
            </MenuItemBadge>
            <Link to="/psl/maintenance" style={{ color: "rgba(0, 0, 0, 0.87)" }} ><span>Maintenance</span></Link>

          </MenuItem>
        }
        {
          <MenuItem onClick={handleRefreshCompanies}>
            <MenuItemBadge>
              {isLoading ? <CircularProgress style={{ width: 24, height: 24 }} /> : <RefreshIcon />}
            </MenuItemBadge>
            <span>Refresh User Profiles</span>
          </MenuItem>
        }
        {
          hasFeature(Features.NEW_COMPANY_CODE) && module !== UI_MODULE.DASHBOARD && <MenuItem onClick={handleAddNewCompCode}>
            <MenuItemBadge color="secondary">
              <AddCircleOutlineIcon />
            </MenuItemBadge>
            <span>Add New Company Code</span>
          </MenuItem>
        }
        <MenuItemLink target={"_blank"} href={"https://app.powerbi.com/groups/me/reports/fc892669-26d6-4d52-898c-26eaa33d4d76/ReportSectionfe9d665c45cb33e0b872"}>
          <MenuItem onClick={props.onClose}>
            <MenuItemBadge>
              <BarChartIcon />
            </MenuItemBadge>
            <span>Report</span>
          </MenuItem>
        </MenuItemLink>
        <MenuItemLink target={"_blank"} href={"https://digital-leap.nestle.com/s2p/en-us/Home.htm"}>
          <MenuItem onClick={props.onClose}>
            <MenuItemBadge>
              <HelpIcon />
            </MenuItemBadge>
            <span>Help</span>
          </MenuItem>
        </MenuItemLink>
        {
          props.isAdmin && module !== UI_MODULE.DASHBOARD && <AdminMenu onClose={props.onClose} />
        }
        <Divider />
        <MenuItem onClick={handleSignout}>
          <MenuItemBadge>
            <ExitToAppIcon />
          </MenuItemBadge>
          <span>Logout</span>
        </MenuItem>
      </Menu>
      {
        showSetting && <SettingsDialog {...props} open={showSetting} onClose={() => setShowSetting(false)} onApply={handleSaveSetting} />
      }
      {
        showProfile && <UserSettingsPopup onClose={() => setShowProfile(false)} />
      }
      {
        showFeedback && <FeedbackPopup onClose={() => setShowFeedback(false)} />
      }
      {
        showAddNewCompCode && <AddCompCodePopUp onClose={() => setShowAddNewCompCode(false)} />
      }
    </>
  )
};

