export interface NetworkPRDTO {
    __metadata: { id: string; type: string; uri: string };
    ItemNo: string,
    Bukrs: string,
    Ekorg: string,
    ApprovalType: string,
    Frgsx: string,
    Frggr: string,
    Frgco: string,
    Frgkx: string,
    ApprovalInd: string,
    Ebeln: string,
    Banfn: string,
    Werks: string,
    Ekgrp: string,
    Comments: string,
    ApprovedBy: string,
    ApprovedDate: string,
    ApprovedTime: string,
    UpdatedInArb: string,
    Preis: string,
    Peinh: string,
    Waers: string,
    MailSent: string,
    MailTime: string,
    MailDate: string,
    ApprovalIndicator: string,
    SapPR: string,
    CompCode: string,
    Counter: string,
    CostObject: string,
    Active: string,
    AppType: string,
    CoPg: string,
    Delegator: string,
    SubEDate: string,
    SubSDtae: string,
    Substitutee: string,
    SystemAlias:string,
    UserID: string,
    FN3: string,
    FV1: string,
    FV2: string,
    FV4: string,
    Position: string,
    Query: string,
    Type: string,
    User: string
}


export enum SAPBoolean {
    TRUE = "X",
    FALSE = "",
}

export class PRSubstitutionRequest {
    constructor(
        public readonly ItemNo: string,
        public readonly Bukrs: string,
        public readonly Ekorg: string,
        public readonly ApprovalType: string,
        public readonly Frgsx: string,
        public readonly Frggr: string,
        public readonly Frgco: string,
        public readonly Frgkx: string,
        public readonly ApprovalInd: string,
        public readonly Ebeln: string,
        public readonly Banfn: string,
        public readonly Werks: string,
        public readonly Ekgrp: string,
        public readonly Comments: string,
        public readonly ApprovedBy: string,
        public readonly ApprovedDate: string,
        public readonly ApprovedTime: string,
        public readonly UpdatedInArb: string,
        public readonly Preis: string,
        public readonly Peinh: string,
        public readonly Waers: string,
        public readonly MailSent: string,
        public readonly MailTime: string,
        public readonly MailDate: string,
        public readonly ApprovalIndicator: string,
        public readonly SapPR: string,
        public readonly CompCode: string,
        public readonly Counter: string,
        public readonly CostObject: string,
        public readonly Active: string,
        public readonly AppType: string,
        public readonly CoPg: string,
        public readonly Delegator: string,
        public readonly SubEDate: string,
        public readonly SubSDtae: string,
        public readonly Substitutee: string,
        public readonly SystemAlias:string,
        public readonly UserID: string,
        public readonly FN3: string,
        public readonly FV1: string,
        public readonly FV2: string,
        public readonly FV4: string,
        public readonly Position: string,
        public readonly Query: string,
        public readonly Type: string,
        public readonly User: string
    ) { }

    static FromBackend(data: NetworkPRDTO): PRSubstitutionRequest {
        return new PRSubstitutionRequest(
            data.ItemNo,
            data.Bukrs,
            data.Ekorg,
            data.ApprovalType,
            data.Frgsx,
            data.Frggr,
            data.Frgco,
            data.Frgkx,
            data.ApprovalInd,
            data.Ebeln,
            data.Banfn,
            data.Werks,
            data.Ekgrp,
            data.Comments,
            data.ApprovedBy,
            data.ApprovedDate,
            data.ApprovedTime,
            data.UpdatedInArb,
            data.Preis,
            data.Peinh,
            data.Waers,
            data.MailSent,
            data.MailTime,
            data.MailDate,
            data.ApprovalIndicator,
            data.SapPR,
            data.CompCode,
            data.Counter,
            data.CostObject,
            data.Active,
            data.AppType,
            data.CoPg,
            data.Delegator,
            data.SubEDate,
            data.SubSDtae,
            data.Substitutee,
            data.SystemAlias,
            data.UserID,
            data.FN3,
            data.FV1,
            data.FV2,
            data.FV4,
            data.Position,
            data.Query,
            data.Type,
            data.User
        )
    }
}
