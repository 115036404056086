import {all, fork, put, select, takeLatest} from "@redux-saga/core/effects";
import {
  deleteFilter,
  filtersLoadError,
  filtersLoadSuccess,
  newFilterAction,
  NewFiltersAction,
  newFiltersAction,
  newOrderAction,
  newPageAction,
  resetFiltersAction,
  newTermAction,
  QueryActions,
  resetFiltersForKeyAction, WithListTypeAction, newPageSizeAction, setDefaultDocQueryAction, newPageRowAction,
} from "./query.actions";
import {
  setSelectedFilter,
  deleteSelectedFilter,
  newPage,
  Query,
  resetFiltersForKey,
  resetFiltersForQuery,
  updateFilters,
  updateOrder,
  updateTerm, 
  updateTermI,
  newPageSize,
  newPageRows,
} from "./query";
import * as R from "ramda";
import {QueryableSagasActions} from "./query.reducer";
import { SupplierTabs } from "src/app/preferredSuppliers/domains/psl";

export function updatePageListener<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const domainState: Query = yield select((state: T) => domainStateSelector(state));
    const newQuery = newPage(domainState, action.newPage);
    yield put({type: newQueryAction, query: newQuery, list: action.list});
  }
}

export function updatePageRowsListener<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const domainState: Query = yield select((state: T) => domainStateSelector(state));
    const newQuery = newPageRows(domainState, action.newRows);
    yield put({type: newQueryAction, query: newQuery, list: action.list});
  }
}

export function updatePageSizeListener<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const domainState: Query = yield select((state: T) => domainStateSelector(state));
    const newQuery = newPageSize(domainState, action.newPageSize);
    yield put({type: newQueryAction, query: newQuery, list: action.list});
  }
}

export function updateOrderListener<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const domainState: Query = yield select((state: T) => domainStateSelector(state));
    const newQuery = updateOrder(domainState, action.column, action.direction);
    yield put({type: newQueryAction, query: newQuery, list: action.list});
  }
}

export function updateFiltersListener<T>(domainStateSelector: any, newQueryAction: string, saveFiltersAction: string) {
  return function* (action: NewFiltersAction) {
    const domainState: Query = yield select((state: T) => domainStateSelector(state));
    const newQuery = updateFilters(domainState, action.filters);
    yield all([
      put({type: newQueryAction, query: newQuery, list: action.list}),
      action.skipPersist ? null : put({type: saveFiltersAction, filters: action.filters, list: action.list})
    ]);
  }
}

export function resetFilters<T>(domainStateSelector: any, newQueryAction: string, saveFiltersAction: string) {
  return function* (action: WithListTypeAction) {
    const query: Query = yield select((state: T) => domainStateSelector(state));
    const filtersReset = resetFiltersForQuery(query);
    yield all([
      put({type: newQueryAction, query: filtersReset, list: action.list}),
      put({type: saveFiltersAction, filters: filtersReset.filters})
    ]);

  }
}

export function updateFiltersWithNewFilter<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const query: Query = yield select((state: T) => domainStateSelector(state));
    const filtersReset = setSelectedFilter(query, action.filter, action.key);
    yield put({type: newQueryAction, query: filtersReset});
  }
}

export function runResetFiltersForKey<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const query: Query = yield select((state: T) => domainStateSelector(state));
    const filtersReset = resetFiltersForKey(query, action.key);
    yield put({type: newQueryAction, query: filtersReset});
  }
}

export function deleteFilterListener<T>(domainStateSelector: any, newQueryAction: string, saveFiltersAction: string) {
  return function* (action) {
    const query: Query = yield select((state: T) => domainStateSelector(state));
    const filtersDelete = deleteSelectedFilter(query, action.filter);
    // Ln: 110 -> deleteSelectedFilter function deletes the property where ID match, [EX: businessUnits id and PslStatuses id same]
    // So PslStatuses also deleted while calling this function only in My Task tab. sln bellow...
    if(action?.list === SupplierTabs.MY_TASKS) filtersDelete.filters.PslStatuses.selected = query.filters.PslStatuses.selected;
    yield all([
      put({type: newQueryAction, query: filtersDelete, list: action.list}),
      put({type: saveFiltersAction, filters: filtersDelete.filters, list: action.list})
    ]);
  }
}

export function updateTermListener<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const domainState: Query = yield select((state: T) => domainStateSelector(state));
    const newQuery = updateTerm(domainState, action.term);
    yield put({type: newQueryAction, query: newQuery, list: action.list});
  }
}

export function updateTermListenerI<T>(domainStateSelector: any, newQueryAction: string) {
  return function* (action) {
    const domainState: Query = yield select((state: T) => domainStateSelector(state));
    const newQuery = updateTermI(domainState, action.term);
    yield put({type: newQueryAction, query: newQuery, list: action.list});
  }
}

export function sagaBuilder<T>(stateSelector: string[], domainAction: (action: QueryActions) => string): QueryableSagasActions {
  const domainStateSelector = R.path([...stateSelector, 'query']);
  const newQueryAction = domainAction(QueryActions.NEW_QUERY);
  const saveFiltersAction = domainAction(QueryActions.SAVE_FILTERS);

  function* watchNewPage() {
    const action = domainAction(QueryActions.NEW_PAGE);
    yield takeLatest(action, updatePageListener<T>(domainStateSelector, newQueryAction))
  }

  function* watchNewRowsPage() {
    const action = domainAction(QueryActions.NEW_ROWS);
    yield takeLatest(action, updatePageRowsListener<T>(domainStateSelector, newQueryAction))
  }

  function* watchNewPageSize() {
    const action = domainAction(QueryActions.NEW_PAGE_SIZE);
    yield takeLatest(action, updatePageSizeListener<T>(domainStateSelector, newQueryAction))
  }

  function* watchNewOrder() {
    const action = domainAction(QueryActions.NEW_ORDER);
    yield takeLatest(action, updateOrderListener<T>(domainStateSelector, newQueryAction))
  }

  function* watchNewFilters() {
    const action = domainAction(QueryActions.NEW_FILTERS);
    yield takeLatest(action, updateFiltersListener<T>(domainStateSelector, newQueryAction, saveFiltersAction))
  }

  function* watchResetFilters() {
    const action = domainAction(QueryActions.RESET_FILTERS);
    yield takeLatest(action, resetFilters<T>(domainStateSelector, newQueryAction, saveFiltersAction))
  }

  function* watchResetFiltersForKey() {
    const action = domainAction(QueryActions.RESET_FILTERS_FOR_KEY);
    yield takeLatest(action, runResetFiltersForKey<T>(domainStateSelector, newQueryAction))
  }

  function* watchNewFilter() {
    const action = domainAction(QueryActions.NEW_FILTER);
    yield takeLatest(action, updateFiltersWithNewFilter<T>(domainStateSelector, newQueryAction))
  }

  function* watchDeleteFilter() {
    const action = domainAction(QueryActions.DELETE_FILTER);
    yield takeLatest(action, deleteFilterListener<T>(domainStateSelector, newQueryAction, saveFiltersAction))
  }

  function* watchNewTerm() {
    const action = domainAction(QueryActions.NEW_TERM);
    yield takeLatest(action, updateTermListener<T>(domainStateSelector, newQueryAction))
  }

  function* watchNewTermI() {
    const action = domainAction(QueryActions.NEW_TERM);
    yield takeLatest(action, updateTermListenerI<T>(domainStateSelector, newQueryAction))
  }

  return {
    actions: {
      resetFilters: resetFiltersAction(domainAction),
      newPage: newPageAction(domainAction),
      newRowsPerPage: newPageRowAction(domainAction),
      newPageSize: newPageSizeAction(domainAction),
      newOrder: newOrderAction(domainAction),
      newFilters: newFiltersAction(domainAction),
      newFilter: newFilterAction(domainAction),
      resetFiltersForKey: resetFiltersForKeyAction(domainAction),
      newTerm: newTermAction(domainAction),
      onDeleteFilter: deleteFilter(domainAction),
      onLoadFilters: domainAction(QueryActions.LOAD_FILTERS),
      onLoadFiltersSuccess: filtersLoadSuccess(domainAction),
      onLoadFiltersError: filtersLoadError(domainAction),
      onNewQuery: newQueryAction,
      onSaveFilters: domainAction(QueryActions.SAVE_FILTERS),
      setDefaultDocQuery: setDefaultDocQueryAction(domainAction)
    },
    sagas: function* () {
      yield all([
        fork(watchNewPage),
        fork(watchNewRowsPage),
        fork(watchNewPageSize),
        fork(watchNewOrder),
        fork(watchNewFilters),
        fork(watchNewTerm),
        fork(watchNewTermI),
        fork(watchResetFilters),
        fork(watchDeleteFilter),
        fork(watchNewFilter),
        fork(watchResetFiltersForKey),
      ])
    }
  }
}
