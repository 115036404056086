class NetworkCurrencyDTO {
  __metadata: { id: string; type: string; uri: string };
  CurrencyKey: string;
  Description: string;
}

export class CurrencyData {
  constructor(public CurrencyKey: string,
              public Description: string) {
  }

  static FromBackend(data: NetworkCurrencyDTO): CurrencyData {
    return new CurrencyData(
      data.CurrencyKey,
      data.Description
    )
  }

  static toSelect(data: CurrencyData) {
    return {
      value: data.CurrencyKey,
      label: data.CurrencyKey
    }
  }

  static toSimpleSelect() {
    return [
      {value: "USD", label: "USD"},
      {value: "EUR", label: "EUR"},
      {value: "CHF", label: "CHF"}
    ]
  }
}
