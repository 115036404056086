import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { procurementApprovalsStateStore } from "../reducers";
import { ProcurementApprovalService } from "../domains/purchaseRequest/purchaseRequest.service";
import * as Sentry from "@sentry/browser";
import {
  FetchPRDetailSuccess,
  FetchPRDetailError,
  FetchPRs,
  FetchPRsError,
  FetchPRsSuccess,
  ProcurementApprovalActionTypes,
  FetchPRLinesSuccess,
  FetchPRLinesError,
  PRForPAApprovalActionSuccess,
  PRForPARejectionActionSuccess,
  ProcessPRForPAActionError
} from "../actions/purchaseRequest.action";
import { PAActionTypes, ResetPAFiltersSuccess } from "../actions/paFilters";
import {
  newFilters,
  updatePAFilters,
  newPage,
  updateTerm,
  newResource,
  purchaseRequestQuery,
  updateOrder
} from "../domains/purchaseRequest/query";
import { SaveProcurementApprovalFilters } from "src/app/shared/actions/user.actions";
import {UserInteractionType} from "../../../shared/domains/user/userInteractionType";
import {DocumentFlowService} from "../../../docFlows/domains/documentFlow/documentFlow.service";

export function* fetch(action: any): any {
  try {
    let newQuery = action.query;

    if (!action.query) {
      let query = purchaseRequestQuery();
      newQuery = newResource(query, action.resource);
      if (!action.filters.companies.length) {
        action.filters.companies.push({
          id: action.companyCode,
          title: action.companyCodeName
        });
      }
      newQuery = updatePAFilters(newQuery, action.filters, [
        { field: "AgentID", value: action.positionId },
        { field: "DisplayType", value: "H" }
      ]);
    }
    const result = yield call(
      ProcurementApprovalService.fetch,
      newQuery,
      action.alias
    );
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
  }
}

export function* fetchDetail(action: any): any {
  const query = {
    AribaPR: action.AribaPR,
    SapPR: action.SapPR,
    ItemNo: action.ItemNo,
    CompCode: action.CompCode,
    PurchOrg: action.PurchOrg,
    RelGroup: action.RelGroup,
    RelStrategy: action.RelStrategy,
    ApprovalLevel: action.ApprovalLevel,
    SAP__Origin: action.SAP__Origin,
    AgentID: action.positionId
  };

  try {
    const { result, result1, result2, result3 } = yield all({
      result: call(ProcurementApprovalService.fetchDetail, query),
      result1: call(ProcurementApprovalService.fetchPricing, query),
      result2: call(ProcurementApprovalService.fetchApprovalIndicator, query),
      result3: call(ProcurementApprovalService.fetchLines, query)
    });
    // const result = yield call(ProcurementApprovalService.fetchDetail, query);
    // const result2 = yield call(ProcurementApprovalService.fetchPricing, query);
    // const result3 = yield call(
    //   ProcurementApprovalService.fetchApprovalIndicator,
    //   query
    // );
    yield put(
      new FetchPRDetailSuccess({
        info: result,
        pricing: result1,
        approvalIndicator: result2,
        prLineList: result3.data
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRDetailError({ error: error, message: error.message }));
  }
}

/**Not Using this function */
export function* fetchLines(action: any): any {
  const query = [
    { field: "SapPR", value: action.SapPR }, // '0435690555'},//action.SapPR },
    //{ field: "ItemNo", value: action.ItemNo }, //'00010' }
    { field: "CompCode", value: action.CompCode },
    { field: "AribaPR", value: action.AribaPR }
    //{ field: "AgentID", value: action.positionId }
  ];

  try {
    const result = yield call(ProcurementApprovalService.fetchLines, action);
    yield put(new FetchPRLinesSuccess({ prLineList: result.data }));
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRLinesError({ error: error, message: error.message }));
  }
}

function* newPAFilter(action: any) {
  try {
    let query = purchaseRequestQuery();
    let newQuery = newResource(query, "PRApproval_PAs");
    // newQuery = updatePAFilters(newQuery, action.filters, [
    //   { field: "AgentID", value: action.positionId },
    //   { field: "DisplayType", value: "H" }
    // ]);

    let oldQuery = [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ];

    if (action?.filters?.historic) {
      // oldQuery = [...oldQuery, { field: "History", value: "X" }]
      oldQuery = oldQuery.concat({ field: "History", value: "X" });
    }
    newQuery = updatePAFilters(newQuery, action.filters, oldQuery);

    const result = yield call(
      ProcurementApprovalService.fetch,
      newQuery,
      action.alias
    );
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
    yield put({ type: PAActionTypes.UPDATE_PA_FILTER_SUCCESS });
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    yield put({ type: PAActionTypes.UPDATE_PA_FILTER_FAILURE });
  }
}

function* deletePAFilter(action: any) {
  try {
    let query = purchaseRequestQuery();
    let newQuery = newResource(query, "PRApproval_PAs");
    newQuery = updatePAFilters(newQuery, action.filters, [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ]);
    const result = yield call(
      ProcurementApprovalService.fetch,
      newQuery,
      action.alias
    );
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
    yield put({ type: PAActionTypes.UPDATE_PA_FILTER_SUCCESS });
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    yield put({ type: PAActionTypes.UPDATE_PA_FILTER_FAILURE });
  }
}

function* resetPAFilter(action: any) {
  try {
    let query = purchaseRequestQuery();
    let newQuery = newResource(query, "PRApproval_PAs");
    let filters = {
      companies: [{ id: action.prefferedCode, title: action.companyCodeName }],
      purchaseReqNo: [],
      aribaPr : [],
      prCreatorName : [],
      supplier : [],
      purchGroup : []
    };
    let post = updatePAFilters(newQuery, filters, [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ]);
    const result = yield call(
      ProcurementApprovalService.fetch,
      post,
      action.alias
    );
    yield put(new ResetPAFiltersSuccess(filters));
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: post
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    yield put({ type: PAActionTypes.UPDATE_PA_FILTER_FAILURE });
  }
}

function* updateBAFilterSuccess(action: any) {}

function* updateBAFilterFailure(action: any) {}

async function* baApproved(action: any) {
  const {callback, purchaseRequest} = action;
  try {
    const result = yield ProcurementApprovalService.processPRForPA(
      purchaseRequest
    );
    if (purchaseRequest.ApprovalIndicator == "A") {
      yield put(new PRForPAApprovalActionSuccess(purchaseRequest));
      await DocumentFlowService.logUserInteractionData(UserInteractionType.ApprovedPRForPAPRequestCockpit);
    } else {
      yield put(new PRForPARejectionActionSuccess(purchaseRequest));
      await DocumentFlowService.logUserInteractionData(UserInteractionType.RejectedPRForPAPRequestCockpit);
    }
    yield callback("success");
  } catch (error) {
    yield put(new ProcessPRForPAActionError(error));
    yield callback("failure");
  }
}

function* updatePage(action) {
  const query = yield select(
    ({
      procurementApprovals
    }: {
      procurementApprovals: procurementApprovalsStateStore;
    }) => procurementApprovals.PurchaseRequest.query
  );
  const newQuery = newPage(query, action.page);
  yield put({
    type: ProcurementApprovalActionTypes.NEW_PA_QUERY,
    query: newQuery,
    alias: action.alias
  });
}

function* newTerm(action) {
  const query = yield select(
    ({
      procurementApprovals
    }: {
      procurementApprovals: procurementApprovalsStateStore;
    }) => procurementApprovals.PurchaseRequest.query
  );

  const newQuery = updateTerm({ ...query, page: 0 }, action.term);
  yield put({
    type: ProcurementApprovalActionTypes.NEW_PA_QUERY,
    query: newQuery,
    alias: action.alias
  });
}

function* newOrder(action) {
  const query = yield select(
    ({
      procurementApprovals
    }: {
      procurementApprovals: procurementApprovalsStateStore;
    }) => procurementApprovals.PurchaseRequest.query
  );
  const newQuery = updateOrder(query, action.field, action.direction);
  yield put({
    type: ProcurementApprovalActionTypes.NEW_PA_QUERY,
    query: newQuery,
    alias: action.alias
  });
}

function* fetchPRSWithDefaultAliys(action) {
  let newQuery = action.query;
  if (!action.query) {
    let query = purchaseRequestQuery();
    newQuery = newResource(query, action.resource);
    // console.log('qResource', qResource)
    // newQuery = newPage(qResource, 0);

    if (!action.filters.companies.length) {
      action.filters.companies.push({
        id: action.companyCode,
        title: action.companyCodeName
      });
    }
    newQuery = updatePAFilters(newQuery, action.filters, [
      { field: "AgentID", value: action.positionId },
      { field: "DisplayType", value: "H" }
    ]);
  }

  try {
    // const aliys = yield call(ProcurementApprovalService.getDefaultAliys, action.companyCode)
    const result = yield call(
      ProcurementApprovalService.fetch,
      newQuery,
      action.alias
    );
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
    // try {
    //   const aliys = 'R3AE1';
    //   const result = yield call(ProcurementApprovalService.fetch, newQuery, aliys);
    //   yield put(
    //     new FetchPRsSuccess({
    //       items: result.data,
    //       totalCount: result.count,
    //       newQuery: newQuery
    //     })
    //   );
    //   yield put({ type: ProcurementApprovalActionTypes.PA_DEFAULT_ALIYS_SUCCESS, aliys });
    // } catch (error) {
    //   Sentry.captureException(error);
    //   yield put(new FetchPRsError({ error: error, message: error.message }));
    // }
  }
}

function* PADefaultAliys(action) {
  yield put({
    type: ProcurementApprovalActionTypes.PA_DEFAULT_ALIYS_SUCCESS,
    aliys: action.aliys
  });
}

function* watchFetchQuery() {
  yield takeLatest(ProcurementApprovalActionTypes.FETCH_PAS, fetch);
}

function* watchNewActiveQuery() {
  yield takeLatest(ProcurementApprovalActionTypes.NEW_PA_QUERY, fetch);
}

function* watchDetailActiveQuery() {
  yield takeLatest(
    ProcurementApprovalActionTypes.FETCH_PAS_DETAIL,
    fetchDetail
  );
}

function* watchLinesQuery() {
  yield takeLatest(ProcurementApprovalActionTypes.FETCH_PAS_LINES, fetchLines);
}

function* watchNewPage() {
  yield takeLatest(ProcurementApprovalActionTypes.NEW_PAGE_PA, updatePage);
}

function* watchNewOrder() {
  yield takeLatest(ProcurementApprovalActionTypes.NEW_ORDER_PA, newOrder);
}

function* watchNewTerm() {
  yield takeLatest(ProcurementApprovalActionTypes.NEW_TERM_PA, newTerm);
}

function* watchNewFilter() {
  yield takeLatest(PAActionTypes.PA_NEW_FILTER, newPAFilter);
}

function* watchDeleteFilter() {
  yield takeLatest(PAActionTypes.DELETE_PA_FILTER, deletePAFilter);
}

function* watchResetBAFilter() {
  yield takeLatest(PAActionTypes.RESET_PA_FILTERS, resetPAFilter);
}

function* watchUpdateFilterSuccess() {
  yield takeLatest(
    PAActionTypes.UPDATE_PA_FILTER_SUCCESS,
    updateBAFilterSuccess
  );
}

function* watchUpdateFilterFailure() {
  yield takeLatest(
    PAActionTypes.UPDATE_PA_FILTER_FAILURE,
    updateBAFilterFailure
  );
}

function* watchPAApprovelActionViaEmail() {
  yield takeLatest(
    ProcurementApprovalActionTypes.ACTION_FOR_PA_PR_VIA_EMAIL,
    baApproved
  );
}

function* watchPAApprovelAction() {
  yield takeLatest(ProcurementApprovalActionTypes.ACTION_FOR_PA_PR, baApproved);
}

function* watchFetchPRSWithDefaultAliys() {
  yield takeLatest(
    ProcurementApprovalActionTypes.FETCH_PA_WITH_DEFAULT_ALIYS,
    fetchPRSWithDefaultAliys
  );
}

function* watchPADefaultAliys() {
  yield takeLatest(
    ProcurementApprovalActionTypes.PA_DEFAULT_ALIYS,
    PADefaultAliys
  );
}

export default function* procurementApprovalsSage() {
  yield all([
    fork(watchFetchQuery),
    fork(watchNewActiveQuery),
    fork(watchNewPage),
    fork(watchDetailActiveQuery),
    fork(watchLinesQuery),
    fork(watchNewTerm),
    fork(watchNewOrder),
    fork(watchNewFilter),
    fork(watchDeleteFilter),
    fork(watchResetBAFilter),
    fork(watchUpdateFilterSuccess),
    fork(watchUpdateFilterFailure),
    fork(watchPAApprovelActionViaEmail),
    fork(watchPAApprovelAction),
    fork(watchFetchPRSWithDefaultAliys),
    fork(watchPADefaultAliys)
  ]);
}
