import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { approvalHistoryStateStore } from "../reducers";
import { ApprovalHistoryService } from "../domains/approvalHistory/approvalHistory.service";
import * as Sentry from '@sentry/browser';
import {
    FetchPRDetailSuccess,
    FetchPRDetailError,
    FetchPRs,
    FetchPRsError,
    FetchPRsSuccess,
    ApprovalHistoryActionTypes,
    FetchPRLinesSuccess,
    FetchPRLinesError,
    // ApprovedPRSuccess,
    // ApprovedPRError
} from "../actions/approvalHistory.action";
import { newPage, newResource, updateTerm, newFilters, ApprovalHistoryQuery, updateOrder } from "../domains/approvalHistory/query";
import { positions } from '@material-ui/system';
import {FilterType} from "../../../../lib/odata";

export function* fetch(action: any): any {
    try {
        let newQuery = action.query;
        if (!action.query) {
            let query = ApprovalHistoryQuery();
            newQuery = newResource(query, action.resource);
            newQuery = newFilters(newQuery, [
                { field: "Aribapr", value: action.Aribapr, type: FilterType.SUBSTRING_OF }
            ]);
        }

        const result = yield call(ApprovalHistoryService.fetch, newQuery);
        console.log('resultresultresult', result)
        yield put(new FetchPRsSuccess({ items: result.data, totalCount: result.count, newQuery: newQuery }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new FetchPRsError({ error: error, message: error.message }))
    }
}

export function* fetchDetail(action: any): any {
    const query = {
        Aribapr: action.Aribapr,
        SapPR: action.SapPR,
        ItemNo: action.ItemNo,
        CompCode: action.CompCode,
        PurchOrg: action.PurchOrg,
        RelGroup: action.RelGroup,
        RelStrategy: action.RelStrategy,
        ApprovalLevel: action.ApprovalLevel
    };
    try {
        const result = yield call(ApprovalHistoryService.fetchDetail, query);
        const result1 = yield call(ApprovalHistoryService.fetchAccount, query);
        const result2 = yield call(ApprovalHistoryService.fetchPricing, query);
        const result3 = yield call(ApprovalHistoryService.fetchApprovalIndicator, query);
        yield put(new FetchPRDetailSuccess({ info: result, accountList: result1, pricing: result2, approvalIndecator: result3 }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new FetchPRDetailError({ error: error, message: error.message }))
    }
}

export function* fetchLines(action: any): any {
    const query = [{ field: "AribaPr", value: action.AribaPr },
    { field: "ApprovalType", value: action.ApprovalType },
    { field: "ApprovalLevel", value: action.ApprovalLevel }];
    if (action.ApprovalType == 'BA') {
        query.push({ field: "ItemNo", value: action.ItemNo })
    }
    try {
        const result = yield call(ApprovalHistoryService.fetchLines, query);
        console.log('resultresult', result)
        yield put(new FetchPRLinesSuccess({ items: result.data, Aribapr: action.AribaPr }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new FetchPRLinesError({ error: error, message: error.message, Aribapr: action.AribaPr }))
    }
}

// export function* approveRequest(action: any): any {
//     const query = { SapPR: action.SapPR, ItemNo: action.ItemNo };
//     try {
//         const result = yield call(ApprovalHistoryService.fetchLines, query);
//         yield put(new ApprovedPRSuccess({ items: result.data, SapPR: action.SapPR }))
//     } catch (error) {
//         Sentry.captureException(error);
//         yield put(new ApprovedPRError({ error: error, message: error.message }))
//     }
// }


function* updatePage(action) {
    const query = yield select(({ approvalHistory }: { approvalHistory: approvalHistoryStateStore }) => approvalHistory.ApprovalHistory.query);
    const newQuery = newPage(query, action.page);
    yield put({ type: ApprovalHistoryActionTypes.NEW_AH_QUERY, query: newQuery });
}


function* newTerm(action) {
    const query = yield select(({ approvalHistory }: { approvalHistory: approvalHistoryStateStore }) => approvalHistory.ApprovalHistory.query);
    const newQuery = updateTerm(query, action.term);
    yield put({ type: ApprovalHistoryActionTypes.NEW_AH_QUERY, query: newQuery });
}

function* newOrder(action) {
    const query = yield select(({ approvalHistory }: { approvalHistory: approvalHistoryStateStore }) => approvalHistory.ApprovalHistory.query);
    const newQuery = updateOrder(query, action.field, action.direction);
    yield put({ type: ApprovalHistoryActionTypes.NEW_AH_QUERY, query: newQuery });
}

function* watchBAFetchQuery() {
    yield takeLatest(ApprovalHistoryActionTypes.FETCH_AH, fetch)
}

function* watchNewActiveQuery() {
    yield takeLatest(ApprovalHistoryActionTypes.NEW_AH_QUERY, fetch)
}

function* watchDetailActiveQuery() {
    yield takeLatest(ApprovalHistoryActionTypes.FETCH_AH_DETAIL, fetchDetail)
}

function* watchLinesQuery() {
    yield takeLatest(ApprovalHistoryActionTypes.FETCH_AH_LINES, fetchLines)
}

function* watchNewPage() {
    yield takeLatest(ApprovalHistoryActionTypes.NEW_PAGE_AH, updatePage)
}

function* watchNewTerm() {
    yield takeLatest(ApprovalHistoryActionTypes.NEW_TERM_AH, newTerm)
}

function* watchNewOrder() {
    yield takeLatest(ApprovalHistoryActionTypes.NEW_ORDER_AH, newOrder);
}



export default function* approvalHistorySaga() {
    yield all([
        fork(watchBAFetchQuery),
        fork(watchNewActiveQuery),
        fork(watchNewPage),
        fork(watchDetailActiveQuery),
        fork(watchLinesQuery),
        fork(watchNewTerm),
        fork(watchNewOrder)
    ])
};
