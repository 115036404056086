import {prop, uniqWith, flatten, either, isNil, isEmpty, defaultTo} from "ramda";
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {
  TenderCalendarActionTypes,
  CreateContractEvent,
  CreateContractEventError,
  CreateContractEventSuccess,
  DeleteContractEvent,
  DeleteContractEventError,
  DeleteContractEventSuccess,
  EditContractEvent,
  EditContractEventError,
  EditContractEventSuccess,
  ExportCalendarEventsError,
  ExportCalendarEventsSuccess,
  FetchClustersBySpendCat,
  FetchClustersBySpendCatError,
  FetchClustersBySpendCatSuccess,
  FetchClustersByBuyerAndSpendCat,
  FetchClustersByBuyerAndSpendCatError,
  FetchClustersByBuyerAndSpendCatSuccess,
  FetchCompanyCodesByMarket,
  FetchCompanyCodesByMarketError,
  FetchCompanyCodesByMarketSuccess,
  FetchEventsError,
  FetchEventsRequest,
  FetchEventsSuccess,
  FetchMarketsByZone,
  FetchMarketsByZoneError,
  FetchMarketsByZoneSuccess,
  FetchPlantsByCompanyCode,
  FetchPlantsByCompanyCodeError,
  FetchPlantsByCompanyCodeSuccess,
  FetchSpendCategoriesClustersByBuyer,
  FetchSpendCategoriesClustersByBuyerError,
  FetchSpendCategoriesClustersByBuyerSuccess,
  NewCalendarFilters,
  NewCalendarQuery,
  ToggleReportPreview,
  FetchSyncStatusSuccess,
  FetchSyncStatusError,
} from "../actions/calendar.actions";
import {CalendarService} from "../domains/calendar.service";
import {newQuery} from "../../shared/queryable/query";
import {defaultCalendarFilters, newFilters} from "../domains/calendar.query";
import {
  CALENDAR_ALL_OPTION,
  codeNameCompare,
  CodeNameDTO, ContractCalendarEvent,
  hasAllOption,
  isAllOption
} from "../domains/calendar";
import {IContractCalendarFilters} from "../../shared/domains/user/user";
import {
  SaveContractCalendarFilters,
} from "../../shared/actions/user.actions";
import {RootStateStore} from "../../application.reducers";
import {withDateFilters} from "./calendar.filter.saga";
import {TenderCalendarFilterActionTypes} from "../actions/calendarFilter.actions";

function* fetchEvents(action: NewCalendarQuery) {
  try {
    const {buyers} = yield select((state: RootStateStore) => state.tenderCalendar.calendar);
    yield put(new FetchEventsRequest());
    const result = yield call(CalendarService.fetchEvents, action.query);
    const events = result.data.map((e: ContractCalendarEvent) => ({...e, buyerName: buyers.find(b => b.email === e.buyerEmail)?.username}))
    yield put(new FetchEventsSuccess(events));
  } catch (error) {
    yield put(new FetchEventsError(error));
  }
}

function* fetchSyncStatus() {
  try {
    const {isSync} = yield call(CalendarService.fetchSyncStatus);
    yield put(new FetchSyncStatusSuccess(isSync));
  } catch (error) {
    yield put(new FetchSyncStatusError(error));
  }
}

function* fetchSpendCatsClustersByBuyer(action: FetchSpendCategoriesClustersByBuyer) {
  try {
    const [spendCats, spendCatsByBuyer , clusters] = yield all([
      call(CalendarService.fetchSpendCategories),
      call(CalendarService.fetchSpendCategoriesByBuyer, action.buyerId),
      call(CalendarService.fetchClustersByBuyer, action.buyerId)
    ]);
    yield put(new FetchSpendCategoriesClustersByBuyerSuccess(!isEmpty(spendCatsByBuyer.data) ? spendCatsByBuyer.data : spendCats.data, clusters.data));
  } catch (error) {
    yield put(new FetchSpendCategoriesClustersByBuyerError(error));
  }
}

function* fetchClustersBySpendCats(action: FetchClustersBySpendCat) {
  try {
    let results = [];
    let spendCatCodes = action.spendCatCodes;
    if (spendCatCodes?.length) {
      if (hasAllOption(spendCatCodes)) {
        const currentSpendCategories = yield select((state: RootStateStore) => state.tenderCalendar.calendar.spendCategories);
        spendCatCodes = currentSpendCategories.map(s => s.code).filter(c => c !== CALENDAR_ALL_OPTION.code);
      } else {
        spendCatCodes = spendCatCodes.filter(s => s !== CALENDAR_ALL_OPTION.code);
      }
      results = yield all(spendCatCodes.map(spendCatCode => call(CalendarService.fetchClustersBySpendCatCode, spendCatCode)));
    }
    yield put(new FetchClustersBySpendCatSuccess(uniqWith(CodeNameDTO.Equality, flatten(results))));
  } catch (error) {
    yield put(new FetchClustersBySpendCatError(error));
  }
}

function* fetchClustersByBuyerAndSpendCats(action: FetchClustersByBuyerAndSpendCat) {
  try {
    let results = [];
    let buyerId = action.buyerId;
    let spendCatCodes = action.spendCatCodes;
    if (spendCatCodes?.length) {
      if (hasAllOption(spendCatCodes)) {
        const currentSpendCategories = yield select((state: RootStateStore) => state.tenderCalendar.calendar.spendCategories);
        spendCatCodes = currentSpendCategories.map(s => s.code).filter(c => c !== CALENDAR_ALL_OPTION.code);
      } else {
        spendCatCodes = spendCatCodes.filter(s => s !== CALENDAR_ALL_OPTION.code);
      }
      results = yield all(spendCatCodes.map(spendCatCode => call(CalendarService.fetchClustersByBuyerAndSpendCat, buyerId, spendCatCode)));
    }
    yield put(new FetchClustersByBuyerAndSpendCatSuccess(uniqWith(CodeNameDTO.Equality, flatten(results))));
  } catch (error) {
    yield put(new FetchClustersByBuyerAndSpendCatError(error));
  }
}

function* fetchMarketsByZone(action: FetchMarketsByZone) {
  try {
    let results = [];
    let zoneCodes = action.zoneCodes;
    if (zoneCodes?.length) {
      if (hasAllOption(zoneCodes)) {
        const currentZones = yield select((state: RootStateStore) => state.tenderCalendar.calendar.zones);
        zoneCodes = currentZones.map(z => z.code).filter(c => c !== CALENDAR_ALL_OPTION.code);
      } else {
        zoneCodes = zoneCodes.filter(s => s !== CALENDAR_ALL_OPTION.code);
      }
      results = yield all(zoneCodes.map(zoneCode => call(CalendarService.fetchMarketsByZone, zoneCode)));
    }
    const markets: any = uniqWith(CodeNameDTO.Equality, flatten(results));
    yield put(new FetchMarketsByZoneSuccess(markets));
    if (action.loadDependencies) {
      yield put(new FetchCompanyCodesByMarket(markets.map(m => m.code)));
    }
  } catch (error) {
    yield put(new FetchMarketsByZoneError(error));
  }
}

function* fetchCompanyCodesByMarket(action: FetchCompanyCodesByMarket) {
  try {
    let results = [];
    let markets = action.markets;
    if (markets?.length) {
      if (hasAllOption(markets)) {
        const currentMarkets = yield select((state: RootStateStore) => state.tenderCalendar.calendar.markets);
        markets = currentMarkets.map(m => m.code).filter(c => c !== CALENDAR_ALL_OPTION.code);
      } else {
        markets = markets.filter(s => s !== CALENDAR_ALL_OPTION.code);
      }
      results = yield call(CalendarService.fetchCompanyCodesByMarket, markets.join(','));
    }
    const companyCodes: any = uniqWith(CodeNameDTO.Equality, flatten(results));
    yield put(new FetchCompanyCodesByMarketSuccess(companyCodes));
    yield put(new FetchPlantsByCompanyCode(companyCodes.map(c=>c.code)));
  } catch (error) {
    yield put(new FetchCompanyCodesByMarketError(error));
  }
}

function* fetchPlantsByCompanyCode(action: FetchPlantsByCompanyCode) {
  try {
    let results = [];
    let companyCodes = action.companyCodes;
    if (companyCodes?.length) {
      if (hasAllOption(companyCodes)) {
        const currentCompanyCodes = yield select((state: RootStateStore) => state.tenderCalendar.calendar.companyCodes);
        companyCodes = currentCompanyCodes.map(s => s.code).filter(c => c !== CALENDAR_ALL_OPTION.code);
      } else {
        companyCodes = companyCodes.filter(s => s !== CALENDAR_ALL_OPTION.code);
      }
      results = yield call(CalendarService.fetchPlantsByCompanyCode, companyCodes.join(','));
    }
    yield put(new FetchPlantsByCompanyCodeSuccess(uniqWith(CodeNameDTO.Equality, flatten(results))));
  } catch (error) {
    yield put(new FetchPlantsByCompanyCodeError(error));
  }
}

function* createEvent(action: CreateContractEvent) {
  try {
    const {spendCategories, clusters, zones, markets, companyCodes, plants} = yield select((state: RootStateStore) => state.tenderCalendar.calendar);

    const isAllSpendCategory = !!action.event.spendCategoryLevel1s.find(isAllOption);
    const isAllClusters = !!action.event.clusters?.find(isAllOption);
    const isAllZones = !!action.event.zones.find(isAllOption);
    const isAllMarkets = !!action.event.markets.find(isAllOption);
    const isAllCompanyCodes = !!action.event.companies?.find(isAllOption);
    const isAllPlants = !!action.event.plants?.find(isAllOption);

    const eventSpendCategoryLevel1s = action.event.spendCategoryLevel1s ? action.event.spendCategoryLevel1s?.map((x: any) => x === CALENDAR_ALL_OPTION.code ? x : spendCategories.find(o => codeNameCompare(o, x))) : [];
    const eventClusters = action.event.clusters ? action.event.clusters?.map((x: any) => x === CALENDAR_ALL_OPTION.code ? x : clusters.find(o => codeNameCompare(o, x))) : [];
    const eventZones = action.event.zones? action.event.zones.map((x: any) => x === CALENDAR_ALL_OPTION.code ? x : zones.find(o => codeNameCompare(o, x))) : [];
    const eventMarkets = action.event.markets ? action.event.markets?.map((x: any) => x === CALENDAR_ALL_OPTION.code ? x : markets.find(o => codeNameCompare(o, x))) : [];
    const eventCompanyCodes = action.event.companies ? action.event.companies?.map((x: any) => x === CALENDAR_ALL_OPTION.code ? x : companyCodes.find(o => codeNameCompare(o, x))) : [];
    const eventPlants = action.event.plants ? action.event.plants?.map((x: any) => x === CALENDAR_ALL_OPTION.code ? x : plants.find(o => codeNameCompare(o, x))) : [];

    const newEvent = {
      ...action.event,
      spendCategoryLevel1s: isAllSpendCategory ? spendCategories : eventSpendCategoryLevel1s,
      clusters: isAllClusters ? clusters : eventClusters,
      zones: isAllZones ? zones : eventZones,
      markets: isAllMarkets ? markets : eventMarkets,
      companies: isAllCompanyCodes ? companyCodes : eventCompanyCodes,
      plants: isAllPlants ? plants : eventPlants
    };
    const result = yield call(CalendarService.save, newEvent);
    yield put(new CreateContractEventSuccess(result));
  } catch (error) {
    yield put(new CreateContractEventError(error));
  }
}

function* updateEvent(action: EditContractEvent) {
  try {
    const filters = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters);
    const calendar = yield select((state) => state.tenderCalendar.calendar);
    const nullOrEmptyFallback = (list1, list2) => either(isNil, isEmpty)(list1) ? defaultTo([], list2) : list1;

    const spendCategories = nullOrEmptyFallback(calendar.spendCategories, filters.spendCategories);
    const clusters = nullOrEmptyFallback(calendar.clusters, filters.clusters);
    const zones = nullOrEmptyFallback(calendar.zones, filters.zones);
    const markets = nullOrEmptyFallback(calendar.markets, filters.markets);
    const companyCodes = nullOrEmptyFallback(calendar.companyCodes, filters.companyCodes);
    const plants = nullOrEmptyFallback(calendar.plants, filters.plants);

    const isAllSpendCategory = !!action.event.spendCategoryLevel1s.find(isAllOption);
    const isAllClusters = !!action.event.clusters?.find(isAllOption);
    const isAllZones = !!action.event.zones.find(isAllOption);
    const isAllMarkets = !!action.event.markets.find(isAllOption);
    const isAllCompanyCodes = !!action.event.companies?.find(isAllOption);
    const isAllPlants = !!action.event.plants?.find(isAllOption);

    const eventSpendCategoryLevel1s = action.event.spendCategoryLevel1s ? action.event.spendCategoryLevel1s?.map((x: any) => x === CALENDAR_ALL_OPTION.code ? x : spendCategories.find(o => codeNameCompare(o, x))) : [];
    const eventClusters = action.event.clusters ? action.event.clusters?.map(x => clusters.find(o => codeNameCompare(o, x))) : [];
    const eventZones = action.event.zones? action.event.zones.map(x => zones.find(o => codeNameCompare(o, x))) : [];
    const eventMarkets = action.event.markets ? action.event.markets?.map(x => markets.find(o => codeNameCompare(o, x))) : [];
    const eventCompanyCodes = action.event.companies ? action.event.companies?.map(x => companyCodes.find(o => codeNameCompare(o, x))) : [];
    const eventPlants = action.event.plants ? action.event.plants?.map(x => plants.find(o => codeNameCompare(o, x))) : [];

    const newEvent = {
      ...action.event,
      spendCategoryLevel1s: isAllSpendCategory ? spendCategories : eventSpendCategoryLevel1s,
      clusters: isAllClusters ? clusters : eventClusters,
      zones: isAllZones ? zones : eventZones,
      markets: isAllMarkets ? markets : eventMarkets,
      companies: isAllCompanyCodes ? companyCodes : eventCompanyCodes,
      plants: isAllPlants ? plants : eventPlants
    };
    const result = yield call(CalendarService.update, newEvent);
    yield put(new EditContractEventSuccess(result));
  } catch (error) {
    yield put(new EditContractEventError(error));
  }
}

function* deleteEvent(action: DeleteContractEvent) {
  try {
    yield call(CalendarService.delete, action.id);
    yield put(new DeleteContractEventSuccess(action.id));
  } catch (error) {
    yield put(new DeleteContractEventError(error));
  }
}

function* newStartDate() {
  const {activeFilters} = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters);
  const filters = yield withDateFilters({...newFilters(activeFilters), ...defaultCalendarFilters});
  yield put(new NewCalendarQuery(newQuery(filters)));
}

function* newFilter(action: NewCalendarFilters) {
  const isPreview = yield select((state: RootStateStore) => state.tenderCalendar.calendar.isPreviewMode);
  const newCalendarFilters: IContractCalendarFilters = {
    buyers: prop('selected', action.filters.buyers),
    spendCategories: prop('selected', action.filters.spendCategories),
    clusters: prop('selected', action.filters.clusters),
    zones: prop('selected', action.filters.zones),
    markets: prop('selected', action.filters.markets),
    companyCodes: prop('selected', action.filters.companyCodes),
    plants: prop('selected', action.filters.plants),
    businesses: prop('selected', action.filters.businesses),
    negotiationType: prop('selected', action.filters.negotiationType),
  };
  let filters = newFilters(newCalendarFilters);
  if (!isPreview) {
    filters = yield withDateFilters({...filters, ...defaultCalendarFilters});
  }
  yield put(new NewCalendarQuery(newQuery(filters)));
  yield put(new SaveContractCalendarFilters(newCalendarFilters));
}

function* toggleReportPreview(action: ToggleReportPreview) {
  const {activeFilters} = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters);
  let filters = newFilters(activeFilters);
  if (!action.isPreview) {
    filters = yield withDateFilters({...filters, ...defaultCalendarFilters});
  }
  yield put(new NewCalendarQuery(newQuery(filters)));
}

function* exportEvents() {
  try {
    const filters = yield select((state: RootStateStore) => state.tenderCalendar.calendarFilters.activeFilters);
    const result = yield call(CalendarService.exportEvents, filters);
    yield put(new ExportCalendarEventsSuccess('Events.xlsx', result));
  } catch (error) {
    yield put(new ExportCalendarEventsError(error));
  }
}

function* watchNewQuery() {
  yield takeLatest(TenderCalendarActionTypes.NEW_CALENDAR_QUERY, fetchEvents);
}

function* watchSyncStatus() {
  yield takeLatest([
    TenderCalendarActionTypes.FETCH_SYNC_STATUS,
    TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS_SUCCESS
  ], fetchSyncStatus);
}

function* watchCreateEvent() {
  yield takeLatest(TenderCalendarActionTypes.CREATE_CONTRACT_EVENT, createEvent);
}

function* watchUpdateEvent() {
  yield takeLatest(TenderCalendarActionTypes.EDIT_CONTRACT_EVENT, updateEvent);
}

function* watchDeleteEvent() {
  yield takeLatest(TenderCalendarActionTypes.DELETE_CONTRACT_EVENT, deleteEvent);
}

function* watchFetchSpendCatsClustersByBuyer() {
  yield takeLatest(TenderCalendarActionTypes.FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER, fetchSpendCatsClustersByBuyer);
}

function* watchFetchClustersBySpendCats() {
  yield takeLatest(TenderCalendarActionTypes.FETCH_CLUSTERS_BY_SPEND_CAT, fetchClustersBySpendCats);
}

function* watchFetchClustersByBuyerAndSpendCats() {
  yield takeLatest(TenderCalendarActionTypes.FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT, fetchClustersByBuyerAndSpendCats);
}

function* watchFetchMarketsByZone() {
  yield takeLatest(TenderCalendarActionTypes.FETCH_MARKETS_BY_ZONE, fetchMarketsByZone);
}

function* watchFetchCompanyCodesByMarket() {
  yield takeLatest(TenderCalendarActionTypes.FETCH_COMPANY_CODES_BY_MARKET, fetchCompanyCodesByMarket);
}

function* watchFetchPlantsByCompanyCode() {
  yield takeLatest(TenderCalendarActionTypes.FETCH_PLANT_BY_COMPANY_CODE, fetchPlantsByCompanyCode);
}

function* watchNewStartDate() {
  yield takeLatest(TenderCalendarActionTypes.NEW_CALENDAR_START_DATE, newStartDate);
}

function* watchNewFilter() {
  yield takeLatest(TenderCalendarActionTypes.NEW_CALENDAR_FILTER, newFilter);
}

function* watchExportEvents() {
  yield takeLatest(TenderCalendarActionTypes.EXPORT_CALENDAR_EVENTS, exportEvents);
}

function* watchToggleReportPreview() {
  yield takeLatest(TenderCalendarActionTypes.TOGGLE_REPORT_PREVIEW, toggleReportPreview);
}

export default function* tenderCalendarSaga() {
  yield all([
    fork(watchFetchSpendCatsClustersByBuyer),
    fork(watchFetchClustersBySpendCats),
    fork(watchFetchClustersByBuyerAndSpendCats),
    fork(watchFetchMarketsByZone),
    fork(watchFetchCompanyCodesByMarket),
    fork(watchFetchPlantsByCompanyCode),
    fork(watchNewQuery),
    fork(watchNewFilter),
    fork(watchSyncStatus),
    fork(watchCreateEvent),
    fork(watchUpdateEvent),
    fork(watchDeleteEvent),
    fork(watchNewStartDate),
    fork(watchExportEvents),
    fork(watchToggleReportPreview)
  ]);
}
