import {IAction} from "../../shared/domains/core/actions";
import {DocumentCounterTypes} from "../actions/documentCounter.actions";
import {mergeRight} from 'ramda';
import {DocumentCounters} from "../domains/documentCounter/documentCounter";

export interface DocCountState {
  loading: boolean;
  error: boolean;
  BlockedCount: number;
  PendingApprCount: number;
  ApprovedCount: number;
  InvoicedCount: number;
}

export const initialDocCountState: DocCountState = {
  loading: true,
  error: false,
  BlockedCount: 0,
  PendingApprCount: 0,
  ApprovedCount: 0,
  InvoicedCount: 0,
};

const loading = {loading: true, error: false};
const failure = {loading: false, error: true};

export function DocumentCounterReducer(state: DocCountState = initialDocCountState, action: IAction<DocumentCounterTypes>): DocCountState {
  switch (action.type) {
    case DocumentCounterTypes.FETCH_DOCCOUNTERS:
      return mergeRight(state, loading);
    case DocumentCounterTypes.FETCH_DOCCOUNTERS_FAILURE:
      return mergeRight(state, failure);
    case DocumentCounterTypes.FETCH_DOCCOUNTERS_SUCCESS:
      const countersWithLoading = mergeRight(action.counters as DocumentCounters, {loading: false});
      return mergeRight(state, countersWithLoading);
    default:
      return state;
  }
}
