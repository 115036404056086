import * as React from "react";
import { Col, Row, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { GetVendorInfo } from "src/app/preferredSuppliers/actions/psl.actions";
import { RootStateStore } from "src/app/application.reducers";
import { Company, PurchasingOrg } from "src/app/preferredSuppliers/domains/psl";
import { ApplyButton } from "../filters/filters.styles";

interface vendorInfo {
    vendorId: string | number;
}

export const PslVendorHoverInfo = ({ vendorId }: vendorInfo) => {
    const [errorString, setErrorString] = React.useState<string>("");

    const [purchOrgMoreButton, setPurchOrgMoreButton] = React.useState<boolean>(false);
    const [compCodeMoreButton, setCompCodeMoreButton] = React.useState<boolean>(false);


    const vendorInfo = useSelector((state: RootStateStore) => state.psl.pslList.data.vendorInfo);
    const { data, loading } = vendorInfo;
    const dispatch = useDispatch();

    const purchOrgs = purchOrgMoreButton ? data.purchasingOrgs : data.purchasingOrgs.slice(0, 5);
    const compCodes = compCodeMoreButton ? data.companies : data.companies.slice(0, 5);

    React.useEffect(() => {
        if (vendorId != "") {
            dispatch(new GetVendorInfo(vendorId, error));
        }
    }, []);

    const error = (typeOfError: string) => {
        setErrorString(typeOfError);
    }

    return (<div>
        <div style={{
            background: "#f19001",
            padding: "16px 24px",
            color: "#fff",
            fontSize: "16px",
            lineHeight: "22px",
            borderRadius: "2px 2px 0 0",
            width: "900px",
            boxShadow: "0 0 1px 1px #f19001",
        }}
        >
            {
                errorString !== "" ? "Alert" : `${data.code} - ${data.description}`
            }
        </div>
        <Spin spinning={loading} size="default" tip="Loading...">
            <Row style={{
                padding: "24px",
                fontSize: "14px",
                lineHeight: "1.5715",
                overflowX: "auto",
                overflowY: "auto",
                maxHeight: "calc(50vh - 54px)",
            }}>
                {errorString !== "" ? <div style={{ padding: "20px", width: "100%", fontSize: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        This Vendor is not extended to any Company codes or Purchasing Org.
                    </div>
                </div>
                    :
                    <>
                        <Col span={8}>
                            <p><b>Vendor</b>
                                <div>{data.code}</div>
                            </p>

                            <p><b>Description</b>
                                <div>{data.description}</div>
                            </p>

                            <p><b>Address</b></p>
                            <p>
                                <div><span className="subHeadingFont">Street House:</span> {data.street}</div>
                                <div><span className="subHeadingFont">Postal Codee:</span> {data.postalCode}</div>
                                <div><span className="subHeadingFont">City:</span> {data.city}</div>
                                <div><span className="subHeadingFont">District:</span> {data.district}</div>
                                <div><span className="subHeadingFont">Region:</span> {data.region}</div>
                                <div><span className="subHeadingFont">Country:</span> {data.country}</div>
                                <div><span className="subHeadingFont">Tax Jurisdiction:</span> {data.taxJurisdiction}</div>
                            </p>
                        </Col>
                        <Col span={8}>
                            <p><b>Extended to PurOrg in SAP Master Data</b></p>
                            {purchOrgs.map((purOrg: PurchasingOrg) => {
                                return (<p>
                                    <div><span className="subHeadingFont">{purOrg.code} - </span>{purOrg.name}</div>
                                </p>)
                            })}
                            {(data.purchasingOrgs.length > 5) && <ApplyButton
                                variant="contained" color="primary" size="small"
                                onClick={() => setPurchOrgMoreButton(prevState => !prevState)}>
                                {purchOrgMoreButton ? "Show Less" : "Show More"}
                            </ApplyButton>
                            }
                        </Col>
                        <Col span={8}>
                            <p><b>Extended to Comp Code in SAP Master Data</b></p>
                            {compCodes.map((compCode: Company) => {
                                return (<p>
                                    <div><span className="subHeadingFont">{compCode.code} - </span>{compCode.name}</div>
                                </p>)
                            })}
                            {(data.companies.length > 5) && <ApplyButton
                                variant="contained" color="primary" size="small"
                                onClick={() => setCompCodeMoreButton(prevState => !prevState)}>
                                {compCodeMoreButton ? "Show Less" : "Show More"}
                            </ApplyButton>
                            }
                        </Col>
                    </>
                }
            </Row>
        </Spin>
    </div>)
}