import * as React from 'react';
import { useStylesForToolTip } from 'src/app/shared/components/tooltip/toolTip.styles';
import { MenuItem, Select, Tooltip } from '@material-ui/core';

export const ZoneNames = {
    AMS: { label: 'AMS', value: 'R3AA1' },
    AOA: { label: 'AOA', value: 'R3AO1' },
    EUR: { label: 'EUR', value: 'R3AE1' }
  };
  
export const DefaultZone = ZoneNames.EUR;

export const GenericZonesComponent = ({ zoneTab, setZoneTab }) => {
    const classes = useStylesForToolTip({});

    return <div>
        <Tooltip
            classes={{ tooltip: classes.customTooltip }}
            placement='right'
            title={"Select a Zone"}>
            <Select disableUnderline={true}
                style={{ paddingLeft: 10 }}
                value={zoneTab}
                onChange={event => setZoneTab(event.target.value as string)}>
                <MenuItem value={ZoneNames.AMS.value}>{ZoneNames.AMS.label}</MenuItem>
                <MenuItem value={ZoneNames.AOA.value}>{ZoneNames.AOA.label}</MenuItem>
                <MenuItem value={ZoneNames.EUR.value}>{ZoneNames.EUR.label}</MenuItem>
            </Select>
        </Tooltip>
    </div>
}