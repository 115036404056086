import {all, fork} from 'redux-saga/effects';
import feedbackSaga from "./feedback.saga";
import ratingSaga from "./rating.saga";
import feedbackFilterSaga from "./feedbackFilters.saga";
import {FeedbackListSagas} from "../reducers/feedback.reducer";
import {RatingListSagas} from "../reducers/rating.reducer";

export default function*() {
  yield all([
    fork(feedbackFilterSaga),
    fork(FeedbackListSagas),
    fork(RatingListSagas),
    fork(feedbackSaga),
    fork(ratingSaga)
  ]);
}
