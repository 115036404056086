import * as R from "ramda";
import {o} from 'odata';
import {UserFeedback, UserFeedbackNetworkDTO, UserRating, UserRatingNetworkDTO} from "./feedback";
import {newQuery, toODataFilter} from "../../shared/queryable/query";
import {List} from "../../shared/utils/odataClient.wrapper";
import {ODataFilter} from "../../../lib/odata";
import {withAuthHeader} from "../../shared/domains/auth/authentication.service";
import {environment} from "../../shared/domains/core/environment";

export class AdminService {
  static async fetchFeedbackList(query = newQuery()): Promise<List<UserFeedback>> {
    const list = R.toPairs(query.filters);
    const filters = list.map(([key, filter], index) => toODataFilter(filter));
    const fullFilters = filters.reduce((previousValue, currentValue) => previousValue.and(currentValue.length && currentValue.reduce((x, y) => x.or(y), ODataFilter.empty())), ODataFilter.empty());
    let strFilters = fullFilters.toString();

    let odataQuery = {$top: 10, $skip: query.page * 10, $count: true};
    if (strFilters) {
      odataQuery = R.assoc('$filter', strFilters, odataQuery);
    }
    if (query.orderBy[0]) {
      odataQuery = R.assoc('$orderby', `${query.orderBy[0].field} ${query.orderBy[0].direction}`, odataQuery);
    }
    if (query.term) {
      odataQuery = R.assoc('searchTerm', `'${query.term}'`, odataQuery);
    }
    try {
      const url = environment.REACT_APP_API_BASE + "/odata/UsersFeedback/";
      const result = await o(url, {headers: await withAuthHeader(), fragment: null}).get().query(odataQuery);
      const data = result.value;
      const count = result['@odata.count'];
      return new List<UserFeedbackNetworkDTO>(data, count).map(UserFeedback.FromBackend);
    } catch (e) {
      throw( { response: { data: await e.json(), status: e.status }} );
    }
  }

  static async fetchRatingList(query = newQuery()): Promise<List<UserRating>> {
    const list = R.toPairs(query.filters);
    const filters = list.map(([key, filter], index) => toODataFilter(filter));
    const fullFilters = filters.reduce((previousValue, currentValue) => previousValue.and(currentValue.length && currentValue.reduce((x, y) => x.or(y), ODataFilter.empty())), ODataFilter.empty());
    let strFilters = fullFilters.toString();
    let odataQuery: any = {$count: true};
    if (query.page) {
      odataQuery = {...odataQuery, $top: 10, $skip: query.page * 10};
    }
    if (strFilters) {
      odataQuery = R.assoc('$filter', strFilters, odataQuery);
    }
    if (query.term) {
      odataQuery = R.assoc('searchTerm', `'${query.term}'`, odataQuery);
    }
    try {
      const url = environment.REACT_APP_API_BASE + "/odata/UsersRating/";
      const result = await o(url, {headers: await withAuthHeader(), fragment: null}).get().query(odataQuery);
      const data = result.value;
      const count = result['@odata.count'];
      return new List<UserRatingNetworkDTO>(data, count).map(UserRating.FromBackend);
    } catch (e) {
      throw( { response: { data: await e.json(), status: e.status }} );
    }
  }
}
