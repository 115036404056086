import {LoginType, MsalAuthProvider} from "react-aad-msal";
import { LogLevel, Logger } from "msal";
import {environment} from "../core/environment";

export const scopes = [`${environment.REACT_APP_CLIENT_ID}/.default`];

export const msalAuthProvider = new MsalAuthProvider(
  {
    auth: {
      authority: `https://login.microsoftonline.com/${environment.REACT_APP_TENANT_ID}`,
      clientId: environment.REACT_APP_CLIENT_ID,
      redirectUri: window.location.origin
    },
    system: {
      logger: new Logger((logLevel, message, containsPii) => {
          console.log("[MSAL]", message);
        },
        {
          level: LogLevel.Warning,
          piiLoggingEnabled: false
        })
    },
    cache: {
      cacheLocation: "localStorage"
    }
  },
  {
    scopes: scopes
  },
  {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + "/auth.html"
  }
);
