import { TextFilter } from "../../../shared/domains/query/filter";
import { AxiosWrapper } from "../../../shared/utils/axios.wrapper";

export enum BAFilterKeys {
  SAP_PR_NUMBER = 'purchaseReqNo',
  REQUESTOR = 'prCreatorName',
  COMPANIES = 'companies',
  VENDOR = 'supplier',
  PURCHASING_GROUP = 'purchGroup',
  COST_CENTER = 'costCenter',
  PROJECT_ID = 'wbsElement',
}

export const BusinessApprovalsFilters = [
  { key: BAFilterKeys.SAP_PR_NUMBER, label: 'SAP PR Number',sapLabel : "SapPR" },
  { key: BAFilterKeys.REQUESTOR, label: 'Spender',sapLabel : "Requestor" },
  { key: BAFilterKeys.COMPANIES, label: 'Company Code',sapLabel : "CompCode"  },
  { key: BAFilterKeys.VENDOR, label: 'Vendor',sapLabel : "FixedVendor"  },
  { key: BAFilterKeys.PURCHASING_GROUP, label: 'Purchasing Group',sapLabel : "PurchaseGroup"  },
  { key: BAFilterKeys.COST_CENTER, label: 'Cost Center',sapLabel : "CosCenter"  },
  { key: BAFilterKeys.PROJECT_ID, label: 'Project Id / WBS',sapLabel : "WBSElement"  },
];

export const enum BusinessApprovalFilterTypes {
  COMPANIES = "companies",
  PGROUPS = "pGroups",
  PORGS = "pOrgs",
  DOCTYPES = "docTypes",
  MGROUPS = "mGroups",
  MATERIALS = "materials",
  PLANTS = "plants",
  VALIDITY = "validity",
  STRATEGICBUYERS = "strategicBuyers"
}

export interface ContractFilterTyped extends TextFilter {
  kind: BusinessApprovalFilterTypes
}

export class BAFilterService {
  static async fetchAllFilters(withMultiOrigin: string): Promise<any[]> {
    const response = await AxiosWrapper.get(`/api/Contracts/GetFilters`,
    {
      headers:
        { 'X-SystemAlias': withMultiOrigin }
    }
    );
    return response.data;
  }

}
