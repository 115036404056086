import * as R from "ramda";
import { filter, Dictionary } from "ramda";
import { newQuery, Query } from "../../shared/queryable/query";
import { FilterCategoryView, newTextFilterCategory } from "../../shared/domains/query/filter";
import { FeedbackCockpitFilterKeys } from "src/app/feedbackCockpit/constants/feedBackCockpitQuery";

const ModuleFilter = 'Module';
const TypeFilter = 'Type';
const NetPromoterScoreFilter = 'NetPromoterScore';
const RatingFilter = 'Rating';

export const FeedbackFilters = [
  { key: ModuleFilter, label: 'Module' },
  { key: TypeFilter, label: 'Type' },
  { key: RatingFilter, label: 'Rating' },
  { key: NetPromoterScoreFilter, label: 'Recommendation Score' },
];

export const AdminFeedbackFilters = [
  { key: FeedbackCockpitFilterKeys.APPLICATION, label: 'Application' },
  { key: FeedbackCockpitFilterKeys.FEEDBACK_BY, label: 'Feedback By' },
  { key: FeedbackCockpitFilterKeys.FEEDBACK_TYPE, label: 'Feedback Type' },
  { key: FeedbackCockpitFilterKeys.CREATED_DATE_RANGE, label: 'Created On' },
  { key: FeedbackCockpitFilterKeys.APPLICATION_RATING, label: 'Rating' },
  { key: FeedbackCockpitFilterKeys.RECOMMENDATION, label: 'Recommendation' },
  { key: FeedbackCockpitFilterKeys.CREATED_BY, label: 'Created On' },
  { key: FeedbackCockpitFilterKeys.STATUS, label: 'Status' },
  { key: FeedbackCockpitFilterKeys.ADMIN, label: 'Admin' },
  { key: FeedbackCockpitFilterKeys.Id, label: 'Feedback Id' },
];

const initialFeedback = FeedbackFilters.map(x => newTextFilterCategory(x.key, x.label));

const initialFeedbackDictionary = R.indexBy(R.prop('key'), initialFeedback);

export function newFeedbackQuery(): Query {
  const initialFilter: Dictionary<FilterCategoryView> = initialFeedbackDictionary;
  return newQuery(initialFilter);
}

export function newRatingQuery(): Query {
  const initialFilter: Dictionary<FilterCategoryView> = initialFeedbackDictionary;
  return newQuery(initialFilter);
}

export function feedbackQuery(query: Query): Query {
  const filters: Dictionary<FilterCategoryView> = filter((f: FilterCategoryView) => [
    ModuleFilter,
    TypeFilter,
    NetPromoterScoreFilter,
  ].indexOf(f.key) !== -1)(query.filters);
  return {
    ...query,
    filters
  };
}

export function ratingQuery(query: Query): Query {
  const filters: Dictionary<FilterCategoryView> = filter((f: FilterCategoryView) => [
    ModuleFilter,
    NetPromoterScoreFilter,
  ].indexOf(f.key) !== -1)(query.filters);
  return {
    ...query,
    filters
  };
}
