import * as R from "ramda";
import {
  FilterCategoryView,
  newTextFilterCategory
} from "../../shared/domains/query/filter";
import {newQuery, Query, setSelectedFiltersBykey, updateOrder} from "../../shared/queryable/query";
import {ICBFilters} from "../../shared/domains/user/user";
import {Dictionary} from "ramda";
import {OrderDirection} from "../../../lib/odata";

const DefaultSortField = "CreatedOn";

export enum CB_FILTER_KEY {
  COMPANY_CODE = 'CompanyCode',
  CATEGORY_CODE = 'CategoryCode',
  SUBCATEGORY_CODE = 'SubcategoryCode',
  PRODUCTCATEGORY_CODE = 'ProductCategoryCode',
  STATUS_ID = 'CbStatusId',
  SELECTED_SUPPLIER_ID = 'SelectedSupplierId',
  CREATOR_USER_EMAIL = 'CreatorUserEmail'
}
export const ParentSpendCategory = 'spendCategories';

export const CbFilters = [
  {key: CB_FILTER_KEY.COMPANY_CODE, label: 'Company code'},
  {key: CB_FILTER_KEY.CATEGORY_CODE, label: 'Spend Category L1'},
  {key: CB_FILTER_KEY.SUBCATEGORY_CODE, label: 'Spend Category L2'},
  {key: CB_FILTER_KEY.PRODUCTCATEGORY_CODE, label: 'Spend Category L3'},
  {key: CB_FILTER_KEY.STATUS_ID, label: 'Status'},
  {key: CB_FILTER_KEY.SELECTED_SUPPLIER_ID, label: 'Suppliers'},
  {key: CB_FILTER_KEY.CREATOR_USER_EMAIL, label: 'Created By'}
];

const initial = CbFilters.map(x => newTextFilterCategory(x.key, x.label));
const initialDictionary = R.indexBy(R.prop('key'), initial);

export function newCbQuery(defaultFilters?: ICBFilters, term?: string): Query {
  let initialFilter: Dictionary<FilterCategoryView> = initialDictionary;
  if (defaultFilters) {
    initialFilter = setSelectedFiltersBykey('CompanyCode', defaultFilters.companies, initialFilter);
    initialFilter = setSelectedFiltersBykey('CategoryCode', defaultFilters.spendCatL1s, initialFilter);
    initialFilter = setSelectedFiltersBykey('SubcategoryCode', defaultFilters.spendCatL2s, initialFilter);
    initialFilter = setSelectedFiltersBykey('ProductCategoryCode', defaultFilters.spendCatL3s, initialFilter);
    initialFilter = setSelectedFiltersBykey('CbStatusId', defaultFilters.statuses, initialFilter);
    initialFilter = setSelectedFiltersBykey('SelectedSupplierId', defaultFilters.suppliers, initialFilter);
    initialFilter = setSelectedFiltersBykey('CreatorUserEmail', defaultFilters.competitiveBiddingCreators, initialFilter);
  }
  const query = {
    ...newQuery(initialFilter),
    term: term
  };
  return updateOrder(query, DefaultSortField, OrderDirection.DESC);
}

