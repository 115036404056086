import * as React from "react";
import * as R from "ramda";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import {createStyles} from "@material-ui/core";
import {Toolbar} from "../../../../shared/components/layout/styles";
import {FilterHeader, FilterSelector, FilterValues} from "../../../../shared/components/filters/filters";
import {
  ApplyButton, FilterRow, Filters, FiltersContainer,
  FiltersContainerWrapper, ResetButton, SmallFilterRow,
  StyledButtons
} from "../../../../shared/components/filters/filters.styles";
import {FilterCategoryView, TextFilter} from "../../../../shared/domains/query/filter";
import {IContractCalendarFilters} from "../../../../shared/domains/user/user";
import {RootStateStore} from "../../../../application.reducers";
import {CalendarFilters} from "../../../domains/calendar.query";
import {DeleteCalendarFilter, RestoreCalendarFilters} from "../../../actions/calendarFilter.actions";

export interface CalendarFilterProps {
  activeFilters: IContractCalendarFilters;
  buyers: TextFilter[];
  spendCategories: TextFilter[];
  clusters: TextFilter[];
  zones: TextFilter[];
  markets: TextFilter[];
  companyCodes: TextFilter[];
  plants: TextFilter[];
  businesses: TextFilter[];
  negotiationType: TextFilter[];
}

export interface CalendarFilterDispatcher {
  updateFilters: (updatedFilters: any) => void;
  resetFilters: () => void;
  fetchHierarchyMarketsByZones: (zones: string[]) => void;
  fetchHierarchyCompanyCodesByMarket: (markets: string[]) => void;
  fetchHierarchyPlantsByCompanyCode: (companyCodes: string[]) => void;
}

interface Props extends CalendarFilterProps, CalendarFilterDispatcher {
  onClose: () => void;
}

interface CalendarFilterCategoryView extends FilterCategoryView {
  hidden: boolean;
}

export default function CalendarFiltersPage(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {activeFilters} = props;
  const addFilter = (key: string, label: string, selected: Array<any>, data: Array<any>, hidden = false): CalendarFilterCategoryView => {
    return {data, key, label, selected, hidden};
  };
  const data = {
    buyers: addFilter('buyers', t('calendar:buyer'), activeFilters.buyers, props.buyers),
    spendCategories: addFilter('spendCategories', t('calendar:spendCategories'), activeFilters.spendCategories, props.spendCategories),
    clusters: addFilter('clusters', t('calendar:clusters'), activeFilters.clusters, props.clusters),
    zones: addFilter('zones', t('calendar:zones'), activeFilters.zones, props.zones),
    markets: addFilter('markets', t('calendar:markets'), activeFilters.markets, props.markets),
    companyCodes: addFilter('companyCodes', t('calendar:companyCodes'), activeFilters.companyCodes, props.companyCodes),
    plants: addFilter('plants', t('calendar:plants'), activeFilters.plants, props.plants),
    businesses: addFilter('businesses', t('calendar:businesses'), activeFilters.businesses, props.businesses, true),
    negotiationType: addFilter('negotiationType', t('calendar:negotiationTypes'), activeFilters.negotiationType, props.negotiationType)
  };
  const customDisplay = (key) => {
    if (key === 'markets') return ((o) => o.value);
    return null;
  };
  const handleChange = (value, d: FilterCategoryView) => {
    setFilters(R.set(R.lensPath([d.key, 'selected']), value, filters))
    const values = value.map(v => v.id);
    switch (d.key) {
      case 'zones': props.fetchHierarchyMarketsByZones(values);
      case 'markets': props.fetchHierarchyCompanyCodesByMarket(values);
      case 'companyCodes': props.fetchHierarchyPlantsByCompanyCode(values);
    }
  };
  const [filters, setFilters] = useReducer((state, newState) => ({...state, ...newState}), data);
  const filterCmps = R.mapObjIndexed((d: FilterCategoryView, key: string) => {
    return !filters[d.key].hidden && <SmallFilterRow key={key}>
      <FilterSelector
        label={d.label}
        customDisplay={customDisplay(key)}
        selected={filters[d.key]?.selected}
        source={d.data}
        onChange={value => handleChange(value, d)}/>
    </SmallFilterRow>
  }, data);

  useEffect(() => {
    dispatch(new RestoreCalendarFilters(activeFilters));
  }, []);

  return (
    <>
      <Toolbar>
        <FilterHeader onCloseClick={() => props.onClose()}/>
      </Toolbar>
      <FiltersContainerWrapper>
        <FiltersContainer style={{paddingTop: 10}}>
          {
            R.keys(filterCmps).map(o => filterCmps[o])
          }
          <FilterRow>
            <StyledButtons>
              <ApplyButton onClick={() => {
                props.updateFilters(filters);
                props.onClose();
              }} variant="contained" color="primary" autoFocus>Apply</ApplyButton>
              <ResetButton onClick={() => {
                props.resetFilters();
                props.onClose();
              }} variant="contained" color="primary">Reset</ResetButton>
            </StyledButtons>
          </FilterRow>
        </FiltersContainer>
      </FiltersContainerWrapper>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    filter: {
      borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
  })
);

export const CalendarActiveFiltersList = () => {
  const {filter} = useStyles({});
  const dispatch = useDispatch();
  const filters = useSelector((state: RootStateStore) => state.tenderCalendar.calendarFilters.activeFilters);
  const deleteFilter = (filter, source) => dispatch(new DeleteCalendarFilter(filter.id, source));
  const hasSelected = () => R.keys(filters).find(k => !R.isEmpty(filters[k]));
  return (
    <>
      {
        hasSelected() && <Filters className={filter}>
          {
            CalendarFilters.map((f, idx) => (
              <FilterValues key={idx} kind={f.key} name={f.label} source={filters[f.source]} onDelete={filter => deleteFilter(filter, f.source)}/>
            ))
          }
        </Filters>
      }
    </>
  )
}
