import {DocumentFlowId} from "./documentFlow";

export interface NetworkDocumentFlowStageDTO {
  __metadata: { id: string; type: string; uri: string };
  IdNo: string;
  PurchaseReqNo: string;
  PRPercentage: string;
  POPercentage: string;
  GRPercntage: string;
  IRPercentage: string;
  Payment: string;
}

export class DocumentFlowStage {
  constructor(
    public dfId: DocumentFlowId,
    public IdNo: string,
    public PurchaseReqNo: string,
    public PRPercentage: number,
    public POPercentage: number,
    public GRPercntage: number,
    public IRPercentage: number,
    public Payment: number,
  ) {}

  static FromBackend(dfId: DocumentFlowId, dto: NetworkDocumentFlowStageDTO): DocumentFlowStage {
    return new DocumentFlowStage(
      dfId,
      dto.IdNo,
      dto.PurchaseReqNo,
      parseInt(dto.PRPercentage),
      parseInt(dto.POPercentage),
      parseInt(dto.GRPercntage),
      parseInt(dto.IRPercentage),
      parseInt(dto.Payment),
    );
  }
}
