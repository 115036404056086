import {dissoc} from "ramda";
import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import {AdminService} from "../domains/admin.service";
import {List} from "../../shared/utils/odataClient.wrapper";
import {UserRating} from "../domains/feedback";
import {newRatingQuery, ratingQuery} from "../domains/feedbackQuery";
import {QueryRatingActions} from "../../admin/reducers/rating.reducer";
import {
  FetchRatingGraph,
  FetchRatingGraphError,
  FetchRatingGraphSuccess,
  FetchRatingList,
  FetchRatingListRequest,
  FetchRatingListRequestError,
  FetchRatingListRequestSuccess,
  RatingTypes
} from "../actions/rating.actions";

function* fetchRating(action: FetchRatingList) {
  try {
    yield put(new FetchRatingListRequest());
    const result: List<UserRating> = yield call(AdminService.fetchRatingList, ratingQuery(action.query));
    yield put(new FetchRatingListRequestSuccess(result));
    yield put(new FetchRatingGraph(ratingQuery(dissoc('page', action.query))));
  } catch (error) {
    yield put (new FetchRatingListRequestError(error));
  }
}

function* fetchRatingGraph(action: FetchRatingGraph) {
  try {
    const result: List<UserRating> = yield call(AdminService.fetchRatingList, action.query);
    yield put(new FetchRatingGraphSuccess(result));
  } catch (error) {
    yield put(new FetchRatingGraphError(error));
  }
}

function* watchFetchRatingGraph() {
  yield takeLatest(RatingTypes.FETCH_RATING_GRAPH, fetchRatingGraph);
}

function* initialRatingFetch() {
  yield put({type: QueryRatingActions.onNewQuery, query: newRatingQuery()});
}

function* watchFetchRatingList() {
  yield takeLatest(RatingTypes.FETCH_RATING_LIST, initialRatingFetch);
}

function* watchNewRatingQuery() {
  yield takeLatest(QueryRatingActions.onNewQuery, fetchRating);
}

export default function* ratingSaga() {
  yield all([
    fork(watchFetchRatingGraph),
    fork(watchNewRatingQuery),
    fork(watchFetchRatingList)
  ]);
}
