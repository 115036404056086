export const convertToScopeTitles = (data:any) => {

    let parentData = [];
    let childData = [];

    data && data.map((item)=>{
        if(item.parentPslScopeFilter && item.parentPslScopeFilterId){
            childData.push({ title : item.name , value: item.id, parentId: item.parentPslScopeFilterId })
        }else{
            parentData.push({ title : item.name , value: item.id })
        }
    });

    let newData = parentData.length > 0 ? parentData.map((parent)=>{
        let childItems = childData.filter(child=>child.parentId === parent.value);
        if(childItems.length > 0) {
            return {...parent, children: childItems};
        }else{
            return parent;
        }
    }) : [];

    return newData
}

export const getTitleWithId = (id:any,data:any) => {

    let parentData = [];
    let childData = [];

    data && data.map((item)=>{
        if(item.parentPslScopeFilter && item.parentPslScopeFilterId){
            childData.push({ title : item.name , value: item.id, parentId: item.parentPslScopeFilterId })
        }else{
            parentData.push({ title : item.name , value: item.id })
        }
    });

    const scopeItems = parentData.concat(childData).filter(item=>item.value === id);

    return scopeItems.length > 0 ? scopeItems[0]?.title : ""
}
