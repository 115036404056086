import { PurchaseRequisitionAction } from '../action';
import { IAction } from '../../shared/domains/core/actions';
import {combineReducers, Reducer} from "redux";

export interface PurchaseRequisitionState {
    loading: boolean;
    error: any;
    PRType: string;
}

export const initialPurchaseRequisitionState: PurchaseRequisitionState = {
    loading: true,
    error: null,
    PRType: null,
}

export function PurchaseRequisitionReducers(state: PurchaseRequisitionState = initialPurchaseRequisitionState, action: IAction<PurchaseRequisitionAction>): PurchaseRequisitionState {

    switch (action.type) {
        case PurchaseRequisitionAction.FETCH_PR_TYPE:
            return {
                ...state,
                loading: true,
            };
        case PurchaseRequisitionAction.FETCH_PR_TYPE_SUCCESS:
            console.log('action mp',action);
            return {
                ...state,
                loading: false,
                PRType: action.PurchaseRequisitionType.BAPA
            };
        case PurchaseRequisitionAction.FETCH_PR_TYPE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        default:
            return state;
    }
}


export interface purchaseRequisitionStateStore {
    PurchaseRequisition : PurchaseRequisitionState  
}

export const purchaseRequisitionReducers: Reducer<purchaseRequisitionStateStore> = combineReducers({
    PurchaseRequisition : PurchaseRequisitionReducers 
});
