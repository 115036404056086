import * as R from "ramda";
import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import {QueryMRPActions} from '../reducers/mrpList.reducer';
import {DateRangeFilter} from "../../shared/domains/query/filter";
import {SaveMrpFiltersError, SaveMrpFiltersSuccess} from "../../shared/actions/user.actions";
import {UserService} from "../../shared/domains/user/user.service";
import moment from 'moment';

function* saveFilters(action) {
  try {
    const dateRange: DateRangeFilter = R.path(['contractCreationDateRange', 'selected'], action.filters);
    const filters: any = {
      materials: R.path(['materials', 'selected'], action.filters),
      plants: R.path(['plants', 'selected'], action.filters),
      catalogType: R.path(['catalogType', 'selected'], action.filters),
      scope: R.path(['scope', 'selected'], action.filters),
      suppliers: R.path(['suppliers', 'selected'], action.filters),
      purchasingOrganizations: R.path(['purchasingOrganizations', 'selected'], action.filters),
      createdBy: R.path(['createdBy', 'selected'], action.filters),
      updatedBy: R.path(['updatedBy', 'selected'], action.filters),
      buyer: R.path(['buyer', 'selected'], action.filters),
      includeDeleted: R.path(['includeDeleted', 'selected'], action.filters),
      supplierPartId: R.path(['supplierPartId', 'selected'], action.filters),
      fromId: R.path(['fromId', 'selected'], action.filters).toString(),
      toId: R.path(['toId', 'selected'], action.filters).toString(),
      excludeExistingContracts: R.path(['excludeExistingContracts', 'selected'], action.filters),
      includeExistingContracts: R.path(['includeExistingContracts', 'selected'], action.filters),
      materialNumberBlankOnly: R.path(['materialNumberBlankOnly', 'selected'], action.filters),
      materialNumberOnly: R.path(['materialNumberOnly', 'selected'], action.filters),
      contractSyncError: R.path(['contractSyncError', 'selected'], action.filters),
      status: R.path(['status', 'selected'], action.filters),
      GetExpiredPrize: R.path(['getExpiredPrize', 'selected'], action.filters),
      ExpiringPrizeIn: R.path(['expiringPrizeIn', 'selected'], action.filters),
      contractCreationDateRange: (dateRange.startDate && dateRange.endDate) ?
        {
          startDate: moment.utc(dateRange.startDate, 'YYYYMMDD'),
          endDate: moment.utc(dateRange.endDate, 'YYYYMMDD')
        } : null,
    };
    yield call(UserService.saveMrpFilters, filters);
    yield put(new SaveMrpFiltersSuccess(filters));
  } catch (error) {
    yield put(new SaveMrpFiltersError(error));
  }
}

function* watchSaveFilters() {
  yield takeLatest(QueryMRPActions.onSaveFilters, saveFilters);
}

export default function* mrpFilterSaga() {
  yield all([
    fork(watchSaveFilters),
  ])
};
