import {DownloadCompletedAction, ErrorAction, PendingAction, SuccessAction} from "../domains/core/actions";

export enum UploadItemTypes {
  CB_Quotation = 0,
  PLS_Vendor_Sourcing = 1,
  Temp_File = 2
}

export enum UploadTypes {
  UPLOAD_ITEM = "UPLOAD_ITEM",
  UPLOAD_ITEM_SUCCESS = "UPLOAD_ITEM_SUCCESS",
  UPLOAD_ITEM_FAILURE = "UPLOAD_ITEM_FAILURE",

  DOWNLOAD_ITEM = "DOWNLOAD_ITEM",
  DOWNLOAD_ITEM_SUCCESS = "DOWNLOAD_ITEM_SUCCESS",
  DOWNLOAD_ITEM_FAILURE = "DOWNLOAD_ITEM_FAILURE",

  DOWNLOAD_MRP_EXPORT_ITEM = "DOWNLOAD_MRP_EXPORT_ITEM",
  DOWNLOAD_MRP_EXPORT_ITEM_SUCCESS = "DOWNLOAD_MRP_EXPORT_ITEM_SUCCESS",
  DOWNLOAD_MRP_EXPORT_ITEM_FAILURE = "DOWNLOAD_MRP_EXPORT_ITEM_FAILURE",

  DOWNLOAD_PSL_EXPORT_ITEM = "DOWNLOAD_PSL_EXPORT_ITEM",
  DOWNLOAD_PSL_EXPORT_ITEM_SUCCESS = "DOWNLOAD_PSL_EXPORT_ITEM_SUCCESS",
  DOWNLOAD_PSL_EXPORT_ITEM_FAILURE = "DOWNLOAD_PSL_EXPORT_ITEM_FAILURE",
  
  DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM = "DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM",
  DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM_SUCCESS = "DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM_SUCCESS",
  DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM_FAILURE = "DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM_FAILURE",
}

export class UploadItem extends PendingAction<UploadTypes> {
  public readonly type = UploadTypes.UPLOAD_ITEM;

  constructor(public itemType: UploadItemTypes, public formData, public onDone: (fileName: string) => void) {
    super();
  }
}

export class UploadItemSuccess extends SuccessAction<UploadTypes> {
  public readonly type = UploadTypes.UPLOAD_ITEM_SUCCESS;

  constructor(public fileName) {
    super();
  }
}

export class UploadItemError extends ErrorAction<UploadTypes> {
  public readonly type = UploadTypes.UPLOAD_ITEM_FAILURE;

  constructor(public error) {
    super();
  }
}

export class DownloadItem extends PendingAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_ITEM;

  constructor(public itemType: UploadItemTypes, public fileName) {
    super();
  }
}

export class DownloadItemSuccess extends DownloadCompletedAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_ITEM_SUCCESS;
}

export class DownloadItemError extends ErrorAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_ITEM_FAILURE;
}

export class DownloadExportMrpItem extends PendingAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_MRP_EXPORT_ITEM;

  constructor(public itemType: UploadItemTypes, public fileName) {
    super();
  }
}

export class DownloadExportMrpItemSuccess extends DownloadCompletedAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_MRP_EXPORT_ITEM_SUCCESS;
}

export class DownloadExportMrpItemError extends ErrorAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_MRP_EXPORT_ITEM_FAILURE;
}

export class DownloadExportPslItem extends PendingAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_PSL_EXPORT_ITEM;

  constructor(public itemType: UploadItemTypes, public fileName) {
    super();
  }
}

export class DownloadExportPslItemSuccess extends DownloadCompletedAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_PSL_EXPORT_ITEM_SUCCESS;
}

export class DownloadExportPslItemError extends ErrorAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_PSL_EXPORT_ITEM_FAILURE;
}


export class DownloadExportMrpMappingsItem extends PendingAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM;

  constructor(public itemType: UploadItemTypes, public fileName) {
    super();
  }
}

export class DownloadExportMrpMappingsItemSuccess extends DownloadCompletedAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM_SUCCESS;
}

export class DownloadExportMrpMappingsItemError extends ErrorAction<UploadTypes> {
  public readonly type = UploadTypes.DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM_FAILURE;
}
