import {mergeRight} from "ramda";
import {IAction} from "../../../shared/domains/core/actions";
import {ContractActionTypes} from "../../actions/contract.actions";
import {ProcSpecs} from "../../domains/contract/mrp/procSpecs";
import {error, loaded, loading, LoadState} from "../../../shared/reducers/utils";
import {Contract} from "../../domains/contract/contract";

export interface ContractMrpState extends LoadState {
  procSpecsList: ProcSpecs[];
  originalContracts: Contract[];
  approvalContract: Contract;
  approvalLoading: boolean;
}

export const initialContractMrpState: ContractMrpState = {
  procSpecsList: [],
  originalContracts: [],
  loading: false,
  approvalContract: null,
  approvalLoading: false,
  error: null
};

export function ContractMrpReducer(state: ContractMrpState = initialContractMrpState, action: IAction<ContractActionTypes>): ContractMrpState {
  switch (action.type) {
    case ContractActionTypes.FETCH_PROC_SPECS:
    case ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL:
      return loading(state);
    case ContractActionTypes.FETCH_ORIGINAL_MRP_CONTRACTS:
      return loading(mergeRight(state, {originalContracts: []}));
    case ContractActionTypes.REJECT_MRP:
    case ContractActionTypes.APPROVE_MRP:
      return mergeRight(state, {approvalLoading: true, approvalContract: action.contract});
    case ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL_SUCCESS:
      return loaded(state);
    case ContractActionTypes.FETCH_PROC_SPECS_SUCCESS:
      return loaded(mergeRight(state, {procSpecsList: action.procSpecsList}));
    case ContractActionTypes.FETCH_ORIGINAL_MRP_CONTRACTS_SUCCESS:
      return loaded(mergeRight(state, {originalContracts: action.contracts}));
    case ContractActionTypes.APPROVE_MRP_SUCCESS:
    case ContractActionTypes.REJECT_MRP_SUCCESS:
    case ContractActionTypes.APPROVE_MRP_FAILURE:
    case ContractActionTypes.REJECT_MRP_FAILURE:
      return mergeRight(state, {approvalLoading: false, approvalContract: null});
    case ContractActionTypes.FETCH_PROC_SPECS_FAILURE:
    case ContractActionTypes.FETCH_ORIGINAL_MRP_CONTRACTS_FAILURE:
    case ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}
