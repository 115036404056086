import {DocumentCounters, NetworkDocCountDTO} from "./documentCounter";
import ODataClientWrapper, {List} from "../../../shared/utils/odataClient.wrapper";
import {newQuery, Query} from "../../../shared/queryable/query";
import {mergeRight} from "ramda";

export class DocumentCounterService {
  static async fetchCounters(query: Query = newQuery()): Promise<DocumentCounters> {
    const q: Partial<Query> = {
      resource: "DocCountSet",
    };

    const result: List<NetworkDocCountDTO> = await ODataClientWrapper.get()
      .fromQ(mergeRight(q, query))
      .withMultiOrigin()
      .count()
      .run();

    return result.map(DocumentCounters.FromBackend).data[0];
  }
}
