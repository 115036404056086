import {PendingAction, SuccessAction} from "../../../shared/domains/core/actions";
import {IChatCounter} from "../../../shared/domains/chat/roomChat.service";

export enum InvoiceChatTypes {
  GET_MESSAGES_SUCCESS = 'GET_INVOICE_CHAT_MESSAGES',
  NEW_INVOICE_CHAT_COUNTER = "NEW_INVOICE_CHAT_COUNTER",
  GET_GROUPS_SUCCESS = 'GET_DOCFLOW_CHAT_GROUPS',
}

export class NewInvoiceChatCounter extends PendingAction<InvoiceChatTypes> {
  public readonly type = InvoiceChatTypes.NEW_INVOICE_CHAT_COUNTER;

  constructor(public counter: IChatCounter, public currentUserId?: number) {
    super();
  }
}

export class GetChatMessagesSuccess extends SuccessAction<InvoiceChatTypes> {
  public readonly type = InvoiceChatTypes.GET_MESSAGES_SUCCESS;

  constructor(public invoiceNumber: string, public messages) {
    super();
  }
}

export class GetChatGroupsSuccess extends SuccessAction<InvoiceChatTypes> {
  public readonly type = InvoiceChatTypes.GET_MESSAGES_SUCCESS;

  constructor(public prNo: string, public poNo: string, public chatGroups) {
    super();
  }
}
