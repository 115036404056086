import * as R from "ramda";
import { newQuery, Query, setSelectedFiltersBykey } from "../../shared/queryable/query";
import { FilterCategoryView, newTextFilterCategory } from "../../shared/domains/query/filter";
import { IMrpFilters } from "../../shared/domains/user/user";
import { Dictionary } from "ramda";
import moment from "moment";

export const DEFAULT_MRP_PAGE_SIZE = 50

export const mrpSearchOptions = [
  { value: "materialNumber", label: "Material Number" },
  { value: "materialDescription", label: "Material Description" },
  { value: "supplierNumber", label: "Supplier Number" },
  { value: "supplierName", label: "Supplier Name" },
  { value: "supplierPartID", label: "Supplier Part ID" },
  { value: "supplierPartIDDescription", label: "Supplier Part ID Description" },
  { value: "contractNumber", label: "Contract Number" }
];

export enum MrpFilterKeys {
  MATERIALS = "materials",
  PLANTS = "plants",
  SUPPLIERS = "suppliers",
  CATALOG_TYPE = "catalogType",
  PURCHASE_ORG = "purchasingOrganizations",
  CREATION_RANGE = "contractCreationDateRange",
  CREATED_BY = "createdBy",
  UPDATED_BY = "updatedBy",
  BUYER = "buyer",
  SCOPE = "scope",
  STATUS = "status",
  SUPPLIER_PART_ID = "supplierPartId",
  FROM_ID = "fromId",
  TO_ID = "toId",
  CONTRACT = "contractId",
  INCLUDE_DELETED = "includeDeleted",
  EXCLUDE_EXISTING_CONTRACTS = "excludeExistingContracts",
  INCLUDE_EXISTING_CONTRACTS = "includeExistingContracts",
  MATERIAL_NUMBER_BLANK_ONLY = "materialNumberBlankOnly",
  MATERIAL_NUMBER_ONLY = "materialNumberOnly",
  GET_EXPIRED_PRICE_LIST = "getExpiredPrize",
  EXPIRING_PRICE_IN = "expiringPrizeIn",
  ENTRIES_WITH_ERRONEOUS = "contractSyncError",
}

// add default value of any new filter added below, this is for reset button in filter page

export const initialMrpFiltersValue = {
  "suppliers": [],
  "purchasingOrganizations": [],
  "materials": [],
  "plants": [],
  "catalogType": null,
  "includeDeleted": false,
  "excludeExistingContracts": false,
  "contractCreationDateRange": null,
  "createdBy": [],
  "updatedBy": [],
  "buyer": [],
  "supplierPartId": null,
  "fromId": null,
  "toId": null,
  "materialNumberBlankOnly": false,
  "includeExistingContracts": false,
  "materialNumberOnly": false,
  "contractId": null,
  "scope": null,
  "status": null,
  "getExpiredPrize": false,
  "expiringPrizeIn": null,
  "contractSyncError" : false
};

export const MrpFilters = [
  { key: MrpFilterKeys.MATERIALS, label: 'Materials' },
  { key: MrpFilterKeys.PLANTS, label: 'Plants' },
  { key: MrpFilterKeys.SUPPLIERS, label: 'Suppliers' },
  { key: MrpFilterKeys.CATALOG_TYPE, label: 'Catalog Type' },
  { key: MrpFilterKeys.PURCHASE_ORG, label: 'Purchase Organizations' },
  { key: MrpFilterKeys.CREATED_BY, label: 'Created By' },
  { key: MrpFilterKeys.UPDATED_BY, label: 'Updated By' },
  { key: MrpFilterKeys.BUYER, label: 'Buyer Name' },
  { key: MrpFilterKeys.SCOPE, label: 'Scope' },
  { key: MrpFilterKeys.STATUS, label: 'Status' },
  { key: MrpFilterKeys.SUPPLIER_PART_ID, label: 'Supplier Part Id' },
  { key: MrpFilterKeys.FROM_ID, label: 'From Id' },
  { key: MrpFilterKeys.TO_ID, label: 'To Id' },
  { key: MrpFilterKeys.CONTRACT, label: 'Contracts' },
  { key: MrpFilterKeys.CREATION_RANGE, label: 'Entry created date' },
  { key: MrpFilterKeys.ENTRIES_WITH_ERRONEOUS, label: 'Entries with Erroneous' },
  { key: MrpFilterKeys.INCLUDE_DELETED, label: 'Include Deleted' },
  { key: MrpFilterKeys.EXCLUDE_EXISTING_CONTRACTS, label: 'Exclude Existing Contracts' },
  { key: MrpFilterKeys.INCLUDE_EXISTING_CONTRACTS, label: 'Entries with Contracts' },
  { key: MrpFilterKeys.MATERIAL_NUMBER_BLANK_ONLY, label: 'Materials not Mapped' },
  { key: MrpFilterKeys.MATERIAL_NUMBER_ONLY, label: 'Mapped Materials' },
  { key: MrpFilterKeys.EXPIRING_PRICE_IN, label: 'Expiring Price In :' },
  { key: MrpFilterKeys.GET_EXPIRED_PRICE_LIST, label: 'Expired Price List' },
];

const initial = MrpFilters.map(x => newTextFilterCategory(x.key, x.label));
const initialDictionary = R.indexBy(R.prop('key'), initial);

export function newMrpQuery(defaultFilters?: IMrpFilters): Query {
  let initialFilter: Dictionary<FilterCategoryView> = initialDictionary;
  if (defaultFilters) {
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.MATERIALS, defaultFilters.materials, initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.PLANTS, defaultFilters.plants, initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.SUPPLIERS, defaultFilters.suppliers, initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.PURCHASE_ORG, defaultFilters.purchasingOrganizations, initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.CREATED_BY, defaultFilters.createdBy, initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.UPDATED_BY, defaultFilters.updatedBy, initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.BUYER, defaultFilters.buyer, initialFilter);
    initialFilter = R.assocPath([MrpFilterKeys.SCOPE, 'selected'], defaultFilters?.scope, initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.SUPPLIER_PART_ID, defaultFilters.supplierPartId, initialFilter);
    initialFilter = R.assocPath([MrpFilterKeys.FROM_ID, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([MrpFilterKeys.TO_ID, 'selected'], "", initialFilter);
    initialFilter = setSelectedFiltersBykey(MrpFilterKeys.CONTRACT, defaultFilters.contractId, initialFilter);
    if (defaultFilters.contractCreationDateRange) {
      initialFilter = setSelectedFiltersBykey(MrpFilterKeys.CREATION_RANGE, defaultFilters.contractCreationDateRange, initialFilter);
    }
  }
  initialFilter = R.assocPath([MrpFilterKeys.CATALOG_TYPE, 'selected'], defaultFilters?.catalogType, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.INCLUDE_DELETED, 'selected'], defaultFilters?.includeDeleted, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.EXCLUDE_EXISTING_CONTRACTS, 'selected'], defaultFilters?.excludeExistingContracts, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.INCLUDE_EXISTING_CONTRACTS, 'selected'], defaultFilters?.includeExistingContracts, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.MATERIAL_NUMBER_BLANK_ONLY, 'selected'], defaultFilters?.materialNumberBlankOnly, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.MATERIAL_NUMBER_ONLY, 'selected'], defaultFilters?.materialNumberOnly, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.ENTRIES_WITH_ERRONEOUS, 'selected'], defaultFilters?.contractSyncError, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.STATUS, 'selected'], defaultFilters?.status, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.GET_EXPIRED_PRICE_LIST, 'selected'], defaultFilters?.getExpiredPrize, initialFilter);
  initialFilter = R.assocPath([MrpFilterKeys.EXPIRING_PRICE_IN, 'selected'], defaultFilters?.expiringPrizeIn, initialFilter);
  let query = newQuery(initialFilter);
  query.top = DEFAULT_MRP_PAGE_SIZE
  return query;
}

export function mrpFiltersToBackend(filters: any) {
  return {
    materials: filters?.materials?.selected ? filters.materials.selected : null,
    plants: filters?.plants?.selected ? filters.plants.selected : null,
    suppliers: filters?.suppliers?.selected ? filters.suppliers.selected : null,
    purchasingOrganizations: filters?.purchasingOrganizations?.selected ? filters.purchasingOrganizations.selected : null,
    createdBy: filters?.createdBy?.selected ? filters.createdBy.selected : null,
    updatedBy: filters?.updatedBy?.selected ? filters.updatedBy.selected : null,
    buyer: filters?.buyer?.selected ? filters.buyer.selected : null,
    catalogType: !R.isNil(filters?.catalogType?.selected) ? filters.catalogType.selected : null,
    scope: filters?.scope?.selected ? filters.scope.selected : null,
    status: filters?.status?.selected === 0 || 1 ? filters.status.selected : null,
    includeDeleted: filters.includeDeleted?.selected,
    excludeExistingContracts: filters.excludeExistingContracts?.selected,
    includeExistingContracts: filters.includeExistingContracts?.selected,
    supplierPartId: filters?.supplierPartId?.selected ? filters.supplierPartId.selected : null,
    fromId: filters.fromId?.selected.toString().length > 0 ? filters.fromId?.selected : "",
    toId: filters.toId?.selected.toString().length > 0 ? filters.toId?.selected : "",
    contractId: filters?.contractId?.selected ? filters.contractId.selected : null,
    materialNumberOnly: filters.materialNumberOnly?.selected,
    contractSyncError: filters.contractSyncError?.selected,
    materialNumberBlankOnly: filters.materialNumberBlankOnly?.selected,
    getExpiredPrize: filters.getExpiredPrize?.selected,
    expiringPrizeIn: filters.expiringPrizeIn?.selected,
    contractCreationDateRange: (filters?.contractCreationDateRange?.selected?.startDate && filters?.contractCreationDateRange?.selected?.endDate) ? {
      startDate: moment.utc(filters.contractCreationDateRange.selected.startDate, 'YYYYMMDD'),
      endDate: moment.utc(filters.contractCreationDateRange.selected.endDate, 'YYYYMMDD').add(23, 'hours').add(59, 'minutes').add(59, 'seconds').add(999, 'milliseconds'),
    } : null
  };
}
