import { ebRealm, ebUrl, sapUrl } from "src/app/shared/utils/urlBuilders";
import {template} from 'lodash';
import * as R from 'ramda';
import { TCode } from "src/app/docFlows/domains/documentFlow/documentFlow";

export interface FromNetworkNbsDataDTO {
    IdNo: string;
    PurchaseReqNo: string;
    PurchaseOrdNo: string;
    SystemAlias: string;
    PurchOrg: string;
    PurchOrgDesc: string;
    AgingDays: string;
    Category: string;
    ChangedOn: string;
    CreationDate: string;
    CompanyCode: string;
    CompanyCodeDesc: string;
    PrCreatorName: string;
    Prcreator_Email: string;
    CollectiveNumber: string;
    Prcurrency: string;
    Pramount: string;
    DocType: string;
    Requisitioner: string;
    Reqfnam: string;
    Reqlnam: string;
    Prcreatorfn: string;
    Prcreatorln: string;
    Prquantity: string;
    Pruom: string;
    AribaReqNum: string;
    FTValidation: string;
    Resend_po: string;
    CreationOn: string;
    PRupdate_date: string;
}

export class NbsData {
    constructor(
        public readonly IdNo,
        public readonly PurchaseReqNo,
        public readonly PurchaseOrdNo,
        public readonly SystemAlias,
        public readonly PurchOrg,
        public readonly PurchOrgDesc,
        public readonly AgingDays,
        public readonly ChangedOn,
        public readonly CreationDate,
        public readonly CompanyCode,
        public readonly CompanyCodeDesc,
        public readonly PrCreatorName,
        public readonly Prcreator_Email,
        public readonly PRupdate_date,
        public readonly CollectiveNumber,
        public readonly Prcurrency,
        public readonly Pramount,
        public readonly DocType,
        public readonly Requisitioner,
        public readonly Reqfnam,
        public readonly Reqlnam,
        public readonly Prcreatorfn,
        public readonly Prcreatorln,
        public readonly Prquantity,
        public readonly Pruom,
        public readonly Category,
        public readonly AribaReqNum,
        public readonly FTValidation,
        public readonly Resend_po,
        public readonly CreationOn
    ) {
    }

    static FromBackend(data: FromNetworkNbsDataDTO): NbsData {
        return new NbsData(
            data.IdNo,
            data.PurchaseReqNo,
            data.PurchaseOrdNo,
            data.SystemAlias,
            data.PurchOrg,
            data.PurchOrgDesc,
            data.AgingDays,
            data.ChangedOn,
            data.CreationDate,
            data.CompanyCode,
            data.CompanyCodeDesc,
            data.PrCreatorName,
            data.Prcreator_Email,
            data.PRupdate_date,
            data.CollectiveNumber,
            data.Prcurrency,
            data.Pramount,
            data.DocType,
            data.Requisitioner,
            data.Reqfnam,
            data.Reqlnam,
            data.Prcreatorfn,
            data.Prcreatorln,
            data.Prquantity,
            data.Pruom,
            data.Category,
            data.AribaReqNum,
            data.FTValidation,
            data.Resend_po,
            data.CreationOn
        )
    }
}

export interface FromNetworkNbsItemDataDTO {
    IdNo
    PrNo: string;
    PrItem: string;
    PoNo: string;
    PoItem: string;
    Material: string;
    ItemTxt: string;
    PrQuantity: string;
    PrQuantityUom: string;
    PrNetPrice: string;
    PrNetPriceUnit: string;
    PrCurrency: string;
    PrDeliveryDate: string;
    PrPlant: string;
    PrPlantTxt: string;
    Supplier: string;
    SupplierTxt: string;
    PurchGrpTxt: string;
    PurchGrp: string;
    HasAtta: string;
    PoOutRecipient: string;
    Prdoctype: string;
    Taxcode: string;
    Validity_Start: string;
    Validity_End: string;
    Threshold_p: string;
    Threshold_c: string;
    Supplier_Status: string;
    PR_PaymentTerms: string;
    PR_Ptermtext: string;
    Vendor_city: string;
    Vendorcity_Desc: string;
    Acc_Grp: string;
    Onetime_Ven: string;
    Freeitem: string;
    Hide_price: string;
    Supplier_Head: string;
    Supplier_Item: string;
    Nbs_Hcomments: string;
    Nbs_Comments: string;
    CompanyCode: string;
    Ven_ptermtxt: string;
    Ven_pterms: string;
    EPR_Supplier: string;
    PRtotprice: string;
    Threshold_otv_c: string;
    Threshold_otv_p: string;
    Currency_diff: string;
    Material_Grp: string;
    MatGrp_Desc: string;
}

export class NbsItemData {
    constructor(
        public readonly IdNo,
        public readonly PrNo,
        public readonly PrItem,
        public readonly PoNo,
        public readonly PoItem,
        public readonly Material,
        public readonly ItemTxt,
        public readonly PrQuantity,
        public readonly PrQuantityUom,
        public readonly PrNetPrice,
        public readonly PrNetPriceUnit,
        public readonly PrCurrency,
        public readonly PrDeliveryDate,
        public readonly PrPlant,
        public readonly PrPlantTxt,
        public readonly Supplier,
        public readonly SupplierTxt,
        public readonly PurchGrpTxt,
        public readonly PurchGrp,
        public readonly HasAtta,
        public readonly PoOutRecipient,
        public readonly Prdoctype,
        public readonly Taxcode,
        public readonly Validity_Start,
        public readonly Validity_End,
        public readonly Threshold_p,
        public readonly Threshold_c,
        public readonly Supplier_Status,
        public readonly PR_PaymentTerms,
        public readonly PR_Ptermtext,
        public readonly Vendor_city,
        public readonly Vendorcity_Desc,
        public readonly Acc_Grp,
        public readonly Onetime_Ven,
        public readonly Freeitem,
        public readonly Hide_price,
        public readonly Supplier_Head,
        public readonly Supplier_Item,
        public readonly Nbs_Hcomments,
        public readonly Nbs_Comments,
        public readonly CompanyCode,
        public readonly Ven_pterms,
        public readonly Ven_ptermtxt,
        public readonly EPR_Supplier,
        public readonly PRtotprice,
        public readonly Threshold_otv_c,
        public readonly Threshold_otv_p,
        public readonly Currency_diff,
        public readonly Material_Grp,
        public readonly MatGrp_Desc,
    ) {
    }

    static FromBackend(data: FromNetworkNbsItemDataDTO): NbsItemData {
        return new NbsItemData(
            data.IdNo,
            data.PrNo,
            data.PrItem,
            data.PoNo,
            data.PoItem,
            data.Material,
            data.ItemTxt,
            data.PrQuantity,
            data.PrQuantityUom,
            data.PrNetPrice,
            data.PrNetPriceUnit,
            data.PrCurrency,
            data.PrDeliveryDate,
            data.PrPlant,
            data.PrPlantTxt,
            data.Supplier,
            data.SupplierTxt,
            data.PurchGrpTxt,
            data.PurchGrp,
            data.HasAtta,
            data.PoOutRecipient,
            data.Prdoctype,
            data.Taxcode,
            data.Validity_Start,
            data.Validity_End,
            data.Threshold_p,
            data.Threshold_c,
            data.Supplier_Status,
            data.PR_PaymentTerms,
            data.PR_Ptermtext,
            data.Vendor_city,
            data.Vendorcity_Desc,
            data.Acc_Grp,
            data.Onetime_Ven,
            data.Freeitem,
            data.Hide_price,
            data.Supplier_Head,
            data.Supplier_Item,
            data.Nbs_Hcomments,
            data.Nbs_Comments,
            data.CompanyCode,
            data.Ven_pterms,
            data.Ven_ptermtxt,
            data.EPR_Supplier,
            data.PRtotprice,
            data.Threshold_otv_c,
            data.Threshold_otv_p,
            data.Currency_diff,
            data.Material_Grp,
            data.MatGrp_Desc,
        )
    }
}

export interface FromNetworkTrackerDataDTO {
    id: number;
    userId: string;
    invoiceNumber: string;
    poNumber: string;
    requestorName: string;
    requestorID: string;
    vendorNumber: string;
    vendorName: string;
    supplierInvoiceNumber: string;
    purchashingGroup: string;
    parkingBlockingDate: string;
    parkingBlockingReason: string;
    dateOfProcessing: string;
    taskResponsible: string;
    taskTaggedTo: string;
    comments: string;
    status: string;
    instructionDate: string;
    companyCode: string;
    dueDate: string;
    aging: string;
    toActor: string;
    enableNotification: boolean;
    notificationCount: number;
}

export class TrackerData {
    constructor(
        public readonly id,
        public readonly userId,
        public readonly invoiceNumber,
        public readonly poNumber,
        public readonly requestorName,
        public readonly requestorID,
        public readonly vendorNumber,
        public readonly vendorName,
        public readonly supplierInvoiceNumber,
        public readonly purchashingGroup,
        public readonly parkingBlockingDate,
        public readonly parkingBlockingReason,
        public readonly dateOfProcessing,
        public readonly taskResponsible,
        public readonly taskTaggedTo,
        public readonly comments,
        public readonly status,
        public readonly instructionDate,
        public readonly companyCode,
        public readonly dueDate,
        public readonly aging,
        public readonly toActor,
        public readonly enableNotification,
        public readonly notificationCount,
    ) {
    }

    static FromBackend(data: FromNetworkTrackerDataDTO): TrackerData {
        return new TrackerData(
            data.id,
            data.userId,
            data.invoiceNumber,
            data.poNumber,
            data.requestorName,
            data.requestorID,
            data.vendorNumber,
            data.vendorName,
            data.supplierInvoiceNumber,
            data.purchashingGroup,
            data.parkingBlockingDate,
            data.parkingBlockingReason,
            data.dateOfProcessing,
            data.taskResponsible,
            data.taskTaggedTo,
            data.comments,
            data.status,
            data.instructionDate,
            data.companyCode,
            data.dueDate,
            data.aging,
            data.toActor,
            data.enableNotification,
            +data.notificationCount
        )
    }
}

export interface FromNetworkNbsMainteanceTemplateDataDTO {
    id: number,
    purchasingOrganizationCode: string,
    purchasingOrganizationDesc: string,
    companyCode: string,
    companyCodeDesc: string,
    companyCodes: {
        code: string
        description: string
    }[],
    purchasingOrganizations: {
        code: string
        description: string
    }[],
    parkingBlockingReasonCode: string,
    parkingBlockingReasonDesc: string,
    receiverCode: string,
    receiverDesc: string,
    purchasingGroups:
    {
        code: string
        description: string
    }[],
    materialTypes: {
        code: string
        description: string
    }[],
    plants: {
        code: string
        description: string
    }[],
    instructions: string,
    createdBy: number,
    modifiedBy: number,
    createdOn: string,
    modifiedOn: string
}

export class NbsMainteanceTemplateData {
    constructor(
        public readonly id: number,
        public readonly purchasingOrganization: { id: string, title: string }[],
        public readonly companyCode: { id: string, title: string }[],
        public readonly purchasingGroup: { id: string, title: string }[],
        public readonly plant: { id: string, title: string }[],
        public readonly materialType: { id: string, title: string }[],
        public readonly parkingBlockingReason: { id: string, title: string }[],
        public readonly receiver: { id: string, title: string }[],
        public readonly instructions: string,
        public readonly createdBy: number,
        public readonly modifiedBy: number,
        public readonly createdOn: string,
        public readonly modifiedOn: string
    ) {
    }

    static FromBackend(data: FromNetworkNbsMainteanceTemplateDataDTO): NbsMainteanceTemplateData {
        return new NbsMainteanceTemplateData(
            data.id,
            data.purchasingOrganizations ? data.purchasingOrganizations.map(el => ({ id: el.code, title: el.description })) : [],
            // data.purchasingOrganizationCode ? [{
            //     "id": data.purchasingOrganizationCode,
            //     "title": data.purchasingOrganizationDesc
            // }] : [],
            data.companyCodes ? data.companyCodes.map(el => ({ id: el.code, title: el.description })) : [],
            // data.companyCodes ? data.companyCodes.map(el => ({ id: el.code, title: el.description })) : [],
            data.purchasingGroups ? data.purchasingGroups.map(el => ({ id: el.code, title: el.description })) : [],
            data.plants ? data.plants.map(el => ({ id: el.code, title: el.description })) : [],
            data.materialTypes ? data.materialTypes.map(el => ({ id: el.code, title: el.description })) : [],
            data.parkingBlockingReasonCode ? [{
                "id": data.parkingBlockingReasonCode,
                "title": data.parkingBlockingReasonDesc
            }] : [],
            data.receiverCode ? [{
                "id": data.receiverCode,
                "title": data.receiverDesc
            }] : [],
            data.instructions,
            data.createdBy,
            data.modifiedBy,
            data.createdOn,
            data.modifiedOn,
        )
    }
}

export interface FromNetworkNbsSapScopeDataDTO {
    // Id: string;

    prNo: string;
    aribaPR: string;

    poNo: string;
    aribaPO: string;

    prPriceRaw: string;
    prCurrency: string;

    threshold_P: string;
    threshold_C: string;

    oB_Indicator: string;
    oB_Rej_Ind: string;
    competitiveBiddingCreatorUserId: string;
    competitiveBiddingIdInAzure: string;
    isCbActive: string;
    sapAttchScn: string;

    creatorfnam: string;
    creatorlnam: string;
    creator: string;

    systemAlias: string;
    prCompanyCode: string;
    poCompanyCode: string;
    collectiveNumber: string;

    prCreationDate: string,
    poCreationDate: string,
    poCompanyCodeDesc: string,
    complianceIndicator: string,
    thresholdStatus:string,

    hasReleaseStrategy: boolean;
    itemList:any;
}

export class NbsSapScopeData {
    
    constructor(
  
        public readonly PurchaseReqNo:string,
        public readonly AribaPR:string,
        
        public readonly PurchaseOrdNo:string,
        public readonly AribaPO:string,

        public readonly PrPriceRaw:string,
        public readonly PrCurrency:string,

        public readonly Threshold_p:string,
        public readonly Threshold_c:string,

        public readonly OB_Indicator:string,
        public readonly OB_Rej_Ind:string,
        public readonly CompetitiveBiddingCreatorUserId:string,
        public readonly CompetitiveBiddingIdInAzure:string,
        public readonly IsCbActive:string,
        public readonly SapAttchScn:string,

        public readonly Creatorfnam:string,
        public readonly Creatorlnam:string,
        public readonly Creator:string,

        public readonly SystemAlias:string,
        public readonly PRCompanyCode:string,
        public readonly POCompanyCode:string,
        public readonly CollectiveNumber:string,

        public readonly PRCreationDate:string,
        public readonly POCreationDate:string,
        public readonly POCompanyCodeDesc:string,
        public readonly ThresholdStatus:string,
        public readonly ComplianceIndicator:string,

        public readonly HasReleaseStrategy:boolean,

        public readonly ViewPRSAPLink:string,
        public readonly ViewPOSAPLink:string,

        public readonly ItemNumbers: any
    ) {
    }

    static FromBackend(data: FromNetworkNbsSapScopeDataDTO): NbsSapScopeData {

        const ViewPRSAPTemplate = template(
            "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=P_BANFN%3d<%=pr%>%26&AutoStart=true"
        );
          
        const ViewPOSAPTemplate = template(
            "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=system%>&TCode=/GLB/RGTPT01_ME23N&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=&SAP_Source=EXT&SAP_IViewDesc=ME23N&DynamicParameter=P_EBELN%3d<%=po%>%26&AutoStart=true",
        );

        const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());
        const sapPrefixed = R.mergeRight({prefix: sapPrefix, system: data.systemAlias});
     
        const ViewPRSAP = ViewPRSAPTemplate(sapPrefixed({pr: data.prNo, code: TCode.PR_DISPLAY}));
        const ViewPOSAP = ViewPOSAPTemplate(sapPrefixed({po: data.poNo, code: TCode.PO_DISPLAY}));


        return new NbsSapScopeData(

            data.prNo,
            data.aribaPR,

            data.poNo,
            data.aribaPO,
         
            data.prPriceRaw,
            data.prCurrency,

            data.threshold_P,
            data.threshold_C,

            data.oB_Indicator,
            data.oB_Rej_Ind,
            data.competitiveBiddingCreatorUserId,
            data.competitiveBiddingIdInAzure,
            data.isCbActive,
            data.sapAttchScn,

            data.creatorfnam,
            data.creatorlnam,
            data.creator,

            data.systemAlias,
            data.prCompanyCode,
            data.poCompanyCode,
            data.collectiveNumber,

            data.prCreationDate,
            data.poCreationDate,
            data.poCompanyCodeDesc,
            data.thresholdStatus,
            data.complianceIndicator,

            data.hasReleaseStrategy,

            ViewPRSAP,
            ViewPOSAP,

            data.itemList
        )
    }
}

export interface FromNetworkNbsSapScopeLineItemsDataDTO {
    prNo:string,
    poNo:string,
    itemNo:string,
    field:string,
    oldValue:string,
    newValue:string,
    time:string,
    date:string,
    name:string,
    userId:string,
    updatedBy:string,
}

export class NbsSapScopeLineItemsData {
    
    constructor(
        public readonly PrNo:string,
        public readonly PoNo:string,
        public readonly ItemNo:string,
        public readonly Field:string,
        public readonly OldValue:string,
        public readonly NewValue:string,
        public readonly Time:string,
        public readonly Date:string,
        public readonly Name:string,
        public readonly UserId:string,
        public readonly UpdatedBy:string,
    ) {
    }

    static FromBackend(data: FromNetworkNbsSapScopeLineItemsDataDTO): NbsSapScopeLineItemsData {
        return new NbsSapScopeLineItemsData(
            data.prNo,
            data.poNo,
            data.itemNo,
            data.field,
            data.oldValue,
            data.newValue,
            data.time,
            data.date,
            data.name,
            data.userId,
            data.updatedBy,
        )
    }
}




