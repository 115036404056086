export interface NetworkDocCountDTO {
  __metadata: { id: string; type: string; uri: string };
  IdNo: string;
  BlockedCount: number;
  PendingApprCount: number;
  ApprovedCount: number;
  InvoicedCount: number;
}

export class DocumentCounters {
  constructor(
    public readonly BlockedCount: number,
    public readonly PendingApprCount: number,
    public readonly ApprovedCount: number,
    public readonly InvoicedCount: number,
  ) {}

  static FromBackend(dto: NetworkDocCountDTO): DocumentCounters {
    return new DocumentCounters(
      dto.BlockedCount,
      dto.PendingApprCount,
      dto.ApprovedCount,
      dto.InvoicedCount,
    )
  }
}
