import {combineReducers, Reducer} from "redux";
import {ContractReducer, ContractState} from "./contract/contract.reducer";
import {ItemsReducer, ItemState} from "./items/items.reducer";
import {ItemsPriceReducer, ItemsPriceState} from "./itemsPrice/itemsPrice.reducer";
import {ContractChatReducer} from "./contract/chat.reducer";
import {ValidationErrorsReducer, ValidationErrorsState} from "./contract/draft.reducer";
import {DraftItemsReducer, DraftItemsState} from "./items/draftItems.reducer";
import {DraftItemsPriceReducer, DraftItemsPriceState} from "./itemsPrice/draftItemsPrice.reducer";
import {ContractFiltersReducer, ContractFiltersState} from "./filters/contract.filters.reducer";
import {ContractDownloadUploadReducer, ContractDownloadUploadState} from "./contract/downloadUpload.reducer";
import {MrpItemsReducer, MrpItemsState} from "./items/mrpItems.reducer";
import {ClmContractReducer, ClmContractState} from "./contract/clm.reducer";
import {ClmItemsPriceReducer, ClmItemsPriceState} from "./itemsPrice/clmItemsPrice.reducer";
import {ContractMrpReducer, ContractMrpState} from "./contract/mrp.reducer";

export interface ContractsStateStore {
  contracts: ContractState,
  clmContract: ClmContractState,
  contractMrp: ContractMrpState,
  items: ItemState,
  draftItems: DraftItemsState,
  mrpItems: MrpItemsState,
  itemsPrice: ItemsPriceState,
  clmItemsPrice: ClmItemsPriceState,
  draftItemsPrice: DraftItemsPriceState,
  chat: any,
  drafts: ValidationErrorsState,
  filters: ContractFiltersState,
  downloadUpload: ContractDownloadUploadState
}

export const ContractsCombinedReducers: Reducer<ContractsStateStore> = combineReducers({
  contracts: ContractReducer,
  clmContract: ClmContractReducer,
  contractMrp: ContractMrpReducer,
  drafts: ValidationErrorsReducer,
  items: ItemsReducer,
  draftItems: DraftItemsReducer,
  mrpItems: MrpItemsReducer,
  itemsPrice: ItemsPriceReducer,
  clmItemsPrice: ClmItemsPriceReducer,
  draftItemsPrice: DraftItemsPriceReducer,
  chat: ContractChatReducer,
  filters: ContractFiltersReducer,
  downloadUpload: ContractDownloadUploadReducer
});
