import {template} from 'lodash';
import {sapUrl} from "../../../shared/utils/urlBuilders";
import {SAPBoolean, SAPBooleanToBoolean} from "../../../shared/domains/core/sapBoolean";
import {ClmContractAzureStatus, ClmContractStatus} from "./clm/contract.clm";
import {Features} from "../../../shared/domains/core/pataflag";
import {PataflagService} from "../../../shared/services/pataflag.service";

export enum ContractColumns {
  CHAT = "chat",
  FAVORITE = "favorite",
  DRAFT_STATUS = "draft_status",
  CONTRACT_NUMBER = "contract_number",
  REF_CONTRACT_NUMBER = "ref_contract_number",
  ITEMS = "items",
  SUPPLIER = "supplier",
  TYPE = "type",
  CURRENCY = "currency",
  COMPANY = "company",
  STRATEGIC_BUYER = "strategic_buyer",
  COVERAGE_MODEL = "coverage_model",
  VALIDITY_START = "validity_start",
  VALIDITY_END = "validity_end",
  STATUS = "status",
  LAST_CHANGED = "last_changed",
  APPROVE_REJECT = "approve_reject",
  CONSUMPTION = "consumption"
}

export enum ContractType {
  ACTIVE = "ACTIVE",
  RECENT = "RECENT",
  FAVORITES = "FAVORITES",
  DRAFT = "DRAFT",
  MRP = "MRP VIEW",
  EXPIRED = "EXPIRED"
}

export enum ContractSource {
  SAP = 'SAP',
  CLM_CHILD = 'CLM child',
  CLM_PARENT = 'CLM parent'
}

export enum DraftContractSource {
  CLONE_CONTRACT = 'Clone contract',
  CHANGES_REQUESTS = 'Changes requests'
}

export enum ContractValidityDate {
  START = "ValidityStart",
  END = "ValidityEnd",
}

const SapLinkTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=alias%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=<%alias%>&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=RM06E-EVRTN%3d<%=contract%>%26&AutoStart=true"
);

const PIRLinkTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=alias%>&TCode=/GLB/RGTPP01_PIR_UPL&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=<%=alias%>&SAP_Source=EXT&SAP_IViewDesc=/GLB/RGTPP01_PIR_UPL"
);

interface NetworkValidationContractMessageDTO {
  AgreementNo: string;
  TimeStamp: string;
  RefAgreementNo: string;
  MsgItemNo: string;
  Message: string;
}

export class ValidationContractMessage {
  constructor(
    public AgreementNo: string,
    public TimeStamp: string,
    public RefAgreementNo: string,
    public MsgItemNo: string,
    public Message: string,
  ) {}

  static FromBackend(dto: NetworkValidationContractMessageDTO): ValidationContractMessage {
    return new ValidationContractMessage(
      dto.AgreementNo,
      dto.TimeStamp,
      dto.RefAgreementNo,
      dto.MsgItemNo,
      dto.Message
    )
  }
}

interface NetworkExcelJobStatusDTO {
  userId: string;
  contractId: string;
  jobId: string;
  statusCode: string;
  description: string;
  sentToSapAt: string;
}

export class ExcelJobStatus {
  constructor(
    public UserId: string,
    public ContractId: string,
    public JobId: string,
    public StatusCode: string,
    public Description: string,
    public SentToSapAt: string
  ) {}

  static FromBackend(dto: NetworkExcelJobStatusDTO): ExcelJobStatus {
    return new ExcelJobStatus(
      dto.userId,
      dto.contractId,
      dto.jobId,
      dto.statusCode,
      dto.description,
      dto.sentToSapAt
    )
  }
}

interface NetworkExcelJobErrorDTO {
  AgreementNo: string;
  IdNo: string;
  JobCount: string;
  JobName: string;
  Message: string;
  MsgItemNo: string;
  Status: string;
}

export class ExcelJobError {
  constructor(
    public AgreementNo: string,
    public IdNo: string,
    public JobCount: string,
    public JobName: string,
    public Message: string,
    public MsgItemNo: string,
    public Status: string
  ) {}

  static FromBackend(dto: NetworkExcelJobErrorDTO): ExcelJobError {
    return new ExcelJobError(
      dto.AgreementNo,
      dto.IdNo,
      dto.JobCount,
      dto.JobName,
      dto.Message,
      dto.MsgItemNo,
      dto.Status
    )
  }
}

export interface NetworkContractDTO {
  __metadata: { id: string; type: string; uri: string };
  IdNo: string;
  AgreementNo: string;
  VendorNo: string;
  AgreementType: string;
  Currency: string;
  CompanyCode: string;
  PurchOrganization: string;
  AgmtDate: string;
  ValidityStart: string;
  ValidityEnd: string;
  NpdAgrNo: string;
  ParentAgrNo: string;
  ParentValidityStart: string;
  ParentValidityEnd: string;
  ParentIniExpiry: string;
  DataChanged: string;
  VendorName: string;
  AgreementTypeDesc: string;
  CompanyCodeDesc: string;
  PurchOrgDesc: string;
  Count: number;
  SearchTerm: string;
  ChangeCode: string;
  DisplayCode: string;
  BlockCount: string;
  DeleteCount: string;
  RefAgreementNo: string;
  Favourite: SAPBoolean;
  TotalCountOfComments: number;
  CountOfUnreadComments: number;
  ErrorCount: number;
  TimeStamp: string;
  TargetValue: string;
  Consumption: number;
  UpdatedAt: string;
  MrpView: string;
  UpdatedOn: string;
  StrategicBuyer: string;
  NVC: string;
  ClmDocType: string;
  PaymentTerms: string;
  Incoterm: string;
  IncotermDesc: string;
  PurchGroup: string;
  MRPPlanner: string;
  MRPApprover: string;
  StatusAzure?: ClmContractAzureStatus;
  StatusClm?: ClmContractStatus;
  StatusMessage?: string;
  SAP__Origin: string;
  SystemAlias: string;
  HeadToItem: { __deferred: { uri: string } };
}

export interface FavoritedContract {
  id: string;
  userId: string;
  favoriteId: string;
}

export const changeTargetValue = (contract: Contract, targetValue: string) => {
  if (targetValue) {
    return {...contract, TargetValue: targetValue};
  }
  return contract;
};

export type ContractNumber = string;

export class Contract {
  constructor(
    public readonly AgreementNo: ContractNumber,
    public readonly VendorNo: string,
    public readonly VendorName: string,
    public readonly AgreementType: string,
    public readonly AgreementTypeDesc: string,
    public readonly Currency: string,
    public readonly CompanyCode: string,
    public readonly CompanyCodeDesc: string,
    public readonly PurchOrganization: string,
    public readonly PurchOrgDesc: string,
    public readonly AgmtDate: string,
    public readonly ValidityStart: string,
    public readonly ValidityEnd: string,
    public readonly InitialExpiryDate: string,
    public readonly NpdAgrNo: string,
    public readonly ParentAgrNo: string,
    public readonly ParentValidityStart: string,
    public readonly ParentValidityEnd: string,
    public readonly Count: number,
    public readonly DisplaySAPUrl: string,
    public readonly EditSAPUrl: string,
    public readonly BlockCount: number,
    public readonly DeleteCount: number,
    public readonly Favourite: SAPBoolean,
    public readonly UpdatedAt: string,
    public readonly TotalCountOfComments: number = 0,
    public readonly CountOfUnreadComments: number = 0,
    public readonly ErrorCount: number = 0,
    public readonly RefAgreementNo: string = "",
    public readonly TimeStamp: string = "",
    public readonly Consumption: number = 0,
    public readonly TargetValue: string = "",
    public readonly StrategicBuyer: string,
    public readonly NVC: boolean,
    public readonly ClmDocType: string,
    public readonly PaymentTerms: string,
    public readonly Incoterm: string,
    public readonly IncotermDesc: string,
    public readonly PurchGroup: string,
    public readonly MRPPlanner: string,
    public readonly MRPApprover: string,
    public readonly MrpView: boolean,
    public readonly UpdatedOn: string,
    public readonly SystemAlias: string = "",
    public readonly ChangeCode: string = "",
    public readonly StatusAzure: ClmContractAzureStatus = ClmContractAzureStatus.Empty,
    public readonly StatusClm: ClmContractStatus = ClmContractStatus.Empty,
    public readonly StatusMessage: string = ""
  ) {
    this.TargetValue = TargetValue.trim();
  }

  static FromBackend(data: NetworkContractDTO): Contract {
    const prefix = sapUrl(process.env.REACT_APP_ENV.trim());
    const alias = !!data.SAP__Origin ? data.SAP__Origin : data.SystemAlias;
    const displaySAPUrl = SapLinkTemplate({
      prefix: prefix,
      alias: alias,
      code: data.DisplayCode,
      contract: data.AgreementNo
    });
    const editSAPUrl = SapLinkTemplate({
      prefix: prefix,
      alias: alias,
      code: data.ChangeCode,
      contract: data.AgreementNo
    });

    return new Contract(
      data.AgreementNo,
      data.VendorNo,
      data.VendorName,
      data.AgreementType,
      data.AgreementTypeDesc,
      data.Currency,
      data.CompanyCode,
      data.CompanyCodeDesc,
      data.PurchOrganization,
      data.PurchOrgDesc,
      data.AgmtDate,
      data.ValidityStart,
      data.ValidityEnd,
      data.ParentIniExpiry,
      data.NpdAgrNo,
      data.ParentAgrNo,
      data.ParentValidityStart,
      data.ParentValidityEnd,
      data.Count,
      displaySAPUrl,
      editSAPUrl,
      parseInt(data.BlockCount),
      parseInt(data.DeleteCount),
      data.Favourite,
      data.UpdatedAt,
      data.TotalCountOfComments,
      data.CountOfUnreadComments,
      data.ErrorCount,
      data.RefAgreementNo,
      data.TimeStamp,
      data.Consumption,
      data.TargetValue,
      data.StrategicBuyer,
      SAPBooleanToBoolean(data.NVC as SAPBoolean),
      data.ClmDocType,
      data.PaymentTerms,
      data.Incoterm,
      data.IncotermDesc,
      data.PurchGroup,
      data.MRPPlanner,
      data.MRPApprover,
      SAPBooleanToBoolean(data.MrpView as SAPBoolean),
      data.UpdatedOn,
      alias,
      data.ChangeCode,
      data.StatusAzure,
      data.StatusClm,
      data.StatusMessage
    )
  }

  static NVCItems(): Array<{value: boolean, label: string}> {
    return [ NVC, VC ]
  }

  static GetContractColumns(contractType: ContractType, contractSource: ContractSource, draftContractSource: DraftContractSource): {id: ContractColumns, show: boolean, hidden: boolean}[] {
    const userCols = [
      {id: ContractColumns.CHAT, show: true, hidden: contractType === ContractType.DRAFT && draftContractSource === DraftContractSource.CLONE_CONTRACT},
      {id: ContractColumns.FAVORITE, show: true, hidden: contractType === ContractType.DRAFT && draftContractSource === DraftContractSource.CLONE_CONTRACT},
      {id: ContractColumns.DRAFT_STATUS, show: true, hidden: contractType !== ContractType.DRAFT},
      {id: ContractColumns.CONTRACT_NUMBER, show: true, hidden: contractType === ContractType.DRAFT && draftContractSource === DraftContractSource.CLONE_CONTRACT},
      {id: ContractColumns.REF_CONTRACT_NUMBER, show: true, hidden: contractType !== ContractType.DRAFT},
      {id: ContractColumns.ITEMS, show: true, hidden: false},
      {id: ContractColumns.SUPPLIER, show: true, hidden: false},
      {id: ContractColumns.TYPE, show: true, hidden: false},
      {id: ContractColumns.CURRENCY, show: true, hidden: false},
      {id: ContractColumns.COMPANY, show: true, hidden: false},
      {id: ContractColumns.STRATEGIC_BUYER, show: true, hidden: false},
      {id: ContractColumns.COVERAGE_MODEL, show: true, hidden: false},
      {id: ContractColumns.VALIDITY_START, show: true, hidden: false},
      {id: ContractColumns.VALIDITY_END, show: true, hidden: false},
      {id: ContractColumns.CONSUMPTION, show: false, hidden: !PataflagService.getInstance().flag(Features.CONTRACT_CONSUMPTION_COLUMN)},
      {id: ContractColumns.LAST_CHANGED, show: true, hidden: contractType !== ContractType.RECENT},
      {id: ContractColumns.STATUS, show: true, hidden: contractSource !== ContractSource.CLM_CHILD},
      {id: ContractColumns.APPROVE_REJECT, show: true, hidden: !(contractType === ContractType.DRAFT && draftContractSource === DraftContractSource.CHANGES_REQUESTS)}
    ];
    const columns = localStorage.getItem('contract_table_config');
    if(columns) {
      const values = JSON.parse(columns) as {id: ContractColumns, show: boolean, hidden: boolean}[];
      return userCols.map((el, i)=>({
        ...values?.[i],
        hidden: el.hidden,
        id: el.id
      }))
    }
    return userCols;
  }

  static SetContractColumns(columns: {id: ContractColumns, show: boolean, hidden: boolean}[]): void {
    localStorage.setItem('contract_table_config', JSON.stringify(columns));
  }

  static IsActive(status: ClmContractAzureStatus) {
    return (status === ClmContractAzureStatus.Empty) || [
      ClmContractAzureStatus.Release,
      ClmContractAzureStatus.PartialReleased,
      ClmContractAzureStatus.RejectedReleased
    ].includes(status);
  }

  static Resendable(status: ClmContractAzureStatus) {
    return status !== ClmContractAzureStatus.Empty && [
      ClmContractAzureStatus.Release,
      ClmContractAzureStatus.PartialReleased,
      ClmContractAzureStatus.RejectedReleased
    ].includes(status);
  }

  static getCreatePIRLink(contacts: Contract[]) {
    const prefix = sapUrl(process.env.REACT_APP_ENV.trim());
    return PIRLinkTemplate({
      prefix: prefix,
      alias: contacts[0].SystemAlias
    });
  }
}

export interface NetworkDatalakeContractDTO {
  contract: string;
  sapServer: string;
  contractId: string;
}

export class DatalakeContract {
  constructor(
    public readonly Contract: string,
    public readonly SapServer: string,
    public readonly ContractId: string
  ) {}

  static FromBackend(data: NetworkDatalakeContractDTO): DatalakeContract {
    return new DatalakeContract(
      data.contract,
      data.sapServer,
      data.contractId
    )
  }
}

export const VC = { value: false, label: 'Volume Committed', shorten: 'VC' };
export const NVC = { value: true, label: 'Non-Volume Committed', shorten: 'NVC' };
