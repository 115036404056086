import {mergeLeft} from "ramda";

export const loading = mergeLeft({loading: true, error: null});
export const loaded = mergeLeft({loading: false, error: null});
export const explicitLoaded = mergeLeft({loading: false, loaded: true, error: null});
export const explicitLoading = mergeLeft({loading: true, loaded: false, error: null});
export const error = (error: string) => mergeLeft({loading: false, error: error});

export interface LoadState {
  loading: boolean;
  error: any;
}

export interface ExplicitLoadState extends LoadState {
  loaded: boolean;
}
