import { createStyles, makeStyles } from "@material-ui/core";


export const newEditRequestPageStyles = makeStyles(() =>
    createStyles({

        boldFont: {
            fontSize: 14,
            fontWeight: 500
        },
        normalFont: {
            fontSize: 14,
        },
        header: {
            fontSize: "16px",
            textAlign: "left",
            color: "#5D5858",            
            margin: '60px 0px 15px 0px',  
            paddingTop: 10          
        },
        totalHeading: {
            display:"flex",
            fontSize: "14px",
            color: "#7E7E7E",
            marginRight: "20px",
            fontWeight: "bolder"
        },
        totalValue: {
            fontSize: "15px",
            color: "#F19001",
            marginLeft: "5px",
            fontWeight: "bolder"
        },
        deleteButton: {
            background: "#FFFFFF 0% 0 % no - repeat padding- box",
            border: "1px solid #F19001",
            borderRadius: "5px",
            color: "#F19001",
        },
        itemHeader: { display: "flex", width: '100%',
         justifyContent: "space-between", alignItems: "center",
        margin: '60px 0px 10px 0px;',
        paddingTop: 10

        },
        label: {
            fontSize: 14,
            marginBottom: 5
        },
        deliveryAddHeader: {
            fontWeight: "bolder", margin: "20px 0"
        },
        avatarLabel: {
            marginBottom: 5,
            marginTop: 10,
            fontWeight: "bold",
        },
        select: {
            marginBottom: "10px!important"
        },
        lastSelect: {
            marginBottom: "5em!important"
        },
        input :{
            margin: 0,
            fontSize: 14,
            height: '100%',
            width: '100%',
            cursor: "pointer"
        },
        tab_selector_selected_tab: {
            borderBottom: '3px solid orange',
            color: 'orange !important'
        },
        sectionDiv:{
            padding: 30,
            paddingTop: 0,
            backgroundColor: 'white',           
          
        },
        paraWithMargin: {
            marginBottom: 18,
            fontSize: '0.8rem',
            fontWeight: 'bold',
            letterSpacing: '0.01071em'
        },
        button : { color: "#F19001", display: "flex", alignItems: "center", cursor: "pointer" },

        poSendingButton: {
            display: 'flex',         
            justifyContent: 'center'        ,
            alignItems: 'center'
        },

        pr_editor_title_tab_selector_link:{
            color: '#5D5858',
            // borderBottom: '3px solid gray',
            cursor: 'pointer',
            width: '25%',                     
        },

        individualTabStyle: {
            display: 'inline',
            marginRight: 30,            
            fontSize: 16,
            fontWeight: 500,
            color: '#5D5858',
            paddingBottom: 18
        },

        generalDetailsItemsStyles: {
            display: 'inline-block',
            marginRight: 60,
            float: 'left',
            fontSize: 14,
            color: 'rgb(124, 119, 119)'
        },
        generalDetailsDropDownStyles: {
            display: 'inline-block',
            marginRight: 60,
            float: 'left',
            fontSize: 14,
            color: 'rgb(124, 119, 119)',
            width: 200
        }

    })
);