import {combineReducers, Reducer} from "redux";
import {Queryable} from "../../shared/queryable/query.reducer";
import {PSLListReducer, PSLListState} from "./pslList.reducer";
import {supplierReducer, SupplierState} from "./supplier.reducer";
import {PslApproverListReducer, PslApproverListState} from "./pslApproverList.reducer";
import { spendCategoryTreeReducer } from "./spendCategoryTree.reducer";

export interface PSLStateStore {
  pslList: Queryable<PSLListState>;
  pslApproverList: Queryable<PslApproverListState>;
  supplier: SupplierState;
  spendCategoryTreeReducer: any[]
}

export const PSLCombinedReducers: Reducer<PSLStateStore> = combineReducers({
  pslList: PSLListReducer,
  pslApproverList: PslApproverListReducer,
  supplier: supplierReducer,
  spendCategoryTreeReducer: spendCategoryTreeReducer
});
