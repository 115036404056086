import { o } from 'odata';
import { flatten } from "ramda";
import { filtersMapper, User, UserFeedback } from "./user";
import { AxiosWrapper, Headers, MultiOriginValue } from "../../utils/axios.wrapper";
import { withAuthHeader } from "../auth/authentication.service";
import { environment } from "../core/environment";
import { DocFlowFilterTabs } from "../../../docFlows/domains/documentFlow/documentFlow";
import { PataflagService } from "../../services/pataflag.service";
import { Features } from "../core/pataflag";
import { IAddCompCodeData } from '../../containers/dashboard/components/addCompanyCode/addCompanyCode.component';

export interface IUserFullScope {
  zone: { id: number, name: string },
  fullZoneAccess: boolean,
  markets: IUserScope[]
}

export interface IUserScope {
  fullMarketAccess: boolean,
  market: { id: number, name: string },
  companyCodes: IUserScopeCompany[]
}

export interface IUserScopeCompany {
  companyCode: {
    id: number,
    code: string,
    name: string
  },
  companyCodeAccess: boolean,
}

export class UserService {
  static async fetchUser(): Promise<User> {
    const response = await AxiosWrapper.get("/api/Users/Me", { headers: { [Headers.MULTIORIGINS]: MultiOriginValue } });
    const user = response.data;
    return User.FromBackend(user);
  }

  static async fetchLiteUser(): Promise<User> {
    const response = await AxiosWrapper.get('/api/Users/MeLite');
    return User.FromBackend(response.data);
  }

  static async fetchUserContactDetails(email:string): Promise<any> {
    const url = "/api/Users/GetUserDetails?email=" + email;
    const response = await AxiosWrapper.get(url);
    return response.data;
  }

  static async fetchUserImageUrl(email:string): Promise<any> {
    const url = "/api/Users/GetUserDetails?email=" + email;
    const response = await AxiosWrapper.get(url);
    // return User.FromBackend(response.data);
    // remove below hard coded value once BE api is completed and return the BE response
    return "https://glbl-dv-contractbrowser-webapi-euwe-001-app.azurewebsites.net/api/users/photo?name=79FD381C80D3807B83F3F0896FA3DC33.jpg"
  }

  static async fetchZones(): Promise<any> {
    const response = await AxiosWrapper.get('/api/Locations/Zones');
    return response.data;
  }

  static async fetchUserAppFilters(): Promise<any> {
    const response = await AxiosWrapper.get(`/api/Settings/GetUserFilters`);
    return filtersMapper(response.data);
  }

  static async fetchUserProfile(): Promise<any> {
    const response = await AxiosWrapper.get(`/api/Settings/GetUserProfile`);
    return response.data;
  }

  static async getInteractionStatistics(): Promise<any> {
    const path = "/api/Users/GetUserInteractionStatistics";
    const response = await AxiosWrapper.get(path, { responseType: 'blob' });
    return response.data;
  }

  static async saveZoneSettings(zone: number): Promise<any> {
    const path = "/api/Users/PostUserProfileSettings";
    const response = await AxiosWrapper.post(path, {
      selectedZone: { id: zone }
    });
    return response.data;
  }

  static async saveUserSettings(payload: any): Promise<any> {
    const path = "/api/Settings/SaveUserProfile";
    const response = await AxiosWrapper.post(path, {
      userPreferredCompanyCode: payload.userPreferredCompanyCode,
      userPreferredCompanyName: payload.userPreferredCompanyName,
      userPreferredPslPersona: payload.userPreferredPslPersona,
      pslExpiryDays: payload.pslExpiryDays,
      dateTimeFormat: payload.dateTimeFormat,
      timeZone: payload.timeZone,
      decimalNotation: payload.decimalNotation,
      selectedZone: payload.zone ? {
        id: payload.zone
      } : null,
      contractSpecificProfile: {
        buyers: payload.contractSpecificProfile.buyers,
        clusters: payload.contractSpecificProfile.clusters,
        zones: payload.contractSpecificProfile.zones,
        markets: payload.contractSpecificProfile.markets?.map(m => m.name),
        spendCategoryCodes: payload.contractSpecificProfile.spendCategoryCodes,
        companyCodes: payload.contractSpecificProfile.companyCodes,
        plants: payload.contractSpecificProfile.plants,
        businesses: payload.contractSpecificProfile.businesses
      }
    });
    return response.data;
  }

  static async saveNewCompanyCode(newCompCodeData: IAddCompCodeData): Promise<any> {
    const path = "/api/PslLocationCompanies/AddLocationCompany";
    const response = await AxiosWrapper.post(path, newCompCodeData);
    return response.data;
  }

  static async saveContractFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Contracts";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveContractCalendarFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/ContractCalendar";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveDocflowFilters(filters, docType = DocFlowFilterTabs.DOCUMENTS): Promise<any> {
    const collection = (docType === DocFlowFilterTabs.FAVORITE) ? 'FavDocumentFlow' : 'DocumentFlow';
    const path = `/api/Settings/SaveFilters/${collection}`;
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveInvoiceFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Invoices";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async savePslFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Psl";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveMrpFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/Mrp";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveUserScopeFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/UserScope";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveCbFilters(filters): Promise<User> {
    const path = "/api/Settings/SaveFilters/CompetitiveBidding";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveBusinessApprovalFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/BusinessApproval";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async saveProcurementApprovalFilters(filters): Promise<any> {
    const path = "/api/Settings/SaveFilters/PurchasingApproval";
    const response = await AxiosWrapper.post(path, filters);
    return response.data;
  }

  static async fetchUserScope(flattened: boolean): Promise<IUserScopeCompany[] | IUserScope[]> {
    const response = await AxiosWrapper.get('/api/Users/GetUserAuthorizedScope');
    return flattened ? flatten(response.data.map(item => item.companyCodes)) : response.data;
  }

  static async fetchUserFullScope(): Promise<IUserFullScope[]> {
    const response = await AxiosWrapper.get('/api/Users/GetUserAuthorizedFullScope');
    return response.data;
  }

  static async viewChanges(changeIds: number[]): Promise<boolean> {
    const path = '/api/Users/PostUserReadChangeLogItems';
    await AxiosWrapper.post(path, { ids: changeIds });
    return true;
  }

  static async sendFeedback(feedback: UserFeedback): Promise<any> {
    const url = environment.REACT_APP_API_BASE + "/odata/UsersFeedback";
    try {
      await o(url, {
        headers: { ...await withAuthHeader(), "Content-Type": "application/json" },
        fragment: null
      }).post('UsersFeedback', UserService.updateFeedbackBody(feedback)).query();
    } catch (e) {
      throw ({ response: { data: await e.json(), status: e.status } });
    }
  }

  static async refusedFeedback(): Promise<any> {
    const url = environment.REACT_APP_API_BASE + "/odata/UsersFeedback(1)";
    try {
      await o(url, {
        headers: { ...await withAuthHeader(), "Content-Type": "application/json" },
        fragment: null
      }).post('UsersFeedback(1)').query();
    } catch (e) {
      throw ({ response: { data: await e.json(), status: e.status } });
    }
  }
  static async updateFeedbackAdminDetails(status: number, admins: string, id: string): Promise<any> {
    const path = `/odata/UsersFeedback(${id})`;
    const response = await AxiosWrapper.put(path, { Status: status, Admin: admins });
    return response;
  }
  private static updateFeedbackBody(feedback: UserFeedback): any {
    return {
      Module: feedback.module,
      BugMessage: feedback.bugMessage,
      ImprovementMessage: feedback.improvementMessage,
      NewFeatureMessage: feedback.newFeatureMessage,
      Rating: feedback.rating,
      NetPromoterScore: feedback.netPromoterScore
    }
  }
}
