import {PFClient} from "@pataflags/sdk-js";
import {AxiosWrapper} from "../utils/axios.wrapper";
import {FeaturesLoaded} from "../actions/user.actions";

export class PataflagService {
  private static _instance: PFClient;

  static setInstance(pfClient: PFClient) {
    this._instance = pfClient;
  }

  static getInstance() {
    return this._instance;
  }

  static async fetchFlagsInitialize(): Promise<FeaturesLoaded> {
    const response = await AxiosWrapper.get('/api/FFlags/Initialize');
    return new FeaturesLoaded(response.data);
  }
}
