import {defaultTo, isEmpty, compose, symmetricDifference} from "ramda";
import moment from 'moment';
import {ODataFormats} from "../../../lib/odata";
import {IContractCalendarFilters, IContractSpecificProfile} from "../../shared/domains/user/user";

export const CALENDAR_ALL_OPTION = {code: 'ALL', name: 'ALL'};

export const DayOfTwoNextMonths = moment().startOf("month").add(2, "months").startOf("day");

export enum CalendarViewMode {
  WEEK = "Week",
  MONTH = "Month"
}

interface NetworkODataCodeNameDTO {
  Code: string;
  Name: string;
}

export class CodeNameDTO {
  constructor(
    public code: string|number,
    public name: string
  ) {}

  static FromBackend(data: NetworkODataCodeNameDTO): CodeNameDTO {
    return new CodeNameDTO(
      data.Code,
      data.Name
    )
  }

  static Equality(a: CodeNameDTO, b: CodeNameDTO) {
    return a.code === b.code;
  }
}

export class MarketDTO {
  constructor(
    public code: string,
    public name: string
  ) {}

  static FromBackend(data: string): CodeNameDTO {
    return new CodeNameDTO(data, data)
  }
}

export class IdNameDTO {
  constructor(
    public id: string,
    public name: string
  ) {}
}

export class BuyerDTO {
  constructor(
    public id: number,
    public email: string,
    public username: string
  ) {}
}

export class NegotiationType extends IdNameDTO {}
export class Buyer extends BuyerDTO {}
export class SpendCategory extends CodeNameDTO {}
export class Cluster extends CodeNameDTO {}
export class Zone extends CodeNameDTO {}
export class Market extends CodeNameDTO {}
export class CompanyCode extends CodeNameDTO {}
export class Plant extends CodeNameDTO {}
export class Business extends CodeNameDTO {}

export interface IHierachyLoading {
  zones: boolean;
  markets: boolean;
  companyCodes: boolean;
  plants: boolean;
}

interface NetworkContractCalendarEventInfoDTO {
  spendCategories: CodeNameDTO[];
  clusters: CodeNameDTO[];
  zones: CodeNameDTO[];
  markets: string[];
  companyCodes: CodeNameDTO[];
  plants: CodeNameDTO[];
  businesses: CodeNameDTO[];
  negotiationType: IdNameDTO[];
  buyers: BuyerDTO[];
}

export class ContractCalendarEventInfo {
  constructor(
    public spendCategories: CodeNameDTO[],
    public clusters: CodeNameDTO[],
    public zones: CodeNameDTO[],
    public markets: CodeNameDTO[],
    public companyCodes: CodeNameDTO[],
    public plants: CodeNameDTO[],
    public businesses: CodeNameDTO[],
    public negotiationType: IdNameDTO[],
    public buyers: BuyerDTO[]
  ) { }

  static FromBackend(data: NetworkContractCalendarEventInfoDTO): ContractCalendarEventInfo {
    return new ContractCalendarEventInfo(
      data.spendCategories,
      data.clusters,
      data.zones,
      data.markets.map(MarketDTO.FromBackend),
      data.companyCodes,
      data.plants,
      data.businesses,
      data.negotiationType,
      data.buyers
    )
  }
}

export interface NetworkODataContractCalendarEventDTO {
  Id: number;
  CreatedOn: string;
  Title: string;
  BusinessCode: string;
  BusinessName: string;
  BuyerId: number;
  BuyerEmail: string;
  CreatedByUserName: string;
  From: any;
  To: any;
  Companies: NetworkODataCodeNameDTO[];
  Clusters: NetworkODataCodeNameDTO[];
  SpendCategoryLevel1s: NetworkODataCodeNameDTO[];
  Markets: string[];
  Plants: NetworkODataCodeNameDTO[];
  Zones: NetworkODataCodeNameDTO[];
  NegotiationTypeId: number;
  PeriodFrom: string;
  PeriodTo: string;
  IsAllSpendCategoryLevel1s: boolean;
  IsAllClusters: boolean;
  IsAllZones: boolean;
  IsAllMarkets: boolean;
  IsAllPlants: boolean;
}

export interface NetworkContractCalendarEventDTO {
  id: number;
  createdOn: string;
  title: string;
  businessCode: string;
  businessName: string;
  buyerId: number;
  buyerEmail: string;
  buyerDateAvailability: string;
  createdByUserName: string;
  from: any;
  to: any;
  companies: CodeNameDTO[];
  clusters: CodeNameDTO[];
  spendCategoryLevel1s: CodeNameDTO[];
  markets: string[];
  plants: CodeNameDTO[];
  zones: CodeNameDTO[];
  negotiationTypeId: number;
  periodFrom: string;
  periodTo: string;
}

export class ContractCalendarEvent {
  constructor(
    public id: number,
    public createdOn: string,
    public title: string,
    public businessCode: string,
    public businessName: string,
    public buyerId: number,
    public buyerEmail: string,
    public createdByUserName: string,
    public startDate: string,
    public endDate: string,
    public companies: CodeNameDTO[],
    public clusters: CodeNameDTO[],
    public spendCategoryLevel1s: any[],
    public markets: CodeNameDTO[],
    public plants: CodeNameDTO[],
    public zones: CodeNameDTO[],
    public negotiationTypeId: number,
    public periodFrom: string,
    public periodTo: string,
    public allDay: boolean = false,
    public buyerName: string = ''
  ) {}

  static FromODataBackend(data: NetworkODataContractCalendarEventDTO): ContractCalendarEvent {
    return new ContractCalendarEvent(
      data.Id,
      data.CreatedOn,
      data.Title,
      data.BusinessCode,
      data.BusinessName,
      data.BuyerId,
      data.BuyerEmail,
      data.CreatedByUserName,
      moment.utc(data.From, ODataFormats.ISO_DATE).toISOString(),
      moment.utc(data.To, ODataFormats.ISO_DATE).toISOString(),
      data.Companies?.map(CodeNameDTO.FromBackend),
      data.Clusters.map(CodeNameDTO.FromBackend),
      data.SpendCategoryLevel1s.map(CodeNameDTO.FromBackend),
      data.Markets.map(MarketDTO.FromBackend),
      data.Plants.map(CodeNameDTO.FromBackend),
      data.Zones.map(CodeNameDTO.FromBackend),
      data.NegotiationTypeId,
      data.PeriodFrom,
      data.PeriodTo,
      Math.abs(moment(data.From).diff(moment(data.To), "days")) > 0
    )
  }

  static FromBackend(data: NetworkContractCalendarEventDTO): ContractCalendarEvent {
    return new ContractCalendarEvent(
      data.id,
      data.createdOn,
      data.title,
      data.businessCode,
      data.businessName,
      data.buyerId,
      data.buyerEmail,
      data.createdByUserName,
      moment.utc(data.from, ODataFormats.ISO_DATE).toISOString(),
      moment.utc(data.to, ODataFormats.ISO_DATE).toISOString(),
      data.companies,
      data.clusters,
      data.spendCategoryLevel1s,
      data.markets.map(MarketDTO.FromBackend),
      data.plants,
      data.zones,
      data.negotiationTypeId,
      data.periodFrom,
      data.periodTo,
      Math.abs(moment(data.from).diff(moment(data.to), "days")) > 0
    )
  }
}

export const codeNameMapper = ({code, name}) => ({value: code, label: name});

export const codeNameCompare = (source, destination, key = 'code') => {
  if (typeof destination === "object") return source[key] === destination.code;
  return source[key] === destination;
};

export const isAllOption = (source) => {
  return (typeof source === "object") ? source.code === CALENDAR_ALL_OPTION.code : source === CALENDAR_ALL_OPTION.code;
};

export const hasAllOption = (source) => {
  return !!source?.find(isAllOption);
};

export const withAll = (source) => {
  if (!isEmpty(source)) return [CALENDAR_ALL_OPTION, ...source];
  return [];
};

export const withoutAll = (source) => {
  if (!isEmpty(source)) return source.filter(o => (typeof o === "object") ? o.value !== CALENDAR_ALL_OPTION.code : o !== CALENDAR_ALL_OPTION.code);
  return [];
};

export const isFiltersSynced = (calendarFilters: IContractCalendarFilters, contractSpecificProfile: IContractSpecificProfile) => {
  const isDiff: any = (l1, l2) => (compose(isEmpty, symmetricDifference) as any)(defaultTo([], l1), defaultTo([], l2));
  return (
    isDiff(calendarFilters.clusters?.map(c => c.id), contractSpecificProfile?.clusters.map(c => c.code)) &&
    isDiff(calendarFilters.zones?.map(c => c.id), contractSpecificProfile?.zones.map(c => c.code)) &&
    isDiff(calendarFilters.markets?.map(c => c.id), contractSpecificProfile?.markets) &&
    isDiff(calendarFilters.spendCategories?.map(c => c.id), contractSpecificProfile?.spendCategoryCodes.map(c => c.code)) &&
    isDiff(calendarFilters.companyCodes?.map(c => c.id), contractSpecificProfile?.companyCodes.map(c => c.code)) &&
    isDiff(calendarFilters.plants?.map(c => c.id), contractSpecificProfile?.plants.map(c => c.code)) &&
    isDiff(calendarFilters.businesses?.map(c => c.id), contractSpecificProfile?.businesses.map(c => c.code))
  )
};
