import {AzureErrorAction, ErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {UserRating} from "../domains/feedback";
import {List} from "../../shared/utils/odataClient.wrapper";
import {newQuery, Query} from "../../shared/queryable/query";

export enum RatingTypes {
  FETCH_RATING_GRAPH = "FETCH_RATING_GRAPH",
  FETCH_RATING_GRAPH_SUCCESS = "FETCH_RATING_GRAPH_SUCCESS",
  FETCH_RATING_GRAPH_FAILURE = "FETCH_RATING_GRAPH_FAILURE",

  FETCH_RATING_LIST = "FETCH_RATING_LIST",
  FETCH_RATING_LIST_REQUEST = "FETCH_RATING_LIST_REQUEST",
  FETCH_RATING_LIST_REQUEST_SUCCESS = "FETCH_RATING_LIST_REQUEST_SUCCESS",
  FETCH_RATING_LIST_REQUEST_FAILURE = "FETCH_RATING_LIST_REQUEST_FAILURE",
}

export class FetchRatingGraph extends PendingAction<RatingTypes> {
  public readonly type = RatingTypes.FETCH_RATING_GRAPH;

  constructor(public readonly query) {
    super();
  }
}

export class FetchRatingGraphSuccess extends SuccessAction<RatingTypes> {
  public readonly type = RatingTypes.FETCH_RATING_GRAPH_SUCCESS;

  constructor(public payload: List<UserRating>) {
    super();
  }
}

export class FetchRatingGraphError extends AzureErrorAction<RatingTypes> {
  public readonly type = RatingTypes.FETCH_RATING_GRAPH_FAILURE;
}

export class FetchRatingList extends PendingAction<RatingTypes> {
  public readonly type = RatingTypes.FETCH_RATING_LIST;

  constructor(public readonly query: Query = newQuery()) {
    super();
  }
}
export class FetchRatingListRequest extends PendingAction<RatingTypes> {
  public readonly type = RatingTypes.FETCH_RATING_LIST_REQUEST;
}
export class FetchRatingListRequestSuccess extends SuccessAction<RatingTypes> {
  public readonly type = RatingTypes.FETCH_RATING_LIST_REQUEST_SUCCESS;
  constructor(public payload: List<UserRating>) {
    super();
  }
}
export class FetchRatingListRequestError extends AzureErrorAction<RatingTypes> {
  public readonly type = RatingTypes.FETCH_RATING_LIST_REQUEST_FAILURE;
}
