import {mergeRight} from "ramda";
import {IAction} from "../../shared/domains/core/actions";
import {MRPTypes} from "../actions/mrp.actions";
import {Mrp} from "../domains/mrp";

export interface MrpState {
  loading: boolean;
  mrp: Mrp;
  error: {
    file: string,
    validationErrors: {key: number, value: {code: string, message: string}[]}[],
    parsingErrors: {lineId: number, failedPropertyToParse: string, parsingExceptionMessage: string}[]
  };
  importing: boolean;
  importFailed: boolean;
  importTimeout: boolean;
}

export const initialSupplierState: MrpState = {
  loading: false,
  error: null,
  mrp: null,
  importing: false,
  importFailed: false,
  importTimeout: false
}

export function mrpReducer(state: MrpState = initialSupplierState, action: IAction<MRPTypes>): MrpState {
  switch (action.type) {

    case MRPTypes.IMPORT_MRP_ENTITY:
      return mergeRight(state, {importing: true});
    case MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA:
      case MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_EMAIL:
      return mergeRight(state, {loading: true});
    case MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_SUCCESS:
      return mergeRight(state, {loading: false});
    case MRPTypes.IMPORT_MRP_ENTITY_SUCCESS:
    case MRPTypes.SAVE_MRP_ENTITY_FAILURE:
    case MRPTypes.EXPORT_MRP_ENTITY_LIST_DATA_FAILURE:
      return mergeRight(state, {importing: false, loading: false, error: action.error});
    case MRPTypes.IMPORT_MRP_ENTITY_FAILURE:
      return mergeRight(state, {loading: false, importing: false, importFailed: true, error: {
          file: action.file,
          validationErrors: action.validationErrors,
          parsingErrors: action.parsingErrors
        }});
    case MRPTypes.RESET_MRP_ENTITY_IMPORT_ERROR:
      return mergeRight(state, {importFailed: false, importTimeout: false});
    case MRPTypes.IMPORT_MRP_ENTITY_TIMEOUT:
      return mergeRight(state, {importTimeout: true, importing: false});
    case MRPTypes.FETCH_SINGLE_MRP_ENTITY_SUCCESS:
      return {...state, mrp: action.mrp};
    case MRPTypes.RESET_MRP_ENTITY_EXPORT:
      return mergeRight(state, {loading: false});
    default:
      return state;
  }
}
