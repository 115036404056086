import {RootStateStore} from "../../../application.reducers";
import PslFiltersPage, {PslFilterDispatcher, PslFilterProps} from "./filtersRefactored.page";
import {Query} from "../../../shared/queryable/query";
import {Dispatch} from "redux";
import {IAction} from "../../../shared/domains/core/actions";
import {connect} from "react-redux";
import {QueryPSLActions} from "../../reducers/pslList.reducer";
import {SupplierTabs} from "../../domains/psl";
import { SavePslApproverFilters } from "../../actions/psl.actions";

function mapStateToProps(state: RootStateStore): PslFilterProps {
  const query: Query = state.psl.pslList.query;
  return {
    data: query.filters,
    user: state.shared.user.currentUser,
    approverQuery: state.psl.pslApproverList.query
  }
}

function mapDispatchToProps(dispatch: Dispatch<IAction>): PslFilterDispatcher {
  return {
    fetchFilters: () => dispatch({type: QueryPSLActions.onLoadFilters}),
    // updateFilters: (newFilters: any, tab: SupplierTabs) => dispatch(new QueryPSLActions.newFilters(newFilters, tab)),
    updateFilters: (newFilters: any, tab: SupplierTabs, isApproval: boolean = false) => dispatch(
      //  isApproval ?
      // new QueryPslApproverActions.newFilters(newFilters, tab) :
       new QueryPSLActions.newFilters(newFilters, tab)),
    saveApproversFilter: (filters: any) => dispatch(new SavePslApproverFilters(filters)),
    resetFilters: (tab: SupplierTabs) => dispatch(new QueryPSLActions.resetFilters(tab))
  }
}

export const PslFilterContainer = connect(mapStateToProps, mapDispatchToProps)(PslFiltersPage);
