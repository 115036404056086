export interface FromNetworkPRItemDTO {
    Threshold_p: string;
    Threshold_c: string;
    CBFileData: any;
    Compliance_Ind: string;
    Supplier_Status: string;
    Aprappba: string,
    Aprapppa: string,
    AribaPO: string,
    AribaPR: string,
    ClosePoInd: string,
    CollectiveNumber: string,
    CompCode: string,
    CompCodeTxt: string,
    ContractNo: string,
    CostCenter: string,
    CostCenterTxt: string,
    DelCompInd: string,
    ExtContrNo: string,
    ExtReqNo: string,
    FinalInvInd: string,
    GlAccount: string,
    GlAccountTxt: string,
    GrDocNum: string,
    GrInd: string,
    GrProgress: string,
    GrQty: string,
    GrQtyUom: string,
    HasAtta: string,
    HdrSupplierComm:string,
    ITFMCstPln: string,
    IdNo: string,
    IrAmount: string,
    IrAmountCurr: string,
    IrAmt: string,
    IrDocNum: string,
    IrProgress: string,
    IrTot: string,
    Ir_Amamt: string,
    IsFavorite: string,
    IsMyGr: string,
    IsSapGr: string,
    ItemTxt: string,
    Material: string,
    MaterialType: string,
    MigoLabel: string,
    MigoUrl: string,
    MyGrComLabel: string,
    MyGrComUrl: string,
    MyGrConfLabel: string,
    MyGrConfUrl: string,
    MyGrExcLabel: string,
    MyGrExcUrl: string,
    MyGrTab: string,
    NextAction: string,
    POApprovaldate: string,
    POApprovername: string,
    POCreatoremail: string,
    PO_Addno: string,
    PPMCstPln: string,
    PRApprovaldate: string,
    PRApprovername: string,
    Plant: string,
    PlantTxt: string,
    PoComments: string,
    PoCreator: string,
    PoCreationDate: string,
    PoCurrency: string,
    PoDeliveryDate: string,
    PoDocType: string,
    PoItem: string,
    PoItemTxt: string,
    PoMaterial: string,
    PoNetPrice: string,
    PoNetPricePerUnit: string,
    PoNetPriceRaw: string,
    PoNetPriceUnit: string,
    PoNo: string,
    PoOutRecipient: string,
    PoQuantity: string,
    PoQuantityRaw: string,
    PoQuantityUom: string,
    PoReqFname: string,
    PoReqLname: string,
    PoRequisitioner: string,
    PoTotalPrice: string,
    Incoterms_Text: string,
    AllIncoterms_Data: string,
    PrApprover: string,
    PrApprovalDate: string,
    PrCreationDate: string,
    PrCurrency: string,
    PrDeliveryDate: string,
    PrItem: string,
    PrItemTxt: string,
    PrMaterial: string,
    PrNetPrice: string,
    PrNetPriceRaw: string,
    PrNetPriceUnit: string,
    PRCreatoremail:string,
    PRReqemail:string,
    PrNo: string,
    PrPrice: string,
    PrPriceRaw: string,
    PrQuantity: string,
    PrQuantityRaw: string,
    PrQuantityUom: string,
    PrReqFname: string,
    PrReqLname: string,
    PrRequisitioner: string,
    Prdoctype: string,
    PR_Doctype: string,
    PR_Inv_Partner:string,
    PR_Inv_Partner_Data:string,
    PrePayment: string,
    PuchOrg: string,
    PurchGrp: string,
    PurchGrpTxt: string,
    PurchOrgTxt: string,
    ReqFname: string,
    ReqLname: string,
    Requisitioner: string,
    SapAttchScn: string,
    SearchTerm: string,
    SendPDF: string,
    Supplier: string,
    SupplierTxt: string,
    SystemAlias: string,
    WBSelement: string,
    WBSelementId: string,
    WbsElementTxt: string,
    PrCreator: string,
    PrCreatorfnam: string,
    PrCreatorlnam: string,
    PrPlant: string,
    PrPlantTxt: string,
    Pr_CostCenter: string,
    Pr_CostCenterTxt: string,
    Pr_wbsid: string,
    Pr_WbsElementTxt: string,
    Pr_GlAccount: string,
    Pr_GlAccountTxt: string,
    Pr_BAApproval: string,
    Pr_PAApproval: string,
    Pr_status: string,
    Taxcode: string,
    Validity_Start: string,
    Validity_End: string,
    VendorMaterial:string,
    Name1: string,
    Name2: string,
    Houseno: string,
    Street: string,
    Postalcode: string,
    City: string,
    Region: string,
    Country: string,
    Textsupplier: string,
    PR_PROFIT: string,
    PR_PaymentTerms: string,
    PO_PaymentTerms: string,
    PR_Deleted: string,
    PR_AccCategory: string,
    PR_Addno: string,
    PR_ItemCategory: string,
    PR_Itemcount: string,
    PR_Ordering_Add:string,
    PR_Ordering_Add_Data:string,
    PO_Itemcount: string,
    Potaxcode: string,
    PR_Ptermtext: string,
    PO_Ptermtext: string,
    Requester_Resend: string,
    Spender_Resend: string,
    Supplier_Resend: string,
    Other_Resend: string,
    P2P_Email: string,
    VenMasemail: string,
    Devact_Flag: string,
    Vendor_Flag: string,
    Hide_Price: string,
    Title_resend: string,
    Psl_ID: string,
    PR_Change: string,
    po_change: string,
    Sourcing_ID: string,
}

export class DocFlowItem {
    constructor(
        public readonly Aprappba,
        public readonly Aprapppa,
        public readonly AribaPO,
        public readonly AribaPR,
        public readonly ClosePoInd,
        public readonly CollectiveNumber,
        public readonly CompCode,
        public readonly CompCodeTxt,
        public readonly ContractNo,
        public readonly CostCenter,
        public readonly CostCenterTxt,
        public readonly DelCompInd,
        public readonly ExtContrNo,
        public readonly ExtReqNo,
        public readonly FinalInvInd,
        public readonly GlAccount,
        public readonly GlAccountTxt,
        public readonly GrDocNum,
        public readonly GrInd,
        public readonly GrProgress,
        public readonly GrQty,
        public readonly GrQtyUom,
        public readonly HasAtta,
        public readonly HdrSupplierComm,
        public readonly ITFMCstPln,
        public readonly IdNo,
        public readonly IrAmount,
        public readonly IrAmountCurr,
        public readonly IrAmt,
        public readonly IrDocNum,
        public readonly IrProgress,
        public readonly IrTot,
        public readonly Ir_Amamt,
        public readonly IsFavorite,
        public readonly IsMyGr,
        public readonly IsSapGr,
        public readonly ItemTxt,
        public readonly Material,
        public readonly MaterialType,
        public readonly MigoLabel,
        public readonly MigoUrl,
        public readonly MyGrComLabel,
        public readonly MyGrComUrl,
        public readonly MyGrConfLabel,
        public readonly MyGrConfUrl,
        public readonly MyGrExcLabel,
        public readonly MyGrExcUrl,
        public readonly MyGrUrl,
        public readonly NextAction,
        public readonly POApprovaldate,
        public readonly POApprovername,
        public readonly POCreatoremail,
        public readonly PO_Addno,
        public readonly PPMCstPln,
        public readonly PRApprovaldate,
        public readonly PRApprovername,
        public readonly Plant,
        public readonly PlantTxt,
        public readonly PoComments,
        public readonly PoCreator,
        public readonly PoCreationDate,
        public readonly PoCurrency,
        public readonly PoDeliveryDate,
        public readonly PoDocType,
        public readonly PoItem,
        public readonly PoItemTxt,
        public readonly PoMaterial,
        public readonly PoNetPrice,
        public readonly PoNetPricePerUnit,
        public readonly PoNetPriceRaw,
        public readonly PoNetPriceUnit,
        public readonly PoNo,
        public readonly PoOutRecipient,
        public readonly PoQuantity,
        public readonly PoQuantityRaw,
        public readonly PoQuantityUom,
        public readonly PoReqFname,
        public readonly PoReqLname,
        public readonly PoRequisitioner,
        public readonly PoTotalPrice,
        public readonly Incoterms_Text,
        public readonly AllIncoterms_Data,
        public readonly PrApprover,
        public readonly PrApprovalDate,
        public readonly PrCreationDate,
        public readonly PrCurrency,
        public readonly PrDeliveryDate,
        public readonly PrItem,
        public readonly PrItemTxt,
        public readonly PrMaterial,
        public readonly PrNetPrice,
        public readonly PrNetPriceRaw,
        public readonly PrNetPriceUnit,
        public readonly PRCreatoremail,
        public readonly PRReqemail,
        public readonly PrNo,
        public readonly PrPrice,
        public readonly PrPriceRaw,
        public readonly PrQuantity,
        public readonly PrQuantityRaw,
        public readonly PrQuantityUom,
        public readonly PrReqFname,
        public readonly PrReqLname,
        public readonly PrRequisitioner,
        public readonly Prdoctype,
        public readonly PR_Doctype,
        public readonly PR_Inv_Partner,
        public readonly PR_Inv_Partner_Data,
        public readonly PrePayment,
        public readonly PuchOrg,
        public readonly PurchGrp,
        public readonly PurchGrpTxt,
        public readonly PurchOrgTxt,
        public readonly ReqFname,
        public readonly ReqLname,
        public readonly Requisitioner,
        public readonly SapAttchScn,
        public readonly SearchTerm,
        public readonly SendPDF,
        public readonly Supplier,
        public readonly SupplierTxt,
        public readonly SystemAlias,
        public readonly WBSelement,
        public readonly WBSelementId,
        public readonly WbsElementTxt,
        public readonly PrCreator,
        public readonly PrCreatorfnam,
        public readonly PrCreatorlnam,
        public readonly PrPlant,
        public readonly PrPlantTxt,
        public readonly Pr_CostCenter,
        public readonly Pr_CostCenterTxt,
        public readonly Pr_wbsid,
        public readonly Pr_WbsElementTxt,
        public readonly Pr_GlAccount,
        public readonly Pr_GlAccountTxt,
        public readonly Pr_BAApproval,
        public readonly Pr_PAApproval,
        public readonly Pr_status,
        public readonly Taxcode,
        public readonly Validity_Start,
        public readonly Validity_End,
        public readonly VendorMaterial,
        public readonly Name1,
        public readonly Name2,
        public readonly Houseno,
        public readonly Street,
        public readonly Postalcode,
        public readonly City,
        public readonly Region,
        public readonly Country,
        public readonly Textsupplier,
        public readonly PR_PROFIT,
        public readonly Threshold_currency,
        public readonly Threshold_value,
        public readonly Supplier_Status,
        public readonly CBFileData,
        public readonly Compliance_Ind,
        public readonly PR_PaymentTerms,
        public readonly PO_PaymentTerms,
        public readonly PR_Deleted,
        public readonly PR_AccCategory,
        public readonly PR_Addno,
        public readonly PR_ItemCategory,
        public readonly PR_Itemcount,
        public readonly PR_Ordering_Add,
        public readonly PR_Ordering_Add_Data,
        public readonly PO_Itemcount,
        public readonly Potaxcode,
        public readonly PR_Ptermtext,
        public readonly PO_Ptermtext,
        public readonly Requester_Resend,
        public readonly Spender_Resend,
        public readonly Supplier_Resend,
        public readonly Other_Resend,
        public readonly P2P_Email,
        public readonly VenMasemail,
        public readonly Devact_Flag,
        public readonly Vendor_Flag,
        public readonly Hide_Price,
        public readonly Title_resend,
        public readonly Psl_ID,
        public readonly PR_Change,
        public readonly po_change,
        public readonly Sourcing_ID,
    ) {
    }

    static FromBackend(data: FromNetworkPRItemDTO): DocFlowItem {
        return new DocFlowItem(
            data.Aprappba,
            data.Aprapppa,
            data.AribaPO,
            data.AribaPR,
            data.ClosePoInd,
            data.CollectiveNumber,
            data.CompCode,
            data.CompCodeTxt,
            data.ContractNo,
            data.CostCenter,
            data.CostCenterTxt,
            data.DelCompInd,
            data.ExtContrNo,
            data.ExtReqNo,
            data.FinalInvInd,
            data.GlAccount,
            data.GlAccountTxt,
            data.GrDocNum,
            data.GrInd,
            data.GrProgress,
            data.GrQty,
            data.GrQtyUom,
            data.HasAtta,
            data.HdrSupplierComm,
            data.ITFMCstPln,
            data.IdNo,
            data.IrAmount,
            data.IrAmountCurr,
            data.IrAmt,
            data.IrDocNum,
            data.IrProgress,
            data.IrTot,
            data.Ir_Amamt,
            data.IsFavorite,
            data.IsMyGr,
            data.IsSapGr,
            data.ItemTxt,
            data.Material,
            data.MaterialType,
            data.MigoLabel,
            data.MigoUrl,
            data.MyGrComLabel,
            data.MyGrComUrl,
            data.MyGrConfLabel,
            data.MyGrConfUrl,
            data.MyGrExcLabel,
            data.MyGrExcUrl,
            data.MyGrTab,
            data.NextAction,
            data.POApprovaldate,
            data.POApprovername,
            data.POCreatoremail,
            data.PO_Addno,
            data.PPMCstPln,
            data.PRApprovaldate,
            data.PRApprovername,
            data.Plant,
            data.PlantTxt,
            data.PoComments,
            data.PoCreator,
            data.PoCreationDate,
            data.PoCurrency,
            data.PoDeliveryDate,
            data.PoDocType,
            data.PoItem,
            data.PoItemTxt,
            data.PoMaterial,
            data.PoNetPrice,
            data.PoNetPricePerUnit,
            data.PoNetPriceRaw,
            data.PoNetPriceUnit,
            data.PoNo,
            data.PoOutRecipient,
            data.PoQuantity,
            data.PoQuantityRaw,
            data.PoQuantityUom,
            data.PoReqFname,
            data.PoReqLname,
            data.PoRequisitioner,
            data.PoTotalPrice,
            data.Incoterms_Text,
            data.AllIncoterms_Data,
            data.PrApprover,
            data.PrApprovalDate,
            data.PrCreationDate,
            data.PrCurrency,
            data.PrDeliveryDate,
            data.PrItem,
            data.PrItemTxt,
            data.PrMaterial,
            data.PrNetPrice,
            data.PrNetPriceRaw,
            data.PrNetPriceUnit,
            data.PRCreatoremail,
            data.PRReqemail,
            data.PrNo,
            data.PrPrice,
            data.PrPriceRaw,
            data.PrQuantity,
            data.PrQuantityRaw,
            data.PrQuantityUom,
            data.PrReqFname,
            data.PrReqLname,
            data.PrRequisitioner,
            data.Prdoctype,
            data.PR_Doctype,
            data.PR_Inv_Partner,
            data.PR_Inv_Partner_Data,
            data.PrePayment,
            data.PuchOrg,
            data.PurchGrp,
            data.PurchGrpTxt,
            data.PurchOrgTxt,
            data.ReqFname,
            data.ReqLname,
            data.Requisitioner,
            data.SapAttchScn,
            data.SearchTerm,
            data.SendPDF,
            data.Supplier,
            data.SupplierTxt,
            data.SystemAlias,
            data.WBSelement,
            data.WBSelementId,
            data.WbsElementTxt,
            data.PrCreator,
            data.PrCreatorfnam,
            data.PrCreatorlnam,
            data.PrPlant,
            data.PrPlantTxt,
            data.Pr_CostCenter,
            data.Pr_CostCenterTxt,
            data.Pr_wbsid,
            data.Pr_WbsElementTxt,
            data.Pr_GlAccount,
            data.Pr_GlAccountTxt,
            data.Pr_BAApproval,
            data.Pr_PAApproval,
            data.Pr_status,
            data.Taxcode,
            data.Validity_Start,
            data.Validity_End,
            data.VendorMaterial,
            data.Name1,
            data.Name2,
            data.Houseno,
            data.Street,
            data.Postalcode,
            data.City,
            data.Region,
            data.Country,
            data.Textsupplier,
            data.PR_PROFIT,
            data.Threshold_c,
            data.Threshold_p,
            data.Supplier_Status,
            data.CBFileData,
            data.Compliance_Ind,
            data.PR_PaymentTerms,
            data.PO_PaymentTerms,
            data.PR_Deleted,
            data.PR_AccCategory,
            data.PR_Addno,
            data.PR_ItemCategory,
            data.PR_Itemcount,
            data.PR_Ordering_Add,
            data.PR_Ordering_Add_Data,
            data.PO_Itemcount,
            data.Potaxcode,
            data.PR_Ptermtext,
            data.PO_Ptermtext,
            data.Requester_Resend,
            data.Spender_Resend,
            data.Supplier_Resend,
            data.Other_Resend,
            data.P2P_Email,
            data.VenMasemail,
            data.Devact_Flag,
            data.Vendor_Flag,
            data.Hide_Price,
            data.Title_resend,
            data.Psl_ID,
            data.PR_Change,
            data.po_change,
            data.Sourcing_ID,
        )
    }
}
