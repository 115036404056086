import {useMutation} from "react-query";
import {MaterialColumnsList} from "./materialColumns";
import {PslVendorColumnsList} from "./pslColumns";
import {AxiosWrapper} from "../../utils/axios.wrapper";
import { RCAccColumnsList, RCDocColumnsList, RCDocFlowItemsColumnsList, RCGrColumnsInvoicesList, RCGrColumnsList, RCInvoiceItemsColumnsList, RCInvoicesColumnsList, RCIrColumnsInvoicesList, RCIrColumnsList } from "./docflowColumns";
import { NbsBlockedParkedTabColumnsList, NbsCockpitEasyRequestTabColumnsList, NbsMainteanceTableColumnsList, NbsSapScopeTabColumnsList, NbsTrackerTabColumnsList } from "src/app/nbsCockpit/helpers/constants";
import { PslCatalogColumnsList } from "src/app/pslCatalogs/misc/pslCatalogsColumns";

export interface IColumnsList {
  id: any;
  show: boolean;
  hidden: boolean;
}

enum FrontendColumns {
  PslVendors = 1,
  PslMaterials = 2,
  PSlMyTasks = 3,
  RcDocsTab = 4,
  RcInvoicesTab = 5,
  RCItemsColumns = 6,
  RCItemsColumnsInv = 7,
  RCGrsColumns = 8,
  RCAccColumns = 9,
  RCGrsColumnsInv = 10,
  RCIrsColumns = 11,
  RCIrsColumnsInv = 12,
  NbsEasyRequestTab = 13,
  NbsBlockedParkedTab = 14,
  NbsPoClosingTab = 15,
  NbsTrackerTab = 16,
  PslCatalogColumns = 17,
  NbsMaintenanceColumns = 18,
  NbsSapScopeColumns = 19
}

async function save(columns: IColumnsList[], column: FrontendColumns) {
  const response = await AxiosWrapper.post(`/api/Settings/SaveList/` + column, columns);
  return response.data
}

export const useSaveVendorsColumns = () => useMutation((columns: PslVendorColumnsList[]) => save(columns, FrontendColumns.PslVendors))
export const useSavePslMyTasksColumns = () => useMutation((columns: PslVendorColumnsList[]) => save(columns, FrontendColumns.PSlMyTasks))
export const useSaveMaterialsColumns = () => useMutation((columns: MaterialColumnsList[]) => save(columns, FrontendColumns.PslMaterials))

export const useSaveRcDocsTabColumns = () => useMutation((columns: RCDocColumnsList[]) => save(columns, FrontendColumns.RcDocsTab))
export const useSaveRcInvoicesTabColumns = () => useMutation((columns: RCInvoicesColumnsList[]) => save(columns, FrontendColumns.RcInvoicesTab))

export const useSaveRCItemsColumns = () => useMutation((columns: RCDocFlowItemsColumnsList[]) => save(columns, FrontendColumns.RCItemsColumns))
export const useSaveRCItemsColumnsInv = () => useMutation((columns: RCInvoiceItemsColumnsList[]) => save(columns, FrontendColumns.RCItemsColumnsInv))
export const useSaveRCGrsColumns = () => useMutation((columns: RCGrColumnsList[]) => save(columns, FrontendColumns.RCGrsColumns))
export const useSaveRCAccColumns = () => useMutation((columns: RCAccColumnsList[]) => save(columns, FrontendColumns.RCAccColumns))
export const useSaveRCGrsColumnsInv = () => useMutation((columns: RCGrColumnsInvoicesList[]) => save(columns, FrontendColumns.RCGrsColumnsInv))
export const useSaveRCIrsColumns = () => useMutation((columns: RCIrColumnsList[]) => save(columns, FrontendColumns.RCIrsColumns))
export const useSaveRCIrsColumnsInv = () => useMutation((columns: RCIrColumnsInvoicesList[]) => save(columns, FrontendColumns.RCIrsColumnsInv))

export const useSaveNbsEasyRequestColumns = () => useMutation((columns: NbsCockpitEasyRequestTabColumnsList[]) => save(columns, FrontendColumns.NbsEasyRequestTab))

export const useSaveNbsBlockedParkedColumns = () => useMutation((columns: NbsBlockedParkedTabColumnsList[]) => save(columns, FrontendColumns.NbsBlockedParkedTab))

export const useSaveNbsTrackerColumns = () => useMutation((columns: NbsTrackerTabColumnsList[]) => save(columns, FrontendColumns.NbsTrackerTab))

export const useSaveNbsSapScopeColumns = () => useMutation((columns: NbsSapScopeTabColumnsList[]) => save(columns, FrontendColumns.NbsSapScopeColumns))

export const useSavePslCatalogColumns = () => useMutation((columns: PslCatalogColumnsList[]) => save(columns, FrontendColumns.PslCatalogColumns))

export const useSaveNbsMainteanceColumns = () => useMutation((columns: NbsMainteanceTableColumnsList[]) => save(columns, FrontendColumns.NbsMaintenanceColumns))



