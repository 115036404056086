import {assoc, mergeRight} from "ramda";
import {ContractActionTypes} from "../../actions/contract.actions";
import {ContractChatTypes} from "../../actions/chat.actions";
import {Contract, ContractSource, ContractType, DraftContractSource} from "../../domains/contract/contract";
import {IAction} from "../../../shared/domains/core/actions";
import {newContractQuery, ODataQuery} from "../../domains/contract/query";
import {IChatCounter} from "../../../shared/domains/chat/roomChat.service";
import {loaded} from "../../../shared/reducers/utils";

type IContractActionType = ContractActionTypes | ContractChatTypes;

interface ContractByServers {
  queries: {[key: string]: ODataQuery};
  localPage: number;
  serverPage: number;
  remoteContracts: Contract[];
  countServers: any[];
}

export interface ContractState {
  query: ODataQuery;
  currentPage: number;
  contractByServers: ContractByServers;
  contracts: Contract[];
  favourite: boolean;
  selectedContract: Contract;
  contractType: ContractType;
  contractSource: ContractSource,
  draftContractSource: DraftContractSource,
  invalidContract: Contract;
  connectionEstablished: boolean;
  loading: boolean;
  cloning: boolean;
  error: any;
  totalCount: number;
}

export const initialContractState: ContractState = {
  query: newContractQuery(),
  currentPage: 0,
  contractByServers: {
    queries: {},
    localPage: 0,
    serverPage: -1,
    remoteContracts: [],
    countServers: []
  },
  contracts: [],
  favourite: false,
  selectedContract: null,
  contractType: ContractType.ACTIVE,
  contractSource: ContractSource.SAP,
  draftContractSource: DraftContractSource.CLONE_CONTRACT,
  invalidContract: null,
  connectionEstablished: false,
  loading: false,
  cloning: false,
  error: null,
  totalCount: 0
};

const loading = {loading: true, error: null};
const emptyAndLoading = {loading: true, error: null, contracts: [], totalCount: 0};

function updateCounter(state: ContractState, chatCounter: IChatCounter, currentUserId: number): ContractState {
  const updatedContracts = state.contracts.map((contract: Contract) =>  contract.AgreementNo === chatCounter.id ?
    mergeRight(contract, {
      CountOfUnreadComments: chatCounter.userId === currentUserId ? contract.CountOfUnreadComments : contract.CountOfUnreadComments + 1,
      TotalCountOfComments: contract.TotalCountOfComments + 1
    }) : contract
  );
  return mergeRight(state, {contracts: updatedContracts});
}

export function ContractReducer(state: ContractState = initialContractState, action: IAction<IContractActionType>): ContractState {
  switch (action.type) {
    case ContractActionTypes.RESET:
      return mergeRight(initialContractState, {connectionEstablished: state.connectionEstablished});
    case ContractActionTypes.DELETE_DRAFT_CONTRACT_SUCCESS:
    case ContractActionTypes.DELETE_MRP_SUCCESS:
    case ContractActionTypes.SUBMIT_MRP_FOR_APPROVAL_SUCCESS:
    case ContractActionTypes.PUBLISH_DRAFT_CONTRACT_SUCCESS:
    case ContractActionTypes.APPROVE_MRP_SUCCESS:
    case ContractActionTypes.REJECT_MRP_SUCCESS:
      return mergeRight(state, {
        loading: false,
        contracts: state.contracts.filter(
          (contract: Contract) => !(contract.AgreementNo === action.contract.AgreementNo && contract.TimeStamp === action.contract.TimeStamp)
        )
      });
    case ContractActionTypes.NEW_CONTRACT_TYPE:
    case ContractActionTypes.NEW_TERM:
    case ContractActionTypes.NEW_ORDER:
      return mergeRight(state, emptyAndLoading);
    case ContractActionTypes.FETCH_CONTRACT:
    case ContractActionTypes.FETCH_CONTRACTS:
    case ContractActionTypes.TOGGLE_FAVORITE_CONTRACT:
    case ContractActionTypes.DELETE_DRAFT_CONTRACT:
    case ContractActionTypes.DELETE_MRP:
    case ContractActionTypes.PUBLISH_DRAFT_CONTRACT:
    case ContractActionTypes.VALIDATE_DRAFT:
    case ContractActionTypes.UPDATE_MRP:
      return mergeRight(state, loading);
    case ContractActionTypes.CLONE_CONTRACT:
      return assoc('cloning', true, state);
    case ContractActionTypes.RESET_INVALID_DRAFT:
      return mergeRight(state, {invalidContract: null});
    case ContractActionTypes.VALIDATE_DRAFT_SUCCESS:
    case ContractActionTypes.VALIDATE_DRAFT_FAILURE:
    case ContractActionTypes.PUBLISH_DRAFT_CONTRACT_FAILURE:
      const contractsWithNewDraft = state.contracts.map((contract: Contract) => {
        if (action.contract && contract.AgreementNo === action.contract.AgreementNo
          && contract.TimeStamp === action.contract.TimeStamp) {
          return {...contract, ErrorCount: action.contract.ErrorCount};
        }
        return contract;
      });
      return mergeRight(state, {loading: false, invalidContract: action.contract, contracts: contractsWithNewDraft});
    case ContractActionTypes.NEW_ACTIVE_QUERY:
      return mergeRight(state, {query: action.query, queries: {}, currentPage: action.query.page});
    case ContractActionTypes.NEW_ACTIVE_QUERIES:
      return mergeRight(state, {currentPage: action.page});
    case ContractActionTypes.FETCH_CONTRACTS_SUCCESS:
      return mergeRight(state, {loading: false, contracts: action.items, totalCount: action.totalCount});
    case ContractActionTypes.TOGGLE_FAVORITE_CONTRACT_SUCCESS:
      const contractsWithFav = state.contracts.map((contract: Contract) => {
        if (contract.AgreementNo === action.agreementNo) {
          return {...contract, Favourite: action.newFav};
        }
        return contract;
      });
      return mergeRight(state, {loading: false, contracts: contractsWithFav});
    case ContractActionTypes.FETCH_CONTRACTS_PER_SERVERS_SUCCESS:
      return assoc('contractByServers', {queries: action.queries, remoteContracts: action.items, totalCount: action.totalCount, countServers: action.countServers, serverPage: action.serverPage, localPage: action.localPage}, state);
    case ContractActionTypes.FETCH_CONTRACTS_FAILURE:
    case ContractActionTypes.FETCH_DATALAKE_CONTRACTS_FAILURE:
    case ContractActionTypes.TOGGLE_FAVORITE_CONTRACT_FAILURE:
    case ContractActionTypes.UPDATE_CONTRACT_FAILURE:
    case ContractActionTypes.UPDATE_DRAFT_FAILURE:
    case ContractActionTypes.DELETE_DRAFT_CONTRACT_FAILURE:
    case ContractActionTypes.DELETE_MRP_FAILURE:
    case ContractActionTypes.DRAFT_CONTRACT_FAILURE:
    case ContractActionTypes.UPDATE_MRP_FAILURE:
      return mergeRight(state, {error: action.error, loading: false});
    case ContractActionTypes.SET_SELECTED_CONTRACT:
      return mergeRight(state, {selectedContract: action.contract});
    case ContractActionTypes.SET_CONTRACT_TYPE:
      return mergeRight(state, {contractType: action.contractType});
    case ContractActionTypes.SET_CONTRACT_SOURCE:
    case ContractActionTypes.NEW_CONTRACT_SOURCE:
      return mergeRight(state, {contractSource: action.contractSource});
    case ContractActionTypes.SET_DRAFT_CONTRACT_SOURCE:
    case ContractActionTypes.NEW_DRAFT_CONTRACT_SOURCE:
      return mergeRight(state, {draftContractSource: action.contractSource});
    case ContractActionTypes.CLONE_CONTRACT_SUCCESS:
      return assoc('cloning', false, state);
    case ContractActionTypes.CLONE_CONTRACT_FAILURE:
      return mergeRight(state, {cloning: false, error: action.error});
    case ContractActionTypes.UPDATE_CONTRACT_SUCCESS:
    case ContractActionTypes.UPDATE_DRAFT_SUCCESS:
    case ContractActionTypes.UPDATE_MRP_SUCCESS:
      const contractsWithNewMrp = state.contracts.map((contract: Contract) => {
        if (contract.AgreementNo === action.contract.AgreementNo) {
          return action.contract;
        }
        return contract;
      });
      return loaded(assoc('contracts', contractsWithNewMrp, state));
    case ContractActionTypes.UPDATE_NEW_STATE_CONTRACTS:
      return mergeRight(state, {contracts: action.contracts, selectedContract: action.selectedContract});
    case ContractActionTypes.SUBMIT_CLM_SUCCESS:
    case ContractActionTypes.RESUBMIT_CLM_SUCCESS:
    case ContractActionTypes.DELETE_CLM_CONTRACT_SUCCESS:
    case ContractActionTypes.SUBMIT_CLM_FAILURE:
    case ContractActionTypes.UNLOCK_CLM_SUCCESS:
      const contractsWithClmStatus = state.contracts.map((contract: Contract) => {
        if (contract.AgreementNo === action.contract.AgreementNo) {
          return {...action.contract};
        }
        return contract;
      });
      return mergeRight(state, {loading: false, selectedContract: action.contract, contracts: contractsWithClmStatus});
    case ContractChatTypes.GET_MESSAGES_SUCCESS:
      const contractsWithUnreadCount = state.contracts.map((contract: Contract) => {
        if (contract.AgreementNo === action.contractId) {
          return {...contract, CountOfUnreadComments: 0};
        }
        return contract;
      });
      return assoc('contracts', contractsWithUnreadCount, state);
    case ContractChatTypes.NEW_CONTRACT_CHAT_COUNTER:
      return updateCounter(state, action.counter, action.currentUserId);
    case ContractActionTypes.NEW_SAP_CONNECTION:
      return assoc('connectionEstablished', true, state);
    default:
      return state;
  }
}
