import {newQuery, Query, setSelectedFiltersBykey, updateOrder} from "../../../../shared/queryable/query";
import {
  FilterCategoryView,
  newTextFilterCategory,
  TextFilter
} from "../../../../shared/domains/query/filter";
import {DateTime} from 'luxon';
import * as R from "ramda";
import {Dictionary} from "ramda";
import {IInvoiceFilters} from "../../../../shared/domains/user/user";
import {OrderDirection} from "../../../../../lib/odata";

export enum InvoiceFilterKeys {
  COMP_CODE = 'CompCode',
  FISCAL_YEAR = 'FiscalYr',
  PURCH_ORG = 'PurchOrg',
  SUPPLIER = 'InvPartner',
  INVOICE_NUMBER = 'InvDocNo',
  SCENARIO = 'Scenario',
  PO_NUMBER = 'PoNo',
  PR_NUMBER = 'PrNo',
  INV_CREATION = 'InvDate',
  PO_REQUESTOR = 'PoCreatorName',
  PR_REQUESTOR = 'PrCreatorName',
  PLANT = 'Plant',
  PURCH_GROUP = 'PurchGrp',
  NESTLE_SUPPLIER_INVOICE = 'RefDoc', // Need to check the Key
  WORKFLOW_AGENT = 'WfAgent', // Need to check the Key,
  CHAT_FILTERS = "chatFilter",
  NOTES_FILTERS = "notesFilter",
  PARKING_REASON_CODE ="ParkRsnCode",
  BLOCKING_REASON_CODE = "BlckRsn"
}

export const InvoiceFilters = [
  {key: InvoiceFilterKeys.COMP_CODE, label: 'Company Code *'},
  {key: InvoiceFilterKeys.FISCAL_YEAR, label: 'Fiscal Year *'},
  {key: InvoiceFilterKeys.PURCH_ORG, label: 'Purchasing Organization'},
  {key: InvoiceFilterKeys.PLANT, label: 'Plant'},
  {key: InvoiceFilterKeys.PURCH_GROUP, label: 'Purchasing Group'},
  {key: InvoiceFilterKeys.SUPPLIER, label: 'Supplier'},
  {key: InvoiceFilterKeys.PO_REQUESTOR, label: 'PO Creator'},
  {key: InvoiceFilterKeys.PR_REQUESTOR, label: 'Requestor'},
  {key: InvoiceFilterKeys.WORKFLOW_AGENT, label: 'Workflow Agent'},
  {key: InvoiceFilterKeys.BLOCKING_REASON_CODE, label: 'Blocking Reason Code'},
  {key: InvoiceFilterKeys.PARKING_REASON_CODE, label: 'Parking Reason Code'},
  {key: InvoiceFilterKeys.INVOICE_NUMBER, label: 'Nestlé Invoice Number'},
  {key: InvoiceFilterKeys.NESTLE_SUPPLIER_INVOICE, label: 'Supplier Invoice Number'},
  {key: InvoiceFilterKeys.SCENARIO, label: 'Scenario'},
  {key: InvoiceFilterKeys.PO_NUMBER, label: 'PO Number'},
  {key: InvoiceFilterKeys.PR_NUMBER, label: 'PR Number'},
  {key: InvoiceFilterKeys.INV_CREATION, label: 'Invoice Creation Date'},
  { key: InvoiceFilterKeys.CHAT_FILTERS, label: 'Chat Filters' },
  { key: InvoiceFilterKeys.NOTES_FILTERS, label: 'Notes Filters' }
];

const DefaultSortField = InvoiceFilterKeys.INV_CREATION;

const initial = InvoiceFilters.map(x => newTextFilterCategory(x.key, x.label));
const initialDictionary = R.indexBy(R.prop('key'), initial);

export const todayLessThreeMonths = DateTime.local().minus({months: 3}).toFormat('yyyyMMdd');
export const todayLessOneMonth = DateTime.local().minus({months: 1}).toFormat('yyyyMMdd');
export const today = DateTime.local().toFormat('yyyyMMdd');

export function newInitialFilters(defaultCompany?: TextFilter, defaultYear?: TextFilter): Dictionary<FilterCategoryView> {
  let dictionary: Dictionary<FilterCategoryView> = initialDictionary;
  defaultCompany ? dictionary = setSelectedFiltersBykey(InvoiceFilterKeys.COMP_CODE, [defaultCompany], dictionary) : null;
  defaultYear ? dictionary = setSelectedFiltersBykey(InvoiceFilterKeys.FISCAL_YEAR, [defaultYear], dictionary) : null;
  return defaultCompany || defaultYear ? dictionary : initialDictionary;
}

export function getInitialFiltersForInvoices(defaultCompany?: TextFilter, defaultYear?: TextFilter, defaultFilters?: IInvoiceFilters) {
  let initialFilter: Dictionary<FilterCategoryView> = R.mergeAll(
    [
      newInitialFilters(defaultCompany, defaultYear)
    ]
  );
  initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.CHAT_FILTERS, null, initialFilter);
  initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.NOTES_FILTERS, null, initialFilter);

  if (defaultFilters) {
    let filters: IInvoiceFilters = R.assoc('companies', R.filter((c: TextFilter) => !!c.id)(defaultFilters.companies), defaultFilters);
    if (R.isEmpty(R.prop('companies', filters))) {
      filters = R.assoc('companies', [defaultCompany], filters);
    }
    if (R.isEmpty(R.prop('fiscalYear', filters))) {
      filters = R.assoc('fiscalYear', [defaultYear], filters);
    }
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.COMP_CODE, filters.companies, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PURCH_ORG, filters.pOrgs, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.INVOICE_NUMBER, filters.invoiceNumber, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.SUPPLIER, filters.supplier, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PO_NUMBER, filters.poNumber, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PR_NUMBER, filters.prNumber, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.FISCAL_YEAR, filters.fiscalYear, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PO_REQUESTOR, filters.poCreator, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PR_REQUESTOR, filters.prCreator, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PLANT, filters.plants, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PURCH_GROUP, filters.purchGrp, initialFilter);
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.NESTLE_SUPPLIER_INVOICE, filters.nestleSupplierInvoice, initialFilter);

    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.WORKFLOW_AGENT, filters.workflowAgent, initialFilter)
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.BLOCKING_REASON_CODE, filters.blckRsn, initialFilter)
    initialFilter = setSelectedFiltersBykey(InvoiceFilterKeys.PARKING_REASON_CODE, filters.parkingReasonCode, initialFilter)
    if (filters.invoiceDateRange) {
      initialFilter = setSelectedFiltersBykey('InvDate', filters.invoiceDateRange, initialFilter);
    }
  }
  return initialFilter;
}

export function newInvoicesQuery(defaultCompany?: TextFilter, defaultYear?: TextFilter, defaultFilters?: IInvoiceFilters, term?: string): Query {
  let initialFilter = getInitialFiltersForInvoices(defaultCompany, defaultYear, defaultFilters);

  return updateOrder({
    ...newQuery(initialFilter),
    term: term
  }, DefaultSortField, OrderDirection.ASC);
}
