import {all, call, delay, fork, put, select, takeLatest} from 'redux-saga/effects'
import {
    AddMaterials,
    AddMaterialsFailure,
    AddMaterialsFromFile,
    AddMaterialsFromFileFailure,
    AddMaterialsSuccess,
    ApprovePSLs,
    ApprovePSLsFailure,
    ApprovePSLsSuccess,
    CheckMaterialsForDuplicates,
    CheckMaterialsForDuplicatesFailure,
    DeleteMaterialPSLsFailure,
    DeleteMaterialPSLsSuccess,
    DeletePSLs,
    DeletePSLsFailure,
    DeletePSLsSuccess,
    ExportPSLListDataEmailError,
    ExportPSLListDataEmailSuccess,
    ExportPSLListDataError,
    ExportPSLListDataSuccess,
    ExportPSLTemplate,
    ExportPSLTemplateError,
    ExportPSLTemplateSuccess,
    FetchMaterials,
    FetchMaterialsFailure,
    FetchMaterialsSuccess,
    FetchPSL,
    FetchPslMaterialRequest,
    FetchPslMaterialRequestFailure,
    FetchPslMaterialRequestSuccess,
    FetchPSLRequest,
    FetchPSLRequestFailure,
    FetchPSLRequestSuccess,
    FetchSingleMaterial,
    FetchSingleMaterialFailure,
    FetchSingleMaterialSuccess,
    FetchSinglePSL,
    FetchSinglePSLFailure,
    FetchSinglePSLSuccess,
    ImportPSLError,
    ImportPSLSuccess,
    ImportPSLTimeout,
    PSLTypes,
    ReactivatePsl,
    ReactivatePslFailure,
    ReactivatePslSuccess,
    RejectPSLs,
    RejectPSLsFailure,
    RejectPSLsSuccess,
    ResetMaterialsImportError,
    ResetPSLImportError,
    SavePSL,
    SavePslApproverFilters,
    SavePslApproverFiltersFailure,
    SavePslApproverFiltersSuccess,
    SavePSLFailure,
    SavePSLFromFile,
    SavePSLSuccess,
    UpdateBulkMaterials,
    UpdateMaterialsFailure,
    UpdateMaterialsSuccess,
    UpdatePSL,
    UpdatePSLFailure,
    UpdatePSLSuccess,
    LocalizePSLFailure,
    LocalizePSLSuccess,
    LocalizePSLWithMaterials,
    UpdateFavourite,
    UpdateFavouriteSuccess,
    UpdatePslVendorWithMaterials,
    UpdatePslVendorWithMaterialsSuccess,
    UpdatePslVendorWithMaterialsFailure,
    CreatePslVendorWithMaterialsSuccess,
    CreatePslVendorWithMaterialsFailure,
    CreatePslVendorWithMaterials,
    ReactivateV2Psl,
    GetVendorInfo,
    SaveVendorInfo,
    ToggleVendorLoading
} from "../actions/psl.actions";
import {PSLService} from "../domains/psl.service";
import {QueryPSLActions} from '../reducers/pslList.reducer';
import {QueryPslApproverActions} from '../reducers/pslApproverList.reducer';
import {RootStateStore} from "../../application.reducers";
import {push} from "connected-react-router";
import {PslComplianceStatus, SupplierTabs} from "../domains/psl";
import {mergeRight, flatten} from "ramda";
import {DEFAULT_PAGE_SIZE, Query} from "../../shared/queryable/query";
import {IUserRole} from "../../shared/domains/user/user";
import {SharedStateStore} from "../../shared/reducers";
import _ from 'lodash';


function fetch(action): any {
  if(action.list === SupplierTabs.MATERIALS) {
    return fetchMaterials(action);
  } else {
    return fetchPSL(action);
  }
}

function fetchForApprover(action): any {
    return fetchPSL(action, true);
}

function* fetchMaterialsForMaterialSearch2(action): any {
  try {
    const userScope = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.userScope);
    const materialQuery = yield select((state: RootStateStore) => state.psl.pslList.query);
    yield put(new FetchPslMaterialRequest());
    const result = yield call(PSLService.fetchMaterialsForMaterialSearch, 
      materialQuery, userScope, action.searchObj);
    yield put(new FetchPslMaterialRequestSuccess(result));
  } catch (error) {
    yield put(new FetchPslMaterialRequestFailure(error.toString()));
  }
}

function* fetchMaterials(action): any {
  try {
    const userScope = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.userScope);
    yield put(new FetchPslMaterialRequest());
    const result = yield call(PSLService.fetchMaterials, action.query, userScope);
    yield put(new FetchPslMaterialRequestSuccess(result));
  } catch (error) {
    yield put(new FetchPslMaterialRequestFailure(error));
  }
}

function* getVendorInfo(action: GetVendorInfo) {
  try {
    yield put(new ToggleVendorLoading(true));
    const result = yield call(PSLService.getVendorInfo, action.vendorId);
    yield put(new SaveVendorInfo(result));
  } catch (e) {
    yield put(new ToggleVendorLoading(false));
      action.errorAction(e.message);
  }
}

function* materialFavouriteToggle(action: UpdateFavourite) {
  try {
    yield put(new UpdateFavouriteSuccess(action.isFav, action.index));
    yield call(PSLService.updateFavouriteToggle, action.id);
  } catch (error) {
    yield put(new FetchPslMaterialRequestFailure(error));
  }
}

function* fetchPSLforPSLSearch(action, skipUserScope = false): any {
  try {
    const userScope = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.userScope);
    const pslQuery = yield select((state: RootStateStore) => state.psl.pslList.query);
    const pslApprovalQuery = yield select((state: RootStateStore) => state.psl.pslApproverList.query);
    let pslQueryOrApprovalPslQuery;

    if(action.isApproverInApproval)
    pslQueryOrApprovalPslQuery = pslApprovalQuery;

    else 
    pslQueryOrApprovalPslQuery = pslQuery

    yield put(new FetchPSLRequest());
    const result: any[] = yield call(PSLService.fetchPslForPSLSearch, 
      pslQueryOrApprovalPslQuery, skipUserScope ? null : userScope, action.searchObj);
    yield put(new FetchPSLRequestSuccess(result));
  } catch (error) {
    yield put(new FetchPSLRequestFailure(error));
  }
}

function* fetchPSL(action, skipUserScope = false): any {
  try {
    const userScope = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.userScope);
    const pslRefactorToggle = yield select(({shared}: { shared: SharedStateStore }) => shared.user.features['psl-refactoring']);
    const commonPSLFilters = yield select(({shared}: { shared: SharedStateStore }) => shared.user.features['common-filters-for-approval-and-psls']);

    let isPslRefactorAndCommonFiltersToggleActive = 
    pslRefactorToggle && commonPSLFilters && action.query.filters.taskKind === "APPROVAL"
    const {spendCatL1s, spendCatL2s, spendCatL3s, markets, companies} = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.approvers);
    let queryCopy = _.cloneDeep(action.query)

    if(isPslRefactorAndCommonFiltersToggleActive){      
      queryCopy.filters.SpendCat1Code.selected = spendCatL1s
      queryCopy.filters.SpendCat2Code.selected = spendCatL2s
      queryCopy.filters.SpendCat3Code.selected = spendCatL3s
      queryCopy.filters.Markets.selected = markets
      queryCopy.filters.CompanyCode.selected = companies
      skipUserScope = true
    }

    if(action.list === SupplierTabs.MY_TASKS) {
      action.query.FavoriteSwitchOn = false;
    }

    yield put(new FetchPSLRequest());
    const result: any[] = yield call(PSLService.fetchPsl, action.query, 
      skipUserScope ? null : userScope,
      isPslRefactorAndCommonFiltersToggleActive,
      queryCopy
      );
    yield put(new FetchPSLRequestSuccess(result));
  } catch (error) {
    yield put(new FetchPSLRequestFailure(error));
  }
}

function* initialFetch(action: FetchPSL) {
  const query: Query = yield select(({psl}: RootStateStore) => psl.pslList.query);

  const filters = action.newFilters ? action.newFilters : query.filters;
  let ComplianceStatuses = filters["ComplianceStatuses"]?.selected as any;
  if (action.list === SupplierTabs.MY_TASKS && action.userRole === IUserRole.OPERATIONAL_BUYER) {
    if(!ComplianceStatuses) ComplianceStatuses = [{id: PslComplianceStatus.Overall}];
  }
  const newQuery = {
    ...query,
    page: 0,
    top: action.userRole === IUserRole.OPERATIONAL_BUYER ? query.top : DEFAULT_PAGE_SIZE,
    filters: {...filters,
      ComplianceStatuses: {selected: ComplianceStatuses},
    },
    exactId: action.exactId,
    pslSearch: action.pslSearch,
    materialSearch: action.materialSearch,
    FavoriteSwitchOn: action.isFavorite ? action.isFavorite : false
  }

  yield put({
    type: QueryPSLActions.onNewQuery,
    query: newQuery,
    list: action.list
  });
}

function* savePsl(action: SavePSL | SavePSLFromFile, fromFile: boolean) {
  try {
    const categoryIds: number[] = fromFile ? [] : flatten<number>((action as SavePSL).materials.map(el => [el.spendCat1Id, el.spendCat2Id, el.spendCat3Id]));
    const result = yield call(PSLService.save, action.psl, categoryIds);
    if(fromFile) {
      try {
        yield call(PSLService.saveMaterialsFromFile, (action as SavePSLFromFile).data, result.pslId);
      } catch (error) {
        const validationErrors: {key: number, value: {code: string, message: string}[]}[] = error.response.data.validationErrors ?? [];
        const parsingErrors: {lineId: number, failedPropertyToParse: string, parsingExceptionMessage: string}[] = error.response.data.parsingErrors ?? [];
        const file: string = error.response.data.file ?? "";
        yield put(new ImportPSLError(file, validationErrors, parsingErrors));

        action.onError();
        yield put(new ResetPSLImportError());
        return
      }
    } else {
      yield all((action as SavePSL).materials.map(el=> call(PSLService.saveMaterial, el, result.pslId)));
    }
    yield put(new SavePSLSuccess(result?.pslIdWithPrefix || result?.pslId));
    action.done();
  } catch (error) {
    yield put(new SavePSLFailure(error));
    action.onError();
  }
}
function* updatePsl(action: UpdatePSL) {
  try {
    yield call(PSLService.update, action.psl);
    yield put(new UpdatePSLSuccess());
    action.done();
  } catch (error) {
    yield put(new UpdatePSLFailure(error));
    action.onError();
  }
}
function* localizePslWithMaterials(action: LocalizePSLWithMaterials) {
  try {
    const result = yield call(PSLService.localizeWithMaterials, action.psl);
    yield put(new LocalizePSLSuccess(result?.pslIdWithPrefix || result?.pslId));
    action.done();
  } catch (error) {
    yield put(new LocalizePSLFailure(error));
    action.onError();
  }
}
function* reactivatePsl(action: ReactivatePsl) {
  try {
    yield call(PSLService.reactivate, action.reactivatePsl);
    yield put(new ReactivatePslSuccess());
    action.done();
  } catch (error) {
    yield put(new ReactivatePslFailure(error));
  }
}
function* reactivatePslV2(action: ReactivateV2Psl) {
    try {
        const result = yield call(PSLService.reactivateV2, action.psl);
        yield put(new ReactivatePslSuccess());
        action.done();
    } catch (error) {
        yield put(new ReactivatePslFailure(error));
        action.onError();
    }
}
function* savePslApproverFilters(action: SavePslApproverFilters) {
  try {
    yield call(PSLService.savePslApproversFilter, action.filters);
    yield put(new SavePslApproverFiltersSuccess());
  } catch (error) {
    yield put(new SavePslApproverFiltersFailure(error));
  }
}
function* updateBulkMaterials(action: UpdateBulkMaterials){
  try {
    yield call(PSLService.updateMaterialsInBulk, action.materials)
    yield put(new UpdateMaterialsSuccess());
    action.done();
  } catch (error) {
    yield put(new UpdateMaterialsFailure(error));
    action.onError();
  }
}
function* addMaterials(action: AddMaterials) {
  try {
    yield all(action.materials.map(el=> call(PSLService.saveMaterial, el, action.pslId)));
    yield put(new AddMaterialsSuccess());
    yield delay(1500)
    action.done();
  } catch (error) {
    yield put(new AddMaterialsFailure(error));
    action.onError();
  }
}
function* addMaterialsFromFile(action: AddMaterialsFromFile) {
  try {
    yield call(PSLService.saveMaterialsFromFile, action.data, action.pslId);
    yield put(new AddMaterialsSuccess());
    yield delay(1500);
    action.done();
  } catch (error) {
    yield put(new AddMaterialsFromFileFailure(error));
    action.onError();
  } finally {
    yield put(new ResetMaterialsImportError());
  }
}
function* fetchSinglePsl(action: FetchSinglePSL) {
  try {
    const result = yield call(PSLService.fetchSinglePsl, action.id);
    yield put(new FetchSinglePSLSuccess(result));
    if (action.done) action.done()
  } catch (error) {
    yield put(new FetchSinglePSLFailure(error));
  }
}
function* fetchSingleMaterial(action: FetchSingleMaterial) {
  try {
    const result = yield call(PSLService.fetchSingleMaterial, action.id);
    yield put(new FetchSingleMaterialSuccess(result));
  } catch (error) {
    yield put(new FetchSingleMaterialFailure(error));
  }
}
function* fetchMaterialsByPslId(action: FetchMaterials) {
  try {
    const result = yield call(PSLService.fetchMaterialsByPslId, action.pslId);
    yield put(new FetchMaterialsSuccess(result));
  } catch (error) {
    yield put(new FetchMaterialsFailure(error));
  }
}
function* importPSL(action) {
  try {
    yield call(PSLService.import, action.formData);
    yield put(new ImportPSLSuccess(`Your data is being uploaded. You will be notified via email when the data upload will be completed`));
    yield delay(2000);
    yield put(push(`/psl`));
  } catch (error) {


    if (error && error.response === undefined) {
      yield put(new ImportPSLTimeout());
      yield delay(3000);
      yield put(push(`/psl`));
    } else {
      const validationErrors: {key: number, value: {code: string, message: string}[]}[] = error.response.data.validationErrors ?? [];
      const parsingErrors: {lineId: number, failedPropertyToParse: string, parsingExceptionMessage: string}[] = error.response.data.parsingErrors ?? [];
      const file: string = error.response.data.file ?? "";
      yield put(new ImportPSLError(file, validationErrors, parsingErrors));
    }
  } finally {
    yield put(new ResetPSLImportError());
  }
}

function* exportPSLData(action
  // : ExportPSLListData
  ) {
  try {
    const userScope = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.userScope);

    let skipUserScope = false;
    const pslRefactorToggle = yield select(({shared}: { shared: SharedStateStore }) => shared.user.features['psl-refactoring']);
    const commonPSLFilters = yield select(({shared}: { shared: SharedStateStore }) => shared.user.features['common-filters-for-approval-and-psls']);

    let isPslRefactorAndCommonFiltersToggleActive = 
    pslRefactorToggle && commonPSLFilters && action.query.filters.taskKind === "APPROVAL"
    const {spendCatL1s, spendCatL2s, spendCatL3s, markets, companies} = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.approvers);
    let queryCopy = _.cloneDeep(action.query)

    if(isPslRefactorAndCommonFiltersToggleActive){      
      queryCopy.filters.SpendCat1Code.selected = spendCatL1s
      queryCopy.filters.SpendCat2Code.selected = spendCatL2s
      queryCopy.filters.SpendCat3Code.selected = spendCatL3s
      queryCopy.filters.Markets.selected = markets
      queryCopy.filters.CompanyCode.selected = companies
      skipUserScope = true
    }


    // const result = yield call(PSLService.exportData, action.query as any, userScope);
    const result = yield call(PSLService.exportData, action.query as any, 
      skipUserScope ? null : userScope,      
      isPslRefactorAndCommonFiltersToggleActive,
      queryCopy);

    yield put(new ExportPSLListDataSuccess(`export_psl.xlsx`, result));
  } catch (error) {
    yield put(new ExportPSLListDataError(error));
  }
}

function* exportPSLDataByEmail(action
  // : ExportPSLListDataEmail
  ) {
  try {
    const userScope = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.userScope);
    const pslRefactorToggle = yield select(({shared}: { shared: SharedStateStore }) => shared.user.features['psl-refactoring']);
    const commonPSLFilters = yield select(({shared}: { shared: SharedStateStore }) => shared.user.features['common-filters-for-approval-and-psls']);

    let isPslRefactorAndCommonFiltersToggleActive = 
    pslRefactorToggle && commonPSLFilters && action.query.filters.taskKind === "APPROVAL"
    const {spendCatL1s, spendCatL2s, spendCatL3s, markets, companies} = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser.filters.approvers);
    let queryCopy = _.cloneDeep(action.query)
    let skipUserScope = false;
    if(isPslRefactorAndCommonFiltersToggleActive){      
      queryCopy.filters.SpendCat1Code.selected = spendCatL1s
      queryCopy.filters.SpendCat2Code.selected = spendCatL2s
      queryCopy.filters.SpendCat3Code.selected = spendCatL3s
      queryCopy.filters.Markets.selected = markets
      queryCopy.filters.CompanyCode.selected = companies
      skipUserScope = true
    }


    yield call(PSLService.exportDataByEmail, action.query as any, 
      skipUserScope ? null : userScope, isPslRefactorAndCommonFiltersToggleActive,
      queryCopy);
    yield put(new ExportPSLListDataEmailSuccess('You will receive an email providing a link to download the file when it is ready.'));
  } catch (error) {
    yield put(new ExportPSLListDataEmailError(error));
  }
}

function* exportTemplate(action: ExportPSLTemplate) {
  try {
    const result = yield call(PSLService.exportTemplate, action.list);
    yield put(new ExportPSLTemplateSuccess(`template.xlsx`, result));
  } catch (error) {
    yield put(new ExportPSLTemplateError(error));
  }
}

function* checkForDuplicates(action: CheckMaterialsForDuplicates) {
  try {
    const results = yield all(action.materials.map(mat => call(PSLService.checkIfDuplicate, mergeRight(action.psl, mat))));
    action.done(results);
  } catch (error) {
    if (typeof error === 'string' || error instanceof String)
      error = [{"code":"checkForDuplicates","message":error}]
    yield put(new CheckMaterialsForDuplicatesFailure(error));
  }
}
function* approvePSLs(action: ApprovePSLs) {
  try {
    yield all(action.ids.map(id=> call(PSLService.approvePsl, id)));
    yield put(new ApprovePSLsSuccess());
    action.done();
  } catch (error) {
    yield put(new ApprovePSLsFailure(error));
  }
}
function* rejectPSLs(action: RejectPSLs) {
  try {
    yield call(PSLService.rejectPsl, action.ids, action.reason);
    yield put(new RejectPSLsSuccess());
    action.done();
  } catch (error) {
    yield put(new RejectPSLsFailure(error));
  }
}
function* deletePSLs(action: DeletePSLs) {
  try {
    yield all(action.ids.map(id=> call(PSLService.deletePsl, id)));
    yield put(new DeletePSLsSuccess());
    action.done();
  } catch (error) {
    yield put(new DeletePSLsFailure(error));
  }
}

function pslAndMaterialSearch(action){

  if(action.mainTab === SupplierTabs.MATERIALS) {
    return fetchMaterialsForMaterialSearch2(action)    
  }
  else {    
    if(action.isApproverInApproval){
      return fetchPSLforPSLSearch(action, true)
    }
    else 
    return fetchPSLforPSLSearch(action);
  }

}


function* deleteMaterialPSLs(action: DeletePSLs) {
  try {
    yield all(action.ids.map(id=> call(PSLService.deleteMaterialPsl, id)));
    yield put(new DeleteMaterialPSLsSuccess());
    action.done();
  } catch (error) {
    yield put(new DeleteMaterialPSLsFailure(error));
  }
}

function* createPslVendorWithMaterials(action: CreatePslVendorWithMaterials) {
  try {
    const result = yield call(PSLService.createPslVendorWithMaterials, action.psl);
    yield put(new CreatePslVendorWithMaterialsSuccess(result?.entity?.pslIdWithPrefix || result?.entity?.pslId));
    action.done();
  } catch (error) {
    yield put(new CreatePslVendorWithMaterialsFailure(error));
    action.onError();
  }
}

function* updatePslVendorWithMaterials(action: UpdatePslVendorWithMaterials) {
  try {
    const result = yield call(PSLService.updatePslVendorWithMaterials, action.psl);
    action.done();
    yield delay(2000);
    yield put(new UpdatePslVendorWithMaterialsSuccess());
  } catch (error) {
    yield put(new UpdatePslVendorWithMaterialsFailure(error));
    action.onError();
  }
}

function* watchPSLFetch() {
  yield takeLatest(PSLTypes.FETCH_PSL, initialFetch);
}

function* watchSavePsl() {
  yield takeLatest(PSLTypes.SAVE_PSL, (action: SavePSL)=>savePsl(action, false))
}

function* watchUpdateFavouritePSLMaterial() {
  yield takeLatest(PSLTypes.UPDATE_FAVOURITE, materialFavouriteToggle)
}

function* watchSavePslFromFile() {
  yield takeLatest(PSLTypes.SAVE_PSL_FROM_FILE, (action: SavePSLFromFile)=>savePsl(action, true))
}
function* watchUpdatePsl() {
  yield takeLatest(PSLTypes.UPDATE_PSL, updatePsl)
}
function* watchLocalizePslWithMaterials() {
  yield takeLatest(PSLTypes.LOCALIZE_PSL_MATERIALS, localizePslWithMaterials)
}
function* watchReactivatePsl() {
  yield takeLatest(PSLTypes.REACTIVATE_PSL, reactivatePsl)
}
function* watchReactivatePslV2() {
    yield takeLatest(PSLTypes.REACTIVATE_PSL_AND_FIELDS, reactivatePslV2)
}
function* watchSavePslApproversFilters() {
  yield takeLatest(PSLTypes.SAVE_PSL_APPROVERS_FILTER, savePslApproverFilters)
}

function* watchUpdateMaterialInBulk() {
  yield takeLatest(PSLTypes.UPDATE_BULK_MATERIALS, updateBulkMaterials)
}
function* watchAddMaterial() {
  yield takeLatest(PSLTypes.ADD_MATERIALS, addMaterials)
}
function* watchAddMaterialFromFile() {
  yield takeLatest(PSLTypes.ADD_MATERIALS_FROM_FILE, addMaterialsFromFile)
}
function* watchFetchSinglePsl() {
  yield takeLatest(PSLTypes.FETCH_SINGLE_PSL, fetchSinglePsl)
}
function* watchFetchSingleMaterial() {
  yield takeLatest(PSLTypes.FETCH_SINGLE_MATERIAL, fetchSingleMaterial)
}
function* watchFetchMaterialsByPslId() {
  yield takeLatest(PSLTypes.FETCH_MATERIALS, fetchMaterialsByPslId)
}
function* watchNewQuery() {
  yield takeLatest(QueryPSLActions.onNewQuery, fetch);
}
function* watchApproverNewQuery() {
  yield takeLatest(QueryPslApproverActions.onNewQuery, fetchForApprover);
}
function* watchImport() {
  yield takeLatest(PSLTypes.IMPORT_PSL, importPSL)
}

function* watchExportListData() {
  yield takeLatest(PSLTypes.EXPORT_PSL_LIST_DATA, exportPSLData)
}

function* watchExportListDataByEmail() {
  yield takeLatest(PSLTypes.EXPORT_PSL_LIST_DATA_BY_EMAIL, exportPSLDataByEmail)
}

function* watchExportTemplate() {
  yield takeLatest(PSLTypes.EXPORT_PSL_TEMPLATE, exportTemplate)
}

function* watchCheckMaterialsForDuplicates() {
  yield takeLatest(PSLTypes.CHECK_MATERIALS_FOR_DUPLICATES, checkForDuplicates)
}
function* watchApprovePSLs() {
  yield takeLatest(PSLTypes.APPROVE_PSLS, approvePSLs)
}
function* watchRejectPSLs() {
  yield takeLatest(PSLTypes.REJECT_PSLS, rejectPSLs)
}
function* watchDeletePSLs() {
  yield takeLatest(PSLTypes.DELETE_PSLS, deletePSLs)
}
function* watchDeleteMaterialPSLs() {
  yield takeLatest(PSLTypes.DELETE_MATERIAL_PSLS, deleteMaterialPSLs)
}

function* watchPSLAndMaterialSearch(){
  yield takeLatest(PSLTypes.PSL_AND_MATERIALS_SEARCH, pslAndMaterialSearch)
  
} 

function* watchCreatePslVendorWithMaterials() {
  yield takeLatest(PSLTypes.CREATE_PSL_VENDOR_WITH_MATERIALS, createPslVendorWithMaterials)
}

function* watchUpdatePslVendorWithMaterials() {
  yield takeLatest(PSLTypes.UPDATE_PSL_VENDOR_WITH_MATERIALS, updatePslVendorWithMaterials)
}

function* watchgetVendorInfo() {
  yield takeLatest(PSLTypes.GET_VENDOR_INFO, getVendorInfo)
}

export default function* pslSaga() {
  yield all([
    fork(watchPSLFetch),
    fork(watchSavePsl),
    fork(watchSavePslFromFile),
    fork(watchUpdatePsl),
    fork(watchLocalizePslWithMaterials),
    fork(watchAddMaterial),
    fork(watchAddMaterialFromFile),
    fork(watchFetchSinglePsl),
    fork(watchFetchSingleMaterial),
    fork(watchFetchMaterialsByPslId),
    fork(watchNewQuery),
    fork(watchApproverNewQuery),
    fork(watchImport),
    fork(watchExportTemplate),
    fork(watchExportListData),
    fork(watchExportListDataByEmail),
    fork(watchCheckMaterialsForDuplicates),
    fork(watchApprovePSLs),
    fork(watchRejectPSLs),
    fork(watchDeletePSLs),
    fork(watchDeleteMaterialPSLs),
    fork(watchReactivatePsl),
    fork(watchReactivatePslV2),
    fork(watchSavePslApproversFilters),
    fork(watchUpdateMaterialInBulk),
    fork(watchPSLAndMaterialSearch),
    fork(watchUpdateFavouritePSLMaterial),
    fork(watchCreatePslVendorWithMaterials),
    fork(watchUpdatePslVendorWithMaterials),
    fork(watchgetVendorInfo)
  ])
};
