import {PartialODataQuery} from "../../contracts/domains/contract/query";
import ODataClientWrapper, {List, ListWithStatus, ListWithStatusInv} from "../utils/odataClient.wrapper";
import {ListResponse} from "../../contracts/domains/contract/contract.service";
import {Query} from "../queryable/query";

type ApiMapper<T, V> = (raw: T) => V;

export class ApiService {
  public static async fetchFromQuery<T, V>(query: PartialODataQuery, mapper: (raw: T) => V, withMultiOrigin = false, debug = false): Promise<ListResponse<V>> {
    const oData = ODataClientWrapper.get()
      .fromQuery(query)
      .count();

    if(withMultiOrigin) {
      oData.withMultiOrigin();
    }

    const response: List<T> = await oData.run();
    return response.map(mapper);
  }
}
