import {AxiosWrapper} from "../../utils/axios.wrapper";
import {UploadItemTypes} from "../../actions/upload.actions";

export class UploadService {

  static async upload(itemType: UploadItemTypes, formData): Promise<{fileName: string}> {
    const response = await AxiosWrapper.post('/api/Upload/UploadItem', formData, {params: {type: itemType}});
    return response.data;
  }

  static async download(itemType: UploadItemTypes, fileName: string): Promise<any> {
    const response = await AxiosWrapper.get(`/api/Upload/DownloadItem`, {params: {type: itemType, fileName: fileName}});
    return response.data;
  }

  static async downloadExportPsl(fileName: string): Promise<any> {
    const response = await AxiosWrapper.get(`/api/download/GetExportPsl`, {
      responseType: 'blob',
      params: {id: fileName}
    });
    return response.data;
  }

  static async downloadExportMrp(fileName: string): Promise<any> {
    const response = await AxiosWrapper.get(`/api/download/GetExportMrp`, {
      responseType: 'blob',
      params: {id: fileName}
    });
    return response.data;
  }

  static async downloadExportMrpMappings(fileName: string): Promise<any> {
    const response = await AxiosWrapper.get(`/api/download/GetMrpMappingsExport`, {
      responseType: 'blob',
      params: {id: fileName}
    });
    return response.data;
  }
}

