import ODataClientWrapper from "../../shared/utils/odataClient.wrapper";
import {TextFilter} from "../../shared/domains/query/filter";
import {AxiosWrapper} from "../../shared/utils/axios.wrapper";
import {ODataFilter, Operators} from "../../../lib/odata";
import {MaterialDTO} from "./item/item";
import {ContractSource, DatalakeContract} from "./contract/contract";
import {CLM_CONTRACT_TYPES, SAP_CONTRACT_TYPES, UNKNOWN_VALUES} from "./contract/query";

export const enum ContractFilterTypes {
  COMPANIES = "companies",
  PGROUPS = "pGroups",
  PORGS = "pOrgs",
  DOCTYPES = "docTypes",
  MGROUPS = "mGroups",
  MATERIALS = "materials",
  PLANTS = "plants",
  VALIDITY = "validity",
  STRATEGICBUYERS = "strategicBuyers"
}

export interface ContractFilterTyped extends TextFilter {
  kind: ContractFilterTypes
}

export class ContractFilterService {
  static async fetchAllFilters(): Promise<any[]> {
    const response = await AxiosWrapper.get(`/api/Contracts/GetFilters`, {params: {companyCodeMultiOrigin: true}});
    return response.data;
  }

  static async fetchUserFilters(): Promise<any[]> {
    const response = await AxiosWrapper.get(`/api/Settings/GetUserContractFilters`);
    return response.data;
  }

  static async fetchStrategicBuyers(term: string = "") {
    const filter = ODataFilter.for({field: 'SearchStr', operator: Operators.EQ, value: term});
    const response = await ODataClientWrapper.get()
      .withMultiOrigin()
      .resource("UserDetails")
      .filter(filter)
      .execute();

    return response.data.d.results.map(b => ({value: b.Bname, label: `${b.NameFirst} ${b.NameLast}`}));
  }

  static fetchMaterialGroups(term: string, callback): Promise<any> {
    const response = AxiosWrapper.get('/api/DatalakeSearch/MaterialGroups',
      {params: {searchTerm: term}}
    );
    return response.then(result => callback(result.data.map(MaterialDTO.FromBackend).map(m => ({value: m.Id, label: m.Name}))));
  }

  static fetchMaterials(term: string, callback, matGroups = []) {
    const response = AxiosWrapper.get('/api/DatalakeSearch/MaterialGroups/Materials',
      {params: {searchTerm: term, materialGroups: matGroups}}
    );
    return response.then(result => callback(result.data.map(MaterialDTO.FromBackend).map(m => ({value: m.Id, label: m.Name}))));
  }

  static fetchPlants(term: string, callback) {
    const response = AxiosWrapper.get('/api/DatalakeSearch/Plants',
      {params: {searchTerm: term}}
    );
    return response.then(result => callback(result.data.map(MaterialDTO.FromBackend).map(m => ({value: m.Id, label: m.Name}))));
  }

  static async fetchDatalakeContracts(materialGroups, materials, plants, validity, companies, contractSource): Promise<DatalakeContract> {
    const response = await AxiosWrapper.get('/api/DatalakeSearch/Contracts',{
        params: {
          materialGroups: materialGroups,
          materials: materials,
          plants: plants,
          validityStartDate: validity,
          companyIds: companies,
          contractTypes: contractSource === ContractSource.SAP ? SAP_CONTRACT_TYPES :
                        (contractSource === ContractSource.CLM_CHILD ? CLM_CONTRACT_TYPES : UNKNOWN_VALUES),
          top: 1000
        }
      }
    );
    return response.data.map(DatalakeContract.FromBackend);
  }
}
