import { PurchaseRequestReducers, PurchaseRequestState } from "./purchaseRequest.reducers";
import { combineReducers, Reducer } from "redux";
import { BAFiltersReducer, BAFiltersState } from "./filter/ba.filters.reducer";

export interface businessApprovalsStateStore {
    PurchaseRequest: PurchaseRequestState
    filters: BAFiltersState,
}

export const businessApprovalsCombinedReducers: Reducer<businessApprovalsStateStore> = combineReducers({
    PurchaseRequest: PurchaseRequestReducers,
    filters: BAFiltersReducer,
});
