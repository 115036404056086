import { Dispatch } from "redux";
import { PurchaseRequestService } from "../domains/purchaseRequest/purchaseRequest.service";
import { ErrorAction, IAction, PendingAction, SuccessAction } from "../../../shared/domains/core/actions";
import { PurchaseRequest } from "../domains/purchaseRequest/purchaseRequest";
import any from 'ramda/es/any';
import { SuccessNotificationAction } from "../../../shared/domains/core/notifications";

export enum PurchaseRequestActionTypes {
    FETCH_PRS = "FETCH_BA_PRS",
    FETCH_PRS_SUCCESS = "FETCH_PRS_SUCCESS",
    FETCH_PRS_FAILURE = "FETCH_PRS_FAILURE",
    NEW_PAGE_BA = "NEW_PAGE_BA",
    NEW_ORDER_BA = "NEW_ORDER_BA",
    NEW_PAGE = "NEW_PR_PAGE",
    NEW_TERM_BA = "NEW_TERM_BA",
    NEW_PR_QUERY = "NEW_PR_QUERY",
    SET_SELECTED_PRS = "SET_SELECTED_PRS",
    FETCH_PRS_DETAIL = "FETCH_PRS_DETAIL",
    FETCH_PRS_DETAIL_SUCCESS = "FETCH_PRS_DETAIL_SUCCESS",
    FETCH_PRS_DETAIL_FAILURE = "FETCH_PRS_DETAIL_FAILURE",
    FETCH_PRS_LINES = "FETCH_PRS_LINES",
    FETCH_PRS_LINES_SUCCESS = "FETCH_PRS_LINES_SUCCESS",
    FETCH_PRS_LINES_FAILURE = "FETCH_PRS_LINES_FAILURE",
    APPROVED_PR = "APPROVED_PR",
    APPROVED_OR_REJECT_BA_PR_VIA_EMAIL = "APPROVED_OR_REJECT_BA_PR_VIA_EMAIL",
    APPROVED_OR_REJECT_BA_PR = "APPROVED_OR_REJECT_BA_PR",
    APPROVED_PR_SUCCESS = "APPROVED_PR_SUCCESS",
    APPROVED_PR_FAILURE = "APPROVED_PR_FAILURE",
    SELECT_PARENT = "SELECT_PARENT",
    SELECT_CHILD = "SELECT_CHILD",

    APPLY_BA_PRS_NUMBER = "APPLY_BA_PRS_NUMBER",
    ACTION_FOR_BA = "ACTION_FOR_BA",
    ACTION_FOR_BA_SUCCESS = "ACTION_FOR_BA_SUCCESS",
    ACTION_FOR_BA_FAILURE = "ACTION_FOR_BA_FAILURE",
    APPLY_BA_PRS_NUMBER_SUCCESS = "APPLY_BA_PRS_NUMBER_SUCCESS",
    APPLY_BA_PRS_NUMBER_FAILURE = "APPLY_BA_PRS_NUMBER_FAILURE",
    RESET_SELECTED_BAS = "RESET_SELECTED_BAS",

    NEW_TERM_PA = "NEW_TERM_PA",

    FETCH_BA_WITH_DEFAULT_ALIYS = "FETCH_BA_WITH_DEFAULT_ALIYS",
    FETCH_BA_WITH_DEFAULT_ALIYS_SUCCESS = "FETCH_BA_WITH_DEFAULT_ALIYS_SUCCESS",
    FETCH_BA_WITH_DEFAULT_ALIYS_FAILURE = "FETCH_BA_WITH_DEFAULT_ALIYS_FAILURE",

    BA_DEFAULT_ALIYS = "BA_DEFAULT_ALIYS",
    BA_DEFAULT_ALIYS_SUCCESS = "BA_DEFAULT_ALIYS_SUCCESS",
}

/** Fetch all PR list */
export class FetchPRs extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS;
}

export class FetchPRsSuccess extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_SUCCESS;
}

export class FetchPRsError extends ErrorAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

class SetSelectedPurchaseRequest extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.SET_SELECTED_PRS;
}

class SetSelectedParent extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.SELECT_PARENT;
}

class SetSelectedChild extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.SELECT_CHILD;
}


export function setSelectedPurchaseRequest(purchaseRequest: PurchaseRequest, dispatch: Dispatch<IAction>) {
    dispatch((new SetSelectedPurchaseRequest({ purchaseRequest: purchaseRequest })));
}

export function setSelectedParent(data: any, dispatch: Dispatch<IAction>, currentKey?: string, currentAction?: any) {
    dispatch((new SetSelectedParent({ selectedParent: data, currentKey, currentAction })));
}

export function setSelectedChild(data: any, checkedArray, dispatch: Dispatch<IAction>) {
    dispatch((new SetSelectedChild({ selectedChild: data, checkedArray: checkedArray })));
}



/** Fetch PR Details */
export class FetchPRDetail extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_DETAIL;
}

export class FetchPRDetailSuccess extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_DETAIL_SUCCESS;
}

export class FetchPRDetailError extends ErrorAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_DETAIL_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRDetail(purchaseRequest: PurchaseRequest, dispatch: Dispatch<IAction>) {
    dispatch((new FetchPRDetail(purchaseRequest)));
}

/** Get list of PR Line according to the PR Number (From Master Detail) */
export class FetchPRLines extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_LINES;
}

export class FetchPRLinesSuccess extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_LINES_SUCCESS;
}

export class FetchPRLinesError extends ErrorAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_PRS_LINES_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRLines(purchaseRequest: PurchaseRequest, companyCode: string, positionId: string, dispatch: Dispatch<IAction>, history: boolean) {
    let purchaseRequestTemp: any = {};
    purchaseRequestTemp = purchaseRequest;
    purchaseRequestTemp.PositionId = positionId;
    purchaseRequestTemp.history = history;
    dispatch((new FetchPRLines(purchaseRequestTemp)));
}

/** Approval Process of the PR Line */
export class ApprovedPR extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.APPROVED_PR;
}

export class ApprovedPRSuccess extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.APPROVED_PR_SUCCESS;
}

export class ApprovedPRError extends ErrorAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.APPROVED_PR_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function approvedPR(purchaseRequest: PurchaseRequest, dispatch: Dispatch<IAction>) {
    dispatch((new ApprovedPR(purchaseRequest)));
}

class PRApproval extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.APPROVED_PR;
}

export class PRApprovalSuccess extends SuccessNotificationAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.APPROVED_PR_SUCCESS;
    public readonly message = "Purchase Requisition line were approved successfully.";
    // constructor(public message) {
    //     super();
    //   }
}
export class PRRejectionSuccess extends SuccessNotificationAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.APPROVED_PR_SUCCESS;
    public readonly message = "Purchase Requisition line were rejected successfully.";
    // constructor(public message) {
    //     super();
    //   }
}
export class PRApprovalError extends ErrorAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.APPROVED_PR_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

//Approval or reject all PA
export class ProcessAction extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.ACTION_FOR_BA;
}

export class PRApproveActionSuccess extends SuccessNotificationAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.ACTION_FOR_BA_SUCCESS;
    public readonly message = "Purchase Requisition(s) line were approved successfully.";
}
export class PRRejectActionSuccess extends SuccessNotificationAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.ACTION_FOR_BA_SUCCESS;
    public readonly message = "Purchase Requisition(s) line were rejected successfully.";
}

export class ProcessActionError extends ErrorAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.ACTION_FOR_BA_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export class ResetSelectedAction extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.RESET_SELECTED_BAS;
}
////

export class FetchPRsWithdefaultaliys extends PendingAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_BA_WITH_DEFAULT_ALIYS;
}

export class FetchPRsWithdefaultaliysSuccess extends SuccessAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_BA_WITH_DEFAULT_ALIYS_SUCCESS;
}

export class FetchPRsWithdefaultaliysError extends ErrorAction<PurchaseRequestActionTypes> {
    public readonly type = PurchaseRequestActionTypes.FETCH_BA_WITH_DEFAULT_ALIYS_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function prApproval(newpurchaseRequest: PurchaseRequest, callback, dispatch: Dispatch<IAction>, updatePRService = PurchaseRequestService.approvalRequest) {

    console.log('success');
    dispatch(new PRApproval());
    return updatePRService(newpurchaseRequest)
        .then(() => {
            callback('success');
            if (newpurchaseRequest.ApprovalIndicator == 'A') {
                // return dispatch(new PRApprovalSuccess(newpurchaseRequest))
            } else {
                // return new PRRejectionSuccess(newpurchaseRequest)
            }

        }).catch((error: any) => {
            callback('failure');

            return new PRApprovalError(error)
        })
        // .then(dispatch);
}


export function processAction(data: any, callback, indicator, dispatch: Dispatch<IAction>, origin, updatePRService = PurchaseRequestService.processForBA) {
    dispatch(new ProcessAction());
    return updatePRService(data, origin)
        .then(() => {
            callback('success');

            if (indicator == 'A') {
                return new PRApproveActionSuccess('A')
            } else {
                return new PRRejectActionSuccess('R')
            }
        }).catch((error: any) => {
            callback('failure');
            return new ProcessActionError(error)
        })
        .then(dispatch);
}


export function resetSelectedBAs(dispatch: Dispatch<IAction>) {
    dispatch(new ResetSelectedAction());
}
