import { Dispatch } from "redux";
import { ErrorAction, IAction, PendingAction, SuccessAction } from "../../../shared/domains/core/actions";
import { ApprovalHistoryRequest } from "../domains/approvalHistory/approvalHistory";

export enum ApprovalHistoryActionTypes {
    FETCH_AH = "FETCH_AH",
    FETCH_AH_SUCCESS = "FETCH_AH_SUCCESS",
    FETCH_AH_FAILURE = "FETCH_AH_FAILURE",
    NEW_PAGE_AH = "NEW_PAGE_AH",
    NEW_ORDER_AH = "NEW_ORDER_AH",
    NEW_TERM_AH = "NEW_TERM_AH",
    NEW_AH_QUERY = "NEW_AH_QUERY",
    SET_SELECTED_AH = "SET_SELECTED_AH",
    FETCH_AH_DETAIL = "FETCH_AH_DETAIL",
    FETCH_AH_DETAIL_SUCCESS = "FETCH_AH_DETAIL_SUCCESS",
    FETCH_AH_DETAIL_FAILURE = "FETCH_AH_DETAIL_FAILURE",
    FETCH_AH_LINES = "FETCH_AH_LINES",
    FETCH_AH_LINES_SUCCESS = "FETCH_AH_LINES_SUCCESS",
    FETCH_AH_LINES_FAILURE = "FETCH_AH_LINES_FAILURE",
}

/** Fetch all PR list */
export class FetchPRs extends PendingAction<ApprovalHistoryActionTypes> {
    public temp = console.log("hi")
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH;
}

export class FetchPRsSuccess extends SuccessAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_SUCCESS;
}

export class FetchPRsError extends ErrorAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

class SetSelectedRow extends SuccessAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.SET_SELECTED_AH;
}

export function setSelectedRow(purchaseRequest: ApprovalHistoryRequest, dispatch: Dispatch<IAction>) {
    dispatch((new SetSelectedRow({ purchaseRequest: purchaseRequest })));
}

/** Fetch PR Details */
export class FetchPRDetail extends PendingAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_DETAIL;
}

export class FetchPRDetailSuccess extends SuccessAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_DETAIL_SUCCESS;
}

export class FetchPRDetailError extends ErrorAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_DETAIL_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRDetail(purchaseRequest: ApprovalHistoryRequest, dispatch: Dispatch<IAction>) {
    dispatch((new FetchPRDetail(purchaseRequest)));
}

/** Get list of PR Line according to the PR Number (From Master Detail) */
export class FetchPRLines extends PendingAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_LINES;
}

export class FetchPRLinesSuccess extends SuccessAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_LINES_SUCCESS;
}

export class FetchPRLinesError extends ErrorAction<ApprovalHistoryActionTypes> {
    public readonly type = ApprovalHistoryActionTypes.FETCH_AH_LINES_FAILURE;
    constructor(public error) {
        super(error, true);
    }
}

export function fetchPRLines(purchaseRequest: ApprovalHistoryRequest, AribaPr: string, ApprovalType: string, ApprovalLevel: string, dispatch: Dispatch<IAction>) {
    let purchaseRequestTemp: any = {};
    purchaseRequestTemp = purchaseRequest;
    purchaseRequestTemp.AribaPr = AribaPr;
    purchaseRequestTemp.ApprovalType = ApprovalType;
    purchaseRequestTemp.ApprovalLevel = ApprovalLevel;
    dispatch((new FetchPRLines(purchaseRequestTemp)));
}



