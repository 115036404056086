import * as R from "ramda";
import {
  cbMapper,
  CompBiddingDTO,
  CompetitiveBiddingType
} from "../../docFlows/domains/documentFlow/documentFlow";
import {ISpendCategory} from "./competitiveBidding";
import {CompliantItem, CompliantItemFromAzure} from "../../docFlows/domains/prpo/item";

export interface IPurchaseNos {
  AribaValue?: string;
  SapValue?: string;
  MatGrp?: string;
  VendorNo?: string;
  innerItems?: CompliantItemFromAzure[]
}

export interface CBShortNetworkDTO {
  Id: number,
  IdNo: string,
  PurchaseReqNos: IPurchaseNos[],
  PurchaseOrderNos: IPurchaseNos[],
  CreatorUserEmail: string,
  ApproverEmailAddresses: string[],
  ValidFrom: string,
  ValidTo: string,
  PurchaseDescription: string,
  FormattedId: string,
  ShortFormattedId: string,
  SelectedSupplierId: string,
  SelectedSupplierName: string,
  CbStatusId: CompBiddingDTO,
  SpendCategories: ISpendCategory[];
}

export class ShortCompetitiveBidding {
  constructor(
    public Id: number,
    public IdNo: string,
    public PurchaseReqNos: IPurchaseNos[],
    public PurchaseOrderNos: IPurchaseNos[],
    public CreatorUserEmail: string,
    public ApproverEmailAddresses: string[],
    public ValidFrom: string,
    public ValidTo: string,
    public PurchaseDescription: string,
    public SelectedSupplierId: string,
    public SelectedSupplierName: string,
    public CbStatusId: CompetitiveBiddingType,
    public FormattedId: string,
    public ShortFormattedId: string,
    public SpendCategories: ISpendCategory[],
  ) {
  }

  static FromBackend(data: CBShortNetworkDTO): ShortCompetitiveBidding {
    return new ShortCompetitiveBidding(
      data.Id,
      data.IdNo,
      data.PurchaseReqNos,
      data.PurchaseOrderNos,
      data.CreatorUserEmail,
      data.ApproverEmailAddresses,
      data.ValidFrom,
      data.ValidTo,
      data.PurchaseDescription,
      data.SelectedSupplierId,
      data.SelectedSupplierName,
      cbMapper(data.CbStatusId),
      data.FormattedId,
      data.ShortFormattedId,
      data.SpendCategories
    )
  }
}
