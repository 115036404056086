import {createMuiTheme} from "@material-ui/core";

export const applicationTheme = {
  overrides: {
    MuiPickersDay: {
      daySelected: {
        color: 'white !important',
      },
      dayDisabled: {
        opacity: 0.5
      },
      day: {
        padding: '0px !important',
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif !important',
  },
  palette: {
    primary: {
      contrastText: "#FFF",
      main: "#F19001",
    },
    secondary: {
      main: "#2689EB",
    },
    background: {
      default: "#F2F4F8"
    }
  },
};

export const muiTheme = createMuiTheme(applicationTheme);
