import _ from 'lodash';
import { PurchaseRequest } from "./purchaseRequest";
import { AxiosWrapper } from "../../../../shared/utils/axios.wrapper";
import ODataClientWrapper from "../../../../shared/utils/odataClient.wrapper";
import { withSystemAlias } from "../../../../shared/domains/auth/authentication.service";
import { purchaseRequestQuery, ODataQuery } from "./query";

export interface ListResponse<T> {
	count: number;
	data: T[]
}

export class ProcurementApprovalService {

	private static async fetchAll(query: ODataQuery, alias:string): Promise<ListResponse<PurchaseRequest>> {
		const response = await ODataClientWrapper.get()
			.withSystemAlias(alias)
			.fromQuery(query)
			.count()
			.top()
			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}

		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results.map(PurchaseRequest.FromBackend)
		}
	}


	static async fetch(query: ODataQuery = purchaseRequestQuery(), alias:string): Promise<ListResponse<PurchaseRequest>> {
		return ProcurementApprovalService.fetchPRs(query, alias);
	}

	private static async fetchPRs(query: ODataQuery = purchaseRequestQuery(), alias:string): Promise<ListResponse<PurchaseRequest>> {
		return await ProcurementApprovalService.fetchAll(query, alias);
	}


	static async fetchDetail(query: any): Promise<ListResponse<PurchaseRequest>> {
		return await ProcurementApprovalService.fetchPRDetailAll(query);
	}

	private static async fetchPRDetailAll(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "',AribaPR='" + encodeURIComponent(query.AribaPR) + "')")
			.execute();

		return response.data.d;
	}
	static async fetchAccAssignment(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.systemAlias)
			.resource("PRLines(SapPR='" + encodeURIComponent(query.sapPR) + "',ItemNo='" + encodeURIComponent(query.itemNo) + "',AribaPR='" + encodeURIComponent(query.aribaPR) + "')" + "/ToAccAssignment")
			.execute();
		return response.data.d.results;
	}

	static async fetchPricing(query: any): Promise<ListResponse<PurchaseRequest>> {
		return ProcurementApprovalService.fetchPRPricing(query);
	}

	private static async fetchPRPricing(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			// .resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) +"',AribaPR='"+encodeURIComponent(query.AribaPR)+ "')/ToPricingCondition")
			.resource("PricingConditions?$filter=(ARIBAPRNo eq '" + encodeURIComponent(query.AribaPR) + "') and (SAPPRNo eq '" + encodeURIComponent(query.SapPR) + "')")
			.execute();
		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}

	/**
	 * function to fetch PR line items
	 * @param query
	 * @returns {Promise<ListResponse<PurchaseRequest>>}
	 */
	static async fetchLines(query: any): Promise<ListResponse<PurchaseRequest>> {

		return await ProcurementApprovalService.fetchPRLinesAll(query);
	}

	private static async fetchPRLinesAll(query: any): Promise<any> {
		const resource = "PRApproval_PAs";
		//const resource = "PRLines";
		/*Loop for filter array*/
		// let filterArray = [];
		// if (query.length > 0) {
		// 	query.map((item: any, index: number) => {
		// 		let oDataFilterObj: SimpleODataFilter = { field: item.field, value: item.value };
		// 		filterArray.push(oDataFilterObj);
		// 	});
		// }


		/*Merge all variables*/
		//const favoriteQuery: PartialODataQuery = { resource, filters: filterArray, orderBy: [] };

		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			.resource(`${resource}?$filter=(SapPR eq '${query.SapPR}') and (CompCode eq '${query.CompCode}') and (AribaPR eq '${query.AribaPR}') ${query.AgentID != 0 ? `and (AgentID eq '${query.AgentID}')` : ''}`)
			// .fromQuery(favoriteQuery)
			// .count()
			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}

		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results
		}
	}



	/**
	 * Apply for approval process of PA
	 * @param query
	 * @returns {Promise<ListResponse<PurchaseRequest>>}
	 */

	static async processPRForPA(reqData: PurchaseRequest): Promise<any> {
		const bodyToUpdate = ProcurementApprovalService.approvalRequestBody(reqData);
		// const response: any = await AxiosWrapper.put(`/api/sapdata/Put?query=PRApproval_PAs(AribaPR='${encodeURIComponent(reqData.AribaPR)}',SapPR='${encodeURIComponent(reqData.SapPR)}',ItemNo='${encodeURIComponent(reqData.ItemNo)}',CompCode='${encodeURIComponent(reqData.CompCode)}',PurchOrg='${encodeURIComponent(reqData.PurchOrg)}',RelStrategy='${encodeURIComponent(reqData.RelStrategy)}',RelGroup='${encodeURIComponent(reqData.RelGroup)}',ApprovalLevel='${encodeURIComponent(reqData.ApprovalLevel)}')`, bodyToUpdate, reqData.SAP__Origin ? { headers: withSystemAlias(reqData.SAP__Origin, {}, true) } : {});
		const response: any = await AxiosWrapper.put(`/api/sapdata/Put?query=PRApproval_PAs(AribaPR='${encodeURIComponent(reqData.AribaPR)}',SapPR='${encodeURIComponent(reqData.SapPR)}',ItemNo='${encodeURIComponent(reqData.ItemNo)}',CompCode='${encodeURIComponent(reqData.CompCode)}',PurchOrg='${encodeURIComponent(reqData.PurchOrg)}',RelStrategy='',RelGroup='',ApprovalLevel='')`, bodyToUpdate, (reqData.SAP__Origin || reqData.SystemAlias) ? { headers: withSystemAlias(reqData.SAP__Origin || reqData.SystemAlias, {}, true) } : {});
		return response.data;

	}

	static async processForPA(data: any, origin: string): Promise<any> {
		const response = AxiosWrapper.post(`/api/sapdata/PostFromBody?query=IdSet`, data, origin && origin != 'undefined' ? { headers: withSystemAlias(origin, {}, true) } : {});
		return response;
	}

	private static approvalRequestBody(PRDetail: PurchaseRequest): any {
		return {
			d: {
				AribaPR: PRDetail.AribaPR,
				SapPR: PRDetail.SapPR,
				ItemNo: PRDetail.ItemNo,
				CompCode: PRDetail.CompCode,
				PurchOrg: PRDetail.PurchOrg,
				RelGroup: PRDetail.RelGroup,
				RelStrategy: PRDetail.RelStrategy,
				ApprovalLevel: PRDetail.ApprovalLevel,
				Comments: PRDetail.Comments,
				ApprovalIndicator: PRDetail.ApprovalIndicator
			}
		}
	}


	static async fetchApprovalIndicator(query: any): Promise<ListResponse<PurchaseRequest>> {
		return ProcurementApprovalService.fetchPRApprovalIndicator(query);
	}

	/**
	 * @name fetchPRApprovalIndicator
	 * @param query 
	 * @method GET
	 * @description fetch Approval Indicator for the API
	 */
	private static async fetchPRApprovalIndicator(query: any): Promise<any> {

		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			.resource("PRApproval_PAs(AribaPR='" + encodeURIComponent(query.AribaPR) + "',SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "',CompCode='" + encodeURIComponent(query.CompCode) + "',PurchOrg='" + encodeURIComponent(query.PurchOrg) + "',RelStrategy='" + encodeURIComponent(query.RelStrategy) + "',RelGroup='" + encodeURIComponent(query.RelGroup) + "',ApprovalLevel='" + encodeURIComponent(query.ApprovalLevel) + "')")
			.execute();

		return response.data.d;
	}

	/**
	 * Apply for approval process of PA
	 * @param query
	 * @returns {Promise<ListResponse<PurchaseRequest>>}
	 */

	static async getDefaultAliys(compCode:string): Promise<any> {
		const response: any = await AxiosWrapper.get(`/api/Users/GetApprovalsSapDefaultSystem${compCode ? '?companyCode='+compCode : ''}`);
		return response.data;

	}

}
