import {IdNo, ListResponse} from "../../../contracts/domains/contract/contract.service";
import ODataClientWrapper from "../../../shared/utils/odataClient.wrapper";
import {CompliantItem, POItem, PRItem} from "./item";
import {AxiosWrapper} from "../../../shared/utils/axios.wrapper";
import {withSystemAlias} from "../../../shared/domains/auth/authentication.service";
import DocumentFlow, {DocCatForSap} from "../documentFlow/documentFlow";
import {SAPBoolean} from "../../../shared/domains/core/sapBoolean";
import {DocFlowItem} from "./poItem";
import {InvoiceItem} from "./InvoiceItem";
import {isEmpty} from "ramda";
import {GRItem} from "./gr.item";
import {IRItem} from "./ir.item";
import { Invoice } from "../../invoices/domains/invoices/Invoice";

export interface CompliantItemPRPO {
  pr: CompliantItem[],
  po: CompliantItem[]
}

export class PRPOItemService {
  private static ITEMS_PER_PAGE = 10;

  static async fetchPRItems(df: {purchaseReqNo: string, systemAlias: string}, page: number = 0, term?: string): Promise<ListResponse<PRItem>> {
    const skip = page * 10;

    const response = await ODataClientWrapper.get()
      .resource(`PurchReqItemSet`)
      .withSystemAlias(df.systemAlias, true)
      .filter({field: 'PurchaseReqNo', value: df.purchaseReqNo})
      .and({field: 'SearchTerm', value: term})
      .top(PRPOItemService.ITEMS_PER_PAGE)
      .skip(skip)
      .count()
      .execute();

    const results = response.data.d.results;

    return {
      count: parseInt(response.data.d.__count),
      data: results.map(PRItem.FromBackend)
    }
  }

  static async fetchDocFlowItemSet(df:DocumentFlow): Promise<ListResponse<DocFlowItem>> {
    let response
    const isPoPresent = !isEmpty(df.PurchaseOrdNo)

    if (isPoPresent) {
      response = await ODataClientWrapper.get()
        .withSystemAlias(df.SystemAlias)
        .resource(`DocFlowItemSet`)
        .filter({field: 'PoNo', value: df.PurchaseOrdNo})
        .count()
        .execute();
    } else {
      response = await ODataClientWrapper.get()
        .withSystemAlias(df.SystemAlias)
        .resource(`DocFlowItemSet`)
        .filter({field: 'PrNo', value: df.PurchaseReqNo})
        .count()
        .execute();
    }

    const results = response.data.d.results;
    const result =  {
      count: parseInt(response.data.d.__count),
      data: results.map(DocFlowItem.FromBackend)
    }

    return result
  }

  static async fetchInvoiceItemSet(i:Invoice): Promise<ListResponse<InvoiceItem>> {
    let response

    response = await ODataClientWrapper.get()
        .withSystemAlias(i.SystemAlias)
        .resource(`InvWrkFlwItmSet`)
        .filter({field: 'WfId', value: i.WfId})
        .count()
        .execute();

    const results = response.data.d.results;
    const result =  {
      count: parseInt(response.data.d.__count),
      data: results.map(InvoiceItem.FromBackend)
    }
    return result
  }

  static async fetchCompliantItemsFromDf(df: DocumentFlow): Promise<CompliantItemPRPO> {
    const PRItems = DocumentFlow.isPR(df) ? PRPOItemService.fetchCompliantItemsFromPR(df) : [];
    const POItems = DocumentFlow.isPO(df) ? PRPOItemService.fetchCompliantItemsFromPO(df) : [];
    const result: CompliantItem[][] = await Promise.all([PRItems, POItems]);
    return {pr: result[0], po: result[1]};
  }

  static async fetchCompliantItemsFromPR(df: DocumentFlow): Promise<CompliantItem[]> {
    return PRPOItemService.fetchCompliantItems(
      DocumentFlow.getPurchaseNoForPR(df),
      DocCatForSap.PR,
      df.SystemAlias
    )
  }

  static async fetchCompliantItemsFromPO(df: DocumentFlow): Promise<CompliantItem[]> {
    return PRPOItemService.fetchCompliantItems(
      DocumentFlow.getPurchaseNoForPO(df),
      DocCatForSap.PO,
      df.SystemAlias
    )
  }

  static async fetchCompliantItems(purchaseNo: string, docCat: string, systemAlias = ""): Promise<CompliantItem[]> {

    const query =ODataClientWrapper.get()
      .resource(`NonCompliantInfoSet`)
      .filter({field: 'PurchaseDocNo', value: purchaseNo})
      .and({field: 'DocCat', value: docCat});

    if(systemAlias) query.withSystemAlias(systemAlias, true);

    const response = await query.execute();
    const results = response.data.d.results;
    return results.map(CompliantItem.FromBackend);
  }

  static async fetchPOItems(df: {purchaseOrdNo: string, systemAlias: string}, page: number = 0, term?: string): Promise<ListResponse<POItem>> {
    const skip = page * 10;

    const response = await ODataClientWrapper.get()
      .resource(`POItemSet`)
      .withSystemAlias(df.systemAlias, true)
      .filter({field: 'PurchaseOrdNo', value: df.purchaseOrdNo})
      .and({field: 'SearchTerm', value: term})
      .top(PRPOItemService.ITEMS_PER_PAGE)
      .skip(skip)
      .count()
      .execute();

    const results = response.data.d.results;

    return {
      count: parseInt(response.data.d.__count),
      data: results.map(POItem.FromBackend)
    }
  }

  static async fetchGRItems({  poNo, prNo,systemAlias }): Promise<ListResponse<GRItem>> {
    const purchDocNumber = poNo || prNo

    const response = await ODataClientWrapper.get()
      .resource(`MatDocSet`)
      .withSystemAlias(systemAlias, true)
      .filter({field: 'PoNo', value: purchDocNumber})
      // .filter({field: 'PoNo', value: '4540029446'}) // example data
      .and({field: 'TransactionType', value: 1})
      .count()
      .execute();

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(GRItem.FromBackend)
    }
  }

  static async fetchGRInvItems({  invDocNo, fiscalYear, systemAlias }): Promise<ListResponse<GRItem>> {
    const response = await ODataClientWrapper.get()
      .resource(`MatDocSet`)
      .withSystemAlias(systemAlias, true)
      .filter({field: 'InvDocNo', value: invDocNo})
      .and({field: 'FiscalYr', value: fiscalYear})
      .and({field: 'IdNo', value: 'B1'})
      .count()
      .execute();

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(GRItem.FromBackend)
    }
  }

  static async fetchIRItems({  poNo, prNo,systemAlias }): Promise<ListResponse<IRItem>> {
    const purchDocNumber = poNo || prNo

    const response = await ODataClientWrapper.get()
      .resource(`InvDetailSet`)
      .withSystemAlias(systemAlias, true)
      .filter({field: 'PoNo', value: purchDocNumber})
      // .filter({field: 'PoNo', value: '4540032621'}) // example data
      .count()
      .execute();

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(IRItem.FromBackend)
    }
  }

  static async fetchIRInvItems({  invDocNo, systemAlias }): Promise<ListResponse<IRItem>> {
    const response = await ODataClientWrapper.get()
      .resource(`InvDetailSet`)
      .withSystemAlias(systemAlias, true)
      .filter({field: 'InvDocNo', value: invDocNo})
      .and({field: 'IdNo', value: 'BP'})
      .count()
      .execute();

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(IRItem.FromBackend)
    }
  }

  static async updatePRItem(item: PRItem, systemAlias: string): Promise<any> {
    const path = `/api/sapdata/PostFromBody?query=IdSet`;
    const bodyToUpdate = PRPOItemService.updatePRBody(item);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(systemAlias, {}, true)});
    return response.data;
  }

  static async updatePOItem(item: POItem, systemAlias: string): Promise<any> {
    const path = `/api/sapdata/PostFromBody?query=IdSet`;
    const bodyToUpdate = PRPOItemService.updatePOBody(item);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(systemAlias, {}, true)});
    return response.data;
  }

  static async fetchGoodsReciptsDetails({  poNo,poItemNo,systemAlias }): Promise<ListResponse<GRItem>> {
    const response = await ODataClientWrapper.get()
      .resource(`MatDocSet`)
      .withSystemAlias(systemAlias, true)
      .filter({field: 'PoNo', value: poNo})
      .filter({field: 'PoItemNo', value: poItemNo}) 
      .and({field: 'TransactionType', value: 1})
      .count()
      .execute();

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(GRItem.FromBackend)
    }
  }

  static async fetchInvoiceReciptsDetails({  poNo,poItemNo,systemAlias }): Promise<ListResponse<IRItem>> {
    const response = await ODataClientWrapper.get()
      .resource(`InvDetailSet`)
      .withSystemAlias(systemAlias, true)
      .filter({field: 'PoNo', value: poNo})
      .filter({field: 'PoItemNo', value: poItemNo}) // example data
      .count()
      .execute();

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(IRItem.FromBackend)
    }
  }

  private static updatePRBody(item: PRItem): any {
    return {
      results: {
        IdNo: IdNo.PR_ITEM,
        DataChanged: SAPBoolean.TRUE,
        IdToPRItem: {
          results: [
            {
              IdNo: IdNo.PR_ITEM,
              PurchaseReqNo: item.PurchaseReqNo,
              PurchaseReqItemNo: item.PurchaseReqItemNo,
              SortText: item.SortText,
              Quantity: item.Quantity,
              UnitOfMeasure: item.UnitOfMeasure,
              Price: item.Price,
              Currency: item.Currency,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    }
  }

  private static updatePOBody(item: POItem): any {
    return {
      results: {
        IdNo: IdNo.PO_ITEM,
        DataChanged: SAPBoolean.TRUE,
        IdToPOItem: {
          results: [
            {
              IdNo: IdNo.PO_ITEM,
              PurchaseOrdNo: item.PurchaseOrdNo,
              PurchaseOrdItemNo: item.PurchaseOrdItemNo,
              SortText: item.SortText,
              Quantity: item.Quantity,
              UnitOfMeasure: item.UnitOfMeasure,
              Price: item.Price,
              Currency: item.Currency,
              DeliveryCompleted: item.DeliveryCompleted,
              FinalInvoice: item.FinalInvoice,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    }
  }

}
