import {combineReducers, Reducer} from "redux";
import {DocCountState, DocumentCounterReducer} from "./documentCounter.reducer";
import {DocumentFlowState, QueryableDocumentFlowReducer} from "./queryableDocumentFlowReducer";
import {InvoiceState, QueryableInvoiceReducer} from "../invoices/reducers/queryableInvoiceReducer";
import {Queryable} from "../../shared/queryable/query.reducer";
import {PRItemState, PRPOItemsReducer} from "./items.reducer";
import {PersonalNoteReducer} from "./docFlowPersonalNote.reducer";
import {InvoicePersonalNoteReducer} from "../invoices/reducers/invoicePersonalNote.reducer";
import {chatReducer} from "./docflowChat.reducer";
import {chatReducer as invoiceChatReducer} from "./invoiceChat.reducer";

export interface DocFlowsStateStore {
  documentCounters: DocCountState;
  documentFlows: Queryable<DocumentFlowState>;
  items: PRItemState,
  chat: any,
  notes: any
}

export const DocFlowsCombinedReducers: Reducer<DocFlowsStateStore> = combineReducers({
  documentCounters: DocumentCounterReducer,
  documentFlows: QueryableDocumentFlowReducer,
  items: PRPOItemsReducer,
  chat: chatReducer,
  notes: PersonalNoteReducer
});

export interface InvoicesStateStore {
  documentCounters: DocCountState;
  documentFlows: Queryable<InvoiceState>;
  items: PRItemState,
  chat: any,
  notes: any
}

export const InvoicesCombinedReducers: Reducer<InvoicesStateStore> = combineReducers({
  documentCounters: DocumentCounterReducer,
  documentFlows: QueryableInvoiceReducer,
  items: PRPOItemsReducer,
  chat: invoiceChatReducer,
  notes: InvoicePersonalNoteReducer
});
