interface NestleSupplierInvDTO {
    __metadata: { id: string; type: string; uri: string };
    SuppInvRef: string;
    PostingDate: string;
  }
  
  export class NestleSupplierInv {
    constructor(
      public SuppInvRef: string,
      public PostingDate: string,      
    ) {
    }
  
    static FromBackend(data: NestleSupplierInvDTO): NestleSupplierInv {
      return new NestleSupplierInv(
        data.SuppInvRef,
        data.PostingDate
      )
    }
  
    static toSelect(data: NestleSupplierInv) {
      return {
        value: data.SuppInvRef,
        label: data.SuppInvRef
      }
    }
  
    static toFilter(data: NestleSupplierInv) {
      return {
        id: data.SuppInvRef,
        title: data.SuppInvRef
      }
    }
  }
  