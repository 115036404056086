import {all, call, delay, fork, put, takeLatest} from "redux-saga/effects";
import {
  DownloadExportMrpItem,
  DownloadExportMrpItemError,
  DownloadExportMrpItemSuccess,
  DownloadExportMrpMappingsItem,
  DownloadExportMrpMappingsItemError,
  DownloadExportMrpMappingsItemSuccess,
  DownloadExportPslItem,
  DownloadExportPslItemError,
  DownloadExportPslItemSuccess,
  DownloadItem,
  DownloadItemError,
  DownloadItemSuccess,
  UploadItem,
  UploadItemError,
  UploadItemSuccess,
  UploadTypes
} from "../actions/upload.actions";
import {UploadService} from "../domains/upload/upload.service";
import {push} from "connected-react-router";


function* uploadItem(action: UploadItem) {
  try {
    const result: {fileName: string} = yield call(UploadService.upload, action.itemType, action.formData);
    yield put(new UploadItemSuccess(result.fileName));
    action.onDone(result.fileName);

  } catch(error) {
    yield put(new UploadItemError(error));
  }
}

function* downloadItem(action: DownloadItem) {
  try {
    const result = yield call(UploadService.download, action.itemType, action.fileName);
    yield put(new DownloadItemSuccess(action.fileName, result.url));

  } catch(error) {
    yield put(new DownloadItemError(error));
  }
}

function* downloadExportPslItem(action: DownloadExportPslItem) {
  try {
    const result = yield call(UploadService.downloadExportPsl, action.fileName);
    yield put(new DownloadExportPslItemSuccess(action.fileName, result));
    yield put(push(`/psl`));
  } catch(error) {
    yield put(new DownloadExportPslItemError(error));
    yield delay(5000);
    yield put(push(`/psl`));
  }
}

function* downloadExportMrpItem(action: DownloadExportMrpItem) {
  try {
    const result = yield call(UploadService.downloadExportMrp, action.fileName);
    yield put(new DownloadExportMrpItemSuccess(action.fileName, result));
    yield put(push(`/mrp`));
  } catch(error) {
    yield put(new DownloadExportMrpItemError(error));
    yield delay(5000);
    yield put(push(`/mrp`));
  }
}

function* downloadExportMrpMappingsItem(action: DownloadExportMrpMappingsItem) {
  try {
    const result = yield call(UploadService.downloadExportMrpMappings, action.fileName);
    yield put(new DownloadExportMrpMappingsItemSuccess(action.fileName, result));
    yield put(push(`/mrp`));
  } catch(error) {
    yield put(new DownloadExportMrpMappingsItemError(error));
    yield delay(5000);
    yield put(push(`/mrp`));
  }
}

function* watchUploadItem() {
  yield takeLatest(UploadTypes.UPLOAD_ITEM, uploadItem);
}

function* watchDownloadItem() {
  yield takeLatest(UploadTypes.DOWNLOAD_ITEM, downloadItem);
}

function* watchDownloadExportPslItem() {
  yield takeLatest(UploadTypes.DOWNLOAD_PSL_EXPORT_ITEM, downloadExportPslItem);
}

function* watchDownloadExportMrpItem() {
  yield takeLatest(UploadTypes.DOWNLOAD_MRP_EXPORT_ITEM, downloadExportMrpItem);
}

function* watchDownloadExportMrpMappingsItem() {
  yield takeLatest(UploadTypes.DOWNLOAD_MRPMAPPINGS_EXPORT_ITEM, downloadExportMrpMappingsItem);
}

export default function* uploadSaga() {
  yield all([
    fork(watchUploadItem),
    fork(watchDownloadItem),
    fork(watchDownloadExportPslItem),
    fork(watchDownloadExportMrpItem),
    fork(watchDownloadExportMrpMappingsItem)
  ])
};
