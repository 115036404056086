import * as R from "ramda";
import {all, call, fork, put, select, take, takeLatest} from "redux-saga/effects";
import {
  ChatActions,
  onEndChat,
  onLoadGroupsFailure,
  onLoadInvoiceChatMessagesFailure,
  onLoadMessagesFailure,
  onNewMessage,
  onSendInvoiceChatMessageFailure,
  onSendInvoiceChatMessageSuccess,
  onSendMessageFailure,
  onSendMessageSuccess,
  startChatCounterFailure,
  startChatCounterSuccess,
  startRoomFailure,
  startRoomSuccess,
  onAttachmentDeletion
} from "./chat.actions";
import {channel} from "redux-saga";
import {IChatCounter} from "../domains/chat/roomChat.service";

const newMessagesChannel = channel();
const newChatCounterChannel = channel();

export function messageListener<T>(domainStateSelector: any, newMessageAction: string) {
  return function* (action) {
    const domainState = yield select((state: T) => domainStateSelector(state));
    if (domainState.room) {
      domainState.room.onNewMessages((messages) => newMessagesChannel.put({
        type: newMessageAction,
        message: messages
      }));
    }
  }
}

export function chatCounterListener<T>(domainStateSelector: any, newChatCounterAction: string) {
  return function* (action) {
    const room = action.room;
    if (room) {
      room.onNewMessages((counter: IChatCounter) => newChatCounterChannel.put({
          type: newChatCounterAction,
          counter: counter,
          currentUserId: action.currentUserId
        })
      );
    }
  }
}

export function broadcastMessage<T>(domainStateSelector: any) {
  return function* (action) {
    const domainState = yield select((state: T) => domainStateSelector(state));
    if (domainState.room) {
      yield call(domainState.room.send.bind(domainState.room, action.result.historyChatMessageId));
    }
  }
}

export function endChat<T>(domainStateSelector: any) {
  return function* (action) {
    const domainState = yield select((state: T) => domainStateSelector(state));
    if (domainState.room) {
      yield call(domainState.room.leave.bind(domainState.room));
    }
  }
}

export function chatSagaBuilder<T>(stateSelector: string[], domainAction: (action: ChatActions) => string) {
  const domainStateSelector = R.path([...stateSelector]);

  function* watchStartRoom() {
    while (true) {
      const action = yield take(newMessagesChannel);
      yield put(action);
    }
  }

  function* watchStartRoomSuccess() {
    const action = domainAction(ChatActions.START_ROOM_SUCCESS);
    const newMessageAction = domainAction(ChatActions.NEW_MESSAGES);
    yield takeLatest(action, messageListener<T>(domainStateSelector, newMessageAction))
  }

  function* watchStartChatCounterSuccess() {
    const action = domainAction(ChatActions.START_CHAT_COUNTERS_SUCCESS);
    const newChatCounterAction = domainAction(ChatActions.NEW_CHAT_COUNTER);
    yield takeLatest(action, chatCounterListener<T>(domainStateSelector, newChatCounterAction));
    while (true) {
      const action = yield take(newChatCounterChannel);
      yield put(action);
    }
  }

  function* watchSendMessageSuccess() {
    const action = domainAction(ChatActions.SEND_MESSAGE_SUCCESS);
    yield takeLatest(action, broadcastMessage<T>(domainStateSelector))
  }

  function* watchSendInvoiceMessageSuccess() {
    const action = domainAction(ChatActions.SEND_INVOICE_CHAT_MESSAGE_SUCCESS);
    yield  takeLatest(action, broadcastMessage<T>(domainStateSelector))
  }

  function* watchEndChat() {
    const action = domainAction(ChatActions.END_CHAT);
    yield takeLatest(action, endChat<T>(domainStateSelector))
  }

  return {
    actions: {
      startRoom: domainAction(ChatActions.START_ROOM),
      startInvoiceChatRoom: domainAction(ChatActions.START_INVOICE_CHAT_ROOM),
      startRoomSuccess: startRoomSuccess(domainAction),
      startRoomFailure: startRoomFailure(domainAction),

      startChatCounter: domainAction(ChatActions.START_CHAT_COUNTERS),
      startChatCounterSuccess: startChatCounterSuccess(domainAction),
      startChatCounterFailure: startChatCounterFailure(domainAction),

      onLoadMessages: domainAction(ChatActions.LOAD_MESSAGES),
      onLoadMessagesSuccess: domainAction(ChatActions.LOAD_MESSAGES_SUCCESS),
      onLoadMessagesFailure: onLoadMessagesFailure(domainAction),

      onLoadGroups: domainAction(ChatActions.LOAD_GROUPS),
      onLoadGroupsSuccess: domainAction(ChatActions.LOAD_GROUPS_SUCCESS),
      onLoadGroupsFailure: onLoadGroupsFailure(domainAction),

      onSendMessage: domainAction(ChatActions.SEND_MESSAGE),
      onNewSendMessage: domainAction(ChatActions.SEND_NEW_MESSAGE),
      onSendMessageSuccess: onSendMessageSuccess(domainAction),
      onSendMessageFailure: onSendMessageFailure(domainAction),

      onLoadInvoiceChatMessages: domainAction(ChatActions.LOAD_INVOICE_CHAT_MESSAGES),
      onLoadInvoiceChatMessagesSuccess: domainAction(ChatActions.LOAD_INVOICE_CHAT_MESSAGES_SUCCESS),
      onLoadInvoiceChatMessagesFailure: onLoadInvoiceChatMessagesFailure(domainAction),

      onSendInvoiceChatMessage: domainAction(ChatActions.SEND_INVOICE_CHAT_MESSAGE),
      onSendInvoiceChatMessageSuccess: onSendInvoiceChatMessageSuccess(domainAction),
      onSendInvoiceChatMessageFailure: onSendInvoiceChatMessageFailure(domainAction),
      onAttachmentDeletion: onAttachmentDeletion(domainAction),

      onNewMessage: onNewMessage(domainAction),
      onNewChatCounter: domainAction(ChatActions.NEW_CHAT_COUNTER),
      onEndChat: onEndChat(domainAction)
    },
    sagas: function* () {
      yield all([
        fork(watchStartRoom),
        fork(watchStartRoomSuccess),
        fork(watchStartChatCounterSuccess),
        fork(watchSendMessageSuccess),
        fork(watchSendInvoiceMessageSuccess),
        fork(watchEndChat),
      ]);
    }
  }
}
