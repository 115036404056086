import {SAPBoolean, SAPBooleanToBoolean} from "../../../shared/domains/core/sapBoolean";

export interface NetworkItemPriceDTO {
  __metadata: { id: string; type: string; uri: string };
  IdNo: string;
  AgreementNo: string;
  RefAgreementNo: string;
  TimeStamp: string;
  ItemNo: string;
  Material: string;
  ConditionRecord: string;
  ConditionItem: string;
  ValidFrom: string;
  ValidTo: string;
  Amount: string;
  Unit: string;
  Per: string;
  UoM: string;
  ScaleInd: string;
  DataChanged: string;
}

export enum ItemPriceValidyDate {
  FROM = "ValidFrom",
  TO = "ValidTo",
}

export class ItemPrice {
  constructor(
    public readonly AgreementNo: string,
    public readonly ItemNo: string,
    public readonly Material: string,
    public readonly ConditionRecord: string,
    public readonly ConditionItem: string,
    public readonly ValidFrom: string,
    public readonly ValidTo: string,
    public readonly Amount: string,
    public readonly Unit: string,
    public readonly Per: string,
    public readonly UoM: string,
    public readonly RefAgreementNo?: string,
    public readonly TimeStamp?: string,
    public readonly ScaleInd?: boolean,
    public readonly IsNew: boolean = false
  ) {
    this.Amount = Amount.trim();
    this.Per = Per.trim();
  }

  static FromBackend(data: NetworkItemPriceDTO): ItemPrice {
    return new ItemPrice(
      data.AgreementNo,
      data.ItemNo,
      data.Material,
      data.ConditionRecord,
      data.ConditionItem,
      data.ValidFrom,
      data.ValidTo,
      data.Amount,
      data.Unit,
      data.Per,
      data.UoM,
      data.RefAgreementNo,
      data.TimeStamp,
      SAPBooleanToBoolean((data.ScaleInd as SAPBoolean)),
    )
  }
}
