
export interface NewNetworkDocumentStatusDTO {
    __metadata: { id: string; type: string; uri: string };
    CollectiveNumber: string,
    Completed: number,
    CostCenter: string,
    Creator: string,
    Currency: string,
    DateRange: string,
    DelDate: string,
    DocType: string,
    IdNo: string,
    IsCbActive: string,
    IsFavorite: string,
    MatGrp: string,
    MaterialNo: string,
    POCompanyCode: string,
    POCreationDate: string,
    PRPlant: string,
    PriceRange: string,
    PurchGroup: string,
    PurchOrg: string,
    PurchaseOrdNo: string,
    PurchaseReqNo: string,
    Requisitioner: string,
    SAP__Origin: string,
    SearchTerm: string,
    Status: string,
    ToAddCb: number,
    ToApprvPo: number,
    ToApprvPr: number,
    ToAwaitDel: number,
    ToAwaitInv: number,
    ToChkGrInv: number,
    ToChkPrcInv: number,
    ToClosePo: number,
    ToCreatePo: number,
    ToDocFlowHead: {__deferred: {uri: string}},
    ToPayInv: number,
    ToPostGr: number,
    ToSolvInv: number,
    VendorNo: string,
    WbsElement: string
  }

export class DocumentStatus {
  constructor(
    public Completed: number,
    public ToAddCb: number,
    public ToApprvPo: number,
    public ToApprvPr: number,
    public ToAwaitDel: number,
    public ToAwaitInv: number,
    public ToChkGrInv: number,
    public ToChkPrcInv: number,
    public ToClosePo: number,
    public ToCreatePo: number,
    public ToPayInv: number,
    public ToPostGr: number,
    public ToSolvInv: number,
  ) {}

  static FromBackend(dto: NewNetworkDocumentStatusDTO): DocumentStatus {
    return new DocumentStatus(
      dto.Completed,
      dto.ToAddCb,
      dto.ToApprvPo,
      dto.ToApprvPr,
      dto.ToAwaitDel,
      dto.ToAwaitInv,
      dto.ToChkGrInv,
      dto.ToChkPrcInv,
      dto.ToClosePo,
      dto.ToCreatePo,
      dto.ToPayInv,
      dto.ToPostGr,
      dto.ToSolvInv,
    );
  }
}
