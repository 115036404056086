import * as React from "react";
import * as R from "ramda";
import { Component, useState } from "react";
import { FilterBy, SelectContainer } from "./filters.styles";
import { TextFilter } from "../../domains/query/filter";
import Chips, { CreationDateChips, PrPoTypeChips, SimpleChip } from "./chips";
import { VerticalView } from "../layout/styles";
import { Banner } from "../banner";
import { createStyles, Grid, InputLabel, makeStyles, Theme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";
import { muiTheme } from "../../../application.theme";
import { MIN_DATE } from "../form";
import Select, { components, createFilter } from 'react-select';
import { FixedSizeList } from "react-window";
import Chip from "@material-ui/core/Chip";
import CloseIcon from '@material-ui/icons/Close';
import { GetExpiredWithin, GetScopeType, GetSelectedCatalogType, GetStatusType, newGetSelectedCatalogType } from "../../../mrp/domains/mrp";
import { FilterChipsComponent } from "../filterChips/filterChips.components";
import { FeaturesContext } from "../../domains/core/pataflag.context";
import { Features } from "../../domains/core/pataflag";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      display: 'flex'
    },
    hoverOption: {
      '&:hover': {
        backgroundColor: '#DEEBFF',
      }
    },
    filterLabelClass: {
      fontSize: '18px',
      fontWeight: 800,
      color: '#888888',
      marginBottom: '10px'
    },
    chipPrimary: {
      marginLeft: '20px',
      marginBottom: '10px',
      color: 'white',
      '& svg': {
        color: 'white'
      }
    },
    chipsContainer: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    addContainer: {
      height: '32px',
      width: '90px',
      marginLeft: '20px',
      border: '2px dotted',
      borderRadius: '15px',
      textAlign: 'center',
      paddingTop: '4px',
      fontSize: '16px',
      fontWeight: 500,
      cursor: 'pointer'
    }
  })
);

export const FilterHeader = (props: any) => (
  <VerticalView>
    <Banner title="Filter" onClose={props.onCloseClick} />
  </VerticalView>
);

export const FilterValues = ({ name, source, kind, onDelete, minimumItems = 0 }) => {
  return source?.length > 0 && <FilterBy>
    <span className={'name'}>{name}:</span>
    <Chips source={source} onDelete={(cf: TextFilter) => onDelete({ ...cf, kind })} minimumItems={minimumItems} />
  </FilterBy>
};
export const PslCataogFilterValues = ({ name, source, kind, onDelete, minimumItems = 0 }) => {
  return source?.length > 0 && <FilterBy>
    <span style={{ paddingRight: "5px", font: "15px / 18px Nestle Text", color: "#B4B4B4" }}>{name}:</span>
    {Array.isArray(source) && source?.length > 1 ?
      <FilterChipsComponent source={source} onDelete={(cf: TextFilter) => onDelete({ ...cf, kind })} minimumItems={minimumItems} enableDeletion={true} /> :
      <Chips source={source} onDelete={(cf: TextFilter) => onDelete({ ...cf, kind })} minimumItems={minimumItems} enableDeletion={true} />
    }
  </FilterBy>
};
export const FilterChipsWithHover = ({ name, source, kind, onDelete, minimumItems = 0, enableDeletion = true }) => {
  return source?.length > 0 && <FilterBy>
    <span className={'name'}>{name}:</span>
    {source?.length > 1 ?
      <FilterChipsComponent source={source} onDelete={(cf: TextFilter) => onDelete({ ...cf, kind })} minimumItems={minimumItems} enableDeletion={enableDeletion} /> :
      <Chips source={source} onDelete={(cf: TextFilter) => onDelete({ ...cf, kind })} minimumItems={minimumItems} enableDeletion={enableDeletion} />
    }
  </FilterBy>
};
export const DateFilterValues = ({ data, name, kind, source, onDelete, startDate, endDate, enableDeletion = true, showToolTip = true }) => {
  return source?.length > 0 && <FilterBy>
    <span style={{ color: "#2689EB", paddingRight: "5px" }}>{name}:</span>
    <CreationDateChips showToolTip={showToolTip} source={source} startDate={startDate} endDate={endDate} kind={kind}
      onDelete={() => onDelete({ startDate: data.startDate, endDate: data.endDate, kind })}
      enableDeletion={enableDeletion} />
  </FilterBy >
};

export const PrPoTypeFilterValues = ({ name, source, kind, onDelete, minimumItems = 0 }) => {
  return source?.length > 0 && <FilterBy>
    <span className={'name'}>{name}:</span>
    <PrPoTypeChips source={source} onDelete={(cf: TextFilter) => onDelete({ ...cf, kind })} minimumItems={minimumItems} />
  </FilterBy>
};
export const DocFilterValues = ({ name, source, kind, onDelete, minimumItems = 0, enableDeletion = true }) => {
  return source?.length > 0 && <FilterBy>
    <span className={'name'}>{name}:</span>
    <Chips source={source} onDelete={(cf: TextFilter) => onDelete({ ...cf, kind })} minimumItems={minimumItems} enableDeletion={enableDeletion} />
  </FilterBy>
};
export const CreationDteFilter = ({ name, kind, source, onDelete, startDate, endDate, enableDeletion = true, showToolTip = true }) => {

  return source?.length > 0 && <FilterBy>
    <span style={{ color: "#2689EB", paddingRight: "5px" }}>{name}:</span>
    <CreationDateChips showToolTip={showToolTip} source={source} startDate={startDate} endDate={endDate} kind={kind} onDelete={onDelete} enableDeletion={enableDeletion} />
  </FilterBy >
};
export const PslCatalogDateFilter = ({ name, kind, source, onDelete, startDate, endDate, enableDeletion = true, showToolTip = true }) => {

  return source?.length > 0 && <FilterBy>
    <span style={{ paddingRight: "5px", font: "15px / 18px Nestle Text", color: "#B4B4B4" }}>{name}:</span>
    <CreationDateChips showToolTip={showToolTip} source={source} startDate={startDate} endDate={endDate} kind={kind} onDelete={onDelete} enableDeletion={enableDeletion} />
  </FilterBy >
};
export const FilterValuesWithAdd = ({ name, source, kind, onDelete, chipColor, onAddFilter, showAdd = true }) => {
  const { filterLabelClass, chipPrimary, chipsContainer, addContainer } = useStyles({});
  return source?.length > 0 && <FilterBy>
    <span className={filterLabelClass}>{name}:</span>
    <div className={chipsContainer}>
      {source?.map((el, i) => <Chip key={i} className={chipPrimary} style={{ backgroundColor: chipColor, opacity: el.alwaysVisible ? '0.7' : '1' }}
        label={
          el.title || el.id
        }
        onDelete={el.alwaysVisible ? null : () => onDelete(el)} deleteIcon={<CloseIcon />} />)}
      {showAdd && <div className={addContainer} style={{ borderColor: chipColor, color: chipColor }}
        onClick={onAddFilter}
      >+ Add</div>}
    </div>
  </FilterBy>
};
export const FilterMrpCatalogType = ({ name, source, chipColor, onAddFilter, onDelete, keyValue }) => {
  const { filterLabelClass, chipPrimary, chipsContainer, addContainer } = useStyles({});
  const { hasFeature } = React.useContext(FeaturesContext);

  return !R.isNil(source) && <FilterBy>
    <span className={filterLabelClass}>{name}:</span>
    <div className={chipsContainer}>
      <Chip className={chipPrimary} style={{ backgroundColor: chipColor, fontSize: '15px' }}
        label={(hasFeature(Features.MRP_167239_NEW_CATALOG_TYPE) ? newGetSelectedCatalogType(source) as any : GetSelectedCatalogType(source) as any)?.label} onDelete={() => onDelete(keyValue)} deleteIcon={<CloseIcon />} />
      <div className={addContainer} style={{ borderColor: chipColor, color: chipColor }}
        onClick={onAddFilter}
      >+ Add</div>
    </div>
  </FilterBy>
};
export const FilterMrpScope = ({ name, source, chipColor, onAddFilter, onDelete, keyValue }) => {
  const { filterLabelClass, chipPrimary, chipsContainer, addContainer } = useStyles({});
  return !R.isNil(source) && <FilterBy>
    <span className={filterLabelClass}>{name}:</span>
    <div className={chipsContainer}>
      <Chip className={chipPrimary} style={{ backgroundColor: chipColor, fontSize: '15px' }}
        label={(GetScopeType(source) as any)?.label} onDelete={() => onDelete(keyValue)} deleteIcon={<CloseIcon />} />
      <div className={addContainer} style={{ borderColor: chipColor, color: chipColor }}
        onClick={onAddFilter}
      >+ Add</div>
    </div>
  </FilterBy>
};
export const FilterMrpExpiringPrice = ({ name, source, chipColor, onAddFilter, onDelete, keyValue }) => {
  const { filterLabelClass, chipPrimary, chipsContainer, addContainer } = useStyles({});
  return !R.isNil(source) && <FilterBy>
    <span className={filterLabelClass}>{name}</span>
    <div className={chipsContainer}>
      <Chip className={chipPrimary} style={{ backgroundColor: chipColor, fontSize: '15px' }}
        label={(GetExpiredWithin(source) as any)?.label} onDelete={() => onDelete(keyValue)} deleteIcon={<CloseIcon />} />
      <div className={addContainer} style={{ borderColor: chipColor, color: chipColor }}
        onClick={onAddFilter}
      >+ Add</div>
    </div>
  </FilterBy>
};
export const FilterMrpStatus = ({ name, source, chipColor, onAddFilter, onDelete, keyValue }) => {
  const { filterLabelClass, chipPrimary, chipsContainer, addContainer } = useStyles({});
  return !R.isNil(source) && <FilterBy>
    <span className={filterLabelClass}>{name}:</span>
    <div className={chipsContainer}>
      <Chip className={chipPrimary} style={{ backgroundColor: chipColor, fontSize: '15px' }}
        label={(GetStatusType(source) as any)?.label} onDelete={() => onDelete(keyValue)} deleteIcon={<CloseIcon />} />
      <div className={addContainer} style={{ borderColor: chipColor, color: chipColor }}
        onClick={onAddFilter}
      >+ Add</div>
    </div>
  </FilterBy>
};
export const FilterSingleValueType = ({ name, label, chipColor, onDelete }) => {
  const { filterLabelClass, chipPrimary, chipsContainer } = useStyles({});
  return <FilterBy>
    <span className={filterLabelClass}>{name}:</span>
    <div className={chipsContainer}>
      <Chip className={chipPrimary} style={{ backgroundColor: chipColor, fontSize: '15px' }}
        label={label} onDelete={onDelete} deleteIcon={<CloseIcon />} />
    </div>
  </FilterBy>
};

export const FilterSingleValueType2 = ({ name, label, onDelete }) => {
  const { chipsContainer } = useStyles({});
  return <FilterBy>
    <span className={'name'}>{name}:</span>
    <div className={chipsContainer}>
      <SimpleChip value={label} onDelete={onDelete} deletable={true} />
    </div>
  </FilterBy>
};

export const PslCatalogsSingleValueFilter = ({ name, label, onDelete }) => {
  const { chipsContainer } = useStyles({});
  return <FilterBy>
    <span style={{ paddingRight: "5px", font: "15px / 18px Nestle Text", color: "#B4B4B4" }}>{name}:</span>
    <div className={chipsContainer}>
      <SimpleChip value={label} onDelete={onDelete} deletable={true} />
    </div>
  </FilterBy>
};

export const DateFilterValue = ({ name, dateFormat, value, kind, onDelete, deletable = true }) => {
  return value && <FilterBy>
    <span className={'name'}>{name}:</span>
    <SimpleChip value={DateTime.fromISO(value).toFormat(dateFormat)} deletable={deletable}
      onDelete={(value) => onDelete({ value, kind })} />
  </FilterBy>
};

export class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue, customDisplay } = this.props as any;
    const [value] = getValue();
    const height = 35;
    const initialOffset = options.indexOf(value) * height;
    const getOptionLabel = (option) => {
      if (customDisplay) {
        return customDisplay(option);
      }
      return `${option.value} - ${option.label}`;
    };
    const getOptionRender = (children) => {
      const option: any = R.path(['props', 'data'], children);
      return R.assocPath(['props', 'children'], getOptionLabel(option), children);
    };
    return (
      <FixedSizeList
        height={Math.min(maxHeight, Math.max(children?.length, 1) * height)}
        width={"100%"}
        itemCount={children?.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{getOptionRender(children[index])}</div>}
      </FixedSizeList>
    );
  }
}

export const FilterSelector = ({ label, selected, onChange, source, minimumItems = 0, customDisplay = null, disabled = false }) => {
  const { hoverOption } = useStyles({});
  const mapper = data => ({ value: data.id, label: data.title, azureId: data.azureId });
  const unmapper = data => ({ id: data.value, title: data.label, azureId: data.azureId });
  const options = source?.map(mapper);
  const selectedValue = selected?.map(s => {
    const option = options.find((o: any) => o.value === s.id);
    return option ? option : mapper(s);
  });
  const generateProps = props => {
    const { innerProps, isFocused, ...otherProps } = props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    return { innerProps: { ...otherInnerProps }, ...otherProps }
  };
  const [query, setQuery] = useState("");
  const onInputChange = (query, { action }) => {
    // Prevents resetting our input after option has been selected
    if (action !== "set-value") setQuery(query);
  };
  return <>
    <InputLabel>{label}</InputLabel>
    <SelectContainer>
      <Select
        isMulti={true}
        inputValue={query}
        closeMenuOnSelect={false}
        onInputChange={onInputChange}
        options={options}
        value={selectedValue}
        isClearable={true}
        isDisabled={disabled}
        components={{
          MenuList: (x: any) => <MenuList {...x} customDisplay={customDisplay} />,
          Option: x => <components.Option {...generateProps(x)} className={hoverOption}>{x.children}</components.Option>
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        onChange={(value) => onChange((value || []).map(unmapper))}
      />
    </SelectContainer>
  </>
};

const formatDate = (date) => {
  if (date) {
    if (date instanceof DateTime) {
      return date.toFormat('yyyyMMdd')
    }
    if (typeof (date) !== 'object') {
      return DateTime.fromFormat(date, 'yyyyMMdd')
    }
  }
  return null;
};

export const FilterDateRange = ({ spacing = false, label, onChange, source, format, yearDuration = 0, miniDate = null }) => {
  const { date } = useStyles({});
  const [startDate, setStartDate] = React.useState<any>(formatDate(source.startDate));
  const [endDate, setEndDate] = React.useState<any>(formatDate(source.endDate));
  const maxDate = endDate ? endDate : formatDate('21000101');
  // const minDate = (endDate && yearDuration > 0) ? endDate.minus({ year: yearDuration }) : formatDate('19000101');
  const minDate = formatDate('19000101');

  return <>
    <InputLabel>{label}</InputLabel>
    <Grid container spacing={spacing ? 1 : 2} wrap={"nowrap"}>
      <Grid item sm={6}>
        <MuiThemeProvider theme={muiTheme}>
          <KeyboardDatePicker
            margin="none"
            label="Start Date"
            format={format}
            className={date}
            clearable={true}
            value={miniDate ? source.startDate : startDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(value) => {
              setStartDate(value);
              onChange({ startDate: formatDate(value), endDate: formatDate(endDate) });
            }}
          />
        </MuiThemeProvider>
      </Grid>
      <Grid item sm={6}>
        <MuiThemeProvider theme={muiTheme}>
          <KeyboardDatePicker
            margin="none"
            label="End Date"
            format={format}
            className={date}
            clearable={true}
            value={miniDate ? source.endDate : endDate}
            onChange={(value) => {
              setEndDate(value);
              onChange({ startDate: formatDate(startDate), endDate: formatDate(value) });
            }}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  </>
};

export const NbsFilterDateRange = ({ spacing = false, label, onChange, source, format, yearDuration = 0, miniDate = null }) => {
  const { date } = useStyles({});
  const currentDate = DateTime.local();
  console.log(currentDate);
  const [startDate, setStartDate] = React.useState<any>(formatDate(source?.startDate));
  const [endDate, setEndDate] = React.useState<any>(formatDate(source?.endDate));
  const maxDate = endDate ? endDate : currentDate;
  const minDate = formatDate('19000101');

  return <>
    <InputLabel>{label}</InputLabel>
    <Grid container spacing={spacing ? 1 : 2} wrap={"nowrap"}>
      <Grid item sm={6}>
        <MuiThemeProvider theme={muiTheme}>
          <KeyboardDatePicker
            margin="none"
            label="Start Date"
            format={format}
            className={date}
            clearable={true}
            value={miniDate ? source?.startDate : startDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(value) => {
              setStartDate(value);
              const tempEndDate = value ? (endDate ? endDate : currentDate) : null;
              setEndDate(tempEndDate);
              onChange({ startDate: formatDate(value), endDate: formatDate(tempEndDate) });
            }}
          />
        </MuiThemeProvider>
      </Grid>
      <Grid item sm={6}>
        <MuiThemeProvider theme={muiTheme}>
          <KeyboardDatePicker
            margin="none"
            label="End Date"
            disabled={!startDate}
            format={format}
            className={date}
            clearable={true}
            value={miniDate ? source.endDate : endDate}
            maxDate={maxDate}
            onChange={(value) => {
              setEndDate(value);
              onChange({ startDate: formatDate(startDate), endDate: formatDate(value) });
            }}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  </>
};

export const FilterDateRangeForDelDate = ({ spacing = false, label, onChange, source, format, yearDuration = 0, miniDate = null, disabled = false }) => {
  const { date } = useStyles({});
  const [startDate, setStartDate] = React.useState<any>(formatDate(source.startDate));
  const [endDate, setEndDate] = React.useState<any>(formatDate(source.endDate));
  const maxDate = endDate ? endDate : formatDate('21000101');
  // const minDate = (endDate && yearDuration > 0) ? endDate.minus({ year: yearDuration }) : formatDate('19000101');
  const minDate = formatDate('19000101');

  React.useEffect(() => {
    setStartDate(formatDate(source.startDate))
    setEndDate(formatDate(source.endDate))
  }, [source.startDate, source.endDate])


  return <>
    <InputLabel>{label}</InputLabel>
    <Grid container spacing={spacing ? 1 : 2} wrap={"nowrap"}>
      <Grid item sm={6}>
        <MuiThemeProvider theme={muiTheme}>
          <KeyboardDatePicker
            margin="none"
            label="Start Date"
            format={format}
            disabled={disabled}
            className={date}
            clearable={true}
            value={miniDate ? source.startDate : startDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(value) => {
              setStartDate(value);
              onChange({ startDate: formatDate(value), endDate: formatDate(endDate) });
            }}
          />
        </MuiThemeProvider>
      </Grid>
      <Grid item sm={6}>
        <MuiThemeProvider theme={muiTheme}>
          <KeyboardDatePicker
            margin="none"
            label="End Date"
            format={format}
            className={date}
            disabled={disabled}
            clearable={true}
            value={miniDate ? source.endDate : endDate}
            onChange={(value) => {
              setEndDate(value);
              onChange({ startDate: formatDate(startDate), endDate: formatDate(value) });
            }}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  </>
};




export const FilterDate = ({ label, onChange, source, format, minDate = null }) => {
  const { date } = useStyles({});
  const [selectedDate, setSelectedDate] = React.useState<any>(formatDate(source));
  return <>
    <InputLabel>{label}</InputLabel>
    <Grid container spacing={2} wrap={"nowrap"}>
      <Grid item sm={12}>
        <MuiThemeProvider theme={muiTheme}>
          <KeyboardDatePicker
            margin="none"
            format={format}
            style={{ fontSize: 14 }}
            className={date}
            clearable={true}
            value={selectedDate}
            minDate={minDate ? minDate : MIN_DATE}
            onChange={(value) => {
              setSelectedDate(value);
              onChange(formatDate(value));
            }}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  </>
};
