import React, {useEffect} from 'react';
import {PFClient, PFUser} from "@pataflags/sdk-js";
import {FullWidthLoadingComponent} from "../../components/loading";
import {useDispatch, useSelector} from "react-redux";
import {
  FeaturesLoaded,
  FetchLiteUser,
  FetchUserAppFilters,
  FetchUserProfile,
} from "../../actions/user.actions";
import {RootStateStore} from "../../../application.reducers";
import {Features, getPataFlagsApiKey, getPataFlagsDefaultValues,} from "../../domains/core/pataflag";
import {FeaturesContext} from "../../domains/core/pataflag.context";
import {PataflagService} from "../../services/pataflag.service";
import {GetChatCounters} from "../../../contracts/actions/chat.actions";
import {MaintenanceComponent} from "../../components/maintenance";
import {UI_MODULE} from "../../domains/core/ui";

const WrappedLoading = ({loadingText="Loading user information..."}) => <FullWidthLoadingComponent text={loadingText}/>;
const WrappedFeatures = ({children}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateStore) => state.shared.user.currentUser);
  const {featuresLoaded, features} = useSelector((state: RootStateStore) => state.shared.user);
  const defaultFlags = getPataFlagsDefaultValues();
  const [releasedFeatures, setReleasedFeatures] = React.useState<any>(features);
  const hasFeature = (feature): boolean => releasedFeatures[feature];

  useEffect(() => {
    if (!featuresLoaded) {
      const userContext: PFUser = {id: user.id, context: {id: `${user.id}`, name: user.name, email: user.email}};
      const pfClient = PFClient.initialize(getPataFlagsApiKey(), userContext, {fallback: {featureFlags: defaultFlags, remoteConfigs: {}}, timeout: 15000});
      pfClient.onReady(() => {
        PataflagService.setInstance(pfClient);
        PataflagService.fetchFlagsInitialize().then((flags) => {
          setReleasedFeatures(flags.features.evaluation.featureFlags);
          dispatch(new FeaturesLoaded(flags));
        });
      });
      pfClient.onError((e) => {
        dispatch(new FeaturesLoaded(getPataFlagsDefaultValues()));
      });
    }
  }, [featuresLoaded]);

  if(hasFeature(Features.UNDER_MAINTENANCE)) {
    return <MaintenanceComponent/>
  }

  return !featuresLoaded ? <WrappedLoading/> : <FeaturesContext.Provider value={{releasedFeatures, hasFeature}}>{children}</FeaturesContext.Provider>;
};

const WrappedUser = ({children}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateStore) => state.shared.user.currentUser);
  const module: UI_MODULE = useSelector((state: RootStateStore) => state.shared.ui.module);
  const userLiteDataLoaded = useSelector((state: RootStateStore) => state.shared.user.currentUser && state.shared.user.liteDataLoaded);

  useEffect(() => {
    if (!userLiteDataLoaded) {
      dispatch(new FetchLiteUser());
    }
  }, [userLiteDataLoaded]);

  useEffect(() => {
    if (user && [UI_MODULE.CONTRACT, UI_MODULE.DOCFLOW].includes(module)) {
      dispatch(new GetChatCounters(user.id));
    }
  }, [module, user]);

  return !userLiteDataLoaded ? <WrappedLoading/> : children;
};

const WrappedApp = ({children}) => {
  const dispatch = useDispatch();
  const profileLoaded = useSelector((state: RootStateStore) => state.shared.user.profileDataLoaded);
  const appFiltersLoading = useSelector((state: RootStateStore) => state.shared.user.appFiltersLoading);
  const appFiltersLoaded = useSelector((state: RootStateStore) => state.shared.user.appFiltersDataLoaded);

  useEffect(() => {
    if (!appFiltersLoading && !appFiltersLoaded) {
      dispatch(new FetchUserAppFilters());
    }
  }, [appFiltersLoading, appFiltersLoaded]);

  useEffect(() => {
    if (!profileLoaded) {
      dispatch(new FetchUserProfile());
    }
  }, [profileLoaded]);

  return (
    (!appFiltersLoaded)
  ) ? <WrappedLoading loadingText={"Loading application data..."}/> : children;
};

export function Me(props: any) {
  return (
    <WrappedUser {...props}>
      <WrappedFeatures {...props}>
        <WrappedApp {...props}></WrappedApp>
      </WrappedFeatures>
    </WrappedUser>
  )
}
