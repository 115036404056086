import { template } from "lodash";
import * as R from 'ramda';
import { ebUrl, sapUrl } from "src/app/shared/utils/urlBuilders";

export interface NetworkGRItemDTO {
  __metadata: { id: string; type: string; uri: string };
  Amount: string;
  Attachment: string;
  Batch: string;
  Currency: string;
  DebitCreditInd: string;
  DelComInd: string;
  DeliveryDate: string;
  DocumentDate: string;
  FiscalYr:string;
  GoodsRecipient: string,
  GRfnam: string,
  GRlnam: string,
  MatDocItemNo: string;
  MatDocNo: string;
  MatTxt: string;
  Material: string;
  MigoUrl: string;
  MovementType: string;
  MovementTypeTxt: string;
  PoItemNo: string;
  PoNo: string;
  PostingDate: string;
  Quantity: string;
  RefDocNum: string;
  StockType: string;
  SystemAlias: string;
  TransactionType: string;
  Uom: string;

}
const ViewGRTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2fevery_user!2fgeneral!2fuwl!2fcom.sap.netweaver.bc.uwl.uwlSapLaunch?System=R3AE1&TCode=MB03&DynamicParameter=RM07M-MBLNR=<%=gr%>&RM07M-MJAHR=<%=year%>&AutoStart=true"
);
export class GRItem {
  constructor(
    public readonly Amount: string,
    public readonly Attachment: string,
    public readonly Batch: string,
    public readonly Currency: string,
    public readonly DebitCreditInd: string,
    public readonly DelComInd: string,
    public readonly DeliveryDate: string,
    public readonly DocumentDate: string,
    public readonly FiscalYr: string,
    public readonly GoodsRecipient: string,
    public readonly GRfnam: string,
    public readonly GRlnam: string,
    public readonly MatDocItemNo: string,
    public readonly MatDocNo: string,
    public readonly MatTxt: string,
    public readonly Material: string,
    public readonly MigoUrl: string,
    public readonly MovementType: string,
    public readonly MovementTypeTxt: string,
    public readonly PoItemNo: string,
    public readonly PoNo: string,
    public readonly PostingDate: string,
    public readonly Quantity: string,
    public readonly RefDocNum: string,
    public readonly StockType: string,
    public readonly SystemAlias : string,
    public readonly TransactionType: string,
    public readonly Uom: string,
    public readonly ViewGRLink: string,
    
  ) {
    this.Amount = (Amount || "").trim();
    this.Quantity = (Quantity || "").trim();
  }

  static FromBackend(data: NetworkGRItemDTO): GRItem {
    const sapPrefix = sapUrl(process.env.REACT_APP_ENV.trim());
    const sapPrefixed = R.mergeRight({prefix: sapPrefix, system: data.SystemAlias});
    
    const ViewGRLink = ViewGRTemplate(sapPrefixed({gr: data.MatDocNo, year: data.FiscalYr}));

    return new GRItem(
      data.Amount,
      data.Attachment,
      data.Batch,
      data.Currency,
      data.DebitCreditInd,
      data.DelComInd,
      data.DeliveryDate,
      data.DocumentDate,
      data.FiscalYr,
      data.GoodsRecipient,
      data.GRfnam,
      data.GRlnam,
      data.MatDocItemNo,
      data.MatDocNo,
      data.MatTxt,
      data.Material,
      data.MigoUrl,
      data.MovementType,
      data.MovementTypeTxt,
      data.PoItemNo,
      data.PoNo,
      data.PostingDate,
      data.Quantity,
      data.RefDocNum,
      data.StockType,
      data.SystemAlias,
      data.TransactionType,
      data.Uom,
      ViewGRLink
    )
  }
}
