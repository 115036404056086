import {all, call, delay, fork, put, select, takeLatest, takeEvery} from 'redux-saga/effects';
import {push} from "connected-react-router";
import {
  CompetitiveBiddingTypes,
  ConvertPriceRequest,
  ConvertPriceRequestFailure,
  ConvertPriceRequestSuccess,
  FetchCBCatL2,
  FetchCBCatL2Error,
  FetchCBCatL2Success,
  FetchCBCatL3,
  FetchCBCatL3Error,
  FetchCBCatL3Success,
  FetchCBParentCatL3Error,
  FetchCBParentCatL3Success,
  FetchCBSuppliersError,
  FetchCBSuppliersSuccess,
  FetchOneCompetitiveBidding,
  FetchOneCompetitiveBiddingError,
  FetchOneCompetitiveBiddingSuccess,
  GetComplianceAlert,
  GetComplianceAlertError,
  GetComplianceAlertSuccess,
  InitialCompetitiveBidding,
  InitialCompetitiveBiddingError,
  InitialCompetitiveBiddingSuccess,
  LinkCBtoPRlist,
  LinkCBtoPRlistError,
  LinkCBtoPRlistSuccess,
  LinkPRtoCB,
  LinkPRtoCBError,
  LinkPRtoCBSuccess,
  SaveCompetitiveBiddingAction,
  SaveCompetitiveBiddingFailureAction,
  SaveCompetitiveBiddingSuccessAction,
  UploadQuotation,
  UploadQuotationError,
  UploadQuotationSuccess
} from "../actions/competitiveBiddings.actions";
import {CompetitiveBiddingService} from "../domains/cb.service";
import {MasterService} from "../../shared/domains/master/master.service";
import {SharedStateStore} from "../../shared/reducers";
import {PriceService} from "../../shared/services/price.service";
import {CompanyData} from "../../shared/domains/master/company";
import {QueryCBListActions} from "../reducers/cbList.reducer";
import {QueryableListEnum} from "../domains/competitiveBidding";
import {RootStateStore} from "../../application.reducers";
import {UploadService} from "../../shared/domains/upload/upload.service";
import {UploadItemTypes} from "../../shared/actions/upload.actions";

function* fetch(action: FetchOneCompetitiveBidding): any {
  try {
    const result = yield call(CompetitiveBiddingService.get, action.id);
    yield put(new FetchOneCompetitiveBiddingSuccess(result));
    if (result.spendCategories && result.spendCategories.length > 0) {
      const actions = result.spendCategories.map((el, index: number)=>new FetchCBCatL2Success([{code: el.subcategoryCode, name: el.subcategoryName}], index));
      yield all(actions.map(el => put(el)))
    }
  } catch (error) {
    yield put(new FetchOneCompetitiveBiddingError(error))
  }
}

function* initCompetitiveBidding(action: InitialCompetitiveBidding) {
  try {
    const [catsL1, companies, currencies] = yield all([
      call(MasterService.fetchSpendCatL1),
      call(MasterService.fetchCompanyCode),
      call(CompetitiveBiddingService.fetchCurrencies)
    ]);
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    yield put(new InitialCompetitiveBiddingSuccess(
      user,
      catsL1,
      companies.map(CompanyData.toSelect),
      currencies
    ));
  } catch (error) {
    yield put(new InitialCompetitiveBiddingError(error));
  }
}

function* fetchCatsL2(action: FetchCBCatL2) {
  try {
    const result = yield call(MasterService.fetchSubCategoriesById, action.parentId);
    yield put(new FetchCBCatL2Success(result, action.index));
  } catch (error) {
    yield put(new FetchCBCatL2Error(error));
  }
}

function* fetchCatsL3(action: FetchCBCatL3) {
  try {
    const result = yield call(MasterService.fetchSubCategoriesById, action.parentId);
    yield put(new FetchCBCatL3Success(result, action.index));
  } catch (error) {
    yield put(new FetchCBCatL3Error(error));
  }
}

function* fetchParentCatsOfL3(action) {
  try {
    const result = yield call(MasterService.fetchParentSpendCatsOfL3, action.level3SpendCatCode);
    yield put(new FetchCBParentCatL3Success(result));
    if (result.level1SpendCatCode) {
      yield put(new FetchCBCatL2(result.level1SpendCatId, action.index));
    }
    if (result.level2SpendCatCode) {
      yield put(new FetchCBCatL3(result.level2SpendCatId, action.index));
    }
    if (action.done) {
      action.done(result);
    }
  } catch (error) {
    yield put(new FetchCBParentCatL3Error(error));
  }
}

function* fetchSuppliers(action) {
  try {
    const vendors = yield call(MasterService.fetchSuppliersFromAzure);
    yield put(new FetchCBSuppliersSuccess(vendors))
  } catch (error) {
    yield put(new FetchCBSuppliersError(error));
  }
}

function* priceConversion(action: ConvertPriceRequest) {
  try {
    const result = yield call(PriceService.convertPrices, action.pricesToConvert);
    yield put(new ConvertPriceRequestSuccess(result));
    if (action.done) {
      action.done(result);
    }
  } catch (error) {
    yield put(new ConvertPriceRequestFailure(error));
  }
}

function* linkPRtoCB(action: LinkPRtoCB) {
  try {
    const result = yield call(CompetitiveBiddingService.linkPRtoCB, action.cbId, action.df);
    yield put(new LinkPRtoCBSuccess(result));
    yield delay(500);
    yield put(push('/document-flow'));
  } catch (error) {
    yield put(new LinkPRtoCBError(error));
  }
}

function* getComplianceAlert(action: GetComplianceAlert) {
  try {
    const result = yield call(CompetitiveBiddingService.getComplianceAlert, action.formData);
    yield put(new GetComplianceAlertSuccess(result.alert));
  } catch (error) {
    yield put(new GetComplianceAlertError(error));
  }
}

function* linkCBtoPRlist(action: LinkCBtoPRlist) {
  try {
    const result = yield call(CompetitiveBiddingService.linkCBtoPRlist, action.cbId, action.dfs);
    yield put(new LinkCBtoPRlistSuccess(result));
    const query = yield select(({cb}: RootStateStore) => cb.list.query);
    yield put({type: QueryCBListActions.onNewQuery, query: query, list: QueryableListEnum.CompetitiveBiddingList});
    action.done();
  } catch (error) {
    yield put(new LinkCBtoPRlistError(error));
    action.done();
  }
}

function* saveCompetitiveBidding(action: SaveCompetitiveBiddingAction): any {
  try {
    var result;
    if (action.competitiveBidding.Id) {
      result = yield call(CompetitiveBiddingService.update, action.competitiveBidding);
    } else {
      result = yield call(CompetitiveBiddingService.save, action.competitiveBidding, action.df);
    }
    yield put(new SaveCompetitiveBiddingSuccessAction(result));
    yield delay(500);
    yield put(push('/competitive-bidding'));
  } catch (error) {
    yield put(new SaveCompetitiveBiddingFailureAction(error));
  }
}

function* uploadQuotation(action: UploadQuotation) {
  try {
    const result = yield call(UploadService.upload, UploadItemTypes.CB_Quotation, action.formData);
    yield put(new UploadQuotationSuccess(action.key, action.index, result.fileName));
    if (action.onDone) {
      action.onDone(result.fileName);
    }
  } catch (error) {
    yield put(new UploadQuotationError(action.key, action.index, error));
  }
}

function* watchFetchCompetitiveBidding() {
  yield takeLatest(CompetitiveBiddingTypes.FETCHONE_COMPETITIVEBIDDING, fetch);
}

function* watchUploadQuotation() {
  yield takeLatest(CompetitiveBiddingTypes.UPLOAD_QUOTATION, uploadQuotation);
}


function* watchSaveCompetitiveBidding() {
  yield takeLatest(CompetitiveBiddingTypes.SAVE_COMPETITIVEBIDDING, saveCompetitiveBidding);
}

function* watchInitCompetitiveBidding() {
  yield takeLatest(CompetitiveBiddingTypes.INITIAL_COMPETITIVEBIDDING, initCompetitiveBidding);
}

function* watchFetchCatsL2() {
  yield takeEvery(CompetitiveBiddingTypes.FETCH_CB_CATS_L2, fetchCatsL2);
}

function* watchFetchCatsL3() {
  yield takeEvery(CompetitiveBiddingTypes.FETCH_CB_CATS_L3, fetchCatsL3);
}

function* watchFetchParentCatsOfL3() {
  yield takeEvery(CompetitiveBiddingTypes.FETCH_CB_PARENT_CATS_L3, fetchParentCatsOfL3);
}

function* watchFetchCbSuppliers() {
  yield takeLatest(CompetitiveBiddingTypes.FETCH_CB_SUPPLIERS, fetchSuppliers);
}

function* watchPriceConversion() {
  yield takeLatest(CompetitiveBiddingTypes.CONVERPRICEREQUEST, priceConversion);
}

function* watchLinkPRtoCB() {
  yield takeLatest(CompetitiveBiddingTypes.LINK_PR_TO_CB, linkPRtoCB);
}

function* watchGetComplianceAlert() {
  yield takeLatest(CompetitiveBiddingTypes.GET_COMPLIANCE_ALERT, getComplianceAlert);
}

function* watchLinkCBtoPRlist() {
  yield takeLatest(CompetitiveBiddingTypes.LINK_CB_TO_PR_LIST, linkCBtoPRlist);
}

export default function* cbSaga() {
  yield all([
    fork(watchInitCompetitiveBidding),
    fork(watchFetchCatsL2),
    fork(watchFetchCatsL3),
    fork(watchFetchParentCatsOfL3),
    fork(watchFetchCompetitiveBidding),
    fork(watchFetchCbSuppliers),
    fork(watchUploadQuotation),
    fork(watchSaveCompetitiveBidding),
    fork(watchPriceConversion),
    fork(watchLinkPRtoCB),
    fork(watchGetComplianceAlert),
    fork(watchLinkCBtoPRlist),
  ])
};
