import {IAction} from "../../shared/domains/core/actions";
import {QueryableReducer} from "../../shared/queryable/query.reducer";
import {error, loaded, loading} from "../../shared/reducers/utils";
import {PSLTypes} from "../actions/psl.actions";
import {newPslQuery} from "../domains/pslQuery";
import {ISupplier, SupplierFilterTabs, SupplierTabs, VendorInfo} from "../domains/psl";
import {IMaterial} from "../domains/pslMaterial";

export interface PSLListState {
  loading: boolean;
  exporting: boolean;
  error: string;
  totalCount: number;
  pslList: ISupplier[];
  psl: ISupplier;
  materials: IMaterial[];
  listTabs: [SupplierTabs, SupplierFilterTabs];
  vendorInfo: {
    loading: boolean,
    data: VendorInfo
  }
}

export const initialPSLlistState: PSLListState = {
  loading: false,
  exporting: false,
  error: null,
  totalCount: 0,
  pslList: [],
  psl: null,
  materials: [],
  listTabs: [SupplierTabs.MATERIALS, SupplierFilterTabs.OVERALL],
  vendorInfo: {
    loading: false,
    data: {
      code: "",
      description: "",
      street: "",
      city: "",
      district: "",
      postalCode: "",
      country: "",
      region: "",
      taxJurisdiction: "",
      companies: [],
      purchasingOrgs: []
    },
  }
};

export function pslListReducer(state: PSLListState = initialPSLlistState, action: IAction<PSLTypes>): PSLListState {
  switch (action.type) {
    case PSLTypes.FETCH_PSL_REQUEST_SUCCESS:
    case PSLTypes.FETCH_PSL_MATERIAL_REQUEST_SUCCESS:
      return loaded({...state, pslList: action.payload?.data, totalCount: action.payload?.total});
    case PSLTypes.SHOW_EMPTY_DATA:
      return loaded({...state, pslList: [], totalCount: 0 })
    case PSLTypes.FETCH_PSL_REQUEST_FAILURE:
    case PSLTypes.FETCH_PSL_MATERIAL_REQUEST_FAILURE:
    case PSLTypes.APPROVE_PSLS_FAILURE:
    case PSLTypes.REJECT_PSLS_FAILURE:
    case PSLTypes.DELETE_MATERIAL_PSLS_FAILURE:
    case PSLTypes.LOCALIZE_PSL_FAILURE:
    case PSLTypes.UPDATE_PSL_FAILURE:
    case PSLTypes.DELETE_PSLS_FAILURE:
    case PSLTypes.CHECK_MATERIALS_FOR_DUPLICATES:
      return error(action.error)(state);
    case PSLTypes.FETCH_PSL_REQUEST:
    case PSLTypes.FETCH_PSL_MATERIAL_REQUEST:
    case PSLTypes.UPDATE_PSL:
    case PSLTypes.LOCALIZE_PSL_MATERIALS:
    case PSLTypes.DELETE_PSLS:
    case PSLTypes.DELETE_MATERIAL_PSLS:
    case PSLTypes.APPROVE_PSLS:
    case PSLTypes.REJECT_PSLS:
      return loading(state);
    case PSLTypes.LOCALIZE_PSL_SUCCESS:
    case PSLTypes.UPDATE_PSL_SUCCESS:
    case PSLTypes.DELETE_PSLS_SUCCESS:
    case PSLTypes.DELETE_MATERIAL_PSLS_SUCCESS:
    case PSLTypes.APPROVE_PSLS_SUCCESS:
    case PSLTypes.REJECT_PSLS_SUCCESS:
      return loaded(state)
    case PSLTypes.FETCH_SINGLE_PSL_SUCCESS:
      return {...state, psl: action.psl};
    case PSLTypes.FETCH_SINGLE_MATERIAL_SUCCESS:
      return {...state, materials: [action.material]};
    case PSLTypes.FETCH_MATERIALS_SUCCESS:
      return {...state, materials: action.materials};
    case PSLTypes.UPDATE_LIST_TABS:
      return {...state, listTabs: action.listTabs};
    case PSLTypes.EXPORT_PSL_LIST_DATA_BY_EMAIL:
    case PSLTypes.EXPORT_PSL_LIST_DATA:
        return {...state, exporting: true};
    case PSLTypes.EXPORT_PSL_LIST_DATA_SUCCESS_BY_EMAIL:
    case PSLTypes.EXPORT_PSL_LIST_DATA_FAILURE_BY_EMAIL:
    case PSLTypes.EXPORT_PSL_LIST_DATA_SUCCESS:
    case PSLTypes.EXPORT_PSL_LIST_DATA_FAILURE:
        return {...state, exporting: false};
    case PSLTypes.UPDATE_FAVOURITE_SUCCESS:
      let pslData = [...state.pslList];
      action.index.map((pslIndex) => {
        pslData[pslIndex].isFavorite = action.isFav;
      });
      
      // if (pslData[action.index]?.isFavorite) {
      //   pslData[action.index].isFavorite = action.isFav;
      // } else {
      //   pslData[action.index].isFavorite = action.isFav;
      // }

      return { ...state, pslList: pslData };
    case PSLTypes.TOGGLE_VENDOR_LOADING:
      return {...state, vendorInfo: {loading: action?.payload, data: state.vendorInfo.data}};
    case PSLTypes.SAVE_VENDOR_INFO:
      return {...state, vendorInfo: {loading: false, data: action?.payload}};
    default:
      return state;
  }
}

export const pslStateSelector = ['psl', 'pslList'];
export const pslDomainName = 'PREFERRED_SUPPLIERS';
export const {reducer: PSLListReducer, sagas: PSLListSagas, actions: QueryPSLActions} = QueryableReducer<PSLListState>(pslDomainName, pslStateSelector, pslListReducer, newPslQuery());
