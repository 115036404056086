export enum PRStatusColumns {
  VIEW_APPROVERS = 'view_approvers',
  ARIBA_PR_NO = 'ariba_pr_no',
  ITEM_NO = 'item_no',
  SAP_PR_NO = 'sap_pr_no',
  APPROVED_BY = 'approved_by',
  APPROVAL_TYPES = 'approval_types',
  APPROVED_DATE_TIME = 'approved_date_time',
  STATUS = 'status',
  COMMENTS = 'comments',
  APPROVAL_LEVEL = 'approval_level',
  COMPANY_CODE = 'company_code',
  PURCHASING_ORGANIZATION = 'purchasing_organization',
  PURCHASING_GROUP = 'purchasing_group',
  PLANT = 'plant',
  MAIL_DATE_TIME = 'mail_date_time',
  COUNTER = 'counter',
  COST_OBJECT = 'cost_object',
  AMC_LINK_FLAG = 'amc_link_flag',
  APPROVAL_STATUS_TEXT = 'approval_status_text',
  PAYMENT_TERMS = "Payment Terms",
  EXTERNAL_REQUEST_NUMBER = "External Request Number",

}
export interface NetworkPRDTO {
  __metadata: { id: string; type: string; uri: string };
  Aribapr: string,
  ItemNo: string,
  Bukrs: string,
  Ekorg: string,
  ApprovalType: string,
  Frgsx: string,
  Frggr: string,
  Frgco: string,
  Frgkx: string,
  ApprovalInd: string,
  Ebeln: string,
  Banfn: string,
  Werks: string,
  Ekgrp: string,
  Comments: string,
  ApprovedBy: string,
  ApprovedDate: string,
  ApprovedTime: string,
  UpdatedInArb: string,
  Preis: string,
  Peinh: string,
  Waers: string,
  MailSent: string,
  MailTime: string,
  MailDate: string,
  ApprovalIndicator: string,
  SapPR: string,
  CompCode: string,
  Counter: string
  CostObject: string,
  SAP__Origin: string,
  AMC_Link_Flag: string,
  Approval_status_text: string,
  Zterm:string,
  ExtReqNo:string
}


export enum SAPBoolean {
  TRUE = "X",
  FALSE = "",
}

export class ApprovalStatusRequest {
  constructor(
    public readonly Aribapr: string,
    public readonly ItemNo: string,
    public readonly Bukrs: string,
    public readonly Ekorg: string,
    public readonly ApprovalType: string,
    public readonly Frgsx: string,
    public readonly Frggr: string,
    public readonly Frgco: string,
    public readonly Frgkx: string,
    public readonly ApprovalInd: string,
    public readonly Ebeln: string,
    public readonly Banfn: string,
    public readonly Werks: string,
    public readonly Ekgrp: string,
    public readonly Comments: string,
    public readonly ApprovedBy: string,
    public readonly ApprovedDate: string,
    public readonly ApprovedTime: string,
    public readonly UpdatedInArb: string,
    public readonly Preis: string,
    public readonly Peinh: string,
    public readonly Waers: string,
    public readonly MailSent: string,
    public readonly MailTime: string,
    public readonly MailDate: string,
    public readonly ApprovalIndicator: string,
    public readonly SapPR: string,
    public readonly CompCode: string,
    public readonly Counter: string,
    public readonly CostObject: string,
    public readonly SAP__Origin: string,
    public readonly AMC_Link_Flag: string,
    public readonly Approval_status_text: string,
    public readonly Zterm:string,
    public readonly ExtReqNo:string
  ) { }

  static FromBackend(data: NetworkPRDTO): ApprovalStatusRequest {
    return new ApprovalStatusRequest(
      data.Aribapr,
      data.ItemNo,
      data.Bukrs,
      data.Ekorg,
      data.ApprovalType,
      data.Frgsx,
      data.Frggr,
      data.Frgco,
      data.Frgkx,
      data.ApprovalInd,
      data.Ebeln,
      data.Banfn,
      data.Werks,
      data.Ekgrp,
      data.Comments,
      data.ApprovedBy,
      data.ApprovedDate,
      data.ApprovedTime,
      data.UpdatedInArb,
      data.Preis,
      data.Peinh,
      data.Waers,
      data.MailSent,
      data.MailTime,
      data.MailDate,
      data.ApprovalIndicator,
      data.SapPR,
      data.CompCode,
      data.Counter,
      data.CostObject,
      data.SAP__Origin,
      data.AMC_Link_Flag,
      data.Approval_status_text,
      data.Zterm,
      data.ExtReqNo
    )
  }

  static GetPRStatusColumns(useDefault = false): { id: PRStatusColumns, show: boolean, hidden: boolean }[] {
    const userCols = [
      { id: PRStatusColumns.VIEW_APPROVERS, show: true, hidden: false },
      { id: PRStatusColumns.ARIBA_PR_NO, show: true, hidden: false },
      { id: PRStatusColumns.ITEM_NO, show: true, hidden: false },
      { id: PRStatusColumns.SAP_PR_NO, show: true, hidden: false },
      { id: PRStatusColumns.APPROVED_BY, show: true, hidden: false },
      { id: PRStatusColumns.APPROVAL_TYPES, show: true, hidden: false },
      { id: PRStatusColumns.APPROVED_DATE_TIME, show: true, hidden: false },
      { id: PRStatusColumns.STATUS, show: true, hidden: false },
      { id: PRStatusColumns.COMMENTS, show: true, hidden: false },
      { id: PRStatusColumns.APPROVAL_LEVEL, show: true, hidden: false },
      { id: PRStatusColumns.COMPANY_CODE, show: true, hidden: false },
      { id: PRStatusColumns.PURCHASING_ORGANIZATION, show: true, hidden: false },
      { id: PRStatusColumns.PURCHASING_GROUP, show: true, hidden: false },
      { id: PRStatusColumns.PLANT, show: true, hidden: false },
      { id: PRStatusColumns.MAIL_DATE_TIME, show: true, hidden: false },
      { id: PRStatusColumns.COUNTER, show: true, hidden: false },
      { id: PRStatusColumns.COST_OBJECT, show: true, hidden: false },
      {id: PRStatusColumns.AMC_LINK_FLAG, show: true, hidden: false},
      {id: PRStatusColumns.APPROVAL_STATUS_TEXT, show: true, hidden: false},
      {id: PRStatusColumns.PAYMENT_TERMS, show: true, hidden: false},
      {id: PRStatusColumns.EXTERNAL_REQUEST_NUMBER, show: true, hidden: false},
    ];

    const columns = localStorage.getItem('PRStatus_table_config');
    if (!useDefault) {
      if (columns) {
        return JSON.parse(columns) as { id: PRStatusColumns, show: boolean, hidden: boolean }[];
      }
    }
    return userCols;
  }

  static SetPRStatusColumns(columns: { id: PRStatusColumns, show: boolean, hidden: boolean }[]): void {
    localStorage.setItem('PRStatus_table_config', JSON.stringify(columns));
  }

}
