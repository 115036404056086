interface NetworkCompanyDTO {
  __metadata: { id: string; type: string; uri: string };
  Bname: string;
  NameFirst: string;
  NameLast: string;
  SearchStr: string;
  Activity: string;
}

export class Requestor {
  constructor(
    public Bname: string,
    public NameFirst: string,
    public NameLast: string,
    public SearchStr: string,
    public Activity: string,
  ) {
  }

  static FromBackend(data: NetworkCompanyDTO): Requestor {
    return new Requestor(
      data.Bname,
      data.NameFirst,
      data.NameLast,
      data.SearchStr,
      data.Activity
    )
  }

  static toSelect(data: Requestor) {
    return {
      value: data.Bname,
      label: (data.NameFirst || data.NameLast ) && (data.NameFirst + " " + data.NameLast) || "",
      email: data.Activity
    }
  }

  static toSelectForEditRequest(data: Requestor) {
    return {
      value: data.Bname,
      firstName: data.NameFirst,
      lastName: data.NameLast,
      email: data.Activity
    }
  }

  static toFilter(data: Requestor) {
    return {
      id: data.Bname,
      title: data.NameFirst + " " + data.NameLast,
      email: data.Activity
    }
  }
} 
