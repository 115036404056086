import { assoc, flatten, filter } from "ramda";
import { ODataFilter, OrderDirection, SimpleODataFilter, SimpleODataOrder } from "../../../../../lib/odata";

export interface ODataQuery {
	path: string;
	resource: string;
	page: number;
	term: string;
	filters: SimpleODataFilter[];
	orderBy: SimpleODataOrder[];
}

export type PartialODataQuery = Partial<ODataQuery>;

export function approvalStatusQuery(): ODataQuery {
	return {
		path: "",
		resource: "",
		page: 0,
		term: "",
		filters: [],
		orderBy: []
	}
}

export interface PRFilter {
	id: string;
	title: string;
}

export interface PRFilters {
	companies: PRFilter[],
	positionId: PRFilter[],
}
  
export function updatePath(query: ODataQuery, newPath: string): ODataQuery {
	return assoc("path", newPath, resetOrder(query));
}

export function updateType(query: ODataQuery, type: string): ODataQuery {
	return assoc("type", type, resetOrder(query));
}

export function updateOrder(query: ODataQuery, field: string, direction: OrderDirection): ODataQuery {
	return assoc("orderBy", [{ field, direction }], query);
}

export function updateTerm(query: ODataQuery, newTerm: string): ODataQuery {
	return assoc("term", newTerm, query);
}

export function newPage(query: ODataQuery, newPage: number): ODataQuery {
	return assoc("page", newPage, query);
}

export function resetOrder(query: ODataQuery): ODataQuery {
	return assoc("orderBy", [], query)
}

export function newResource(query: ODataQuery, newResource: string): ODataQuery {
    return assoc("resource", newResource, query);
}

export function newFilters(query: ODataQuery, newFilter: any): ODataQuery {

	let filterArray = [];
    if(newFilter.length > 0){
        newFilter.map((item: any, index:number)=>{
         		let oDataFilterObj: SimpleODataFilter = {field: item.field, value: item.value};
         		filterArray.push(oDataFilterObj);
         	});
         }
    return assoc("filters", filterArray, query);
}


