import * as R from 'ramda';
import {AxiosWrapper} from "../../../shared/utils/axios.wrapper";
import {ContractNumber} from "../contract/contract";
import {IdNo, ListResponse} from "../contract/contract.service";
import {ItemNumber} from "../item/item";
import {ItemPrice} from "./itemPrice";
import ODataClientWrapper from "../../../shared/utils/odataClient.wrapper";
import {withSystemAlias} from "../../../shared/domains/auth/authentication.service";
import {ScalePrice} from "./scalePrice";
import {SAPBoolean} from "../../../shared/domains/core/sapBoolean";

export class ItemPriceService {
  private static ITEMS_PER_PAGE = 50;

  static async fetchItemPrices(contractNo: ContractNumber, itemNo: ItemNumber, systemAlias: string, page: number = 0): Promise<ListResponse<ItemPrice>> {
    const skip = page * ItemPriceService.ITEMS_PER_PAGE;

    let response = await ODataClientWrapper.get()
      .resource(`ContractItemSet(IdNo='${IdNo.CONTRACT}',AgreementNo='${contractNo}',ItemNo='${itemNo}')/ItemToPricing`)
      .top(ItemPriceService.ITEMS_PER_PAGE)
      .skip(skip)
      .count()
      .withSystemAlias(systemAlias, true)
      .execute();

    const results = response.data.d.results;

    return {
      count: parseInt(response.data.d.__count),
      data: results.map(ItemPrice.FromBackend)
    }
  }

  static async fetchDraftItemPrices(contractNo: ContractNumber, refContractNo: ContractNumber, itemNo: ItemNumber, systemAlias: string, timestamp: string, page: number = 0): Promise<ListResponse<ItemPrice>> {
    const skip = page * 10;
    let response = await ODataClientWrapper.get()
      .resource(`ContractItemSet(IdNo='${IdNo.DELETE_DRAFT}',AgreementNo='${contractNo}',ItemNo='${itemNo}')/ItemToPricing`)
      .filter({field: 'RefAgreementNo', value: refContractNo})
      .and({field: 'TimeStamp', value: timestamp})
      .top(ItemPriceService.ITEMS_PER_PAGE)
      .skip(skip)
      .count()
      .withSystemAlias(systemAlias, true)
      .execute();

    const results = response.data.d.results;

    return {
      count: parseInt(response.data.d.__count),
      data: results.map(ItemPrice.FromBackend)
    }
  }

  static async fetchExpiredItemPrices(contractNo: ContractNumber, itemNo: ItemNumber, systemAlias: string, page: number = 0): Promise<ListResponse<ItemPrice>> {
    const skip = page * 10;
    let response = await ODataClientWrapper.get()
      .resource(`ContractItemSet(IdNo='${IdNo.EX}',AgreementNo='${contractNo}',ItemNo='${itemNo}')/ItemToPricing`)
      .top(ItemPriceService.ITEMS_PER_PAGE)
      .skip(skip)
      .count()
      .withSystemAlias(systemAlias, true)
      .execute();

    const results = response.data.d.results;

    return {
      count: parseInt(response.data.d.__count),
      data: results.map(ItemPrice.FromBackend)
    }
  }

  static async fetchScalesPrice(conditionRecord: string, conditionItem: string): Promise<ScalePrice> {
    let response = await ODataClientWrapper.get()
      .resource("ScalesSet")
      .filter({field: "ConditionRecord", value: conditionRecord})
      .filter({field: "ConditionItem", value: conditionItem})
      .execute();

    return response.data.d.results.map(ScalePrice.FromBackend);
  }

  static async updateItemPrice(itemPrice: ItemPrice, systemAlias: string, newTargetValue?: string): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const bodyToUpdate = ItemPriceService.updateBody(itemPrice, newTargetValue);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(systemAlias, {}, true)});
    return response.data.d.ItemsToPricing.results[0];
  }

  static async updateDraftItemPrice(itemPrice: ItemPrice, systemAlias: string, newTargetValue?: string): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const bodyToUpdate = ItemPriceService.updateDraftBody(itemPrice, newTargetValue);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(systemAlias, {}, true)});
    return response.data.d.ItemsToPricing.results[0];
  }

  static async deleteItemPrice(itemPrice: ItemPrice, systemAlias: string): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const bodyToUpdate = ItemPriceService.deleteItemPriceBody(itemPrice);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(systemAlias, {}, true)});
    return response.data.d.ItemsToPricing.results[0];
  }

  static async addItemPrice(itemPrice: ItemPrice, systemAlias: string): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const bodyToUpdate = ItemPriceService.addItemPriceBody(itemPrice);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(systemAlias, {}, true)});
    return response.data.d.ItemsToPricing.results[0];
  }

  static async updateScalesPrice(itemPrice: ItemPrice, scalesPrice: ScalePrice[]): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const bodyToUpdate = ItemPriceService.updateScalesPriceBody(itemPrice, scalesPrice);
    return await AxiosWrapper.post(path, bodyToUpdate);
  }

  private static updateDraftBody(itemPrice: ItemPrice, newTargetValue?: string): any {
    const body = {
      results: {
        IdNo: IdNo.DRAFT,
        DataChanged: SAPBoolean.TRUE,
        ItemsToPricing: {
          results: [
            {
              IdNo: IdNo.DRAFT,
              RefAgreementNo: itemPrice.RefAgreementNo,
              AgreementNo: itemPrice.AgreementNo,
              TimeStamp: itemPrice.TimeStamp,
              ItemNo: itemPrice.ItemNo,
              Material: itemPrice.Material,
              ConditionRecord: itemPrice.ConditionRecord,
              ConditionItem: itemPrice.ConditionItem,
              ValidFrom: itemPrice.ValidFrom,
              ValidTo: itemPrice.ValidTo,
              Amount: itemPrice.Amount,
              Unit: itemPrice.Unit,
              Per: itemPrice.Per,
              UoM: itemPrice.UoM,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    };

    return newTargetValue ? R.assocPath(['results', 'TargetValue'], newTargetValue, body) : body;
  }

  private static updateBody(itemPrice: ItemPrice, newTargetValue?: string): any {
    const body = {
      results: {
        IdNo: IdNo.ITEM_PRICE,
        DataChanged: SAPBoolean.TRUE,
        ItemsToPricing: {
          results: [
            {
              IdNo: IdNo.ITEM_PRICE,
              AgreementNo: itemPrice.AgreementNo,
              ItemNo: itemPrice.ItemNo,
              Material: itemPrice.Material,
              ConditionRecord: itemPrice.ConditionRecord,
              ConditionItem: itemPrice.ConditionItem,
              ValidFrom: itemPrice.ValidFrom,
              ValidTo: itemPrice.ValidTo,
              Amount: itemPrice.Amount,
              Unit: itemPrice.Unit,
              Per: itemPrice.Per,
              UoM: itemPrice.UoM,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    };

    return newTargetValue ? R.assocPath(['results', 'TargetValue'], newTargetValue, body) : body;
  }

  private static addItemPriceBody(itemPrice: ItemPrice): any {
    return {
      results: {
        IdNo: IdNo.AP,
        DataChanged: SAPBoolean.TRUE,
        ItemsToPricing: {
          results: [
            {
              IdNo: IdNo.AP,
              AgreementNo: itemPrice.AgreementNo,
              ItemNo: itemPrice.ItemNo,
              Material: itemPrice.Material,
              ConditionRecord: itemPrice.ConditionRecord,
              ConditionItem: itemPrice.ConditionItem,
              ValidFrom: itemPrice.ValidFrom,
              ValidTo: itemPrice.ValidTo,
              Amount: itemPrice.Amount,
              Unit: itemPrice.Unit,
              Per: itemPrice.Per,
              UoM: itemPrice.UoM,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    };
  }

  private static deleteItemPriceBody(itemPrice: ItemPrice): any {
    return {
      results: {
        IdNo: IdNo.AP,
        DataChanged: SAPBoolean.TRUE,
        CopyDeleted: SAPBoolean.TRUE,
        ItemsToPricing: {
          results: [
            {
              IdNo: IdNo.AP,
              AgreementNo: itemPrice.AgreementNo,
              ItemNo: itemPrice.ItemNo,
              Material: itemPrice.Material,
              ConditionRecord: itemPrice.ConditionRecord,
              ConditionItem: itemPrice.ConditionItem,
              ValidFrom: itemPrice.ValidFrom,
              ValidTo: itemPrice.ValidTo,
              Amount: itemPrice.Amount,
              Unit: itemPrice.Unit,
              Per: itemPrice.Per,
              UoM: itemPrice.UoM,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    };
  }

  private static updateScalesPriceBody(itemPrice: ItemPrice, scalesPrice: ScalePrice[]): any {
    return {
      results: {
        IdNo: IdNo.SC,
        DataChanged: SAPBoolean.TRUE,
        IdToScales: {
          results: scalesPrice.map(sc => ({
            IdNo: IdNo.SC,
            AgreementNo: itemPrice.AgreementNo,
            ItemNo: itemPrice.ItemNo,
            ConditionRecord: sc.ConditionRecord,
            ConditionItem: sc.ConditionItem,
            LineNo: sc.LineNo,
            CondValue: sc.CondValue,
            ScaleQuantity: sc.ScaleQuantity
          }))
        }
      }
    }
  }
}
