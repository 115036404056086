import {error, loaded, loading, LoadState} from "../../shared/reducers/utils";
import {mergeRight} from "ramda";
import {UserRating} from "../domains/feedback";
import {IAction} from "../../shared/domains/core/actions";
import {QueryableReducer} from "../../shared/queryable/query.reducer";
import {newFeedbackQuery} from "../domains/feedbackQuery";
import {RatingTypes} from "../actions/rating.actions";

export interface RatingState extends LoadState {
  ratingList: UserRating[],
  graphList: UserRating[],
  totalCount: number;
}

export const initialAdminState: RatingState = {
  ratingList: [],
  graphList: [],
  totalCount: 0,
  loading: false,
  error: null
};

export function feedbackReducer(state: RatingState = initialAdminState, action: IAction<RatingTypes>): RatingState {
  switch (action.type) {
    case RatingTypes.FETCH_RATING_LIST_REQUEST:
      return loading(state);
    case RatingTypes.FETCH_RATING_LIST_REQUEST_SUCCESS:
      return loaded(mergeRight(state, {ratingList: action.payload.data, totalCount: action.payload.count}));
    case RatingTypes.FETCH_RATING_GRAPH_SUCCESS:
      return loaded(mergeRight(state, {graphList: action.payload.data}));
    case RatingTypes.FETCH_RATING_LIST_REQUEST_FAILURE:
    case RatingTypes.FETCH_RATING_GRAPH_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}

export const ratingStateSelector = ['admin', 'ratingList'];
export const ratingDomainName = 'RATING';
export const {reducer: RatingReducer, sagas: RatingListSagas, actions: QueryRatingActions} = QueryableReducer<RatingState>(ratingDomainName, ratingStateSelector, feedbackReducer, newFeedbackQuery());
