import * as R from "ramda";
import {DateTime} from "luxon";
import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import moment from 'moment';
import {QueryActionsI} from '../reducers/queryableInvoiceReducer';
import {IInvoiceFilters} from "../../../shared/domains/user/user";
import {DateRangeFilter, TextFilter} from "../../../shared/domains/query/filter";
import {UserService} from "../../../shared/domains/user/user.service";
import {
  SaveInvoicesFiltersError,
  SaveInvoicesFiltersSuccess
} from "../../../shared/actions/user.actions";
import {InvoiceFilterKeys} from "../domains/invoices/InvoiceQuery";


export function convertToInvoiceFilters(filters){
    const invoiceDateRange: DateRangeFilter = R.path(['InvDate', 'selected'], filters);
    const fiscalYear: TextFilter[] = R.path(['FiscalYr', 'selected'], filters);
    const companies: TextFilter[] = R.path(['CompCode', 'selected'], filters);
    const pOrgs: TextFilter[] = R.path(['PurchOrg', 'selected'], filters);
    const invoiceNumber: TextFilter[] = R.path(['InvDocNo', 'selected'], filters);
    const supplier: TextFilter[] = R.path(['InvPartner', 'selected'], filters);
    const poNumber: TextFilter[] = R.path(['PoNo', 'selected'], filters);
    const prNumber: TextFilter[] = R.path(['PrNo', 'selected'], filters);
    const workflowAgent: TextFilter[] = R.path(['WfAgent', 'selected'], filters);
    const nestleSupplierInvoice: TextFilter[] = R.path(['RefDoc', 'selected'], filters);


return {
      fiscalYear: fiscalYear,
      companies: companies,
      pOrgs: pOrgs,
      invoiceNumber: invoiceNumber,
      supplier: supplier,
      poNumber: poNumber,
      prNumber: prNumber,
      workflowAgent: workflowAgent,
      nestleSupplierInvoice: nestleSupplierInvoice,
      poCreator: R.path([InvoiceFilterKeys.PO_REQUESTOR, 'selected'], filters),
      prCreator: R.path([InvoiceFilterKeys.PR_REQUESTOR, 'selected'], filters),
      plants: R.path([InvoiceFilterKeys.PLANT, 'selected'], filters),
      purchGrp: R.path([InvoiceFilterKeys.PURCH_GROUP, 'selected'], filters),
      parkingReasonCode: R.path([InvoiceFilterKeys.PARKING_REASON_CODE, 'selected'], filters),
      BlckRsn: R.path([InvoiceFilterKeys.BLOCKING_REASON_CODE, 'selected'], filters),
      invoiceDateRange: (invoiceDateRange.startDate && invoiceDateRange.endDate) ?
      {
        startDate: moment.utc(invoiceDateRange.startDate, 'YYYYMMDD'),
        endDate: moment.utc(invoiceDateRange.endDate, 'YYYYMMDD')
      } : null,
    };  
}


function* saveFilters(action) {
  try {
    const invoiceDateRange: DateRangeFilter = R.path(['InvDate', 'selected'], action.filters);
    const fiscalYear: TextFilter[] = R.path(['FiscalYr', 'selected'], action.filters);
    const companies: TextFilter[] = R.path(['CompCode', 'selected'], action.filters);
    const pOrgs: TextFilter[] = R.path(['PurchOrg', 'selected'], action.filters);
    const invoiceNumber: TextFilter[] = R.path(['InvDocNo', 'selected'], action.filters);
    const supplier: TextFilter[] = R.path(['InvPartner', 'selected'], action.filters);
    const poNumber: TextFilter[] = R.path(['PoNo', 'selected'], action.filters);
    const prNumber: TextFilter[] = R.path(['PrNo', 'selected'], action.filters);
    const workflowAgent: TextFilter[] = R.path(['WfAgent', 'selected'], action.filters)
    const nestleSupplierInvoice: TextFilter[] = R.path(['RefDoc', 'selected'], action.filters)

    const filters: IInvoiceFilters = action.skipMapping ? action.filters : {
      fiscalYear: fiscalYear,
      companies: companies,
      pOrgs: pOrgs,
      invoiceNumber: invoiceNumber,
      supplier: supplier,
      poNumber: poNumber,
      prNumber: prNumber,
      workflowAgent: workflowAgent,
      nestleSupplierInvoice: nestleSupplierInvoice,
      poCreator: R.path([InvoiceFilterKeys.PO_REQUESTOR, 'selected'], action.filters),
      prCreator: R.path([InvoiceFilterKeys.PR_REQUESTOR, 'selected'], action.filters),
      plants: R.path([InvoiceFilterKeys.PLANT, 'selected'], action.filters),
      purchGrp: R.path([InvoiceFilterKeys.PURCH_GROUP, 'selected'], action.filters),
      BlckRsn: R.path([InvoiceFilterKeys.BLOCKING_REASON_CODE, 'selected'], action.filters),
      parkingReasonCode: R.path([InvoiceFilterKeys.PARKING_REASON_CODE, 'selected'], action.filters),
      invoiceDateRange: (invoiceDateRange.startDate && invoiceDateRange.endDate) ?
        {
          startDate: moment.utc(invoiceDateRange.startDate, 'YYYYMMDD'),
          endDate: moment.utc(invoiceDateRange.endDate, 'YYYYMMDD')
        } : null,
    };
    yield call(UserService.saveInvoiceFilters, filters);
    yield put(new SaveInvoicesFiltersSuccess(R.mergeRight(filters, {
      invoiceDateRange: invoiceDateRange
    })));
  } catch (error) {
    yield put(new SaveInvoicesFiltersError(error));
  }
}

function* watchSaveFilters() {
  yield takeLatest(QueryActionsI.onSaveFilters, saveFilters);
}

export default function* invFilterSaga() {
  yield all([
    fork(watchSaveFilters)
  ])
};
