import { ODataClient } from "../../../lib/odata";
import { AxiosResponse } from "axios";
import * as R from "ramda";
import { hasPath, is, not } from "ramda";
import { MalformedJsonException } from "../domains/core/exceptions";

export interface ListResponseDTO<T> {
  d: {
    results: T[];
    __count?: string;
  };
}

export interface ListResponseDTOWithStatus<T> {
  d: {
    results: T[];
    __status?: object;
    __count?: string;
  };
}

export type AxiosListResponseDTO<T> = AxiosResponse<ListResponseDTO<T>>;

export class List<T> {
  constructor(
    public readonly data: T[],
    public readonly count: number = data.length,
  ) {}

  map<V>(mapper: (a: T) => V): List<V> {
    return new List<V>(this.data.map(mapper), this.count);
  }

  reduce(d: any): ListWithStatus<T> {
    return new ListWithStatus<T>(
      this.data,
      this.count,
      {
        Completed: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.Completed, 0) : undefined,
        ToAddCb: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToAddCb, 0) : undefined,
        ToApprvPo: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToApprvPo, 0) : undefined,
        ToApprvPr: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToApprvPr, 0) : undefined,
        ToAwaitDel: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToAwaitDel, 0) : undefined,
        ToAwaitInv: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToAwaitInv, 0) : undefined,
        ToChkGrInv: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToChkGrInv, 0) : undefined,
        ToChkPrcInv: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToChkPrcInv, 0) : undefined,
        ToClosePo: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToClosePo, 0) : undefined,
        ToCreatePo: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToCreatePo, 0) : undefined,
        ToPayInv: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToPayInv, 0) : undefined,
        ToPostGr: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToPostGr, 0) : undefined,
        ToSolvInv: d.length ? d.reduce((accumulator, currentValue) => accumulator + currentValue.ToSolvInv, 0) : undefined,
      }
    );
  }

  reduceInv(d: any): ListWithStatusInv<T> {
    return new ListWithStatusInv<T>(
      this.data,
      this.count,
      {
        BlckCnt: d.length ? d.reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.BlckCnt) || 0, 0) : undefined,
        PrkCnt: d.length ? d.reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.PrkCnt) || 0, 0) : undefined,
      }
    );
  }

  static FromDTO<T>(response: AxiosListResponseDTO<T>) {
    if (
      not(hasPath(["data", "d", "results"])(response)) ||
      not(is(Array, response.data.d.results))
    ) {
      throw new MalformedJsonException(JSON.stringify(response));
    }

    return new List(response.data.d.results, parseInt(response.data.d.__count));
  }

  static empty() {
    return new List([], 0);
  }
}

export class ListWithStatus<T> {
  constructor(
    public readonly data: T[],
    public readonly count: number = data.length,
    public status: {
      Completed: number;
      ToAddCb: number;
      ToApprvPo: number;
      ToApprvPr: number;
      ToAwaitDel: number;
      ToAwaitInv: number;
      ToChkGrInv: number;
      ToChkPrcInv: number;
      ToClosePo: number;
      ToCreatePo: number;
      ToPayInv: number;
      ToPostGr: number;
      ToSolvInv: number;
    }
  ) {}

  map<V>(mapper: (a: T) => V): ListWithStatus<V> {
    return new ListWithStatus<V>(
      this.data.map(mapper),
      this.count,
      this.status
    );
  }

  reduce(d: any): ListWithStatus<T> {
    return new ListWithStatus<T>(
      this.data.reduce((a, b) => a.concat(b), []),
      this.count,
      this.status
    );
  }

  static empty() {
    return new ListWithStatus([], 0, {
      Completed: 0,
      ToAddCb: 0,
      ToApprvPo: 0,
      ToApprvPr: 0,
      ToAwaitDel: 0,
      ToAwaitInv: 0,
      ToChkGrInv: 0,
      ToChkPrcInv: 0,
      ToClosePo: 0,
      ToCreatePo: 0,
      ToPayInv: 0,
      ToPostGr: 0,
      ToSolvInv: 0
    });
  }
}

export class ListWithStatusInv<T> {
  constructor(
    public readonly data: T[],
    public readonly count: number = data.length,
    public status: {
      BlckCnt: number;
      PrkCnt: number;
    }
  ) {}

  map<V>(mapper: (a: T) => V): ListWithStatusInv<V> {
    return new ListWithStatusInv<V>(
      this.data.map(mapper),
      this.count,
      this.status
    );
  }

  reduceInv(d: any): ListWithStatusInv<T> {
    return new ListWithStatusInv<T>(
      this.data.reduce((a, b) => a.concat(b), []),
      this.count,
      this.status
    );
  }

  static empty() {
    return new ListWithStatusInv([], 0, {
      BlckCnt: 0,
      PrkCnt: 0,
    });
  }
}

export default class ODataClientWrapper<T = any> extends ODataClient {
  static get<V>(): ODataClientWrapper<V> {
    return new ODataClientWrapper<V>("/api/sapdata/Get?query=");
  }

  static getExport<V>(): ODataClientWrapper<V> {
    return new ODataClientWrapper<V>("/api/sapdata/Get?query=");
  }

  static post<V>(): ODataClientWrapper<V> {
    return new ODataClientWrapper<V>("/api/sapdata/PostFromBody?query=IdSet");
  }

  async run(): Promise<List<T>> {
    try {
      const response: AxiosResponse<ListResponseDTO<T>> = await super.execute();
      return List.FromDTO<T>(response);
    } catch (e) {
      const errorDetailsPath = ["innererror", "errordetails"];

      if (R.hasPath(errorDetailsPath, e)) {
        const errorDetails: { message: string }[] = R.path(errorDetailsPath, e);
        const errorMessage = errorDetails.map(x => x.message).join("###");
        throw new Error(errorMessage);
      }

      throw e;
    }
  }
}
