import { Tooltip } from "@material-ui/core";
import * as React from "react";
import { CustomFastSelect } from "../form";
import { StyledIconButton, StyledInput, StyledPaper } from "../search/search.styles";
import { useStylesForToolTip } from "../tooltip/toolTip.styles";
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from "react";

interface searchComponentProps {
    searchOptions: { value: string; label: string; }[],
    onSearch: any,
    width?: string,
    initialSearch?: string,
    initialCategory?: { label: string, value } | null
}

export default function GenericSearchCategoryComponent({ searchOptions, onSearch, width = "200px", initialCategory = null, initialSearch = "" }: searchComponentProps) {
    const toolTipClass = useStylesForToolTip({});
    const [categorySelected, setCategorySelected] = useState(initialCategory);
    const [searchValue, setSearchValue] = useState<string>(initialSearch);
    const isItFirstRun = React.useRef(true);

    const handleSearch = (category: { label: string, value } | null = categorySelected, finalSearchValue: string = searchValue) => {
        onSearch(category, finalSearchValue.trim());
        setSearchValue(prevState => prevState.trim());
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter' || e.target.value === "") {
            handleSearch(categorySelected, e.target.value);
        }
    };

    const handleCatChange = (selectedCategory: { label: string, value } | null) => {
        setCategorySelected(selectedCategory);
        setSearchValue("");
        if (!selectedCategory) { // this is to fetch data again when search cat is cleared
            handleSearch(selectedCategory, "");
        }
    }

    useEffect(() => {
        if (isItFirstRun.current) {
            isItFirstRun.current = false;
            return;
        }
        setCategorySelected(initialCategory);
        setSearchValue(initialSearch);
    }, [initialCategory, initialSearch]);

    return <div style={{ display: "flex" }}>
        <div style={{ width: width, zIndex: 12 }}>
            <CustomFastSelect
                placeholder={'Select Category'}
                options={searchOptions}
                initialValue={categorySelected}
                onChange={handleCatChange}
                changeBorderRadius={true} />
        </div>
        <Tooltip
            classes={{ tooltip: toolTipClass.customTooltip }}
            title={!categorySelected ? "Select a category" : ""}
        >
            <StyledPaper elevation={0} style={{ borderRadius: "0px 4px 4px 0px", height: "38px", width: "200px" }} >
                <StyledInput
                    inputProps={{ 'aria-label': 'Search...' }}
                    disabled={!categorySelected}
                    value={searchValue}
                    onKeyUp={handleKeyUp}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <StyledIconButton aria-label="Search" disabled={categorySelected ? false : true} onClick={() => handleSearch()}>
                    <SearchIcon />
                </StyledIconButton>
            </StyledPaper>
        </Tooltip>
    </div>
}