import {ItemPrice} from "../domains/itemPrice/itemPrice";
import {SuccessNotificationAction} from "../../shared/domains/core/notifications";
import {ErrorAction, IPromiseExecutor, PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {ContractNumber} from "../domains/contract/contract";
import {ScalePrice} from "../domains/itemPrice/scalePrice";

export enum ItemPriceActionTypes {
  FETCH_ITEMSPRICE = "FETCH_ITEMSPRICE",
  FETCH_ITEMSPRICE_SUCCESS = "FETCH_ITEMSPRICE_SUCCESS",
  FETCH_ITEMSPRICE_FAILURE = "FETCH_ITEMSPRICE_FAILURE",

  FETCH_CLM_ITEMSPRICE = "FETCH_CLM_ITEMSPRICE",
  FETCH_CLM_ITEMSPRICE_SUCCESS = "FETCH_CLM_ITEMSPRICE_SUCCESS",
  FETCH_CLM_ITEMSPRICE_FAILURE = "FETCH_CLM_ITEMSPRICE_FAILURE",

  FETCH_DRAFT_ITEMSPRICE = "FETCH_DRAFT_ITEMSPRICE",
  FETCH_DRAFT_ITEMSPRICE_SUCCESS = "FETCH_DRAFT_ITEMSPRICE_SUCCESS",
  FETCH_DRAFT_ITEMSPRICE_FAILURE = "FETCH_DRAFT_ITEMSPRICE_FAILURE",

  UPDATE_ITEMSPRICE = "UPDATE_ITEMSPRICE",
  UPDATE_ITEMSPRICE_SUCCESS = "UPDATE_ITEMSPRICE_SUCCESS",
  UPDATE_ITEMSPRICE_FAILURE = "UPDATE_ITEMSPRICE_FAILURE",

  UPDATE_ITEMSPRICE_DRAFT = "UPDATE_ITEMSPRICE_DRAFT",
  UPDATE_ITEMSPRICE_DRAFT_SUCCESS = "UPDATE_ITEMSPRICE_DRAFT_SUCCESS",
  UPDATE_ITEMSPRICE_DRAFT_FAILURE = "UPDATE_ITEMSPRICE_DRAFT_FAILURE",

  ADD_ITEM_PRICE = "ADD_ITEM_PRICE",
  ADD_ITEM_PRICE_SUCCESS = "ADD_ITEM_PRICE_SUCCESS",
  ADD_ITEM_PRICE_FAILURE = "ADD_ITEM_PRICE_FAILURE",

  DELETE_ITEM_PRICE = "DELETE_ITEM_PRICE",
  DELETE_ITEM_PRICE_SUCCESS = "DELETE_ITEM_PRICE_SUCCESS",
  DELETE_ITEM_PRICE_FAILURE = "DELETE_ITEM_PRICE_FAILURE",

  FETCH_SCALESPRICE = "FETCH_SCALESPRICE",
  FETCH_SCALESPRICE_SUCCESS = "FETCH_SCALESPRICE_SUCCESS",
  FETCH_SCALESPRICE_FAILURE = "FETCH_SCALESPRICE_FAILURE",

  UPDATE_SCALESPRICE = "UPDATE_SCALESPRICE",
  UPDATE_SCALESPRICE_SUCCESS = "UPDATE_SCALESPRICE_SUCCESS",
  UPDATE_SCALESPRICE_FAILURE = "UPDATE_SCALESPRICE_FAILURE",

  ADD_CLM_ITEM_PRICE = "ADD_CLM_ITEM_PRICE",
  ADD_CLM_ITEM_PRICE_SUCCESS = "ADD_CLM_ITEM_PRICE_SUCCESS",
  CLM_ITEM_PRICE_CHANGE = "CLM_ITEM_PRICE_CHANGE"
}

export class FetchItemsPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_ITEMSPRICE;

  constructor(public contractNumber, public readonly itemNo: string, public systemAlias: string) {
    super();
  }
}

export class FetchItemsPriceSuccess extends SuccessAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_ITEMSPRICE_SUCCESS;

  constructor(public readonly itemNo: string, public readonly items: ItemPrice[], public readonly totalCount: number) {
    super();
  }
}

export class FetchItemsPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_ITEMSPRICE_FAILURE;

  constructor(public readonly itemNo: string, public readonly error) {
    super();
  }
}

export class FetchDraftItemsPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_DRAFT_ITEMSPRICE;

  constructor(public contractNumber, public refContractNumber, public systemAlias: string, public timestamp, public readonly itemNo: string) {
    super();
  }
}

export class FetchDraftItemsPriceSuccess extends SuccessAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_DRAFT_ITEMSPRICE_SUCCESS;

  constructor(public readonly itemNo: string, public readonly items: ItemPrice[], public readonly totalCount: number) {
    super();
  }
}

export class FetchDraftItemsPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_DRAFT_ITEMSPRICE_FAILURE;

  constructor(public readonly itemNo: string, public readonly error) {
    super();
  }
}

export class UpdateItemsPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_ITEMSPRICE;

  constructor(public executor: IPromiseExecutor, public itemPrice, public SystemAlias, public targetValue, public currentPage) {
    super();
  }
}

export class UpdateItemsPriceSuccess extends SuccessNotificationAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_ITEMSPRICE_SUCCESS;

  constructor(public executor: IPromiseExecutor, public itemPrice, public newTargetValue) {
    super();
    this.executor.resolve();
  }
}

export class UpdateItemsPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_ITEMSPRICE_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);
    this.executor.reject();
  }
}

export class UpdateDraftItemsPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_ITEMSPRICE_DRAFT;

  constructor(public executor: IPromiseExecutor, public itemPrice, public SystemAlias, public timestamp, public currentPage, public targetValue) {
    super();
  }
}

export class UpdateItemsDraftPriceSuccess extends SuccessNotificationAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_ITEMSPRICE_DRAFT_SUCCESS;

  constructor(public executor: IPromiseExecutor, public itemPrice, public timestamp, public newTargetValue) {
    super();
    this.executor.resolve();
  }
}

export class UpdateItemsDraftPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_ITEMSPRICE_DRAFT_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);
    this.executor.reject();
  }
}

export class DeleteItemPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.DELETE_ITEM_PRICE;

  constructor(public contractNo: ContractNumber, public itemPrice, public SystemAlias, public currentPage, public done) {
    super();
  }
}

export class DeleteItemPriceSuccess extends SuccessAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.DELETE_ITEM_PRICE_SUCCESS;

  constructor(public itemPrice) {
    super();
  }
}

export class DeleteItemPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.DELETE_ITEM_PRICE_FAILURE;

  constructor(public itemPrice, public error) {
    super(error);
  }
}

export class AddItemPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.ADD_ITEM_PRICE;

  constructor(public contractNo: ContractNumber, public itemPrice, public SystemAlias, public currentPage, public done) {
    super();
  }
}

export class AddItemPriceSuccess extends SuccessAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.ADD_ITEM_PRICE_SUCCESS;

  constructor(public itemPrice) {
    super();
  }
}

export class AddItemPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.ADD_ITEM_PRICE_FAILURE;

  constructor(public itemPrice, public error) {
    super(error);
  }
}

export class FetchClmItemsPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_CLM_ITEMSPRICE;

  constructor(public contractNumber, public readonly itemNo: string, public systemAlias: string) {
    super();
  }
}

export class FetchClmItemsPriceSuccess extends SuccessAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_CLM_ITEMSPRICE_SUCCESS;

  constructor(public readonly itemNo: string, public readonly items: ItemPrice[]) {
    super();
  }
}

export class FetchClmItemsPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_CLM_ITEMSPRICE_FAILURE;

  constructor(public readonly itemNo: string, public readonly error) {
    super();
  }
}

export class FetchScalesPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_SCALESPRICE;

  constructor(public itemPrice: ItemPrice) {
    super();
  }
}

export class FetchScalesPriceSuccess extends SuccessAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_SCALESPRICE_SUCCESS;

  constructor(public scalesPrice: ScalePrice[]) {
    super();
  }
}

export class FetchScalesPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.FETCH_SCALESPRICE_FAILURE;
}

export class UpdateScalesPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_SCALESPRICE;

  constructor(public itemPrice, public scalesPrice, public systemAlias: string, public done) {
    super();
  }
}

export class UpdateScalesPriceSuccess extends SuccessNotificationAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_SCALESPRICE_SUCCESS;

  constructor(public message) {
    super();
  }
}

export class UpdateScalesPriceError extends ErrorAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.UPDATE_SCALESPRICE_FAILURE;
}

export class AddClmItemPrice extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.ADD_CLM_ITEM_PRICE;

  constructor(public itemPrice) {
    super();
  }
}

export class AddClmItemPriceSuccess extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.ADD_CLM_ITEM_PRICE_SUCCESS;

  constructor(public itemPrice, public newItemsPrice) {
    super();
  }
}

export class ClmItemPriceChange extends PendingAction<ItemPriceActionTypes> {
  public readonly type = ItemPriceActionTypes.CLM_ITEM_PRICE_CHANGE;

  constructor(public itemPrice) {
    super();
  }
}
