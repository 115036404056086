import {all, fork} from 'redux-saga/effects';
import userSaga from "./shared/sagas/user.saga";
import uploadSaga from "./shared/sagas/upload.saga";
import feedbackSaga from "./admin/sagas";
import contractSaga from "./contracts/sagas";
import tenderCalendarSaga from "./tenderCalendar/sagas";
import purchaseRequisitionSage from "./purchaseRequisition/sagas"
import cbSaga from "./competitiveBidding/sagas/cb.saga";
import summarySaga from "./competitiveBidding/sagas/summary.saga";
import cbListSaga from "./competitiveBidding/sagas/cbList.saga";
import {queryCBListSagas} from './competitiveBidding/reducers/cbList.reducer';
import pslSaga from "./preferredSuppliers/sagas/psl.saga";
import {PSLListSagas} from './preferredSuppliers/reducers/pslList.reducer';
import {PslApproverListSagas} from './preferredSuppliers/reducers/pslApproverList.reducer';
import {MRPListSagas} from './mrp/reducers/mrpList.reducer';
import pslFilterSaga from "./preferredSuppliers/sagas/pslFilters.saga";
import cbFilterSaga from "./competitiveBidding/sagas/cbFilters.saga";
import mrpSaga from "./mrp/sagas/mrp.saga";
import mrpFilterSaga from "./mrp/sagas/mrpFilters.saga";
import documentCounterSaga from "./docFlows/sagas/documentCounter.saga";
import documentFlowSaga from "./docFlows/sagas/documentFlow.saga";
import invoicesSaga from "./docFlows/invoices/sagas/invoices.saga";
import dfFilterSaga from "./docFlows/sagas/dfFilters.saga";
import invFilterSaga from "./docFlows/invoices/sagas/invFilters.saga";
import docflowChatSaga from "./docFlows/sagas/docflowChat.saga";
import invoiceChatSaga from "./docFlows/invoices/sagas/invoiceChat.saga";
import PRItemsSaga from "./docFlows/sagas/items.saga";
import {queryDocFlowSagas} from './docFlows/reducers/queryableDocumentFlowReducer';
import {queryInvoiceSagas} from './docFlows/invoices/reducers/queryableInvoiceReducer';
import {docflowCoreChatSaga} from './docFlows/reducers/docflowChat.reducer';
import {invoiceCoreChatSaga} from "./docFlows/reducers/invoiceChat.reducer";
import documentFlowPersonalNoteSaga from './docFlows/sagas/docFlowPersonalNote.saga';
import invoicePersonalNoteSaga from './docFlows/invoices/sagas/invoicePersonalNote.saga';

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(contractSaga),
    fork(docflowCoreChatSaga),
    fork(invoiceCoreChatSaga),
    fork(tenderCalendarSaga),
    fork(purchaseRequisitionSage),
    fork(documentCounterSaga),
    fork(documentFlowSaga),
    fork(invoicesSaga),
    fork(documentFlowPersonalNoteSaga),
    fork(invoicePersonalNoteSaga),
    fork(dfFilterSaga),
    fork(invFilterSaga),
    fork(docflowChatSaga),
    fork(invoiceChatSaga),
    fork(cbSaga),
    fork(summarySaga),
    fork(cbListSaga),
    fork(cbFilterSaga),
    fork(queryDocFlowSagas),
    fork(queryInvoiceSagas),
    fork(queryCBListSagas),
    fork(PRItemsSaga),
    fork(pslSaga),
    fork(mrpSaga),
    fork(pslFilterSaga),
    fork(mrpFilterSaga),
    fork(PSLListSagas),
    fork(PslApproverListSagas),
    fork(MRPListSagas),
    fork(feedbackSaga),
    fork(uploadSaga),
  ])
};
