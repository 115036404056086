import { assoc, assocPath, either, isNil, isEmpty, toPairs } from "ramda";
import { o } from 'odata';
import moment from 'moment';
import { AxiosWrapper } from "../../shared/utils/axios.wrapper";
import {
  ContractCalendarEvent,
  ContractCalendarEventInfo,
  NetworkODataContractCalendarEventDTO,
  CodeNameDTO,
  MarketDTO,
  CALENDAR_ALL_OPTION,
} from "./calendar";
import { withAuthHeader } from "../../shared/domains/auth/authentication.service";
import { List } from "../../shared/utils/odataClient.wrapper";
import { newQuery, toODataFilter } from "../../shared/queryable/query";
import { ODataFilter, ODataFormats, Operators } from "../../../lib/odata";
import {IContractCalendarFilters, IPrismFilter} from "../../shared/domains/user/user";
import { environment } from "../../shared/domains/core/environment";

export class CalendarService {
  static async fetchInfo(): Promise<ContractCalendarEventInfo> {
    const path = `/api/ContractsCalendar/GetInfoForNewEvent`;
    const result: any = await AxiosWrapper.get(path);
    return ContractCalendarEventInfo.FromBackend(result.data);
  }

  private static mapToODataFilter([key, filter]) {
    const selected = filter.selected;
    const value = selected?.value;
    switch (key) {
      case 'From':
        return toODataFilter(assocPath(['selected', 'value'], value ? moment.utc(value, ODataFormats.ISO_DATE).toISOString() : null, filter), Operators.GE);
      case 'To':
        return toODataFilter(assocPath(['selected', 'value'], value ? moment.utc(value, ODataFormats.ISO_DATE).toISOString() : null, filter), Operators.LE);
      case 'SpendCategoryLevel1s':
      case 'Clusters':
      case 'Zones':
      case 'Companies':
      case 'Plants':
        return toODataFilter(assocPath(['selected', 'value'], !either(isNil, isEmpty)(selected) ? selected.map(s => `'${s.id}'`).join(',') : null, filter), Operators.IN, 'code');
      case 'Markets':
        return toODataFilter(assocPath(['selected', 'value'], !either(isNil, isEmpty)(selected) ? selected.map(s => `'${s.title}'`).join(',') : null, filter), Operators.IN);
      case 'BuyerEmail':
        return toODataFilter(assoc('selected', !either(isNil, isEmpty)(selected) ? selected.map(s => ({ id: s.title })) : null, filter), Operators.EQ);
      default:
        return toODataFilter(filter);
    }
  }

  static async fetchEvents(query = newQuery()): Promise<List<ContractCalendarEvent>> {
    const list = toPairs(query.filters);
    const filters = list.map(CalendarService.mapToODataFilter);
    const fullFilters = filters.reduce((previousValue, currentValue) => previousValue.and(currentValue.length && currentValue.reduce((x, y) => x.or(y), ODataFilter.empty())), ODataFilter.empty());
    const strFilters = fullFilters.toString();
    let odataQuery = {};
    if (strFilters) {
      odataQuery = assoc('$filter', strFilters, odataQuery);
    }
    try {
      const url = environment.REACT_APP_API_BASE + "/odata/ContractsCalendar";
      const result = await o(url, { headers: await withAuthHeader(), fragment: null }).get().query(odataQuery);
      const data = result.value;
      return new List<NetworkODataContractCalendarEventDTO>(data).map(ContractCalendarEvent.FromODataBackend);
    } catch (e) {
      throw ({ response: { data: await e.json(), status: e.status } });
    }
  }

  static async fetchSyncStatus(): Promise<any> {
    const path = `/api/ContractsCalendar/IsCalendarSync`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async fetchSpendCategories(): Promise<CodeNameDTO[]> {
    const path = `/api/ContractsCalendar/GetSpendCategories`;
    const result: any = await AxiosWrapper.get(path);
    return result;
  }

  static async fetchSpendCategoriesByBuyer(buyerId: number): Promise<CodeNameDTO[]> {
    const path = `/api/ContractsCalendar/GetSpendCategories`;
    const result: any = await AxiosWrapper.get(path, { params: buyerId ? { buyerId: buyerId } : null });
    return result;
  }

  static async fetchClustersByBuyer(buyerId: number): Promise<CodeNameDTO[]> {
    const path = `/api/ContractsCalendar/GetClustersById`;
    const result: any = await AxiosWrapper.get(path, { params: buyerId ? { buyerId: buyerId } : null });
    return result;
  }

  static async fetchClustersBySpendCatCode(spendCatCode?: string): Promise<CodeNameDTO[]> {
    const path = `/api/ContractsCalendar/GetClustersBySpendCat`;
    const result: any = await AxiosWrapper.get(path, { params: (spendCatCode && spendCatCode !== CALENDAR_ALL_OPTION.code) ? { spendCatCode: spendCatCode } : null });
    return result.data;
  }

  static async fetchClustersByBuyerAndSpendCat(buyerId: number, spendCatCode: string): Promise<CodeNameDTO[]> {
    const path = `/api/ContractsCalendar/GetClustersByBuyerAndSpendCat`;
    const result: any = await AxiosWrapper.get(path, { params: { buyerId: buyerId, spendCatCode: spendCatCode }});
    return result.data;
  }

  static async fetchMarketsByZone(zoneCode?: string): Promise<string[]> {
    const path = `/api/ContractsCalendar/GetMarkets`;
    const result: any = await AxiosWrapper.get(path, { params: (zoneCode && zoneCode !== CALENDAR_ALL_OPTION.code) ? { zoneCode: zoneCode } : null });
    return result.data.map(MarketDTO.FromBackend);
  }

  static async fetchCompanyCodesByMarket(market?: string): Promise<CodeNameDTO[]> {
    const path = `/api/ContractsCalendar/GetCompanyCodes`;
    const result: any = await AxiosWrapper.get(path, { params: (market && market !== CALENDAR_ALL_OPTION.code) ? { market: market } : null });
    return result.data;
  }

  static async fetchPlantsByCompanyCode(companyCode?: string): Promise<CodeNameDTO[]> {
    const path = `/api/ContractsCalendar/GetPlants`;
    const result: any = await AxiosWrapper.get(path, { params: companyCode ? { companyCode: companyCode } : null });
    return result.data;
  }

  static async save(event: ContractCalendarEvent): Promise<any> {
    const path = `/api/ContractsCalendar/CreateEvent`;
    const bodyToUpdate = CalendarService.updateBody(event);
    const result: any = await AxiosWrapper.post(path, bodyToUpdate);
    return ContractCalendarEvent.FromBackend(result.data);
  }

  static async update(event: ContractCalendarEvent): Promise<any> {
    const path = `/api/ContractsCalendar/EditEvent`;
    const bodyToUpdate = CalendarService.updateBody(event);
    const result: any = await AxiosWrapper.post(path, bodyToUpdate);
    return ContractCalendarEvent.FromBackend(result.data);
  }

  static async delete(id: number): Promise<any> {
    const path = `/api/ContractsCalendar/DeleteEvent`;
    return await AxiosWrapper.post(path, { Id: id });
  }

  static async syncEvents(isSync: boolean): Promise<any> {
    const path = `/api/ContractsCalendar/SyncEvents`;
    return await AxiosWrapper.get(path, { params: { isSync: isSync } });
  }

  static async exportEvents(filters: IContractCalendarFilters): Promise<any> {
    const bodyToUpdate = CalendarService.updateExportBody(filters);
    const response = await AxiosWrapper.post(`/api/ContractsCalendar/ContractCalendarEventDownloadAsExcel`, bodyToUpdate, {
      responseType: 'blob'
    });
    return response.data;
  }

  private static updateExportBody(filters: IContractCalendarFilters) {
    return {
      buyers: filters.buyers,
      spendCategories: filters.spendCategories,
      clusters: filters.clusters,
      zones: filters.zones,
      markets: filters.markets,
      companyCodes: filters.companyCodes,
      plants: filters.plants,
      businesses: filters.businesses,
      negotiationType: filters.negotiationType
    }
  }

  private static updateBody(event: ContractCalendarEvent): any {
    return {
      Id: event.id,
      Title: event.title,
      BusinessCode: event.businessCode,
      BusinessName: event.businessName,
      BuyerId: event.buyerId,
      CreatedByUserName: event.createdByUserName,
      From: new Date(event.startDate).toISOString(),
      To: new Date(event.endDate).toISOString(),
      Clusters: event.clusters,
      SpendCategoryLevel1Codes: event.spendCategoryLevel1s.map(v => (typeof v === "object") ? v.code : v),
      Markets: event.markets.map(m => m.name),
      Companies: event.companies,
      Plants: event.plants,
      Zones: event.zones,
      NegotiationTypeId: event.negotiationTypeId,
      PeriodFrom: event.periodFrom ? new Date(event.periodFrom).toISOString() : null,
      PeriodTo: event.periodTo ? new Date(event.periodTo).toISOString() : null
    }
  }
  static async fetchBusinessDays(startDate: string): Promise<any> {
    const path = `/api/ContractsCalendar/GetValidEventDates`;
    const response = await AxiosWrapper.get(path, { params: startDate ? { startDate: startDate } : null });
    return response.data;
  }

  static async fetchPrismData(prismFilter: IPrismFilter): Promise<any> {
    const path = `/api/ContractsCalendar/GetRelatedFromPrism`;
    const response = await AxiosWrapper.post(path, prismFilter, {});
    return response.data;
  }
}

