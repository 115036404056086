import {all, fork} from 'redux-saga/effects';
import {contractCoreChatSaga} from "../reducers/contract/chat.reducer";
import contractChatSaga from "./contract/chat.saga";
import contractFilterSaga from "./filters/filter.saga";
import contractSaga from "./contract/contract.saga";
import mrpContractSaga from "./contract/mrpContract.saga";
import contractUploadDownloadSaga from "./contract/contractUploadDownload.saga";
import contractItemsSaga from "./items/items.saga";
import contractItemPricesSaga from "./itemsPrice/itemPrices.saga";

export default function*() {
  yield all([
    fork(contractCoreChatSaga),
    fork(contractChatSaga),
    fork(contractSaga),
    fork(mrpContractSaga),
    fork(contractUploadDownloadSaga),
    fork(contractFilterSaga),
    fork(contractItemsSaga),
    fork(contractItemPricesSaga),
  ])
}
