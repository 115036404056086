import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStylesForToolTip = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      backgroundColor: "#fff",
      color: '#777777',
      boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)",
      borderRadius: "5px",
    },
    hidePriceTooltip: {
      backgroundColor: "#fff",
      color: '#777777',
      boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)",
      borderRadius: "5px",
      marginLeft: "145px"
    },
    contactCardToolTip: {
      backgroundColor: "#fff",
      color: '#777777',
      boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)", borderRadius: "5px",
      marginTop: 0,
      maxWidth: "none",
      // minWidth: "465px",
      // minHeight: "140px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    customTooltipWithHt: {
      backgroundColor: "#fff",
      color: '#777777',
      boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)",
      borderRadius: "5px",
      maxWidth: "1000px",
      maxHeight: "500px",
      overflowX: "auto",
      overflowY: "auto"
    },
    customTooltipForVendorHover: {
      backgroundColor: "#fff",
      color: '#777777',
      boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)",
      borderRadius: "5px",
      maxWidth: "900px",
      maxHeight: "50vh",
      padding:0,
      margin:0
    },
    pasteButtonTooltip: {
      backgroundColor: "#fff",
      color: '#777777',
      boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)", borderRadius: "5px",
      marginTop: 0,
      maxWidth: "none",
      display: "flex",
      alignItems: "left",
      justifyContent: "center"
    }
  })
);
