import {mergeRight} from "ramda";
import {BuyerDTO, CodeNameDTO, ContractCalendarEvent, IdNameDTO, IHierachyLoading} from "../domains/calendar";
import {TenderCalendarActions, TenderCalendarActionTypes} from "../actions/calendar.actions";
import {error, loaded, loading} from "../../shared/reducers/utils";
import {TenderCalendarFilterActions, TenderCalendarFilterActionTypes} from "../actions/calendarFilter.actions";

export interface TenderCalendarState {
  loading: boolean,
  hierachyLoading: IHierachyLoading,
  error: any,
  isPreviewMode: boolean;
  isSync: boolean;
  spendCategories: CodeNameDTO[],
  clusters: CodeNameDTO[],
  zones: CodeNameDTO[],
  markets: CodeNameDTO[],
  companyCodes: CodeNameDTO[],
  plants: CodeNameDTO[],
  businesses: CodeNameDTO[],
  negotiationType: IdNameDTO[],
  buyers: BuyerDTO[];
  events: ContractCalendarEvent[]
}

export const initialTenderCalendarState: TenderCalendarState = {
  loading: false,
  error: null,
  hierachyLoading: {
    zones: false,
    markets: false,
    companyCodes: false,
    plants: false
  },
  isPreviewMode: false,
  isSync: false,
  spendCategories: [],
  clusters: [],
  zones: [],
  markets: [],
  companyCodes: [],
  plants: [],
  businesses: [],
  negotiationType: [],
  buyers: [],
  events: []
};

export function TenderCalendarReducer(state: TenderCalendarState = initialTenderCalendarState, action: TenderCalendarActions|TenderCalendarFilterActions): TenderCalendarState {
  switch (action.type) {
    case TenderCalendarActionTypes.FETCH_EVENTS_REQUEST:
    case TenderCalendarActionTypes.FETCH_SYNC_STATUS:
    case TenderCalendarActionTypes.CREATE_CONTRACT_EVENT:
    case TenderCalendarActionTypes.EDIT_CONTRACT_EVENT:
    case TenderCalendarActionTypes.DELETE_CONTRACT_EVENT:
    case TenderCalendarActionTypes.EXPORT_CALENDAR_EVENTS:
      return loading(state);
    case TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT_SUCCESS:
      return mergeRight(state, {
        buyers: action.data.buyers,
        zones: action.data.zones,
        businesses: action.data.businesses,
        negotiationType: action.data.negotiationType
      });
    case TenderCalendarActionTypes.TOGGLE_REPORT_PREVIEW:
      return mergeRight(state, {isPreviewMode: action.isPreview});
    case TenderCalendarActionTypes.EXPORT_CALENDAR_EVENTS_SUCCESS:
      return loaded(state);
    case TenderCalendarActionTypes.FETCH_SYNC_STATUS_SUCCESS:
      return loaded(mergeRight(state, {isSync: action.isSync}));
    case TenderCalendarActionTypes.FETCH_EVENTS_SUCCESS:
      return loaded(mergeRight(state, {events: action.events}));
    case TenderCalendarActionTypes.FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER_SUCCESS:
      return mergeRight(state, {clusters: action.clusters, spendCategories: action.spendCategories});
    case TenderCalendarActionTypes.FETCH_CLUSTERS_BY_SPEND_CAT_SUCCESS:
      return mergeRight(state, {clusters: action.clusters});
    case TenderCalendarActionTypes.FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT_SUCCESS:
      return mergeRight(state, {clusters: action.clusters});
    case TenderCalendarActionTypes.FETCH_MARKETS_BY_ZONE:
      return mergeRight(state, {hierachyLoading: {...state.hierachyLoading, markets: true}});
    case TenderCalendarActionTypes.FETCH_MARKETS_BY_ZONE_SUCCESS:
      return mergeRight(state, {markets: action.markets, hierachyLoading: {...state.hierachyLoading, markets: false}});
    case TenderCalendarActionTypes.FETCH_COMPANY_CODES_BY_MARKET:
      return mergeRight(state, {hierachyLoading: {...state.hierachyLoading, companyCodes: true}});
    case TenderCalendarActionTypes.FETCH_COMPANY_CODES_BY_MARKET_SUCCESS:
      return mergeRight(state, {companyCodes: action.companyCodes, hierachyLoading: {...state.hierachyLoading, companyCodes: false}});
    case TenderCalendarActionTypes.FETCH_PLANT_BY_COMPANY_CODE:
      return mergeRight(state, {hierachyLoading: {...state.hierachyLoading, plants: true}});
    case TenderCalendarActionTypes.FETCH_PLANT_BY_COMPANY_CODE_SUCCESS:
      return mergeRight(state, {plants: action.plants, hierachyLoading: {...state.hierachyLoading, plants: false}});
    case TenderCalendarActionTypes.CREATE_CONTRACT_EVENT_SUCCESS:
      return loaded(mergeRight(state, {events: [...state.events, {...action.event}] }));
    case TenderCalendarActionTypes.EDIT_CONTRACT_EVENT_SUCCESS:
      return loaded(mergeRight(state, { events: state.events.map(e => action.event.id === e.id ? { ...e, ...action.event } : e) }));
    case TenderCalendarActionTypes.DELETE_CONTRACT_EVENT_SUCCESS:
      return loaded(mergeRight(state, { events: state.events.filter(e => e.id !== action.deletedId) }));
    case TenderCalendarActionTypes.FETCH_SYNC_STATUS_FAILURE:
    case TenderCalendarActionTypes.CREATE_CONTRACT_EVENT_FAILURE:
    case TenderCalendarActionTypes.EDIT_CONTRACT_EVENT_FAILURE:
    case TenderCalendarActionTypes.DELETE_CONTRACT_EVENT_FAILURE:
    case TenderCalendarActionTypes.FETCH_EVENTS_FAILURE:
    case TenderCalendarActionTypes.EXPORT_CALENDAR_EVENTS_FAILURE:
      return error(action.error)(state);
    default:
      return state;
  }
}
