import * as React from "react";
import {is, prop} from "ramda";
import {template} from 'lodash';
import Notification, {NotificationType} from "../notification/index";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {StyledErrorDialog} from "./error.styles";
import {StyledDialogActions, StyledDialogContent, StyledDialogTitle} from "../layout/styles";
import {SharedStateStore} from "../../reducers";
import {MalformedJsonException} from "../../domains/core/exceptions";

interface ErrorBoundaryProps {
  source: string;
  ignoreSource: boolean;
  success: boolean;
  error: any;
  message: string;
}

function mapStateToProps({shared}: {shared: SharedStateStore}): ErrorBoundaryProps {
  return {
    success: shared.notifications.success,
    error: shared.notifications.error,
    message: shared.notifications.message,
    ignoreSource: shared.notifications.ignoreSource,
    source: shared.notifications.source
  }
}

interface SessisonExpiredDialogProps {
  open: boolean;
  onClose: (value: string) => void;
}

function SessisonExpiredDialog(props: SessisonExpiredDialogProps) {
  const {onClose, ...other} = props;

  function handleClose() {
    onClose("close");
    window.location.reload();
  }

  return (
    <StyledErrorDialog maxWidth={"xs"} fullWidth={true} {...other}>
      <StyledDialogTitle>Session timeout</StyledDialogTitle>
      <StyledDialogContent>For security reasons and protection of your personal data, your session timed out due to inactivity.</StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleClose} color="primary">Refresh</Button>
      </StyledDialogActions>
    </StyledErrorDialog>
  )
}

class ErrorComponent extends React.Component<ErrorBoundaryProps, any> {
  constructor(props) {
    super(props)

    this.state = {
      open: true
    }
  }

  render() {
    const {message, success, error, source, ignoreSource} = this.props;
    const {open} = this.state;
    const getErrorMessage = (error) => {
      const errorTemplate = ignoreSource ? template("<%=message%>") : template("<%=source%>: <%=message%>");
      if (error instanceof MalformedJsonException) {
        return errorTemplate({source: source, message: 'The response is not a valid format.'});
      } else {
        let message: any;
        if (is(String, error)) {
          message = error;
        } else {
          message = prop('message', error);
        }
        if (message) {
          if (typeof message === 'string') {
            return errorTemplate({source: source, message: message});
          } else if (message.value) {
            return errorTemplate({source: source, message: message.value});
          } else {
            return errorTemplate({source: source, message: JSON.stringify(message)});
          }
        }
      }
      return errorTemplate({source: source, message: 'An unexpected error occurred.'});
    }

    return (
      <>
        {error && error.response && error.response.status === 401 && <SessisonExpiredDialog open={open} onClose={()=>this.setState({open: false})}/>}
        {success && <Notification type={NotificationType.success} message={message}/>}
        {error && <Notification type={NotificationType.error} message={message || getErrorMessage(error)}/>}
        {this.props.children}
      </>
    )
  }
}

export const ErrorBoundary = connect(mapStateToProps, null)(ErrorComponent);
