import * as React from 'react';
import {
  ChatButton,
  StyledBadge,
  StyledDefaultButton,
  StyledEditColumnsButton,
  StyledFilterButton,
  StyledPrimaryButton,
  StyledSecondaryButton
} from "./button.styles";
import FilterList from "@material-ui/icons/FilterList";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { Notes } from 'grommet-icons';
import ChatIcon from '@material-ui/icons/Chat';

interface FilterButtonProps {
  onClick: any;
  disabled?: boolean;
}

interface EditColumnsButtonProps {
  onClick: any;
}

interface ChatBadgeButtonProps {
  count: number;
  total?: number;
  onClick: any;
}

interface ButtonProps {
  onClick?: any;
  buttonlabel?: any;
  buttonicon?: any;
  buttoniconalign?: 'left' | 'right';
  disabled?: boolean;
  badgecontent?: number;
  children?: any;
}

export function FilterButton(props: FilterButtonProps) {
  return <StyledFilterButton variant="contained" {...props}><FilterList />Filter</StyledFilterButton>
}

export function EditColumnsButton(props: EditColumnsButtonProps) {
  return <StyledEditColumnsButton variant="contained" onClick={props.onClick}>==&nbsp;&nbsp;Edit
    Columns</StyledEditColumnsButton>
}

export function PrimaryButton(props: ButtonProps) {
  return <StyledPrimaryButton
    variant="contained"
    buttonicon={props.buttonicon}
    {...props}
  >
    {(props.buttonicon && props.buttoniconalign == 'left') && <>
      <Icon
        style={{ marginLeft: -5 }}
      >
        {props.buttonicon}
      </Icon>
      &nbsp;&nbsp;
    </>}
    {props.buttonlabel}
    {(props.buttonicon && props.buttoniconalign == 'right') && <>
      &nbsp;&nbsp;
      <Icon
        style={{ marginLeft: -5 }}
      >
        {props.buttonicon}
      </Icon>
    </>}
    {props.children}
  </StyledPrimaryButton>
}

export function SecondaryButton(props: ButtonProps) {
  return <StyledSecondaryButton variant="contained" buttonicon={props.buttonicon} {...props}>{props.buttonicon && <>
    <Icon
      style={{ marginLeft: -5 }}>{props.buttonicon}</Icon> &nbsp;&nbsp;</>}{props.buttonlabel}</StyledSecondaryButton>
}

export function DefaultButton(props: ButtonProps) {
  return <StyledDefaultButton variant="contained" buttonicon={props.buttonicon} {...props}>
    {
      props.buttonicon &&
      props.badgecontent &&
      <>
        <StyledBadge badgeContent={props.badgecontent}>
          <Icon style={{ marginLeft: -5 }}>{props.buttonicon}</Icon>
        </StyledBadge>
        &nbsp;&nbsp;&nbsp;
      </>
      ||
      <><Icon style={{ marginLeft: -5 }}>{props.buttonicon}</Icon> &nbsp;&nbsp;</>
    }{props.buttonlabel}
  </StyledDefaultButton>
}

export function ChatBadgeButton({ count, total = 0, onClick }: ChatBadgeButtonProps) {
  return <ChatButton>
    <IconButton aria-label={`${count} unread messages`} onClick={onClick}>
      <StyledBadge badgeContent={count} max={9}>
        {/* <Icon style={{ color: `${total > 0 ? '#A4AFB7' : '#d9d9d9'}` }}>chat</Icon> */}
        <ChatIcon style={{ color: `${total > 0 ? '#A4AFB7' : '#d9d9d9'}` }}/>
      </StyledBadge>
    </IconButton>
  </ChatButton>
}

export function NotesBadgeButton({ count, total = 0, onClick }: ChatBadgeButtonProps) {
  return <ChatButton>
    <IconButton aria-label={`${count} unread messages`} onClick={onClick}>
      <StyledBadge badgeContent={count} max={9}>
        <Notes color={total > 0 ? '#A4AFB7' : '#bfbdbd'} />
      </StyledBadge>
    </IconButton>
  </ChatButton>
}
