import * as R from "ramda";
import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {QueryCBListActions} from "../reducers/cbList.reducer";
import {CbFiltersService} from "../domains/cbFilter.service";
import {SaveCBFiltersError, SaveCBFiltersSuccess} from "../../shared/actions/user.actions";
import {ICBFilters} from "../../shared/domains/user/user";
import {UserService} from "../../shared/domains/user/user.service";
import {CompetitiveBiddingData} from "../domains/competitiveBidding";
import {CB_FILTER_KEY} from "../domains/cbQuery";

function* fetch(): any {
  try {
    const result: any[] = yield call(CbFiltersService.fetchAllFilters);
    const filters = R.indexBy(R.prop('key'),
      result.map(filter => ({
        data: filter.data.map(value => ({id: value.id, title: value.label })),
        key: filter.key
      }))
    );
    const filtersAppendStatuses = R.assoc('CbStatusId', {
      key: 'CbStatusId', data: CompetitiveBiddingData.StatuesFilterable()
    }, filters);
    yield put(new QueryCBListActions.onLoadFiltersSuccess(filtersAppendStatuses));
  } catch (error) {
    yield put(new QueryCBListActions.onLoadFiltersError(error));
  }
}

function* saveFilters(action) {
  try {
    const filters: ICBFilters = action.skipMapping ? action.filters : {
      companies: R.path([CB_FILTER_KEY.COMPANY_CODE, 'selected'], action.filters),
      competitiveBiddingCreators: (<any>R.path([CB_FILTER_KEY.CREATOR_USER_EMAIL, 'selected'], action.filters)).map(c => c.id),
      suppliers: R.path([CB_FILTER_KEY.SELECTED_SUPPLIER_ID, 'selected'], action.filters),
      spendCatL1s: R.path([CB_FILTER_KEY.CATEGORY_CODE, 'selected'], action.filters),
      spendCatL2s: R.path([CB_FILTER_KEY.SUBCATEGORY_CODE, 'selected'], action.filters),
      spendCatL3s: R.path([CB_FILTER_KEY.PRODUCTCATEGORY_CODE, 'selected'], action.filters),
      statuses: R.path([CB_FILTER_KEY.STATUS_ID, 'selected'], action.filters),
      validFrom: R.path(['ValidFrom', 'selected', 'value'], action.filters),
      validTo: R.path(['ValidTo', 'selected', 'value'], action.filters),
    };
    yield call(UserService.saveCbFilters, filters);
    yield put(new SaveCBFiltersSuccess({
      ...filters,
      competitiveBiddingCreators: filters.competitiveBiddingCreators.map(el => ({id: el, title: el}))
    }));
  } catch (error) {
    yield put(new SaveCBFiltersError(error));
  }
}

function* watchGetFilters() {
  yield takeLatest(QueryCBListActions.onLoadFilters, fetch);
}

function* watchSaveFilters() {
  yield takeLatest(QueryCBListActions.onSaveFilters, saveFilters);
}

export default function* cbFilterSaga() {
  yield all([
    fork(watchGetFilters),
    fork(watchSaveFilters),
  ])
}
