interface InvoiceDTO {
    __metadata: { id: string; type: string; uri: string };
    AttaCat: string
    AttaId: string
    Catid: string
    ChDate: string
    ChName: string
    ChTime: string
    ChUser: string
    CrDate: string
    CrName: string
    CrTime: string
    CrUser: string
    Descr: string
    Filename: string
    Filesize: number
    Instid: string
    Lang: string
    TechType: string
    Typeid: string
}

export class Invoice {
    constructor(
        public AttaCat: string,
        public AttaId: string,
        public Catid: string,
        public ChDate: string,
        public ChName: string,
        public ChTime: string,
        public ChUser: string,
        public CrDate: string,
        public CrName: string,
        public CrTime: string,
        public CrUser: string,
        public Descr: string,
        public Filename: string,
        public Filesize: number,
        public Instid: string,
        public Lang: string,
        public TechType: string,
        public Typeid: string,
    ) {
    }

    static FromBackend(data: InvoiceDTO): Invoice {
        return new Invoice(
            data.AttaCat,
            data.AttaId,
            data.Catid,
            data.ChDate,
            data.ChName,
            data.ChTime,
            data.ChUser,
            data.CrDate,
            data.CrName,
            data.CrTime,
            data.CrUser,
            data.Descr,
            data.Filename,
            data.Filesize,
            data.Instid,
            data.Lang,
            data.TechType,
            data.Typeid,
        )
    }
}
