import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";

export const StyledToolbar = styled(Toolbar)`
  background-color: white;
  width: 100%;
  height: 64px;
`;

export const StyledIconButton = styled(IconButton)`
  color: #7E7E7E!important;
`;

export const StyledDialog = styled(Dialog)`
  && .MuiDialog-paperWidthXs {
    width: 250px;
  }
`;

export const ContextMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 64px;
`;

export const StyledAvatar = styled.img`
  border-radius: 32px;
  width: 32px;
  height: 32px;
`;

export const MyAccountText = styled.span`
  display: flex;
  flex-direction: column;
  color: #3C4144;
  padding-left: 10px;
  
  span {
    font-size: 11px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

