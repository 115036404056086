import { DateTime } from "luxon";
import { IAction } from "../../../../shared/domains/core/actions";
import { assoc, defaultTo, merge, filter } from "ramda";
import { BAActionTypes } from "../../actions/baFilters";
import { TextFilter } from "../../../../shared/domains/query/filter";
import { IBAFilters } from "../../../../shared/domains/user/user";
import { error } from "../../../../shared/reducers/utils";

export interface BAFiltersState {
  companies: TextFilter[];
  baFilters: IBAFilters;
  loading: boolean;
  loaded: boolean;
  error: any;
  alias: string;
}

export const initialBAFilters = {
  companies: [],
  purchaseReqNo: [],
  aribaPr: [],
  prCreatorName: [],
  supplier: [],
  purchGroup: [],
  accAssignment: [],
  glAccount: [],
  costCenter: [],
  wbsElement: [],
  validity: DateTime.local()
    .minus({ months: 3 })
    .toFormat("yyyyMMdd")
};

export const initialFiltersState: BAFiltersState = {
  companies: [],
  baFilters: initialBAFilters,
  loading: false,
  loaded: false,
  error: null,
  alias: null
};

const loading = { loading: true, loaded: false, error: null };

export function BAFiltersReducer(
  state: BAFiltersState = initialFiltersState,
  action: IAction<BAActionTypes | BAActionTypes>
): BAFiltersState {
  switch (action.type) {
    case BAActionTypes.BA_GET_FILTERS:
      return merge(state, loading);
    case BAActionTypes.BA_GET_FILTERS_FAILURE:
      return error(action.error)(state);
    case BAActionTypes.BA_GET_FILTERS_SUCCESS:
      const filtersCompanies = state.baFilters.companies.map(
        (c: TextFilter) => {
          const com = action.companies.find(
            (co: TextFilter) => c && c.id === co.id
          );
          return com ? com : c;
        }
      );
      return {
        ...state,
        companies: defaultTo(state.companies, action.companies),
        baFilters: assoc("companies", filtersCompanies, state.baFilters),
        loading: false,
        loaded: true
      };

    case BAActionTypes.BA_NEW_FILTER:
      return {
        ...state,

        baFilters: {
          companies: filter(
            (f: TextFilter) => !!f.id,
            action.filters.companies
          ),
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName: action.filters.prCreatorName,
          supplier: action.filters.supplier,
          purchGroup: action.filters.purchGroup,
          costCenter: action.filters.costCenter,
          wbsElement: action.filters.wbsElement,
          validity: action.filters.validity
        }
      };

    case BAActionTypes.INIT_BA_FILTERS:
      return {
        ...state,
        baFilters: {
          companies: action.filters.companies,
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName: action.filters.prCreatorName,
          supplier: action.filters.supplier,
          purchGroup: action.filters.purchGroup,
          costCenter: action.filters.costCenter,
          wbsElement: action.filters.wbsElement,
          validity: DateTime.local()
            .minus({ months: 3 })
            .toFormat("yyyyMMdd")
        }
      };
    case BAActionTypes.DELETE_BA_FILTER:
      return {
        ...state,
        baFilters: {
          companies: action.filters.companies,
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName: action.filters.prCreatorName,
          supplier: action.filters.supplier,
          purchGroup: action.filters.purchGroup,
          costCenter: action.filters.costCenter,
          wbsElement: action.filters.wbsElement,
          validity: DateTime.local()
            .minus({ months: 3 })
            .toFormat("yyyyMMdd")
        }
      };
    case BAActionTypes.RESET_BA_FILTERS:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case BAActionTypes.RESET_BA_FILTERS_SUCCESS:
      return {
        ...state,
        baFilters: {
          companies: action.filters.companies,
          purchaseReqNo: action.filters.purchaseReqNo,
          prCreatorName: action.filters.prCreatorName,
          supplier: action.filters.supplier,
          purchGroup: action.filters.purchGroup,
          costCenter: action.filters.costCenter,
          wbsElement: action.filters.wbsElement,
          validity: DateTime.local()
            .minus({ months: 3 })
            .toFormat("yyyyMMdd")
        },
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
}
