import {applyMiddleware, combineReducers, compose, createStore, Reducer} from 'redux';
import rootSaga from "./application.sagas";
import {actionMiddleware} from "./shared/middleware/action.middleware";
import {createLogger} from "redux-logger";
import createSagaMiddleware from '@redux-saga/core';
import {DocFlowsCombinedReducers , DocFlowsStateStore, InvoicesStateStore, InvoicesCombinedReducers } from "./docFlows/reducers";
import {SharedCombinedReducers, SharedStateStore} from "./shared/reducers";
import {ContractsCombinedReducers, ContractsStateStore} from "./contracts/reducers";
import {connectRouter, routerMiddleware, RouterState} from 'connected-react-router';
import {
  businessApprovalsCombinedReducers,
  businessApprovalsStateStore
} from "./purchaseRequisition/BusinessApprovals/reducers";
import {
  procurementApprovalsCombinedReducers,
  procurementApprovalsStateStore
} from "./purchaseRequisition/ProcurementApprovals/reducers";
import {CbCombinedReducers, CbStateStore} from "./competitiveBidding/reducers";
import {PSLCombinedReducers, PSLStateStore} from "./preferredSuppliers/reducers";
import {purchaseRequisitionReducers, purchaseRequisitionStateStore} from './purchaseRequisition/reducers';
import {
  approvalStatusCombinedReducers,
  approvalStatusStateStore
} from "./purchaseRequisition/ApprovalStatus/reducers/index";
import {
  approvalHistoryCombinedReducers,
  approvalHistoryStateStore
} from './purchaseRequisition/ApprovalHistory/reducers/index'
import {
  prSubstitutionCombinedReducers,
  prSubstitutionStateStore
} from './purchaseRequisition/PRSubstitution/reducers/index'
import {createBrowserHistory} from 'history';
import {MRPCombinedReducers, MRPStateStore} from "./mrp/reducers";
import {AdminCombinedReducers, AdminStateStore} from "./admin/reducers";
import {TenderCalendarCombinedReducers, TenderCalendarStateStore} from "./tenderCalendar/reducers";

const logger = createLogger({
  collapsed: () => true
});

const sagaMiddleware = createSagaMiddleware();

export interface RootStateStore {
  router: RouterState,
  shared: SharedStateStore,
  contracts: ContractsStateStore,
  tenderCalendar: TenderCalendarStateStore,
  docFlows: DocFlowsStateStore,
  invoices: InvoicesStateStore,
  businessApprovals: businessApprovalsStateStore,
  procurementApprovals: procurementApprovalsStateStore,
  purchaseRequisition: purchaseRequisitionStateStore,
  approvalStatus: approvalStatusStateStore,
  approvalHistory: approvalHistoryStateStore,
  prSubstitution: prSubstitutionStateStore,
  cb: CbStateStore,
  psl: PSLStateStore,
  mrp: MRPStateStore,
  admin: AdminStateStore
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25}) || compose;

const rootReducers: (history) => Reducer<RootStateStore> = history => combineReducers({
  router: connectRouter(history),
  shared: SharedCombinedReducers,
  contracts: ContractsCombinedReducers,
  tenderCalendar: TenderCalendarCombinedReducers,
  docFlows: DocFlowsCombinedReducers,
  invoices: InvoicesCombinedReducers,
  businessApprovals: businessApprovalsCombinedReducers,
  procurementApprovals: procurementApprovalsCombinedReducers,
  purchaseRequisition: purchaseRequisitionReducers,
  approvalStatus: approvalStatusCombinedReducers,
  approvalHistory: approvalHistoryCombinedReducers,
  prSubstitution: prSubstitutionCombinedReducers,
  cb: CbCombinedReducers,
  psl: PSLCombinedReducers,
  mrp: MRPCombinedReducers,
  admin: AdminCombinedReducers
});

export const appHistory = createBrowserHistory();

export const store = createStore(
  rootReducers(appHistory),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(appHistory),
      logger,
      actionMiddleware,
      sagaMiddleware,
    )
  )
);
// @ts-ignore
window.store = store;
sagaMiddleware.run(rootSaga);
