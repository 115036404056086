import {isEmpty, defaultTo} from "ramda";
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'
import {
  ClmItemChange,
  DeleteDraftItem,
  DeleteDraftItemError,
  DeleteDraftItemSuccess,
  FetchDeletedBlockedDraftItems,
  FetchDeletedBlockedItems,
  FetchDeletedBlockedItemsError,
  FetchDeletedBlockedItemsSuccess,
  FetchDraftItems,
  FetchDraftItemsError,
  FetchDraftItemsSuccess,
  FetchItems,
  FetchItemsError,
  FetchItemsSuccess,
  FetchMrpItems,
  FetchMrpItemsError,
  FetchMrpItemsSuccess,
  FetchOriginalMrpItems,
  FetchOriginalMrpItemsError,
  FetchOriginalMrpItemsSuccess,
  ItemActionTypes,
  ToggleItemStatus,
  ToggleItemStatusError,
  ToggleItemStatusSuccess,
  UpdateDraftItem,
  UpdateDraftItemError,
  UpdateDraftItemSuccess,
  UpdateItem,
  UpdateItemError,
  UpdateItemSuccess,
  UpdateMrpItem,
  UpdateMrpItemError,
  UpdateMrpItemSuccess
} from "../../actions/item.actions";
import {ItemService} from "../../domains/item/item.service";
import {DeletedBlockedItem} from "../../domains/item/item";
import {RootStateStore} from "../../../application.reducers";
import {changeTargetValue, Contract, ContractType, DraftContractSource} from "../../domains/contract/contract";
import {ClmContractChange, UpdateNewStateContracts} from "../../actions/contract.actions";
import {SharedStateStore} from "../../../shared/reducers";
import {ContractsStateStore} from "../../reducers";

function* fetchItems(action: FetchItems) {
  try {
    const result = yield call(ItemService.fetchItems, action.contractNumber, action.systemAlias, action.page, action.term, action.actions);
    const {items} = yield select((state: RootStateStore) => state.contracts.clmContract.changes);
    yield put(new FetchItemsSuccess(result.data, result.count, items));
  } catch (error) {
    yield put(new FetchItemsError(error));
  }
}

function* fetchDraftItems(action: FetchDraftItems) {
  try {
    const result = yield call(ItemService.fetchDraftItems, action.agreementId, action.refAgreementNo, action.systemAlias, action.timestamp, action.page);
    yield put(new FetchDraftItemsSuccess(result.data, result.count));
  } catch (error) {
    yield put(new FetchDraftItemsError(error));
  }
}

function* fetchMrpItems(action: FetchMrpItems) {
  try {
    const result = yield call(ItemService.fetchMrpItems, action.agreementId, action.systemAlias, action.page);
    yield put(new FetchMrpItemsSuccess(result.data, result.count));
    const {contractType, draftContractSource} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts);
    if (contractType === ContractType.DRAFT && draftContractSource === DraftContractSource.CHANGES_REQUESTS) {
      yield put(new FetchOriginalMrpItems(action.agreementId, action.systemAlias, action.page));
    }
  } catch (error) {
    yield put(new FetchMrpItemsError(error));
  }
}

function* fetchOriginalMrpItems(action: FetchOriginalMrpItems) {
  try {
    const result = yield call(ItemService.fetchItems, action.agreementId, action.systemAlias, action.page);
    yield put(new FetchOriginalMrpItemsSuccess(result.data));
  } catch (error) {
    yield put(new FetchOriginalMrpItemsError(error));
  }
}

function* updateItem(action: UpdateItem) {
  try {
    yield call(ItemService.update, action.item);
    yield put(new UpdateItemSuccess(action.executor, action.item));
  } catch (error) {
    yield put(new UpdateItemError(action.executor, error));
  }
}

function* updateDraftItem(action: UpdateDraftItem) {
  try {
    yield call(ItemService.updateDraft, action.item);
    yield put(new UpdateDraftItemSuccess(action.executor, action.item));
  } catch (error) {
    yield put(new UpdateDraftItemError(action.executor, error));
  }
}

function* updateMrpItem(action: UpdateMrpItem) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    yield call(ItemService.updateMrp, action.item, user);
    yield put(new UpdateMrpItemSuccess(action.executor, action.item));
  } catch (error) {
    yield put(new UpdateMrpItemError(action.executor, error));
  }
}

function* fetchDeletedBlockedItems(action: FetchDeletedBlockedItems) {
  try {
    const result = yield call(ItemService.fetchDeletedBlockedCounter, action.contractNumber, action.systemAlias);
    let itemCounters = new DeletedBlockedItem();
    if (result.data.length) {
      itemCounters = result.data[0];
    }
    const {contracts, selectedContract} = yield select(({contracts}: RootStateStore) => contracts.contracts);
    const updatedContracts = contracts.map((contract: Contract): Contract =>
      (contract.AgreementNo === action.contractNumber) ? changeTargetValue(contract, itemCounters.TargetValue) : contract
    );
    yield put(new FetchDeletedBlockedItemsSuccess(itemCounters, action.contractNumber));
    yield put(new UpdateNewStateContracts(updatedContracts, changeTargetValue(selectedContract, itemCounters.TargetValue)));
  } catch (error) {
    yield put(new FetchDeletedBlockedItemsError(error));
  }
}

function* fetchDeletedBlockedDraftItems(action: FetchDeletedBlockedDraftItems) {
  try {
    const result = yield call(ItemService.fetchDeletedBlockedDraftItems, action.contractNumber, action.refAgreementNo, action.systemAlias, action.timestamp);
    let itemCounters = new DeletedBlockedItem();
    if (result.data.length) {
      itemCounters = result.data[0];
    }
    const {contracts, selectedContract} = yield select(({contracts}: RootStateStore) => contracts.contracts);
    const updatedContracts = contracts.map((contract: Contract): Contract =>
      (contract.RefAgreementNo === action.refAgreementNo && contract.TimeStamp === action.timestamp) ?
        changeTargetValue(contract, itemCounters.TargetValue) :
        contract
    );
    yield put(new UpdateNewStateContracts(updatedContracts, changeTargetValue(selectedContract, itemCounters.TargetValue)));
  } catch (error) {
    yield put(new FetchDeletedBlockedItemsError(error));
  }
}

function* toggleItemStatus(action: ToggleItemStatus) {
  try {
    const oldAction = action.item.Action;
    const newItem = {...action.item, Action: action.status};
    yield call(ItemService.update, newItem);
    yield put(new ToggleItemStatusSuccess(newItem, action.status, oldAction));
  } catch (error) {
    yield put(new ToggleItemStatusError(error));
  }
}

function* deleteDraftItem(action: DeleteDraftItem) {
  try {
    yield call(ItemService.deleteDraft, action.item);
    yield put(new DeleteDraftItemSuccess(action.item));
  } catch (error) {
    yield put(new DeleteDraftItemError(error));
  }
}

function* clmItemChange(action: ClmItemChange) {
  const {selectedContract} = yield select(({contracts}: RootStateStore) => contracts.contracts);
  const {contract} = yield select(({contracts}: RootStateStore) => contracts.clmContract.changes);
  yield put(new ClmContractChange(defaultTo(selectedContract, contract)));
}

function* watchFetchItems() {
  yield takeLatest(ItemActionTypes.FETCH_ITEMS, fetchItems);
}

function* watchFetchDraftItems() {
  yield takeLatest(ItemActionTypes.FETCH_DRAFT_ITEMS, fetchDraftItems);
}

function* watchFetchMrpItems() {
  yield takeLatest(ItemActionTypes.FETCH_MRP_ITEMS, fetchMrpItems);
}

function* watchFetchOriginalMrpItems() {
  yield takeLatest(ItemActionTypes.FETCH_ORIGINAL_MRP_ITEMS, fetchOriginalMrpItems);
}

function* watchUpdateItem() {
  yield takeLatest(ItemActionTypes.UPDATE_ITEM, updateItem);
}

function* watchUpdateDraftItem() {
  yield takeLatest(ItemActionTypes.UPDATE_DRAFTITEM, updateDraftItem);
}

function* watchUpdateMrpItem() {
  yield takeLatest(ItemActionTypes.UPDATE_MRP_ITEM, updateMrpItem);
}

function* watchFetchDeletedBlockedItems() {
  yield takeLatest(ItemActionTypes.FETCH_DELETED_BLOCKED_ITEMS, fetchDeletedBlockedItems);
}

function* watchFetchDeletedBlockedDraftItems() {
  yield takeLatest(ItemActionTypes.FETCH_DELETED_BLOCKED_DRAFT_ITEMS, fetchDeletedBlockedDraftItems);
}

function* watchToggleItemStatus() {
  yield takeLatest(ItemActionTypes.TOGGLE_ITEMSTATUS, toggleItemStatus);
}

function* watchDeleteDraftItem() {
  yield takeLatest(ItemActionTypes.DELETE_DRAFT_ITEM, deleteDraftItem);
}

function* watchClmItemChange() {
  yield takeLatest(ItemActionTypes.CLM_ITEM_CHANGE, clmItemChange);
}

export default function* contractItemsSaga() {
  yield all([
    fork(watchFetchItems),
    fork(watchFetchDraftItems),
    fork(watchFetchMrpItems),
    fork(watchFetchOriginalMrpItems),
    fork(watchUpdateItem),
    fork(watchUpdateDraftItem),
    fork(watchUpdateMrpItem),
    fork(watchFetchDeletedBlockedItems),
    fork(watchFetchDeletedBlockedDraftItems),
    fork(watchToggleItemStatus),
    fork(watchDeleteDraftItem),
    fork(watchClmItemChange)
  ])
};
