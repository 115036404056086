import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {
    GetPersonalNoteRequest,
    GetPersonalNoteRequestError,
    GetPersonalNoteRequestSuccess,
    PersonalNoteActions,
    SavePersonalNoteRequest,
    SavePersonalNoteRequestError,
    SavePersonalNoteRequestSuccess
} from "../actions/docFlowPersonalNote.actions";
import {DocflowNotesService} from "../domains/docFlowNotes/docFlowNotes.service";


function* getDocFlowNotes(action: GetPersonalNoteRequest): any {
    const {prNo, poNo, systemAlias} = action
    try {
        const result = yield call(DocflowNotesService.getDocFlowNotes, action.prNo, action.poNo);
        yield put(new GetPersonalNoteRequestSuccess(prNo, poNo, systemAlias, result));
    } catch (error) {
        yield put(new GetPersonalNoteRequestError(prNo, poNo, systemAlias));
    }
}

function* sendNote(action: SavePersonalNoteRequest): any {
    const {prNo, poNo, systemAlias} = action
    try {
        const result = yield call(DocflowNotesService.sendNote, action.prNo, action.poNo, action.note);
        yield put(new SavePersonalNoteRequestSuccess(prNo, poNo, systemAlias, result.note));
    } catch (error) {
        yield put(new SavePersonalNoteRequestError(prNo, poNo, systemAlias));
    }
}

function* watchDocumentFlowGetPersonalNote() {
    yield takeEvery(PersonalNoteActions.GET_PERSONAL_NOTE_REQUEST, getDocFlowNotes);
}

function* watchDocumentFlowSavePersonalNote() {
    yield takeEvery(PersonalNoteActions.SAVE_PERSONAL_NOTE_REQUEST, sendNote);
}

export default function* documentFlowPersonalNoteSaga() {
    yield all([
        fork(watchDocumentFlowGetPersonalNote),
        fork(watchDocumentFlowSavePersonalNote)
    ])
};
