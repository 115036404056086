import * as React from "react";
import {useState} from "react";
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import MoreVert from "@material-ui/icons/MoreVert";
import Fade from '@material-ui/core/Fade/Fade';

const styles = (theme: Theme) =>
    createStyles({
        root: {},
        more: {
            fontSize: '19px',
        },
        listItemIcon: {
            minWidth: '40px'
        },
        menuList:{
            paddingTop: "2px",
            paddingBottom: "2px"
        },
        menuLabel:{
            fontSize: '15px',
        }
    });

interface Item {
    label: string;
    onClick?: (event: any) => void;
}

interface Props {
    classes?: any;
    items: Item[];
    children?: any
}

function IconMenu(props: Props) {
    const {classes, items, children} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (e: React.MouseEvent, item: Item) => {
        if (item.onClick) item.onClick(e);
        setAnchorEl(null)
    };
    const visibleItems = items.filter(item => !(item.hasOwnProperty('visible')) || item['visible']);
    return (
        visibleItems.length ? <>
                {!!children ?
                    <div onClick={(event: any) => setAnchorEl(event.currentTarget)}
                         style={{width: 'fit-content'}}>
                        {children}
                    </div> :
                    <IconButton onClick={(event: any) => setAnchorEl(event.currentTarget)}>
                        <MoreVert className={classes.more} color={"action"}/>
                    </IconButton>
                }
                <Menu
                    id="icon-button-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                    style={{marginTop: '10px'}}
                >
                    <MenuList className={classes.menuList}>
                        {visibleItems.map((item: Item, index: number) => (
                            <MenuItem  key={index} onClick={(e) => handleClick(e, item)}>
                                <Typography className={classes.menuLabel} variant="inherit">{item.label}</Typography>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </>
            : null
    )
}

export const MoreOptionsOnGivenHook = withStyles(styles)(IconMenu);
