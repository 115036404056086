import {Item, ItemStatus} from "../domains/item/item";
import {ContractNumber} from "../domains/contract/contract";
import {SuccessNotificationAction} from "../../shared/domains/core/notifications";
import {ErrorAction, IPromiseExecutor, PendingAction, SuccessAction} from "../../shared/domains/core/actions";

export enum ItemActionTypes {
  INIT = "INITIAL_ITEMS",

  FETCH_ITEMS = "FETCH_ITEMS",
  FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS",
  FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE",

  FETCH_DRAFT_ITEMS = "FETCH_DRAFT_ITEMS",
  FETCH_DRAFT_ITEMS_SUCCESS = "FETCH_DRAFT_ITEMS_SUCCESS",
  FETCH_DRAFT_ITEMS_FAILURE = "FETCH_DRAFT_ITEMS_FAILURE",

  FETCH_MRP_ITEMS = "FETCH_MRP_ITEMS",
  FETCH_MRP_ITEMS_SUCCESS = "FETCH_MRP_ITEMS_SUCCESS",
  FETCH_MRP_ITEMS_FAILURE = "FETCH_MRP_ITEMS_FAILURE",

  FETCH_ORIGINAL_MRP_ITEMS = "FETCH_ORIGINAL_MRP_ITEMS",
  FETCH_ORIGINAL_MRP_ITEMS_SUCCESS = "FETCH_ORIGINAL_MRP_ITEMS_SUCCESS",
  FETCH_ORIGINAL_MRP_ITEMS_FAILURE = "FETCH_ORIGINAL_MRP_ITEMS_FAILURE",

  FETCH_DELETED_BLOCKED_ITEMS = "FETCH_DELETED_BLOCKED_ITEMS",
  FETCH_DELETED_BLOCKED_ITEMS_SUCCESS = "FETCH_DELETED_BLOCKED_ITEMS_SUCCESS",
  FETCH_DELETED_BLOCKED_ITEMS_FAILURE = "FETCH_DELETED_BLOCKED_ITEMS_FAILURE",

  FETCH_DELETED_BLOCKED_DRAFT_ITEMS = "FETCH_DELETED_BLOCKED_DRAFT_ITEMS",
  FETCH_DELETED_BLOCKED_DRAFT_ITEMS_SUCCESS = "FETCH_DELETED_BLOCKED_DRAFT_ITEMS_SUCCESS",
  FETCH_DELETED_BLOCKED_DRAFT_ITEMS_FAILURE = "FETCH_DELETED_BLOCKED_DRAFT_ITEMS_FAILURE",

  UPDATE_ITEM = "UPDATE_ITEM",
  UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS",
  UPDATE_ITEM_FAILURE = "UPDATE_ITEM_FAILURE",

  UPDATE_DRAFTITEM = "UPDATE_DRAFTITEM",
  UPDATE_DRAFTITEM_SUCCESS = "UPDATE_DRAFTITEM_SUCCESS",
  UPDATE_DRAFTITEM_FAILURE = "UPDATE_DRAFTITEM_FAILURE",

  UPDATE_MRP_ITEM = "UPDATE_MRP_ITEM",
  UPDATE_MRP_ITEM_SUCCESS = "UPDATE_MRP_ITEM_SUCCESS",
  UPDATE_MRP_ITEM_FAILURE = "UPDATE_MRP_ITEM_FAILURE",

  TOGGLE_ITEMSTATUS = "TOGGLE_ITEMSTATUS",
  TOGGLE_CLM_ITEMSTATUS = "TOGGLE_CLM_ITEMSTATUS",
  TOGGLE_ITEMSTATUS_SUCCESS = "TOGGLE_ITEMSTATUS_SUCCESS",
  TOGGLE_ITEMSTATUS_FAILURE = "TOGGLE_ITEMSTATUS_FAILURE",

  DELETE_DRAFT_ITEM = "DELETE_DRAFT_ITEM",
  DELETE_DRAFT_ITEM_SUCCESS = "DELETE_DRAFT_ITEM_SUCCESS",
  DELETE_DRAFT_ITEM_FAILURE = "DELETE_DRAFT_ITEM_FAILURE",

  CLM_ITEM_CHANGE = "CLM_ITEM_CHANGE"
}

export class InitItems extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.INIT;
}

export class FetchItems extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_ITEMS;

  constructor(public contractNumber: ContractNumber, public systemAlias: string, public page: number = 0, public term: string = "", public actions: string[] = []) {
    super();
  }
}

export class FetchItemsSuccess extends SuccessAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_ITEMS_SUCCESS;

  constructor(public items, public totalCount, public changes = {}) {
    super();
  }
}

export class FetchItemsError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_ITEMS_FAILURE;
}

export class FetchDraftItems extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_DRAFT_ITEMS;

  constructor(
    public agreementId: string,
    public refAgreementNo: ContractNumber,
    public systemAlias: string,
    public timestamp: string,
    public page: number = 0
  ) {
    super();
  }
}

export class FetchDraftItemsSuccess extends SuccessAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_DRAFT_ITEMS_SUCCESS;

  constructor(public items, public totalCount) {
    super();
  }
}

export class FetchDraftItemsError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_DRAFT_ITEMS_FAILURE;
}

export class FetchDeletedBlockedItems extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_DELETED_BLOCKED_ITEMS;

  constructor(public contractNumber: ContractNumber, public systemAlias: string,) {
    super();
  }
}

export class FetchDeletedBlockedItemsSuccess extends SuccessAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_DELETED_BLOCKED_ITEMS_SUCCESS;

  constructor(public itemCounters, public AgreementNo) {
    super();
  }
}

export class FetchDeletedBlockedItemsError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_DELETED_BLOCKED_ITEMS_FAILURE;
}

export class FetchDeletedBlockedDraftItems extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_DELETED_BLOCKED_DRAFT_ITEMS;

  constructor(public contractNumber: ContractNumber, public refAgreementNo: ContractNumber, public systemAlias: string, public timestamp: string) {
    super();
  }
}

export class UpdateItem extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_ITEM;

  constructor(public executor: IPromiseExecutor, public item: Item) {
    super();
  }
}

export class UpdateItemSuccess extends SuccessNotificationAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_ITEM_SUCCESS;

  constructor(public executor: IPromiseExecutor, public item, public newTargetValue?) {
    super();

    this.executor.resolve();
  }
}

export class UpdateItemError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_ITEM_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class UpdateDraftItem extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_DRAFTITEM;

  constructor(public executor: IPromiseExecutor, public item: Item) {
    super();
  }
}

export class UpdateDraftItemSuccess extends SuccessNotificationAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_DRAFTITEM_SUCCESS;

  constructor(public executor: IPromiseExecutor, public item) {
    super();

    this.executor.resolve();
  }
}

export class UpdateDraftItemError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_DRAFTITEM_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class ToggleItemStatus extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.TOGGLE_ITEMSTATUS;

  constructor(public item: Item, public status: ItemStatus) {
    super();
  }
}

export class ToggleClmItemStatus extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.TOGGLE_CLM_ITEMSTATUS;

  constructor(public item: Item, public action) {
    super();
  }
}

export class ToggleItemStatusSuccess extends SuccessAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.TOGGLE_ITEMSTATUS_SUCCESS;

  constructor(public item: Item, public action, public lastAction) {
    super();
  }
}

export class ToggleItemStatusError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.TOGGLE_ITEMSTATUS_FAILURE;
}

export class DeleteDraftItem extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.DELETE_DRAFT_ITEM;

  constructor(public item: Item) {
    super();
  }
}

export class DeleteDraftItemSuccess extends SuccessNotificationAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.DELETE_DRAFT_ITEM_SUCCESS;

  constructor(public item: Item) {
    super();
  }
}

export class DeleteDraftItemError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.DELETE_DRAFT_ITEM_FAILURE;
}

export class FetchMrpItems extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_MRP_ITEMS;

  constructor(public agreementId: string, public systemAlias: string, public page: number = 0) {
    super();
  }
}

export class FetchMrpItemsSuccess extends SuccessAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_MRP_ITEMS_SUCCESS;

  constructor(public items, public totalCount) {
    super();
  }
}

export class FetchMrpItemsError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_MRP_ITEMS_FAILURE;
}

export class FetchOriginalMrpItems extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_ORIGINAL_MRP_ITEMS;

  constructor(public agreementId: string, public systemAlias: string, public page: number = 0) {
    super();
  }
}

export class FetchOriginalMrpItemsSuccess extends SuccessAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_ORIGINAL_MRP_ITEMS_SUCCESS;

  constructor(public items) {
    super();
  }
}

export class FetchOriginalMrpItemsError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.FETCH_ORIGINAL_MRP_ITEMS_FAILURE;
}

export class UpdateMrpItem extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_MRP_ITEM;

  constructor(public executor: IPromiseExecutor, public item: Item) {
    super();
  }
}

export class UpdateMrpItemSuccess extends SuccessNotificationAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_MRP_ITEM_SUCCESS;

  constructor(public executor: IPromiseExecutor, public item) {
    super();

    this.executor.resolve();
  }
}

export class UpdateMrpItemError extends ErrorAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.UPDATE_MRP_ITEM_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class ClmItemChange extends PendingAction<ItemActionTypes> {
  public readonly type = ItemActionTypes.CLM_ITEM_CHANGE;

  constructor(public item) {
    super();
  }
}

