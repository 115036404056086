import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createStyles, makeStyles, MuiThemeProvider, Theme, Tooltip} from "@material-ui/core";
import { isEmpty } from 'ramda';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {CompanyData} from "../../../../domains/master/company";
import {StyledDialogActions} from "../../../../components/layout/styles";
import {
  CustomFastInput,
  LargeTypeaheadAsyncSelectWrapper,
  SelectFieldWrapper,
  TypeaheadAsyncSelectWrapper
} from "../../../../components/form";
import {MasterService} from "../../../../domains/master/master.service";
import {FetchPreferredCompanies, FetchTenderCalendarInfo,} from "../../../../actions/user.actions";
import {PopupLoading} from "../../../../components/loading";
import {RootStateStore} from "../../../../../application.reducers";
import {Plant} from "../../../../domains/master/plant";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import {
  SettingsTheme,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledUserSettingsDialogContent
} from "./userSettings.styles";
import {UI_MODULE} from "../../../../domains/core/ui";
import {IUserRole, MrpUser, User} from "../../../../domains/user/user";
import {UserScopeComponent} from "../../../../components/userScopeSelector";
import {UserCategoryComponent} from "../../../../components/userScopeSelector/userCategorySelector";
import {withContext} from "../../../../services/context.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: 15
    },
    label: {
      fontSize: 14,
      fontStyle: 'italic'
    },
    selector: {
      paddingTop: 5,
      marginBottom: 15
    },
    expansion: {
      marginBottom: 15
    }
  })
);

const UserSettings = ({
                        user, onClose,
                        selectedBuyers, setBuyers,
                        selectedClusters, setClusters,
                        selectedZones, setZones,
                        selectedMarkets, setMarkets,
                        selectedCategories, setCategories,
                        selectedCompanyCodes, setCompanyCodes,
                        selectedPlants, setPlants,
                        selectedBusinesses, setBusinesses,
                        prpoSettings, setPrpoSettings
                     }) => {
  const dispatch = useDispatch();
  const {label, selector, heading, expansion} = useStyles({});
  const module: UI_MODULE = useSelector((state: RootStateStore) => state.shared.ui.module);

  const {
    currentUser,
    tenderCalendarBuyers,
    tenderCalendarClusters,
    tenderCalendarZones,
    tenderCalendarMarkets,
    tenderCalendarCategories,
    tenderCalendarCompanyCodes,
    tenderCalendarBusinesses
  } = user;

  const isViewOnly = currentUser.isViewOnly();

  useEffect(() => {
    dispatch(new FetchTenderCalendarInfo());
  }, []);

  useEffect(() => {
    if (!user.preferredCompanyLoaded) {
      dispatch(new FetchPreferredCompanies());
    }
  }, [user.preferredCompanyLoaded]);

  const findRole = (options, role) => {
    return options.find(el=>el.value === role);
  };
  return (
    <MuiThemeProvider theme={SettingsTheme}>
      {
        user.preferredCompanyLoading ? <PopupLoading text={'Loading...'}/> :
          <StyledUserSettingsDialogContent dividers={true}>
            <Grid container>
              <Grid item sm={12} className={label}>Select a company:</Grid>
                <Tooltip title={"Select the company code(s) you belong to. This way, the sourcing section and Request cockpit of the WebApp will show you documents (PR/PO/CB forms) that are relevant for you"}>
                <Grid item sm={12} className={selector}>
                <TypeaheadAsyncSelectWrapper name="companyCode"
                                             labelName="companyName"
                                             options={user.companies}
                                             customDisplay={option => `${option.value} - ${option.label}`}
                                             clearable={true}
                                             menuPosition="fixed"
                                             menuPortalTarget={document.body}
                                             onLoad={x => {
                                              if (module === UI_MODULE.APPROVAL) {
                                                return MasterService.fetchCompanyCodeBySystemAlias(x, !isEmpty(currentUser.defaultSystemAlias) ? currentUser.defaultSystemAlias[0] : '').then((x: CompanyData[]) => x.map(CompanyData.toSelect))
                                              }
                                              return MasterService.fetchCompanyCodeFromAzure(x).then((x: CompanyData[]) => x.map(CompanyData.toSelect))
                                             }}/>
              </Grid>
                </Tooltip>
              {
                module === UI_MODULE.TENDER_CALENDAR && <Grid item sm={12} className={expansion}>
                  <ExpansionPanel>
                    <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={heading}>Tender Calendar</Typography>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <Grid item sm={12} className={label}>Select Buyers:</Grid>
                      <Grid item sm={12} className={selector}>
                        <SelectFieldWrapper          options={tenderCalendarBuyers.map(el => ({label: el.name, value: el.code}))}
                                                     customDisplay={option => `${option.value} - ${option.label}`}
                                                     clearable={true}
                                                     isMulti={true}
                                                     returnFullOnChange={true}
                                                     returnFullSelected={true}
                                                     selected={selectedBuyers}
                                                     menuPosition="fixed"
                                                     menuPortalTarget={document.body}
                                                     onChange={setBuyers}
                        />
                      </Grid>
                      <Grid item sm={12} className={label}>Select Clusters:</Grid>
                      <Grid item sm={12} className={selector}>
                        <SelectFieldWrapper          options={tenderCalendarClusters.map(el => ({label: el.name, value: el.code}))}
                                                     customDisplay={option => `${option.value} - ${option.label}`}
                                                     clearable={true}
                                                     isMulti={true}
                                                     returnFullOnChange={true}
                                                     returnFullSelected={true}
                                                     selected={selectedClusters}
                                                     menuPosition="fixed"
                                                     menuPortalTarget={document.body}
                                                     onChange={setClusters}
                        />
                      </Grid>
                      <Grid item sm={12} className={label}>Select Zones:</Grid>
                      <Grid item sm={12} className={selector}>
                        <SelectFieldWrapper          options={tenderCalendarZones.map(el => ({label: el.name, value: el.id}))}
                                                     customDisplay={option => `${option.value} - ${option.label}`}
                                                     clearable={true}
                                                     isMulti={true}
                                                     returnFullOnChange={true}
                                                     returnFullSelected={true}
                                                     selected={selectedZones}
                                                     menuPosition="fixed"
                                                     menuPortalTarget={document.body}
                                                     onChange={setZones}
                        />
                      </Grid>
                      <Grid item sm={12} className={label}>Select Markets:</Grid>
                      <Grid item sm={12} className={selector}>
                        <SelectFieldWrapper          options={tenderCalendarMarkets.map(el => ({label: el.name, value: el.code}))}
                                                     clearable={true}
                                                     isMulti={true}
                                                     returnFullOnChange={true}
                                                     returnFullSelected={true}
                                                     selected={selectedMarkets}
                                                     menuPosition="fixed"
                                                     menuPortalTarget={document.body}
                                                     onChange={setMarkets}
                        />
                      </Grid>
                      <Grid item sm={12} className={label}>Select Spend Categories:</Grid>
                      <Grid item sm={12} className={selector}>
                        <SelectFieldWrapper          options={tenderCalendarCategories.map(el => ({id: el.id, label: el.name, value: el.code}))}
                                                     customDisplay={option => `${option.value} - ${option.label}`}
                                                     clearable={true}
                                                     isMulti={true}
                                                     returnFullOnChange={true}
                                                     returnFullSelected={true}
                                                     selected={selectedCategories}
                                                     menuPosition="fixed"
                                                     menuPortalTarget={document.body}
                                                     onChange={setCategories}
                        />
                      </Grid>
                      <Grid item sm={12} className={label}>Select Company Codes:</Grid>
                      <Grid item sm={12} className={selector}>
                        <SelectFieldWrapper          options={tenderCalendarCompanyCodes.map(el => ({id: el.id, label: el.name, value: el.code}))}
                                                     customDisplay={option => `${option.value} - ${option.label}`}
                                                     clearable={true}
                                                     isMulti={true}
                                                     returnFullOnChange={true}
                                                     returnFullSelected={true}
                                                     selected={selectedCompanyCodes}
                                                     menuPosition="fixed"
                                                     menuPortalTarget={document.body}
                                                     onChange={setCompanyCodes}
                        />
                      </Grid>
                      <Grid item sm={12} className={label}>Select Plants:</Grid>
                      <Grid item sm={12} className={selector}>
                        <LargeTypeaheadAsyncSelectWrapper
                          isMulti={true}
                          returnFullOnChange={true}
                          returnFullSelected={true}
                          selected={selectedPlants}
                          limited={2}
                          customDisplay={option => `${option.value} - ${option.label}`}
                          clearable={true}
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                          onChange={setPlants}
                          onLoad={x => {
                            return MasterService.fetchPlant(x).then((x: Plant[]) => x.map(y => y.toSelect()))
                          }}/>
                      </Grid>
                      <Grid item sm={12} className={label}>Select Businesses:</Grid>
                      <Grid item sm={12} className={selector}>
                        <SelectFieldWrapper          options={tenderCalendarBusinesses.map(el => ({id: el.id, label: el.name, value: el.code}))}
                                                     customDisplay={option => `${option.value} - ${option.label}`}
                                                     clearable={true}
                                                     isMulti={true}
                                                     returnFullOnChange={true}
                                                     returnFullSelected={true}
                                                     selected={selectedBusinesses}
                                                     menuPosition="fixed"
                                                     menuPortalTarget={document.body}
                                                     onChange={setBusinesses}
                        />
                      </Grid>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              }
              {
                [UI_MODULE.PSL, UI_MODULE.CB, UI_MODULE.MRP, UI_MODULE.DOCFLOW].includes(module) && <Grid item sm={12}>
                  <ExpansionPanel>
                    <Tooltip title={"Configure your access for the Preferred Supplier List (PSL) - Supplier section of the WebApp"}>
                    <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={heading}>PSL Settings</Typography>
                    </StyledExpansionPanelSummary>
                    </Tooltip>
                    <StyledExpansionPanelDetails>
                      <Grid item sm={12} className={label}>Role:</Grid>
                      <Tooltip title={"Your PSL role is auto-determined in accordance with your SAP roles and position. In case there is something wrong, please contact your Market Role Coordinator"}>
                      <Grid item sm={12} className={selector}>
                        <CustomFastInput
                          type={"text"}
                          disabled={true}
                          initialValue={findRole(prpoSettings.role.options, user.currentUser.getUserRole(user.currentUser))?.label}
                          onChange={()=>void(0)}
                        />
                      </Grid>
                      </Tooltip>
                      <Grid item sm={12} className={label}>Select a Location:</Grid>
                      <Tooltip title={"The suggested options are auto-determined in accordance with your SAP roles. Select the market(s) and/or company code(s) that are relevant to you to filter the Preferred Supplier List accordingly"}>
                      <Grid item sm={12} className={selector}>
                        <UserScopeComponent
                        company = {`${user.currentUser.companyCode} - ${user.currentUser.company}`}
                        isViewOnly = {isViewOnly}
                          initialMarkets={user.currentUser.filters.userScope.markets}
                          initialCompanies={user.currentUser.filters.userScope.companies}
                          hasError={false}
                          onChange={(values)=>{
                            setPrpoSettings({
                              ...prpoSettings,
                              markets: values.markets,
                              companyCodes: values.companyCodes
                            });
                          }}
                        />
                      </Grid>
                      </Tooltip>
                      <Grid item sm={12} className={label}>Select Category:</Grid>
                      <Tooltip title={"For buyers. Select 1 or multiple categories you manage. In case you select nothing, the PSL will come with items that refer to all categories"}>
                      <Grid item sm={12} className={selector}>
                        <UserCategoryComponent
                        disabled = {isViewOnly}
                          initialSpendCatL1s={user.currentUser.filters.userScope.spendCatL1s}
                          initialSpendCatL2s={user.currentUser.filters.userScope.spendCatL2s}
                          initialSpendCatL3s={user.currentUser.filters.userScope.spendCatL3s}
                          onChange={(values) => {
                            setPrpoSettings({
                              ...prpoSettings,
                              spendCatL1s: values.spendCatL1s,
                              spendCatL2s: values.spendCatL2s,
                              spendCatL3s: values.spendCatL3s,
                            });
                          }}
                          hasError={false}
                        />
                      </Grid>
                      </Tooltip>
                      {prpoSettings.role?.selected?.value !== IUserRole.REQUESTOR && <>
                        <Grid item sm={12} className={label}>PSL expiration days:</Grid>
                        <Tooltip title={"For buyers only. The Expiration days help you to regroup all PSL items that are going to expire within the time frame you define so you can prepare the negotiations accordingly. Example: by keeping the default value of 60 days, all PSL items that are going to expire within the next 60 days will be accessible in the “Expiring” tab of the PSL"}>
                        <Grid item sm={12} className={selector}>
                          <CustomFastInput
                        disabled = {isViewOnly}
                          type={'number'} initialValue={prpoSettings.pslExpiryDays}
                                           onChange={(value)=>setPrpoSettings({...prpoSettings, pslExpiryDays: parseInt(value)})}/>
                        </Grid>
                        </Tooltip>
                      </>}
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <Tooltip title={"Configure your access for the MRP"}>
                      <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={heading}>MRP Settings</Typography>
                      </StyledExpansionPanelSummary>
                    </Tooltip>
                    <StyledExpansionPanelDetails>
                      <Grid item sm={12} className={label}>Role:</Grid>
                      <Tooltip title={"Your MRP role is auto-determined in accordance with your SAP roles and position. In case there is something wrong, please contact your Market Role Coordinator"}>
                        <Grid item sm={12} className={selector}>
                          <CustomFastInput
                            type={"text"}
                            disabled={true}
                            initialValue={findRole(prpoSettings.role.options, MrpUser.fromUser(user.currentUser).getUserRole())?.label}
                            onChange={()=>void(0)}
                          />
                        </Grid>
                      </Tooltip>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              }
            </Grid>
          </StyledUserSettingsDialogContent>
      }
      <StyledDialogActions>
        {
          onClose && <Button color="primary" onClick={() => onClose()}>Cancel</Button>
        }
        <Button color="primary" type="submit">Save</Button>
      </StyledDialogActions>
    </MuiThemeProvider>
  )
};

export const UserSettingsContent = withContext(UserSettings);
