import {AzureErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {CompetitiveBiddingTypes} from "./competitiveBiddings.actions";
import {ShortCompetitiveBidding} from "../domains/shortCompetitiveBidding";
import {List} from "../../shared/utils/odataClient.wrapper";
import {QueryableListEnum} from "../domains/competitiveBidding";
import DocumentFlow from "../../docFlows/domains/documentFlow/documentFlow";

export class FetchCbList extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_LIST;

  constructor(public list: QueryableListEnum, public df?: DocumentFlow) {
    super();
  }
}

export class FetchCbListSuccess extends SuccessAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_LIST_SUCCESS;

  constructor(public data: List<ShortCompetitiveBidding>) {
    super();
  }
}

export class FetchCBListRequest extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_LIST_REQUEST;
}

export class FetchCbListError extends AzureErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_LIST_FAILURE;
}
