import * as React from "react";
import { assoc, mergeWith, omit, isNil } from 'ramda';
import MaterialTable, { Column } from "material-table";
import { TableContainer } from "../../../shared/components/layout/styles";
import { getFormStatus, getScope, ISupplier, MyTasksAtMarketTabs, PSL, SupplierStatus, SupplierTabs } from "../../domains/psl";
import Select from "@material-ui/core/Select";
import TablePagination from "@material-ui/core/TablePagination";
import { User } from "../../../shared/domains/user/user";
import { OrderDirection } from "../../../../lib/odata";
import { useTranslation } from "react-i18next";
import Chip from "@material-ui/core/Chip";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory } from "react-router";
import Chips, { DiversityChip } from "../../../shared/components/filters/chips";
import { PSLService } from "../../domains/psl.service";
import { useTableStyles } from "./pslTable.styles";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { DownloadItem, UploadItemTypes } from "../../../shared/actions/upload.actions";
import { FileName } from "../../../competitiveBidding/containers/cb.styles";
import { PSLColumns } from 'src/app/shared/domains/user/pslColumns';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useRef } from "react";
import { FeaturesContext } from "../../../shared/domains/core/pataflag.context";
import { IMaterial } from "../../domains/pslMaterial";
import { DocumentFlowService } from "../../../docFlows/domains/documentFlow/documentFlow.service";
import { UserInteractionType } from "../../../shared/domains/user/userInteractionType";
import { Features } from "src/app/shared/domains/core/pataflag";
import styled from "styled-components";
import StarRounded from "@material-ui/icons/StarRounded";
import InfoIcon from '@material-ui/icons/Info';
import { PSLTypes, UpdateFavourite } from "../../actions/psl.actions";
import { getAllIndexes } from "src/app/shared/utils/arraysHelper";
import { Link, Tooltip } from "@material-ui/core";
import { PslVendorInfo } from "src/app/shared/components/modal/pslVendorInfo";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";
import { LocationInfo, LocationType } from "../../containers/pslForm/component/LocationInfo";

interface PslTableProps {
  isLocalizedList: boolean,
  setSelectedCheckboxes: Function,
  showBorder: boolean;
  loading: boolean;
  data: ISupplier[];
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onNewParent: (parentId: number, parentVendorName: string, parentVendorId: string) => void;
  onNewPage: (page: number) => void;
  onNewPageSize: (pageSize: number) => void;
  onNewOrder: (field: string, direction: OrderDirection) => void;
  user: User;
  userColumns: { id: PSLColumns, show: boolean, hidden: boolean }[];
  pslPanelColumns: Column<any>[];
  pslPanelData: IMaterial[];
  isApproverList: boolean
  showDeleted: boolean;
  onDelete?: (pslId: number) => void;
  onReactivate?: (psl: ISupplier) => void;
  onMaterialDelete: (materialId: number) => void;
  paginationOptions: number[];
  mainTab?: SupplierTabs;
  urlTaskTab?: any;
}

const StyledIcon = styled(StarRounded)`
  color: #F7A122;
  cursor: pointer;
`;

const FavIcon = styled(StyledIcon)`
  color: grey;
`;

export function PslTable(props: PslTableProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedPsl, setSelectedPsl] = React.useState<ISupplier>(null);
  const isRequestor = props.user.isRequestor()
  const isAboveMarketRole = props.user.hasAboveMarketRole()
  const isViewOnly = () => isRequestor
  const tableRef = useRef(null)
  const isViewOnlyUser = props.user.isViewOnly();
  const handleClose = () => setAnchorEl(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = React.useState<string>('');
  const editPsl = async () => {
    localStorage.setItem("isDisabledForm", "false");
    handleClose();
    await DocumentFlowService.logUserInteractionData(UserInteractionType.EditRejectedPSL);
    const param = props.isLocalizedList ? 'localize' : 'edit'
    history.push('/psl/' + param + '/' + selectedPsl.pslId);
  }

  const editwithDisabledPsl = async (currentPslId: number) => {
    handleClose();
    await DocumentFlowService.logUserInteractionData(UserInteractionType.EditRejectedPSL);

    localStorage.setItem("isDisabledForm", "true");

    const param = props.isLocalizedList ? 'localize' : 'edit'
    history.push('/psl/' + param + '/' + currentPslId);
  }
  const { hasFeature } = React.useContext(FeaturesContext);

  const addMaterials = () => {
    handleClose();
    history.push(`/psl/edit/${selectedPsl.pslId}/addmaterials`);
  }
  const deletePsl = () => {
    handleClose()
    props.onDelete(selectedPsl.pslId)
  }
  const reactivatePsl = () => {
    localStorage.setItem("isDisabledForm", "false");
    handleClose()
    props.onReactivate(selectedPsl)
  }
  const reactivateChildPsl = () => {
    handleClose()
    props.onReactivate(selectedPsl)
  }
  const historyPsl = async () => {
    handleClose();
    await DocumentFlowService.logUserInteractionData(UserInteractionType.ViewPslHistory);
    history.push('/psl/history/' + selectedPsl.pslId);
  }

  const viewChildren = async () => {
    handleClose()
    await DocumentFlowService.logUserInteractionData(UserInteractionType.ViewChildrenPsl);
    props.onNewParent(selectedPsl.pslId, selectedPsl.childSupplierName, selectedPsl.childSupplierCode)
  }

  const viewParent = async () => {
    handleClose()
    await DocumentFlowService.logUserInteractionData(UserInteractionType.ViewParentPsl);
    history.push('/psl/edit/' + selectedPsl.parentId);
  }

  const useStyles = makeStyles(() =>
    createStyles({
      hidden: {
        display: "none"
      },
      orange: {
        color: "#F19001"
      }
    }),
  );
  const classes = useStyles({});
  const toolTipclass = useStylesForToolTip({});
  const { pslTableClass, statusChipClass, moreClass, subTableContainer } = useTableStyles({});
  const cellStyle = { padding: '15px 0 15px 15px' };
  const styledColumn = (column: Column<any>) => assoc('cellStyle', cellStyle, column);
  const userColumns = props.userColumns;
  const columns: Column<any>[] = userColumns.map((el, i) => {
    switch (el.id) {
      case PSLColumns.PARENT_VENDOR:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'supplierCode',
          render: (psl: ISupplier) => {
            if (psl?.childSupplierCode && psl?.scope == 1) {
              return <span style={{ textDecoration: 'underline' }}>{psl?.childSupplierCode} - {psl?.childSupplierName}
                {hasFeature(Features.PSL_166244_FEATURE_VENDORINFO) && <span style={{ verticalAlign: 'middle', paddingLeft: '5px', cursor: 'pointer' }}><InfoIcon fontSize="small" onClick={() => {
                  setSelectedVendor(psl?.childSupplierCode);
                  setIsModalOpen(true)
                }} /></span>}
              </span>
            }
            if (psl?.parentCode && psl?.scope == 0) {
              return <span style={{ textDecoration: 'underline' }}>{psl?.parentCode} - {psl?.parentName}
                {hasFeature(Features.PSL_166244_FEATURE_VENDORINFO) && <span style={{ verticalAlign: 'middle', paddingLeft: '5px', cursor: 'pointer' }}><InfoIcon fontSize="small" onClick={() => {
                  setSelectedVendor(psl?.parentCode);
                  setIsModalOpen(true)
                }} /></span>}
              </span>
            }
            return null
          }
        });
      case PSLColumns.CHILD_VENDOR:
        let pslColumn = props.isLocalizedList ? el.id : PSLColumns.LOCAL_VENDOR;
        return styledColumn({
          sorting: true, title: t(`psl_columns:${pslColumn}`), hidden: !el.show || el.hidden, field: 'childSupplierCode',
          render: (psl: ISupplier) => psl?.childSupplierCode && psl?.scope != 1 ? <span style={{ textDecoration: 'underline' }}>{psl?.childSupplierCode} - {psl?.childSupplierName}</span> : null
        });
      case PSLColumns.LOCAL_CHILD_VENDOR:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'childSupplierCode',
          render: (psl: ISupplier) => psl?.childSupplierCode && psl?.scope != 1 ? <span style={{ textDecoration: 'underline' }}>{psl?.childSupplierCode} - {psl?.childSupplierName}
            {hasFeature(Features.PSL_166244_FEATURE_VENDORINFO) && <span style={{ verticalAlign: 'middle', paddingLeft: '5px', cursor: 'pointer' }}><InfoIcon fontSize="small" onClick={() => {
              setSelectedVendor(psl?.childSupplierCode);
              setIsModalOpen(true)
            }} /></span>}
          </span> : null
        });
      case PSLColumns.SUPPLIER_CONTACT:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'pslVendorContactName', hidden: !el.show || el.hidden });
      case PSLColumns.SUPPLIER_STATUS:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'statusId',
          render: (psl: ISupplier) => psl?.statusId !== SupplierStatus.BLANK ?
            <Chip label={Object.keys(SupplierStatus).find(key => SupplierStatus[key] === psl?.statusId)?.replace('_', ' ')} className={statusChipClass} /> : null
        });
      case PSLColumns.BUSINESS_UNIT:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => <div style={{ maxWidth: '240px' }}><Chips source={psl?.businessUnits?.map(el => ({ title: el.name, id: el.id }))}
            minimumItems={1000} onDelete={() => { }} /></div>
        });
      case PSLColumns.VALID_FROM:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'validFrom',
          render: (psl: ISupplier) => <span>{psl?.validFrom ? DateTime.fromJSDate(new Date(psl?.validFrom)).toFormat("dd/MM/yyyy") : ""}</span>
        });
      case PSLColumns.VALID_TO:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'validTo',
          render: (psl: ISupplier) => <span>{psl?.validTo ? DateTime.fromJSDate(new Date(psl?.validTo)).toFormat("dd/MM/yyyy") : ""}</span>
        });
      case PSLColumns.LOCATION:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => {

            let locationCollections = {
              zones: psl?.zones ? psl.zones : [],
              markets: psl?.markets ? psl.markets : [],
              companyCodes: psl?.companyCodes ? psl.companyCodes : [],
            }

            return (
              <span style={{ color: '#F19001', display: 'block' }}>
                <Tooltip
                  interactive
                  classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
                  title={
                    <LocationInfo locationCollections={locationCollections} showInfo={LocationType.ZONE} />
                  }
                  placement="right-end"
                >
                  <div style={{width: '100px'}}>{psl?.zones?.length > 0 && `Zone - ${psl.zones.length}`}</div>
                </Tooltip>

                <Tooltip
                  interactive
                  classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
                  title={
                    <LocationInfo locationCollections={locationCollections} showInfo={LocationType.MARKET} />
                  }
                  placement="right-end"
                >
                  <div style={{width: '100px'}}>{psl?.markets?.length > 0 && `Market - ${psl.markets.length}`}</div>
                </Tooltip>

                <Tooltip
                  interactive
                  classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
                  title={
                    <LocationInfo locationCollections={locationCollections} showInfo={LocationType.CODE} />
                  }
                  placement="right-end"
                >
                  <div style={{width: '100px'}}>{psl?.companyCodes?.length > 0 && `Code - ${psl.companyCodes.length}`}</div>
                </Tooltip>
              </span>
            )
          }
        });
      case PSLColumns.ZONE:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (psl: ISupplier) => <span>{psl?.zones?.map((el, i) => <div key={i}>{el.name}</div>)}</span>, hidden: !el.show || el.hidden
        });
      case PSLColumns.MARKET:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (psl: ISupplier) => <span>{psl?.markets?.map((el, i) => <div key={i}>{el.name}</div>)}</span>, hidden: !el.show || el.hidden
        });
      case PSLColumns.COMPANY_CODE:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (psl: ISupplier) => <span>{psl?.companyCodes?.map((el, i) => <div key={i}>{el.code} - {el.name}</div>)}</span>, hidden: !el.show || el.hidden
        });
      case PSLColumns.AGREEMENTS:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'agreements', hidden: !el.show || el.hidden });
      case PSLColumns.BUYER_NAME:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'strategicBuyerEmailAddress', hidden: !el.show || el.hidden });
      case PSLColumns.Market_Contact_Name:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'marketContactName', hidden: !el.show || el.hidden });
      case PSLColumns.TEAM_NAME:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'teamName', hidden: !el.show || el.hidden });
      case PSLColumns.NOTE_REQUESTER:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'noteRequester', hidden: !el.show || el.hidden });
      case PSLColumns.NOTE_LOCAL_PROCUREMENT:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'noteLocalProcurement', hidden: !el.show || el.hidden });
      case PSLColumns.TENDER_NUMBER:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'tenderNumber', hidden: !el.show || el.hidden });
      case PSLColumns.PSL_ID:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'pslId', hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => <Link underline="hover" color="inherit" onClick={()=> editwithDisabledPsl(psl.pslId)}>
          {psl.pslIdWithPrefix || psl.pslId}
        </Link>
        });
      case PSLColumns.PRICE_LIST_LINK:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'priceList',
          render: (psl: ISupplier) => {
            const isUrl = psl?.priceList?.startsWith('http')
            return <span
              style={{
                cursor: isUrl ? 'pointer' : 'default',
                color: isUrl ? '#2689EB' : ''
              }}
              onClick={() => {
                isUrl ? window.open(psl.priceList) : ''
              }}

            >{psl?.priceList}</span>
          },
          hidden: !el.show || el.hidden
        });
      case PSLColumns.STATUS_JUSTIFICATION:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'statusJustification', hidden: !el.show || el.hidden });
      case PSLColumns.REJECTION:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'rejectionComment',
          hidden: (!el.show || el.hidden) && !props.isApproverList
        });
      case PSLColumns.PRICE_AVAILABLE:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'priceList',
          render: (psl: ISupplier) => <span>{psl?.priceList || psl?.unitPrice ? 'Yes' : 'No'}</span>, hidden: !el.show || el.hidden
        });
      case PSLColumns.CREATED_ON:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'createdOn',
          render: (psl: ISupplier) => <span>{psl?.createdOn ? DateTime.fromJSDate(new Date(psl?.createdOn)).toFormat("dd/MM/yyyy") : ""}</span>,
          hidden: !el.show || el.hidden
        });
      case PSLColumns.CREATED_BY_USER:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'createdByUserEmail',
          render: (psl: ISupplier) => <span>{psl?.createdByUser?.email}</span>, hidden: !el.show || el.hidden
        });
      case PSLColumns.UPDATED_ON:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'modifiedOn',
          render: (psl: ISupplier) => <span>{psl?.modifiedOn ? DateTime.fromJSDate(new Date(psl?.modifiedOn)).toFormat("dd/MM/yyyy") : ""}</span>,
          hidden: !el.show || el.hidden
        });
      case PSLColumns.UPDATED_BY_USER:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'modifiedByUserEmail',
          render: (psl: ISupplier) => <span>{psl?.modifiedByUser?.email}</span>, hidden: !el.show || el.hidden
        });
      case PSLColumns.RELATED_TO_EASYBUY:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'relatedToEasyBuy',
          render: (psl: ISupplier) => <span>{psl?.relatedToEasyBuy ? 'YES' : 'NO'}</span>
        });
      case PSLColumns.STATUS_ATTACHMENTS:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => <span style={{ display: 'flex', flexDirection: 'column' }}>
            {psl?.statusAttachments?.map((el, i) => <FileName key={i} onClick={() => downloadPslAttachment(el)}>{el}</FileName>)}
          </span>
        });
      case PSLColumns.FORM_STATUS:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'formStatus',
          render: (psl: ISupplier) => {
            const status = getFormStatus(psl, props.user.pslExpiryDays);
            switch (status) {
              case 'Pending Localization-VFT Maintenance':
                return <Tooltip placement={"right"} title={'There is no linkage existing between Parent & Child Vendor for the Market in Vendor Family Tree ( VFT) in Master Data'}><div>{status}</div></Tooltip>
              default:
                return <span>{status}</span>
            }
          }
        });
      case PSLColumns.PLANT:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (psl: ISupplier) => <span>{psl?.plants?.map((el, i) => <div key={i}>{el.code} - {el.name}</div>)}</span>, hidden: !el.show || el.hidden
        });
      case PSLColumns.SCOPE:
        const findScopeLocalChild = (pslIdWithPrefix) => {
          if (pslIdWithPrefix.charAt(0) === "L") {
            return "- Local";
          } else if (pslIdWithPrefix.charAt(0) === "C") {
            return "- Child";
          } else {
            return "";
          }
        }
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'scope', hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => <span>{getScope(psl?.scope)}{hasFeature(Features.PSL_163225_COLUMN_STATUS) && findScopeLocalChild(psl.pslIdWithPrefix)}</span>
        });
      case PSLColumns.APPROVERS:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'approvers', hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => (psl.approvers?.map((el) => (<div>{el}</div>)))
        });
      case PSLColumns.Supplier_Diversity:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'diversity', hidden: !el.show || el.hidden,
          // render: (psl: ISupplier) => <DiversityChip diversity={[{ code: "Currently Testing it only", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: false }]}></DiversityChip>
          render: (psl: ISupplier) => psl?.diversity?.length ? <DiversityChip diversity={psl.diversity}></DiversityChip> : null
        });
      case PSLColumns.Localizer:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'localizer', hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => (psl.localizer?.map((el) => (<div>{el}</div>)))
        });
      case PSLColumns.REFRENCE_NUMBER:
        if(hasFeature(Features.PSL_166920_SHOW_REF_NUMBER)){
          return styledColumn({
            sorting: true, 
            title: t(`psl_columns:${el.id}`), 
            field: 'referenceId', 
            hidden: !el.show || el.hidden,
            render: (psl: ISupplier) => <span style={{whiteSpace: 'nowrap'}}>{psl.referenceId}</span>
          });
        }else{
          return null
        }
      default:
        return null;
    }
  });

  const downloadPslAttachment = (name: string) => {
    name?.startsWith("http") ?
      window.open(name, '_blank')
      : dispatch(new DownloadItem(UploadItemTypes.PLS_Vendor_Sourcing, name));
  }
  const removedNulls = columns.filter(el => !isNil(el));
  const pageOptionsSelect = <Select disableUnderline={true}
    style={{ fontSize: "0.875rem" }}
    classes={{ icon: classes.orange, root: classes.orange }}
    value={props.pageSize}
    onChange={e => props.onNewPageSize(Number(e.target.value))}>
    {props.paginationOptions.map(pageSize => (
      <MenuItem key={pageSize} value={pageSize}>
        {pageSize} lines
      </MenuItem>
    ))}
  </Select>
  const columnsWithMore = (): Column<any>[] => {
    var withMoreOption = removedNulls;
    
    if (isRequestor || props.isApproverList || isViewOnlyUser) {
      withMoreOption.push({ sorting: false, title: pageOptionsSelect, field: '' })
    }
    else {
      withMoreOption.push({
        title: '', field: '', sorting: false ,render: (psl: any) => {
          return <div className={moreClass} onClick={(event) => {
            setSelectedPsl(psl);
            setAnchorEl(event.currentTarget);
          }
          }>
            {
              hasFeature(Features.PSL_167588_HIDE_MORE_OPTIONS_FOR_REFNUMBER) ?
              psl?.referenceId ? null : <><span>More</span><MoreVertIcon /></>
              :
              <><span>More</span><MoreVertIcon /></>
            }
          </div>
        }
      })
    }

    if (hasFeature(Features.PSL_FAVORITE) && props.mainTab === SupplierTabs.PSL) {
      withMoreOption.unshift({
        sorting: false, title: "", field: '', render: (material: IMaterial) => {
          // let index = props.data.findIndex(x => x.pslId === material.pslId);
          return <React.Fragment>
            {material.isFavorite ? <StyledIcon onClick={() => {
              let index = getAllIndexes(props.data, "pslId", material.pslId);
              dispatch(new UpdateFavourite(false, index, material.pslId));
            }} /> :
              <FavIcon onClick={() => {
                let index = getAllIndexes(props.data, "pslId", material.pslId);
                dispatch(new UpdateFavourite(true, index, material.pslId));
              }} />}
          </React.Fragment>
        }
      });
    }

    return withMoreOption;

  };

  const pslPanelColumnsWithDelete = props.pslPanelColumns.concat([{
    title: '', field: '', render: (material: any) => {
      return <div className={moreClass} onClick={() => props.onMaterialDelete(material.materialId)}>
        <DeleteIcon />
      </div>
    }
  }]);
  const isPslArchivedAndNotAChild = () => selectedPsl && new PSL(selectedPsl).isArchived() && !new PSL(selectedPsl).isChildPsl()
  const isPslChildArchived = () => selectedPsl && new PSL(selectedPsl).isOnlyArchived() && new PSL(selectedPsl).isChildPsl()
  const isChildDeleted = () => selectedPsl && new PSL(selectedPsl).isChildPslDeleted()
  const isObsoleteChild = () => selectedPsl && new PSL(selectedPsl).isObsoleteChildPsl()
  const isAboveMarketNotDeletedPsl = selectedPsl && new PSL(selectedPsl).isAboveMarket() && selectedPsl.deleted == false
  const isChildPsl = selectedPsl && new PSL(selectedPsl).isChildPsl()
  const isLocalizedTabOrNotPendingChild = props.isLocalizedList || selectedPsl && !new PSL(selectedPsl).isChildPslWithAnyPendingChanges()
  return (
    <TableContainer className={pslTableClass} style={{ marginTop: '20px' }}>
      <Menu
        key={"psl-table"}
        id="simple-menu"
        style={{ minWidth: '100px' }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(props.onReactivate && (isPslArchivedAndNotAChild() || isChildDeleted()) && !isObsoleteChild()) &&
          <MenuItem key={"reactivate-psl"} onClick={reactivatePsl}>Reactivate</MenuItem>
          || [
            !isPslChildArchived() && <MenuItem key={"edit-psl"} onClick={editPsl}>Edit Form</MenuItem>,
            !isChildPsl && <MenuItem key={"add-material"} onClick={addMaterials}>Add Materials</MenuItem>,
            isLocalizedTabOrNotPendingChild && <MenuItem key={"delete-psl"} onClick={deletePsl}>Delete Form</MenuItem>
          ]
        }
        <MenuItem onClick={historyPsl}>PSL History</MenuItem>
        {isAboveMarketRole && isAboveMarketNotDeletedPsl && <MenuItem onClick={viewChildren}>View Children</MenuItem>}
        {isChildPsl && <MenuItem onClick={viewParent}>View Parent PSL</MenuItem>}
      </Menu>
      <MaterialTable
        isLoading={props.loading}
        columns={columnsWithMore()}
        data={props.data}
        style={{
          borderSpacing: '10px',
          borderCollapse: 'separate',
          border: props.showBorder ? '1px solid #2689EB' : null
        }}
        components={{
          Pagination: (componentProps: any) => <TablePagination
            {...componentProps}
            rowsPerPageOptions={props.paginationOptions}
            rowsPerPage={props.pageSize}
            count={props.totalCount}
            page={props.currentPage}
            onChangePage={(evt: any, page: number) => {
              props.onNewPage(page);
            }}
            onChangeRowsPerPage={(event: any) => props.onNewPageSize(event.target.value)}
            classes={{ input: classes.hidden, caption: classes.hidden }}
          />
        }}
        options={{
          selection: props.isApproverList,
          draggable: false,
          toolbar: false,
          pageSizeOptions: [],
          pageSize: props.data.length > 0 && props.data.length < props.pageSize ? props.data.length : props.pageSize,
          rowStyle: {
            backgroundColor: '#F9F9F9'
          },
          headerStyle: {
            padding: cellStyle.padding,
            whiteSpace: 'nowrap',
            fontWeight: 700,
            color: '#4A4A4A'
          }
        }}
        onSelectionChange={(allSelectedItems: ISupplier[], toggledItem: ISupplier) => {
          props.setSelectedCheckboxes(allSelectedItems?.length > 0 ? allSelectedItems.map(el => el.pslId) : []);
        }}
        onOrderChange={(orderBy: number, orderDirection: ("asc" | "desc")) => {
          if(props.mainTab === SupplierTabs.PSL) orderBy--;
          const column = columns[orderBy]?.field;
          
          const order = orderDirection as OrderDirection;
          props.onNewOrder(column as string, order);
        }}
        detailPanel={[
          {
            render: (rowData: any) => {
              const isPslNotDeleted = () => ["Active", "Pending"].indexOf(getFormStatus(rowData)) !== -1;
              const isPendingChild = !props.isLocalizedList && rowData && new PSL(rowData).isChildPslWithAnyPendingChanges()
              return <div className={subTableContainer}>
                <MaterialTable
                  tableRef={tableRef}
                  isLoading={false}
                  columns={isPslNotDeleted() && !isViewOnly() && !isPendingChild && !isViewOnlyUser ? pslPanelColumnsWithDelete : props.pslPanelColumns}
                  data={() => PSLService.fetchMaterialsByPslId(rowData.pslId, props.showDeleted).then(res => {
                    tableRef.current.dataManager.changePageSize(res.length)
                    return ({
                      data: res.map(el => mergeWith((material, psl) => material ? material : psl, el, omit(['tableData'], rowData))),
                      totalCount: res.length
                    })
                  }) as any}
                  style={{
                    borderSpacing: '10px',
                    borderCollapse: 'separate'
                  }}
                  options={{
                    toolbar: false,
                    pageSizeOptions: [],
                    rowStyle: {
                      backgroundColor: '#F9F9F9'
                    },
                    headerStyle: {
                      padding: '0 0 15px 15px',
                      whiteSpace: 'nowrap',
                      fontWeight: 700,
                      color: '#4A4A4A'
                    }
                  }}
                  components={{
                    Pagination: (componentProps: any) => null
                  }}
                >
                </MaterialTable></div>
            }
          }
        ]}
      >
      </MaterialTable>
      <PslVendorInfo isModalOpen={isModalOpen} vendorId={selectedVendor} setIsModalOpen={setIsModalOpen} />
    </TableContainer>
  );
}