import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { __RouterContext } from 'react-router';
import { RootStateStore } from "../../../application.reducers";
import { User } from "../../domains/user/user";
import { UI_MODULE } from "../../domains/core/ui";

import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ListItemText from '@material-ui/core/ListItemText';
import PollIcon from '@material-ui/icons/Poll';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import WebIcon from '@material-ui/icons/Web';
import ChatIcon from '@material-ui/icons/Chat';
import { StyledAppBar, StyledApprovePrViaEmailScreen, StyledListItem, StyledListItemIcon, StyledSidebar } from './styles';
import { Header } from "../../components/header";
import { Link } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import Tooltip from "@material-ui/core/Tooltip";
import { FeaturesContext } from "../../domains/core/pataflag.context";
import { UserSettingsPopup } from "./components/userSettings/userSettingsPopup";
import { environment } from '../../domains/core/environment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { EasyRequestIcon, NbsCockpitIcon, RequestCockpitIcon } from 'src/app/landing/containers/icons';
import { Features } from '../../domains/core/pataflag';
import { ShowNbsTile } from 'src/app/landing/containers/landing.page';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      display: 'flex!important',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none!important',
      borderBottom: '1px solid #E0E0E0'
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px) !important`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      color: '#fff!important',
      margin: '0!important',
      padding: '14px!important',
      width: 52
    },
    hide: {
      display: 'none',
    },
    drawer: {
      backgroundColor: '#F19001',
      color: 'white',
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor: '#F19001!important',
      color: '#fff!important',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: '#F19001!important',
      color: '#fff!important',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 52,
      [theme.breakpoints.up('sm')]: {
        width: 52,
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      ...theme.mixins.toolbar
    },
    menus: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    content: {
      flexGrow: 1,
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        width: 0
      }
    },
    hidden: {
      display: 'none'
    }
  }),
);

function CompanyGuard(props) {
  const user: User = useSelector((state: RootStateStore) => state.shared.user.currentUser);
  const module: UI_MODULE = useSelector((state: RootStateStore) => state.shared.ui.module);
  const { hasFeature } = useContext(FeaturesContext);

  if ([UI_MODULE.CONTRACT, UI_MODULE.TENDER_CALENDAR].includes(module))
    return props.children;

  if (hasFeature("zones")) {
    return (
      (!user.userPreferredCompanyCode || !user.userPreferredCompanyName) || (!user.selectedZoneId) ?
        <UserSettingsPopup /> : <>{props.children}</>
    )
  } else {
    return (
      (!user.userPreferredCompanyCode || !user.userPreferredCompanyName) ?
        <UserSettingsPopup /> : <>{props.children}</>
    )
  }
}

export default function DashboardComponent(props: any) {
  const classes = useStyles({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const routerContext = useContext(__RouterContext);
  const { hasFeature } = useContext(FeaturesContext);
  const module: UI_MODULE = useSelector((state: RootStateStore) => state.shared.ui.module);
  const isApprovePrViaEmailPage = routerContext.location.pathname === "/approve-pr-via-email";
  const user: User = useSelector((state: RootStateStore) => state.shared.user.currentUser);

  if (module === UI_MODULE.DASHBOARD) {
    return (
      <div className={classes.root}>
        <StyledAppBar
          color="inherit"
          position="fixed"
          className={classes.appBar}
        >
          <Toolbar disableGutters={true}>
            <Header sidebarOpen={false} />
          </Toolbar>
        </StyledAppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      </div>
    )
  }

  if (isApprovePrViaEmailPage) {
    return (
      <div className={classes.root}>
        <StyledApprovePrViaEmailScreen>
          <CssBaseline />
          {props.children}
        </StyledApprovePrViaEmailScreen>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <StyledAppBar
        color="inherit"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar disableGutters={true}>
          <IconButton
            aria-label="Open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            color="primary"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Header sidebarOpen={open} />
        </Toolbar>
      </StyledAppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <StyledSidebar className={classes.toolbar}>
          <a onClick={() => dispatch(push('/'))}><img src={process.env.PUBLIC_URL + '/images/logo.png'} /></a>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </StyledSidebar>

        <List className={classes.menus}>
          <div className="menu-app">
            <StyledListItem button className={classes.hidden}>
              <StyledListItemIcon><PollIcon /></StyledListItemIcon>
              <ListItemText primary="Dashboard" />
            </StyledListItem>
            <StyledListItem button component={Link} to="/psl">
              <StyledListItemIcon><Tooltip title="Preferred Supplier List"><PersonIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="Preferred Supplier List" />
            </StyledListItem>

            <StyledListItem button component={Link} to={{ pathname: `${environment.EASY_REQUEST_URL}` }} target="_blank">
              <StyledListItemIcon><Tooltip title="Easy Request">
                <div>
                  <EasyRequestIcon stroke={"white"} fill={"#F19001"} width="2em" height="2em" />
                </div>
              </Tooltip></StyledListItemIcon>
              <ListItemText primary="Easy Request" />
            </StyledListItem>

            <StyledListItem button component={Link} to="/document-flow">
              <StyledListItemIcon><Tooltip title="Request Cockpit">
                <div>
                  <RequestCockpitIcon stroke={"white"} fill={"#F19001"} width="2em" height="2em" />
                </div>
              </Tooltip>
              </StyledListItemIcon>
              <ListItemText primary="Request Cockpit" />
            </StyledListItem>

            <StyledListItem button component={Link} to="/business-approval">
              <StyledListItemIcon><Tooltip
                title="Business Approval"><AssignmentTurnedInIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="Business Approval" />
            </StyledListItem>

            <StyledListItem button component={Link} to="/purchasing-approval">
              <StyledListItemIcon><Tooltip
                title="Procurement Approval"><AssignmentTurnedInIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="Procurement Approval" />
            </StyledListItem>

            <StyledListItem button component={Link} to={{ pathname: `${environment.AMC_URL}` }} target="_blank">
              <StyledListItemIcon><Tooltip
                title="Configuration Cockpit"><DoneAllIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary={`Configuration Cockpit`} />
            </StyledListItem>

            <StyledListItem button component={Link} to="/competitive-bidding">
              <StyledListItemIcon><Tooltip title="Competitive Bidding"><MoneyIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="Competitive Bidding" />
            </StyledListItem>

            <StyledListItem button component={Link} to="/mrp">
              <StyledListItemIcon><Tooltip title="MRP"><SettingsIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="MRP" />
            </StyledListItem>

            <StyledListItem button component={Link} to="/contracts">
              <StyledListItemIcon><Tooltip title="Contracts"><AssignmentIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="Contracts" />
            </StyledListItem>

            <StyledListItem button component={Link} to="/contract/appointments">
              <StyledListItemIcon><Tooltip title="Tender Calendar"><CalendarTodayIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="Tender Calendar" />
            </StyledListItem>

            <StyledListItem button className={classes.hidden}>
              <StyledListItemIcon><Tooltip title="Catalogs"><WebIcon /></Tooltip></StyledListItemIcon>
              <ListItemText primary="Catalogs" />
            </StyledListItem>
            <ShowNbsTile>
              {
                hasFeature(Features.NBS_COCKPIT) ?
                  <StyledListItem button component={Link} to="/nbs-cockpit">
                    <StyledListItemIcon><Tooltip title="NBS Cockpit">
                      <div>
                        <NbsCockpitIcon fill={"#fff"} width="24px" height="24px" />
                      </div>
                    </Tooltip>
                    </StyledListItemIcon>
                    <ListItemText primary="NBS Cockpit" />
                  </StyledListItem> : null
              }
            </ShowNbsTile>
          </div>
          {/* <StyledListItem button component={Link} to="/faq">
            <StyledListItemIcon><Tooltip
              title="FAQ"><ChatIcon /></Tooltip></StyledListItemIcon>
            <ListItemText primary="FAQ" />
          </StyledListItem> */}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <CssBaseline />
        <CompanyGuard>{props.children}</CompanyGuard>
      </main>
    </div>
  )
}
