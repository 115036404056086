interface MaterialTypeDTO {
    Mat_Type: string;
    Mtype_desc: string;
    __metadata: { id: string; type: string; uri: string };
}

export class MaterialType {
    constructor(
        public Mat_Type: string,
        public Mtype_desc: string,
    ) {
    }

    static FromBackend(data: MaterialTypeDTO): MaterialType {
        return new MaterialType(
            data.Mat_Type,
            data.Mtype_desc
        )
    }

    static toSelect(data: MaterialType) {
        return {
            value: data.Mat_Type,
            label: `${data.Mat_Type} - ${data.Mtype_desc}`,
        }
    }
}
