import { assoc, flatten, filter, merge } from "ramda";
import * as _ from "lodash";
import { PurchaseRequestActionTypes } from "../actions/purchaseRequest.action";
import { IAction } from "../../../shared/domains/core/actions";
import {
  purchaseRequestQuery,
  ODataQuery
} from "../domains/purchaseRequest/query";
import { PurchaseRequest } from "../domains/purchaseRequest/purchaseRequest";
import { BAActionTypes } from "../actions/baFilters";

export interface PurchaseRequestState {
  query: ODataQuery;
  purchaseRequestList: PurchaseRequest[];
  loading: boolean;
  itemLoading: boolean;
  error: any;
  totalCount: number;
  selectedPurchaseRequest: PurchaseRequest;
  purchaseRequestInfo: any;
  purchaseRequestAccount: any;
  purchaseRequestPricing: any;
  selectedParent: any;
  selectedChild: any;
  allCheckedArray: any;
  isChangedRowSelectedData: boolean;
  defaultAliys: string
}

export const initialPurchaseRequestState: PurchaseRequestState = {
  query: purchaseRequestQuery(),
  purchaseRequestList: [],
  loading: true,
  itemLoading: true,
  error: null,
  totalCount: 0,
  selectedPurchaseRequest: null,
  purchaseRequestInfo: null,
  purchaseRequestAccount: [],
  purchaseRequestPricing: [],
  selectedParent: [],
  selectedChild: [],
  allCheckedArray: [],
  isChangedRowSelectedData: false,
  defaultAliys: null
};

const loading = { loading: true, error: null };
const emptyAndLoading = {
  loading: true,
  error: null,
  purchaseRequestList: [],
  totalCount: 0
};

export function PurchaseRequestReducers(
  state: PurchaseRequestState = initialPurchaseRequestState,
  action: IAction<PurchaseRequestActionTypes | BAActionTypes>
): PurchaseRequestState {
  switch (action.type) {
    case PurchaseRequestActionTypes.FETCH_PRS:
      return {
        ...state,
        loading: true,
        purchaseRequestList: [],
        totalCount: 0
      };
    case PurchaseRequestActionTypes.FETCH_PRS_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseRequestList: action.items,
        totalCount: action.totalCount ? action.totalCount : 0,
        query: action.newQuery
      };
    case PurchaseRequestActionTypes.NEW_PR_QUERY:
      assoc("query", action.query, state);
      const loaderState = { loading: true };
      return merge(state, loaderState);
    case PurchaseRequestActionTypes.SET_SELECTED_PRS:
      const partialState = {
        selectedPurchaseRequest: action.purchaseRequest,
        loading: false
      };
      return merge(state, partialState);
    case PurchaseRequestActionTypes.SELECT_CHILD:
      let storedArray = [...state.selectedChild];
      console.log();
      let ArrayA = [];
      let ArrayB = [];
      if (storedArray.length) {
        action.selectedChild.map(child => {
          storedArray.map(storedChild => {
            console.log();
            if (
              child.AribaPR == storedChild.AribaPR &&
              child.ItemNo == storedChild.ItemNo
            ) {
              storedChild.checked = child.checked;
            } else {
              let index = -1;
              for (let i = 0; i < ArrayA.length; i++) {
                var el = ArrayA[i];
                if (child.AribaPR == el.AribaPR && child.ItemNo == el.ItemNo) {
                  index = i;
                }
              }
              for (let i = 0; i < storedArray.length; i++) {
                var el = storedArray[i];
                if (child.AribaPR == el.AribaPR && child.ItemNo == el.ItemNo) {
                  index = i;
                }
              }
              if (index == -1) {
                ArrayA.push(child);
              }
            }
          });
        });
        storedArray = [...storedArray, ...ArrayA];
      }

      let allAriba = [];
      if (storedArray.length) {
        storedArray.map(storedLine => {
          allAriba.push(storedLine.AribaPR);
        });
      } else {
        action.selectedChild.map(storedLine => {
          allAriba.push(storedLine.AribaPR);
        });
      }

      let unique = _.uniq(allAriba);
      if (storedArray.length) {
        storedArray.map(child => {
          if (!child.checked) {
            const index = unique.indexOf(child.AribaPR);

            if (index > -1) {
              unique.splice(index, 1);
            }
          }
        });
      } else {
        action.selectedChild.map(child => {
          if (!child.checked) {
            const index = unique.indexOf(child.AribaPR);

            if (index > -1) {
              unique.splice(index, 1);
            }
          }
        });
      }

      const partialChildState = {
        selectedChild: storedArray.length ? storedArray : action.selectedChild,
        allCheckedArray: unique,
        loading: false,
        isChangedRowSelectedData: !state.isChangedRowSelectedData
      };
      return merge(state, partialChildState);
    case PurchaseRequestActionTypes.SELECT_PARENT:
      let childItem = [...state.selectedChild];
      let parentItem = [...action.selectedParent];
      childItem.map(item => {
        if (parentItem.indexOf(item.AribaPR) > -1) {
          item.checked = true;
        } else {
          if (action.currentKey == item.AribaPR && !action.currentAction) {
            item.checked = false;
          }
        }
      });
      const partialParentState = {
        selectedChild: childItem,
        selectedParent: action.selectedParent,
        loading: false
      };
      return merge(state, partialParentState);
    case PurchaseRequestActionTypes.RESET_SELECTED_BAS:
      let resetChildItem = [...state.selectedChild];
      resetChildItem.filter(item => (item.checked = false));
      const selectData = {
        selectedParent: [],
        selectedChild: resetChildItem,
        allCheckedArray: [],
        loading: false
      };
      return merge(state, selectData);
    case PurchaseRequestActionTypes.FETCH_PRS_DETAIL:
      return {
        ...state,
        itemLoading: true
      };
    case PurchaseRequestActionTypes.FETCH_PRS_DETAIL_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        purchaseRequestInfo: action.info,
        purchaseRequestAccount: action.accountList,
        purchaseRequestPricing: action.pricing
      };
    case PurchaseRequestActionTypes.FETCH_PRS_LINES:
      return {
        ...state,
        purchaseRequestList: state.purchaseRequestList.map(purchaseRequest =>
          purchaseRequest.SapPR === action.SapPR
            ? { ...purchaseRequest, innerLoading: true }
            : purchaseRequest
        )
      };
    case PurchaseRequestActionTypes.FETCH_PRS_LINES_SUCCESS:
      return {
        ...state,
        purchaseRequestList: state.purchaseRequestList.map(purchaseRequest =>
          purchaseRequest.SapPR === action.SapPR
            ? { ...purchaseRequest, PRLines: action.items, innerLoading: false }
            : purchaseRequest
        )
      };

    case PurchaseRequestActionTypes.FETCH_PRS_LINES_FAILURE:
      return {
        ...state,
        purchaseRequestList: state.purchaseRequestList.map(purchaseRequest =>
          purchaseRequest.SapPR === action.error.SapPR
            ? { ...purchaseRequest, innerLoading: false }
            : purchaseRequest
        ),
        error: action.error,
        loading: false
      };

    case PurchaseRequestActionTypes.FETCH_PRS_FAILURE:
      const failurePartialState = { error: action.error, loading: false, purchaseRequestList: [],
        totalCount: 0 };
      return merge(state, failurePartialState);
    case BAActionTypes.BA_NEW_FILTER:
    case BAActionTypes.DELETE_BA_FILTER:
    case BAActionTypes.RESET_BA_FILTERS:
      return {
        ...state,
        loading: true
      };
    case BAActionTypes.UPDATE_BA_FILTER_SUCCESS:
    case BAActionTypes.UPDATE_BA_FILTER_FAILURE:
      return {
        ...state,
        loading: false
      };
      case PurchaseRequestActionTypes.BA_DEFAULT_ALIYS:
      return {
        ...state,
      };
    case PurchaseRequestActionTypes.BA_DEFAULT_ALIYS_SUCCESS:
      return {
        ...state,
        defaultAliys: action.aliys
      };
    default:
      return state;
  }
}
