import { isEmpty } from 'ramda';
import { SharedStateStore } from './../reducers/index';
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects'
import {
  FetchUserError,
  FetchUserSuccess,
  FetchUserAppFiltersSuccess,
  FetchUserProfileSuccess,
  FetchLiteUserSuccess,
  FetchPreferredCompaniesError,
  FetchPreferredCompaniesSuccess,
  FetchTenderCalendarInfoError,
  FetchTenderCalendarInfoSuccess,
  GetUserInteractionStatisticsError,
  GetUserInteractionStatisticsSuccess,
  RefusedSendFeedbackError,
  RefusedSendFeedbackSuccess,
  SaveContractCalendarFilters,
  SaveContractCalendarFiltersError,
  SaveContractCalendarFiltersSuccess,
  SaveContractFiltersError,
  SaveContractFiltersSuccess,
  SaveBusinessApprovalFiltersError,
  SaveBusinessApprovalFiltersSuccess,
  SaveProcurementApprovalFiltersError,
  SaveProcurementApprovalFiltersSuccess,
  SavePreferredCompany,
  SavePreferredCompanyError,
  SavePreferredCompanySuccess,
  SavePreferredZone,
  SendFeedback,
  SendFeedbackError,
  SendFeedbackSuccess,
  UserActionTypes,
  ViewChanges,
  ViewChangesSuccess,
  SaveNewCompanyCodeSuccess,
  SaveNewCompanyCodeError,
} from "../actions/user.actions";
import { UserService } from "../domains/user/user.service";
import { MasterService } from "../domains/master/master.service";
import { CompanyData } from "../domains/master/company";
import { User, UserFiltersDTO } from "../domains/user/user";
import { QueryMRPActions } from "../../mrp/reducers/mrpList.reducer";
import { QueryPSLActions } from "../../preferredSuppliers/reducers/pslList.reducer";
import { QueryPslApproverActions } from "../../preferredSuppliers/reducers/pslApproverList.reducer";
import { newMrpQuery } from "../../mrp/domains/mrpQuery";
import { newPslQuery } from "../../preferredSuppliers/domains/pslQuery";
import { UI_MODULE } from "../domains/core/ui";
import { RestoreCalendarFilters } from "../../tenderCalendar/actions/calendarFilter.actions";

export function* fetchLiteUser(): any {
  try {
    const result: User = yield call(UserService.fetchLiteUser);
    yield put(new FetchLiteUserSuccess(result));
  } catch (error) {
    yield put(new FetchUserError(error));
  }
}

export function* fetchUserAppFilters() {
  try {
    const filters: UserFiltersDTO = yield call(UserService.fetchUserAppFilters);
    yield put(new FetchUserAppFiltersSuccess(filters));
    yield populateFiltersAfterLoad(filters);
    yield put(new QueryPslApproverActions.onLoadFiltersSuccess(newPslQuery(filters.approvers)?.filters));
    yield put(new RestoreCalendarFilters(filters.contractCalendar));
  } catch (error) {
    yield put(new FetchUserError(error));
  }
}

export function* fetchUserProfile() {
  try {
    const profile = yield call(UserService.fetchUserProfile);
    yield put(new FetchUserProfileSuccess(profile));
  } catch (error) {
    yield put(new FetchUserError(error));
  }
}

export function* fetchUser(): any {
  try {
    const result: User = yield call(UserService.fetchUser);
    yield put(new FetchUserSuccess(result));
    yield populateFiltersAfterLoad(result.filters);
  } catch (error) {
    yield put(new FetchUserError(error));
  }
}

function* populateFiltersAfterLoad(filters: UserFiltersDTO): any {
  yield put(new QueryMRPActions.onLoadFiltersSuccess(newMrpQuery(filters.mrp)?.filters));
  yield put(new QueryPSLActions.onLoadFiltersSuccess(newPslQuery(filters.psl)?.filters));
}

function* fetchPreferredCompanies() {
  try {
    const { module } = yield select(({ shared }: { shared: SharedStateStore }) => shared.ui);
    let companies: CompanyData[] = [];
    if (module === UI_MODULE.APPROVAL) {
      const { currentUser } = yield select(({ shared }: { shared: SharedStateStore }) => shared.user);
      companies = yield call(MasterService.fetchCompanyCodeBySystemAlias, null, !isEmpty(currentUser.defaultSystemAlias) ? currentUser.defaultSystemAlias[0] : '');
    } else {
      companies = yield call(MasterService.fetchCompanyCodeFromAzure);
    }
    yield put(new FetchPreferredCompaniesSuccess(companies.map(CompanyData.toSelect)));
  } catch (error) {
    yield put(new FetchPreferredCompaniesError(error));
  }
}

function* fetchTenderCalendarInfo() {
  try {
    const [buyers, clusters, zones, markets, categories, companyCodes, businesses] = yield all([
      call(MasterService.fetchTenderBuyers),
      call(MasterService.fetchTenderClusters),
      call(MasterService.fetchZones),
      call(MasterService.fetchTenderMarkets),
      call(MasterService.fetchTenderSpendCategories),
      call(MasterService.fetchTenderCompanyCodes),
      call(MasterService.fetchTenderBusinesses)
    ]);
    yield put(new FetchTenderCalendarInfoSuccess(buyers, clusters, zones, markets, categories, companyCodes, businesses));
  } catch (error) {
    yield put(new FetchTenderCalendarInfoError(error));
  }
}

function* saveUserSettings(action: SavePreferredCompany) {
  try {
    yield call(UserService.saveUserSettings, action.payload);
    yield put(new SavePreferredCompanySuccess(action.payload));
    if (action.done) {
      action.done();
    }
  } catch (error) {
    yield put(new SavePreferredCompanyError(error));
  }
}

function* saveNewCompanyCode(action: any) {
  try {
    yield call(UserService.saveNewCompanyCode, action.payload);
    yield put(new SaveNewCompanyCodeSuccess());
    if (action.done) {
      action.done();
    }
  } catch (error) {
    yield put(new SaveNewCompanyCodeError(error));
  }
}

function* saveContractFilters(action: any) {
  try {
    yield call(UserService.saveContractFilters, action.filters);
    yield put(new SaveContractFiltersSuccess(action.filters));
  } catch (error) {
    yield put(new SaveContractFiltersError(error));
  }
}

function* saveContractCalendarFilters(action: SaveContractCalendarFilters) {
  try {
    yield call(UserService.saveContractCalendarFilters, action.filters);
    yield put(new SaveContractCalendarFiltersSuccess(action.filters));
    if (action.done) {
      action.done();
    }
  } catch (error) {
    yield put(new SaveContractCalendarFiltersError(error));
  }
}

function* saveBAFilters(action: any) {
  try {
    yield call(UserService.saveBusinessApprovalFilters, action.filters);
    yield put(new SaveBusinessApprovalFiltersSuccess(action.filters));
  } catch (error) {
    yield put(new SaveBusinessApprovalFiltersError(error));
  }
}

function* savePAFilters(action: any) {
  try {
    yield call(UserService.saveProcurementApprovalFilters, action.filters);
    yield put(new SaveProcurementApprovalFiltersSuccess(action.filters));
  } catch (error) {
    yield put(new SaveProcurementApprovalFiltersError(error));
  }
}

function* getInteractionStatistics() {
  try {
    const result = yield call(UserService.getInteractionStatistics);
    yield put(new GetUserInteractionStatisticsSuccess("Statistics.xlsx", result));
  } catch (error) {
    yield put(new GetUserInteractionStatisticsError(error));
  }
}

function* viewChanges(action: ViewChanges) {
  try {
    yield call(UserService.viewChanges, action.changeIds);
    yield put(new ViewChangesSuccess());
  } catch (error) {
    console.log(error);
  }
}

function* sendFeedback(action: SendFeedback) {
  try {
    yield call(UserService.sendFeedback, action.feedback);
    yield put(new SendFeedbackSuccess('Feedback sent!'));
    if (action.onDone) {
      action.onDone();
    }
  } catch (error) {
    yield put(new SendFeedbackError(error));
  }
}

function* refusedFeedback() {
  try {
    yield call(UserService.refusedFeedback);
    yield put(new RefusedSendFeedbackSuccess());
  } catch (error) {
    yield put(new RefusedSendFeedbackError(error));
  }
}

function* saveZoneSettings(action: SavePreferredZone) {
  try {
    yield call(UserService.saveZoneSettings, action.zone);
  } catch (error) { }
}

function* watchFetchUser() {
  yield takeLatest(UserActionTypes.FETCH_USER, fetchUser);
}

function* watchFetchLiteUser() {
  yield takeLatest(UserActionTypes.FETCH_LITE_USER, fetchLiteUser);
}

function* watchFetchUserAppFilters() {
  yield takeLatest(UserActionTypes.FETCH_USER_APP_FILTERS, fetchUserAppFilters);
}

function* watchFetchUserProfile() {
  yield takeLatest(UserActionTypes.FETCH_USER_PROFILE, fetchUserProfile);
}

function* watchSaveContractFilters() {
  yield takeLatest(UserActionTypes.SAVE_CONTRACT_FILTERS, saveContractFilters);
}

function* watchSaveContractCalendarFilters() {
  yield takeLatest(UserActionTypes.SAVE_CONTRACT_CALENDAR_FILTERS, saveContractCalendarFilters);
}

function* watchSaveBAFilters() {
  yield takeLatest(UserActionTypes.SAVE_BA_FILTERS, saveBAFilters);
}

function* watchSavePAFilters() {
  yield takeLatest(UserActionTypes.SAVE_PA_FILTERS, savePAFilters);
}

function* watchGetInteractionStatistics() {
  yield takeLatest(UserActionTypes.GET_USER_INTERACTIONS_STATISTICS, getInteractionStatistics);
}

function* watchFetchPreferredCompanies() {
  yield takeLatest(UserActionTypes.FETCH_PREFERRED_COMPANIES, fetchPreferredCompanies);
}

function* watchFetchTenderCalendarInfo() {
  yield takeLatest(UserActionTypes.FETCH_TENDER_CALENDAR_INFO, fetchTenderCalendarInfo);
}

function* watchSavePreferredCompany() {
  yield takeLatest(UserActionTypes.SAVE_PREFERRED_COMPANY, saveUserSettings);
}

function* watchSaveCompanyCode() {
  yield takeLatest(UserActionTypes.SAVE_NEW_COMPANY_CODE, saveNewCompanyCode);
}

function* watchViewChanges() {
  yield takeLatest(UserActionTypes.VIEW_CHANGES, viewChanges);
}

function* watchSendFeedback() {
  yield takeLatest(UserActionTypes.SEND_FEEDBACK, sendFeedback);
}

function* watchRefusedFeedback() {
  yield takeLatest(UserActionTypes.REFUSED_FEEDBACK, refusedFeedback);
}

function* watchSavePreferredZone() {
  yield takeLatest(UserActionTypes.SAVE_PREFERRED_ZONE, saveZoneSettings);
}

export default function* userSaga() {
  yield all([
    fork(watchFetchUser),
    fork(watchFetchLiteUser),
    fork(watchFetchUserAppFilters),
    fork(watchFetchUserProfile),
    fork(watchSaveContractFilters),
    fork(watchSaveContractCalendarFilters),
    fork(watchSaveBAFilters),
    fork(watchSavePAFilters),
    fork(watchGetInteractionStatistics),
    fork(watchFetchPreferredCompanies),
    fork(watchSavePreferredCompany),
    fork(watchViewChanges),
    fork(watchSendFeedback),
    fork(watchRefusedFeedback),
    fork(watchFetchTenderCalendarInfo),
    fork(watchSavePreferredZone),
    fork(watchSaveCompanyCode)
  ])
};
