import {PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {IChatCounter} from "../../shared/domains/chat/roomChat.service";

export enum DocFlowChatTypes {
  GET_MESSAGES_SUCCESS = 'GET_DOCFLOW_CHAT_MESSAGES',
  GET_GROUPS_SUCCESS = 'GET_DOCFLOW_CHAT_GROUPS',
  NEW_DOCFLOW_CHAT_COUNTER = "NEW_DOCFLOW_CHAT_COUNTER"
}

export class NewDocFlowChatCounter extends PendingAction<DocFlowChatTypes> {
  public readonly type = DocFlowChatTypes.NEW_DOCFLOW_CHAT_COUNTER;

  constructor(public counter: IChatCounter, public currentUserId?: number) {
    super();
  }
}

export class GetChatMessagesSuccess extends SuccessAction<DocFlowChatTypes> {
  public readonly type = DocFlowChatTypes.GET_MESSAGES_SUCCESS;

  constructor(public prNo: string, public poNo: string, public messages) {
    super();
  }
}

export class GetChatGroupsSuccess extends SuccessAction<DocFlowChatTypes> {
  public readonly type = DocFlowChatTypes.GET_MESSAGES_SUCCESS;

  constructor(public prNo: string, public poNo: string, public chatGroups) {
    super();
  }
}
