export function sapUrl(env: string = 'local'): string {
  switch (env) {
    case 'prod':
      return 'globe7';
    case 'test':
      return 'globe7prep';
    case 'rte':
      return 'globe7rte';
    case 'local':
    case 'dev':
    default:
      return 'globe7dev';
  }
}

export function ebUrl(env: string = 'local'): string {
  switch (env) {
    case 'rte':
      return 's1-2';
    case 'test':
    case 'prod':
    case 'local':
    case 'dev':
    default:
      return 's1';
  }
}

export function ebRealm(env: string = 'local'): string {
  switch (env) {
    case 'rte':
      return 'nestleondemand-REG-realm-T';
    case 'prod':
      return 'nestleondemand-realm1';
    case 'test':
      return 'nestleondemand-realm1-t';
    case 'local':
    case 'dev':
    default:
      return 'nestleondemand-realm1-T';
  }
}

export function rcUrl(env: string = 'local'): string {
  switch (env) {
    case 'prod':
      return 'https://s2pbrowser.nestle.com'
    case 'test':
      return 'https://test.s2pbrowser.nestle.com';
    case 'rte':
      return 'https://rte.s2pbrowser.nestle.com';
    case 'dev':
      return 'https://dev.s2pbrowser.nestle.com';
    case 'local':
    default:
      return 'https://dev.s2pbrowser.nestle.com';
  }
}
