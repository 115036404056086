import { all, call, fork, put, select, takeLatest, delay } from 'redux-saga/effects'
import businessApprovalsSage from "../BusinessApprovals/sagas";
import procurementApprovalsSage from "../ProcurementApprovals/sagas";

import { PurchaseRequisitionService } from "../domains";
import * as Sentry from '@sentry/browser';
import { FetchPRTypeError, FetchPRTypeSuccess, PurchaseRequisitionAction } from "../action";
import approvalStatusSaga from "../ApprovalStatus/sagas/approvalStatus.sagas";
import approvalHistorySaga from '../ApprovalHistory/sagas/approvalHistory.sagas'
import prSubstitutionSage from '../PRSubstitution/sagas/prSubstitution.sagas'


export function* purchaseRequisition(action: any): any {
    const query = { SapPR: action.SapPR, ItemNo: action.ItemNo };
    try {
        const result = yield call(PurchaseRequisitionService.getPurchaseRequisitionType);
        yield put(new FetchPRTypeSuccess({ PurchaseRequisitionType: result }))
    } catch (error) {
        Sentry.captureException(error);
        yield put(new FetchPRTypeError({ error: error, message: error.message }))
    }
}


function* watchPurchaseRequisitionTypeQuery() {
    yield takeLatest(PurchaseRequisitionAction.FETCH_PR_TYPE, purchaseRequisition)
}


export default function* PurchaseRequisitionSage() {
    yield all([
        fork(watchPurchaseRequisitionTypeQuery),
        fork(businessApprovalsSage),
        fork(procurementApprovalsSage),
        fork(approvalStatusSaga),
        fork(approvalHistorySaga),
        fork(prSubstitutionSage),
    ])
};