import {CompetitiveBiddingData} from "../domains/competitiveBidding";
import {CompetitiveBiddingTypes} from "../actions/competitiveBiddings.actions";
import {IAction} from "../../shared/domains/core/actions";
import {loaded, loading} from "../../shared/reducers/utils";

export interface SummaryState {
  summary: CompetitiveBiddingData;
  loading: boolean;
  error: any;
}

export const initialSummaryState: SummaryState = {
  summary: null,
  loading: true,
  error: null
};

export function SummaryReducer(state: SummaryState = initialSummaryState, action: IAction<CompetitiveBiddingTypes>): SummaryState {
  switch (action.type) {
    case CompetitiveBiddingTypes.FETCH_SUMMARY:
      return loading(state);
    case CompetitiveBiddingTypes.FETCH_SUMMARY_SUCCESS:
      return loaded({summary: action.summary});
    case CompetitiveBiddingTypes.FETCH_SUMMARY_FAILURE:
      return loaded(state);
    default:
      return state;
  }
}
