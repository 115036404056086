import {
  Action,
  AzureErrorAction,
  DownloadCompletedAction,
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../shared/domains/core/actions";
import {CompetitiveBiddingData} from "../domains/competitiveBidding";
import {SuccessNotificationAction} from "../../shared/domains/core/notifications";
import {ConvertedPrice, PriceConversionRequestDTO} from "../../shared/services/price.service";
import DocumentFlow from "../../docFlows/domains/documentFlow/documentFlow";

export enum CompetitiveBiddingTypes {
  INITIAL_COMPETITIVEBIDDING = "INITIAL_COMPETITIVEBIDDING",
  INITIAL_COMPETITIVEBIDDING_SUCCESS = "INITIAL_COMPETITIVEBIDDING_SUCCESS",
  INITIAL_COMPETITIVEBIDDING_FAILURE = "INITIAL_COMPETITIVEBIDDING_FAILURE",

  CONVERPRICEREQUEST = "CONVERPRICEREQUEST",
  CONVERPRICEREQUEST_SUCCESS = "CONVERPRICEREQUEST_SUCCESS",
  CONVERPRICEREQUEST_FAILURE = "CONVERPRICEREQUEST_FAILURE",

  FETCHONE_COMPETITIVEBIDDING = "FETCHONE_COMPETITIVEBIDDING",
  FETCHONE_COMPETITIVEBIDDING_SUCCESS = "FETCHONE_COMPETITIVEBIDDING_SUCCESS",
  FETCHONE_COMPETITIVEBIDDING_FAILURE = "FETCHONE_COMPETITIVEBIDDING_FAILURE",

  FETCH_CB_CATS_L2 = "FETCH_CB_CATS_L2",
  FETCH_CB_CATS_L2_SUCCESS = "FETCH_CB_CATS_L2_SUCCESS",
  FETCH_CB_CATS_L2_FAILURE = "FETCH_CB_CATS_L2_FAILURE",

  FETCH_CB_CATS_L3 = "FETCH_CB_CATS_L3",
  FETCH_CB_CATS_L3_SUCCESS = "FETCH_CB_CATS_L3_SUCCESS",
  FETCH_CB_CATS_L3_FAILURE = "FETCH_CB_CATS_L3_FAILURE",
  FETCH_CB_PARENT_CATS_L3 = "FETCH_CB_PARENT_CATS_L3",
  FETCH_CB_PARENT_CATS_L3_SUCCESS = "FETCH_CB_PARENT_CATS_L3_SUCCESS",
  FETCH_CB_PARENT_CATS_L3_FAILURE = "FETCH_CB_PARENT_CATS_L3_FAILURE",

  FETCH_CB_SUPPLIERS = "FETCH_CB_SUPPLIERS",
  FETCH_CB_SUPPLIERS_SUCCESS = "FETCH_CB_SUPPLIERS_SUCCESS",
  FETCH_CB_SUPPLIERS_FAILURE = "FETCH_CB_SUPPLIERS_FAILURE",

  FETCH_SUMMARY = "FETCH_SUMMARY",
  FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS",
  FETCH_SUMMARY_FAILURE = "FETCH_SUMMARY_FAILURE",

  FETCH_CB_LIST = "FETCH_CB_LIST",
  FETCH_CB_LIST_REQUEST = "FETCH_CB_LIST_REQUEST",
  FETCH_CB_LIST_SUCCESS = "FETCH_CB_LIST_SUCCESS",
  FETCH_CB_LIST_FAILURE = "FETCH_CB_LIST_FAILURE",

  SAVE_COMPETITIVEBIDDING = "SAVE_COMPETITIVEBIDDING",
  SAVE_COMPETITIVEBIDDING_SUCCESS = "SAVE_COMPETITIVEBIDDING_SUCCESS",
  SAVE_COMPETITIVEBIDDING_FAILURE = "SAVE_COMPETITIVEBIDDING_FAILURE",

  UPLOAD_QUOTATION = "UPLOAD_QUOTATION",
  UPLOAD_QUOTATION_SUCCESS = "UPLOAD_QUOTATION_SUCCESS",
  UPLOAD_QUOTATION_FAILURE = "UPLOAD_QUOTATION_FAILURE",

  REMOVE_QUOTATION = "REMOVE_QUOTATION",

  PSL_STATUS = "PSL_STATUS",
  PSL_STATUS_SUCCESS = "PSL_STATUS_SUCCESS",
  PSL_STATUS_FAILURE = "PSL_STATUS_FAILURE",
  RESET_PSL_STATUS = "RESET_PSL_STATUS",

  LINK_PR_TO_CB = "LINK_PR_TO_CB",
  LINK_PR_TO_CB_SUCCESS = "LINK_PR_TO_CB_SUCCESS",
  LINK_PR_TO_CB_FAILURE = "LINK_PR_TO_CB_FAILURE",

  LINK_CB_TO_PR_LIST = "LINK_CB_TO_PR_LIST",
  LINK_CB_TO_PR_LIST_SUCCESS = "LINK_CB_TO_PR_LIST_SUCCESS",
  LINK_CB_TO_PR_LIST_FAILURE = "LINK_CB_TO_PR_LIST_FAILURE",

  GET_COMPLIANCE_ALERT = "GET_CB_COMPLIANCE_ALERT",
  GET_COMPLIANCE_ALERT_SUCCESS = "GET_CB_COMPLIANCE_ALERT_SUCCESS",
  GET_COMPLIANCE_ALERT_FAILURE = "GET_CB_COMPLIANCE_ALERT_FAILURE",
  CLOSE_COMPLIANCE_ALERT = "CLOSE_COMPLIANCE_ALERT",

  CLEAR_L2_L3_INDEX = "CLEAR_L2_L3_INDEX"
}

export class InitialCompetitiveBidding extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.INITIAL_COMPETITIVEBIDDING;

  constructor(public CompanyCode: string, public df?: DocumentFlow) {
    super();
  }
}

export class ConvertPriceRequest extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.CONVERPRICEREQUEST;

  constructor(public pricesToConvert: PriceConversionRequestDTO[], public done) {
    super();
  }
}

export class ConvertPriceRequestSuccess extends SuccessAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.CONVERPRICEREQUEST_SUCCESS;

  constructor(public pricesToConvert: ConvertedPrice[]) {
    super();
  }
}

export class ConvertPriceRequestFailure extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.CONVERPRICEREQUEST_FAILURE;
}

export class InitialCompetitiveBiddingSuccess extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.INITIAL_COMPETITIVEBIDDING_SUCCESS;

  constructor(public user, public catsL1, public companies, public currencies) {
    super();
  }
}

export class InitialCompetitiveBiddingError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.INITIAL_COMPETITIVEBIDDING_FAILURE;
}

export class FetchOneCompetitiveBidding extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCHONE_COMPETITIVEBIDDING;

  constructor(public id: string) {
    super();
  }
}

export class FetchOneCompetitiveBiddingSuccess extends SuccessAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCHONE_COMPETITIVEBIDDING_SUCCESS;

  constructor(public competitiveBidding: CompetitiveBiddingData) {
    super();
  }
}

export class FetchOneCompetitiveBiddingError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCHONE_COMPETITIVEBIDDING_FAILURE;
}

export class FetchCBCatL2 extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_CATS_L2;

  constructor(public parentId: number, public index: number) {
    super();
  }
}

export class FetchCBCatL2Success extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_CATS_L2_SUCCESS;

  constructor(public cats, public index: number) {
    super();
  }
}

export class FetchCBCatL2Error extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_CATS_L2_FAILURE;
}

export class FetchCBCatL3 extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_CATS_L3;

  constructor(public parentId: number, public index: number) {
    super();
  }
}

export class FetchCBCatL3Success extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_CATS_L3_SUCCESS;

  constructor(public cats, public index: number) {
    super();
  }
}

export class FetchCBCatL3Error extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_CATS_L3_FAILURE;
}

export class FetchCBParentCatL3 extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_PARENT_CATS_L3;

  constructor(public level3SpendCatCode: string, public index: number, public done) {
    super();
  }
}

export class FetchCBParentCatL3Success extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_PARENT_CATS_L3_SUCCESS;

  constructor(public cats) {
    super();
  }
}

export class FetchCBParentCatL3Error extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_PARENT_CATS_L3_FAILURE;
}

export class FetchCBSuppliers extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_SUPPLIERS;

  constructor(public companyCode) {
    super();
  }
}

export class FetchCBSuppliersSuccess extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_SUPPLIERS_SUCCESS;

  constructor(public vendors: {id: string, name: string}[]) {
    super();
  }
}

export class FetchCBSuppliersError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.FETCH_CB_SUPPLIERS_FAILURE;
}

export class SaveCompetitiveBiddingAction extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.SAVE_COMPETITIVEBIDDING;

  constructor(public competitiveBidding: CompetitiveBiddingData, public df?: DocumentFlow) {
    super();
  }
}

export class SaveCompetitiveBiddingSuccessAction extends SuccessNotificationAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.SAVE_COMPETITIVEBIDDING_SUCCESS;
}

export class SaveCompetitiveBiddingFailureAction extends AzureErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.SAVE_COMPETITIVEBIDDING_FAILURE;
}

export class UploadQuotation extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.UPLOAD_QUOTATION;

  constructor(public key, public index, public formData, public onDone) {
    super();
  }
}

export class UploadQuotationSuccess extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.UPLOAD_QUOTATION_SUCCESS;

  constructor(public key, public index, public fileName) {
    super();
  }
}

export class UploadQuotationError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.UPLOAD_QUOTATION_FAILURE;

  constructor(public key, public index, public error) {
    super();
  }
}

export class RemoveQuotation extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.REMOVE_QUOTATION;

  constructor(public key, public index) {
    super();
  }
}

export class StatusForVendor extends Action<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.PSL_STATUS;

  constructor(public supplierIndex: number, public vendorNo: string, public purchaseOrg: string, public l3code: string) {
    super();
  }
}

export class ResetStatusForVendor extends Action<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.RESET_PSL_STATUS;

  constructor(public supplierIndex: number, public pslStatus = null) {
    super();
  }
}

export class LinkPRtoCB extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.LINK_PR_TO_CB;

  constructor(public cbId: string, public df: DocumentFlow) {
    super();
  }
}

export class LinkPRtoCBSuccess extends SuccessNotificationAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.LINK_PR_TO_CB_SUCCESS;

  constructor(public data: any) {
    super();
  }
}

export class LinkPRtoCBError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.LINK_PR_TO_CB_FAILURE;
}

export class LinkCBtoPRlist extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.LINK_CB_TO_PR_LIST;

  constructor(
    public cbId: string,
    public dfs: DocumentFlow[],
    public done: Function
  ) {
    super();
  }
}

export class LinkCBtoPRlistSuccess extends SuccessNotificationAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.LINK_CB_TO_PR_LIST_SUCCESS;

  constructor(public data: any) {
    super();
  }
}
export class LinkCBtoPRlistError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.LINK_CB_TO_PR_LIST_FAILURE;
}

export class GetComplianceAlert extends PendingAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.GET_COMPLIANCE_ALERT;

  constructor(public formData) {
    super();
  }
}

export class GetComplianceAlertSuccess extends SuccessAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.GET_COMPLIANCE_ALERT_SUCCESS;

  constructor(public alert: boolean) {
    super();
  }
}

export class GetComplianceAlertError extends ErrorAction<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.GET_COMPLIANCE_ALERT_FAILURE;
}

export class CloseComplianceAlert extends Action<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.CLOSE_COMPLIANCE_ALERT;
}

export class ClearL2andL3ByIndex extends Action<CompetitiveBiddingTypes> {
  public readonly type = CompetitiveBiddingTypes.CLEAR_L2_L3_INDEX;

  constructor(public index: number) {
    super();
  }
}

