import * as React from "react";
import { useEffect, useReducer, useState, useRef } from "react";
import * as R from "ramda";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  CreationDteFilter,
  FilterChipsWithHover,
  FilterHeader,
  FilterSingleValueType2,
  FilterValues,
  NbsFilterDateRange,
} from "src/app/shared/components/filters/filters";
import { InputLabel } from "@material-ui/core";
import _ from "lodash";
import { FilterCategoryView } from "src/app/shared/domains/query/filter";
import {
  ApplyButton,
  FilterRow,
  Filters,
  FiltersContainer,
  FiltersContainerWrapper,
  ResetButton,
  SelectContainer,
  StyledButtons
} from "src/app/shared/components/filters/filters.styles";
import { CustomFastAsyncSelect, CustomFastSelect, CustomTextareaField } from "src/app/shared/components/form";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { DateTime } from "luxon";
import { Toolbar } from "src/app/shared/components/layout/styles";
import { defaultNbsMaintenanceFiltersData, NbsMaintenanceFilter, NbsMaintenanceFilterKeys } from "../helpers/constants";
import { useFetchNbsMaintenceReasonsCodes } from "../hooks/nbsCockpitCustomHooks";
import { setSelectedFiltersBykey } from 'src/app/shared/queryable/query';

export interface NbsInstructionsFiltersProps {
  dateFormat: string,
  onClose: Function;
  zone: string;
  updateFilters: Function;
  queryData: any;
  receivers: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start'
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      alignItems: "center"
    },
    rootRadio: {
      width: 19,
      height: 19,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 20
    },
    label: {
      fontSize: 13
    },
    radioSet: {
      display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", marginTop: "1rem"
    },
    expandPanel: {
      maxHeight: "2000px", overflowY: "hidden",
      '&& .MuiPaper-root': {
        boxShadow: 'none',
        border: "none"
      }
    },
    date: {
      display: 'flex'
    }
  })
);

const formatDate = (date) => {
  if (date) {
    if (date instanceof DateTime) {
      return date.toFormat('yyyyMMdd')
    }
    if (typeof (date) !== 'object') {
      return DateTime.fromFormat(date, 'yyyyMMdd')
    }
  }
  return null;
};

export const NbsInstructionsActiveFilters = ({ filters, setQueryObject }: { filters: any, setQueryObject: any }) => {

  const removeTheEntry = (filterSelected, filterToDelete) => {
    return filterSelected.filter(el => el["id"] !== filterToDelete.id);
  };

  const newDeleteFilter = (filter) => {
    const tempFilter = { ...filters };
    tempFilter[filter.kind].selected = removeTheEntry(tempFilter[filter.kind].selected, filter);
    setQueryObject(tempFilter)
  };

  const deleteSingleValueFilter = (key: string) => {
    const tempFilter = { ...filters };
    tempFilter[key].selected = null;
    setQueryObject(tempFilter)
  };

  return (
    <>
      <Filters>
        {
          NbsMaintenanceFilter.map((f, idx) => {
            if (!filters[f.key]) {
              return <div key={idx}></div>
            }
            switch (f.key) {
              case NbsMaintenanceFilterKeys.COMPANY_CODE:
                return <FilterChipsWithHover key={idx} kind={f.key} name={f.label}
                  source={filters[f.key].selected}
                  minimumItems={100} //to ensure this cannot be deleted
                  onDelete={newDeleteFilter} enableDeletion={false}
                />
              case NbsMaintenanceFilterKeys.INSTRUCTIONS:
                return filters[f.key]?.selected ?
                  <FilterSingleValueType2 key={idx} name={f.label}
                    // @ts-ignore
                    label={filters[f.key]?.selected}
                    onDelete={() => deleteSingleValueFilter(f.key)} /> : null;
              default:
                return <FilterChipsWithHover key={idx} kind={f.key} name={f.label}
                  source={filters[f.key].selected}
                  onDelete={newDeleteFilter} />
            }
          })
        }
      </Filters>
    </>
  )
};

export default function NbsInstructionsFilters(props: NbsInstructionsFiltersProps) {

  const { zone } = props;
  const classes = useStyles(props);

  // const [initalFiltersData, setFilters]: [any, any] = useState((props.queryData.filters) ? props.queryData.filters : defaultNbsMaintenanceFiltersData);
  const [currentFiltersData, setCurrentFiltersData] = useState((props.queryData.filters) ? props.queryData.filters : defaultNbsMaintenanceFiltersData);

  const { data: nbsMaintenanceReasonCodesData } = useFetchNbsMaintenceReasonsCodes(zone);

  useEffect(() => {
    setCurrentFiltersData((props.queryData.filters) ? props.queryData.filters : defaultNbsMaintenanceFiltersData);
  }, [props.queryData.filters]);

  const handleReset = () => {
    let tempFilters: any = defaultNbsMaintenanceFiltersData;
    tempFilters.CompanyCode.selected = currentFiltersData?.CompanyCode?.selected;
    props.updateFilters(tempFilters);
  }

  const handleChange = (value, d: FilterCategoryView) => {
    const newValues = value ? value.map(el => ({ title: el.title, id: (el.azureId && el.azureId !== 0) ? el.azureId : el.id })) : [];
    setCurrentFiltersData(R.set(R.lensPath([d.key, 'selected']), newValues, currentFiltersData));
  };

  const nbsTemplateFilterComponents = R.mapObjIndexed((d: FilterCategoryView, key: string) => {
    switch (d.key) {
      case NbsMaintenanceFilterKeys.PURCHASE_GROUP:
        return <FilterRow key={key}>
          <InputLabel>{d.label}</InputLabel>
          <SelectContainer>
            <CustomFastAsyncSelect
              isMulti={true}
              onLoadLimit={3}
              onLoad={x => MasterService.fetchPurchaseGroup(x.trim(), zone)}
              customDisplay={option => `${option.value} - ${option.label}`}
              initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
              onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
            />
          </SelectContainer>
        </FilterRow>;
      case NbsMaintenanceFilterKeys.PLANT:
        return <FilterRow key={key}>
          <InputLabel>{d.label}</InputLabel>
          <SelectContainer>
            <CustomFastAsyncSelect
              isMulti={true}
              onLoadLimit={1}
              onLoad={(x) => MasterService.fetchPlantsWithCompanyCodes(x.trim(), currentFiltersData[NbsMaintenanceFilterKeys.COMPANY_CODE]?.selected, zone).then(res => res.map(v => ({ value: v.PlantCode, label: v.PlantName })))}
              customDisplay={option => `${option.value} - ${option.label}`}
              initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
              onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)} />
          </SelectContainer>
        </FilterRow>;
      case NbsMaintenanceFilterKeys.MATERIAL_TYPE:
        return <FilterRow key={key}>
          <InputLabel>{d.label}</InputLabel>
          <SelectContainer>
            <CustomFastAsyncSelect
              isMulti={true}
              onLoadLimit={3}
              onLoad={x => MasterService.fetchMeterialTypes(x.trim().toUpperCase(), zone)}
              customDisplay={option => `${option.value} - ${option.label}`}
              initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
              onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
            />
          </SelectContainer>
        </FilterRow>;
      case NbsMaintenanceFilterKeys.PARKING_OR_BLOCKING_REASON:
        return <FilterRow key={key}>
          <InputLabel>{d.label}</InputLabel>
          <SelectContainer>
            {/* <CustomFastSelect
              isMulti={true}
              // customDisplay={option => `${option.value} - ${option.label}`}
              customDisplay={option => <span style={{ whiteSpace: 'nowrap' }}>{`${option.value} - ${option.label}`}</span>}
              initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
              options={nbsMaintenanceReasonCodesData ? nbsMaintenanceReasonCodesData : []}
              onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
            /> */}
            <CustomFastSelect
              isMulti={true}
              // customDisplay={option => `${option.value} - ${option.label}`}
              customDisplay={option => <span style={{whiteSpace: 'nowrap'}}>{`${Number(option.value) ? (option.value * 1).toString() : option.value} - ${option.label}`}</span>}
              initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
              options={nbsMaintenanceReasonCodesData ? nbsMaintenanceReasonCodesData : []}
              onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)} 
            />
          </SelectContainer>
        </FilterRow>;
      case NbsMaintenanceFilterKeys.RECEIVER:
        return <FilterRow key={key}>
          <InputLabel>{d.label}</InputLabel>
          <SelectContainer>
            <CustomFastSelect
              isMulti={true}
              initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
              options={props.receivers}
              onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
              maxHeight={175}
            />
          </SelectContainer>
        </FilterRow>;
      case NbsMaintenanceFilterKeys.INSTRUCTIONS:
        return <FilterRow key={key}>
          <InputLabel>{d.label}</InputLabel>
          <CustomTextareaField
            placeholder={'Type Instructions to Search'}
            rows={1}
            rowsMax={'Infinity'}
            initialValue={currentFiltersData[d.key]?.selected || ""}
            onChange={(v) => setCurrentFiltersData(R.set(R.lensPath([d.key, 'selected']), v, currentFiltersData))} />
        </FilterRow>;
      default:
        return null;
    }
  }, currentFiltersData);

  const scrollToRef = useRef(null);

  useEffect(() => {
    scrollToRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <>
      <Toolbar>
        <FilterHeader
          onCloseClick={() => props.onClose(false)}
        />
      </Toolbar>
      <FiltersContainerWrapper ref={scrollToRef}>
        <FiltersContainer className={classes.root}>
          {
            R.keys(nbsTemplateFilterComponents).map(o => nbsTemplateFilterComponents[o])
          }
          <FilterRow style={{ padding: "0 0 20px 0" }}>
            <br />
            <StyledButtons>
              <ApplyButton
                onClick={() => {
                  props.updateFilters(currentFiltersData)
                }}
                disabled={currentFiltersData[NbsMaintenanceFilterKeys.COMPANY_CODE]?.selected?.length > 0 ? false : true}
                variant="contained"
                color="primary"
                autoFocus>
                Apply
              </ApplyButton>
              <ResetButton
                onClick={handleReset}
                variant="contained"
                color="primary">
                Clear
              </ResetButton>
            </StyledButtons>
          </FilterRow>
        </FiltersContainer>
      </FiltersContainerWrapper>
    </>
  )
}