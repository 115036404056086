export enum MAType {
  SUCROSE = 'SUCROSE Master Agreement',
  COCOA = 'COCOA Master Agreement',
  GRAIN = 'GRAIN Master Agreement',
  COFFEE = 'COFFEE Master Agreement',
  DAIRY = 'DAIRY Master Agreement',
  OIL = 'OIL Master Agreement',
  AI = 'Agriculture Ingredients Master Agreement',
  PI = 'Processed Ingredients Master Agreement'
}

export enum ClmContractAzureStatus {
  Empty = '',
  Locked = 'Locked',
  Release = 'Release',
  PartialReleased = 'PartialReleased',
  RejectedReleased = 'RejectedReleased',
  Sending = 'Sending'
}

export enum ClmContractStatus {
  Empty = '',
  OnHold = 'OnHold',
  Draft = 'Draft',
  Unknown = 'Unknown'
}

export class CodeNameTerm {
  constructor(
    public readonly Code: string,
    public readonly Name: string
  ) {}

  static FromBackend({code, name}) {
    return new CodeNameTerm(code, name);
  }
}

export class PaymentTerm {
  static FromBackend({value, label}) {
    return new CodeNameTerm(value, label);
  }
}

interface NetworkContractClmLogDTO {
  contractId: string;
  changedBy: string;
  agreementNo: string;
  modifiedAt: string;
  isHeader: string;
  isHeaderChange: string;
  validityStart: string;
  validityEnd: string;
  paymentTerms: string;
  incoterm: string;
  incotermLocation: string;
  strategicBuyer: string;
  nVC: string;
  purchGroup: string;
  initialExpiryDate: string;
  isDelete: string;
  doNotPrint: string;
  itemNo: string;
  isNew: string;
  isDeleteItem: string;
  itemType: string;
  productId: string;
  targetQty: string;
  orderUnit: string;
  serialId: string;
  conditionItem: string;
  validFrom: string;
  validTo: string;
  plantId: string;
  awarded: string;
  netPrice: string;
  currency: string;
  per: string;
}

export class ContractClmLog {
  constructor(
    public readonly ContractId: string,
    public readonly ChangedBy: string,
    public readonly AgreementNo: string,
    public readonly ModifiedAt: string,
    public readonly IsHeader: string,
    public readonly IsHeaderChange: string,
    public readonly ValidityStart: string,
    public readonly ValidityEnd: string,
    public readonly PaymentTerms: string,
    public readonly Incoterm: string,
    public readonly IncotermLocation: string,
    public readonly StrategicBuyer: string,
    public readonly NVC: string,
    public readonly PurchGroup: string,
    public readonly InitialExpiryDate: string,
    public readonly IsDelete: string,
    public readonly DoNotPrint: string,
    public readonly ItemNo: string,
    public readonly IsNew: string,
    public readonly IsDeleteItem: string,
    public readonly ItemType: string,
    public readonly ProductId: string,
    public readonly TargetQty: string,
    public readonly OrderUnit: string,
    public readonly SerialId: string,
    public readonly ConditionItem: string,
    public readonly ValidFrom: string,
    public readonly ValidTo: string,
    public readonly PlantId: string,
    public readonly Awarded: string,
    public readonly NetPrice: string,
    public readonly Currency: string,
    public readonly Per: string
  ) {}

  static FromBackend(data: NetworkContractClmLogDTO): ContractClmLog {
    return new ContractClmLog(
      data.contractId,
      data.changedBy,
      data.agreementNo,
      data.modifiedAt,
      data.isHeader,
      data.isHeaderChange,
      data.validityStart,
      data.validityEnd,
      data.paymentTerms,
      data.incoterm,
      data.incotermLocation,
      data.strategicBuyer,
      data.nVC,
      data.purchGroup,
      data.initialExpiryDate,
      data.isDelete,
      data.doNotPrint,
      data.itemNo,
      data.isNew,
      data.isDeleteItem,
      data.itemType,
      data.productId,
      data.targetQty,
      data.orderUnit,
      data.serialId,
      data.conditionItem,
      data.validFrom,
      data.validTo,
      data.plantId,
      data.awarded,
      data.netPrice,
      data.currency,
      data.per
    )
  }
}

interface NetworkContractClmResultLog {
  contractId: string;
  clmResponse: string;
  eccResponse: string;
  status: string;
  createdOn: string;
}

export class ContractClmResultLog {
  constructor(
    public readonly ContractId: string,
    public readonly ClmResponse: string,
    public readonly EccResponse: string,
    public readonly Status: string,
    public readonly CreatedOn: string
  ) {
  }

  static FromBackend(data: NetworkContractClmResultLog): ContractClmResultLog {
    return new ContractClmResultLog(
      data.contractId,
      data.clmResponse,
      data.eccResponse,
      data.status,
      data.createdOn
    )
  }
}
