import "antd/dist/antd.css";
import { TreeSelect } from "antd";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import {useEffect} from "react";

const useStyles = makeStyles(() =>
    createStyles({
        treeSelectClass: {
            "&& div": {
                height: "100%"
            },
            "&& .ant-select-selector": {
                color: "rgba(0, 0, 0, 0.65)"
            }
        }
    })
);
const waysTreeData = [
    { title: "Standard Purchase Order", value: 1 },
    { title: "Framework Order", value: 2 },
    { title: "Capex Purchase Order", value: 3 },
];
export const WOBLabel = (value) => waysTreeData.find(x => x.value == value).title

export const WaysOfBuyingComponent = ({ onChange, initialValue, disabled = false, clear = false}) => {
    const classes = useStyles({});
    const [selectedpslScope, setSelectedpslScope] = React.useState(initialValue ? initialValue : []);

    useEffect(() => {
        if (clear) {
            setSelectedpslScope([]);
        }
    }, [clear]);

    const onChangeValues = (value: any[]) =>
    {
        setSelectedpslScope(value);
        onChange(value);
    };

    const userScopeProps = {
        treeData: waysTreeData,
        value: selectedpslScope,
        onChange: onChangeValues,
        disabled: disabled,
        treeCheckable: true,
        treeCheckStrictly: false,
        dropdownMatchSelectWidth: false,
        treeNodeFilterProp: "title",
        showCheckedStrategy: TreeSelect.SHOW_PARENT,
        placeholder: "Click here to select Ways of Buying",
        style: { width: "100%", minHeight: "38px" }
    };

    return (
        <TreeSelect
            {...userScopeProps}
            dropdownStyle={{ zIndex: 99999 }}
            className={classes.treeSelectClass}
        />
    );
}
