import {FeedbackReducer, FeedbackState} from "./feedback.reducer";
import {combineReducers, Reducer} from "redux";
import {Queryable} from "../../shared/queryable/query.reducer";
import {RatingReducer, RatingState} from "./rating.reducer";

export interface AdminStateStore {
  feedbackList: Queryable<FeedbackState>,
  ratingList: Queryable<RatingState>
}

export const AdminCombinedReducers: Reducer<AdminStateStore> = combineReducers({
  feedbackList: FeedbackReducer,
  ratingList: RatingReducer
});
