import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {
  PersonalNoteActions,
  GetInvoiceNoteRequest,
  GetInvoiceNoteRequestError,
  GetInvoiceNoteRequestSuccess,
  SaveInvoiceNoteRequest,
  SaveInvoiceNoteRequestError,
  SaveInvoiceNoteRequestSuccess
} from "../actions/invoicePersonalNote.actions";
import {DocflowNotesService} from "../../domains/docFlowNotes/docFlowNotes.service";

function* getInvoiceNotes(action: GetInvoiceNoteRequest): any {
  const {invoiceNumber, systemAlias} = action
  try {
    const result = yield call(DocflowNotesService.getInvoiceNotes, action.invoiceNumber);
    yield put(new GetInvoiceNoteRequestSuccess(invoiceNumber, systemAlias, result));
  } catch (error) {
    yield put(new GetInvoiceNoteRequestError(invoiceNumber, systemAlias));
  }
}

function* sendInvoiceNote(action: SaveInvoiceNoteRequest): any {
  const {invoiceNumber, systemAlias} = action
  try {
    const result = yield call(DocflowNotesService.sendInvoiceNote, action.invoiceNumber, action.note);
    yield put(new SaveInvoiceNoteRequestSuccess(invoiceNumber, systemAlias, result.note));
  } catch (error) {
    yield put(new SaveInvoiceNoteRequestError(invoiceNumber, systemAlias));
  }
}

function* watchInvoiceGetPersonalNote() {
  yield takeEvery(PersonalNoteActions.GET_INVOICE_NOTE_REQUEST, getInvoiceNotes);
}

function* watchInvoiceSavePersonalNote() {
  yield takeEvery(PersonalNoteActions.SAVE_INVOICE_NOTE_REQUEST, sendInvoiceNote);
}

export default function* invoicePersonalNoteSaga() {
  yield all([
    fork(watchInvoiceGetPersonalNote),
    fork(watchInvoiceSavePersonalNote)
  ])
};
