interface NetworkPslIdDTO {
    __metadata: { id: string; type: string; uri: string };
    PurchaseReq: string
    PSL_ID: string
}

export class PslID {
    constructor(
        public PurchaseReq: string,
        public PSL_ID: string
    ) {
    }

    static FromBackend(data: NetworkPslIdDTO): PslID {
        return new PslID(
            data.PurchaseReq,
            data.PSL_ID,
        )
    }

    static toSelect(data: PslID) {
        return {
            value: data.PSL_ID,
            label: data.PSL_ID
        }
    }
}
