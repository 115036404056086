import {AxiosWrapper} from "../../../shared/utils/axios.wrapper";

export class DocflowNotesService {
  static async getDocFlowNotes(prNo: string, poNo: string): Promise<any[]> {
    const path = `/api/DocFlowNotes/GetDocFlowPersonalNote?prId=${prNo}&poId=${poNo}`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async sendNote(prNo: string, poNo: string, note: string): Promise<any> {
    const path = `/api/DocFlowNotes/SaveDocFlowPersonalNote`;
    const result = await AxiosWrapper.post(path, {prId: prNo, poId: poNo, note: note});
    return result.data;
  }

  static async getInvoiceNotes(invoiceNumber: string): Promise<any[]> {
    const path = `/api/InvNotes/GetInvoiceNoteByUser?invoiceNumber=${invoiceNumber}`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async sendInvoiceNote(invoiceNumber: string, note: string): Promise<any> {
    const path = `/api/InvNotes/SaveInvoiceNoteByUser`;
    const result = await AxiosWrapper.post(path, {invoiceNumber: invoiceNumber, note: note});
    return result.data;
  }
}
