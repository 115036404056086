import * as R from 'ramda';
import {IAction} from "../domains/core/actions";
import {combineReducers, Reducer} from "redux";
import {NewFiltersAction, QueryAction, QueryActions, richActionName, WithListTypeAction} from "./query.actions";
import {newQuery, Query} from "./query";
import {sagaBuilder} from "./query.saga";
import {Saga} from '@redux-saga/core';
import {OrderDirection} from "../../../lib/odata";
import {DateRangeFilter, FilterCategoryView, LoadFilterData, TextFilter} from "../domains/query/filter";
import {Dictionary} from 'ramda';
import {explicitLoaded, loading} from "../reducers/utils";

export interface Queryable<T> {
  query: Query;
  data: T
}

export function queryReducer(domain: string, initialQuery: Query = newQuery()) {
  const actionName = richActionName(domain);
  return (state: Query = initialQuery, action: IAction<QueryActions>): Query => {
    switch (action.type) {
      case actionName(QueryActions.NEW_QUERY):
        return action.query;
      case actionName(QueryActions.SET_DEFAULT_QUERY_DOC):
        return action.query;
      case actionName(QueryActions.LOAD_FILTERS_SUCCESS):
        const filters = R.assoc('filters', R.mergeDeepRight(state.filters, action.filters), state);
        return explicitLoaded(filters);
      case actionName(QueryActions.LOAD_FILTERS):
        return loading(state);
      default:
        return state;
    }
  }
}

export const initialQueryStateWith = <T>(state: T, query: Query = newQuery()): Queryable<T> => ({
  query: query,
  data: state,
});

export interface QueryableAction {
  onLoadFilters: string,
  resetFilters: new(list?: string) => WithListTypeAction,
  onDeleteFilter: new(filter: TextFilter, list?: string | object) => WithListTypeAction,
  onLoadFiltersSuccess: new(filters: Dictionary<LoadFilterData>) => QueryAction,
  onLoadFiltersError: new(error: any) => QueryAction,
  newPage: new(page: number, list?: string) => QueryAction,
  newRowsPerPage: new(rows: number, list: string) => QueryAction,
  newPageSize: new(pageSize: number, list?: string) => QueryAction,
  newFilters: new(filters: Dictionary<FilterCategoryView>, list?: string | object, skipPersist?: boolean) => NewFiltersAction,
  newFilter: new(filter: TextFilter | DateRangeFilter, key: string) => QueryAction,
  resetFiltersForKey: new(key: string) => QueryAction,
  newTerm: new(term: string, list?: string) => QueryAction,
  newOrder: new(column: string, direction: OrderDirection, list?: string) => WithListTypeAction,
  onNewQuery: string;
  onSaveFilters: string;
  setDefaultDocQuery: any;
}

export interface QueryableSagasActions {
  sagas: Saga,
  actions: QueryableAction,
  saveFilters?: (filters) => any;
}

export interface QueryableComponents<T> extends QueryableSagasActions {
  reducer: Reducer<Queryable<T>>,
}

export const QueryableReducer = <T>(domain: string, stateSelector: string[], reducer: Reducer<T, IAction<any>>, initialQuery: Query = newQuery()): QueryableComponents<T> => {
  const builtSaga = sagaBuilder(stateSelector, richActionName(domain));
  return ({
    reducer: combineReducers({
      query: queryReducer(domain, initialQuery),
      data: reducer,
    }),
    sagas: builtSaga.sagas,
    actions: builtSaga.actions
  });
};
