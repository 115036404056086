import { assoc, flatten, filter, merge } from "ramda";
import { ProcurementApprovalActionTypes } from "../actions/purchaseRequest.action";
import { IAction } from "../../../shared/domains/core/actions";
import {
  purchaseRequestQuery,
  ODataQuery
} from "../domains/purchaseRequest/query";
import { PurchaseRequest } from "../domains/purchaseRequest/purchaseRequest";
import { PAActionTypes } from "../actions/paFilters";

export interface PurchaseRequestState {
  query: ODataQuery;
  purchaseRequestList: PurchaseRequest[];
  loading: boolean;
  itemLoading: boolean;
  error: any;
  totalCount: number;
  selectedPurchaseRequest: PurchaseRequest;
  purchaseRequestInfo: any;
  purchaseRequestAccount: any;
  purchaseRequestPricing: any;
  approvalIndicator: any;
  purchaseRequestLineList: PurchaseRequest[];
  defaultAliys: string
}

export const initialPurchaseRequestState: PurchaseRequestState = {
  query: purchaseRequestQuery(),
  purchaseRequestList: [],
  loading: true,
  itemLoading: true,
  error: null,
  totalCount: 0,
  selectedPurchaseRequest: null,
  purchaseRequestInfo: null,
  purchaseRequestAccount: [],
  purchaseRequestPricing: [],
  purchaseRequestLineList: [],
  approvalIndicator: null,
  defaultAliys: null
};

const loading = { loading: true, error: null };
const emptyAndLoading = {
  loading: true,
  error: null,
  purchaseRequestList: [],
  totalCount: 0
};

export function PurchaseRequestReducers(
  state: PurchaseRequestState = initialPurchaseRequestState,
  action: IAction<ProcurementApprovalActionTypes | PAActionTypes>
): PurchaseRequestState {
  switch (action.type) {
    case ProcurementApprovalActionTypes.FETCH_PAS:
      return {
        ...state,
        loading: true,
        purchaseRequestList: [],
        totalCount: 0
      };
    case ProcurementApprovalActionTypes.FETCH_PAS_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseRequestList: action.items,
        totalCount: action.totalCount ? action.totalCount : 0,
        query: action.newQuery
      };
      case ProcurementApprovalActionTypes.FETCH_PAS_FAILURE:
        const failurePASList = { error: action.error, loading: false , totalCount: 0, purchaseRequestList: []};
        return merge(state, failurePASList);
        
    case ProcurementApprovalActionTypes.NEW_PA_QUERY:
      assoc("query", action.query, state);
      const loaderState = { loading: true };
      return merge(state, loaderState);
    case ProcurementApprovalActionTypes.SET_SELECTED_PRS:
      const partialState = {
        selectedPurchaseRequest: action.purchaseRequest,
        loading: false
      };
      return merge(state, partialState);
    case ProcurementApprovalActionTypes.FETCH_PAS_DETAIL:
      return {
        ...state,
        itemLoading: true
      };
    case ProcurementApprovalActionTypes.FETCH_PAS_DETAIL_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        purchaseRequestInfo: action.info,
        purchaseRequestAccount: action.accountList,
        purchaseRequestPricing: action.pricing,
        approvalIndicator: action.approvalIndicator,
        purchaseRequestLineList: action.prLineList
      };
    case ProcurementApprovalActionTypes.FETCH_PAS_LINES:
      return {
        ...state,
        itemLoading: true
      };
    case ProcurementApprovalActionTypes.FETCH_PAS_LINES_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseRequestLineList: action.prLineList
      };
    case ProcurementApprovalActionTypes.FETCH_PAS_LINES_FAILURE:
      const failurePartialState = { error: action.error, loading: false };
      return merge(state, failurePartialState);

    case PAActionTypes.PA_NEW_FILTER:
    case PAActionTypes.DELETE_PA_FILTER:
    case PAActionTypes.RESET_PA_FILTERS:
      return {
        ...state,
        loading: true
      };
    case PAActionTypes.UPDATE_PA_FILTER_SUCCESS:
    case PAActionTypes.UPDATE_PA_FILTER_FAILURE:
      
      return {
        ...state,
        loading: false
      };
    case ProcurementApprovalActionTypes.PA_DEFAULT_ALIYS:
      return {
        ...state,
      };
    case ProcurementApprovalActionTypes.PA_DEFAULT_ALIYS_SUCCESS:
      return {
        ...state,
        defaultAliys: action.aliys
      };
    default:
      return state;
  }
}
