import * as R from 'ramda';
import {isNumeric} from "rxjs/internal-compatibility";
import {DateTime} from "luxon";
import {User} from "../../shared/domains/user/user";
import {IMaterial} from "./pslMaterial";
import { PataflagService } from 'src/app/shared/services/pataflag.service';
import { Features } from 'src/app/shared/domains/core/pataflag';

export const PSL_ABOVE_MARKET = 1
export const PSL_AT_MARKET = 0
export const PSL_PPA_AT_MARKET = 5
export const PSL_PPA_ABOVE_MARKET = 6

export enum PSL_SCOPE {
  AT_MARKET= PSL_AT_MARKET,
  ABOVE_MARKET = PSL_ABOVE_MARKET,
  PPA_ABOVE_MARKET = PSL_PPA_ABOVE_MARKET
}

export enum SupplierTabs {
  PSL = "Suppliers",
  MATERIALS = "Materials",
  MY_TASKS = "My Tasks"
}

export enum SupplierFilterTabs {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  EXPIRING = "EXPIRING",
  ARCHIVED = "ARCHIVED",
  OVERALL = "OVERALL"
}

export enum PendingOptions {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  PENDING_LOCALIZATION = "PENDING_LOCALIZATION"
}

export enum ArchivedOptions {
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  OBSOLETE = "OBSOLETE",
}

export enum MyTasksAtMarketTabs {
  COMPLIANCE = "COMPLIANCE",
  APPROVAL = "APPROVAL",
  LOCALIZATION = "LOCALIZATION",
  CAN_LOCALIZATION = "CAN LOCALIZE",
  CANNOT_LOCALIZATION = "CANNOT LOCALIZE",
  CANLOCALIZATION = "CAN LOCALIZATION",
  CANNOTLOCALIZATION = "CANNOT LOCALIZATION",
  JUSTIFIED = 'JUSTIFIED',
  NOTJUSTIFIED = 'NOTJUSTIFIED'

}
export enum PslStatus
{
  Pending = 0,
  Approved = 1,
  Rejected = 2,
  PendingToAcceptChanges = 3
}
export enum PslComplianceStatus
{
  Overall = 0,
  Compliance = 10,
  Justified = 1,
  NotJustified = 2,
  Localization = 13,
  canLocalize = 14,
  CannotLocalize = 15,
  Approval = 99
}
export enum SupplierStatus {
  ALL = 0,
  MANDATORY = 1,
  SOLE_SOURCE = 2,
  VALIDATED = 3,
  BLANK = 4
}

export const getScope = (id: number) => id === 0 ? "At Market Level" : "Above Market Level"

export interface IdNamePair {
  id: number,
  name: string
}

export interface IdTitlePair {
  id: number,
  title: string
}

export interface CodeNamePair {
  code: string,
  name: string
}

export interface IdNameCode extends IdNamePair {
  code: string
}

export interface IdNameCompanyCode extends IdNamePair {
  companyCodes: string
}
export interface IPslVendorWithMaterials {
  vendor: ISupplier,
  materials: IMaterial[]
}
export interface IParentSupplier {
  agreements?: string,
  tenderNumber?: string,
  modifiedOn?: string,
}

export interface ISupplier {
  pslId?: number,
  pslIdWithPrefix?:string,
  statusId?: number,
  pslStatus?: PslStatus,
  statusName?: string,
  supplierCode?: string,
  supplierName?: string,
  childSupplierCode?: string,
  childSupplierName?: string,
  parentCode?: string,
  parentName?: string,
  businessUnits?: IdNamePair[],
  zones?: IdNamePair[],
  markets?: IdNamePair[],
  companyCodes?: IdNameCode[],
  plants?: CodeNamePair[],
  isAboveMarket?: boolean,
  spendCat1Id?: number,
  spendCat1DisplayValue?: string,
  spendCat1Code?: string,
  spendCat2Id?: number,
  spendCat2DisplayValue?: string,
  spendCat2Code?: string,
  spendCat3Id?: number,
  spendCat3Code?: string,
  spendCat3DisplayValue?: string,
  materialNumber?: string,
  materialName?: string,
  priceList?: string,
  keyWords?: string[],
  wayOfBuying?: string,
  validFrom?: string,
  validTo?: string,
  purchasingOrgCode?: string,
  purchasingOrgName?: string,
  plantCode?: string,
  plantName?: string,
  strategicBuyerEmailAddress?: string,
  createdOn?: string, //2020-05-21T07?:09?:18.877Z
  createdByUser?: { id: string, username: string, email: string },
  modifiedOn?: string, //2020-05-21T07?:09?:18.877Z
  modifiedByUser?: { id: string, username: string, email: string },
  systemAlias?: string,
  pslVendorContactName?: string,
  pslVendorContactUrl?: string,
  pslVendorContactEmail?: string,
  pslVendorContactPhone?: string,
  shortDescription?: string,
  itemDescription?: string,
  supplierPartId?: string,
  unitOfMeasure?: string,
  unitPrice?: number,
  pricePer?: number,
  currencyId?: number,
  yearlySpendCurrencyCode?: string,
  languageId?: number,
  languageTag?: string,
  agreements?: string,
  teamName?: string,
  imageUrl?: string,
  thumbnailUrl?: string,
  supplierAuxiliaryID?: string,
  plantGroup?: string,
  catalogId?: string,
  noteRequester?: string,
  noteLocalProcurement?: string,
  tenderNumber?: string,
  relatedToEasyBuy?: boolean,
  deleted?: boolean,
  statusJustification?: string,
  statusAttachments?: Array<string>
  rejectionComment?: string,
  currency?: string,
  yearlySpend?: number,
  scope?: number,
  parentId?: number,
  approvers?: string[],
  diversity?:Array<{code: string, text: string, defined: boolean}>
  marketContactName?:string,
  parentVendor?: IParentSupplier | any,
  isParentApproved?: boolean,
  isParentDeleted?: boolean,
  isFavorite?: boolean,
  iseditable?: boolean,
  localizer?: null | any[],
  referenceId?: string
}

export interface VendorInfo {
  code: string
  description: string
  street: string
  city: string
  district: string
  postalCode: string
  country: string
  region: string
  taxJurisdiction: string
  companies: Company[]
  purchasingOrgs: PurchasingOrg[]
}

export interface Company {
  code: string
  name: string
}

export interface PurchasingOrg {
  code: string
  name: string
}

export class PSL {
  public static readonly FieldSeparator = "||"
  public psl: ISupplier;

  constructor(psl: ISupplier | IMaterial) {
    this.psl = psl
  }

  static StatuesFilterable() {
    return R.keys(SupplierStatus).filter((k=>!isNumeric(k))).map(key => ({id: SupplierStatus[key], title: key}))
  }
  static StatuesSelectable(): Array<{value: SupplierStatus, label: string}> {
    return [
      SelectBlank,
      SelectMandatory,
      SelectSoleSource,
      SelectValidated,
    ];
  }
  static GetSelectedOption(id: number): {value: SupplierStatus, label: string} {
    switch(id) {
      case SupplierStatus.MANDATORY:
        return SelectMandatory;
      case SupplierStatus.SOLE_SOURCE:
        return SelectSoleSource;
      case SupplierStatus.VALIDATED:
        return SelectValidated;
      default:
        return SelectBlank;
    }
  }
  getParentTenderNumber = () => this.psl?.parentVendor?.tenderNumber;
  getChildTenderNumber = () => this.psl?.tenderNumber;
  getParentAgreements = () => this.psl?.parentVendor?.agreements;
  getParentModifiedDate = () => this.psl?.parentVendor?.modifiedOn;
  getChildAgreements = () => this.psl?.agreements;
  isAboveMarket = () => this.psl.scope === PSL_ABOVE_MARKET || this.psl.scope === PSL_PPA_ABOVE_MARKET || this.psl.scope === 3
  isAtMarket = () => (this.psl.scope === PSL_AT_MARKET || this.psl.scope === PSL_PPA_AT_MARKET)
  isChildPsl = (): boolean => this.psl.parentId > 0 && this.psl.scope === PSL_AT_MARKET
  isChildPslNotLocalized = (): boolean => this.isChildPsl() && this.psl.pslStatus === PslStatus.Pending;
  isChildPslWithPendingChanges = (): boolean => this.isChildPsl() && this.psl.pslStatus === PslStatus.PendingToAcceptChanges;
  isChildPslWithAnyPendingChanges = (): boolean => this.isChildPsl() && (this.psl.pslStatus === PslStatus.PendingToAcceptChanges || this.psl.pslStatus === PslStatus.Pending);
  isChildPslActive = (): boolean => this.isChildPsl() && this.psl.pslStatus === PslStatus.Approved;
  isChildPslDeleted = (): boolean => this.isChildPsl() && this.psl.deleted === true;
  isObsoleteChildPsl = (): boolean => this.isChildPsl() && this.psl.isParentDeleted === true;

  isArchived = () => this.psl.deleted || this.psl.validTo && DateTime.fromJSDate(new Date(this.psl.validTo)).toISODate() < DateTime.local().toISODate();
  isOnlyArchived = () => this.psl.validTo && DateTime.fromJSDate(new Date(this.psl.validTo)).toISODate() < DateTime.local().toISODate();

  isEditable(user: User, isReactivation: boolean = false) {
    if (this.isArchived() && !isReactivation) {
      return false
    }
    if (user.hasAboveMarketRole() && this.isAboveMarket()) {
      return true
    }
    if ((user.isAtMarketUser() || user.hasMaintenance()) && this.isAtMarket()) {
      return true
    }
    return false
  }

  isParentPslAndAboveMarketUser(user: User) {
    if (user.hasAboveMarketRole() && this.isAboveMarket()) {
      return true
    }
    return false
  }
}

export const SelectBlank = {value: SupplierStatus.BLANK, label: '‎‎‎‎‏‏‎ ‎'};
export const SelectMandatory = {value: SupplierStatus.MANDATORY, label: 'MANDATORY'};
export const SelectSoleSource = {value: SupplierStatus.SOLE_SOURCE, label: 'SOLE SOURCE'};
export const SelectValidated = {value: SupplierStatus.VALIDATED, label: 'VALIDATED'};

export const getFormStatus = (psl: ISupplier|IMaterial, pslExpiryDays: number = 30) => {
  const PSL_163225_COLUMN_STATUS = PataflagService.getInstance().flag(Features.PSL_163225_COLUMN_STATUS);
  if (psl === undefined) {
    return "";
  }
  if (psl.isParentDeleted)
      return "Obsolete"
  if ("deleted" in psl && psl.deleted || "materialDeleted" in psl && psl.materialDeleted) {
    return 'Deleted';
  }
  if (DateTime.fromJSDate(new Date(psl.validTo)).toISODate() < DateTime.local().toISODate()) {
    return 'Expired';
  }
  if (psl.pslStatus === PslStatus.Pending || psl.pslStatus === PslStatus.PendingToAcceptChanges || (psl.parentId != null && psl.pslStatus === PslStatus.Approved && psl.isParentApproved === false)) {
    if (psl.isParentApproved) {
      if (PSL_163225_COLUMN_STATUS) {
        return psl?.childSupplierCode !== null ? 'Pending Localization' : 'Pending Localization-VFT Maintenance';
      } else {
        return 'Pending Localization'
      }
    };
    if (!psl.isParentApproved) {
      if (PSL_163225_COLUMN_STATUS) {
        return psl.pslIdWithPrefix.charAt(0) === "C" ? 'Parent Pending Approval' : 'Pending Approval';
      } else {
        return 'Pending Approval'
      }
    }
    return 'Pending';
  }
  if (psl.pslStatus === PslStatus.Rejected) {
    return 'Rejected';
  }
  if (DateTime.fromJSDate(new Date(psl.validTo)).toISODate() < DateTime.local().plus({days: pslExpiryDays}).toISODate()) {
    return 'Expiring';
  }
  return 'Active';
};
