import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  FullWidthHeightLoading,
  FullWidthLoading,
  LabelLoading,
  LoadingContainer,
  MarginLoadingContainer,
  StyledPopupLoading,
  TableLoadingContainer
} from "./loading.styles";
import {Typography} from "@material-ui/core";

export function LoadingComponent() {
  return (
    <LoadingContainer><CircularProgress/></LoadingContainer>
  )
}

export function FullWidthLoadingComponent({text = ""}) {
  return (
    <FullWidthLoading>
      <CircularProgress/>
      {text && <LabelLoading><Typography variant="caption">{text}</Typography></LabelLoading>}
    </FullWidthLoading>
  )
}

export function FullSizeLoading({text = ""}) {
  return (
    <FullWidthHeightLoading>
      <CircularProgress/>
      {text && <LabelLoading><Typography variant="caption">{text}</Typography></LabelLoading>}
    </FullWidthHeightLoading>
  )
}

export function MarginLoadingComponent() {
  return (
    <MarginLoadingContainer><CircularProgress/></MarginLoadingContainer>
  )
}

export function TableLoadingComponent() {
  return (
    <TableLoadingContainer><CircularProgress/></TableLoadingContainer>
  )
}

export function PopupLoading({text}) {
  return (
    <StyledPopupLoading>
      <CircularProgress/>
      {text && <LabelLoading><Typography variant="caption">{text}</Typography></LabelLoading>}
    </StyledPopupLoading>
  )
}

export function SmallCircularLoader() {
  return (
    <CircularProgress style={{width: '24px', height: '24px'}}/>
  )
}
