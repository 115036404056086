import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {MTableCell} from "material-table";
import {createStyles, makeStyles} from "@material-ui/core";

export const StyledGrid = styled(Grid)`
  padding-left: 20px;
`;

export const StyledCell = styled(MTableCell)`
  && {
    padding: 0px 5px 0px 5px
  } 
`;

export const useTableStyles = makeStyles(() =>
  createStyles({
    divider:{
      backgroundColor: '#FFFFFF !important',
      height: '15px',
    },
    subTableContainer: {
      backgroundColor: '#FFFFFF !important',
      maxHeight: '550px',
      borderLeft: '1px solid #F9F9F9',
      borderRight: '1px solid #F9F9F9',
      // overflow: 'auto',
      '&& .MuiTableRow-root': {
        height: 'unset !important'
      },
      '&& .MuiTableBody-root .MuiTableRow-root': {
        backgroundColor: 'rgb(241, 145, 0, .1)',
      },
      '&& .MuiTabs-flexContainer':{
        display: "flex",
        paddingLeft: "30px",
        alignItems: "flex-end"
      },
      '&& .MuiTab-labelIcon':{
        minHeight: "48px"
      }
    },
    docFlowTableClass: {
      '&& .MuiTableRow-root': {
        height: 'unset !important',
        backgroundColor: '#F9F9F9'
      },
      '&& .MuiPaper-root': {
        padding: '0 25px',
        boxShadow: 'none'
      },
      '&& .MuiTable-root': {
        borderCollapse: 'separate',
        borderSpacing: '0 10px'
      },
      '&& .MuiTableCell-root': {
        border: 'none'
      },
      '&& .MuiTableCell-body': {
        color: '#777777',
        fontSize: '15px'
      },
      '&& .MuiIconButton-colorSecondary': {
        color: '#2689EB'
      },
      '&& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)' 
        // !important'
      },
      '&& .MuiTableCell-paddingNone:last-child': {
        padding: "16px",
      }
    },

    notesButton:{
      border: "none", 
      backgroundColor: "transparent", 
      outline: "0", 
      cursor: 'pointer',
      borderRadius: '50%',
      height: '48px',
      width: '48px',
      "&:hover":{
        backgroundColor: '#d9d9d9'
      }
    },

    removeBorderForCheckBox: {
      '&& .MuiTableCell-paddingNone': {
        padding: "16px",
        border: 'none !important'
      }
    }

  })
);


