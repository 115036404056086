import React from 'react';

export const EasyRequestIcon = ({ stroke, fill, width, height }: { stroke: string, fill: string, width: string, height: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48.73 31.403">
            <g id="Group_74677" data-name="Group 74677" transform="translate(-643.625 -351.398)">
                <path id="Path_38578" data-name="Path 38578" d="M-141.335,11256.9h7.438l6.021,20.072h17.12l6.612-13.932h-19.127" transform="translate(795 -10903.999)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <circle id="Ellipse_415" data-name="Ellipse 415" cx="2.348" cy="2.348" r="2.348" transform="translate(680.803 378.105)" fill={stroke} />
                <circle id="Ellipse_416" data-name="Ellipse 416" cx="2.348" cy="2.348" r="2.348" transform="translate(666.713 378.105)" fill={stroke} />
                <line id="Line_656" data-name="Line 656" x1="9" transform="translate(645.125 365.021)" fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth="3" />
                <line id="Line_657" data-name="Line 657" x1="9" transform="translate(648.125 372.971)" fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth="3" />
            </g>
        </svg>
    )
}

export const RequestCockpitIcon = ({ stroke, fill, width, height }: { stroke: string, fill: string, width: string, height: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48.753 45.245">
            <g id="Group_74675" data-name="Group 74675" transform="translate(-642.748 -339.5)">
                <path id="Path_38576" data-name="Path 38576" d="M-152.4,11266.122s-.034-2.887,0-5.24.822-5.027,3.978-7.631a12.908,12.908,0,0,1,8.646-2.782c2.233-.035,32.361,0,32.361,0" transform="translate(796.161 -10904)" fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth="3" />
                <path id="Union_32" data-name="Union 32" d="M801-10898l-6,6Zm-6-6,6,6Z" transform="translate(-110.5 11244.5)" fill={"#F19001"} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <path id="Path_38577" data-name="Path 38577" d="M-107.426,11250.452s.034,2.887,0,5.24-.822,5.027-3.978,7.631a12.908,12.908,0,0,1-8.646,2.782c-2.233.035-32.361,0-32.361,0" transform="translate(797.911 -10888.33)" fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth="3" />
                <path id="Union_33" data-name="Union 33" d="M6,6,0,12ZM0,0,6,6Z" transform="translate(649.751 383.745) rotate(180)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <g id="Group_74676" data-name="Group 74676">
                    <path id="Path_38578" data-name="Path 38578" d="M-141.335,11256.9h4.751l3.846,12.821H-121.8l4.223-8.9H-129.8" transform="translate(795 -10904)" fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth="2.5" />
                    <circle id="Ellipse_415" data-name="Ellipse 415" cx="1.5" cy="1.5" r="1.5" transform="translate(671 369)" fill={stroke} />
                    <circle id="Ellipse_416" data-name="Ellipse 416" cx="1.5" cy="1.5" r="1.5" transform="translate(662 369)" fill={stroke} />
                </g>
            </g>
        </svg>
    )
}

export const NbsCockpitIcon = ({ fill, width, height }: { fill: string, width: string, height: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <g id="icon-nbs_cockpit-opt2" transform="translate(-1672 -353)">
                <rect id="Rectangle_1809" data-name="Rectangle 1809" width={width} height={height}
                    transform="translate(1672 353)" fill={fill} opacity="0" />
                <g id="Layer_1-2" transform="translate(1674.001 355)">
                    <path id="Path_1" d="M.76.66a.755.755,0,0,1,.2.03c.782.24,1.563.46,2.345.69l.623.18a.894.894,0,0,1,.623.6l.063.19c.137.39.275.78.391,1.17a.776.776,0,0,0,.8.56H14.5a8.11,8.11,0,0,0,2.06,7.81,9.125,9.125,0,0,0,6.717,2.9,8.894,8.894,0,0,0,1.151-.07,12.3,12.3,0,0,1-.613,1.53,1.567,1.567,0,0,1-.961.77,1.865,1.865,0,0,1-.433.04H8.629a.543.543,0,0,0-.454.24.481.481,0,0,0-.021.49l.063.11c.053.09.1.17.127.22a3.491,3.491,0,0,0,1.669,1.57,3.936,3.936,0,0,0,1.521.32h10.34a.79.79,0,0,1,.76.55.983.983,0,0,1-.275,1.1.72.72,0,0,1-.422.15H11.291a4.733,4.733,0,0,1-3-1.05,6.53,6.53,0,0,1-1.9-2.59,14.22,14.22,0,0,1-.94-3.16c-.19-.95-.38-1.91-.581-2.86l-.243-1.21L4.214,8.91,3.8,6.88c-.211-1.07-.433-2.15-.665-3.27a.817.817,0,0,0-.475-.49l-.422-.15C1.732,2.8,1.215,2.61.7,2.46A.966.966,0,0,1,.053,1.23,1.052,1.052,0,0,1,.327.83.606.606,0,0,1,.76.65M15.23,3.56Zm9.939,10.51Z" transform="translate(0)" fill={fill} />
                    <path id="Path_2" d="M21.26,12.36A6.18,6.18,0,1,1,27.5,6.24h0a6.182,6.182,0,0,1-6.24,6.11Zm-1.03-5.6h-.08a.864.864,0,0,0-.1-.16c-.31-.31-.61-.62-.92-.92a.679.679,0,0,0-.96-.03l-.03.03c-.1.1-.2.21-.3.3a.7.7,0,0,0-.12.98.679.679,0,0,0,.12.12c.63.62,1.25,1.25,1.88,1.88a.635.635,0,0,0,.9.05c.02-.02.04-.03.05-.05.57-.56,1.14-1.13,1.7-1.69.85-.84,1.69-1.69,2.53-2.53a.683.683,0,0,0,.04-.97l-.03-.03c-.11-.11-.22-.21-.33-.33a.7.7,0,0,0-.99-.09l-.09.09c-1.05,1.07-2.12,2.13-3.18,3.19a.6.6,0,0,0-.09.15h0Z" transform="translate(1.339)" fill={fill} />
                    <path id="Path_3" d="M21.99,25.88a2.12,2.12,0,1,1-2.12-2.13,2.115,2.115,0,0,1,2.12,2.13h0Z" transform="translate(1.022)" fill={fill} />
                    <path id="Path_4" d="M11.38,28a2.13,2.13,0,1,1,2.13-2.13A2.125,2.125,0,0,1,11.38,28Z" transform="translate(0.533)" fill={fill} />
                </g>
            </g>
        </svg>
    )
}

export const NbsAttachmentDownloadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="download_FILL0_wght400_GRAD0_opsz48" d="M161.5-784a1.439,1.439,0,0,1-1.05-.45,1.439,1.439,0,0,1-.45-1.05v-3.575h1.5v3.575h13v-3.575H176v3.575a1.439,1.439,0,0,1-.45,1.05,1.439,1.439,0,0,1-1.05.45Zm6.5-3.825-4.825-4.825,1.075-1.075,3,3V-800h1.5v9.275l3-3,1.075,1.075Z" transform="translate(-160 800)" fill="#2a5699" />
        </svg>
    )
}

export const ContactOrganisationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-organisation" transform="translate(9846 -1268)">
                <g id="Rectangle_21333" data-name="Rectangle 21333" transform="translate(-9846 1268)" fill="#a0b0b6" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="apartment_FILL0_wght300_GRAD0_opsz24" d="M130-825.528v-8.791h2.6v-2.6h6.186v5.209h2.6v6.186h-4.884v-2.6h-1.628v2.6Zm.977-.977h1.628v-1.628h-1.628Zm0-2.6h1.628v-1.628h-1.628Zm0-2.6h1.628v-1.628h-1.628Zm2.6,2.6h1.628v-1.628h-1.628Zm0-2.6h1.628v-1.628h-1.628Zm0-2.6h1.628v-1.628h-1.628Zm2.6,5.209h1.628v-1.628h-1.628Zm0-2.6h1.628v-1.628h-1.628Zm0-2.6h1.628v-1.628h-1.628Zm2.6,7.814h1.628v-1.628h-1.628Zm0-2.6h1.628v-1.628h-1.628Z" transform="translate(-9974.698 2106.225)" fill="#a0b0b6" />
            </g>
        </svg>
    )
}

export const ContactDepartmentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-department" transform="translate(9846 -2185)">
                <g id="Rectangle_21334" data-name="Rectangle 21334" transform="translate(-9846 2185)" fill="#a0b0b6" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="meeting_room_FILL0_wght300_GRAD0_opsz24" d="M150-808.929v-.977h1.3V-820h5.86v.651h2.279v9.442h1.3v.977h-2.279v-9.442h-1.3v9.442Zm2.279-10.093v0Zm2.6,5.134a.555.555,0,0,0,.408-.168.555.555,0,0,0,.168-.408.555.555,0,0,0-.168-.408.555.555,0,0,0-.408-.168.555.555,0,0,0-.408.168.555.555,0,0,0-.168.408.555.555,0,0,0,.168.408A.555.555,0,0,0,154.886-813.888Zm-2.6,3.982h3.907v-9.116h-3.907Z" transform="translate(-9994.374 3006.464)" fill="#a0b0b6" />
            </g>
        </svg>
    )
}

export const ContactDesignationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-designation" transform="translate(9846 570)">
                <g id="Rectangle_21331" data-name="Rectangle 21331" transform="translate(-9846 -570)" fill="#a0b0b6" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="work_FILL0_wght300_GRAD0_opsz24" d="M101.178-828.6a1.136,1.136,0,0,1-.835-.342,1.136,1.136,0,0,1-.342-.835v-6.762a1.136,1.136,0,0,1,.342-.835,1.136,1.136,0,0,1,.835-.342h2.73v-1.1a1.136,1.136,0,0,1,.342-.835,1.136,1.136,0,0,1,.835-.342h2.2a1.136,1.136,0,0,1,.835.342,1.136,1.136,0,0,1,.342.835v1.1h2.73a1.136,1.136,0,0,1,.835.342,1.136,1.136,0,0,1,.342.835v6.762a1.136,1.136,0,0,1-.342.835,1.136,1.136,0,0,1-.835.342Zm0-.977H111.2a.191.191,0,0,0,.138-.063.192.192,0,0,0,.063-.138v-6.762a.191.191,0,0,0-.063-.138.192.192,0,0,0-.138-.063H101.178a.192.192,0,0,0-.138.063.191.191,0,0,0-.063.138v6.762a.192.192,0,0,0,.063.138A.191.191,0,0,0,101.178-829.578Zm3.707-8.139h2.6v-1.1a.192.192,0,0,0-.063-.138.192.192,0,0,0-.138-.063h-2.2a.192.192,0,0,0-.138.063.192.192,0,0,0-.063.138Zm-3.907,8.139v0Z" transform="translate(-9945.188 271.299)" fill="#a0b0b6" />
            </g>
        </svg>
    )
}

export const ContactPhoneIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-phone" transform="translate(9846 -3092)">
                <g id="Rectangle_21335" data-name="Rectangle 21335" transform="translate(-9846 3092)" fill="#a0b0b6" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="call_FILL0_wght300_GRAD0_opsz24" d="M150.381-808.929a8.778,8.778,0,0,1-3.7-.856,11.772,11.772,0,0,1-3.414-2.415,11.856,11.856,0,0,1-2.412-3.414,8.757,8.757,0,0,1-.856-3.7.672.672,0,0,1,.2-.493.659.659,0,0,1,.488-.2h2.124a.65.65,0,0,1,.435.161.708.708,0,0,1,.24.4l.373,1.916a1.1,1.1,0,0,1-.016.458.681.681,0,0,1-.2.322l-1.5,1.464a9.035,9.035,0,0,0,.83,1.258,12.4,12.4,0,0,0,1.01,1.133,11.234,11.234,0,0,0,1.14,1,11.443,11.443,0,0,0,1.3.855l1.461-1.474a.827.827,0,0,1,.37-.223,1.065,1.065,0,0,1,.451-.031l1.808.368a.765.765,0,0,1,.4.252.642.642,0,0,1,.156.423v2.111a.66.66,0,0,1-.2.488A.672.672,0,0,1,150.381-808.929Zm-8.7-7.275,1.162-1.112a.116.116,0,0,0,.041-.069.153.153,0,0,0,0-.081l-.283-1.455a.134.134,0,0,0-.044-.075.126.126,0,0,0-.081-.025h-1.393a.085.085,0,0,0-.063.025.085.085,0,0,0-.025.063,8.328,8.328,0,0,0,.219,1.356A8.292,8.292,0,0,0,141.677-816.2Zm5.665,5.627a6.7,6.7,0,0,0,1.351.461,7.657,7.657,0,0,0,1.314.19.085.085,0,0,0,.063-.025.085.085,0,0,0,.025-.063v-1.37a.126.126,0,0,0-.025-.081.134.134,0,0,0-.075-.044l-1.367-.278a.1.1,0,0,0-.066,0,.159.159,0,0,0-.059.041ZM141.677-816.2ZM147.342-810.577Z" transform="translate(-9984.536 3913.464)" fill="#a0b0b6" />
            </g>
        </svg>
    )
}

export const ContactEmailIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-email" transform="translate(9846 -347)">
                <g id="Rectangle_21332" data-name="Rectangle 21332" transform="translate(-9846 347)" fill="#a0b0b6" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="mail_FILL0_wght300_GRAD0_opsz24" d="M101.178-770.232a1.136,1.136,0,0,1-.835-.342,1.136,1.136,0,0,1-.342-.835v-7.413a1.136,1.136,0,0,1,.342-.835,1.136,1.136,0,0,1,.835-.342H111.2a1.136,1.136,0,0,1,.835.342,1.136,1.136,0,0,1,.342.835v7.413a1.136,1.136,0,0,1-.342.835,1.136,1.136,0,0,1-.835.342Zm5.009-4.521-5.209-3.331v6.674a.2.2,0,0,0,.056.144.2.2,0,0,0,.144.056H111.2a.2.2,0,0,0,.144-.056.2.2,0,0,0,.056-.144v-6.674Zm0-1.014,5.109-3.256H101.078Zm-5.209-2.317v6.674a.2.2,0,0,0,.056.144.2.2,0,0,0,.144.056h-.2Z" transform="translate(-9945.188 1129.115)" fill="#a0b0b6" />
            </g>
        </svg>
    )
}

export const ContactChatIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-chat" transform="translate(11274 -2183)">
                <rect id="Rectangle_21773" data-name="Rectangle 21773" width="14" height="14" transform="translate(-11274 2183)" fill="#a0b0b6" opacity="0.002" />
                <path id="chat_FILL0_wght300_GRAD0_opsz24" d="M102.369-852.894h4.737v-.947h-4.737Zm0-1.895h7.263v-.947h-7.263Zm0-1.895h7.263v-.947h-7.263ZM100-848.291v-10.567a1.1,1.1,0,0,1,.332-.81,1.1,1.1,0,0,1,.81-.332h9.717a1.1,1.1,0,0,1,.81.332,1.1,1.1,0,0,1,.332.81v7.19a1.1,1.1,0,0,1-.332.81,1.1,1.1,0,0,1-.81.332h-8.623Zm1.832-3.182h9.027a.186.186,0,0,0,.134-.061.186.186,0,0,0,.061-.134v-7.19a.186.186,0,0,0-.061-.134.186.186,0,0,0-.134-.061h-9.717a.186.186,0,0,0-.134.061.186.186,0,0,0-.061.134v8.259Zm-.884,0v0Z" transform="translate(-11373.001 3044.145)" fill="#a0b0b6" />
            </g>
        </svg>
    )
}

export const ContactIdIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-id" transform="translate(11293 -2183)">
                <rect id="Rectangle_21335" data-name="Rectangle 21335" width="14" height="14" transform="translate(-11293 2183)" fill="#a0b0b6" opacity="0" />
                <path id="badge_FILL0_wght300_GRAD0_opsz24" d="M100.333-651.488a1.1,1.1,0,0,1-.332-.81v-6.559a1.1,1.1,0,0,1,.332-.81,1.1,1.1,0,0,1,.81-.332h3.279c.011,0,3.189.018,3.158,0h3.279a1.1,1.1,0,0,1,.81.332,1.1,1.1,0,0,1,.332.81v6.559a1.1,1.1,0,0,1-.332.81,1.1,1.1,0,0,1-.81.332S100.554-651.267,100.333-651.488Zm.81-.616h9.717a.189.189,0,0,0,.14-.055.189.189,0,0,0,.055-.14v-6.559A.189.189,0,0,0,111-659a.189.189,0,0,0-.14-.055H107.58v.243a.911.911,0,0,1-.28.667.911.911,0,0,1-.667.28h-1.263a.911.911,0,0,1-.667-.28.911.911,0,0,1-.28-.667v-.243h-3.279A.189.189,0,0,0,101-659a.189.189,0,0,0-.055.14v6.559a.189.189,0,0,0,.055.14A.189.189,0,0,0,101.143-652.1Zm1.166-1.445h3.6v-.187a.8.8,0,0,0-.135-.449.9.9,0,0,0-.373-.319,3.245,3.245,0,0,0-.627-.2,3.156,3.156,0,0,0-.663-.068,3.156,3.156,0,0,0-.663.068,3.245,3.245,0,0,0-.627.2.9.9,0,0,0-.373.319.8.8,0,0,0-.135.449Zm4.955-.96h2.526v-.753h-2.526Zm-3.158-.753a.819.819,0,0,0,.6-.249.819.819,0,0,0,.249-.6.819.819,0,0,0-.249-.6.819.819,0,0,0-.6-.249.819.819,0,0,0-.6.249.819.819,0,0,0-.249.6.819.819,0,0,0,.249.6A.819.819,0,0,0,104.106-655.262Zm3.158-.947h2.526v-.753h-2.526Zm-1.895-2.6h0ZM106-655.578Z" transform="translate(-11392.001 2844.999)" fill="#a0b0b6" />
            </g>
        </svg>
    )
}

export const ContactMobileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="icon-contact-phone" transform="translate(9846 -3092)">
                <g id="Rectangle_21335" data-name="Rectangle 21335" transform="translate(-9846 3092)" fill="#a0b0b6" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="smartphone_FILL0_wght400_GRAD0_opsz24" d="M201.091-908a1.051,1.051,0,0,1-.77-.32,1.05,1.05,0,0,1-.32-.77v-9.818a1.05,1.05,0,0,1,.32-.77,1.05,1.05,0,0,1,.77-.32h5.455a1.05,1.05,0,0,1,.77.32,1.05,1.05,0,0,1,.32.77v9.818a1.05,1.05,0,0,1-.32.77,1.051,1.051,0,0,1-.77.32Zm0-1.636v.545h5.455v-.545Zm0-1.091h5.455v-6.545h-5.455Zm0-7.636h5.455v-.545h-5.455Zm0,0v0Zm0,8.727v0Z" transform="translate(-10043 4013)" fill="#a0b0b6" />
            </g>
        </svg>

    )
}

export const DollarIcon = ({ fill }: { fill: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_78353" data-name="Group 78353" transform="translate(-341 -4271)">
                <rect id="Rectangle_21332" data-name="Rectangle 21332" width="18" height="18" transform="translate(341 4271)" fill={fill} opacity="0" />
                <path id="attach_money_FILL0_wght300_GRAD0_opsz24" d="M307.536-815.6v-1.722a3.9,3.9,0,0,1-1.851-.839,3.782,3.782,0,0,1-1.146-1.813l1.142-.475a3.2,3.2,0,0,0,.947,1.462,2.5,2.5,0,0,0,1.642.506,2.913,2.913,0,0,0,1.572-.424,1.467,1.467,0,0,0,.681-1.345,1.661,1.661,0,0,0-.512-1.276,6.166,6.166,0,0,0-2.118-1,5.407,5.407,0,0,1-2.336-1.244,2.546,2.546,0,0,1-.654-1.791,2.389,2.389,0,0,1,.872-1.955,3.247,3.247,0,0,1,1.761-.792V-830h1.234v1.7a3.275,3.275,0,0,1,1.582.612,3.261,3.261,0,0,1,1,1.268l-1.127.516a2.174,2.174,0,0,0-.755-.924,2.227,2.227,0,0,0-1.274-.329,2.316,2.316,0,0,0-1.493.453,1.414,1.414,0,0,0-.564,1.152,1.362,1.362,0,0,0,.578,1.149,7.567,7.567,0,0,0,2.147.9,4.594,4.594,0,0,1,2.177,1.263,2.885,2.885,0,0,1,.718,1.969,2.612,2.612,0,0,1-.868,2.1,3.807,3.807,0,0,1-2.12.883v1.7Z" transform="translate(41.851 5102.799)" fill={fill} />
            </g>
        </svg>
    )
}

export const AmountIcon = ({ fill }: { fill: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_78354" data-name="Group 78354" transform="translate(-394 -4271)">
                <rect id="Rectangle_21333" data-name="Rectangle 21333" width="18" height="18" transform="translate(394 4271)" fill={fill} opacity="0" />
                <path id="inbox_FILL0_wght300_GRAD0_opsz24" d="M141.532-805.6a1.478,1.478,0,0,1-1.087-.445A1.478,1.478,0,0,1,140-807.13v-11.338a1.478,1.478,0,0,1,.445-1.087,1.478,1.478,0,0,1,1.087-.445H152.87a1.478,1.478,0,0,1,1.087.445,1.478,1.478,0,0,1,.445,1.087v11.338a1.478,1.478,0,0,1-.445,1.086,1.478,1.478,0,0,1-1.087.445Zm0-1.271H152.87a.254.254,0,0,0,.187-.073.254.254,0,0,0,.073-.187v-2.443h-2.786a3.92,3.92,0,0,1-1.376,1.249,3.658,3.658,0,0,1-1.768.445,3.658,3.658,0,0,1-1.768-.445,3.92,3.92,0,0,1-1.376-1.249h-2.786v2.443a.254.254,0,0,0,.073.187A.254.254,0,0,0,141.532-806.87Zm5.669-2.281a2.467,2.467,0,0,0,1.461-.466,2.4,2.4,0,0,0,.911-1.228h3.558v-7.624a.254.254,0,0,0-.073-.187.254.254,0,0,0-.187-.073H141.532a.254.254,0,0,0-.187.073.254.254,0,0,0-.073.187v7.624h3.558a2.4,2.4,0,0,0,.911,1.228A2.467,2.467,0,0,0,147.2-809.15Zm-5.669,2.281h0Z" transform="translate(255.799 5092.799)" fill={fill} />
            </g>
        </svg>
    )
}

export const PercentageIcon = ({ fill }: { fill: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_78355" data-name="Group 78355" transform="translate(-447 -4271)">
                <rect id="Rectangle_21334" data-name="Rectangle 21334" width="18" height="18" transform="translate(447 4271)" fill={fill} opacity="0" />
                <path id="percent_FILL0_wght300_GRAD0_opsz24" d="M182.53-775.325a2.437,2.437,0,0,1-1.788-.741,2.437,2.437,0,0,1-.741-1.788,2.437,2.437,0,0,1,.741-1.788,2.437,2.437,0,0,1,1.788-.741,2.437,2.437,0,0,1,1.788.741,2.437,2.437,0,0,1,.741,1.788,2.437,2.437,0,0,1-.741,1.788A2.437,2.437,0,0,1,182.53-775.325Zm0-1.2a1.284,1.284,0,0,0,.942-.388,1.281,1.281,0,0,0,.388-.942,1.284,1.284,0,0,0-.388-.942,1.281,1.281,0,0,0-.942-.388,1.284,1.284,0,0,0-.942.388,1.282,1.282,0,0,0-.388.942,1.284,1.284,0,0,0,.388.942A1.281,1.281,0,0,0,182.53-776.524Zm6.942,8.141a2.437,2.437,0,0,1-1.788-.741,2.437,2.437,0,0,1-.741-1.788,2.437,2.437,0,0,1,.741-1.788,2.437,2.437,0,0,1,1.788-.741,2.437,2.437,0,0,1,1.788.741,2.437,2.437,0,0,1,.741,1.788,2.437,2.437,0,0,1-.741,1.788A2.437,2.437,0,0,1,189.472-768.383Zm0-1.2a1.284,1.284,0,0,0,.942-.388,1.281,1.281,0,0,0,.388-.942,1.284,1.284,0,0,0-.388-.942,1.281,1.281,0,0,0-.942-.388,1.284,1.284,0,0,0-.942.388,1.281,1.281,0,0,0-.388.942,1.284,1.284,0,0,0,.388.942A1.281,1.281,0,0,0,189.471-769.582Zm-8.559,1.122-.835-.835,11.011-11.011.835.835Z" transform="translate(269.999 5054.383)" fill={fill} />
            </g>
        </svg>
    )
}

export const LetterBIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
            <g id="Group_76825" data-name="Group 76825" transform="translate(-44 -593)">
                <g id="Group_76804" data-name="Group 76804" transform="translate(-9871.5 -5266.5)">
                    <g id="shield_FILL1_wght300_GRAD0_opsz24" transform="translate(9735.499 6717.96)" fill="#f4f9ff">
                        <path d="M 189.0009918212891 -838.9747314453125 C 186.5771179199219 -839.5811157226562 184.5385589599609 -840.8711547851562 182.9403076171875 -842.8104248046875 C 181.3217010498047 -844.7745971679688 180.5010070800781 -846.99853515625 180.5010070800781 -849.42041015625 L 180.5010070800781 -855.1338500976562 L 189.0009918212891 -857.9335327148438 L 197.5010070800781 -855.1338500976562 L 197.5010070800781 -849.42041015625 C 197.5010070800781 -846.99853515625 196.6803131103516 -844.7745971679688 195.0616912841797 -842.8104248046875 C 193.4634552001953 -840.8711547851562 191.4248809814453 -839.5811157226562 189.0009918212891 -838.9747314453125 Z" stroke="none" />
                        <path d="M 189.0009918212891 -839.49072265625 C 191.2938842773438 -840.087890625 193.1521301269531 -841.279541015625 194.6758270263672 -843.1283569335938 C 196.2404327392578 -845.0270385742188 197.0010070800781 -847.0851440429688 197.0010070800781 -849.42041015625 L 197.0010070800781 -854.7720947265625 L 189.0009918212891 -857.4071044921875 L 181.0010070800781 -854.7720947265625 L 181.0010070800781 -849.42041015625 C 181.0010070800781 -847.085205078125 181.7615814208984 -845.0270385742188 183.3261566162109 -843.12841796875 C 184.8498840332031 -841.279541015625 186.7081146240234 -840.087890625 189.0009918212891 -839.49072265625 M 189.0009918212891 -838.4599609375 C 186.4056091308594 -839.0826416015625 184.2567749023438 -840.4268798828125 182.5544586181641 -842.492431640625 C 180.8521728515625 -844.5581665039062 180.0010070800781 -846.867431640625 180.0010070800781 -849.42041015625 L 180.0010070800781 -855.49560546875 L 189.0009918212891 -858.4599609375 L 198.0010070800781 -855.49560546875 L 198.0010070800781 -849.42041015625 C 198.0010070800781 -846.867431640625 197.1498565673828 -844.5581665039062 195.4475555419922 -842.492431640625 C 193.7452392578125 -840.4268798828125 191.5963897705078 -839.0826416015625 189.0009918212891 -838.4599609375 Z" stroke="none" fill="#e3eefd" />
                    </g>
                </g>
                <text id="B" transform="translate(53 607)" fill="#438ae4" font-size="12" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-4" y="0">B</tspan></text>
            </g>
        </svg>
    )
}

export const LetterMIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
            <g id="Group_76825" data-name="Group 76825" transform="translate(-44 -593)">
                <g id="Group_76804" data-name="Group 76804" transform="translate(-9871.5 -5266.5)">
                    <g id="shield_FILL1_wght300_GRAD0_opsz24" transform="translate(9735.499 6717.96)" fill="#f4f9ff">
                        <path d="M 189.0009918212891 -838.9747314453125 C 186.5771179199219 -839.5811157226562 184.5385589599609 -840.8711547851562 182.9403076171875 -842.8104248046875 C 181.3217010498047 -844.7745971679688 180.5010070800781 -846.99853515625 180.5010070800781 -849.42041015625 L 180.5010070800781 -855.1338500976562 L 189.0009918212891 -857.9335327148438 L 197.5010070800781 -855.1338500976562 L 197.5010070800781 -849.42041015625 C 197.5010070800781 -846.99853515625 196.6803131103516 -844.7745971679688 195.0616912841797 -842.8104248046875 C 193.4634552001953 -840.8711547851562 191.4248809814453 -839.5811157226562 189.0009918212891 -838.9747314453125 Z" stroke="none" />
                        <path d="M 189.0009918212891 -839.49072265625 C 191.2938842773438 -840.087890625 193.1521301269531 -841.279541015625 194.6758270263672 -843.1283569335938 C 196.2404327392578 -845.0270385742188 197.0010070800781 -847.0851440429688 197.0010070800781 -849.42041015625 L 197.0010070800781 -854.7720947265625 L 189.0009918212891 -857.4071044921875 L 181.0010070800781 -854.7720947265625 L 181.0010070800781 -849.42041015625 C 181.0010070800781 -847.085205078125 181.7615814208984 -845.0270385742188 183.3261566162109 -843.12841796875 C 184.8498840332031 -841.279541015625 186.7081146240234 -840.087890625 189.0009918212891 -839.49072265625 M 189.0009918212891 -838.4599609375 C 186.4056091308594 -839.0826416015625 184.2567749023438 -840.4268798828125 182.5544586181641 -842.492431640625 C 180.8521728515625 -844.5581665039062 180.0010070800781 -846.867431640625 180.0010070800781 -849.42041015625 L 180.0010070800781 -855.49560546875 L 189.0009918212891 -858.4599609375 L 198.0010070800781 -855.49560546875 L 198.0010070800781 -849.42041015625 C 198.0010070800781 -846.867431640625 197.1498565673828 -844.5581665039062 195.4475555419922 -842.492431640625 C 193.7452392578125 -840.4268798828125 191.5963897705078 -839.0826416015625 189.0009918212891 -838.4599609375 Z" stroke="none" fill="#e3eefd" />
                    </g>
                </g>
                <text id="M" transform="translate(53 607)" fill="#438ae4" font-size="12" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-5" y="0">M</tspan></text>
            </g>
        </svg>

    )
}

export const LetterSIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
            <g id="Group_76825" data-name="Group 76825" transform="translate(-44 -593)">
                <g id="Group_76804" data-name="Group 76804" transform="translate(-9871.5 -5266.5)">
                    <g id="shield_FILL1_wght300_GRAD0_opsz24" transform="translate(9735.499 6717.96)" fill="#f4f9ff">
                        <path d="M 189.0009918212891 -838.9747314453125 C 186.5771179199219 -839.5811157226562 184.5385589599609 -840.8711547851562 182.9403076171875 -842.8104248046875 C 181.3217010498047 -844.7745971679688 180.5010070800781 -846.99853515625 180.5010070800781 -849.42041015625 L 180.5010070800781 -855.1338500976562 L 189.0009918212891 -857.9335327148438 L 197.5010070800781 -855.1338500976562 L 197.5010070800781 -849.42041015625 C 197.5010070800781 -846.99853515625 196.6803131103516 -844.7745971679688 195.0616912841797 -842.8104248046875 C 193.4634552001953 -840.8711547851562 191.4248809814453 -839.5811157226562 189.0009918212891 -838.9747314453125 Z" stroke="none" />
                        <path d="M 189.0009918212891 -839.49072265625 C 191.2938842773438 -840.087890625 193.1521301269531 -841.279541015625 194.6758270263672 -843.1283569335938 C 196.2404327392578 -845.0270385742188 197.0010070800781 -847.0851440429688 197.0010070800781 -849.42041015625 L 197.0010070800781 -854.7720947265625 L 189.0009918212891 -857.4071044921875 L 181.0010070800781 -854.7720947265625 L 181.0010070800781 -849.42041015625 C 181.0010070800781 -847.085205078125 181.7615814208984 -845.0270385742188 183.3261566162109 -843.12841796875 C 184.8498840332031 -841.279541015625 186.7081146240234 -840.087890625 189.0009918212891 -839.49072265625 M 189.0009918212891 -838.4599609375 C 186.4056091308594 -839.0826416015625 184.2567749023438 -840.4268798828125 182.5544586181641 -842.492431640625 C 180.8521728515625 -844.5581665039062 180.0010070800781 -846.867431640625 180.0010070800781 -849.42041015625 L 180.0010070800781 -855.49560546875 L 189.0009918212891 -858.4599609375 L 198.0010070800781 -855.49560546875 L 198.0010070800781 -849.42041015625 C 198.0010070800781 -846.867431640625 197.1498565673828 -844.5581665039062 195.4475555419922 -842.492431640625 C 193.7452392578125 -840.4268798828125 191.5963897705078 -839.0826416015625 189.0009918212891 -838.4599609375 Z" stroke="none" fill="#e3eefd" />
                    </g>
                </g>
                <text id="S" transform="translate(53 607)" fill="#438ae4" font-size="12" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-3" y="0">S</tspan></text>
            </g>
        </svg>
    )
}

export const LetterVIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
            <g id="Group_76825" data-name="Group 76825" transform="translate(-44 -593)">
                <g id="Group_76804" data-name="Group 76804" transform="translate(-9871.5 -5266.5)">
                    <g id="shield_FILL1_wght300_GRAD0_opsz24" transform="translate(9735.499 6717.96)" fill="#f4f9ff">
                        <path d="M 189.0009918212891 -838.9747314453125 C 186.5771179199219 -839.5811157226562 184.5385589599609 -840.8711547851562 182.9403076171875 -842.8104248046875 C 181.3217010498047 -844.7745971679688 180.5010070800781 -846.99853515625 180.5010070800781 -849.42041015625 L 180.5010070800781 -855.1338500976562 L 189.0009918212891 -857.9335327148438 L 197.5010070800781 -855.1338500976562 L 197.5010070800781 -849.42041015625 C 197.5010070800781 -846.99853515625 196.6803131103516 -844.7745971679688 195.0616912841797 -842.8104248046875 C 193.4634552001953 -840.8711547851562 191.4248809814453 -839.5811157226562 189.0009918212891 -838.9747314453125 Z" stroke="none" />
                        <path d="M 189.0009918212891 -839.49072265625 C 191.2938842773438 -840.087890625 193.1521301269531 -841.279541015625 194.6758270263672 -843.1283569335938 C 196.2404327392578 -845.0270385742188 197.0010070800781 -847.0851440429688 197.0010070800781 -849.42041015625 L 197.0010070800781 -854.7720947265625 L 189.0009918212891 -857.4071044921875 L 181.0010070800781 -854.7720947265625 L 181.0010070800781 -849.42041015625 C 181.0010070800781 -847.085205078125 181.7615814208984 -845.0270385742188 183.3261566162109 -843.12841796875 C 184.8498840332031 -841.279541015625 186.7081146240234 -840.087890625 189.0009918212891 -839.49072265625 M 189.0009918212891 -838.4599609375 C 186.4056091308594 -839.0826416015625 184.2567749023438 -840.4268798828125 182.5544586181641 -842.492431640625 C 180.8521728515625 -844.5581665039062 180.0010070800781 -846.867431640625 180.0010070800781 -849.42041015625 L 180.0010070800781 -855.49560546875 L 189.0009918212891 -858.4599609375 L 198.0010070800781 -855.49560546875 L 198.0010070800781 -849.42041015625 C 198.0010070800781 -846.867431640625 197.1498565673828 -844.5581665039062 195.4475555419922 -842.492431640625 C 193.7452392578125 -840.4268798828125 191.5963897705078 -839.0826416015625 189.0009918212891 -838.4599609375 Z" stroke="none" fill="#e3eefd" />
                    </g>
                </g>
                <text id="V" transform="translate(53 607)" fill="#438ae4" font-size="12" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-3" y="0">V</tspan></text>
            </g>
        </svg>
    )
}

export const FilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="filter_alt_FILL0_wght400_GRAD0_opsz24" d="M167.054-782a1.084,1.084,0,0,1-.8-.323,1.09,1.09,0,0,1-.323-.8v-6.75l-6.508-8.325a1.073,1.073,0,0,1-.126-1.181,1.042,1.042,0,0,1,1.024-.619h15.71a1.042,1.042,0,0,1,1.024.619,1.073,1.073,0,0,1-.126,1.181l-6.508,8.325v6.75a1.09,1.09,0,0,1-.323.8,1.084,1.084,0,0,1-.8.323Zm1.122-8.663,5.554-7.087H162.622ZM168.176-790.662Z" transform="translate(-159.177 800)" fill="#b4b4b4" />
        </svg>
    )
}

export const ViewIcon = () => {
    return (
        <svg id="comp-view_approvers" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="icon-show">
                <circle id="Ellipse_376" data-name="Ellipse 376" cx="18" cy="18" r="18" fill="#ccc" opacity="0" />
                <path id="visibility_24dp_CCC_FILL1_wght400_GRAD0_opsz24" d="M48.64-790.575a3.409,3.409,0,0,0,2.5-1.031,3.409,3.409,0,0,0,1.031-2.5,3.409,3.409,0,0,0-1.031-2.5,3.409,3.409,0,0,0-2.5-1.031,3.409,3.409,0,0,0-2.5,1.031,3.409,3.409,0,0,0-1.031,2.5,3.409,3.409,0,0,0,1.031,2.5A3.409,3.409,0,0,0,48.64-790.575Zm0-1.414a2.045,2.045,0,0,1-1.5-.619,2.045,2.045,0,0,1-.619-1.5,2.045,2.045,0,0,1,.619-1.5,2.046,2.046,0,0,1,1.5-.619,2.046,2.046,0,0,1,1.5.619,2.045,2.045,0,0,1,.619,1.5,2.045,2.045,0,0,1-.619,1.5A2.045,2.045,0,0,1,48.64-791.988Zm0,3.77a9.093,9.093,0,0,1-5.223-1.6A9.1,9.1,0,0,1,40-794.109a9.1,9.1,0,0,1,3.417-4.291A9.094,9.094,0,0,1,48.64-800a9.094,9.094,0,0,1,5.223,1.6,9.1,9.1,0,0,1,3.417,4.291,9.1,9.1,0,0,1-3.417,4.291A9.094,9.094,0,0,1,48.64-788.218Z" transform="translate(-29.92 811.52)" fill="#666" />
            </g>
        </svg>
    )
}