import * as React from "react";
import {useState} from "react";
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import MoreVert from "@material-ui/icons/MoreVert";
import Fade from '@material-ui/core/Fade/Fade';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    more: {
      fontSize: '19px',
    },
    listItemIcon: {
      minWidth: '40px'
    }
  });

interface Item {
  label: string;
  onClick?: (event: any) => void;
}

interface Props {
  classes?: any;
  items: Item[];
}

function IconMenu(props: Props) {
  const {classes, items} = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (e: React.MouseEvent, item: Item) => {
    if (item.onClick) item.onClick(e);
    setAnchorEl(null)
  };
  const visibleItems = items.filter(item => !(item.hasOwnProperty('visible')) || item['visible']);
  return (
    visibleItems.length ? <>
      <IconButton onClick={(event: any) => setAnchorEl(event.currentTarget)}>
        <MoreVert className={classes.more} color={"action"}/>
      </IconButton>
      <Menu
        id="icon-button-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <MenuList>
          {visibleItems.map((item: Item, index: number) => (
            <MenuItem key={index} onClick={(e) => handleClick(e, item)}>
              <Typography variant="inherit">{item.label}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
    : null
  )
}

export const MoreOptionsMenu = withStyles(styles)(IconMenu);
