import styled from 'styled-components';
import { MainTitle, MainToolbar, TableContainer, Toolbar, HorizontalView } from 'src/app/shared/components/layout/styles';
import { BannerTitle, BannerWrapper } from '../shared/components/banner/banner.styles';
import { MTableCell } from 'material-table';

export const PRTableContainer = styled(TableContainer)`
@media only screen and (max-width: 768px) {
    padding: 0 16px 16px 16px;
}    
`;

export const PRMainToolbar = styled(MainToolbar)`
flex-direction: row;
align-items: center;
background-color: #fff;
@media only screen and (max-width: 768px) {
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  align-items: flex-start;
  justify-content: center;
  height:auto;
  padding-top:15px;
}
`;

export const PRMainToolbarTitle = styled.div`
width:65%;
@media only screen and (max-width: 768px) {
width:100%;
}
`;

export const PRMainTitle = styled(MainTitle)`
padding-top:28px;
@media only screen and (max-width: 768px) {
margin-bottom:0;
font-size:20px
margin-top: 0;
}
`;

export const PRStyledToolbar = styled(Toolbar)`

width: 1120px;
min-height: auto;
@media only screen and (max-width: 768px) {
    width: 100%;
}
`;
export const NewPaExpandPage = styled(PRStyledToolbar)`

width: 1520px;
`;

export const MultiLineEditExpandPage = styled(PRStyledToolbar)`
min-height: 100vh;
width: 100%;
`;


export const PRItemsTableContainer = styled.div`
  padding: 26px 26px 0;
  width: 1120px;
  @media only screen and (max-width: 768px) {
    padding: 16px 16px 0;    
    width: 100%;
  }
`;

export const PRHeaderWrapper = styled(HorizontalView)`
  justify-content: space-between;
  padding: 10px 25px;
  @media only screen and (max-width: 768px) {
      flex-direction:column;
      padding: 10px 16px;
  }
`;



export const PRPopupHeaderTable = styled.table`
text-align:right;
`;
export const PRPopupHeaderTableRow = styled.tr`
  
`;
export const PRPopupHeaderTableTBody = styled.tbody`
  
`;
export const PRPopupHeaderTableCell = styled.td`
font-size: 16px;
color:rgb(60, 65, 68);
white-space: nowrap;
padding-left:10px;
font-weight: 500;
`;
export const PRPopupHeaderTableCellTH = styled.td`
  font-size: 16px;
  color: #707070;
  padding-right:30px
  text-transform: capitalize;
`;


export const PRpopupTable = styled.div`
overflow-x: auto;
@media only screen and (max-width: 768px) {
    width: calc( 100vw - 32px);
}
`;

export const EditDetailsTable = styled.div`
max-height:565px
overflow-x: auto;
@media only screen and (max-width: 768px) {
    width: calc( 100vw - 32px);
}
`;
export const PRCommentBox = styled.div` 
  padding:0px 30px 20px;
  .MuiInput-inputMultiline:nth-child(2){
    left:0  
  }
  @media only screen and (max-width: 768px) {
    padding:0px 16px 16px;
  }
`;

interface StyledCellProps {
  width?: string
}

export const PRStyledCell = styled(MTableCell)`
  && {
    border-bottom: none !important
    color: #707070;
    padding: 0px 5px 0px 5px
    height:49px;
    min-width: ${(props: StyledCellProps) => props.width ? `${props.width}px` : 'inherit'};
    @media only screen and (max-width: 768px) {
    font-size:12px
    }
  } 
`;

export const PslCatalogsStyledCell = styled(MTableCell)`
  && {
    border-bottom: none !important
    color: #777777;
    font: normal normal 300 15px/18px Nestle Text;
    height:100px;
    min-width: ${(props: StyledCellProps) => props.width ? `${props.width}px` : 'inherit'};
    @media only screen and (max-width: 768px) {
    font-size:12px
    }
  } 
`;

export const NewEditReqStyledCell = styled(MTableCell)`
.MuiTableCell-root {
  border-bottom:none
}
  && {
    border-bottom: none !important
    color: #707070;
    padding: 0 16px;
    height:60px;
    margin:0 16px;
    min-width: ${(props: StyledCellProps) => props.width ? `${props.width}px` : 'inherit'};
    @media only screen and (max-width: 768px) {
    font-size:12px
    }
  } 
`;

