import { PurchaseRequest, SAPBoolean } from "./purchaseRequest";
import ODataClientWrapper from "../../../../shared/utils/odataClient.wrapper";
import { AxiosWrapper } from "../../../../shared/utils/axios.wrapper";
import { withSystemAlias } from "../../../../shared/domains/auth/authentication.service";
import { purchaseRequestQuery, ODataQuery, PartialODataQuery } from "./query";
import { SimpleODataFilter } from "../../../../../lib/odata";

export interface ListResponse<T> {
	count: number;
	data: T[]
}

let PRLines = []

export class PurchaseRequestService {

	private static async fetchAll(query: ODataQuery, alias:string): Promise<ListResponse<PurchaseRequest>> {
		const resource = query.resource;
		const response = await ODataClientWrapper.get()
			.withSystemAlias(alias)
			.fromQuery(query)
			.count()
			.top()
			.execute();
		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}

		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results.map(PurchaseRequest.FromBackend)
		}
	}


	static async fetch(query: ODataQuery = purchaseRequestQuery(), alias:string): Promise<ListResponse<PurchaseRequest>> {

		return PurchaseRequestService.fetchPRs(query, alias);
	}

	private static async fetchPRs(query: ODataQuery = purchaseRequestQuery(), alias:string): Promise<ListResponse<PurchaseRequest>> {
		return await PurchaseRequestService.fetchAll(query, alias);
	}


	static async fetchDetail(query: any): Promise<ListResponse<PurchaseRequest>> {
		return await PurchaseRequestService.fetchPRDetailAll(query);
	}

	private static async fetchPRDetailAll(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "',AribaPR='" + encodeURIComponent(query.AribaPR) + "')")
			.execute();

		return response.data.d;
	}

	static async fetchAccount(query: any): Promise<ListResponse<PurchaseRequest>> {
		return PurchaseRequestService.fetchPRAccount(query);
	}

	private static async fetchPRAccount(query: any): Promise<any> {
		let accountValues = []
		let filterValues = '?$filter='
		if (query.Cos) {
			accountValues = query.Cos.split(',')
		}
		accountValues.length && accountValues.map((value, index) => {
			if (index) {
				if (index == 1) {
					filterValues += `(Zebkn eq '${value}')`
				} else {
					filterValues += ` or (Zebkn eq '${value}')`
				}
			}
		})

		// ?$filter=(Zebkn eq '01') or (Zebkn eq '02')
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "',AribaPR='" + encodeURIComponent(query.AribaPR) + "')/ToAccAssignment" + `${query.Cos ? filterValues : ''}`)
			.execute();
		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		PRLines = response.data.d.results
		return response.data.d.results;
	}


	static async fetchPricing(query: any): Promise<ListResponse<PurchaseRequest>> {
		return PurchaseRequestService.fetchPRPricing(query);
	}

	private static async fetchPRPricing(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			//.resource("PRLines(SapPR='"+encodeURIComponent(query.SapPR)+"',ItemNo='"+encodeURIComponent(query.ItemNo)+"',AribaPR='"+encodeURIComponent(query.AribaPR)+"')/ToPricingCondition")	
			.resource("PricingConditions?$filter=(ARIBAPRNo eq '" + encodeURIComponent(query.AribaPR) + "') and (SAPPRNo eq '" + encodeURIComponent(query.SapPR) + "') and (ItemNumber eq '" + encodeURIComponent(query.ItemNo) + "')&sap-language=en")

			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}

	static async fetchApprovalIndicator(query: any): Promise<ListResponse<PurchaseRequest>> {
		return PurchaseRequestService.fetchPRApprovalIndicator(query);
	}

	/**
	 * @name fetchPRApprovalIndicator
	 * @param query 
	 * @method GET
	 * @description fetch Approval Indicator for the API
	 */
	private static async fetchPRApprovalIndicator(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			.resource("PRApprovals_BAs(AribaPR='" + encodeURIComponent(query.AribaPR) + "',SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "',CompCode='" + encodeURIComponent(query.CompCode) + "',PurchOrg='" + encodeURIComponent(query.PurchOrg) + "',RelStrategy='" + encodeURIComponent(query.RelStrategy) + "',RelGroup='" + encodeURIComponent(query.RelGroup) + "',Counter='" + encodeURIComponent(query.Counter) + "',ApprovalLevel='" + encodeURIComponent(query.ApprovalLevel) + "')")
			.execute();

		if (!response.data || !response.data.d) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}

	/**
	* encrypt parameter
	* @param paramString
	* @returns EncryptedString
	*/
	static parameterEncrypter = (paramString) => {
		return encodeURIComponent(paramString);
	}

	/**
	 * @name approvalRequest
	 * @param PRDetail 
	 * @description Post the data for approval of PR
	 */
	static async approvalRequest(PRDetail: PurchaseRequest): Promise<any> {
		let zebknString = '';
		let counterValue = '';
		if (PRDetail.IsEmail) {
			zebknString = PRDetail.Counter
			counterValue = PRDetail.Counter
		} else {
			PRLines && PRLines.length > 0 && PRLines.map((line, index) => {
				if (!index) {
					counterValue = line.Zebkn
				}
				zebknString += index ? `,${line.Zebkn}` : `${line.Zebkn}`
			})
		}
		const bodyToUpdate = PurchaseRequestService.approvalRequestBody(PRDetail);
		bodyToUpdate.d.Cos = zebknString;
		// const response = await AxiosWrapper.put("/api/sapdata/Put?query=PRApprovals_BAs(AribaPR='" + encodeURIComponent(PRDetail.AribaPR) + "',SapPR='" + encodeURIComponent(PRDetail.SapPR) + "',ItemNo='" + encodeURIComponent(PRDetail.ItemNo) + "',CompCode='" + encodeURIComponent(PRDetail.CompCode) + "',PurchOrg='" + encodeURIComponent(PRDetail.PurchOrg) + "',RelStrategy='" + encodeURIComponent(PRDetail.RelStrategy) + "',RelGroup='" + encodeURIComponent(PRDetail.RelGroup) + "',Counter='" + encodeURIComponent(counterValue) + "',ApprovalLevel='" + encodeURIComponent(PRDetail.ApprovalLevel) + "')", bodyToUpdate, { headers: withSystemAlias(PRDetail.SAP__Origin, {}, true) });
		const response = await AxiosWrapper.put("/api/sapdata/Put?query=PRApprovals_BAs(AribaPR='" + encodeURIComponent(PRDetail.AribaPR) + "',SapPR='" + encodeURIComponent(PRDetail.SapPR) + "',ItemNo='" + encodeURIComponent(PRDetail.ItemNo) + "',CompCode='" + encodeURIComponent(PRDetail.CompCode) + "',PurchOrg='" + encodeURIComponent(PRDetail.PurchOrg) + "',RelStrategy='',RelGroup='',Counter='" + encodeURIComponent(counterValue) + "',ApprovalLevel='')", bodyToUpdate, { headers: withSystemAlias(PRDetail.SAP__Origin || PRDetail.SystemAlias, {}, true) });

		return response.data;
	}

	static async processForBA(data: any, origin: string): Promise<any> {
		const response: any = await AxiosWrapper.post(`/api/sapdata/PostFromBody?query=IdSet`, data, origin && origin != 'undefined' ? { headers: withSystemAlias(origin, {}, true) } : {});
		return response.data;
	}

	private static approvalRequestBody(PRDetail: PurchaseRequest): any {
		return {
			d: {
				AribaPR: PRDetail.AribaPR,
				SapPR: PRDetail.SapPR,
				ItemNo: PRDetail.ItemNo,
				CompCode: PRDetail.CompCode,
				PurchOrg: PRDetail.PurchOrg,
				RelGroup: PRDetail.RelGroup,
				RelStrategy: PRDetail.RelStrategy,
				ApprovalLevel: PRDetail.ApprovalLevel,
				Comments: PRDetail.Comments,
				ApprovalIndicator: PRDetail.ApprovalIndicator
			}
		}
	}



	/**
	 * function to fetch PR line items
	 * @param query
	 * @returns {Promise<ListResponse<PurchaseRequest>>}
	 */
	static async fetchLines(query: any): Promise<ListResponse<PurchaseRequest>> {
		return await PurchaseRequestService.fetchPRLinesAll(query);
	}

	private static async fetchPRLinesAll(query: any): Promise<any> {
		const resource = "PRApprovals_BAs";

		/*Loop for filter array*/
		let filterArray = [];
		if (query.query.length > 0) {
			query.query.map((item: any, index: number) => {
				let oDataFilterObj: SimpleODataFilter = { field: item.field, value: item.value };
				filterArray.push(oDataFilterObj);
			});
		}

		/*Merge all variables*/
		const favoriteQuery: PartialODataQuery = { resource, filters: filterArray, orderBy: [] };
		const response = await ODataClientWrapper.get()
			.withSystemAlias(query.SAP__Origin)
			.fromQuery(favoriteQuery)
			.count()
			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}

		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results
		}
	}

	static async getDefaultAliys(compCode:string): Promise<any> {
		const response: any = await AxiosWrapper.get(`/api/Users/GetApprovalsSapDefaultSystem${compCode ? '?companyCode='+compCode : ''}`);
		return response.data;
	}

}
