interface NetworkSourcingIdDTO {
    __metadata: { id: string; type: string; uri: string };
    PurchaseReq: string
    Sourcing_ID: string
}

export class SourcingID {
    constructor(
        public PurchaseReq: string,
        public Sourcing_ID: string
    ) {
    }

    static FromBackend(data: NetworkSourcingIdDTO): SourcingID {
        return new SourcingID(
            data.PurchaseReq,
            data.Sourcing_ID,
        )
    }

    static toSelect(data: SourcingID) {
        return {
            label: data.Sourcing_ID,
            value: data.Sourcing_ID
        }
    }
}
