import { ApprovalStatusRequest, SAPBoolean } from "./approvalStatus";
import ODataClientWrapper from "../../../../shared/utils/odataClient.wrapper";
import { withSystemAlias } from "../../../../shared/domains/auth/authentication.service";
import { AxiosWrapper } from "../../../../shared/utils/axios.wrapper";
import { approvalStatusQuery, ODataQuery, PartialODataQuery } from "./query";
import { SimpleODataFilter } from "../../../../../lib/odata";

export interface ListResponse<T> {
	count: number;
	data: T[]
}

export class ApprovalStatusService {

	private static async fetchAll(query: ODataQuery, aliys:string): Promise<ListResponse<ApprovalStatusRequest>> {
		const resource = query.resource;
		const response = await ODataClientWrapper.get()
			.withSystemAlias(aliys)
			.fromQuery(query)
			.count()
			.execute();
		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}

		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results.map(ApprovalStatusRequest.FromBackend)
		}
	}


	static async fetch(query: ODataQuery = approvalStatusQuery(), aliys): Promise<ListResponse<ApprovalStatusRequest>> {

		return ApprovalStatusService.fetchPRs(query, aliys);
	}

	private static async fetchPRs(query: ODataQuery = approvalStatusQuery(), aliys): Promise<ListResponse<ApprovalStatusRequest>> {
		return await ApprovalStatusService.fetchAll(query, aliys);
	}


	static async fetchDetail(query: any): Promise<ListResponse<ApprovalStatusRequest>> {
		return await ApprovalStatusService.fetchPRDetailAll(query);
	}

	private static async fetchPRDetailAll(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "')")
			.execute();

		return response.data.d;
	}

	static async fetchAccount(query: any): Promise<ListResponse<ApprovalStatusRequest>> {
		return ApprovalStatusService.fetchPRAccount(query);
	}

	private static async fetchPRAccount(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "')/ToAccAssignment")
			.execute();
		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;

	}


	static async fetchPricing(query: any): Promise<ListResponse<ApprovalStatusRequest>> {
		return ApprovalStatusService.fetchPRPricing(query);
	}

	private static async fetchPRPricing(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.resource("PRLines(SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "')/ToPricingCondition")
			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}

	static async fetchApprovalIndicator(query: any): Promise<ListResponse<ApprovalStatusRequest>> {
		return ApprovalStatusService.fetchPRApprovalIndicator(query);
	}

	/**
	 * @name fetchPRApprovalIndicator
	 * @param query 
	 * @method GET
	 * @description fetch Approval Indicator for the API
	 */
	private static async fetchPRApprovalIndicator(query: any): Promise<any> {
		const response = await ODataClientWrapper.get()
			.resource("PRApprovals_BAs(Aribapr='" + encodeURIComponent(query.Aribapr) + "',SapPR='" + encodeURIComponent(query.SapPR) + "',ItemNo='" + encodeURIComponent(query.ItemNo) + "',CompCode='" + encodeURIComponent(query.CompCode) + "',PurchOrg='" + encodeURIComponent(query.PurchOrg) + "',RelStrategy='" + encodeURIComponent(query.RelStrategy) + "',RelGroup='" + encodeURIComponent(query.RelGroup) + "',ApprovalLevel='" + encodeURIComponent(query.ApprovalLevel) + "')")
			.execute();

		if (!response.data || !response.data.d) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}
		return response.data.d.results;
	}

	/**
	 * @name approvalRequest
	 * @param PRDetail 
	 * @description Post the data for approval of PR
	 */
	static async approvalRequest(PRDetail: ApprovalStatusRequest): Promise<any> {
		const bodyToUpdate = ApprovalStatusService.approvalRequestBody(PRDetail);
		/*const response = await AxiosWrapper.put("/api/sapdata/Put?query=PRApprovals_BAs(Aribapr='"+PRDetail.Aribapr+"',SapPR='"+PRDetail.SapPR+"',ItemNo='"+PRDetail.ItemNo+"',CompCode='"+PRDetail.CompCode+"',PurchOrg='"+PRDetail.PurchOrg+"',RelStrategy='"+PRDetail.RelStrategy+"',RelGroup='"+PRDetail.RelGroup+"',ApprovalLevel='"+PRDetail.ApprovalLevel+"')", bodyToUpdate);
		return response.data;*/
		return [];
	}



	private static approvalRequestBody(PRDetail: ApprovalStatusRequest): any {
		return {
			d: {
				Aribapr: PRDetail.Aribapr,

			}
		}
	}


	/**
 * @name emailTriggerService
 * @param PRDetail 
 * @description Post the data for approval of PR
 */
	static async emailTriggerService(rowData: any, selectedRow: ApprovalStatusRequest): Promise<any> {
		const body = {
			AgentId: rowData.AgentId,
			Email: rowData.EmailId,
			Aribapr: selectedRow.Aribapr,
			ItemNo: selectedRow.ItemNo,
			Bukrs: selectedRow.Bukrs,
			Ekorg: selectedRow.Ekorg,
			ApprovalType: selectedRow.ApprovalType,
			Frgco: selectedRow.Frgco,
			Sappr: selectedRow.Banfn,
			Counter: selectedRow.Counter,
			Frgsx: selectedRow.Frgsx,
			Frggr: selectedRow.Frggr
		}
		const encodedQuery = encodeURIComponent(`ApprovalRetriggerSet(Aribapr='${selectedRow.Aribapr}',ItemNo='${selectedRow.ItemNo}',Bukrs='${selectedRow.Bukrs}',Ekorg='${selectedRow.Ekorg}',ApprovalType='${selectedRow.ApprovalType}',Frgco='00',Sappr='${selectedRow.Banfn}',Counter='${selectedRow.Counter}',Frgsx='00',Frggr='00')`);
		const response = await AxiosWrapper.put(
			`/api/sapdata/Put?query=${encodedQuery}`, 
			body,
			selectedRow.SAP__Origin?{ headers: withSystemAlias(selectedRow.SAP__Origin, {}, true) }:{}
		);
		return response.data
	}

	/**
	 * function to fetch PR line items
	 * @param query
	 * @returns {Promise<ListResponse<PurchaseRequest>>}
	 */
	static async fetchLines(query: any): Promise<ListResponse<ApprovalStatusRequest>> {
		return await ApprovalStatusService.fetchPRLinesAll(query);
	}

	/**
	 * function to fetch PR line items
	 * @param query
	 * @returns {Promise<ListResponse<PurchaseRequest>>}
	 */
	static async sendEmail(query: any): Promise<any> {
		const { rowData, selectedRow } = query
		return await ApprovalStatusService.emailTriggerService(rowData, selectedRow);
	}

	private static async fetchPRLinesAll(query: any): Promise<any> {
		const resource = "PRApproversSet";

		/*Loop for filter array*/
		let filterArray = [];
		if (query.postQuery.length > 0) {
			query.postQuery.map((item: any, index: number) => {
				let oDataFilterObj: SimpleODataFilter = { field: item.field, value: item.value };
				filterArray.push(oDataFilterObj);
			});
		}

		/*Merge all variables*/
		const favoriteQuery: PartialODataQuery = { resource, filters: filterArray, orderBy: [] };

		const response = await ODataClientWrapper.get()
		.withSystemAlias(query.SAP__Origin)
			.fromQuery(favoriteQuery)
			.count()
			.execute();

		if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
			throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
		}

		const results = response.data.d.results;
		return {
			count: parseInt(response.data.d.__count),
			data: results
		}
	}

	/**
	 * Apply for approval process of PA
	 * @param query
	 * @returns {Promise<ListResponse<PurchaseRequest>>}
	 */

	static async getDefaultAliys(compCode:string): Promise<any> {
		const response: any = await AxiosWrapper.get(`/api/Users/GetApprovalsSapDefaultSystem${compCode ? '?companyCode='+compCode : ''}`);
		return response.data;

	}

}
