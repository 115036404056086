import * as R from "ramda";
import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import {QueryPSLActions} from '../reducers/pslList.reducer';
import {PSL} from "../domains/psl";
import {SavePslFiltersError, SavePslFiltersSuccess} from "../../shared/actions/user.actions";
import {IPslFilters} from "../../shared/domains/user/user";
import {UserService} from "../../shared/domains/user/user.service";
import {ITreeCategory} from "../containers/pslForm/pslForm.utils";
import {PSLService} from "../domains/psl.service";
import {CreationDateFilter} from "../../shared/domains/query/filter";
import moment from 'moment';

function* fetch(): any {
  try {
    const result: ITreeCategory[] = yield call(PSLService.fetchCategoryTree);

    let spendCat1Data = []
    let spendCat2Data = []
    let spendCat3Data = []

    result.forEach(value => {
      spendCat1Data.push({id: value.code, title: value.name, azureId: value.id})
      value.spendCategories.forEach(valueL2 => {
        spendCat2Data.push({id: valueL2.code, title: valueL2.name, azureId: valueL2.id, parentId: value.id})
        valueL2.spendCategories.forEach(valueL3 => {
          spendCat3Data.push({id: valueL3.code, title: valueL3.name, azureId: valueL3.id, parentId: valueL2.id})
        })
      })
    });

    const filters = {
      SpendCat1Code: {
        data: spendCat1Data,
        key: "SpendCat1Code"
      },
      SpendCat2Code: {
        data: spendCat2Data,
        key: "SpendCat2Code"
      },
      SpendCat3Code: {
        data: spendCat3Data,
        key: "SpendCat3Code"
      }
    }

    const filtersAppendStatuses = R.assoc('StatusId', {
      key: 'StatusId', data: PSL.StatuesFilterable()
    }, filters);

    yield put(new QueryPSLActions.onLoadFiltersSuccess(filtersAppendStatuses));
    // the below line is for saving the tree structure into the state.
    yield put({type: 'LOAD_SPEND_CATEGORY_TREE', payload: result})
  } catch (error) {
    yield put(new QueryPSLActions.onLoadFiltersError(error));
  }
}

function* saveFilters(action) {
  try {
    const dateRange: CreationDateFilter = R.path(['creationAndValidityDate', 'selected'], action.filters);
    const filters: IPslFilters = action.skipMapping ? action.filters : {
      spendCatL1s: R.path(['SpendCat1Code', 'selected'], action.filters),
      spendCatL2s: R.path(['SpendCat2Code', 'selected'], action.filters),
      spendCatL3s: R.path(['SpendCat3Code', 'selected'], action.filters),
      companies: R.path(['CompanyCode', 'selected'], action.filters),
      markets: R.path(['Markets', 'selected'], action.filters),
      zones: R.path(['zones', 'selected'], action.filters),
      suppliers: R.path(['SupplierId', 'selected'], action.filters),
      materials: R.path(['MaterialNumber', 'selected'], action.filters),
      statuses: R.path(['StatusId', 'selected'], action.filters),
      businessUnits: R.path(['BusinessUnits', 'selected'], action.filters),
      strategicBuyers: R.path(['StrategicBuyers', 'selected'], action.filters),
      supplierDiversity: R.path(['SupplierDiversity', 'selected'], action.filters),
      wayOfBuying: R.path(['WayOfBuying', 'selected'], action.filters),
      scope: R.path(['Scope', 'selected'], action.filters),
      showObsolete: R.path(['ShowObsolete', 'selected'], action.filters),
      supplierDescription: R.path(['supplierDescription', 'selected'], action.filters),
      pslCreationAndValidityDate: (dateRange.startDate && dateRange.endDate) ?
        {
          startDate: moment.utc(dateRange.startDate, 'YYYYMMDD'),
          endDate: moment.utc(dateRange.endDate, 'YYYYMMDD'),
          type: dateRange.type
        } : null,
    };
    yield call(UserService.savePslFilters, filters);
    yield put(new SavePslFiltersSuccess(filters));
  } catch (error) {
    yield put(new SavePslFiltersError(error));
  }
}

function* watchGetFilters() {
  yield takeLatest(QueryPSLActions.onLoadFilters, fetch);
}

function* watchSaveFilters() {
  yield takeLatest(QueryPSLActions.onSaveFilters, saveFilters);
}

export default function* pslFilterSaga() {
  yield all([
    fork(watchGetFilters),
    fork(watchSaveFilters),
  ])
};
