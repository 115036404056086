// import { IdNo } from "./contract/contract.service";
import { TextFilter } from "../../../shared/domains/query/filter";
import { AxiosWrapper } from "../../../shared/utils/axios.wrapper";

export enum PAFilterKeys {
  SAP_PR_NUMBER = 'purchaseReqNo',
  REQUESTOR = 'prCreatorName',
  COMPANIES = 'companies',
  VENDOR = 'supplier',
  PURCHASING_GROUP = 'purchGroup'
}
export const PurchasingApprovalsFilters = [
  { key: PAFilterKeys.SAP_PR_NUMBER, label: 'SAP PR Number', sapLabel: "SapPR" },
  { key: PAFilterKeys.REQUESTOR, label: 'Spender', sapLabel: "Requestor" },
  { key: PAFilterKeys.COMPANIES, label: 'Company Code', sapLabel: "CompCode" },
  { key: PAFilterKeys.VENDOR, label: 'Vendor', sapLabel: "FixedVendor" },
  { key: PAFilterKeys.PURCHASING_GROUP, label: 'Purchasing Group', sapLabel: "PurchaseGroup" }
];

export const enum ProcurementApprovalFilterTypes {
  COMPANIES = "companies",
  PGROUPS = "pGroups",
  PORGS = "pOrgs",
  DOCTYPES = "docTypes",
  MGROUPS = "mGroups",
  MATERIALS = "materials",
  PLANTS = "plants",
  VALIDITY = "validity",
  STRATEGICBUYERS = "strategicBuyers"
}

export interface ContractFilterTyped extends TextFilter {
  kind: ProcurementApprovalFilterTypes
}

export interface ListResponse<T> {
  count: number;
  data: T[]
}


export class PAFilterService {
  static async fetchAllFilters(withMultiOrigin: string): Promise<any[]> {
    const response = await AxiosWrapper.get(`/api/Contracts/GetFilters`,
      {
        headers:
          { 'X-SystemAlias': withMultiOrigin }
      }
    );
    return response.data;
  }
}
