import "antd/dist/antd.css";
import { TreeSelect } from "antd";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        treeSelectClass: {
            "&& div": {
                height: "100%"
            },
            "&& .ant-select-selector": {
                color: "rgba(0, 0, 0, 0.65)"
            }
        }
    })
);
const mbeChilds = [
    {
        "title": "AFRICAN-AMERICAN",
        "value": "MBE : AFRICAN-AMERICAN",
    },
    {
        "title": "ASIAN-INDIAN AMERICAN",
        "value": "MBE : ASIAN-INDIAN AMERICAN",
    },
    {
        "title": "ALASKAN NATIVE CORP AND INDIAN",
        "value": "MBE : ALASKAN NATIVE CORP AND INDIAN",
    },
    {
        "title": "ASIAN PACIFIC-AMERICAN",
        "value": "MBE : ASIAN PACIFIC-AMERICAN",
    },
    {
        "title": "ASIAN-INDIAN",
        "value": "MBE : ASIAN-INDIAN",
    },
    {
        "title": "CAUCASIAN",
        "value": "MBE : CAUCASIAN",
    },
    {
        "title": "HISPANIC-AMERICAN",
        "value": "MBE : HISPANIC-AMERICAN",
    },
    {
        "title": "NATIVE-AMERICAN",
        "value": "MBE : NATIVE-AMERICAN",
    },
    {
        "title": "NO RACE",
        "value": "MBE : NO RACE",
    }
];
const supplierDiversityTreeData = [
    {
        "title": "LGBTE: LGBT BUSINESS ENTERPRISE",
        "value": "LGBTE",
    },
    {
        "title": "WBE: WOMAN BUSINESS ENTERPRISE",
        "value": "WBE",
    }, {
        "title": "VBE: VETERAN BUSINESS ENTERPRISE",
        "value": "VBE",
    }, {
        "title": "DOBE: DISABLED OWNED BUSINESS ENTERPRISE",
        "value": "DOBE",
    },
    {
        "title": "MINORITY BUSINESS ENTERPRISE",
        "value": "MBE",
        "children": mbeChilds
    }
];
export const SupplierDiversityFilterComponent = ({ onChange, initialValue }) => {
    const classes = useStyles({});

    const [selectedSuppDiversity, setSelectedSuppDiversity] = React.useState(initialValue ? initialValue : []);

    const onChangeSuperDiversity = (
        value: any[]
    ) => {
        console.log([...selectedSuppDiversity, ...value]);
        setSelectedSuppDiversity(value);
        onChange(value);
    };

    const userScopeProps = {
        treeData: supplierDiversityTreeData,
        value: selectedSuppDiversity,
        onChange: onChangeSuperDiversity,
        disabled: false,
        treeCheckable: true,
        treeCheckStrictly: false,
        dropdownMatchSelectWidth: false,
        // labelInValue: true,
        treeNodeFilterProp: "title",
        showCheckedStrategy: TreeSelect.SHOW_PARENT,
        placeholder: "Click here to select Supplier Diversity",
        style: { width: "100%", minHeight: "38px" }
    };

    return (
        <TreeSelect
            {...userScopeProps}
            dropdownStyle={{ zIndex: 99999 }}
            className={classes.treeSelectClass}
        />
    );
}

export enum supplierDiversityLabels {
    "LGBTE: LGBT BUSINESS ENTERPRISE" = 'LGBTE',
    "WBE: WOMAN BUSINESS ENTERPRISE" = 'WBE',
    "VBE: VETERAN BUSINESS ENTERPRISE" = 'VBE',
    "DOBE: DISABLED OWNED BUSINESS ENTERPRISE" = 'DOBE',
    "MINORITY BUSINESS ENTERPRISE" = 'MBE',
    "AFRICAN-AMERICAN" = 'MBE : AFRICAN-AMERICAN',
    "ASIAN-INDIAN AMERICAN" = "MBE : ASIAN-INDIAN AMERICAN",
    "ALASKAN NATIVE CORP AND INDIAN" = "MBE : ALASKAN NATIVE CORP AND INDIAN",
    "ASIAN PACIFIC-AMERICAN" = "MBE : ASIAN PACIFIC-AMERICAN",
    "ASIAN-INDIAN" = "MBE : ASIAN-INDIAN",
    "CAUCASIAN" = "MBE : CAUCASIAN",
    "HISPANIC-AMERICAN" = "MBE : HISPANIC-AMERICAN",
    "NATIVE-AMERICAN" = "MBE : NATIVE-AMERICAN",
    "NO RACE" = "MBE : NO RACE"
}