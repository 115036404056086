import { assoc, flatten, filter, merge } from "ramda";
import { ApprovalHistoryActionTypes } from "../actions/approvalHistory.action";
import { IAction } from "../../../shared/domains/core/actions";
import { ApprovalHistoryQuery, ODataQuery } from "../domains/approvalHistory/query";
import { ApprovalHistoryRequest } from "../domains/approvalHistory/approvalHistory";

export interface ApprovalHistoryState {
	query: ODataQuery;
	ApprovalHistoryList: ApprovalHistoryRequest[];
	loading: boolean;
	itemLoading: boolean;
	error: any;
	totalCount: number;
	selectedRow: ApprovalHistoryRequest;
	purchaseRequestInfo: any;
	purchaseRequestAccount: any,
	purchaseRequestPricing: any,
	approvalItems: any,
}

export const initialApprovalHistoryState: ApprovalHistoryState = {
	query: ApprovalHistoryQuery(),
	ApprovalHistoryList: [],
	loading: true,
	itemLoading: true,
	error: null,
	totalCount: 0,
	selectedRow: null,
	purchaseRequestInfo: null,
	purchaseRequestAccount: [],
	purchaseRequestPricing: [],
	approvalItems: [],
};

const loading = { loading: true, error: null };
const emptyAndLoading = { loading: true, error: null, ApprovalHistoryList: [], totalCount: 0 };

export function ApprovalHistoryReducers(state: ApprovalHistoryState = initialApprovalHistoryState, action: IAction<ApprovalHistoryActionTypes>): ApprovalHistoryState {
	switch (action.type) {
		case ApprovalHistoryActionTypes.FETCH_AH:
			return {
				...state,
				loading: true,
				ApprovalHistoryList: [],
				totalCount: 0
			};
		case ApprovalHistoryActionTypes.FETCH_AH_SUCCESS:
			return {
				...state,
				loading: false,
				ApprovalHistoryList: action.items,
				totalCount: action.totalCount ? action.totalCount : 0,
				query: action.newQuery
			};
		case ApprovalHistoryActionTypes.NEW_AH_QUERY:
			assoc('query', action.query, state);
			const loaderState = { loading: true };
			return merge(state, loaderState);
		case ApprovalHistoryActionTypes.SET_SELECTED_AH:
			const partialState = { selectedRow: action.purchaseRequest, loading: false };
			return merge(state, partialState);
		case ApprovalHistoryActionTypes.FETCH_AH_DETAIL:
			return {
				...state,
				itemLoading: true,
			};
		case ApprovalHistoryActionTypes.FETCH_AH_DETAIL_SUCCESS:
			return {
				...state,
				itemLoading: false,
				purchaseRequestInfo: action.info,
				purchaseRequestAccount: action.accountList,
				purchaseRequestPricing: action.pricing,
			};
		case ApprovalHistoryActionTypes.FETCH_AH_LINES:
			return {
				...state,
				itemLoading: true
				/*ApprovalHistoryList: state.ApprovalHistoryList.map(purchaseRequest => purchaseRequest.SapPR === action.SapPR ?
		
					{ ...purchaseRequest, innerLoading: true } :
					purchaseRequest
				)*/
			};
		case ApprovalHistoryActionTypes.FETCH_AH_LINES_SUCCESS:

			return {
				...state,
				itemLoading: false,
				approvalItems: action.items
				/*ApprovalHistoryList: state.ApprovalHistoryList.map(purchaseRequest => purchaseRequest.Aribapr === action.Aribapr ?
		
					{ ...purchaseRequest, PRLines: action.items, innerLoading: false } :
					purchaseRequest
				)*/
			};

		case ApprovalHistoryActionTypes.FETCH_AH_LINES_FAILURE:
			return {
				...state,
				/*ApprovalHistoryList: state.ApprovalHistoryList.map(purchaseRequest => purchaseRequest.Aribapr === action.error.Aribapr ?
		
					{ ...purchaseRequest, innerLoading: false } :
					purchaseRequest
				),*/
				error: action.error,
				itemLoading: false
			};

		case ApprovalHistoryActionTypes.FETCH_AH_FAILURE:
			const failurePartialState = { error: action.error, loading: false };
			return merge(state, failurePartialState);

		default:
			return state;
	}
}