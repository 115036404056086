import {Action, AzureErrorAction, ErrorAction, PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {CodeNameDTO, ContractCalendarEventInfo} from "../domains/calendar";

export enum TenderCalendarFilterActionTypes {
  FETCH_INFO_FOR_NEW_EVENT = "FETCH_INFO_FOR_NEW_EVENT",
  FETCH_INFO_FOR_NEW_EVENT_SUCCESS = "FETCH_INFO_FOR_NEW_EVENT_SUCCESS",
  FETCH_INFO_FOR_NEW_EVENT_FAILURE = "FETCH_INFO_FOR_NEW_EVENT_FAILURE",

  FETCH_INITIAL_EVENTS = "FETCH_INITIAL_EVENTS",

  SYNCHRONIZE_FILTERS = "SYNCHRONIZE_FILTERS",
  SYNCHRONIZE_FILTERS_SUCCESS = "SYNCHRONIZE_FILTERS_SUCCESS",
  SYNCHRONIZE_FILTERS_FAILURE = "SYNCHRONIZE_FILTERS_FAILURE",

  FETCH_HIERARCHY_MARKETS_BY_ZONE = "FETCH_HIERARCHY_MARKETS_BY_ZONE",
  FETCH_HIERARCHY_MARKETS_BY_ZONE_SUCCESS = "FETCH_HIERARCHY_MARKETS_BY_ZONE_SUCCESS",
  FETCH_HIERARCHY_MARKETS_BY_ZONE_FAILURE = "FETCH_HIERARCHY_MARKETS_BY_ZONE_FAILURE",

  FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET = "FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET",
  FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET_SUCCESS = "FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET_SUCCESS",
  FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET_FAILURE = "FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET_FAILURE",

  FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE = "FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE",
  FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE_SUCCESS = "FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE_SUCCESS",
  FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE_FAILURE = "FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE_FAILURE",

  DELETE_CALENDAR_FILTER = "DELETE_CALENDAR_FILTER",
  RESET_CALENDAR_FILTER = "RESET_CALENDAR_FILTER",
  RESTORE_CALENDAR_FILTERS = "RESTORE_CALENDAR_FILTERS"
}

export class FetchInitialEvents extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_INITIAL_EVENTS;
}

export class FetchInfoForNewEvent extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT;
}

export class FetchInfoForNewEventSuccess extends SuccessAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT_SUCCESS;

  constructor(public data: ContractCalendarEventInfo) {
    super();
  }
}

export class FetchInfoForNewEventError extends ErrorAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_INFO_FOR_NEW_EVENT_FAILURE;
}

export class SynchronizeFilters extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS;

  constructor(public isSync: boolean) {
    super();
  }
}

export class SynchronizeFiltersSuccess extends SuccessAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS_SUCCESS;
}

export class SynchronizeFiltersError extends ErrorAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.SYNCHRONIZE_FILTERS_FAILURE;
}

export class RestoreCalendarFilters extends Action<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.RESTORE_CALENDAR_FILTERS;

  constructor(public filters) {
    super();
  }
}

export class ResetCalendarFilters extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.RESET_CALENDAR_FILTER;
}

export class DeleteCalendarFilter extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.DELETE_CALENDAR_FILTER;

  constructor(public id, public key) {
    super();
  }
}


export class FetchHierarchyMarketsByZone extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_MARKETS_BY_ZONE;

  constructor(public zoneCodes: string[]) {
    super();
  }
}

export class FetchHierarchyMarketsByZoneSuccess extends SuccessAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_MARKETS_BY_ZONE_SUCCESS;

  constructor(public markets: CodeNameDTO[]) {
    super();
  }
}

export class FetchHierarchyMarketsByZoneError extends AzureErrorAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_MARKETS_BY_ZONE_FAILURE;
}

export class FetchHierarchyCompanyCodesByMarket extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET;

  constructor(public markets: string[]) {
    super();
  }
}

export class FetchHierarchyCompanyCodesByMarketSuccess extends SuccessAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET_SUCCESS;

  constructor(public companyCodes: CodeNameDTO[]) {
    super();
  }
}

export class FetchHierarchyCompanyCodesByMarketError extends AzureErrorAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_COMPANY_CODES_BY_MARKET_FAILURE;
}

export class FetchHierarchyPlantsByCompanyCode extends PendingAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE;

  constructor(public companyCodes: string[]) {
    super();
  }
}

export class FetchHierarchyPlantsByCompanyCodeSuccess extends SuccessAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE_SUCCESS;

  constructor(public plants: CodeNameDTO[]) {
    super();
  }
}

export class FetchHierarchyPlantsByCompanyCodeError extends AzureErrorAction<TenderCalendarFilterActionTypes> {
  public readonly type = TenderCalendarFilterActionTypes.FETCH_HIERARCHY_PLANT_BY_COMPANY_CODE_FAILURE;
}

export type TenderCalendarFilterActions =
  |FetchInitialEvents
  |FetchInfoForNewEvent
  |FetchInfoForNewEventSuccess
  |FetchInfoForNewEventError
  |SynchronizeFilters
  |SynchronizeFiltersSuccess
  |SynchronizeFiltersError
  |RestoreCalendarFilters
  |ResetCalendarFilters
  |DeleteCalendarFilter
  |FetchHierarchyMarketsByZone
  |FetchHierarchyMarketsByZoneSuccess
  |FetchHierarchyMarketsByZoneError
  |FetchHierarchyCompanyCodesByMarket
  |FetchHierarchyCompanyCodesByMarketSuccess
  |FetchHierarchyCompanyCodesByMarketError
  |FetchHierarchyPlantsByCompanyCode
  |FetchHierarchyPlantsByCompanyCodeSuccess
  |FetchHierarchyPlantsByCompanyCodeError;
