export interface IEnvironment {
  REACT_APP_TENANT_ID: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_API_BASE: string;
  REACT_APP_MRP_IMPORT_FILE_TIMEOUT: string;
  REACT_APP_MRP_EXPORT_FILE_TIMEOUT: string;
  CHATBOT_URL: string;
  REACT_APP_EASY_REQUEST: string;
  DESCRIBE_YOUR_NEED: string;
  AMC_URL: string;
  EASY_REQUEST_URL: string;
}

export const environment: IEnvironment = {
  local: {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "bd6bc09e-c56a-4f54-8901-f643e17d5436",
    REACT_APP_API_BASE: "http://localhost:3001",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: 15000,
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: 1500,
    EASY_REQUEST_URL: "https://ezpr-codev.fluidapp.io/",
  },
  local2: {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "bd6bc09e-c56a-4f54-8901-f643e17d5436",
    REACT_APP_API_BASE: "https://localhost:5001",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: 15000,
    AMC_URL: "https://amc-codev.fluidapp.io/",
    EASY_REQUEST_URL: "https://ezpr-codev.fluidapp.io/",
  },
  debug: {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "bd6bc09e-c56a-4f54-8901-f643e17d5436",
    REACT_APP_API_BASE: "https://localhost:5000",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: 15000,
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: 1500,
    CHATBOT_URL: "https://webchat.botframework.com/embed/qnaknowledgeservice-bot?s=SaYSGWmKWHI._Em3-AG2SnI9FGZv7h-H4cQiOkh4yGxcM86wRPnDK3Q",
    REACT_APP_EASY_REQUEST: "https://ezpr-codev.fluidapp.io",
    DESCRIBE_YOUR_NEED: "https://ezpr-codev.fluidapp.io/?#createNewRequest=true",
    AMC_URL: "https://amc-codev.fluidapp.io/",
    EASY_REQUEST_URL: "https://ezpr-codev.fluidapp.io/",
  },
  dev: {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "bd6bc09e-c56a-4f54-8901-f643e17d5436",
    REACT_APP_API_BASE: "https://glbl-dv-contractbrowser-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: 15000,
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: 1500,
    CHATBOT_URL: "https://webchat.botframework.com/embed/qnaknowledgeservice-bot?s=SaYSGWmKWHI._Em3-AG2SnI9FGZv7h-H4cQiOkh4yGxcM86wRPnDK3Q",
    REACT_APP_EASY_REQUEST: "https://ezpr-codev.fluidapp.io",
    DESCRIBE_YOUR_NEED: "https://ezpr-codev.fluidapp.io/?#createNewRequest=true",
    AMC_URL: "https://amc-codev.fluidapp.io/",
    EASY_REQUEST_URL: "https://ezpr-codev.fluidapp.io/",
  },
  rte: {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "9d93e2a9-116b-4274-b277-421ba8b44d50",
    REACT_APP_API_BASE: "https://emna-np-contractbrowser-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: 30000,
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: 3000,
    CHATBOT_URL: "https://webchat.botframework.com/embed/qnaknowledgeservice-bot?s=SaYSGWmKWHI._Em3-AG2SnI9FGZv7h-H4cQiOkh4yGxcM86wRPnDK3Q",
    REACT_APP_EASY_REQUEST: "https://easy-request-preprod.fluidapp.io",
    DESCRIBE_YOUR_NEED: "https://easy-request-preprod.fluidapp.io/#createNewRequest=true",
    AMC_URL: "https://amc-reg.fluidapp.io/",
    EASY_REQUEST_URL: "https://easy-request-preprod.fluidapp.io/",
  },
  test: {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "99caa03f-cecf-4be8-b7f8-be2a6e8b8879",
    REACT_APP_API_BASE: "https://glbl-pp-contractbrowser-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: 25000,
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: 2500,
    CHATBOT_URL: "https://webchat.botframework.com/embed/qnaknowledgeservice-bot?s=SaYSGWmKWHI._Em3-AG2SnI9FGZv7h-H4cQiOkh4yGxcM86wRPnDK3Q",
    REACT_APP_EASY_REQUEST: "https://easy-request-preprod.fluidapp.io",
    DESCRIBE_YOUR_NEED: "https://easy-request-preprod.fluidapp.io/#createNewRequest=true",
    AMC_URL: "https://amc-preprod.fluidapp.io/",
    EASY_REQUEST_URL: "https://easy-request-preprod.fluidapp.io/",
  },
  prod: {
    REACT_APP_TENANT_ID: "12a3af23-a769-4654-847f-958f3d479f4a",
    REACT_APP_CLIENT_ID: "cfae22b0-3ec2-47a5-8df2-133612446fe9",
    REACT_APP_API_BASE: "https://glbl-pr-contractbrowser-webapi-euwe-001-app.azurewebsites.net",
    REACT_APP_MRP_IMPORT_FILE_TIMEOUT: 30000,
    REACT_APP_MRP_EXPORT_FILE_TIMEOUT: 3000,
    CHATBOT_URL: "https://webchat.botframework.com/embed/glbl-pr-contractbrowser-euwe-003-bot?s=JwlHfy6KLv4.5U8UrtULzbEyZaxBhVS7XN70HK5bqC2CThs5wUBqAVM",
    REACT_APP_EASY_REQUEST: "https://easy-request.fluidapp.io",
    DESCRIBE_YOUR_NEED: "https://easy-request.fluidapp.io/#createNewRequest=true",
    AMC_URL: "https://amc.fluidapp.io/",
    EASY_REQUEST_URL: "https://easy-request.fluidapp.io/",
  }
}[process.env.REACT_APP_ENV || 'dev'];
