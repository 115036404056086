import {
    ArchivedOptions,
    MyTasksAtMarketTabs,
    PendingOptions,
    PslComplianceStatus,
    PslStatus,
    SupplierFilterTabs,
    SupplierStatus,
    SupplierTabs
} from "../../domains/psl";
import {
    BigStyledTab,
    HorizontalView,
    StyledDialogContent,
    StyledTab,
    StyledTabs,
    TabsContainer,
    Toolbar
} from "../../../shared/components/layout/styles";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FetchPSL, PSLTypes, UpdateListTabs } from "../../actions/psl.actions";
import { QueryPSLActions } from "../../reducers/pslList.reducer";
import { QueryPslApproverActions } from "../../reducers/pslApproverList.reducer";
import { User } from "../../../shared/domains/user/user";
import styled from "styled-components";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCartOutlined";
import IconButton from "@material-ui/core/IconButton";
import { Badge, Button, FormControl, Popover, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { useFetchCounter } from "../../domains/easyRequest.hooks";
import { FeaturesContext } from "../../../shared/domains/core/pataflag.context";
import { environment } from "../../../shared/domains/core/environment";
import { PrimaryButton } from "src/app/shared/components/button";
import { UserInteractionType } from "../../../shared/domains/user/userInteractionType";
import { DocumentFlowService } from "../../../docFlows/domains/documentFlow/documentFlow.service";
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { RootStateStore } from "src/app/application.reducers";
import { makeStyles } from "@material-ui/core";
import { Features } from "src/app/shared/domains/core/pataflag";
import { getMaterialPslSearchValues } from "../../containers/list/preferredSuppliers.page";
import { PataflagService } from "src/app/shared/services/pataflag.service";

interface Props {
    listLoading: boolean;
    localizeTab;
    setLocalizeTab;
    mainTab;
    setMainTab;
    filterTab;
    taskTab: PslComplianceStatus;
    approvalTab;
    setTaskTab;
    setFilterTab;
    setApprovalTab;
    filters;
    user: User;
    setSelectedTaskPslIds;
    children;
    exactIdSearch;
    isFavouriteActive: boolean;
    searchCategory: { label: string, value: string } | null;
    validateSearchCatOption: (currentTab: SupplierTabs) => void;
    searchInputTerm: string;
    isFromUrl?;
    setIsFromUrl?;
}

const MyTasksLinks = {
    [PslComplianceStatus.Compliance]: "/psl",
    [PslComplianceStatus.canLocalize]: "/psl",
    [PslComplianceStatus.canLocalize]: "/psl",
    [PslComplianceStatus.CannotLocalize]: "/psl",
    [PslComplianceStatus.Approval]: "/psl",
    [PslComplianceStatus.Overall]: "/psl",
    [PslComplianceStatus.Justified]: "/psl",
    [PslComplianceStatus.NotJustified]: "/psl",
};

const myTasksLocalizationFilters = (value) => ({
    ComplianceStatuses: null,
    PslStatuses: { selected: [{ id: PslStatus.Pending }] },
    statusId: [],
    taskKind: [13, 14].includes(value) ? MyTasksAtMarketTabs.LOCALIZATION : "LOCALIZATION_CANNOT",
    time: SupplierFilterTabs.ACTIVE,
    createdByUserId: null
});
const myTasksComplianceFilters = (userId: number) => ({
    ComplianceStatuses: null,
    PslStatuses: { selected: [{ id: PslStatus.Rejected }] },
    statusId: [],
    taskKind: MyTasksAtMarketTabs.COMPLIANCE,
    time: SupplierFilterTabs.ACTIVE,
    createdByUserId: userId
});
export const myTasksApproverFilters = (
    userId: number,
    complianceStatus: PslComplianceStatus
) => ({
    ComplianceStatuses: { selected: [{ id: complianceStatus }] },
    PslStatuses: { selected: [{ id: PslStatus.Pending }] },
    statusId: {
        selected: [
            { id: SupplierStatus.MANDATORY },
            { id: SupplierStatus.SOLE_SOURCE },
            { id: SupplierStatus.VALIDATED }
        ]
    },
    taskKind: MyTasksAtMarketTabs.APPROVAL,
    time: SupplierFilterTabs.ACTIVE,
    createdByUserId: userId
});


const PENDING_OPTIONS_ARR = [
    { value: PendingOptions.PENDING_APPROVAL, label: "1. Pending Approval" },
    { value: PendingOptions.PENDING_LOCALIZATION, label: "2. Pending Localization" }
]

const ARCHIVED_OPTIONS = [
    { value: ArchivedOptions.EXPIRED, label: "1. Expired" },
    { value: ArchivedOptions.DELETED, label: "2. Deleted" }
]
const ARCHIVED_OPTIONS_WITH_OBSOLETE = [
    { value: ArchivedOptions.EXPIRED, label: "1. Expired" },
    { value: ArchivedOptions.DELETED, label: "2. Deleted" },
    { value: ArchivedOptions.OBSOLETE, label: "3. Obsolete" },
]

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

const DrowpDownComponent = ({ mainTab, filterTab, optionsArr, filterTabStatic, setFilterTab, getNewFilters }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null | boolean>(null);
    const [optionsIndex, setOptionsIndex] = React.useState('')
    const [selectedValue, setSelectedValue] = React.useState('')
    const popoverAnchor = useRef(null);
    const dispatch = useDispatch();
    const classes = useStyles({})

    React.useEffect(() => {
        setOptionsIndex(calculateIndex(selectedValue).toString())
    }, [selectedValue])

    React.useEffect(() => {
        setSelectedValue('')
    }, [mainTab])

    React.useEffect(() => {
        if (filterTab !== filterTabStatic) {
            setSelectedValue('')
        }
    }, [filterTab])

    function calculateIndex(options) {

        let index = optionsArr.findIndex(el => {
            return el.value === options
        })

        if (index === -1) return ''
        return index + 1

    }

    const changeHandler = (event) => {
        let newFilters = getNewFilters(mainTab, filterTabStatic)
        setSelectedValue(event.target.value)
        setAnchorEl(null);

        newFilters = {
            ...newFilters, taskKind: event.target.value
        };
        dispatch(new QueryPSLActions.newFilters(newFilters, mainTab));
        setFilterTab(filterTabStatic)

    }

    const clearHandler = () => {
        // setOptions('')
        setSelectedValue('')
        let newFilters = getNewFilters(mainTab, filterTabStatic)

        newFilters = {
            ...newFilters, taskKind: null
        };

        dispatch(new QueryPSLActions.newFilters(newFilters, mainTab));

        setAnchorEl(null)

    }

    function showDropDown(event) {
        if (event.type === 'click') {
            filterTab === filterTabStatic && setAnchorEl(event.currentTarget);
            return;
        } else
            setAnchorEl(event.currentTarget);
    }

    return <>
        <Button
            ref={popoverAnchor}
            style={{ backgroundColor: 'transparent', position: 'relative' }}
            variant="text"
            color="inherit"
            onClick={(event) => showDropDown(event)}
            onMouseEnter={(event) => showDropDown(event)}
            onMouseLeave={() => setAnchorEl(null)}

            endIcon={<KeyboardArrowDownOutlined style={{ fontSize: 18 }} />}>
            <span style={{ marginRight: -8, fontSize: '0.8125rem' }}>{filterTabStatic}</span>
            <div style={{ position: 'absolute', fontSize: 10, right: 0, top: 0 }}>
                {!!optionsIndex ? <Tooltip
                    title={optionsArr[parseInt(optionsIndex) - 1].label}
                    // {optionsIndex === '1'? '1. Pending Approval': '2. Pending Localization'}
                    placement="right">
                    <span>{optionsIndex}</span>
                </Tooltip> :
                    <>{optionsIndex}</>
                }
            </div>
        </Button>

        <Popover
            open={Boolean(anchorEl)}
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
                paper: classes.popoverContent,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            anchorEl={popoverAnchor.current}
            onClose={() => setAnchorEl(null)}
            PaperProps={{ onMouseEnter: () => setAnchorEl(true), onMouseLeave: () => setAnchorEl(null) }}
        >
            <div style={{ position: 'relative' }}>
                {!!selectedValue &&
                    <span style={{
                        position: 'absolute', right: 10, top: 0, color: 'orange',
                        fontSize: 14, cursor: 'pointer', zIndex: 99
                    }}
                        onClick={clearHandler}>clear</span>}
                <StyledDialogContent style={{ fontSize: 11, padding: 10, paddingTop: 15 }}>
                    <FormControl component="fieldset" fullWidth={true}>
                        <RadioGroup aria-label="drop_down_options" name="drop_down_options"
                            value={selectedValue}
                            onChange={changeHandler}>
                            {optionsArr.map((el, i) => {
                                return <FormControlLabel key={el + i.toString()} value={el.value} control={<Radio />}
                                    label={el.label}
                                />
                            })}
                        </RadioGroup>
                    </FormControl>
                </StyledDialogContent>
            </div>
        </Popover>
    </>
}

interface StateInterface {
    blankTab: any,
    soleTab: any,
    mandatoryTab: any,
    validatedTab: any,
    overallTab: any,
    isShowTaskTab: boolean,
    selectedTabs: any[],
    isShowLocalization: boolean,
    isApprover: boolean
}

enum ActionTypeEnum {
    CHANGE_STATUS_TABS = 'CHANGE_STATUS_TABS',
    PENDING_FILTER_TAB_SELECTED = "PENDING_FILTER_TAB_SELECTED",
    FILTER_TAB_CHANGED = "FILTER_TAB_CHANGED"
}

type payloadType = 'blankTab' | 'mandatoryTab' | 'soleTab' | 'validatedTab' | 'overallTab'
type Action = { type: ActionTypeEnum.CHANGE_STATUS_TABS, payload: StateInterface } |
{ type: ActionTypeEnum.PENDING_FILTER_TAB_SELECTED } |
{ type: ActionTypeEnum.FILTER_TAB_CHANGED, payload: SupplierFilterTabs }

const initialDefaultState = {
    blankTab: { id: SupplierStatus.BLANK, active: false, label: 'BLANK' },
    soleTab: { id: SupplierStatus.SOLE_SOURCE, active: false, label: 'SOLE SOURCE' },
    mandatoryTab: { id: SupplierStatus.MANDATORY, active: false, label: 'MANDATORY' },
    validatedTab: { id: SupplierStatus.VALIDATED, active: false, label: 'VALIDATED' },
    overallTab: { active: true, label: 'OVERALL' },
    selectedTabs: []
}

const defaultStaticState = () => {
    const PSL_163766_CACHE_SEARCH_OPTIONS = PataflagService.getInstance().flag(Features.PSL_163766_CACHE_SEARCH_OPTIONS);
    const pslDefaultOptions = JSON.parse(localStorage.getItem("tabsReducer"));

    if(PSL_163766_CACHE_SEARCH_OPTIONS && pslDefaultOptions?.tabsState) {
        return pslDefaultOptions.tabsState;
    } else {
        return initialDefaultState
    }
}

function defineInitialState(user: User): StateInterface {
    const isRequestor = user.isRequestor();
    const isViewOnly = user.isViewOnly();
    const isFactoryAnalyst = user.isFactoryAnalyst();

    let isShowTaskTab = !(isRequestor || isFactoryAnalyst || isViewOnly)
    let isShowLocalization = (user.hasAtMarketRole() && isShowTaskTab)

    return {
        ...defaultStaticState(),
        isShowTaskTab: isShowTaskTab,
        isShowLocalization: isShowLocalization,
        isApprover: user.canApprove()
    }
}

function reducerMethod(state: StateInterface, action: Action) {
    switch (action.type) {
        case ActionTypeEnum.CHANGE_STATUS_TABS: {
            return action.payload
        }
        case ActionTypeEnum.PENDING_FILTER_TAB_SELECTED:
            return { ...state, ...initialDefaultState, isStatusTabChanged: false }
        case ActionTypeEnum.FILTER_TAB_CHANGED:
            if (action.payload === SupplierFilterTabs.PENDING) {
                return {
                    ...state, ...initialDefaultState,
                    isFilterTabChanged: true,
                    isStatusTabChanged: false,
                }
            }
            return {
                ...state,
                isFilterTabChanged: true,
                isStatusTabChanged: false
            }
        default:
            return state;
    }
}

export function PslTabsComponent({
    listLoading,
    mainTab,
    setMainTab,
    filterTab,
    setFilterTab,
    setApprovalTab,
    localizeTab,
    setLocalizeTab,
    taskTab,
    approvalTab,
    setTaskTab,
    filters,
    user,
    setSelectedTaskPslIds,
    children,
    exactIdSearch,
    isFavouriteActive,
    searchCategory,
    searchInputTerm,
    validateSearchCatOption
}: Props) {
    const dispatch = useDispatch();
    const { hasFeature } = React.useContext(FeaturesContext);

    const history = useHistory();
    const approvalQuery = useSelector((state: RootStateStore) => state.psl.pslApproverList.query);
    const userRole = user.getUserRole();
    const isRequestor = user.isRequestor();
    const isViewOnly = user.isViewOnly();
    const isAtMarket = user.isAtMarket();
    const isFactoryAnalyst = user.isFactoryAnalyst();
    const [showDesYourNeedBtn] = React.useState(() => !isViewOnly)
    const [tabsState, dispatchMethod] = React.useReducer(reducerMethod, null, () => defineInitialState(user))

    const {
        blankTab: blankTabState,
        soleTab: soleTabState,
        mandatoryTab: mandatoryTabState,
        validatedTab: validatedTabState,
        overallTab: overallTabState,
        selectedTabs: selectedTabsState,
        isShowLocalization: isShowLocalizationState,
        isShowTaskTab: isShowTaskTabState,
        isApprover: isApproverState
    } = tabsState;

    useEffect(() => {
        if (hasFeature(Features.PSL_163766_CACHE_SEARCH_OPTIONS)) {
            const tabsReducer = { tabsState };
            localStorage.setItem("tabsReducer", JSON.stringify(tabsReducer))
        }
    }, [tabsState]);

    let showMyTasksAllSubTabs = (mainTab === SupplierTabs.MY_TASKS && isShowTaskTabState);
    const pslListQuery = useSelector((state: RootStateStore) => state.psl.pslList.query);


    const PendingFilters = () => ({
        ComplianceStatuses: null,
        PslStatuses: { selected: [{ id: PslStatus.Pending }] },
        statusId: { selected: [] },
        taskKind: null,
        time: SupplierFilterTabs.ACTIVE,
        createdByUserId: null
    });
    const OverallFilters = () => ({
        ComplianceStatuses: null,
        PslStatuses: { selected: [] },
        statusId: { selected: selectedTabsState },
        taskKind: null,
        time: null,
        createdByUserId: null
    });
    const ArchivedFilters = () => ({
        ComplianceStatuses: null,
        PslStatuses: { selected: [] },
        statusId: { selected: selectedTabsState },
        taskKind: null,
        time: SupplierFilterTabs.ARCHIVED,
        createdByUserId: null
    });
    const ActiveFilters = () => ({
        ComplianceStatuses: null,
        PslStatuses: { selected: [{ id: PslStatus.Approved }] },
        statusId: { selected: selectedTabsState },
        taskKind: null,
        time: SupplierFilterTabs.ACTIVE,
        createdByUserId: null
    });
    const ExpiringFilters = () => ({
        ComplianceStatuses: null,
        PslStatuses: { selected: [{ id: PslStatus.Approved }] },
        statusId: { selected: selectedTabsState },
        taskKind: null,
        time: SupplierFilterTabs.EXPIRING,
        createdByUserId: null
    });

    const showLocalizationSubTabs = (taskTab === PslComplianceStatus.Localization ||
        taskTab === PslComplianceStatus.canLocalize ||
        taskTab === PslComplianceStatus.CannotLocalize) && isShowLocalizationState

    const showApproversubTabs = mainTab === SupplierTabs.MY_TASKS &&
        (taskTab !== PslComplianceStatus.Compliance
            && taskTab !== PslComplianceStatus.Localization
            && taskTab !== PslComplianceStatus.canLocalize &&
            taskTab !== PslComplianceStatus.CannotLocalize
        )
        && isApproverState

    const myTasksAsApproverFilters = (complianceStatus: number) => ({ ...filters, ...myTasksApproverFilters(user.id, complianceStatus) });
    const myTasksComplianceListFilters = () => ({
        ...filters,
        ...myTasksComplianceFilters(user.id)
    });
    const myTasksLocalizationListFilters = (value) => ({
        ...filters,
        ...myTasksLocalizationFilters(value)
    });
    const { data } = useFetchCounter();

    useEffect(() => {
        fetchData(mainTab, taskTab);
    }, [mainTab, taskTab]);


    function fetchData(mainTabValue: SupplierTabs, taskTabVal) {
        if (!!!mainTabValue) return;

        let taskTabValue = taskTabVal !== null ? taskTabVal : PslComplianceStatus.Compliance
        setSelectedTaskPslIds([]);

        // for My tasks
        if (mainTabValue === SupplierTabs.MY_TASKS) {
            if (!isShowTaskTabState) {
                return;
            }

            if (taskTabValue === PslComplianceStatus.Overall || taskTabValue === PslComplianceStatus.Justified || taskTabValue === PslComplianceStatus.NotJustified) {
                if (!isApproverState) {
                    dispatch({ type: PSLTypes.SHOW_EMPTY_DATA })
                    return
                }
                onMyTasksAsApproverTabChange2(taskTabValue)
            }

            if (taskTabValue === PslComplianceStatus.Compliance)
                onMyTaskAsAtMarketTabChange(PslComplianceStatus.Compliance);

            if (taskTabValue === PslComplianceStatus.Localization || taskTabValue === PslComplianceStatus.canLocalize) {
                if (!isShowLocalizationState) {
                    return;
                }

                onLocalizationTabChange(PslComplianceStatus.canLocalize);
            }

            if (taskTabValue === PslComplianceStatus.CannotLocalize) {
                if (!isShowLocalizationState) {
                    return;
                }
                onLocalizationTabChange(PslComplianceStatus.CannotLocalize)
            }
            dispatch(new UpdateListTabs([mainTabValue, filterTab]));
            return;
        }

        // for Suppliers and Materials
        history.push({ pathname: "/psl" });
        setApprovalTab(false);
        const newFilters = getNewFilters(mainTabValue, filterTab);

        let pslSearch = null;
        let materialSearch = null;
        if (hasFeature(Features.psl_162778_search_state_mgmt)) {
            [materialSearch, pslSearch] = getMaterialPslSearchValues(mainTab, searchCategory, searchInputTerm);
        }
        dispatch(new FetchPSL(mainTabValue, userRole, exactIdSearch, newFilters, pslSearch, materialSearch, mainTab === SupplierTabs.MY_TASKS ? false : isFavouriteActive));
        dispatch(new UpdateListTabs([mainTabValue, filterTab]));
    }

    const onFilterTabChange = (value: SupplierFilterTabs, e) => {
        if (listLoading) return null;
        if (value === filterTab) return;

        const newFilters = getNewFilters(mainTab, value);
        dispatch(new QueryPSLActions.newFilters(newFilters, mainTab));

        dispatchMethod({ type: ActionTypeEnum.FILTER_TAB_CHANGED, payload: value })
        setFilterTab(value);
    };

    function onStatusTabChange(statusTab: payloadType) {
        let clickedTab = statusTab;
        let selectedTabs = tabsState.selectedTabs;
        let activeStatus = tabsState[clickedTab].active
        let statusId = tabsState[clickedTab].id
        let noChange = selectedTabs.length === 1 && selectedTabs[0].id === statusId
        let selectedTabsUpdate = activeStatus ? selectedTabs.filter(el => el.id !== statusId) :
            statusId ? selectedTabs.concat({ id: statusId }) : []

        let payload;

        if (noChange) return;

        if (clickedTab === 'overallTab') {
            payload = { ...tabsState, ...initialDefaultState, isStatusTabChanged: true }
        } else {
            payload = {
                ...tabsState,
                [clickedTab]: { ...tabsState[clickedTab], active: !activeStatus },
                selectedTabs: selectedTabsUpdate,
                overallTab: { active: false, label: 'OVERALL' },
                isStatusTabChanged: true
            }

        }

        let newFilters = { ...filters, statusId: { selected: selectedTabsUpdate } }
        dispatch(new QueryPSLActions.newFilters(newFilters, mainTab));

        dispatchMethod({ type: ActionTypeEnum.CHANGE_STATUS_TABS, payload: payload })
    }

    const getNewFilters = (mainTab: SupplierTabs, value: SupplierFilterTabs) => {
        if (mainTab === SupplierTabs.MY_TASKS && approvalTab) {
            return myTasksAsApproverFilters(PslComplianceStatus.Overall);
        }
        if (mainTab === SupplierTabs.MY_TASKS && isAtMarket) {
            return myTasksComplianceListFilters();
        }

        let tabFilters;
        switch (value) {
            case SupplierFilterTabs.PENDING:
                tabFilters = PendingFilters();
                break;
            case SupplierFilterTabs.OVERALL:
                tabFilters = OverallFilters();
                break;
            case SupplierFilterTabs.ARCHIVED:
                tabFilters = ArchivedFilters();
                break;
            case SupplierFilterTabs.ACTIVE:
                tabFilters = ActiveFilters();
                break;
            case SupplierFilterTabs.EXPIRING:
                tabFilters = ExpiringFilters();
                break;
        }
        return { ...filters, ...tabFilters };
    };

    function onMyTasksAsApproverTabChange2(value) {
        setApprovalTab(true);
        const newFilters = myTasksAsApproverFilters(value);

        dispatch(new QueryPSLActions.newFilters(newFilters, mainTab, true))
    }

    const onMyTaskAsAtMarketTabChange = (value: any) => {
        // if user is not approver and is a spender, show nothing...
        if (!isShowTaskTabState) {
            return;
        }

        setApprovalTab(false);
        const newFilters = myTasksComplianceListFilters();
        dispatch(new QueryPSLActions.newFilters(newFilters, mainTab, true));
    };

    const onLocalizationTabChange = (value: any) => {
        setApprovalTab(false)
        // if (listLoading) return null;
        setLocalizeTab(value);
        const newFilters = myTasksLocalizationListFilters(value);
        dispatch(new QueryPSLActions.newFilters(newFilters, mainTab, true));
    };

    const logViewShoppingCartAction = async () => {
        await DocumentFlowService.logUserInteractionData(UserInteractionType.ViewPslShoppingCart);
    };
    const logDescribeYourNeedAction = async () => {
        window.open(
            `${environment.DESCRIBE_YOUR_NEED}`,
            "_blank"
        );
        await DocumentFlowService.logUserInteractionData(UserInteractionType.DescribeYourNeed);
    };

    function onMainTabChange(value: SupplierTabs) {
        let pslSearch = null;
        let materialSearch = null;

        if (hasFeature(Features.psl_162778_search_state_mgmt)) {
            validateSearchCatOption(value);
            [materialSearch, pslSearch] = getMaterialPslSearchValues(mainTab, searchCategory, searchInputTerm);
        }

        let newApprovalQuery = { ...approvalQuery };
        dispatch(new QueryPslApproverActions.setDefaultDocQuery({ ...newApprovalQuery, pslSearch: pslSearch, materialSearch: materialSearch }))
        pslListQuery.orderBy = null;
        let newPslListQuery = { ...pslListQuery, pslSearch: pslSearch, materialSearch: materialSearch }
        dispatch(new QueryPSLActions.setDefaultDocQuery(newPslListQuery))
        if (value === SupplierTabs.MY_TASKS) {
            setTaskTab(PslComplianceStatus.Compliance)
        }
        setMainTab(value)
    }

    return (
        <Toolbar style={{ flexDirection: "column", padding: "0 30px" }}>
            {/*  Main Tab ------ Starts */}
            <HorizontalView
                style={{
                    height: 65,
                    alignItems: "flex-end",
                    justifyContent: "space-between"
                }}
            >
                <StyledTabs
                    value={mainTab}
                    onChange={(e, value) => onMainTabChange(value)}
                >
                    <BigStyledTab value={SupplierTabs.PSL} label={SupplierTabs.PSL} />
                    <BigStyledTab value={SupplierTabs.MATERIALS} label={SupplierTabs.MATERIALS} />

                    {isShowTaskTabState && (
                        <BigStyledTab
                            value={SupplierTabs.MY_TASKS}
                            label={SupplierTabs.MY_TASKS}
                        />
                    )}
                </StyledTabs>
                {showDesYourNeedBtn && (
                    <div>
                        <PrimaryButton buttonlabel={"Describe your need"}
                            onClick={() => logDescribeYourNeedAction()}
                        />
                        <IconButton
                            aria-label="cart"
                            onClick={() => logViewShoppingCartAction()}
                            href={environment.REACT_APP_EASY_REQUEST}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Badge badgeContent={data?.count} color="primary">
                                <ShoppingCartIcon style={{ fontSize: 35 }} />
                            </Badge>
                        </IconButton>
                    </div>
                )}
            </HorizontalView>

            {/*  Main Tab ------ Ends */}
            <TabsContainer>
                {/* Filter tabs and status tabs for Suppliers and Materials ------ Start */}
                {mainTab !== SupplierTabs.MY_TASKS && (
                    <div>

                        {/* Filter tabs for Suppliers and Materials ----------- start */}
                        <StyledTabs
                            value={filterTab}
                            onChange={(e, value) => onFilterTabChange(value, e)}
                        >
                            <StyledPslTab
                                value={SupplierFilterTabs.ACTIVE}
                                label={SupplierFilterTabs.ACTIVE}
                            />
                            {!(isRequestor || isFactoryAnalyst) && (
                                <StyledPslTab
                                    value={SupplierFilterTabs.PENDING}
                                    label={<DrowpDownComponent
                                        mainTab={mainTab}
                                        filterTab={filterTab}
                                        optionsArr={PENDING_OPTIONS_ARR}
                                        filterTabStatic={SupplierFilterTabs.PENDING}
                                        setFilterTab={setFilterTab}
                                        getNewFilters={getNewFilters}
                                    />}
                                />
                            )}
                            {!(isRequestor || isFactoryAnalyst) && (
                                <StyledPslTab
                                    value={SupplierFilterTabs.EXPIRING}
                                    label={SupplierFilterTabs.EXPIRING}
                                />
                            )}
                            <StyledPslTab
                                value={SupplierFilterTabs.ARCHIVED}
                                label={<DrowpDownComponent
                                    mainTab={mainTab}
                                    filterTab={filterTab}
                                    optionsArr={filters?.ShowObsolete?.selected ? ARCHIVED_OPTIONS_WITH_OBSOLETE : ARCHIVED_OPTIONS}
                                    filterTabStatic={SupplierFilterTabs.ARCHIVED}
                                    setFilterTab={setFilterTab}
                                    getNewFilters={getNewFilters}
                                />
                                }
                            />
                            <StyledPslTab
                                value={SupplierFilterTabs.OVERALL}
                                label={SupplierFilterTabs.OVERALL}
                            />
                        </StyledTabs>

                        {/* Filter tabs for Suppliers and Materials ------ Ends */}


                        <div style={{ display: "flex" }}>

                            {/*  blank */}

                            {filterTab !== SupplierFilterTabs.PENDING && (
                                <StyledTabs
                                    value={blankTabState.active}
                                    onChange={() => onStatusTabChange('blankTab')}
                                >
                                    <StyledPslTab value={true} label={blankTabState.label} />
                                </StyledTabs>
                            )}


                            {/*  Mandatory */}

                            <StyledTabs
                                value={mandatoryTabState.active}
                                onChange={() =>
                                    onStatusTabChange('mandatoryTab')
                                }
                            >
                                <StyledPslTab value={true} label={mandatoryTabState.label} />
                            </StyledTabs>


                            {/*  Sole Source */}

                            <StyledTabs
                                value={soleTabState.active}
                                onChange={() => onStatusTabChange('soleTab')
                                }
                            >
                                <StyledPslTab value={true} label={soleTabState.label} />
                            </StyledTabs>

                            {/*  Validated */}

                            {filterTab !== SupplierFilterTabs.PENDING && (
                                <StyledTabs
                                    value={validatedTabState.active}
                                    onChange={() => onStatusTabChange('validatedTab')}>
                                    <StyledPslTab value={true} label={validatedTabState.label} />
                                </StyledTabs>
                            )}


                            {/*  Over All */}

                            <StyledTabs
                                value={overallTabState.active}
                                onChange={() => onStatusTabChange('overallTab')}>
                                <StyledPslTab value={true} label={overallTabState.label} />
                            </StyledTabs>

                        </div>
                    </div>
                )}
                {/* Filter tabs and status tabs for Suppliers and Materials ------ Ends */}
                {/*  Filter tabs under My Tasks -------- value === taskTab*/}

                {showMyTasksAllSubTabs &&
                    (
                        <div>
                            <div style={{ display: 'flex' }}>
                                <StyledTabs
                                    value={showLocalizationSubTabs ? PslComplianceStatus.Localization : taskTab}
                                    onChange={(e, value) => setTaskTab(value)}
                                >
                                    <StyledPslTab
                                        value={PslComplianceStatus.Compliance}
                                        label={MyTasksAtMarketTabs.COMPLIANCE}
                                    />
                                    {
                                        isShowLocalizationState && <StyledPslTab
                                            value={PslComplianceStatus.Localization}
                                            label={MyTasksAtMarketTabs.LOCALIZATION}
                                        />
                                    }
                                </StyledTabs>

                                {isShowTaskTabState && isApproverState &&
                                    <StyledTabs
                                        value={
                                            (taskTab === PslComplianceStatus.Overall
                                                || taskTab === PslComplianceStatus.Justified
                                                || taskTab === PslComplianceStatus.NotJustified) ? PslComplianceStatus.Approval : ''}
                                        onChange={(e, value) => {
                                            setTaskTab(PslComplianceStatus.Overall)
                                            setApprovalTab(true);
                                        }}
                                    >
                                        <StyledPslTab
                                            value={PslComplianceStatus.Approval}
                                            label={MyTasksAtMarketTabs.APPROVAL}
                                        />
                                    </StyledTabs>
                                }
                            </div>

                            <div style={{ display: "flex" }}>
                                {showLocalizationSubTabs && <div>
                                    {/* Localization sub tabs  --- value === localizeTab*/}
                                    <StyledTabs
                                        value={localizeTab}
                                        onChange={(e, value) => onLocalizationTabChange(value)}
                                    >
                                        <StyledLocalizeTab
                                            value={PslComplianceStatus.canLocalize}
                                            label={MyTasksAtMarketTabs.CAN_LOCALIZATION}
                                        />
                                        <StyledLocalizeTab
                                            value={PslComplianceStatus.CannotLocalize}
                                            label={MyTasksAtMarketTabs.CANNOT_LOCALIZATION}
                                        />
                                    </StyledTabs>
                                </div>}

                                {/* Approval subTabs ====== value === taskTab */}

                                {showApproversubTabs && (
                                    <div>
                                        <StyledTabs
                                            value={taskTab}
                                            onChange={(e, value) =>
                                                setTaskTab(value)
                                            }
                                        >
                                            <StyledPslTab
                                                value={PslComplianceStatus.Overall}
                                                label={"OVERALL"}
                                            />
                                            <StyledPslTab
                                                value={PslComplianceStatus.Justified}
                                                label={"JUSTIFIED"}
                                            />
                                            <StyledPslTab
                                                value={PslComplianceStatus.NotJustified}
                                                label={"NOT JUSTIFIED"}
                                            />
                                        </StyledTabs>
                                    </div>
                                )}

                            </div>
                        </div>
                    )}
                {children}
            </TabsContainer>
        </Toolbar>
    )
}

const StyledPslTab = styled(StyledTab)`
  width: 96px;
  padding: 6px 0 !important;
`;

const StyledLocalizeTab = styled(StyledTab)`
  width: 120px;
  padding: 10px 0 !important;
`;
