interface PrNumberDTO {
    AribaPr:string;
    CrtDate: string;
    Currency: string;
    DataChanged: string;
    DelInd: string;
    DeliveryDate: string;
    DocType: string;
    GrInd: string;
    IdNo: string;
    InvRecpInd: string;
    Material: string;
    NetPrice: string;
    NetPriceUnit: string;
    Plant: string;
    PurchGrp: string;
    PurchOrg: string;
    PurchaseReqItemNo: string;
    PurchaseReqNo: string;
    Quantity: string;
    Requester: string;
    SAP__Origin: string;
    SortText: string;
    TotalPrice: string;
    UnitOfMeasure: string;
    Vendor: string;
    __metadata: { id: string; type: string; uri: string };
}

export class PrNumber {
    constructor(
        public AribaPr: string,
        public CrtDate: string,
        public Currency: string,
        public DataChanged: string,
        public DelInd: string,
        public DeliveryDate: string,
        public DocType: string,
        public GrInd: string,
        public IdNo: string,
        public InvRecpInd: string,
        public Material: string,
        public NetPrice: string,
        public NetPriceUnit: string,
        public Plant: string,
        public PurchGrp: string,
        public PurchOrg: string,
        public PurchaseReqItemNo: string,
        public PurchaseReqNo: string,
        public Quantity: string,
        public Requester: string,
        public SAP__Origin: string,
        public SortText: string,
        public TotalPrice: string,
        public UnitOfMeasure: string,
        public Vendor: string,
    ) {
    }

    static FromBackend(data: PrNumberDTO): PrNumber {
        return new PrNumber(
            data.AribaPr,
            data.CrtDate,
            data.Currency,
            data.DataChanged,
            data.DelInd,
            data.DeliveryDate,
            data.DocType,
            data.GrInd,
            data.IdNo,
            data.InvRecpInd,
            data.Material,
            data.NetPrice,
            data.NetPriceUnit,
            data.Plant,
            data.PurchGrp,
            data.PurchOrg,
            data.PurchaseReqItemNo,
            data.PurchaseReqNo,
            data.Quantity,
            data.Requester,
            data.SAP__Origin,
            data.SortText,
            data.TotalPrice,
            data.UnitOfMeasure,
            data.Vendor,
        )
    }

    static toSelect(data: PrNumber) {
        return {
            value: data.PurchaseReqNo,
            label: `${data.AribaPr ? data.AribaPr + " ("+data.PurchaseReqNo+")" : data.PurchaseReqNo}`
        }
    }
}
