import styled from "styled-components";
import Divider from "@material-ui/core/Divider";
import {HorizontalView} from "../layout/styles";
import Button from "@material-ui/core/Button";
import "react-virtualized-select/node_modules/react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";

export const SelectContainer = styled.div`
  font-size: 15px;
  padding-top: 5px;
  .Select-value {
    background-color: rgb(230, 230, 230)!important;
    color: rgb(51, 51, 51)!important;
  }
`;
export const AddCompCodeSelectContainer = styled.div`
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  .Select-value {
    background-color: rgb(230, 230, 230)!important;
    color: rgb(51, 51, 51)!important;
  }
`;
export const FiltersContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  overflow-y: auto;
  height: calc(100% - 65px);
`;

export const FiltersContainer = styled.div`
  padding: 0 25px;
  label {
    font-weight: 900;
  }
`;

export const CommonFilterRow = styled.div`
  width: 100%;
    .MuiSelect-select {
      padding-right: 32px!important;
      width: 425px;
      height: auto;
    }
    label {
      font-size: 15px;
    }
`;

export const PopUpRow = styled(CommonFilterRow)`
  padding: 20px 0 0 0;
`;

export const FilterRow = styled(CommonFilterRow)`
  padding: 20px 0 0 0;
`;
export const CompCodeFilterRow = styled(CommonFilterRow)`
  padding: 0;
`;
export const SmallFilterRow = styled(CommonFilterRow)`
  padding: 10px 0;
`;

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 30px;
`;

export const FiltersBlock = styled.div`
  flex-wrap: wrap;
  padding: 15px 30px;
`;

export const FilterBy = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 30px 5px 0;
  .name {
    color: #2689EB;
    padding-right: 5px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 30px 0!important;
`;

export const StyledButtons = styled(HorizontalView)`
  justify-content: flex-end;
  button {
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: none;
    color: #F19001;
    font-weight: 600;
    padding: 4px;
    width: 80px;
  }
`;

export const ApplyButton = styled(Button)`
  background-color: #F19001!important; 
  color: #fff!important;
  
  &:disabled {
    opacity: 0.4 !important;
  }
`;

export const ResetButton = styled(Button)`
  border: 1px solid #F19001!important;
  background-color: transparent!important; 
`;
