import ODataClientWrapper from "../../shared/utils/odataClient.wrapper";

export class PurchaseRequisitionService {
    static async getPurchaseRequisitionType(): Promise<any> {
        const response = await ODataClientWrapper.get()
            .resource("ApproverTypeSet")
            .execute();
        if (!response.data || !response.data.d || !Array.isArray(response.data.d.results) || response.data.d.results.length === 0) {
            throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
        }
        return response.data.d.results[0];
    }
}
