import {assoc, assocPath, mergeRight} from "ramda";
import {error, loaded, loading} from "../../../shared/reducers/utils";
import {Contract} from "../../domains/contract/contract";
import {IAction} from "../../../shared/domains/core/actions";
import {ContractActionTypes} from "../../actions/contract.actions";
import {ItemPrice} from "../../domains/itemPrice/itemPrice";
import {Item} from "../../domains/item/item";
import {ItemActionTypes} from "../../actions/item.actions";
import {ItemPriceActionTypes} from "../../actions/itemPricing.actions";
import {
  ClmContractAzureStatus,
  ClmContractStatus,
  CodeNameTerm,
  ContractClmLog,
  ContractClmResultLog,
} from "../../domains/contract/clm/contract.clm";

export interface IClmChanges {
  contract?: Contract;
  parent?: Partial<Contract>;
  items?: {[key: string]: Item};
  itemsPrice?: {[key: string]: ItemPrice[]};
}

export interface ClmContractState {
  loading: boolean;
  logsLoading: boolean;
  checking: boolean;
  deleting: boolean;
  incoTerms: CodeNameTerm[];
  paymentTerms: CodeNameTerm[];
  supplierPaymentTerm: CodeNameTerm;
  changes: IClmChanges;
  contractClmLogs: ContractClmLog[];
  contractClmResponseLogs: ContractClmResultLog[];
}

export const initialClmContractState: ClmContractState = {
  loading: false,
  logsLoading: false,
  checking: false,
  deleting: false,
  incoTerms: [],
  paymentTerms: [],
  supplierPaymentTerm: null,
  changes: {},
  contractClmLogs: [],
  contractClmResponseLogs: []
};

function updateClmItemsPrice(state: ClmContractState, itemPrice) {
  let newItemsPrice = null;
  let matchPrice = (p1, p2) => p1.ConditionItem == p2.ConditionItem && p1.ItemNo == p2.ItemNo && p1.AgreementNo == p2.AgreementNo;

  if (state.changes.itemsPrice) {
    newItemsPrice = state.changes.itemsPrice[itemPrice.ItemNo];
  }
  if (!newItemsPrice) {
    newItemsPrice = [];
    newItemsPrice.push(itemPrice);
  } else {
    if (!newItemsPrice.find(p => matchPrice(p, itemPrice))) {
      newItemsPrice.push(itemPrice);
    } else {
      newItemsPrice = newItemsPrice.map((p: ItemPrice) => matchPrice(p, itemPrice) ? itemPrice : p);
    }
  }
  return newItemsPrice;
}

function supplierPaymentTerm(paymentTerms: CodeNameTerm[], term: string): CodeNameTerm {
  return {Code: term, Name: paymentTerms.find(t => t.Code === term)?.Name};
}

export function ClmContractReducer(state: ClmContractState = initialClmContractState, action: IAction<ContractActionTypes|ItemActionTypes|ItemPriceActionTypes>): ClmContractState {
  switch (action.type) {
    case ContractActionTypes.FETCH_CHILD_CONTRACT_HEADER:
      return loading(initialClmContractState);
    case ContractActionTypes.FETCH_CHILD_CONTRACT_HEADER_SUCCESS:
      return loaded(mergeRight(state, {incoTerms: action.incoTerms, paymentTerms: action.paymentTerms, supplierPaymentTerm: supplierPaymentTerm(action.paymentTerms, action.supplierTerm)}));
    case ContractActionTypes.FETCH_CHILD_CONTRACT_HEADER_FAILURE:
      return error(action.error)(state);
    case ContractActionTypes.FETCH_CLM_LOGS:
    case ContractActionTypes.FETCH_CLM_RESPONSE_LOGS:
      return mergeRight(state, {logsLoading: true});
    case ContractActionTypes.FETCH_CLM_LOGS_SUCCESS:
      return mergeRight(state, {contractClmLogs: action.contractClmLogs, logsLoading: false});
    case ContractActionTypes.FETCH_CLM_RESPONSE_LOGS_SUCCESS:
      return mergeRight(state, {contractClmResponseLogs: action.contractClmResultLogs, logsLoading: false});
    case ContractActionTypes.FETCH_CLM_LOGS_FAILURE:
    case ContractActionTypes.FETCH_CLM_RESPONSE_LOGS_FAILURE:
      return mergeRight(state, {logsLoading: false});
    case ContractActionTypes.DELETE_CLM_CONTRACT:
      return mergeRight(state, {deleting: true});
    case ContractActionTypes.DELETE_CLM_CONTRACT_SUCCESS:
    case ContractActionTypes.DELETE_CLM_CONTRACT_FAILURE:
      return mergeRight(state, {deleting: false});
    case ContractActionTypes.CLM_CONTRACT_CHANGE:
      return assocPath(['changes', 'contract'], action.contract, state);
    case ContractActionTypes.RESUBMIT_CLM:
      return assocPath(['changes', 'contract'], {...action.contract, StatusAzure: ClmContractAzureStatus.Sending}, state);
    case ContractActionTypes.CLM_PARENT_CONTRACT_CHANGE:
      return assocPath(['changes', 'parent'], action.contract, state);
    case ItemActionTypes.INIT:
      return assocPath(['changes', 'items'], {}, state);
    case ItemActionTypes.CLM_ITEM_CHANGE:
      return assocPath(['changes', 'items'], {...state.changes.items, [action.item.ItemNo]: action.item}, state);
    case ItemPriceActionTypes.CLM_ITEM_PRICE_CHANGE:
      return assocPath(['changes', 'itemsPrice'], {...state.changes.itemsPrice, [action.itemPrice.ItemNo]: updateClmItemsPrice(state, action.itemPrice)}, state);
    case ItemPriceActionTypes.ADD_CLM_ITEM_PRICE_SUCCESS:
      return assocPath(['changes', 'itemsPrice'], {...state.changes.itemsPrice, [action.itemPrice.ItemNo]: action.newItemsPrice}, state);
    case ContractActionTypes.SUBMIT_CLM:
    case ContractActionTypes.SUBMIT_CLM_SUCCESS:
    case ContractActionTypes.RESUBMIT_CLM_SUCCESS:
      return assocPath(['changes', 'contract'], {...state.changes.contract, StatusMessage: "", StatusClm: ClmContractStatus.Unknown, StatusAzure: ClmContractAzureStatus.Locked}, state);
    case ContractActionTypes.SUBMIT_CLM_FAILURE:
    case ContractActionTypes.RESUBMIT_CLM_FAILURE:
      return assocPath(['changes', 'contract'], {...state.changes.contract, StatusMessage: action.error, StatusClm: ClmContractStatus.Unknown, StatusAzure: ClmContractAzureStatus.PartialReleased}, state);
    case ContractActionTypes.TEST_CLM_CONNECTION:
      return mergeRight(state, {checking: true});
    case ContractActionTypes.TEST_CLM_CONNECTION_SUCCESS:
    case ContractActionTypes.TEST_CLM_CONNECTION_FAILURE:
      return mergeRight(state, {checking: false});
    default:
      return state;
  }
}
