interface NetworkCompanyDTO {
    __metadata: { id: string; type: string; uri: string };
    Pspend_Cat: string,
    Spend_Cat_Txt: string,
    Spend_Category: string,
    Spend_Level: string,
  }
  
  export class MrpSpendCat {
    constructor(
      public Pspend_Cat: string,
      public title: string,
      public value: string,
      public Spend_Level: string,
    ) {
    }
  
    static FromBackend(data: NetworkCompanyDTO): MrpSpendCat {
      return new MrpSpendCat(
        data.Pspend_Cat,
        `${data.Spend_Category} - ${data.Spend_Cat_Txt}`,
        data.Spend_Category,
        data.Spend_Level,
      )
    }
  } 
  