import {connect} from "react-redux";
import {Dispatch} from "redux";
import {sortBy, prop} from "ramda";
import {RootStateStore} from "../../../../application.reducers";
import {IAction} from "../../../../shared/domains/core/actions";
import CalendarFiltersPage, {CalendarFilterDispatcher, CalendarFilterProps} from "./calenderFilters.component";
import {NewCalendarFilters} from "../../../actions/calendar.actions";
import {
  FetchHierarchyCompanyCodesByMarket,
  FetchHierarchyMarketsByZone,
  FetchHierarchyPlantsByCompanyCode,
  ResetCalendarFilters
} from "../../../actions/calendarFilter.actions";

const sortById = sortBy(prop('id'));

export const buyerToFilter = (email) => ({id: email, title: email});

function mapStateToProps(state: RootStateStore): CalendarFilterProps {
  const {calendarFilters} = state.tenderCalendar;
  const codeNameMapper = ({code, name}) => ({id: code, title: name});
  const idNameMapper = ({id, name}) => ({id: id, title: name});
  const buyerMapper = ({username, email}) => ({id: username, title: email});
  return {
    activeFilters: calendarFilters.activeFilters,
    spendCategories: calendarFilters.spendCategories.map(codeNameMapper),
    clusters: sortById(calendarFilters.clusters.map(codeNameMapper)),
    zones: calendarFilters.zones.map(codeNameMapper),
    markets: calendarFilters.hierarchyFilters.markets.map(codeNameMapper),
    companyCodes: calendarFilters.hierarchyFilters.companyCodes.map(codeNameMapper),
    plants: calendarFilters.hierarchyFilters.plants.map(codeNameMapper),
    businesses: calendarFilters.businesses.map(codeNameMapper),
    negotiationType: calendarFilters.negotiationType.map(idNameMapper),
    buyers: calendarFilters.buyers.map(buyerMapper)
  }
}

function mapDispatchToProps(dispatch: Dispatch<IAction>): CalendarFilterDispatcher {
  return {
    updateFilters: (newFilters) => dispatch(new NewCalendarFilters(newFilters)),
    resetFilters: () => dispatch(new ResetCalendarFilters()),
    fetchHierarchyMarketsByZones: (zones: string[]) => dispatch(new FetchHierarchyMarketsByZone(zones)),
    fetchHierarchyCompanyCodesByMarket: (markets: string[]) => dispatch(new FetchHierarchyCompanyCodesByMarket(markets)),
    fetchHierarchyPlantsByCompanyCode: (companyCodes: string[]) => dispatch(new FetchHierarchyPlantsByCompanyCode(companyCodes))
  }
}

export const CalendarFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarFiltersPage);
