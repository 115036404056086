import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import {CompetitiveBiddingTypes} from "../actions/competitiveBiddings.actions";
import {CompetitiveBiddingService} from "../domains/cb.service";
import {
  FetchSummary,
  FetchSummaryError,
  FetchSummarySuccess
} from "../actions/summary.actions";

export function* fetch(action: FetchSummary): any {
  try {
    const result = yield call(CompetitiveBiddingService.get, action.id);
    yield put(new FetchSummarySuccess(result))
  } catch (error) {
    yield put(new FetchSummaryError(error))
  }
}

function* watchFetchSummary() {
  yield takeLatest(CompetitiveBiddingTypes.FETCH_SUMMARY, fetch);
}

export default function* summarySaga() {
  yield all([
    fork(watchFetchSummary)
  ])
};
