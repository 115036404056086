import {PendingAction, SuccessAction, ErrorAction} from "../../../shared/domains/core/actions";

export enum PersonalNoteActions {
  GET_INVOICE_NOTE_REQUEST = "GET_INVOICE_NOTE_REQUEST",
  GET_INVOICE_NOTE_REQUEST_SUCCESS = "GET_INVOICE_NOTE_REQUEST_SUCCESS",
  GET_INVOICE_NOTE_REQUEST_FAILURE = "GET_INVOICE_NOTE_REQUEST_FAILURE",
  SAVE_INVOICE_NOTE_REQUEST = "SAVE_INVOICE_NOTE_REQUEST",
  SAVE_INVOICE_NOTE_REQUEST_SUCCESS = "SAVE_INVOICE_NOTE_REQUEST_SUCCESS",
  SAVE_INVOICE_NOTE_REQUEST_FAILURE = "SAVE_INVOICE_NOTE_REQUEST_FAILURE",
}

export class GetInvoiceNoteRequest extends PendingAction<PersonalNoteActions> {
  public readonly type = PersonalNoteActions.GET_INVOICE_NOTE_REQUEST;

  constructor(public invoiceNumber: string,
              public systemAlias: string) {
    super();
  }
}

export class GetInvoiceNoteRequestSuccess extends SuccessAction<PersonalNoteActions> {
  public readonly type = PersonalNoteActions.GET_INVOICE_NOTE_REQUEST_SUCCESS;

  constructor(public invoiceNumber: string,
              public systemAlias: string,
              public note: string) {
    super();
  }
}

export class GetInvoiceNoteRequestError extends ErrorAction<PersonalNoteActions> {
  public readonly type = PersonalNoteActions.GET_INVOICE_NOTE_REQUEST_FAILURE;

  constructor(public invoiceNumber: string,
              public systemAlias: string) {
    super();
  }
}

export class SaveInvoiceNoteRequest extends PendingAction<PersonalNoteActions> {
  public readonly type = PersonalNoteActions.SAVE_INVOICE_NOTE_REQUEST;

  constructor(public invoiceNumber: string,
              public systemAlias: string,
              public note: string) {
    super();
  }
}

export class SaveInvoiceNoteRequestSuccess extends SuccessAction<PersonalNoteActions> {
  public readonly type = PersonalNoteActions.SAVE_INVOICE_NOTE_REQUEST_SUCCESS;

  constructor(public invoiceNumber: string,
              public systemAlias: string,
              public note: string) {
    super();
  }
}

export class SaveInvoiceNoteRequestError extends ErrorAction<PersonalNoteActions> {
  public readonly type = PersonalNoteActions.SAVE_INVOICE_NOTE_REQUEST_FAILURE;

  constructor(public invoiceNumber: string,
              public systemAlias: string) {
    super();
  }
}
