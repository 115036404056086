import {DateTime} from "luxon";
import {and, isNil, isEmpty, splitEvery, reduceBy, sum, uniq, flatten, filter, equals, mergeRight, assoc} from "ramda";
import {all, call, delay, fork, put, select, takeLatest} from 'redux-saga/effects';
import {ContractsStateStore} from "../../reducers";
import {ContractService} from "../../domains/contract/contract.service";
import {
  UNKNOWN_VALUES,
  updatePage,
  updateExpiredDate,
  updateFilters,
  updateOrder,
  updatePath,
  updateSource,
  updateTerm,
  updateType,
  updateValidity,
  updateHanaSearch,
  ODataQuery,
} from "../../domains/contract/query";
import {
  CloneContract,
  CloneContractError,
  CloneContractSuccess,
  ContractActionTypes,
  DeleteDraftContract,
  DeleteDraftContractError,
  DeleteDraftContractSuccess,
  DownloadCSVCLM,
  DownloadCSVCLMError,
  DownloadCSVCLMSuccess,
  FetchChildContractHeader,
  FetchChildContractHeaderError,
  FetchChildContractHeaderSuccess,
  FetchClmLogs,
  FetchClmLogsError,
  FetchClmLogsSuccess,
  FetchClmResponseLogs,
  FetchClmResponseLogsError,
  FetchClmResponseLogsSuccess,
  FetchContract,
  FetchContracts,
  FetchContractsError,
  FetchContractsSuccess,
  FetchDatalakeContracts,
  FetchDatalakeContractsSuccess,
  FetchDatalakeContractsError,
  FetchDraftErrors,
  FetchDraftErrorsError,
  FetchDraftErrorsSuccess,
  NewActiveQuery,
  NewActiveQueries,
  PublishDraftContractError,
  PublishDraftContractSuccess,
  ResetInvalidContract,
  SetSelectedContract,
  SubmitCLM,
  SubmitCLMError,
  SubmitCLMNotification,
  SubmitCLMSuccess,
  SubmitParentCLMError,
  SubmitParentCLMSuccess,
  ToggleFavoriteContract,
  ToggleFavoriteContractError,
  ToggleFavoriteContractSuccess,
  UpdateContract,
  UpdateContractError,
  UpdateContractSuccess,
  UpdateDraft,
  UpdateDraftError,
  UpdateDraftSuccess,
  ValidateDraftError,
  ValidateDraftSuccess,
  UnlockCLM,
  UnlockCLMError,
  UnlockCLMSuccess,
  TestCLMConnectionError,
  TestCLMConnectionSuccess,
  FetchContractsPerServersSuccess,
  DeleteCLMContract,
  DeleteCLMContractError,
  DeleteCLMContractSuccess,
  ReSubmitCLM,
  ReSubmitCLMSuccess,
  ReSubmitCLMError
} from "../../actions/contract.actions";
import {push} from "connected-react-router";
import {
  Contract,
  DraftContractSource,
  ContractType, DatalakeContract
} from "../../domains/contract/contract";
import {RootStateStore} from "../../../application.reducers";
import {SharedStateStore} from "../../../shared/reducers";
import {ClmContractAzureStatus, ClmContractStatus, PaymentTerm} from "../../domains/contract/clm/contract.clm";
import {ContractFilterService} from "../../domains/filter.service";
import {MasterService} from "../../../shared/domains/master/master.service";
import {dateTimeFromFormat} from "../../../shared/utils/global";
import {selectPageableContractsPerServers, selectPageableQueriesPerServers} from "./pagination.saga";
import {selectClmItemsPriceChange} from "../itemsPrice/itemPrices.saga";

function* selectSource() {
  const contractType = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractType);
  const {contractSource, draftContractSource} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts);
  return contractType === ContractType.DRAFT ? draftContractSource : contractSource;
}

function* selectContractsFromDatalake(mGroups, materials, plants, validity, companies) {
  try {
    yield put(new FetchDatalakeContracts());
    const {contractSource} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts);
    const contracts = yield call(ContractFilterService.fetchDatalakeContracts, mGroups, materials, plants, dateTimeFromFormat(validity), companies, contractSource);
    const concatContracts = (acc, {ContractId}) => acc.concat(ContractId);
    const groupByServer = (c: DatalakeContract) => c.SapServer.substring(0, 3).toUpperCase();
    return splitEvery(100, contracts).map((data: any) => reduceBy(concatContracts, [], groupByServer, data));
  } catch (error) {
    yield put(new FetchDatalakeContractsError(error));
  }
  return null;
}

function selectQueryByContracts(query, filters, contractType, contractSource, contracts = null) {
  const path = ContractService.pathForType(contractType);
  const queryWithSource = updateSource(query, contractSource);
  if (contractType === ContractType.EXPIRED) {
    const expireStart = DateTime.local().minus({month: 12}).toFormat('yyyyMMdd');
    const expireEnd = DateTime.local().toFormat('yyyyMMdd');
    const newFilters = updateValidity(null, updateExpiredDate([expireStart, expireEnd], filters));
    return updateFilters(updateType(updatePath(queryWithSource, path), ContractType.EXPIRED), newFilters, !!contracts ? (contracts.length ?  contracts : UNKNOWN_VALUES) : []);
  } else {
    return updateFilters(updateType(updatePath(queryWithSource, path), contractType), updateExpiredDate([], filters), !!contracts ? (contracts.length ?  contracts : UNKNOWN_VALUES) : []);
  }
}

function* selectQuery() {
  const contractType = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractType);
  const contractSource = yield selectSource();
  const filters = yield select((state: RootStateStore) => state.contracts.filters.contractFilters);
  const query = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.query);
  if (contractType !== ContractType.RECENT) {
    const {mGroups, materials, plants, validity, companies} = filters;
    let contracts = null;
    if (!isEmpty(filters.mGroups) || !isEmpty(filters.materials) || !isEmpty(filters.plants)) {
      contracts = yield selectContractsFromDatalake(mGroups.map(g => g.id), materials.map(m => m.id), plants.map(p => p.id), validity, companies.map(c => c.id));
      yield put(new FetchDatalakeContractsSuccess(contracts));
    }
    if (!isNil(contracts)) {
      return contracts.map(data => Object.keys(data).reduce((acc, key) => {
        acc.push({[key]: selectQueryByContracts(query, filters, contractType, contractSource, data[key])});
        return acc;
      }, []));
    }
  }
  return selectQueryByContracts(query, filters, contractType, contractSource);
}

function* newContractType() {
  const query = yield selectQuery();
  if (Array.isArray(query)) {
    const newQueries = query.map(q => q.reduce((acc, value) => {
      return {...acc, [Object.keys(value)[0]]: updatePage(Object.values(value)[0] as ODataQuery, 0)};
    }, {}));
    yield put(new NewActiveQueries(newQueries));
  } else {
    const newQuery = updatePage(query, 0);
    yield put(new NewActiveQuery(newQuery));
  }
}

function* newOrder(action) {
  const query = yield selectQuery();
  if (Array.isArray(query)) {
    const newQueries = query.map(q => q.reduce((acc, value) => {
      return {...acc, [Object.keys(value)[0]]: updateOrder(Object.values(value)[0] as ODataQuery, action.field, action.direction)};
    }, {}));
    yield put(new NewActiveQueries(newQueries));
  } else {
    const newQuery = updateOrder(query, action.field, action.direction);
    yield put(new NewActiveQuery(newQuery));
  }
}

function* newFilter() {
  const query = yield selectQuery();
  if (Array.isArray(query)) {
    const newQueries = query.map(q => q.reduce((acc, value) => {
      return {...acc, [Object.keys(value)[0]]: updatePage(Object.values(value)[0] as ODataQuery, 0)};
    }, {}));
    yield put(new NewActiveQueries(newQueries));
  } else {
    const newQuery = updatePage(query, 0);
    yield put(new NewActiveQuery(newQuery));
  }
}

function* newPage(action) {
  const query = yield selectQuery();
  if (Array.isArray(query)) {
    const {queries, nextServerPage, nextLocalPage} = yield selectPageableQueriesPerServers(action.page, query);
    yield put(new NewActiveQueries(queries, action.page, nextServerPage, nextLocalPage));
  } else {
    const newQuery = updatePage(query, action.page);
    yield put(new NewActiveQuery(newQuery));
  }
}

function* newTerm(action) {
  const query = yield selectQuery();
  if (Array.isArray(query)) {
    const newQueries = query.map(q => q.reduce((acc, value) => {
      return {...acc, [Object.keys(value)[0]]: updateTerm(Object.values(value)[0] as ODataQuery, action.term)};
    }, {}));
    yield put(new NewActiveQueries(newQueries));
  } else {
    const newQuery = updateTerm(query, action.term);
    yield put(new NewActiveQuery(newQuery));
  }
}

function* updateContract(action: UpdateContract) {
  try {
    yield call(ContractService.update, action.contract);
    yield put(new UpdateContractSuccess(action.executor, action.contract));
  } catch (error) {
    yield put(new UpdateContractError(action.executor, error));
  }
}

function* updateDraft(action: UpdateDraft) {
  try {
    yield call(ContractService.updateDraft, action.contract);
    yield put(new UpdateDraftSuccess(action.executor, action.contract));
  } catch (error) {
    yield put(new UpdateDraftError(action.executor, error));
  }
}

export function* validateDraft(action) {
  try {
    const result = yield call(ContractService.validateDraft, action.contract);
    if (result.ErrorCount === 0) {
      yield put(new ValidateDraftSuccess("Contract is valid."));
    } else {
      yield put(new ValidateDraftError(result, {message: "Contract is not valid."}));
    }
  } catch (error) {
    yield put(new ValidateDraftError(action.contract, error));
  }
}

export function* publishDraft(action) {
  try {
    const result = yield call(ContractService.publishDraft, action.contract);
    if (result.ErrorCount === 0) {
      yield put(new PublishDraftContractSuccess(result, `Contract published successfully with Id: ${result.AgreementNo}`));
      yield delay(500);
      yield put(push(`/contracts?type=${ContractType.RECENT}&agreementNo=${result.AgreementNo}`));
    } else {
      yield put(new PublishDraftContractError(result, {message: "Contract is not valid."}));
    }
  } catch (error) {
    yield put(new PublishDraftContractError(action.contract, error));
  }
}

function* fetchChildContractHeader(action: FetchChildContractHeader) {
  try {
    const [incoTerms, paymentTerms, supplierPaymentTerm] = yield all([
      call(ContractService.fetchIncoTerms),
      call(MasterService.fetchPaymentTerms, true, action.contract.CompanyCode, action.contract.PurchOrganization),
      call(ContractService.fetchSupplierPaymentTerm, action.contract.VendorNo, action.contract.PurchOrganization)
    ]);
    yield put(new FetchChildContractHeaderSuccess(incoTerms, paymentTerms.map(PaymentTerm.FromBackend), supplierPaymentTerm));
  } catch (error) {
    yield put(new FetchChildContractHeaderError(error));
  }
}

function* fetchContractClmLogs(action: FetchClmLogs) {
  try {
    const logs = yield call(ContractService.fetchClmLogs, action.contractNo);
    yield put(new FetchClmLogsSuccess(logs));
  } catch (error) {
    yield put(new FetchClmLogsError(error));
  }
}

function* fetchContractClmResponseLogs(action: FetchClmResponseLogs) {
  try {
    const logs = yield call(ContractService.fetchClmResponseLogs, action.contractNo);
    yield put(new FetchClmResponseLogsSuccess(logs));
  } catch (error) {
    yield put(new FetchClmResponseLogsError(error));
  }
}

function* submitParentCLM(contract: Contract, doNotPrint: boolean) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    const result = yield call(ContractService.submitToCLM, user, contract, doNotPrint);
    if (result) {
      yield put(new SubmitParentCLMSuccess());
      yield put(new SubmitCLMNotification(`Parent Contract ${contract.AgreementNo} submitted successfully`));
    } else {
      yield put(new SubmitParentCLMError(`Error when submitting Parent Contract to CLM`));
    }
  } catch(error) {
    yield put(new SubmitParentCLMError(error));
  }
}

function* submitCLM(action: SubmitCLM) {
  const {contract, parent, items} = yield select((state: RootStateStore) => state.contracts.clmContract.changes);
  const itemsPrice = yield selectClmItemsPriceChange();
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    const currentItems = yield select((state: RootStateStore) => state.contracts.items.items);
    if (parent) {
      yield submitParentCLM(parent, action.doNotPrint);
      yield delay(800);
    }
    const result = yield call(ContractService.submitToCLM, user, contract, action.doNotPrint, false, items, itemsPrice);
    if (result) {
      const newItems = currentItems.reduce((prev, value) => {
        if (items && items[value.ItemNo]) {
          return [...prev, items[value.ItemNo]]
        }
        return [...prev, value];
      }, []);
      yield put(new SubmitCLMSuccess({...contract, StatusMessage: "", StatusClm: ClmContractStatus.Unknown, StatusAzure: ClmContractAzureStatus.Locked}, newItems));
      yield put(new SubmitCLMNotification(`Contract ${contract.AgreementNo} submitted successfully`));
    } else {
      throw("Could not submit contract to CLM");
    }
  } catch (error) {
    yield put(new SubmitCLMError({...contract, StatusMessage: error, StatusClm: ClmContractStatus.Unknown, StatusAzure: ClmContractAzureStatus.PartialReleased}, error));
  }
}

function* resubmitCLM(action: ReSubmitCLM) {
  try {
    yield call(ContractService.resubmitToCLM, action.contract);
    yield put(new ReSubmitCLMSuccess({...action.contract, StatusClm: ClmContractStatus.Unknown, StatusAzure: ClmContractAzureStatus.Locked}));
    yield put(new SubmitCLMNotification(`Contract ${action.contract.AgreementNo} resubmitted successfully`));
  } catch (error) {
    yield put(new ReSubmitCLMError(error));
  }
}

function* downloadCSVCLM(action: DownloadCSVCLM) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    const {contract, parent, items} = yield select((state: RootStateStore) => state.contracts.clmContract.changes);
    const itemsPrice = yield selectClmItemsPriceChange();
    let result = yield call(ContractService.downloadCSVCLM, user, contract, action.doNotPrint, items, itemsPrice);
    yield put(new DownloadCSVCLMSuccess(`${contract.AgreementNo}.csv`, result));
    if (parent) {
      result = yield call(ContractService.downloadCSVCLM, user, parent, action.doNotPrint);
      yield put(new DownloadCSVCLMSuccess(`${parent.AgreementNo}.csv`, result));
    }
  } catch (error) {
    yield put(new DownloadCSVCLMError(error));
  }
}

function* unlockCLM(action: UnlockCLM) {
  try {
    yield call(ContractService.unlock, action.contract);
    yield put(new UnlockCLMSuccess({...action.contract, StatusAzure: ClmContractAzureStatus.Release}, `Contract ${action.contract.AgreementNo} unlocked successfully.`));
  } catch (error) {
    yield put(new UnlockCLMError(error));
  }
}

function* deleteCLMContract(action: DeleteCLMContract) {
  try {
    const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
    const result = yield call(ContractService.submitToCLM, user, action.contract, true, true);
    if (result) {
      yield put(new DeleteCLMContractSuccess({...action.contract, StatusMessage: "", StatusClm: ClmContractStatus.Unknown, StatusAzure: ClmContractAzureStatus.Locked}));
      yield put(new SubmitCLMNotification(`Contract ${action.contract.AgreementNo} submitted successfully`));
    } else {
      yield put(new DeleteCLMContractError(`Error when submitting Contract to CLM`));
    }
  } catch (error) {
    yield put(new DeleteCLMContractError(error));
  }
}

function* testCLMConnection() {
  try {
    yield call(ContractService.healthCheck);
    yield put(new TestCLMConnectionSuccess('Connection is healthy!'));
  } catch (error) {
    yield put(new TestCLMConnectionError('Could not establish connection to CLM!'));
  }
}

function* deleteDraft(action: DeleteDraftContract) {
  try {
    yield call(ContractService.deleteDraft, action.contract);
    yield put(new DeleteDraftContractSuccess(action.contract, `Draft contract deleted successfully.`));
  } catch (error) {
    yield put(new DeleteDraftContractError(error));
  }
}

function* cloneContract(action: CloneContract) {
  try {
    const result = yield call(ContractService.clone, action.contract, action.copyFlags);
    yield put(new CloneContractSuccess());
    yield delay(500);
    yield put(push(`/contracts?type=${ContractType.DRAFT}&source=${DraftContractSource.CLONE_CONTRACT}&refAgreementNo=${result.RefAgreementNo}&agreementNo=${result.AgreementNo}`));
  } catch (error) {
    yield put(new CloneContractError(error));
  }
}

function* toggleFavoriteContract(action: ToggleFavoriteContract) {
  try {
    yield call(ContractService.toggleFav, action.contractId, action.systemAlias, action.toggle);
    yield put(new ToggleFavoriteContractSuccess(action.contractId, action.toggle))
  } catch (error) {
    yield put(new ToggleFavoriteContractError(error))
  }
}

export function* fetch(action: any): any {
  try {
    yield put(new FetchContracts());
    const filters = yield select((state: RootStateStore) => state.contracts.filters.contractFilters);
    if (!and(isEmpty(filters.companies), isEmpty(filters.strategicBuyers))) {
      let query = action.query;
      if (query.term) {
        const contractType = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractType);
        const user = yield select(({shared}: { shared: SharedStateStore }) => shared.user.currentUser);
        query = updateHanaSearch(query, ![ContractType.DRAFT, ContractType.MRP].includes(contractType) &&
          ['arkajeet.dasgupta@nestle.com'].includes(user.email.toLowerCase()));

      }
      const result = yield call(ContractService.fetch, query);
      yield put(new FetchContractsSuccess(result.data, result.count));
    } else {
      yield put(new FetchContractsSuccess([]));
    }
  } catch (error) {
    yield put(new FetchContractsError(error));
  }
}

function* fetchPerServers(action: NewActiveQueries) {
  try {
    yield put(new FetchContracts());
    const {queries, countServers, remoteContracts, serverPage, totalCount} = yield select(({contracts}: { contracts: ContractsStateStore }) => contracts.contracts.contractByServers);
    let contracts = remoteContracts, count = totalCount, countByServers = countServers;
    let nextServerPage = action.serverPage;
    const isLastPage = count >= action.page*10 && count <= action.page*10 + 10;

    if (!equals(queries, action.queries)) {
      const results = yield all(action.queries.map(query => call(ContractService.fetchPerServers, query)));
      count = sum(results.map(r => r.count));
      countByServers = filter(c => !!c)(flatten(results.map(r => r.countPerServer)));
      contracts = results.reduce((acc, value) => [...acc, ...value.data], []);
      if (isLastPage && contracts.length < count % 10) {
        const newServerPage = nextServerPage - 1;
        const newQueries = action.queries.reduce((acc, q) => {
          acc.push(Object.keys(q).reduce((acc, k) => ({...acc, [k]: updatePage(q[k] as ODataQuery, newServerPage)}), {}));
          return acc;
        }, []);
        yield put(new NewActiveQueries(newQueries, action.page, newServerPage, action.localPage));
        return;
      }
    }

    const {nextLocalPage, nextVisibleContracts, nextRemoteContracts} = yield selectPageableContractsPerServers(contracts, action.page, action.localPage, nextServerPage, count);

    yield put(new FetchContractsPerServersSuccess(action.queries, nextRemoteContracts, count, countByServers, nextServerPage, nextLocalPage));
    yield put(new FetchContractsSuccess(nextVisibleContracts, count));
  } catch (error) {
    yield put(new FetchContractsError(error));
  }
}

function* fetchContract(action: FetchContract) {
  try {
    const result = yield call(ContractService.fetchContract, action.contractId);
    yield put(new FetchContractsSuccess(result.data, result.count));
    if (result.data.length) {
      yield put(new SetSelectedContract(result.data[0]));
    }
  } catch (error) {
    yield put(new FetchContractsError(error));
  }
}

function* fetchExpiredContracts() {
  try {
    const query = yield selectQuery();
    if (Array.isArray(query)) {
      const newQueries = query.map(q => q.reduce((acc, value) => {
        return {...acc, [Object.keys(value)[0]]: updatePage(Object.values(value)[0] as ODataQuery, 0)};
      }, {}));
      yield put(new NewActiveQueries(newQueries));
    } else {
      const newQuery = updatePage(query, 0);
      yield put(new NewActiveQuery(newQuery));
    }
  } catch (error) {
    yield put(new FetchContractsError(error));
  }
}

function* fetchDraftErrors(action: FetchDraftErrors) {
  try {
    const result = yield call(ContractService.errorsForDraft, action.agreementNo, action.timestamp, action.refContractNo, action.systemAlias)
    yield put(new FetchDraftErrorsSuccess({items: result}))
  } catch (error) {
    yield put(new FetchDraftErrorsError(error))
  } finally {
    yield put(new ResetInvalidContract());
  }
}

function* watchNewOrder() {
  yield takeLatest(ContractActionTypes.NEW_ORDER, newOrder);
}

function* watchNewTerm() {
  yield takeLatest(ContractActionTypes.NEW_TERM, newTerm);
}

function* watchNewPage() {
  yield takeLatest(ContractActionTypes.NEW_PAGE, newPage);
}

function* watchNewFilter() {
  yield takeLatest(ContractActionTypes.NEW_FILTER, newFilter);
}

function* watchFetchContract() {
  yield takeLatest(ContractActionTypes.FETCH_CONTRACT, fetchContract);
}

function* watchUpdateContract() {
  yield takeLatest(ContractActionTypes.UPDATE_CONTRACT, updateContract);
}

function* watchUpdateDraft() {
  yield takeLatest(ContractActionTypes.UPDATE_DRAFT, updateDraft);
}

function* watchFetchExpired() {
  yield takeLatest(ContractActionTypes.FETCH_EXPIRED_CONTRACTS, fetchExpiredContracts);
}

function* watchUpdateContractType() {
  yield takeLatest([
    ContractActionTypes.NEW_CONTRACT_TYPE,
    ContractActionTypes.NEW_CONTRACT_SOURCE,
    ContractActionTypes.NEW_DRAFT_CONTRACT_SOURCE
  ], newContractType);
}

function* watchNewActiveQuery() {
  yield takeLatest(ContractActionTypes.NEW_ACTIVE_QUERY, fetch);
}

function* watchNewActiveQueries() {
  yield takeLatest(ContractActionTypes.NEW_ACTIVE_QUERIES, fetchPerServers);
}

function* watchValidateDraft() {
  yield takeLatest(ContractActionTypes.VALIDATE_DRAFT, validateDraft);
}

function* watchPublishDraft() {
  yield takeLatest(ContractActionTypes.PUBLISH_DRAFT_CONTRACT, publishDraft);
}

function* watchFetchChildContractHeader() {
  yield takeLatest(ContractActionTypes.FETCH_CHILD_CONTRACT_HEADER, fetchChildContractHeader);
}

function* watchFetchContractClmLogs() {
  yield takeLatest(ContractActionTypes.FETCH_CLM_LOGS, fetchContractClmLogs);
}

function* watchFetchContractClmResponseLogs() {
  yield takeLatest(ContractActionTypes.FETCH_CLM_RESPONSE_LOGS, fetchContractClmResponseLogs);
}

function* watchSubmitCLM() {
  yield takeLatest(ContractActionTypes.SUBMIT_CLM, submitCLM);
}

function* watchReSubmitCLM() {
  yield takeLatest(ContractActionTypes.RESUBMIT_CLM, resubmitCLM);
}

function* watchUnlockCLM() {
  yield takeLatest(ContractActionTypes.UNLOCK_CLM, unlockCLM);
}

function* watchDeleteCLMContract() {
  yield takeLatest(ContractActionTypes.DELETE_CLM_CONTRACT, deleteCLMContract);
}

function* watchTestCLMConnection() {
  yield takeLatest(ContractActionTypes.TEST_CLM_CONNECTION, testCLMConnection);
}

function* watchDownloadCSVCLM() {
  yield takeLatest(ContractActionTypes.DOWNLOAD_CSV_CLM, downloadCSVCLM);
}

function* watchDeleteDraft() {
  yield takeLatest(ContractActionTypes.DELETE_DRAFT_CONTRACT, deleteDraft);
}

function* watchDraftErrors() {
  yield takeLatest(ContractActionTypes.FETCH_DRAFT_ERRORS, fetchDraftErrors);
}

function* watchCloneContract() {
  yield takeLatest(ContractActionTypes.CLONE_CONTRACT, cloneContract);
}

function* watchToggleFavorite() {
  yield takeLatest(ContractActionTypes.TOGGLE_FAVORITE_CONTRACT, toggleFavoriteContract);
}

export default function* contractSaga() {
  yield all([
    fork(watchNewTerm),
    fork(watchNewPage),
    fork(watchNewFilter),
    fork(watchFetchContract),
    fork(watchFetchExpired),
    fork(watchNewOrder),
    fork(watchNewActiveQuery),
    fork(watchNewActiveQueries),
    fork(watchUpdateContractType),
    fork(watchUpdateContract),
    fork(watchUpdateDraft),
    fork(watchValidateDraft),
    fork(watchPublishDraft),
    fork(watchDeleteDraft),
    fork(watchDraftErrors),
    fork(watchCloneContract),
    fork(watchToggleFavorite),
    fork(watchFetchChildContractHeader),
    fork(watchFetchContractClmLogs),
    fork(watchFetchContractClmResponseLogs),
    fork(watchSubmitCLM),
    fork(watchReSubmitCLM),
    fork(watchUnlockCLM),
    fork(watchDeleteCLMContract),
    fork(watchDownloadCSVCLM),
    fork(watchTestCLMConnection)
  ])
};
