import {ContractNumber} from "../contract/contract";
import {AxiosWrapper} from "../../../shared/utils/axios.wrapper";

export class ChatService {
  static async fetchChatMessages(contractId: ContractNumber): Promise<any[]> {
    const path = `/api/Contracts/GetContractChatMessage?contractId=${contractId}`;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async sendMessage(contractId: ContractNumber, message: string, mentionedEmailAddresses: string[]): Promise<any> {
    const path = `/api/Contracts/InsertContractChatMessage`;
    const result = await AxiosWrapper.post(path, { contractId: contractId, comment: message, mentionedEmailAddresses: mentionedEmailAddresses });
    return result.data;
  }
}


