import * as React from "react";

import {SecondaryButton} from "../../../shared/components/button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import {SupplierTabs} from "../../domains/psl";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import {move, update} from "ramda";
import {useTranslation} from "react-i18next";
import {GetDefaultPslColumns} from "../../../shared/domains/user/pslColumns";
import {GetDefaultMaterialsColumns} from "../../../shared/domains/user/materialColumns";
import {GetDefaultMyTasksColumns} from "../../../shared/domains/user/myTasksColumns";

export function ColumnsConfigComponent({userRole, mainTab, setVendorColumns, setMaterialColumns, setMyTasksColumns, materialColumns, vendorColumns, myTasksColumns}) {
  const {t} = useTranslation();

  const [tmpPslColumns, setTmpPslColumns] = React.useState<any>(()=>vendorColumns);
  const [tmpMyTasksColumns, setTmpMyTasksColumns] = React.useState<any>(()=>myTasksColumns);
  const [tmpMaterialColumns, setTmpMaterialColumns] = React.useState<any>(()=>materialColumns);
  const [open, setOpen] = React.useState(false);

  const SortableItem = SortableElement(({col}) => <li
    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
  ><FormControlLabel
    style={{margin: '0', pointerEvents: "auto"}}
    control={<Checkbox
      checked={col.show}
      onChange={()=>handleColVisibility(col)}
      size="small"
      color="primary"
    />}
    label={t(`psl_columns:${col.id}`)}
  /><DragHandleIcon style={{cursor: 'move'}}/></li>);
  const SortableList = SortableContainer(({columns}) => {
    return (
      <ul style={{listStyle: 'none', padding: '0'}}>
        {columns.map((col, i) => col.hidden ? null : <SortableItem key={`item-${i}`} index={i} col={col} />)}
      </ul>
    );
  });
  const onSortEnd = ({oldIndex, newIndex}) => {
    switch(mainTab){
      case SupplierTabs.MATERIALS:
        setTmpMaterialColumns(move(oldIndex, newIndex, tmpMaterialColumns));
        break;
      case SupplierTabs.PSL:
        setTmpPslColumns(move(oldIndex, newIndex, tmpPslColumns));
        break;
      case SupplierTabs.MY_TASKS:
      default:
        setTmpMyTasksColumns(move(oldIndex, newIndex, tmpMyTasksColumns));
    }
  };
  const handleColVisibility = (col) => {
    const columns = getCorrectColumns(mainTab);
    const index = columns.map(el=>el.id).indexOf(col.id);
    const cols = update(index, {
      ...columns[index],
      show: !columns[index].show
    }, columns);
    switch(mainTab){
      case SupplierTabs.MATERIALS:
        setTmpMaterialColumns(cols);
        break;
      case SupplierTabs.PSL:
        setTmpPslColumns(cols);
        break;
      case SupplierTabs.MY_TASKS:
      default:
        setTmpMyTasksColumns(cols);
    }
  }
  const restoreDefault = () => {
    switch(mainTab){
      case SupplierTabs.MATERIALS:
        setTmpMaterialColumns(()=>GetDefaultMaterialsColumns(userRole));
        break;
      case SupplierTabs.PSL:
        setTmpPslColumns(()=>GetDefaultPslColumns(userRole));
        break;
      case SupplierTabs.MY_TASKS:
      default:
        setTmpMyTasksColumns(()=>GetDefaultMyTasksColumns(userRole));
    }
  };
  const getCorrectColumns = (tab: SupplierTabs) => {
    switch(mainTab){
      case SupplierTabs.MATERIALS:
        return tmpMaterialColumns;
      case SupplierTabs.PSL:
        return tmpPslColumns;
      case SupplierTabs.MY_TASKS:
      default:
        return tmpMyTasksColumns;
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }
  const handleSaveAndClose = () => {
   switch(mainTab){
      case SupplierTabs.MATERIALS:
        setMaterialColumns(tmpMaterialColumns);
        break;
      case SupplierTabs.PSL:
        setVendorColumns(tmpPslColumns);
        break;
      case SupplierTabs.MY_TASKS:
      default:
        setMyTasksColumns(tmpMyTasksColumns);
    }
    setOpen(false);
  };

  return <>
    <SecondaryButton buttonlabel={"Columns"} onClick={handleClickOpen}/>
    <Dialog
      open={open}
      onClose={()=>handleClose()}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogContent style={{display: 'flex', flexDirection: 'column', maxHeight: '450px', minWidth: '380px'}}>
        <DialogTitle style={{textAlign: 'center', padding: '0'}}>{mainTab === SupplierTabs.MATERIALS ? 'Material Columns' : 'PSL Columns'}</DialogTitle>
        <SortableList
          columns={getCorrectColumns(mainTab)}
          onSortEnd={onSortEnd}
          hideSortableGhost={false}
        />
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <Button onClick={restoreDefault} color="secondary">
          RESTORE DEFAULT
        </Button>
        <span>
          <Button onClick={()=>handleClose()} color="primary">
            CANCEL
          </Button>
          <Button onClick={()=>handleSaveAndClose()} color="primary">
            SAVE
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  </>
}
