import {
  Action,
  AzureErrorAction,
  DownloadCompletedAction,
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../shared/domains/core/actions";
import {CodeNameDTO, ContractCalendarEvent} from "../domains/calendar";

export enum TenderCalendarActionTypes {
  FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST",
  FETCH_EVENTS = "FETCH_EVENTS",
  FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS",
  FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE",

  FETCH_SYNC_STATUS = "FETCH_SYNC_STATUS",
  FETCH_SYNC_STATUS_SUCCESS = "FETCH_SYNC_STATUS_SUCCESS",
  FETCH_SYNC_STATUS_FAILURE = "FETCH_SYNC_STATUS_FAILURE",

  CREATE_CONTRACT_EVENT = "CREATE_CONTRACT_EVENT",
  CREATE_CONTRACT_EVENT_SUCCESS = "CREATE_CONTRACT_EVENT_SUCCESS",
  CREATE_CONTRACT_EVENT_FAILURE = "CREATE_CONTRACT_EVENT_FAILURE",

  EDIT_CONTRACT_EVENT = "EDIT_CONTRACT_EVENT",
  EDIT_CONTRACT_EVENT_SUCCESS = "EDIT_CONTRACT_EVENT_SUCCESS",
  EDIT_CONTRACT_EVENT_FAILURE = "EDIT_CONTRACT_EVENT_FAILURE",

  DELETE_CONTRACT_EVENT = "DELETE_CONTRACT_EVENT",
  DELETE_CONTRACT_EVENT_SUCCESS = "DELETE_CONTRACT_EVENT_SUCCESS",
  DELETE_CONTRACT_EVENT_FAILURE = "DELETE_CONTRACT_EVENT_FAILURE",

  FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER = "FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER",
  FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER_SUCCESS = "FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER_SUCCESS",
  FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER_FAILURE = "FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER_FAILURE",

  FETCH_CLUSTERS_BY_SPEND_CAT = "FETCH_CLUSTERS_BY_SPEND_CAT",
  FETCH_CLUSTERS_BY_SPEND_CAT_SUCCESS = "FETCH_CLUSTERS_BY_SPEND_CAT_SUCCESS",
  FETCH_CLUSTERS_BY_SPEND_CAT_FAILURE = "FETCH_CLUSTERS_BY_SPEND_CAT_FAILURE",

  FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT = "FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT",
  FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT_SUCCESS = "FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT_SUCCESS",
  FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT_FAILURE = "FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT_FAILURE",

  FETCH_MARKETS_BY_ZONE = "FETCH_MARKETS_BY_ZONE",
  FETCH_MARKETS_BY_ZONE_SUCCESS = "FETCH_MARKETS_BY_ZONE_SUCCESS",
  FETCH_MARKETS_BY_ZONE_FAILURE = "FETCH_MARKETS_BY_ZONE_FAILURE",

  FETCH_COMPANY_CODES_BY_MARKET = "FETCH_COMPANY_CODES_BY_MARKET",
  FETCH_COMPANY_CODES_BY_MARKET_SUCCESS = "FETCH_COMPANY_CODES_BY_MARKET_SUCCESS",
  FETCH_COMPANY_CODES_BY_MARKET_FAILURE = "FETCH_COMPANY_CODES_BY_MARKET_FAILURE",

  FETCH_PLANT_BY_COMPANY_CODE = "FETCH_PLANT_BY_COMPANY_CODE",
  FETCH_PLANT_BY_COMPANY_CODE_SUCCESS = "FETCH_PLANT_BY_COMPANY_CODE_SUCCESS",
  FETCH_PLANT_BY_COMPANY_CODE_FAILURE = "FETCH_PLANT_BY_COMPANY_CODE_FAILURE",

  EXPORT_CALENDAR_EVENTS = "EXPORT_CALENDAR_EVENTS",
  EXPORT_CALENDAR_EVENTS_SUCCESS = "EXPORT_CALENDAR_EVENTS_SUCCESS",
  EXPORT_CALENDAR_EVENTS_FAILURE = "EXPORT_CALENDAR_EVENTS_FAILURE",

  NEW_CALENDAR_START_DATE = "NEW_CALENDAR_START_DATE",
  NEW_CALENDAR_FILTER = "NEW_CALENDAR_FILTER",
  NEW_CALENDAR_QUERY = "NEW_CALENDAR_QUERY",
  TOGGLE_REPORT_PREVIEW = "TOGGLE_REPORT_PREVIEW"
}

export class FetchEventsRequest extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_EVENTS_REQUEST;
}

export class FetchEvents extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_EVENTS;
}

export class FetchEventsSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_EVENTS_SUCCESS;

  constructor(public events: ContractCalendarEvent[]) {
    super();
  }
}

export class FetchEventsError extends AzureErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_EVENTS_FAILURE;
}

export class FetchSyncStatus extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_SYNC_STATUS;
}

export class FetchSyncStatusSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_SYNC_STATUS_SUCCESS;

  constructor(public isSync: boolean) {
    super();
  }
}

export class FetchSyncStatusError extends ErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_SYNC_STATUS_FAILURE;
}

export class FetchSpendCategoriesClustersByBuyer extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER;

  constructor(public buyerId) {
    super();
  }
}

export class FetchSpendCategoriesClustersByBuyerSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER_SUCCESS;

  constructor(public spendCategories: CodeNameDTO[], public clusters: CodeNameDTO[]) {
    super();
  }
}

export class FetchSpendCategoriesClustersByBuyerError extends AzureErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_SPEND_CATEGORIES_CLUSTERS_BY_BUYER_FAILURE;
}

export class FetchClustersBySpendCat extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_CLUSTERS_BY_SPEND_CAT;

  constructor(public spendCatCodes: string[]) {
    super();
  }
}

export class FetchClustersBySpendCatSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_CLUSTERS_BY_SPEND_CAT_SUCCESS;

  constructor(public clusters: CodeNameDTO[]) {
    super();
  }
}

export class FetchClustersBySpendCatError extends AzureErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_CLUSTERS_BY_SPEND_CAT_FAILURE;
}

export class FetchClustersByBuyerAndSpendCat extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT;

  constructor(public buyerId, public spendCatCodes: string[]) {
    super();
  }
}

export class FetchClustersByBuyerAndSpendCatSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT_SUCCESS;

  constructor(public clusters: CodeNameDTO[]) {
    super();
  }
}

export class FetchClustersByBuyerAndSpendCatError extends AzureErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_CLUSTERS_BY_BUYER_AND_SPEND_CAT_FAILURE;
}

export class FetchMarketsByZone extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_MARKETS_BY_ZONE;

  constructor(public zoneCodes: string[], public loadDependencies: boolean = true) {
    super();
  }
}

export class FetchMarketsByZoneSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_MARKETS_BY_ZONE_SUCCESS;

  constructor(public markets: CodeNameDTO[]) {
    super();
  }
}

export class FetchMarketsByZoneError extends AzureErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_MARKETS_BY_ZONE_FAILURE;
}

export class FetchCompanyCodesByMarket extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_COMPANY_CODES_BY_MARKET;

  constructor(public markets: string[]) {
    super();
  }
}

export class FetchCompanyCodesByMarketSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_COMPANY_CODES_BY_MARKET_SUCCESS;

  constructor(public companyCodes: CodeNameDTO[]) {
    super();
  }
}

export class FetchCompanyCodesByMarketError extends AzureErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_COMPANY_CODES_BY_MARKET_FAILURE;
}

export class FetchPlantsByCompanyCode extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_PLANT_BY_COMPANY_CODE;

  constructor(public companyCodes: string[]) {
    super();
  }
}

export class FetchPlantsByCompanyCodeSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_PLANT_BY_COMPANY_CODE_SUCCESS;

  constructor(public plants: CodeNameDTO[]) {
    super();
  }
}

export class FetchPlantsByCompanyCodeError extends AzureErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.FETCH_PLANT_BY_COMPANY_CODE_FAILURE;
}

export class NewCalendarQuery extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.NEW_CALENDAR_QUERY;

  constructor(public query) {
    super();
  }
}

export class NewCalendarStartDate extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.NEW_CALENDAR_START_DATE;

  constructor(public startDate, public viewMode) {
    super();
  }
}

export class NewCalendarFilters extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.NEW_CALENDAR_FILTER;

  constructor(public filters) {
    super();
  }
}

export class CreateContractEvent extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.CREATE_CONTRACT_EVENT;

  constructor(public event: ContractCalendarEvent) {
    super();
  }
}

export class CreateContractEventSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.CREATE_CONTRACT_EVENT_SUCCESS;

  constructor(public event: ContractCalendarEvent) {
    super();
  }
}

export class CreateContractEventError extends ErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.CREATE_CONTRACT_EVENT_FAILURE;
}

export class EditContractEvent extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.EDIT_CONTRACT_EVENT;

  constructor(public event: ContractCalendarEvent) {
    super();
  }
}

export class EditContractEventSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.EDIT_CONTRACT_EVENT_SUCCESS;

  constructor(public event: ContractCalendarEvent) {
    super();
  }
}

export class EditContractEventError extends ErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.EDIT_CONTRACT_EVENT_FAILURE;
}

export class DeleteContractEvent extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.DELETE_CONTRACT_EVENT;

  constructor(public id) {
    super();
  }
}

export class DeleteContractEventSuccess extends SuccessAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.DELETE_CONTRACT_EVENT_SUCCESS;

  constructor(public deletedId) {
    super();
  }
}

export class DeleteContractEventError extends ErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.DELETE_CONTRACT_EVENT_FAILURE;
}

export class ExportCalendarEvents extends PendingAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.EXPORT_CALENDAR_EVENTS;
}

export class ExportCalendarEventsSuccess extends DownloadCompletedAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.EXPORT_CALENDAR_EVENTS_SUCCESS;
}

export class ExportCalendarEventsError extends ErrorAction<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.EXPORT_CALENDAR_EVENTS_FAILURE;
}

export class ToggleReportPreview extends Action<TenderCalendarActionTypes> {
  public readonly type = TenderCalendarActionTypes.TOGGLE_REPORT_PREVIEW;

  constructor(public isPreview: boolean) {
    super();
  }
}

export type TenderCalendarActions =
  |FetchEventsRequest
  |FetchEvents
  |FetchEventsSuccess
  |FetchEventsError
  |FetchSyncStatus
  |FetchSyncStatusSuccess
  |FetchSyncStatusError
  |FetchSpendCategoriesClustersByBuyer
  |FetchSpendCategoriesClustersByBuyerSuccess
  |FetchSpendCategoriesClustersByBuyerError
  |FetchClustersBySpendCat
  |FetchClustersBySpendCatSuccess
  |FetchClustersBySpendCatError
  |FetchClustersByBuyerAndSpendCat
  |FetchClustersByBuyerAndSpendCatSuccess
  |FetchClustersByBuyerAndSpendCatError
  |FetchMarketsByZone
  |FetchMarketsByZoneSuccess
  |FetchMarketsByZoneError
  |FetchCompanyCodesByMarket
  |FetchCompanyCodesByMarketSuccess
  |FetchCompanyCodesByMarketError
  |FetchPlantsByCompanyCode
  |FetchPlantsByCompanyCodeSuccess
  |FetchPlantsByCompanyCodeError
  |NewCalendarQuery
  |CreateContractEvent
  |CreateContractEventSuccess
  |CreateContractEventError
  |EditContractEvent
  |EditContractEventSuccess
  |EditContractEventError
  |DeleteContractEvent
  |DeleteContractEventSuccess
  |DeleteContractEventError
  |ExportCalendarEvents
  |ExportCalendarEventsSuccess
  |ExportCalendarEventsError
  |ToggleReportPreview;
