interface NetworkSpendCategoryDTO {
  Id: number;
  Code: string;
  Name: string;
  MaterialCode: string;
}

export class SpendCategory {
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public materialCode?: string,
    public materialDescription?: string,
    public materialLongDescription?: string,
    public spendCategories?: SpendCategory[]
  ) {
  }

  static FromBackend(data: NetworkSpendCategoryDTO): SpendCategory {
    return new SpendCategory(
      data.Id,
      data.Code,
      data.Name,
      data.MaterialCode
    )
  }
  static FromBackendCamel(data: any): SpendCategory {
    return new SpendCategory(
      data.id,
      data.code,
      data.name,
      data.materialCode,
      data.materialDescription,
      data.materialLongDescription,
      data.spendCategories
    )
  }
  static toSelect(data: SpendCategory) {
    return {
      value: data.code,
      label: data.materialCode ? `${data.materialCode} - ${data.name}`: data.name
    }
  }

  static toFilter(data: SpendCategory) {
    return {
      id: data.code,
      title: data.name
    }
  }

  static toDisplayString(data: {value: string, label: string}): string {
    return `${data.value} - ${data.label}`;
  }
}
