import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, createStyles, FormControlLabel, InputLabel, makeStyles, MuiThemeProvider, Theme, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import { StyledDialogActions, StyledDialogTitle } from "../../../../components/layout/styles";
import {
  CustomFastInput, CustomFastSelect,
} from "../../../../components/form";
import {
  SettingsTheme,
  StyledAddCompCodeDialog,
  StyledAddCompCodeDialogContent
} from "./addCompanyCode.styles";
import { useFetchCompCode } from "./compCode.hooks";
import { AddCompCodeSelectContainer } from "src/app/shared/components/filters/filters.styles";
import { SaveNewCompanyCode } from "src/app/shared/actions/user.actions";
import { ConfirmDialog } from "src/app/shared/components/popup";
import InfoIcon from '@material-ui/icons/Info';

export interface IAddCompCodeData {
  Code: string;
  Name: string;
  PslLocationMarketId: number;
  PurchasingOrganizations: string;
  PlantGroup: string;
  CatalogId: string;
  ApprovalNeeded: boolean;
}

interface companyCodeInputData {
  code: string;
  name: string;
  zoneCode: string;
  zoneName: string;
  marketId: number;
  marketName: string;
  purchasingOrganizationCode: string;
  purchasingOrganizationName: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: 15
    },
    label: {
      fontSize: 14,
      fontStyle: 'italic'
    },
    selector: {
      paddingTop: 5,
      marginBottom: 15
    },
    expansion: {
      marginBottom: 15
    }
  })
);

export const AddCompCodePopUp = ({ onClose }) => {
  const { data: CompanyCodeData } = useFetchCompCode();

  const dispatch = useDispatch();
  const { selector } = useStyles({});

  let tempData = [];
  let finalMarketsData = [];
  CompanyCodeData?.forEach(el => {
    if (!tempData.includes(el.marketId)) {
      tempData.push(el.marketId);
      finalMarketsData.push({ value: el, label: `${el.marketName}` });
    }
  });
  const [purchaseOrgs, setPurchaseOrgs] = useState<any>(null);
  const [compCodes, setCompCodes] = useState<any>(null);
  const [openPopUp, setOpenPopUp] = useState<any>(false);

  const [selectedMarket, setSelectedMarket] = useState<companyCodeInputData>(null);
  const [selectedPurchaseOrg, setSelectedPurchaseOrg] = useState<companyCodeInputData>(null);
  const [selectedCompCode, setSelectedCompCode] = useState<companyCodeInputData>(null);
  const [approvalNeeded, setApprovalNeeded] = useState<boolean>(true);

  useEffect(() => {
    const purchaseOrg = CompanyCodeData?.filter(el => el.marketName === selectedMarket?.marketName && el.marketId === selectedMarket?.marketId);
    let purchaseOrgFinalData = [];
    let tempArray = [];
    purchaseOrg?.forEach(el => {
      if (!tempArray.includes(el.purchasingOrganizationCode)) {
        tempArray.push(el.purchasingOrganizationCode);
        purchaseOrgFinalData.push({ value: el, label: `${el.purchasingOrganizationCode} - ${el.purchasingOrganizationName}` })
      }
    });
    setPurchaseOrgs(purchaseOrgFinalData);
  }, [selectedMarket]);

  useEffect(() => {
    setCompCodes(CompanyCodeData?.filter(el => el.marketName === selectedMarket?.marketName && el.marketId === selectedMarket?.marketId
      && el.purchasingOrganizationName === selectedPurchaseOrg?.purchasingOrganizationName && el.purchasingOrganizationCode === selectedPurchaseOrg?.purchasingOrganizationCode).map(el => ({ value: el, label: `${el.code} - ${el.name}` })));
  }, [selectedPurchaseOrg]);

  useEffect(() => {
    if ((purchaseOrgs?.length === 0 || compCodes?.lengh === 0)) {
      setOpenPopUp(true);
      onClose();
    };
  }, [selectedPurchaseOrg, selectedCompCode]);

  const plantGroup = selectedMarket && selectedCompCode ? `${selectedMarket['marketName']}_all_${selectedCompCode['code']}` : "";
  const CatalogueId = selectedMarket && selectedCompCode ? `${selectedMarket['zoneName']}_${selectedMarket['marketName']}_WBCatalog_${selectedCompCode['code']}` : "";

  const submitForm = () => {

    const finalData: IAddCompCodeData = {
      Code: selectedCompCode['code'],
      Name: selectedCompCode['name'],
      PslLocationMarketId: selectedMarket['marketId'],
      PurchasingOrganizations: selectedPurchaseOrg['purchasingOrganizationCode'],
      PlantGroup: plantGroup,
      CatalogId: CatalogueId,
      ApprovalNeeded: approvalNeeded
    }
    dispatch(new SaveNewCompanyCode(
      finalData, onClose()
    ));
  };


  return (
    <>
      <StyledAddCompCodeDialog open={true} maxWidth={"xs"} scroll={'paper'} fullWidth={true}>
        <MuiThemeProvider theme={SettingsTheme}>
          <StyledDialogTitle>Add New Company Code</StyledDialogTitle>
          <StyledAddCompCodeDialogContent dividers={true}>
            <Grid container>
              <Grid item sm={12}>
                <div style={{ display: "flex" }}>
                  <InputLabel>Market </InputLabel>
                  <Tooltip style={{ fontSize: "1rem" }} title="If you don´t find a Market in the dropdown , it means all the companies of that Market are already added in PSL´s Scope">
                    <InfoIcon></InfoIcon>
                  </Tooltip>
                </div>
                <AddCompCodeSelectContainer>
                  <CustomFastSelect
                    options={finalMarketsData}
                    initialValue={null}
                    clearable={false}
                    onChange={(v) => setSelectedMarket(v.value)}
                  />
                </AddCompCodeSelectContainer>
                <div style={{ display: "flex" }}>
                  <InputLabel>Purchase org </InputLabel>
                  <Tooltip style={{ fontSize: "1rem" }} title="If you don´t find a Purchasing Org in the dropdown , it means EITHER the Market field is Blank ( you need to put a market, in that case) OR You are not authorized to use the Purchasing Org">
                    <InfoIcon></InfoIcon>
                  </Tooltip>
                </div>
                <AddCompCodeSelectContainer>
                  <CustomFastSelect
                    options={purchaseOrgs}
                    initialValue={null}
                    clearable={false}
                    onChange={(v) => setSelectedPurchaseOrg(v.value)}
                  />
                </AddCompCodeSelectContainer>
                <div style={{ display: "flex" }}>
                  <InputLabel>Company Code </InputLabel>
                  <Tooltip style={{ fontSize: "1rem" }} title="If you don´t find a Company Code in the dropdown , it means EITHER the PurOrg field is Blank ( you need to put a Pur Org, in that case) OR You are not authorized to that Company OR that Company already is added in PSL´s Scope">
                    <InfoIcon></InfoIcon>
                  </Tooltip>
                </div>
                <AddCompCodeSelectContainer>
                  <CustomFastSelect
                    options={compCodes}
                    initialValue={null}
                    clearable={false}
                    onChange={(v) => setSelectedCompCode(v.value)}
                  />
                </AddCompCodeSelectContainer>
                <InputLabel>Plant Group</InputLabel>
                <Grid item sm={12} className={selector}>
                  <CustomFastInput
                    type={"text"}
                    disabled={true}
                    initialValue={plantGroup}
                    onChange={() => void (0)}
                  />
                </Grid>
                <InputLabel>Catalogue ID</InputLabel>
                <Grid item sm={12} className={selector}>
                  <CustomFastInput
                    type={"text"}
                    disabled={true}
                    initialValue={CatalogueId}
                    onChange={() => void (0)}
                  />
                </Grid>
                <div style={{ display: "flex", alignItems:"center", justifyContent:"left" }}>
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={approvalNeeded}
                      onChange={(_, checked) => setApprovalNeeded(checked)}
                      size="small"
                      color="primary" />}
                    label={"Approval Needed"}
                    style = {{marginRight : "0"}}
                  />
                  <Tooltip style = {{fontSize : "1rem"}} title="This indicates an Approval is Needed & will be triggered to the Approvers for those PSLs (belonging to this Company) who has Mandatory or Sole Source Suppliers">
                    <InfoIcon></InfoIcon>
                  </Tooltip>
                </div>

              </Grid>
            </Grid>
          </StyledAddCompCodeDialogContent>
          <StyledDialogActions>
            {
              onClose && <Button color="primary" onClick={() => onClose()}>Cancel</Button>
            }
            <Button color="primary" type="submit" disabled={!(selectedMarket && selectedPurchaseOrg && selectedCompCode)} onClick={submitForm}>Save</Button>
          </StyledDialogActions>
        </MuiThemeProvider>
      </StyledAddCompCodeDialog>

      <ConfirmDialog
        open={openPopUp}

        title={"You do not have any Authorization to add any Company Codes"}
        confirmText={"To continue, please select correct Market and Purchase Organisation"}
        onOK={() => {

          setOpenPopUp(false);
        }}
        okButtonText={"Close"}
      />
    </>

  );
};