import * as React from "react";
import {SnackbarWrapper, StyledSnackbar} from "./notification.styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Icon from "@material-ui/core/Icon";
import { Fragment } from 'react';

export enum NotificationType {
  success = 1,
  warning = 2,
  error = 3
}

interface NotificationProps {
  message?: string;
  type: NotificationType;
}

interface NotificationForSigneAgreNoProps {
  message?: string;
  type: NotificationType;
  setSignedAgreementError: Function;
}

class Notification extends React.Component<NotificationProps> {
  state = {
    open: true
  };

  handleClose() {
    this.setState({open: false})
  }

  render() {
    const {message, type} = this.props;
    const {open} = this.state;
    return (
      <SnackbarWrapper
        className={type === NotificationType.success ? 'success' : (type === NotificationType.warning ? 'warning' : 'error')}>
        <StyledSnackbar
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          message={
            <span className="message">
              {
                type === NotificationType.success ? <Icon>done</Icon> :
                  type === NotificationType.warning ? <Icon>warning</Icon> :
                    <Icon>error</Icon>
              }
              {
                type === NotificationType.success ? (message ? message : 'Saved successfully') :
                  type === NotificationType.error ? <>{message?.split('###').map((x, i) => <Fragment key={i}>{x.includes(": ") ? x?.split(": ")[1] : x}<br/></Fragment>)}</>:
                    message
              }
          </span>
          }
          autoHideDuration={type === NotificationType.success ? 20000 : null}
          onClose={() => this.handleClose()}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose()}
            >
              <CloseIcon/>
            </IconButton>
          ]}
        />
      </SnackbarWrapper>
    )
  }
}

export class NotificationForSignAgreeNoError extends React.Component<NotificationForSigneAgreNoProps> {
  state = {
    open: true
  };

  handleClose() {
    this.setState({open: false});
    this.props.setSignedAgreementError({error: ''})
  }

componentDidUpdate(prevProps: Readonly<NotificationProps>, prevState: Readonly<{}>, snapshot?: any): void {
  if((prevProps !== this.props && this.props.message)){
    this.setState({open: true})
  }  
}

  render() {
    const {message, type} = this.props;
    const {open} = this.state;
    return (
      <SnackbarWrapper
        className={type === NotificationType.success ? 'success' : (type === NotificationType.warning ? 'warning' : 'error')}>
        <StyledSnackbar
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          message={
            <span className="message">
              {
                type === NotificationType.success ? <Icon>done</Icon> :
                  type === NotificationType.warning ? <Icon>warning</Icon> :
                    <Icon>error</Icon>
              }
              {
                type === NotificationType.success ? (message ? message : 'Saved successfully') :
                  type === NotificationType.error ? <>{message?.split('###').map((x, i) => <Fragment key={i}>{x.includes(": ") ? x?.split(": ")[1] : x}<br/></Fragment>)}</>:
                    message
              }
          </span>
          }
          autoHideDuration={type === NotificationType.success ? 5000 : null}
          onClose={() => this.handleClose()}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose()}
            >
              <CloseIcon/>
            </IconButton>
          ]}
        />
      </SnackbarWrapper>
    )
  }
}

export default Notification;

