import * as R from "ramda";
import {DocFlowActions, DocumentFlowTypes} from "../../actions/documentFlow.actions";
import {InvoiceActions, InvoiceTypes} from "../actions/invoices.actions";
import DocumentFlow, {
  CompetitiveBiddingType,
  DocFlowFilterTabs,
  DocumentFlowIdGenerator
} from "../../domains/documentFlow/documentFlow";
import {Invoice} from "../domains/invoices/Invoice";
import {DocumentFlowStage} from "../../domains/documentFlow/documentFlowStage";
import {QueryableReducer} from "../../../shared/queryable/query.reducer";
import {newInvoicesQuery} from "../domains/invoices/InvoiceQuery";
import {DocFlowChatTypes} from "../../actions/docflowChat.actions";
import {IChatCounter} from "../../../shared/domains/chat/roomChat.service";
import {DocFlowItem} from "../../domains/prpo/poItem";
import {InvoiceItem} from "../../domains/prpo/InvoiceItem";
import {SAPBoolean} from "../../../shared/domains/core/sapBoolean";

export type StageDocumentFlow = DocumentFlow & { stage: DocFlowStageState };
export type DocumentFlows = { [key: string]: StageDocumentFlow };

export interface DocFlowStageState {
  stage: DocumentFlowStage;
  loading: boolean;
  error: string;
}

export interface InvoiceState {
  loading: boolean;
  error: string;
  totalCount: number;
  docStatus: {};
  documentFlows: DocumentFlows;
  invoices: Invoice[];
  invoicesItems: {
    [key: string]: {
      items: InvoiceItem[];
      itemsLoading: boolean;
    };
  };
  documentFlowsItems: {
    [key: string]: {
      items: DocFlowItem[];
      itemsLoading: boolean;
    };
  };
  selectedDocFlow: DocumentFlow;
  selectedDocFlowItem: DocFlowItem;
  docFlowToLink: DocumentFlow;
  docFlowsToLink: {
    loading: boolean;
    error: string;
    data: DocumentFlow[];
  };
  onUpdateLoading: boolean;
  onClosePOLoading: boolean;
  onFiltersLoading: boolean;
  selectedTab: DocFlowFilterTabs;
  invoiceParkingReasonCodes: []
}

export const emptyStage = () => ({
  stage: null,
  loading: false,
  error: null
});

export const initialDocFlowsState: InvoiceState = {
  loading: true,
  error: null,
  totalCount: 0,
  docStatus: {},
  documentFlows: {},
  invoices: [],
  invoicesItems: {},
  documentFlowsItems: {},
  selectedDocFlow: null,
  selectedDocFlowItem: null,
  docFlowToLink: null,
  docFlowsToLink: {
    loading: false,
    error: null,
    data: []
  },
  onUpdateLoading: false,
  onClosePOLoading: false,
  onFiltersLoading: false,
  selectedTab: DocFlowFilterTabs.DOCUMENTS,
  invoiceParkingReasonCodes: []
};

const loading = { loading: true, error: null };
const loaded = { loading: false, error: null };
const failure = (error: string) => ({ loading: false, error });
const stageFailure = (error: string) => ({ ...failure(error), stage: null });

function updateCounter(
  state: InvoiceState,
  chatCounter: IChatCounter,
  currentUserId: number
): InvoiceState {
  const ids = chatCounter.id.split(":");
  var item = Object.values(state.documentFlows).find(doc => {
    return (
      (!ids[0] || (ids[0] && ids[0] === doc.PurchaseReqNo)) &&
      (!ids[1] || (ids[1] && ids[1] === doc.PurchaseOrdNo))
    );
  });
  if (item) {
    const newState = {
      ...state,
      documentFlows: {
        ...state.documentFlows,
        [item.id]: {
          ...state.documentFlows[item.id],
          CountOfUnreadComments:
            chatCounter.userId === currentUserId
              ? state.documentFlows[item.id].CountOfUnreadComments
              : state.documentFlows[item.id].CountOfUnreadComments + 1,
          TotalCountOfComments:
            state.documentFlows[item.id].TotalCountOfComments + 1
        }
      }
    };
    return newState as InvoiceState;
  }
  return state;
}

export function invoiceReducer(
  state: InvoiceState = initialDocFlowsState,
  action: InvoiceActions
): InvoiceState {
  switch (action.type) {
    case InvoiceTypes.FETCH_INVOICES_REQUEST:
      return R.mergeRight(state, { ...loading, onFiltersLoading: true });
    case InvoiceTypes.FETCH_INVOICES:
      return R.mergeRight(state, { ...loading, onFiltersLoading: true });
    case InvoiceTypes.FETCH_INVOICES_SUCCESS:
      const withInvoices = R.mergeRight(
        { totalCount: action.total, invoices: action.documents },
        loaded
      );
      return R.mergeRight(state, withInvoices);
    case InvoiceTypes.FETCH_INVOICE_STATUS_SUCCESS:
      const withStatus = R.mergeRight(
        { docStatus: action.status, onFiltersLoading: false },
        loaded
      );
      const withOutStatus = R.mergeRight(
        { onFiltersLoading: false },
        loaded
      );
      return Object.values(action.status).every(el => el != undefined) ? R.mergeRight(state, withStatus) : R.mergeRight(state, withOutStatus); // To avoid updating after pagination
    case InvoiceTypes.FETCH_INVOICE_ITEMS_REQUEST:
      return R.mergeRight(state, {
        invoicesItems: {
          ...state.invoicesItems,
          [action.i.InvDocNo]: {
            itemsLoading: true,
            items: []
          }
        }
      });
    case InvoiceTypes.FETCH_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        invoicesItems: {
          ...state.invoicesItems,
          [action.i.InvDocNo]: {
            itemsLoading: false,
            items: action.documents
          }
        }
      };
    case InvoiceTypes.FETCH_INVOICE_ITEMS_FAILURE:
      return {
        ...state,
        invoicesItems: {
          ...state.invoicesItems,
          [action.i.InvDocNo]: {
            itemsLoading: false,
            items:
            state.invoicesItems[action.i.InvDocNo].items
          }
        }
      };
    case InvoiceTypes.FETCH_INVOICE_STATUS_FAILURE:
      return R.mergeRight(state, { onFiltersLoading: false });
    case InvoiceTypes.FETCH_INVOICES_FAILURE:
      return R.mergeRight(state, failure(action.error));
    case InvoiceTypes.FETCH_INVOICE_PARKING_REASON_CODE:
      return R.mergeRight(state, { ...loading });
    case InvoiceTypes.FETCH_INVOICE_PARKING_REASON_CODE_SUCCESS:
      const invoiceParkingReasonCodes = R.mergeRight(
        { invoiceParkingReasonCodes: action.data },
        loaded
      );
      return R.mergeRight(state, invoiceParkingReasonCodes);
    case InvoiceTypes.FETCH_INVOICE_PARKING_REASON_CODE_FAILURE:
      return R.mergeRight(state, failure(action.error));
    default:
      return state;
  }
}

export const invoiceStateSelector = ["invoices", "documentFlows"];
export const invoiceDomainName = "INVOICES";
export const {
  reducer: QueryableInvoiceReducer,
  sagas: queryInvoiceSagas,
  actions: QueryActionsI
} = QueryableReducer<InvoiceState>(
  invoiceDomainName,
  invoiceStateSelector,
  invoiceReducer
);
