import * as R from "ramda";
import {o} from 'odata';
import {get} from 'lodash';
import moment from 'moment';
import {CBComplianceQuery, CompetitiveBiddingData} from "./competitiveBidding";
import {AxiosWrapper} from "../../shared/utils/axios.wrapper";
import ODataClientWrapper, {List} from "../../shared/utils/odataClient.wrapper";
import {IdNo} from "../../contracts/domains/contract/contract.service";
import {ApiService} from "../../shared/services/api.service";
import {CurrencyData} from "./selector/currency";
import {CBShortNetworkDTO, ShortCompetitiveBidding} from "./shortCompetitiveBidding";
import {newQuery, toODataFilter} from "../../shared/queryable/query";
import {ODataFilter, ODataFormats, Operators} from "../../../lib/odata";
import {withAuthHeader, withMultiOrigin} from "../../shared/domains/auth/authentication.service";
import {CB_FILTER_KEY, ParentSpendCategory} from "./cbQuery";
import DocumentFlow from "../../docFlows/domains/documentFlow/documentFlow";
import {environment} from "../../shared/domains/core/environment";

export class CompetitiveBiddingService extends ApiService {
  static async get(cbId: string | number): Promise<CompetitiveBiddingData> {
    const response = await AxiosWrapper.get('/api/CompetitiveBidding/GetCbData', {params: {id: cbId}});
    return CompetitiveBiddingData.FromBackend(response.data);
  }

  private static mapToODataFilter([key, filter]) {
    const selected = filter.selected;
    const value = selected?.value;
    switch(key) {
      case 'ValidFrom':
        return toODataFilter(R.assocPath(['selected', 'value'], value ? moment.utc(value, ODataFormats.ISO_DATE).toISOString() : null, filter), Operators.GE);
      case 'ValidTo':
        return toODataFilter(R.assocPath(['selected', 'value'], value ? moment.utc(value, ODataFormats.ISO_DATE).toISOString() : null, filter), Operators.LE);
      case CB_FILTER_KEY.CATEGORY_CODE:
      case CB_FILTER_KEY.SUBCATEGORY_CODE:
      case CB_FILTER_KEY.PRODUCTCATEGORY_CODE:
        return toODataFilter({
          ...filter,
          key: ParentSpendCategory,
          selected: {value: filter.selected.map(el=>`'${el.id}'`).join(",")}
          }, Operators.IN, key);
      default:
        return toODataFilter(filter);
    }
  }

  static async list(
    query = newQuery(),
    url = "/odata/CompetitiveBiddingLists/",
    options?: {[key:string]: any}
  ): Promise<List<ShortCompetitiveBidding>> {
    const list = R.toPairs(query.filters);
    const filters = list.map(CompetitiveBiddingService.mapToODataFilter);
    const fullFilters = filters.reduce((previousValue, currentValue) => previousValue.and(currentValue.length && currentValue.reduce((x, y) => x.or(y), ODataFilter.empty())), ODataFilter.empty());
    const strFilters = fullFilters.toString();

    let odataQuery = {$top: 10, $skip: query.page * 10, $count: true};

    if (options?.MatGrp) {
      //oh boy
      odataQuery = R.assoc('spendCatLevel3Code', options.MatGrp.join("#"), odataQuery);
    }
    if (options?.VendorNo) {
      odataQuery = R.assoc('vendorNo', options.VendorNo, odataQuery);
    }
    if (strFilters) {
      odataQuery = R.assoc('$filter', strFilters, odataQuery);
    }
    if (query.orderBy[0]) {
      odataQuery = R.assoc('$orderby', `${query.orderBy[0].field} ${query.orderBy[0].direction}`, odataQuery);
    }
    if (query.term) {
      odataQuery = R.assoc('searchTerm', `'${query.term}'`, odataQuery);
    }
    try {
      const fullUrl = `${environment.REACT_APP_API_BASE}${url}`;
      const result = await o(fullUrl, {headers: await withAuthHeader(withMultiOrigin()), fragment: null}).get().query(odataQuery);
      const data = result.value;
      const count = result['@odata.count'];
      return new List<CBShortNetworkDTO>(data, count).map(ShortCompetitiveBidding.FromBackend);
    } catch (e) {
      throw( { response: { data: await e.json(), status: e.status }} );
    }
  }

  static async activeList(query = newQuery(), options?: {[key:string]: any}): Promise<List<ShortCompetitiveBidding>> {
    return CompetitiveBiddingService.list(query, "/odata/CompetitiveBiddingLists(1)", options)
  }

  static async fetchCurrencies(): Promise<CurrencyData[]> {
    const response = await ODataClientWrapper.get()
      .resource("CurrencySet")
      .execute();

    return response?.data?.d?.results?.map(CurrencyData.FromBackend);
  }

  static async getComplianceAlert(formData: any): Promise<any> {
    const selectedSupplier = get(formData, 'SelectedSupplier');
    const vendors = get(formData, 'Vendors');
    const cbCompliance: CBComplianceQuery = {
      selectedSupplierCode: get(formData, `Vendors[${selectedSupplier}].VendorCode`),
      numberOfFilledSuppliers: R.prop('length', vendors.filter(v => !!v.VendorCode)),
      companyCode: get(formData, 'CompanyCode'),
      spendCatLevel3Code: get(formData, 'ProductCategoryCode'),
      value: get(formData, `Vendors[${selectedSupplier}].Total`),
      currency: get(formData, `Vendors[${selectedSupplier}].Currency`)
    };
    const response = await AxiosWrapper.get(`/api/CompetitiveBidding/GetComplianceAlert`, {params: cbCompliance});
    return response.data;
  }

  static async linkPRtoCB(cbId: string, df: DocumentFlow): Promise<any> {
    const response = await AxiosWrapper.post("/api/CompetitiveBidding/AssociateCbWithEntity", {
      competitiveBiddingId: cbId,
      prId: DocumentFlow.isPR(df) ? DocumentFlow.getPRblock(df) : null,
      poId: DocumentFlow.isPO(df) ?  DocumentFlow.getPOblock(df) : null
    });
    return response.data;
  }

  static async linkCBtoPRlist(cbId: string, dfs: DocumentFlow[]): Promise<any> {
    const response = await AxiosWrapper.post("/api/CompetitiveBidding/AssociateCbWithEntities", {
      competitiveBiddingId: cbId,
      prId: dfs.filter(DocumentFlow.isPR).map(df => DocumentFlow.getPRblock(df)),
      poId: dfs.filter(DocumentFlow.isPO).map(df => DocumentFlow.getPOblock(df))
    });
    return response.data;
  }

  static async save(competitiveBidding: CompetitiveBiddingData, df?: DocumentFlow): Promise<any> {
    const bodyToSave = CompetitiveBiddingService.saveBody(competitiveBidding);
    const withDf = df ? {
      ...bodyToSave,
      PurchaseReqNos: DocumentFlow.isPR(df) ? [DocumentFlow.getPRblock(df)] : [],
      PurchaseOrderNos: DocumentFlow.isPO(df) ? [DocumentFlow.getPOblock(df)] : []
    } : bodyToSave;
    const response = await AxiosWrapper.post("/api/CompetitiveBidding/SaveCbData", withDf);

    return response.data;
  }

  static async update(competitiveBidding: CompetitiveBiddingData): Promise<any> {
    const bodyToUpdate = CompetitiveBiddingService.updateBody(competitiveBidding);
    const response = await AxiosWrapper.post("/api/CompetitiveBidding/UpdateCbData", bodyToUpdate);
    return response.data;
  }

  private static saveBody(competitiveBidding: CompetitiveBiddingData): any {

    return {
      IdNo: IdNo.COMPETITIVE_BIDDING,
      PurchaseReqNos: competitiveBidding.PurchaseReqNos,
      PurchaseOrderNos: competitiveBidding.PurchaseOrderNos,
      ProductCategoryCodes: competitiveBidding.spendCategories.map(el=>el.productCategoryCode).filter(el=>!!el),
      CompanyCode: competitiveBidding.CompanyCode,
      CompanyName: competitiveBidding.CompanyName,
      PurchaseDescription: competitiveBidding.PurchaseDescription,

      ValidFrom: competitiveBidding.ValidFrom,
      ValidTo: competitiveBidding.ValidTo,

      Vendors: competitiveBidding.Vendors.map(vendor=>({
        ...vendor,
        AttachedQuotation: vendor.AttachedQuotation.filter(el=>!R.isNil(el) && el !== "")})),

      SelectedSupplierCode: get(competitiveBidding, `Vendors[${competitiveBidding.SelectedSupplier}].VendorCode`),
      ExceptionJustification: competitiveBidding.ExceptionJustification,
      isBestPrice: competitiveBidding.isBestPrice,
      isBestQuality: competitiveBidding.isBestQuality,
      isBestDelivery: competitiveBidding.isBestDelivery,
      isException: competitiveBidding.isException,
    }
  }

  private static updateBody(competitiveBidding: CompetitiveBiddingData): any {

    return {
      Id: competitiveBidding.Id,
      ProductCategoryCodes: competitiveBidding.spendCategories.map(el=>el.productCategoryCode).filter(el=>!!el),
      CompanyCode: competitiveBidding.CompanyCode,
      CompanyName: competitiveBidding.CompanyName,
      PurchaseDescription: competitiveBidding.PurchaseDescription,

      ValidFrom: competitiveBidding.ValidFrom,
      ValidTo: competitiveBidding.ValidTo,
      Vendors: competitiveBidding.Vendors.map(vendor=>({
        ...vendor,
        AttachedQuotation: vendor.AttachedQuotation.filter(el=>!R.isNil(el) && el !== "")})),
      SelectedSupplierCode: get(competitiveBidding, `Vendors[${competitiveBidding.SelectedSupplier}].VendorCode`),
      ExceptionJustification: competitiveBidding.ExceptionJustification,
      isBestPrice: competitiveBidding.isBestPrice,
      isBestQuality: competitiveBidding.isBestQuality,
      isBestDelivery: competitiveBidding.isBestDelivery,
      isException: competitiveBidding.isException,
    }
  }
}
