import {ErrorAction, IPromiseExecutor, PendingAction, SuccessAction} from "../../shared/domains/core/actions";
import {SuccessNotificationAction} from "../../shared/domains/core/notifications";
import DocumentFlow from "../domains/documentFlow/documentFlow";
import {CompliantItemPRPO} from "../domains/prpo/item.service";
import {DocFlowItem} from "../domains/prpo/poItem";

export enum PRPOItemsTypes {
  FETCH_PR_ITEMS = "FETCH_PR_ITEMS",
  FETCH_PR_ITEMS_SUCCESS = "FETCH_PR_ITEMS_SUCCESS",
  FETCH_PR_ITEMS_FAILURE = "FETCH_PR_ITEMS_FAILURE",

  FETCH_PO_ITEMS = "FETCH_PO_ITEMS",
  FETCH_PO_ITEMS_SUCCESS = "FETCH_PO_ITEMS_SUCCESS",
  FETCH_PO_ITEMS_FAILURE = "FETCH_PO_ITEMS_FAILURE",

  FETCH_GR_ITEMS = "FETCH_GR_ITEMS",
  FETCH_GR_ITEMS_SUCCESS = "FETCH_GR_ITEMS_SUCCESS",
  FETCH_GR_ITEMS_FAILURE = "FETCH_GR_ITEMS_FAILURE",

  FETCH_GR_ITEMS_INV = "FETCH_GR_ITEMS_INV",
  FETCH_GR_ITEMS_INV_SUCCESS = "FETCH_GR_ITEMS_INV_SUCCESS",
  FETCH_GR_ITEMS_INV_FAILURE = "FETCH_GR_ITEMS_INV_FAILURE",

  FETCH_IR_ITEMS = "FETCH_IR_ITEMS",
  FETCH_IR_ITEMS_SUCCESS = "FETCH_IR_ITEMS_SUCCESS",
  FETCH_IR_ITEMS_FAILURE = "FETCH_IR_ITEMS_FAILURE",

  FETCH_IR_ITEMS_INV = "FETCH_IR_ITEMS_INV",
  FETCH_IR_ITEMS_INV_SUCCESS = "FETCH_IR_ITEMS_INV_SUCCESS",
  FETCH_IR_ITEMS_INV_FAILURE = "FETCH_IR_ITEMS_INV_FAILURE",

  FETCH_COMPLIANT_ITEMS = "FETCH_COMPLIANT_ITEMS",
  FETCH_COMPLIANT_ITEMS_SUCCESS = "FETCH_COMPLIANT_ITEMS_SUCCESS",
  FETCH_COMPLIANT_ITEMS_FAILURE = "FETCH_COMPLIANT_ITEMS_FAILURE",

  UPDATE_PR_ITEM = "UPDATE_PR_ITEM",
  UPDATE_PO_ITEM = "UPDATE_PO_ITEM",
  UPDATE_PRPO_ITEM_SUCCESS = "UPDATE_PRPO_ITEM_SUCCESS",
  UPDATE_PRPO_ITEM_FAILURE = "UPDATE_PRPO_ITEM_FAILURE",

  FETCH_DOCFLOW_DRILLDOWN = "FETCH_DOCFLOW_DRILLDOWN",
  FETCH_DOCFLOW_DRILLDOWN_SUCCESS = "FETCH_DOCFLOW_DRILLDOWN_SUCCESS",
  FETCH_DOCFLOW_DRILLDOWN_FAILURE = "FETCH_DOCFLOW_DRILLDOWN_FAILURE",

  NBS_APPROVE_REJECT_SUCCESS = "NBS_APPROVE_REJECT_SUCCESS",
  NBS_APPROVE_REJECT_FAILURE = "NBS_APPROVE_REJECT_FAILURE",
}

export class DocumentFlowFetchDrilldownAction extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_DOCFLOW_DRILLDOWN;

  constructor(public id: string, public isPo: boolean) {
    super();
  }
}

export class DocumentFlowFetchDrilldownSuccessAction extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_DOCFLOW_DRILLDOWN_SUCCESS;

  constructor(public documents: DocFlowItem[], public id: string) {
    super();
  }
}

export class DocumentFlowFetchDrilldownFailureAction extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_DOCFLOW_DRILLDOWN_FAILURE;
}


export class FetchPRItems extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_PR_ITEMS;

  constructor(public purchaseReqNo, public systemAlias: string) {
    super();
  }
}

export class FetchPRItemsSuccess extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_PR_ITEMS_SUCCESS;

  constructor(public items, public totalCount) {
    super();
  }
}

export class FetchPRItemsFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_PR_ITEMS_FAILURE;
}

export class FetchCompliantItems extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_COMPLIANT_ITEMS;

  constructor(public df: DocumentFlow) {
    super();
  }
}

export class FetchCompliantItemsSuccess extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_COMPLIANT_ITEMS_SUCCESS;

  constructor(public items: CompliantItemPRPO) {
    super();
  }
}

export class FetchCompliantItemsFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_COMPLIANT_ITEMS_FAILURE;
}

export class FetchPOItems extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_PO_ITEMS;

  constructor(public purchaseOrdNo, public systemAlias: string) {
    super();
  }
}

export class FetchPOItemsSuccess extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_PO_ITEMS_SUCCESS;

  constructor(public items, public totalCount) {
    super();
  }
}

export class FetchPOItemsFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_PO_ITEMS_FAILURE;
}

export class UpdatePRItem extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.UPDATE_PR_ITEM;

  constructor(public executor: IPromiseExecutor, public item, public systemAlias: string) {
    super(executor);
  }
}

export class UpdatePOItem extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.UPDATE_PO_ITEM;

  constructor(public executor: IPromiseExecutor, public item, public systemAlias: string) {
    super(executor);
  }
}

export class UpdatePRPOItemSuccess extends SuccessNotificationAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.UPDATE_PRPO_ITEM_SUCCESS;

  constructor(public executor: IPromiseExecutor) {
    super();
    this.executor.resolve();
  }
}

export class NbsAppRejectSucess extends SuccessNotificationAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.NBS_APPROVE_REJECT_SUCCESS;

  constructor(public message) {
    super();
  }
}

export class NbsAppRejectFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.NBS_APPROVE_REJECT_FAILURE;
}

export class UpdatePRPOItemError extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.UPDATE_PRPO_ITEM_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);
    this.executor.reject();
  }
}


export class FetchGRItems extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_GR_ITEMS;

    constructor(
      public poNo: string,
      public prNo: string,
      public systemAlias: string,
    ) {
    super();
  }
}

export class FetchGRItemsSuccess extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_GR_ITEMS_SUCCESS;

  constructor(public items,
              public totalCount,
              public poNo: string,
              public prNo: string,
              public systemAlias: string) {
    super();
  }
}

export class FetchGRItemsFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_GR_ITEMS_FAILURE;

  constructor(public poNo: string,
              public prNo: string,
              public systemAlias: string) {
    super();
  }
}

export class FetchGRInvItems extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_GR_ITEMS_INV;

  constructor(
    public InvDocNo: string,
    public FiscalYr: string,
    public systemAlias: string,
  ) {
    super();
  }
}

export class FetchGRInvItemsSuccess extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_GR_ITEMS_INV_SUCCESS;

  constructor(public items,
              public totalCount,
              public InvDocNo: string,
              public FiscalYr: string,
              public systemAlias: string) {
    super();
  }
}

export class FetchGRInvItemsFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_GR_ITEMS_INV_FAILURE;

  constructor(public InvDocNo: string,
              public FiscalYr: string,
              public systemAlias: string) {
    super();
  }
}

export class FetchIRItems extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_IR_ITEMS;

    constructor(
      public poNo: string,
      public prNo: string,
      public systemAlias: string,
    ) {
    super();
  }
}

export class FetchIRItemsSuccess extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_IR_ITEMS_SUCCESS;

  constructor(public items,
              public totalCount,
              public poNo: string,
              public prNo: string,
              public systemAlias: string,) {
    super();
  }
}

export class FetchIRItemsFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_IR_ITEMS_FAILURE;

  constructor(
      public poNo: string,
      public prNo: string,
      public systemAlias: string,
  ) {
    super();
  }
}

export class FetchIRInvItems extends PendingAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_IR_ITEMS_INV;

  constructor(
    public InvDocNo: string,
    public systemAlias: string,
  ) {
    super();
  }
}

export class FetchIRInvItemsSuccess extends SuccessAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_IR_ITEMS_INV_SUCCESS;

  constructor(public items,
              public totalCount,
              public InvDocNo: string,
              public systemAlias: string,) {
    super();
  }
}

export class FetchIRInvItemsFailure extends ErrorAction<PRPOItemsTypes> {
  public readonly type = PRPOItemsTypes.FETCH_IR_ITEMS_INV_FAILURE;

  constructor(
    public InvDocNo: string,
    public systemAlias: string,
  ) {
    super();
  }
}
