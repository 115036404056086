import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { prSubstitutionStateStore } from "../reducers";
import { PRSubstitutionService } from "../domains/prSubstitution/prSubstitution.service";
import * as Sentry from "@sentry/browser";
import {
  FetchPRDetailSuccess,
  FetchPRDetailError,
  FetchPRs,
  FetchPRsError,
  FetchPRsSuccess,
  PRSubstitutionActionTypes,
  FetchPRLinesSuccess,
  FetchPRLinesError,
  ApprovedPRSuccess,
  ApprovedPRError,
  MailFailure,
  AddSubstitutionSuccess,
  DeleteSubstitutionSuccess,
  FetchCostObjectSuccess,
  FetchSubstituteeFailure,
  FetchSubstituteeSuccess,
  FetchCostObjectFailure,
  AddPRsSuccess,
  AddPRsError,
  behalfUser
} from "../actions/prSubstitution.action";
import {
  newPage,
  newResource,
  updateTerm,
  newFilters,
  prSubstitutionQuery,
  updateOrder
} from "../domains/prSubstitution/query";

export function* setbehalfUser(action: any): any {
  console.log("action", action);
  yield put(new behalfUser({ userData: action.userData }));
}

export function* fetchPrSubstitutionSage(action: any): any {
  try {
    let newQuery = action.query;
    if (!action.query) {
      let query = prSubstitutionQuery();
      newQuery = newResource(query, action.resource);
      newQuery = newFilters(newQuery, [
        { field: "AppType", value: action.appType },
        // { field: "Delegator", value: 'MPOOLWA1' },
        // { field: "UserID", value: 'MPOOLWA1' },
        { field: "Delegator", value: `${action.userData.Delegator}` },
        { field: "UserID", value: `${action.userData.UserID}` },
      ]);
    }

    const result = yield call(PRSubstitutionService.fetch, newQuery, action.zone);
    yield put(
      new FetchPRsSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRsError({ error: error, message: error.message }));
  }
}

export function* fetchCostObjectSaga(action: any): any {
  try {
    let newQuery = action.query;
    if (!action.query) {
      let query = prSubstitutionQuery();
      newQuery = newResource(query, action.resource);
      newQuery = newFilters(newQuery, [
        { field: "Type", value: action.objectType },

        // { field: "User", value: 'NBKARMAKKA' },
        // { field: "Position", value: `71075079` },
        { field: "User", value: `${action.user.UserID}` },
        { field: "Position", value: `${action.user.Delegator}` }
      ]);
    }

    const result = yield call(PRSubstitutionService.fetchCostObject, newQuery, action.zone);
    yield put(
      new FetchCostObjectSuccess({
        items: result.data,
        totalCount: 0,
        newQuery: newQuery
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(
      new FetchCostObjectFailure({ error: error, message: error.message })
    );
  }
}

export function* fetchSubstituteeSaga(action: any): any {
  try {
    let newQuery = action.query;
    if (!action.query) {
      let query = prSubstitutionQuery();
      newQuery = newResource(query, action.resource);
      newQuery = newFilters(newQuery, [
        { field: "Type", value: action.objectType },
        { field: "Query", value: action.term },
        { field: "User", value: `${action.user.UserID}` },
        { field: "Position", value: `${action.user.Delegator}` }
      ]);
    }

    const result = yield call(PRSubstitutionService.fetchSubstitutee, newQuery);
    yield put(
      new FetchSubstituteeSuccess({
        items: result.data,
        totalCount: result.count,
        newQuery: newQuery
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(
      new FetchSubstituteeFailure({ error: error, message: error.message })
    );
  }
}

export function* fetchDetail(action: any): any {
  const query = {
    Aribapr: action.Aribapr,
    SapPR: action.SapPR,
    ItemNo: action.ItemNo,
    CompCode: action.CompCode,
    PurchOrg: action.PurchOrg,
    RelGroup: action.RelGroup,
    RelStrategy: action.RelStrategy,
    ApprovalLevel: action.Frgco
  };
  try {
    const result = yield call(PRSubstitutionService.fetchDetail, query);
    const result1 = yield call(PRSubstitutionService.fetchAccount, query);
    const result2 = yield call(PRSubstitutionService.fetchPricing, query);
    const result3 = yield call(
      PRSubstitutionService.fetchApprovalIndicator,
      query
    );
    yield put(
      new FetchPRDetailSuccess({
        info: result,
        accountList: result1,
        pricing: result2,
        approvalIndecator: result3
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new FetchPRDetailError({ error: error, message: error.message }));
  }
}

export function* fetchLines(action: any): any {
  const query = [
    { field: "AribaPr", value: encodeURIComponent(action.Aribapr) },
    { field: "ApprovalType", value: encodeURIComponent(action.ApprovalType) },
    { field: "ApprovalLevel", value: encodeURIComponent(action.Frgco) },
    { field: "AgentId", value: encodeURIComponent(action.positionId) },
    { field: "Counter", value: encodeURIComponent(action.Counter) },
    { field: "SapPr", value: encodeURIComponent(action.Banfn) }
  ];

  if (action.ApprovalType == "BA") {
    query.push({ field: "ItemNo", value: encodeURIComponent(action.ItemNo) });
  }
  try {
    const result = yield call(PRSubstitutionService.fetchLines, query);
    yield put(
      new FetchPRLinesSuccess({ items: result.data, Aribapr: action.AribaPr })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(
      new FetchPRLinesError({
        error: error,
        message: error.message,
        Aribapr: action.AribaPr
      })
    );
  }
}

export function* approveRequest(action: any): any {
  const query = { SapPR: action.SapPR, ItemNo: action.ItemNo };
  try {
    const result = yield call(PRSubstitutionService.fetchLines, query);
    yield put(
      new ApprovedPRSuccess({ items: result.data, SapPR: action.SapPR })
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(new ApprovedPRError({ error: error, message: error.message }));
  }
}

function* addPrSubstitution(action) {
  try {
    if (action.payload.data?.requestType === "Addition") {
      const result = yield call(
        PRSubstitutionService.substitutionServiceAddition,
        action.payload.data?.data
      );
    } else {
      const result = yield call(
        PRSubstitutionService.substitutionServiceDeletion,
        action.payload.data?.data
      );
    }
    if (
      action.payload &&
      action.payload.data &&
      action.payload.data.requestType === "Addition" &&
      action.payload.data.data &&
      action.payload.data.data.Active === "X"
    ) {
      yield put(new AddSubstitutionSuccess());
    } else {
      yield put(new DeleteSubstitutionSuccess());
    }
    yield put(new AddPRsSuccess());
    action.payload.callback();
  } catch (error) {
    Sentry.captureException(error);
    yield put(new AddPRsError(error));
    // yield put(new MailFailure(error));
  }
}

function* updatePage(action) {
  const query = yield select(
    ({ prSubstitution }: { prSubstitution: prSubstitutionStateStore }) =>
      prSubstitution.PRSubstitution.query
  );
  const newQuery = newPage(query, action.page);
  yield put({ type: PRSubstitutionActionTypes.NEW_AS_QUERY, query: newQuery, zone: action.zone });
}

function* newTerm(action) {
  const query = yield select(
    ({ prSubstitution }: { prSubstitution: prSubstitutionStateStore }) =>
      prSubstitution.PRSubstitution.query
  );
  const newQuery = updateTerm(query, action.term);
  yield put({ type: PRSubstitutionActionTypes.NEW_AS_QUERY, query: newQuery });
}

function* newOrder(action) {
  const query = yield select(
    ({ prSubstitution }: { prSubstitution: prSubstitutionStateStore }) =>
      prSubstitution.PRSubstitution.query
  );
  const newQuery = updateOrder(query, action.field, action.direction);
  yield put({ type: PRSubstitutionActionTypes.NEW_AS_QUERY, query: newQuery, zone: action.zone });
}

function* watchSetbehalfUser() {
  yield takeLatest(
    PRSubstitutionActionTypes.SET_SUBSTITUTION_BEHALF_USER,
    setbehalfUser
  );
}

function* watchBAFetchQuery() {
  yield takeLatest(
    PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION,
    fetchPrSubstitutionSage
  );
}

function* watchCostObjectQuery() {
  yield takeLatest(
    PRSubstitutionActionTypes.FETCH_COST_OBJECT,
    fetchCostObjectSaga
  );
}

function* watchSubstituteeQuery() {
  yield takeLatest(
    PRSubstitutionActionTypes.FETCH_SUBSTITUTEE,
    fetchSubstituteeSaga
  );
}

function* watchNewActiveQuery() {
  yield takeLatest(
    PRSubstitutionActionTypes.NEW_AS_QUERY,
    fetchPrSubstitutionSage
  );
}

function* watchDetailActiveQuery() {
  yield takeLatest(
    PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_DETAIL,
    fetchDetail
  );
}

function* watchLinesQuery() {
  yield takeLatest(
    PRSubstitutionActionTypes.FETCH_PR_SUBSTITUTION_LINES,
    fetchLines
  );
}

function* watchPRApprovalQuery() {
  yield takeLatest(PRSubstitutionActionTypes.PRSUBSTITUTION_APPROVED_PR, approveRequest);
}

function* watchNewPage() {
  yield takeLatest(PRSubstitutionActionTypes.NEW_PAGE_AS, updatePage);
}

function* watchNewTerm() {
  yield takeLatest(PRSubstitutionActionTypes.NEW_TERM_AS, newTerm);
}

function* watchNewOrder() {
  yield takeLatest(PRSubstitutionActionTypes.NEW_ORDER_AS, newOrder);
}

function* watchPRSubstition() {
  yield takeLatest(
    PRSubstitutionActionTypes.ADD_PR_SUBSTITUTION,
    addPrSubstitution
  );
}

export default function* prSubstitutionSaga() {
  yield all([
    fork(watchBAFetchQuery),
    fork(watchNewActiveQuery),
    fork(watchNewPage),
    fork(watchDetailActiveQuery),
    fork(watchLinesQuery),
    fork(watchPRApprovalQuery),
    fork(watchNewTerm),
    fork(watchNewOrder),
    fork(watchPRSubstition),
    fork(watchCostObjectQuery),
    fork(watchSubstituteeQuery),
    // fork(watchSetbehalfUser)
  ]);
}
