import {mergeRight} from "ramda";
import {IAction} from "../../shared/domains/core/actions";
import {PSLTypes} from "../actions/psl.actions";
import {error, loading} from "../../shared/reducers/utils";
import {PSL} from "../domains/psl";

export interface SupplierState {
  loading: boolean;
  error: {
    file: string,
    validationErrors: {key: number, value: {code: string, message: string}[]}[],
    parsingErrors: {lineId: number, failedPropertyToParse: string, parsingExceptionMessage: string}[]
  };
  supplier: PSL;
  importing: boolean;
  importFailed: boolean;
  importTimeout: boolean;
}

export const initialSupplierState: SupplierState = {
  loading: false,
  error: null,
  supplier: null,
  importing: false,
  importFailed: false,
  importTimeout: false
};

export function supplierReducer(state: SupplierState = initialSupplierState, action: IAction<PSLTypes>): SupplierState {
  switch (action.type) {
    case PSLTypes.SAVE_PSL:
      return loading(state);
    case PSLTypes.IMPORT_PSL:
    case PSLTypes.ADD_MATERIALS_FROM_FILE:
      return mergeRight(state, {importing: true});
    case PSLTypes.IMPORT_PSL_SUCCESS:
      return mergeRight(state, {importing: false});
    case PSLTypes.SAVE_PSL_FAILURE:
      return mergeRight(state, {importing: false, loading: false, error: action.error});
    case PSLTypes.MATERIALS_FROM_FILE_FAILURE:
      return mergeRight(state, {importing: false, loading: false, error: action.customError});
    case PSLTypes.IMPORT_PSL_FAILURE:
      return mergeRight(state, {loading: false, importing: false, importFailed: true, error: {
          file: action.file,
          validationErrors: action.validationErrors,
          parsingErrors: action.parsingErrors
        }});
    case PSLTypes.RESET_PSL_IMPORT_ERROR:
    case PSLTypes.RESET_MATERIALS_FROM_FILE_IMPORT_ERROR:
      return mergeRight(state, {importFailed: false, importTimeout: false});
    case PSLTypes.IMPORT_PSL_TIMEOUT:
      return mergeRight(state, {importing: false, loading: false, error: action.error, importTimeout: true});
    default:
      return state;
  }
}
