import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import {
  DocumentCountersFailureAction,
  DocumentCountersSuccessAction,
  DocumentCounterTypes,
} from "../actions/documentCounter.actions";
import {DocumentCounterService} from "../domains/documentCounter/documentCounter.service";

export function* fetchCounters(action): any {
  try {
    const result = yield call(DocumentCounterService.fetchCounters, action.query);
    yield put(new DocumentCountersSuccessAction({counters: result}));
  } catch (error) {
    yield put(new DocumentCountersFailureAction({error: error}));
  }
}

function* watchDocFlowCounters() {
  yield takeLatest(DocumentCounterTypes.FETCH_DOCCOUNTERS, fetchCounters);
}

export default function* documentCounterSaga() {
  yield all([
    fork(watchDocFlowCounters),
  ])
};
