import {all, call, fork, put, takeLatest} from 'redux-saga/effects'
import {
  FeedbackTypes,
  FetchFeedbackList,
  FetchFeedbackListRequest,
  FetchFeedbackListRequestError,
  FetchFeedbackListRequestSuccess,
} from "../actions/feedback.actions";
import {AdminService} from "../domains/admin.service";
import {List} from "../../shared/utils/odataClient.wrapper";
import {UserFeedback} from "../domains/feedback";
import {QueryFeedbackActions} from "../../admin/reducers/feedback.reducer";
import {feedbackQuery, newFeedbackQuery} from "../domains/feedbackQuery";

function* fetchFeedback(action: FetchFeedbackList) {
  try {
    yield put(new FetchFeedbackListRequest());
    const feedback: List<UserFeedback> = yield call(AdminService.fetchFeedbackList, feedbackQuery(action.query));
    yield put(new FetchFeedbackListRequestSuccess(feedback));
  } catch (error) {
    yield put(new FetchFeedbackListRequestError(error));
  }
}

function* initialFeedbackFetch() {
  yield put({type: QueryFeedbackActions.onNewQuery, query: newFeedbackQuery()});
}

function* watchFetchFeedbackList() {
  yield takeLatest(FeedbackTypes.FETCH_FEEDBACK_LIST, initialFeedbackFetch);
}

function* watchNewFeedbackQuery() {
  yield takeLatest(QueryFeedbackActions.onNewQuery, fetchFeedback);
}

export default function* feedbackSaga() {
  yield all([
    fork(watchFetchFeedbackList),
    fork(watchNewFeedbackQuery)
  ]);
}
