import "antd/dist/antd.css";
import {TreeSelect} from "antd";
import * as React from "react";
import {useEffect} from "react";
import {makeStyles} from "@material-ui/styles";
import {createStyles} from "@material-ui/core";
import {compose, contains, prop, sortBy, toLower} from "ramda";
import {PSLService} from "../../../preferredSuppliers/domains/psl.service";
import {ITreeCategory} from "../../../preferredSuppliers/containers/pslForm/pslForm.utils";
import {useQuery} from "react-query";

const useStyles = makeStyles(() =>
  createStyles({
    treeSelectClass: {
      "&& div": {
        height: "100%"
      },
      "&& .ant-select-selector": {
        color: "rgba(0, 0, 0, 0.65)"
      }
    }
  })
);

export function UserCategoryComponent({
  initialSpendCatL1s = [],
  initialSpendCatL2s = [],
  initialSpendCatL3s = [],
  onChange,
  hasError,
  disabled = false,
  clear = false,
  removeObsoluteCat = false,
  customSuccessStyle = null,
  customErrorStyle = null
}: {
  initialSpendCatL1s: { id: any; title: string }[];
  initialSpendCatL2s: { id: any; title: string }[];
  initialSpendCatL3s: { id: any; title: string }[];
  hasError: boolean;
  onChange: Function;
  disabled?: boolean;
  clear?: boolean;
  removeObsoluteCat?: boolean;
  customSuccessStyle?: any;
  customErrorStyle?: any
}) {
  const classes = useStyles({});
  const [selectedUserScope, setSelectedUserScope] = React.useState(
    []
      .concat(
        initialSpendCatL1s.map(el => ({
          value: "l1-" + el?.id,
          label: el?.title
        }))
      )
      .concat(
        initialSpendCatL2s.map(el => ({
          value: "l2-" + el?.id,
          label: el?.title
        }))
      )
      .concat(
        initialSpendCatL3s.map(el => ({
          value: "l3-" + el?.id,
          label: el?.title
        }))
      )
  );
  const [dataCat, setDataCat] = React.useState([]);
  const sortByCode = sortBy(compose(toLower, prop("code")));
  const { data: response } = useQuery(
    "categoryTree",
    async () => PSLService.fetchCategoryTree(),
    { staleTime: Infinity }
  );

  useEffect(() => {
    let isSubscribed = true;
    if (response && isSubscribed) {
      setDataCat(
        sortByCode(response as ITreeCategory[]).filter(el => 
          {

            if(removeObsoluteCat) return el.active === true;

            return el;

          }).map((el: ITreeCategory) => ({
          title: el.code + " - " + el.name,
          code: el.code,
          id: el.id,
          isLeaf: false,
          key: `l1-${el.id}`,
          children: sortByCode(el.spendCategories).filter(el2 =>
            {
              if(removeObsoluteCat) return el2.active === true;

              return el2;
            }).map(
            (el2: ITreeCategory) => ({
              title: el2.code + " - " + el2.name,
              code: el2.code,
              id: el2.id,
              isLeaf: false,
              key: `l2-${el2.id}`,
              children: sortByCode(el2.spendCategories).filter(el3 => 
                {
                  if(removeObsoluteCat) return el3.active == true;

                  return el3;
                }
                
                ).map(
                (el3: ITreeCategory) => ({
                  title: el3.code + " - " + el3.name,
                  code: el3.code,
                  id: el3.id,
                  isLeaf: true,
                  key: `l3-${el3.id}`,
                  mCode: el3.materialCode,
                  mDesc: el3.materialDescription,
                  mLongDesc: el3.materialLongDescription
                })
              )
            })
          )
        }))
      );
    }
    return () => (isSubscribed = false);
  }, [response]);

  useEffect(() => {
    if (clear) {
      setSelectedUserScope([]);
    }
  }, [clear]);

  const allData = dataCat.map(el => ({ value: el.key, label: el.title }));
  const onChangeUserScope = (
    originalValues: { value: string; label: string }[]
  ) => {
    setSelectedUserScope(originalValues);
    onChange({
      spendCatL1s: originalValues?.filter(el => el && el.value?.search(/l1-\d*$/gi) !== -1).map(el => ({ id: el.value.split("l1-")[1], title: el.label })),
      spendCatL2s: originalValues?.filter(el => el && el.value?.search(/l2-\d*$/gi) !== -1)
        .map(el => ({ id: el.value.split("l2-")[1], title: el.label })),
      spendCatL3s: originalValues?.filter(el => el && el.value?.search(/l3-\d*$/gi) !== -1)
        .map(el => ({ id: el.value.split("l3-")[1], title: el.label }))
    });
  };

  const userScopeProps = {
    treeData: [
          {
            title:
              selectedUserScope.length > 0 ? (
                <span
                  onClick={() => onChangeUserScope([])}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer"
                  }}
                >
                  Unselect all
                </span>
              ) : (
                <span
                  onClick={() => onChangeUserScope(allData)}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer"
                  }}
                >
                  Select all
                </span>
              ),
            value: "xxx",
            disableCheckbox: true,
            checkable: false,
            disabled: true
          }
        ].concat(dataCat),
    value: selectedUserScope,
    onChange: onChangeUserScope,
    treeCheckable: true,
    disabled: disabled,
    treeCheckStrictly: false,
    dropdownMatchSelectWidth: false,
    treeNodeFilterProp: "title",
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    labelInValue: true,
    placeholder: "Click here to select categories",
    filterTreeNode: (inputValue: string, treeNode: any) => {
      if (inputValue.length < 2 || typeof treeNode.title !== 'string') return false;
      return contains(inputValue.toUpperCase(), treeNode.title.toUpperCase());
    }
  };

    const errorStyle = {
        border: "1px solid red",
        borderRadius: "4px",
        width: "100%",
        minHeight: "38px"
    };
    const successStyle = {width: "100%", minHeight: "38px"};
    const getStyle = () =>
    hasError
      ? (customErrorStyle == null ? errorStyle : customErrorStyle)
      : (customSuccessStyle == null ? successStyle : customSuccessStyle);

  return (
    <TreeSelect
      {...userScopeProps}
      dropdownStyle={{ zIndex: 99999 }}
      style={getStyle()}
      className={classes.treeSelectClass}
    />
  );
}
