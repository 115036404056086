interface NetworkCompanyDTO {
  __metadata: { id: string; type: string; uri: string };
  CompCode: string;
  CompCodeDesc: string;
}

export class CompanyData {
  constructor(
    public CompCode: string,
    public CompCodeDesc: string
  ) {
  }

  static FromBackend(data: NetworkCompanyDTO): CompanyData {
    return new CompanyData(
      data.CompCode,
      data.CompCodeDesc
    )
  }

  static toSelect(data: CompanyData) {
    return {
      value: data.CompCode,
      label: data.CompCodeDesc
    }
  }

  static toFilter(data: CompanyData) {
    return {
      id: data.CompCode,
      title: data.CompCodeDesc
    }
  }
}
