import {useTranslation} from "react-i18next";
import {CompetitiveBiddingType} from "../../../../domains/documentFlow/documentFlow";
import {ComplianceIndicator} from "../../../complianceIndicator";
import {MoreOptionsMenu} from "../../../menu/menu";
import * as React from "react";

export const getType = (collectiveNumber: string, id, isCbActive: string): CompetitiveBiddingType => {
    //rules defined with Koushik
    if (isCbActive === "X") {
        if (collectiveNumber === "P") return CompetitiveBiddingType.Compliant;
        if (collectiveNumber === "X" && id) return CompetitiveBiddingType.NonCompliant;
        if (collectiveNumber === "X" && !id) return CompetitiveBiddingType.MissingCB;
    } else {
        if (collectiveNumber === "P") return CompetitiveBiddingType.Compliant;
        if (collectiveNumber === "X") return CompetitiveBiddingType.NonCompliant;
    }

    return null;
};

export const CBLink = ({df, isCreator, onCreateCB, onViewCB, onEditCB, onLinkCB, onRemoveCB}) => {
    const {t} = useTranslation();
    const id = df.CompetitiveBiddingIdInAzure;
    const isCbActive = df.IsCbActive;
    const collectiveNumber = df.CollectiveNumber;
    const hideSapFlag = df.SapAttchScn === "X";
    const menuItems = [
        {
            label: "Create Competitive Bidding",
            onClick: onCreateCB,
            visible: collectiveNumber === "X" && !id && !hideSapFlag
        },
        {
            label: "View Competitive Bidding",
            onClick: onViewCB,
            visible: id
        },
        {
            label: "Edit Competitive Bidding",
            onClick: onEditCB,
            visible: isCreator && id
        },
        {
            label: t('doc_flow:Link with existing CB'),
            onClick: onLinkCB,
            visible: collectiveNumber === "X" && !id && !hideSapFlag
        },
        {
            label: t('doc_flow:Remove link to CB'),
            onClick: onRemoveCB,
            visible: id && !hideSapFlag
        },
    ];
 
    return <div>
        <ComplianceIndicator type={getType(collectiveNumber, id, isCbActive)} tooltip={id}>
            {isCbActive === "X" ? <MoreOptionsMenu items={menuItems}/> : ""}
        </ComplianceIndicator>
    </div>
};
