import {ErrorAction, PendingAction, SuccessAction} from "../domains/core/actions";

export enum ChatActions {
  START_ROOM = "START_ROOM",
  START_INVOICE_CHAT_ROOM = "START_INVOICE_CHAT_ROOM",
  START_ROOM_SUCCESS = "START_ROOM_SUCCESS",
  START_ROOM_FAILURE = "START_ROOM_FAILURE",

  START_CHAT_COUNTERS = "START_CHAT_COUNTERS",
  START_CHAT_COUNTERS_SUCCESS = "START_CHAT_COUNTERS_SUCCESS",
  START_CHAT_COUNTERS_FAILURE = "START_CHAT_COUNTERS_FAILURE",

  LOAD_MESSAGES = "LOAD_MESSAGES",
  LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS',
  LOAD_MESSAGES_FAILURE = 'LOAD_MESSAGES_FAILURE',

  LOAD_INVOICE_CHAT_MESSAGES = "LOAD_INVOICE_CHAT_MESSAGES",
  LOAD_INVOICE_CHAT_MESSAGES_SUCCESS = "LOAD_INVOICE_CHAT_MESSAGES_SUCCESS",
  LOAD_INVOICE_CHAT_MESSAGES_FAILURE = "LOAD_INVOICE_CHAT_MESSAGES_FAILURE",

  LOAD_GROUPS = "LOAD_GROUPS",
  LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS',
  LOAD_GROUPS_FAILURE = 'LOAD_GROUPS_FAILURE',

  SEND_MESSAGE = "SEND_MESSAGE",
  SEND_NEW_MESSAGE = "SEND_NEW_MESSAGE",
  SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE",

  SEND_INVOICE_CHAT_MESSAGE = "SEND_INVOICE_CHAT_MESSAGE",
  SEND_INVOICE_CHAT_MESSAGE_SUCCESS = "SEND_INVOICE_CHAT_MESSAGE_SUCCESS",
  SEND_INVOICE_CHAT_MESSAGE_FAILURE = "SEND_INVOICE_CHAT_MESSAGE_FAILURE",

  NEW_MESSAGES = "NEW_MESSAGES",
  NEW_CHAT_COUNTER = "NEW_CHAT_COUNTER",

  DELETE_ATTACHMENTS = "DELETE_ATTACHMENTS",

  END_CHAT = "END_CHAT",
}

export const chatActionName = (domain: string) => (action: ChatActions): string => `@@${domain}/${action}`;

export function startRoomSuccess(actionGenerator: Function) {
  return class implements SuccessAction<ChatActions> {
    public type = actionGenerator(ChatActions.START_ROOM_SUCCESS);

    constructor(public room) {}
  }
}

export function startRoomFailure(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.START_ROOM_FAILURE);
  }
}

export function startChatCounterSuccess(actionGenerator: Function) {
  return class implements SuccessAction<ChatActions> {
    public type = actionGenerator(ChatActions.START_CHAT_COUNTERS_SUCCESS);

    constructor(public room, public currentUserId: number) {}
  }
}

export function startChatCounterFailure(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.START_CHAT_COUNTERS_FAILURE);
  }
}

export function onLoadMessagesSuccess(actionGenerator: Function) {
  return class implements SuccessAction<ChatActions> {
    public type = actionGenerator(ChatActions.LOAD_MESSAGES_SUCCESS);

    constructor(public id, public messages) {}
  }
}

export function onLoadMessagesFailure(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.LOAD_MESSAGES_FAILURE);
  }
}

export function onLoadGroupsSuccess(actionGenerator: Function) {
  return class implements SuccessAction<ChatActions> {
    public type = actionGenerator(ChatActions.LOAD_GROUPS_SUCCESS);

    constructor(public id, public chatGroups) {}
  }
}

export function onLoadGroupsFailure(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.LOAD_GROUPS_FAILURE);
  }
}

export function onSendMessageSuccess(actionGenerator: Function) {
  return class implements SuccessAction<ChatActions> {
    public type = actionGenerator(ChatActions.SEND_MESSAGE_SUCCESS);

    constructor(public result) {}
  }
}

export function onAttachmentDeletion(actionGenerator: Function){
  return class implements SuccessAction<ChatActions>{
    public type = actionGenerator(ChatActions.DELETE_ATTACHMENTS);
    constructor(public result) {}
  }
}

export function onSendMessageFailure(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.SEND_MESSAGE_FAILURE);
  }
}

export function onNewMessage(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.NEW_MESSAGES);

    constructor(public message) {}
  }
}

export function onNewChatCounter(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.NEW_CHAT_COUNTER);

    constructor(public counter) {}
  }
}

export function onEndChat(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.END_CHAT);
  }
}

export function onLoadInvoiceChatMessagesSuccess(actionGenerator: Function) {
  return class implements SuccessAction<ChatActions> {
    public type = actionGenerator(ChatActions.LOAD_INVOICE_CHAT_MESSAGES_SUCCESS);

    constructor(public id, public messages) {}
  }
}

export function onLoadInvoiceChatMessagesFailure(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.LOAD_INVOICE_CHAT_MESSAGES_FAILURE);
  }
}

export function onSendInvoiceChatMessageSuccess(actionGenerator: Function) {
  return class implements SuccessAction<ChatActions> {
    public type = actionGenerator(ChatActions.SEND_INVOICE_CHAT_MESSAGE_SUCCESS);

    constructor(public result) {}
  }
}

export function onSendInvoiceChatMessageFailure(actionGenerator: Function) {
  return class implements ErrorAction<ChatActions> {
    public type = actionGenerator(ChatActions.SEND_INVOICE_CHAT_MESSAGE_FAILURE);
  }
}
