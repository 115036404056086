import {CbFormState, CbReducer} from "./cb.reducer";
import {combineReducers, Reducer} from "redux";
import {SummaryReducer, SummaryState} from "./summary.reducer";
import {ListState, QueryableCBListReducer} from "./cbList.reducer";
import {Queryable} from "../../shared/queryable/query.reducer";

export interface CbStateStore {
  cbForm: CbFormState;
  summary: SummaryState;
  list: Queryable<ListState>;
}

export const CbCombinedReducers: Reducer<CbStateStore> = combineReducers({
  cbForm: CbReducer,
  summary: SummaryReducer,
  list: QueryableCBListReducer,
});
