import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

export function ConfirmDialog({open, title, confirmText, onCancel = null, onOK, 
  cancelButtonText = 'Cancel', okButtonText='Delete',
  customUi = <></>
}) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{__html: confirmText}}></DialogContentText>
        {customUi}
      </DialogContent>
      <DialogActions>
        {
          onCancel && <Button onClick={onCancel} color="primary" variant="outlined">
            {cancelButtonText}
          </Button>
        }
        <Button onClick={onOK} color="secondary" variant="outlined">
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
