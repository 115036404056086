import {
  ErrorAction,
  PendingAction,
  SuccessAction
} from "../../../shared/domains/core/actions";
import {GetChatMessagesSuccess, NewDocFlowChatCounter} from "../../actions/docflowChat.actions";
import {InvoiceItem} from "../../domains/prpo/InvoiceItem";
import { Invoice } from "../domains/invoices/Invoice";

export enum InvoiceTypes {
  FETCH_INVOICES = 'FETCH_INVOICES',
  FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST",
  FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE',

  FETCH_INVOICE_ITEMS_REQUEST="FETCH_INVOICE_ITEMS_REQUEST",
  FETCH_INVOICE_ITEMS_SUCCESS="FETCH_INVOICE_ITEMS_SUCCESS",
  FETCH_INVOICE_ITEMS_FAILURE="FETCH_INVOICE_ITEMS_FAILURE",

  FETCH_INVOICE_STATUS = "FETCH_INVOICE_STATUS",
  FETCH_INVOICE_STATUS_SUCCESS = "FETCH_INVOICE_STATUS_SUCCESS",
  FETCH_INVOICE_STATUS_FAILURE = "FETCH_INVOICE_STATUS_FAILURE",

  FETCH_INVOICE_PARKING_REASON_CODE = "FETCH_INVOICE_PARKING_REASON_CODE",
  FETCH_INVOICE_PARKING_REASON_CODE_SUCCESS = "FETCH_INVOICE_PARKING_REASON_CODE_SUCCESS",
  FETCH_INVOICE_PARKING_REASON_CODE_FAILURE = "FETCH_INVOICE_PARKING_REASON_CODE_FAILURE",
}

export class InvoiceFetchParkingCodeAction extends PendingAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_PARKING_REASON_CODE;
  constructor(public zone: string) {
    super();
  }
}

export class InvoicesFetchParkingCodeSuccessAction extends SuccessAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_PARKING_REASON_CODE_SUCCESS;
  constructor(public data: any) {
    super();
  }
}

export class InvoicesFetchParkingCodeFailureAction extends ErrorAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_PARKING_REASON_CODE_FAILURE;
}

export class InvoicesFetchAction extends PendingAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICES;
}

export class InvoicesFetchLoad extends PendingAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICES_REQUEST;
}

export class InvoicesFetchSuccessAction extends SuccessAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICES_SUCCESS;

  constructor(public documents: Invoice[], public total: number) {
    super();
  }
}

export class InvoicesFetchFailureAction extends ErrorAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICES_FAILURE;
}

export class InvoiceItemsFetchLoad extends PendingAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_ITEMS_REQUEST;

  constructor(public i: Invoice) {
    super();
  }
}

export class InvoiceItemsFetchSuccessAction extends SuccessAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_ITEMS_SUCCESS;

  constructor(public documents: InvoiceItem[], public i: Invoice) {
    super();
  }
}

export class InvoiceItemsFetchFailureAction extends ErrorAction<InvoiceTypes> {
  constructor(public errormsg: string, public i: Invoice) {
    super(errormsg);
  }

  public readonly type = InvoiceTypes.FETCH_INVOICE_ITEMS_FAILURE;
}

export class InvoiceStatusFetch extends PendingAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_STATUS;
}

export class InvoiceStatusFetchSuccessAction extends SuccessAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_STATUS_SUCCESS;

  constructor(public documents: Invoice[], public total: number, public status: object) {
    super();
  }
}

export class InvoicetatusFetchFailureAction extends ErrorAction<InvoiceTypes> {
  public readonly type = InvoiceTypes.FETCH_INVOICE_STATUS_FAILURE;
}

export type InvoiceActions =
  | InvoiceItemsFetchLoad
  | InvoiceItemsFetchSuccessAction
  | InvoiceItemsFetchFailureAction
  | GetChatMessagesSuccess
  | InvoicesFetchAction
  | InvoicesFetchSuccessAction
  | InvoicesFetchFailureAction
  | InvoiceStatusFetch
  | InvoiceStatusFetchSuccessAction
  | InvoicetatusFetchFailureAction
  | InvoicesFetchLoad
  | InvoiceFetchParkingCodeAction
  | InvoicesFetchParkingCodeSuccessAction
  | InvoicesFetchParkingCodeFailureAction
  ;
