import {all, fork} from 'redux-saga/effects';
import tenderCalendarSaga from "./calendar.saga";
import tenderCalendarFilterSaga from "./calendar.filter.saga";

export default function*() {
  yield all([
    fork(tenderCalendarSaga),
    fork(tenderCalendarFilterSaga)
  ]);
}
