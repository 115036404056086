import { useQuery } from "react-query";
import { UserService } from "../user/user.service";
import { ILanguage, MasterService } from "./master.service";

export const useFetchMaterials = (spendCat, materialNumber, companyCodes) => useQuery(spendCat + materialNumber + companyCodes,
  () => MasterService.fetchMaterialFromAzure(spendCat, materialNumber, companyCodes),
  {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: false,
  })

export const useFetchLanguages = () => useQuery("languages",
  () => MasterService.fetchLanguages().then((res: ILanguage[]) => {
    return res.filter(el => !!el.languageCode2Letters)
  }),
  {
    staleTime: Infinity,
    placeholderData: []
  })

export const useFetchBusinessUnits = () =>
  useQuery("businessUnits", () => MasterService.fetchBusinessUnits(),
    {
      staleTime: Infinity,
      placeholderData: []
    })

export const useFetchSpendCat = (systemAlias: string) =>
  useQuery("SpendCat" + systemAlias, () => MasterService.fetchSpendCat(systemAlias),
    {
      staleTime: Infinity,
      placeholderData: []
    })

export const useFetchMaterialType = (term: string, systemAlias: string) =>
  useQuery("MaterialType" + term + systemAlias, () => MasterService.fetchMaterialType(term, systemAlias),
    {
      staleTime: Infinity,
      placeholderData: []
    })

export const useFetchTaxCode = (compCode: string, systemAlias: string) => {
  return useQuery(`taxCodes${compCode}` + systemAlias, () => MasterService.fetchTaxCode(compCode, systemAlias), { staleTime: Infinity })
}

export const useFetchConditionTypes = (Purchaseorg: string, systemAlias: string) => {
  return useQuery("conditionTypes" + Purchaseorg + systemAlias, () => MasterService.fetchConditionTypes(Purchaseorg, systemAlias), { staleTime: Infinity })
}

export const useFetchCurrencyDecimals = (systemAlias: string) => {
  return useQuery("taxCodes" + systemAlias, () => MasterService.fetchCurrencyDecimals(systemAlias), { staleTime: Infinity })
}

export const useFetchCountryRegion = () => {
  return useQuery("countryRegions", () => MasterService.fetchCountryRegion(), { staleTime: Infinity })
}

export const useFetchUserContactDetails = (email: string) => {
  return useQuery("UserContactDetails" + email, () => UserService.fetchUserContactDetails(email), { staleTime: Infinity })
}

export const useFetchUserImageUrl = (email: string) => {
  return useQuery("UserContactDetails" + email, () => UserService.fetchUserImageUrl(email), { staleTime: Infinity })
}

export const useFetchPslScopeTitles = () => {
  return useQuery(`pslScopeTitles`, () => MasterService.fetchPslScopeTitles(), { staleTime: Infinity })
}