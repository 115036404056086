import {createStyles, makeStyles, Theme} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        center: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            color: theme.palette.secondary.main,
        },
        text: {
            fontWeight: 500,
        },
        link: {
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        },
        amount: {
            color: "#3C4144",
            fontSize: "18px",
            fontWeight: "bold",
        },
        currencySymbol: {
            paddingRight: "5px",
        },
        more: {
            fontSize: '19px',
        },
        pbContainer: {
            maxWidth: '125px',
            cursor: 'pointer'
        }
    })
);
