import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import Drawer from "@material-ui/core/Drawer";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { ToggleButton } from "@material-ui/lab";

export const MainTitle = styled.h2`
  font-size: 22px;
  color: #4A4A4A;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  color: #4A4A4A;
  font-weight: bold;
`;

export const VerticalView = styled.div`
  display : flex;
  flex-direction: column; 
`;

export const HorizontalView = styled.div`
  display : flex;
  flex-direction: row;
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between; 
  min-height: 65px;
  position: relative;
`;

export const ToolbarWithPadding = styled(Toolbar)`
   padding-left: 30px;
   padding-right: 30px;
`;

export const MainToolbar = styled(ToolbarWithPadding)`
   height: 115px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flexDirection: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;
export const NBSTabsContainer = styled(TabsContainer)`
background-color: #F2F2F2;
padding-left: 15px

`;
export const TableContainer = styled.div`
  padding: 0 30px 30px 30px;
`;

export const StyledTable = {
  boxShadow: 'none',
  border: '1px solid #E5E5E5',
  borderRadius: 0
};

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #F19001;
  }
`;

export const DocFlowSelect = styled.div`
  display: flex;
  .MuiSelect-select.MuiSelect-select {
    padding-left: 15px;
    padding-top: 7px;
  }
`;

export const StyledTab = styled(Tab)`
  min-width: 10px!important;
  &.MuiTab-root {
    @media (max-width: 1024px) {
      padding: 6px;
    }
  }
  &.Mui-selected {
    color: #F19001 !important;
  }
`;

export const StyledTabV = styled(Tab)`
  min-width: 10px!important;
  &.MuiTab-root {
    @media (max-width: 1024px) {
      padding: 2px;
    }
  }
  &.Mui-selected {
    color: #F19001 !important;
  }
`;
export const BigStyledTab = styled(StyledTab)`
  &.MuiTab-root {
    font-size: 22px;
    opacity: 1;
    color: #4A4A4A;
    text-transform: none;
  }
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

export const VerticalDivider = styled.div`
  border-left: 1px solid #E0E0E0;
  height: 40px;
  margin: 0 15px;
`;

export const StyledSearch = styled.div`
  align-self: flex-end;
  padding-bottom: 5px;
`;

export const StyledDialog = styled(Dialog)`
  && .MuiDialog-paperWidthXs {
    padding: 10px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  border-bottom: 1px solid #E0E0E0;
  color: #3C4144;
  h6 {
    font-weight: 600;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  color: #707070;
  font-size: 16px;
  opacity: 0.8;
  overflow: hidden!important;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 8px 18px!important;
  button {
    color: #F19001;
    font-weight: 600;
    padding: 10px;
  }
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: #F5F5F5;
    @media only screen and (max-width: 1120px) {
      left: 0;
      right: auto;
    }
  }
`;

export const DrawerForMultiLineEdit = styled(StyledDrawer)`
  .MuiDrawer-paper {
 width: 93%;   
  }
`;

export const ScrollableDrawer = styled(StyledDrawer)`
  .MuiDrawer-paper {
    @media only screen and (max-width: 1200px) {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
`;

export const ChatScrollableDrawer = styled(StyledDrawer)`
  .MuiDrawer-paper {
    @media only screen and (max-width: 1120px) {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
`;

export const StyledButton = styled(Button)`
  background-color: #F19001!important; 
  color: #fff!important;

  &:disabled {
    opacity: 0.4 !important;
  }
`;

export const ExportButton = styled(Button)`
  background-color: #F19001!important; 
  color: #fff!important;
  
  &:disabled {
    opacity: 0.4 !important;
  }
`;

