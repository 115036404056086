import {combineReducers, Reducer} from "redux";
import {NotificationsReducer, NotificationState} from "./notifications.reducer";
import {UserReducer, UserState} from "./user.reducer";
import {UIReducer, UIState} from "./ui.reducer";

export interface SharedStateStore {
  notifications: NotificationState;
  user: UserState;
  ui: UIState;
}

export const SharedCombinedReducers: Reducer<SharedStateStore> = combineReducers({
  notifications: NotificationsReducer,
  user: UserReducer,
  ui: UIReducer
});
